<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <!-- <form
      class="navbar-search form-inline mr-sm-3"
      :class="{
        'navbar-search-light': type === 'default',
        'navbar-search-dark': type === 'light',
      }"
      id="navbar-search-main"
    >
      <div class="form-group mb-0">
        <div class="input-group input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input class="form-control" placeholder="Search" type="text" />
        </div>
      </div>
      <button
        type="button"
        class="close"
        data-action="search-close"
        data-target="#navbar-search-main"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </form> -->
    <h3>{{title}}</h3>
    <!-- <h5>{{description}}</h5> -->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <!-- <div style="width:200px">
        <el-slider v-model="globalfontsize" :format-tooltip="formatTooltip" @change="onChangeFontSize" :min="10" :max="500"> </el-slider>
      </div> -->
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle far fa-user">
                <!-- <img alt="Image placeholder" src="img/theme/team-4.jpg" /> -->
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{userInfo.userName}}</span>
              </div>
            </div>
          </a>
        </template>
        <a class="dropdown-item" @click="showUser">
          <i class="fa fa-user"></i>ユーザー情報</a>
        <a class="dropdown-item" @click="signOut">
          <i class="fa fa-door-open"></i>ログアウト</a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<style v-if="isZando" lang="scss" src="./css/DashboardNavbarZando-scoped.scss" scoped />
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { Auth } from "aws-amplify";
// import UserModel from "../../appModel/users/UserModel"
import UserInfo from "../../appUtils/UserInfo";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    title() {
      const metaTitle = this.$route.meta.title
      // console.log(`meta title:${metaTitle}`)

      const navInfo = this.$store.state.navInfo
      // console.log(`navInfo.title:${navInfo.title}`)

      // F5で更新した時に、$route.meta.title が空になる為、$store.state.navInfo に プロジェクト名-画面名をセットする。（各画面）
      return `${navInfo.title}`

      // if (metaTitle == navInfo.title) {
      //   return !metaTitle ? "" : metaTitle
      // } else {
      //   if (metaTitle && navInfo.title) {
      //     return `${metaTitle} - ${navInfo.title}`
      //   } else if (!metaTitle) {
      //     return !navInfo.title ? "" : navInfo.title
      //   } else if (!navInfo.title) {
      //     return !metaTitle ? "" : metaTitle
      //   } else {
      //     return ""
      //   }
      
      // }
      // return !metaTitle ? "" : metaTitle
      // const navInfo = this.$store.state.navInfo
      // console.log(`navInfo.title:${navInfo.title}`)
      // return !navInfo.title ? "" : navInfo.title
    },
    description() {
      const navInfo = this.$store.state.navInfo
      return !navInfo.description ? "" : navInfo.description
    }
  },
  async mounted(){
    const user = await UserInfo.getUserInfo()

    if (this.$store.state.user.userId == "" && user.group != `ncsmanager`) {
      Auth.signOut()
      this.$router.push({
        name: 'Signin'
      })
    }
    this.initNavbarData().then(() => {
      // console.log('initNavbarData')
    })

  },
  data() {
    return {
      // userData: {},
      userInfo: {},
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      globalfontsize: "62"
    };
  },
  methods: {
    onChangeFontSize() {
      document.body.style.fontSize = `${this.globalfontsize}%`
    },
    formatTooltip(val) {
      return `${val}%`
    },
    onClickTodo() {
      // this.$refs.todolist.init()
      // let todoStore = this.$store.state.todo
      // todoStore.drawer = !todoStore.drawer
      // this.$store.commit('setTodo', todoStore)
      this.$emit('showtodolist', {})

    },
    onClickMeeting() {
      this.$router.push({
        name: 'MeetingMain'
      })
    },
    async initNavbarData() {
      // let result = await Auth.currentUserInfo()
      // alert(JSON.stringify(result,null,"\t"))
      try {
        let userStore = this.$store.state.user
        this.userInfo = {
          userName: userStore.userName,
          group: userStore.group,
          sub: userStore.cognitoSub
        }
        // this.userInfo = await UserInfo.getUserInfo()

        // const userId = this.userInfo.userName
        // console.log(`initNavbarData ${userId}`)
        // const pk = this.userInfo.group
        // const sk = UserModel.createSk(this.userInfo.sub)
        //alert(`pk:${pk}, sk:${sk}`)
        // const userData = await UserModel.getUser({pk: pk, sk: sk})
        //alert(JSON.stringify(userData,null,"\t"))
        // this.userData = userData.user
      } catch (e) {
        // console.log(`ユーザー情報取得エラー`)
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    signOut: function() {
      Auth.signOut()
      this.$router.push({
        name: 'Signin'
      })
    },
    async showUser() {
      // TODO:ユーザー情報表示中だと
      // this.$router.currentRoute._rawValue.path

      // let userinfo = await UserInfo.getUserInfo()
      // const userId = userinfo.userName
      let params = this.$store.state.user
      params.editUserId = params.cognitoSub
      params.createFlg = false
      params.fromNavbar = true

      this.$store.commit("setUser", params)

      this.$router.push({
        path: `/editusermodal`,
      });

    },
    async startMyRoom() {
      let meeting = this.$store.state.meeting
      // App.vueのWatchで監視しているプロパティを更新する
      meeting.startFlg = true
      this.$store.commit("setMeeting", meeting)

      
    }
  },
};
</script>
