<template>
  <div>
    <div class="card">
      <!-- ヘッダー -->
      <div class="row ml-0">
        <application-header
          :title="appTitle"
          :isDatePicker="false"
          :isNowTime="false"
          :isEditBase="false"
          :isEditVehicle="false"
          :isCreatePlan="false"
          :isDriveLog="false"
          :isOutputCsv="false"
          :isDriveVue="true"
          @driveVue="onClickDriveVue"
        >
        </application-header>

      <div v-if="!volumeId" style="margin-top:20px;margin-left:40px">
        <p>閲覧したい受入検査の打設箇所を運行情報画面上で選択してから、画面を表示してください。</p> 
      </div>

      <!-- <div v-if="volumeId" class="card-header border-0"> -->
        <!-- メニュー 追加・削除・編集を有効 -->
        <div v-if="volumeId" class="m-2 d-flex w-100">
          <el-date-picker
            ref="AcceptanceDate"
            class="ml-0 mr-1"
            v-model="datestring"
            type="date"
            :clearable="false"
            size="medium"
            format="YYYY/MM/DD"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            placeholder=""
            @change="changeDate($event)"
          ></el-date-picker>
          
          <div class="ml-0">
              <table-tool-menu
                  class="acceptanceMenu"
                  :title="title"
                  :addTitle="addTitle"
                  :editTitle="editTitle"
                  :deleteTitle="deleteTitle"
                  :isAdd="isAdd"
                  :isDelete="true"
                  :isEdit="isEdit"
                  :isCopy="false"
                  :isSingleSelect="selectRow"
                  :isMultiSelect="false"
                  :processing="false"
                  :isOrderMove="false" 
                  :disableMoveDown="false"
                  :disableMoveUp="false"
                  @addNew="onAddAcceptance()"
                  @edit="onEditAcceptance(selectedData)"
                  @delete="onDeleteAcceptance()"
              >
              </table-tool-menu>            
          </div>
          <div class="ml-auto pr-3" >
            <el-button
              class="listButton "
              size="medium"
              @click="onClickCSV"
              >CSV出力</el-button
            > 
          </div>
        </div>
      <!-- </div> -->

      <!-- 受入検査一覧テーブル -->
      <div v-if="volumeId" class="acceptance-list">
        <el-table
          ref="AcceptanceTable"
          class="table-responsive table-flush"
          header-row-class-name="app-th-row"
          select-on-indeterminate="true"
          :data="pagedData"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
        >
          <el-table-column v-if="isAdd" type="selection" align="left" min-width="120px"></el-table-column>          
          <el-table-column label="検査回数" min-width="120px" prop="inspectionCnt">
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onEditAcceptance(row)"
                  >{{ row.inspectionCnt }}回目
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="検査日時" min-width="240px" prop="acceptance.inspectionDateTime">
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onEditAcceptance(row)"
                  >{{ DateJp(row.acceptance.inspectionDateTime) }}
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="スランプ（cm）" min-width="120px" prop="acceptance.slump" ></el-table-column> 
          <el-table-column label="塩化物量（kg/㎥）" min-width="130px" prop="acceptance.chlorideQuantity" ></el-table-column>
          <el-table-column label="空気量（%）" min-width="120px" prop="acceptance.airVolume" ></el-table-column>
          <el-table-column label="コンクリート温度（℃）" min-width="200px" prop="acceptance.ct" ></el-table-column>
          <el-table-column label="外気温度（℃）" min-width="120px" prop="acceptance.at" ></el-table-column>
          <el-table-column label="写真" min-width="120px" prop="isPhoto" ></el-table-column>
          <el-table-column label="備考" min-width="250px" prop="acceptance.memo" ></el-table-column>
        </el-table>

        <div class="row mt-1 d-flex flex-fill justify-content-center">
          <div class="col-auto">
            <div class="mt-1">
              <el-pagination
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      
      <!-- 受入検査結果登録モーダル -->
      <modal v-model:show="modals.editAcceptanceDlg" size="lg" >
        <el-tabs
          type="card"
          v-model="acctivePane"          
          class="demo- mt-1"
        >
          <!----------------------------------------------------
            1つめのタブ（受入検査項目）
           ---------------------------------------------------->
          <el-tab-pane name="0">
            <template #label>
              <span class="font-size-middle font-weight-bold">受入検査項目</span>
            </template>

            <div style="display:flex">
              <!-------------------------- 左側の項目 -------------------------->
              <div>
                <el-form :model="formData" :rules="rules" ref="form">
                  <!-------------------------- 0行目の項目（天候 0:晴  1:曇 2:雨 3:雪） -------------------------->
                  <h4>天候</h4>
                  <el-radio-group
                    class="mb-2"
                    v-model="formData.weather"
                    size="large"
                    :fill="'#154CA3'"
                    @change="changeType"
                  >
                    <el-radio-button label="0">晴</el-radio-button>
                    <el-radio-button label="1">曇</el-radio-button>
                    <el-radio-button label="2">雨</el-radio-button>
                    <el-radio-button label="3">雪</el-radio-button>
                  </el-radio-group>                  

                  <!-------------------------- １行目の項目（検査回数、検査日時） -------------------------->
                  <div style="display:flex">
                    <div>
                      <h4>検査回数</h4>
                      <p>{{editValueItem.inspectionCnt}} 回目</p>
                    </div>
                    <div style="margin-left:40px">
                      <h4>検査日時<require-tag /></h4>
                        <!-- <el-form-item prop="inspectionDateTime">
                          <el-date-picker
                            name="value"
                            v-model="formData.inspectionDateTime"
                            type="datetime"
                            class="inline-input flex-fill"
                            placeholder="日付を選択"
                            :shortcuts="[{text: '今日', value: new Date()}]"
                            :disabled-date="disabledDate"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            format="YYYY/MM/DD HH:mm:ss"
                            clearable
                          />
                        </el-form-item> -->
                        <div style="display:flex">
                          <p>{{formData.inspectionDate}}</p>
                          <el-time-picker
                            class="w-50"
                            v-model="formData.inspectionDateTime"
                            placeholder=""
                            :clearable="false"
                            size="medium"
                            format="HH:mm"
                            style="margin-left:20px;margin-top:-5px"
                          >
                          </el-time-picker>
                      </div>
                    </div>
                  </div>
                  <!-------------------------- 2行目の項目（スランプ（cm）、塩化物量（kg/㎥）、空気量（%）） -------------------------->
                  <div style="display:flex">
                    <div>
                      <h4>スランプ（cm）</h4>
                      <el-form-item prop="slump">
                        <el-input  style="width:160px;margin-right:20px"  v-model="formData.slump"></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <h4>塩化物量（kg/㎥）</h4>
                      <el-form-item prop="chlorideQuantity">
                        <el-input  style="width:160px;margin-right:20px"  v-model="formData.chlorideQuantity"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <!-------------------------- 2行目の分割（空気量（%）） -------------------------->
                  <div style="display:flex">
                    <div>
                      <h4>空気量（%）</h4>
                      <el-form-item prop="airVolume">
                        <el-input  style="width:160px;margin-right:20px"  v-model="formData.airVolume"></el-input>
                      </el-form-item>
                    </div>

                  </div>
                  <!-------------------------- 3行目の項目（コンクリート温度（℃）、外気温度（℃）） -------------------------->
                  <div style="display:flex">
                    <div>
                      <h4>コンクリート温度（℃）</h4>
                      <el-form-item prop="ct">
                        <el-input  style="width:160px;margin-right:20px"  v-model="formData.ct"></el-input>
                      </el-form-item>
                    </div>
                    <div>
                      <h4>外気温度（℃）</h4>
                      <el-form-item prop="at">
                        <el-input  style="width:160px;margin-right:20px"  v-model="formData.at"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <!-------------------------- 4行目の項目（備考） -------------------------->
                  <div>
                    <h4>備考</h4>
                    <el-form-item prop="memo">
                      <el-input  v-model="formData.memo"></el-input>
                    </el-form-item>
                  </div>
                  
                </el-form>  
              </div>
              <!-------------------------- 右側の項目 -------------------------->
              <div style="margin-left:20px; margin-top:30px">
                <!-------------------------- 黒板写真 -------------------------->
                <small>黒板写真</small>

                <!-- S3からダウンロードした写真を表示 -->
                <div v-if="isBlackBordPhoto">
                  <el-image style="width: 280px; height: auto" :fit="`cover`" :src="thumbListBlackboard[0].data" :key="thumbListBlackboard[0].data" :preview-src-list="[thumbListBlackboard[0].data]" />
                  <div class="d-flex justify-content-between">
                    <!-- <base-button size="mini" type="primary" class="d-flex" @click="onDeletePhotoBlackBord">削除</base-button>
                    <el-button
                      class="listButton "
                      size="medium"
                      @click="downloadImageUrl(0)"
                      >ダウンロード</el-button
                    >  -->
                    <el-button
                        class="deleteButton"
                        size="mini"
                        @click="onDeletePhotoBlackBord"
                        ><i class="fas fa-trash-alt"></i></el-button
                      >
                    <el-button
                        class="downloadButton"
                        size="mini"
                        @click="downloadBlackBordUrl(0)"
                        ><i class="fas fa-download"></i></el-button
                      >
                  </div>
                  
                </div>

                <!-- S3へアップロードする写真選択用 -->
                <div v-if="!isBlackBordPhoto">
                  <el-upload
                    class="upload-demo m-1"
                    action=""
                    :on-change="handleOnChangeBlackboard"
                    :on-remove="handleRemoveBlackboard"
                    :file-list="uploadFileListBlackboard"
                    :multiple="false"
                    :auto-upload="false"
                    :limit=1
                    :show-file-list="true"
                    list-type="picture-card"
                    style="margin-left:40px"
                  >
                    <el-button size="small" type="primary" class="mb-0">写真を選択</el-button>
                    <template #file="{ file }">
                      <div v-if="disabled">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreviewBlackboard(file)"
                          >
                            <el-icon><zoom-in /></el-icon>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemoveBlackboard(file)"
                          >
                            <el-icon><Delete /></el-icon>
                          </span>

                        </span>
                      </div>
                    </template>
                  </el-upload>
                  <el-dialog v-model="dialogVisible">
                    <img w-full class="w-100" :src="dialogImageUrl" alt="Preview Image" />
                  </el-dialog>
                </div>              
              </div>              
            </div>              
          </el-tab-pane>

          <!----------------------------------------------------
            2つめのタブ（写真）
           ---------------------------------------------------->
          <el-tab-pane name="1">
            <template #label>
              <span class="font-size-middle font-weight-bold">写真</span>
            </template>

            <!-- S3からダウンロードした写真を表示 -->
            <div class="d-flex flex-column mt-1 d-flex my-box-light flex-wrap">
              <div class="">
                <div class="mb-2 mt-2 overflow-auto" style="max-height:300px">
                  <!-- <el-image
                    v-for="thumb in thumbList"
                    :key="thumb"
                    style="width:130px;height:130px;"
                    class="m-1 "
                    :src="thumb"
                    :preview-src-list="photoList"
                    :fit="`cover`">
                    </el-image> -->
                  <div v-for="thumb in thumbList" :key="thumb.index" style="display: inline-block; margin-right:20px;">
                    <el-image style="width: 130px; height: 130px" :fit="`cover`" :src="thumb.data" :key="thumb.data" :preview-src-list="[thumb.data]"/> <!-- :preview-src-list="photoList" -->
                    <!-- <base-button size="mini" type="primary" class="d-flex" @click="onDeletePhoto(thumb.index)">削除</base-button> -->
                    <div class="d-flex justify-content-between">
                    <el-button
                        class="deleteButton"
                        size="mini"
                        @click="onDeletePhoto(thumb.index)"
                        ><i class="fas fa-trash-alt"></i></el-button
                      >
                    <el-button
                        class="downloadButton"
                        size="mini"
                        @click="downloadImageUrl(thumb.index)"
                        ><i class="fas fa-download"></i></el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- S3へアップロードする写真選択用 -->
            <div v-if="uploadFileList.length > 0">
              <small>未アップロード写真 {{uploadFileList.length}}枚</small>
            </div>

            <el-upload
              class="upload-demo m-1"
              action=""
              :on-change="handleOnChange"
              :on-remove="handleRemove"
              :file-list="uploadFileList"
              :limit=9
              :multiple="false"
              :auto-upload="false"
              list-type="picture-card"
            >
              <el-button size="small" type="primary" class="mb-0">写真を選択</el-button>
              <template #file="{ file }">
                <div v-if="disabled">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <el-icon><zoom-in /></el-icon>
                    </span>
                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleDownload(file)"
                    >
                      <el-icon><Download /></el-icon>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <el-icon><Delete /></el-icon>
                    </span>
                  </span>
                </div>
              </template>
            
            </el-upload>

          </el-tab-pane>
        </el-tabs>

        <div class="row mt-2 mb-2 ml-2 mr-2">
          <div class="col-sm-5">
            <base-button
              type="secondary"
              block
              @click="onClickCancel"
              >キャンセル</base-button
            >
          </div>
          <div class="col-sm-2">
          </div>
          <div class="col-sm-5">
            <base-button
              type="primary"
              block
              @click="onClickSubmit"
              >更新</base-button
            >
          </div>
        </div>
      </modal> 


      <!-- 受入検査結果処理確認モーダル -->
      <modal v-model:show="modals.confirmDlg">
        <template v-slot:header>
            <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
        </template>
        <div class="mb-5">
          {{confirmMessage}}
        </div>
        <div class="row mt-2 mb-2 ml-2 mr-2">
          <div class="col-sm-5">
            <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
          </div>
          <div class="col-sm-2">
          </div>
          <div class="col-sm-5">
            <base-button v-if="actionMode != 1" type="primary" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
            <base-button v-if="actionMode == 1" type="danger" block @click="onClickConfirmOK">{{confirmOK}}</base-button>
          </div>
        </div>
      </modal>

      </div>
    </div>
  </div>
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left:5px;
  }  
  .acceptance-list {
    width: 100%;
    height: 100%;
  }
  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
  .listButton {
    color: #fff;
    background-color: #ED7D31;
    border: 1px solid #AE5A21;
    font-size: 0.85rem;
  }
  .downloadButton {
    color: #fff;
    background-color: #007806;
    border: 1px solid #005305;
    font-size: 0.85rem;
  }
  .deleteButton {
    color: #fff;
    background-color: #b50404;
    border: 1px solid #700000;
    font-size: 0.85rem;
  }
  
</style>

<style>
  .el-table .no-disp-mobile-row {
    --el-table-tr-background-color: gray;
  }
  .el-upload-list__item-thumbnail{
    object-fit: cover;
  }  
</style>

<script>
import ApplicationHeader from '../components/Menu/ApplicationHeader.vue';
import Modal from "@/components/Modal";
import appLog from "@/appUtils/AppLog"
import TableToolMenu from "../components/Menu/TableToolMenu";
import VehicleModel from "@/appModel/Vehicle/VehicleModel";
import AcceptanceModel from "@/appModel/Acceptance/AcceptanceModel";
import { useToast } from "vue-toastification";
import DateUtil from "@/appUtils/DateUtil";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage"
import Mime from "@/appUtils/Mime.js";
import path from 'path'
import ValidateUtil from "@/appUtils/ValidateUtil";
import PlandModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
const stringify = require("csv-stringify/lib/sync");

export default {
  components: {
    ApplicationHeader,
    TableToolMenu,
    Modal
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      appTitle: "受入検査結果登録",
      title: "受入検査結果一覧",
      addTitle: "受入検査結果を追加",
      editTitle: "受入検査結果を編集",
      deleteTitle: "受入検査結果を削除",
      acctivePane: "0",
      isBlackBordPhoto: false,
      thumbListBefore: [],
      thumbList: [],
      photoList: [],    
      uploadFileList: [],
      thumbListBlackboard: [],
      uploadFileListBlackboard: [],
      tenantStorage: null, 
      isAdd: true,
      isEdit: true,   
      userInfo: null,  
      projectId: null,
      projectName: null,
      datestring: null,
      volumeId: null,
      acceptances: [],
      selectedData: null,
      selectedDatas: null,
      selectedDataCnt: 0,      
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },      
      modals: {
        editAcceptanceDlg: false,
        confirmDlg: false,
      },
      editValueItem: {
        rowId: null,
        inspectionCnt: null
      },  
      userAuthority: 1,
      memoLengh: 100,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）  
      confirmTitle: '',
      confirmMessage: '',
      confirmOK: '',
      actionMode: 0, // 0: 追加, 1: 削除, 2: 編集, 3: コピー
      disabled: false,
      dialogImageUrl: null,
      dialogVisible: false,      
      formData: {
        weather: 0,
        inspectionDate: null,
        inspectionDateTime: null,
        slump: 0,
        chlorideQuantity: 0,
        airVolume: 0,
        ct: 0,
        at: 0,
        memo: '',
      },
      formDataBeofore: {
        weather: 0,
        inspectionDate: null,
        inspectionDateTime: null,
        slump: 0,
        chlorideQuantity: 0,
        airVolume: 0,
        ct: 0,
        at: 0,
        memo: '',
      },
      rules: {
        inspectionDateTime: [
          { required: false, validator: this.checkInspectionDateTime, trigger: "blur" },
        ],
        slump: [
          { required: false, validator: this.checkSlump, trigger: "blur" },
        ],
        chlorideQuantity: [
          { required: false, validator: this.checkChlorideQuantity, trigger: "blur" },
        ],
        airVolume: [
          { required: false, validator: this.checkAirVolume, trigger: "blur" },
        ],
        ct: [
          { required: false, validator: this.checkCt, trigger: "blur" },
        ],
        at: [
          { required: false, validator: this.checkAt, trigger: "blur" },
        ],
        memo: [
          { required: false, validator: this.checkMemo, trigger: "blur" },
        ],
      },
      availableDays: {},
      checkPhotoSize: 32, // 検査写真のアップロード時のチェックするファイルサイズ（単位：MB）
    };
  },

  // コンピュートプロパティ
  computed: {
    selectRow() {
      if (this.userAuthority == 0)
      {
        return false;
      }
      if (this.selectedDataCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.acceptances.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.acceptances.length;
    },
    vuename() {
      return "AcceptanceList.vue"
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  async created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.infoLog("created", `Start Vuex：projectid(${this.$store.state.acceptance.projectid})、projectname(${this.$store.state.acceptance.projectname})、datestring(${this.$store.state.acceptance.datestring})`)
    await this.init()
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    this.dbData = null
  },
  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.editAcceptanceDlg = false;
    this.modals.confirmDlg = false;
    
    this.infoLog("beforeRouteLeave", `End`)
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    handleOnChangeBlackboard(file, fileList){

      // ファイルサイズチェック
      const sizeMb = file.size / 1024 / 1024;
      const isOverSize = sizeMb > this.checkPhotoSize;

      if (isOverSize) {
        this.showBottomToast(`指定した画像ファイルは大きすぎます。\n${this.checkPhotoSize}MB以下のファイルを指定してください。`, 'error')
        this.uploadFileListBlackboard = []
      } else {
        this.uploadFileListBlackboard = fileList
      }

    },
    handleRemoveBlackboard(file, fileList){
      this.uploadFileListBlackboard = []
    },
    handlePictureCardPreviewBlackboard(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleOnChange(file, fileList){

      // ファイルサイズチェック
      const sizeMb = file.size / 1024 / 1024;
      const isOverSize = sizeMb > this.checkPhotoSize;

      if (isOverSize) {
        this.showBottomToast(`指定した画像ファイルは大きすぎます。\n${this.checkPhotoSize}MB以下のファイルを指定してください。`, 'error');
        this.handleRemove(file, null);
      } else {

        // ファイル数をチェック
        if (this.thumbList.length + fileList.length > 9) {
          this.showBottomToast(`写真が登録できるのは9枚までです。`, 'error');
          this.handleRemove(file, null);
        } else {
          this.uploadFileList = fileList  
        }        
      }      

    },
    handleRemove(file, fileList){
      let newFileList = [];
      for (let i=0; i<this.uploadFileList.length; i++){
        if (file.raw != this.uploadFileList[i].raw) {
          newFileList.push(this.uploadFileList[i]);
        }
      }
      this.uploadFileList = newFileList
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload(file) {
      console.log(file)
    },
    /**
     * 初期化処理
     */    
    async init() {

      let loader = this.showLoader();

      this.userInfo = await UserInfo.getUserInfo()

      // store(vuex)から値取得
      this.projectId = this.$store.state.acceptance.projectid
      this.datestring = this.$store.state.acceptance.datestring
      this.projectName = this.$store.state.acceptance.projectname
      this.volumeId = this.$store.state.acceptance.volumeid

      // タイトルセット
      this.title = "受入検査結果一覧（" + this.$store.state.acceptance.pouringPosition + "）";

      try {
        // ProjectIDがセットされているかチェック
        if (this.projectId == null || this.projectId == ""){
          this.showBottomToast(`受入検査結果一覧画面表示処理に失敗しました。（プロジェクトIDが不正）`, 'error').then(() => {
          })
          this.$router.push({
            path: "/projects",
          });        
        }

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType
        if (this.userAuthority == 0) {
          this.isAdd = false;
        }
        
        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
        
        // 受入検査編集用変数初期化
        this.initEditListItem();
        // カレンダー制御用リスト取得
        this.getAcceptanceListAll().then(() => {
          
        })
        // 受入検査一覧取得
        await this.getAcceptanceList();

        // 連打防止
        this.repeatedHitsFlg = false

      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
        
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 受入検査編集項目初期化
     */       
    initEditListItem() {
      
      // 受入検査編集項目の初期化
      let item = {
        rowId: "",
        inspectionCnt: 0,
        inspectionDateTime: "",
        slump: 0,
        chlorideQuantity: 0,
        airVolume: 0,
        ct: 0,
        at: 0,
        memo: "",
      }

      this.editValueItem = item
    },
    /**
     * カレンダーの活性制御用
     */
    async getAcceptanceListAll() {
      this.availableDays = {}

      const projectId = this.projectId;
      
      let listdata = await AcceptanceModel.getPkSkOnlyList(projectId, "");
      for (let data of listdata) {
        let skElements = data.sk.split("#")
        console.log(`sk: ${data.sk}`)
        this.availableDays[skElements[2]] = true
      }
      console.log(`availableDays: ${JSON.stringify(this.availableDays)}`)
      
    },
    /**
     * 受入検査情報一覧取得（acceptance.inspectionDateTimeカラム昇順でソート）
     */       
    async getAcceptanceList(){

      // テーブル関連編集初期化
      this.selectedData = null;      
      this.selectedDatas = null;      
      this.selectedDataCnt = 0; 

      const projectId = this.projectId;
      const targetDate = DateUtil.dateStringBase(this.datestring, "YYYYMMDD");
      //let listdata = await AcceptanceModel.getAcceptanceList(projectId, targetDate); 
      let listdata = await AcceptanceModel.getAcceptanceListFromVolumeId(projectId, this.volumeId); 
      
      // ソートして一覧に表示
      this.acceptances = listdata.sort(function(a, b){
        if (a.acceptance.inspectionDateTime > b.acceptance.inspectionDateTime) return 1;
        if (a.acceptance.inspectionDateTime < b.acceptance.inspectionDateTime) return -1;
        return 0
      })

      // 検査回数、写真のありを設定
      let cnt = 0;
      this.acceptances.forEach(item => {    
        item.inspectionCnt = ++cnt;
        if (item.acceptance.photos.length > 0){
          item.isPhoto = "あり";
        }
        //item.AcceptanceList.maxCapacity = item.AcceptanceList.maxCapacity.toFixed(2);
        item.acceptance.inspectionDateTime = DateUtil.dateStringBase(item.acceptance.inspectionDateTime, 'YYYY/MM/DD HH:mm:ss');
      });

      // メニューを非表示にする
      this.selectedData = null;      
    },
    DateJp(dateString) {
      return DateUtil.dateStringBase(dateString, "YYYY年MM月DD日 HH:mm:ss")
    },
    /**
     * 受入検査情報一覧取得（acceptance.inspectionDateTimeカラム昇順でソート）
     */       
    async makePhotoList(row, user) {

      this.thumbList = []
      this.thumbListBefore = [];
      this.photoList = []

      this.thumbListBlackboard = []
      this.thumbListBeforeBlackboard = [];
      this.photoListBlackboard = []

      this.uploadFileList = [];
      this.uploadFileListBlackboard = [];
      this.isBlackBordPhoto = false;

      // ユーザー情報取得

      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      let tenantStorage = new TenantStorage(user.group) 

      // サムネイル、クリック時の写真表示用データ作成
      for (let i = 0; i < row.acceptance.photos.length; i++) {
        
        try {
          let item = row.acceptance.photos[i];
          let photoFileFullPath = await this.getPhotoS3FullPath(item.photoName, row.acceptance.acceptanceId);
          const photoKey = `${path.dirname(photoFileFullPath)}/${path.basename(photoFileFullPath)}`
          let result = await tenantStorage.get(photoKey);

          if (item.photoType == 0){
            this.isBlackBordPhoto = true;
            this.thumbListBlackboard.push({index: i, path: photoFileFullPath, data: result.toBase64()})
            this.thumbListBeforeBlackboard.push({index: i, path: photoFileFullPath, data: result.toBase64()})  
            this.photoListBlackboard.push({index: i, path: photoFileFullPath, data: result.toBase64()})  
          } else {
            let index_adjustment = 0;
            if (this.isBlackBordPhoto) index_adjustment = 1; 

            this.thumbList.push({index: i - index_adjustment, path: photoFileFullPath, data: result.toBase64()})
            this.thumbListBefore.push({index: i - index_adjustment, path: photoFileFullPath, data: result.toBase64()})  
            this.photoList.push({index: i - index_adjustment, path: photoFileFullPath, data: result.toBase64()})  
          }
        } catch (e){
          this.errorLog("makePhotoList", this.parseErrorObject(e))
        }

      }

    },

    /**
     * 写真ファイルS3のフルパスを取得する
     */
    async getPhotoS3FullPath(fileName, acceptanceId){
      const user = await UserInfo.getUserInfo()
      const targetDate = DateUtil.dateStringBase(this.datestring, "YYYYMMDD");
      return `tenant/${user.group}/${this.projectId}/${targetDate}/acceptance/${acceptanceId}/${fileName}`; 
    },

    /**
     * 受入検査結果情報登録画面（モーダル画面表示）
     */
    onAddAcceptance() {
      this.editValueItem.rowId = null;
      this.editValueItem.inspectionCnt = this.acceptances.length + 1;
      this.formData.weather = "0";
      this.formData.inspectionDate = this.datestring;
      let now = new Date();
      this.formData.inspectionDateTime = this.datestring + now.getHours() + ":" + now.getMinutes(); //DateUtil.dateStringDateTime(new Date());
      this.formData.slump = null;
      this.formData.chlorideQuantity = null;
      this.formData.airVolume = null;
      this.formData.ct = null;
      this.formData.at = null;
      this.formData.memo = "";

      this.thumbList = []
      this.thumbListBefore = [];
      this.photoList = []
      this.thumbListBlackboard = []
      this.thumbListBeforeBlackboard = [];
      this.photoListBlackboard = []
      this.uploadFileList = [];
      this.uploadFileListBlackboard = [];
      this.isBlackBordPhoto = false;

      this.formDataBeofore.inspectionDateTime = this.formData.inspectionDateTime;
      this.formDataBeofore.slump = this.formData.slump;
      this.formDataBeofore.chlorideQuantity = this.formData.chlorideQuantity;
      this.formDataBeofore.airVolume = this.formData.airVolume;
      this.formDataBeofore.ct = this.formData.ct;
      this.formDataBeofore.at = this.formData.at;
      this.formDataBeofore.memo = this.formData.memo;      

      // 選択行の情報をセット    
      this.acctivePane = "0";  
      this.modals.editAcceptanceDlg = true;     
    },
    /**
     * 受入検査結果情報編集画面（モーダル画面表示）
     */
    async onEditAcceptance(row) {
      this.selectedData = row
      let loader = this.showLoader();

      try {

        // 選択行の情報をセット    
        this.editValueItem.rowId = row.id;
        this.editValueItem.inspectionCnt = row.inspectionCnt;
        this.formData.weather = row.acceptance.weather;
        this.formData.inspectionDate = this.datestring;
        this.formData.inspectionDateTime = row.acceptance.inspectionDateTime;
        this.formData.slump = row.acceptance.slump;
        this.formData.chlorideQuantity = row.acceptance.chlorideQuantity;
        this.formData.airVolume = row.acceptance.airVolume;
        this.formData.ct = row.acceptance.ct;
        this.formData.at = row.acceptance.at;
        this.formData.memo = row.acceptance.memo;

        const user = await UserInfo.getUserInfo()
        await this.makePhotoList(row, user);

        this.formDataBeofore.weather = this.formData.weather;
        this.formDataBeofore.inspectionDate = this.formData.inspectionDate;
        this.formDataBeofore.inspectionDateTime = this.formData.inspectionDateTime;
        this.formDataBeofore.slump = this.formData.slump;
        this.formDataBeofore.chlorideQuantity = this.formData.chlorideQuantity;
        this.formDataBeofore.airVolume = this.formData.airVolume;
        this.formDataBeofore.ct = this.formData.ct;
        this.formDataBeofore.at = this.formData.at;
        this.formDataBeofore.memo = this.formData.memo;   

        // 編集画面表示
        this.acctivePane = "0";  
        this.modals.editAcceptanceDlg = true;

      } catch (e) {
        this.errorLog("onEditAcceptance", this.parseErrorObject(e))

      } finally {
        this.hideLoader(loader);
      }

    },
    /**
     * 受入検査結果削除
     */       
    async onDeleteAcceptance(){

      // 受入検査処理確認モーダル表示
      let operation = this.deleteTitle;
      this.confirmTitle = operation;
      this.confirmMessage = "選択した受入検査結果を削除します。よろしいですか？";
      this.confirmOK = '削除';
      this.actionMode = 1;
      this.modals.confirmDlg = true;

    },
    /**
     * 受入検査編集編集画面（モーダル）キャンセルボタン押下時の処理
     */      
    async onClickCancel()
    {
      let editJudge = false;
      
      // 写真
      if (JSON.stringify(this.thumbList) !=JSON.stringify(this.thumbListBefore)) {
        editJudge = true;
      } else if (JSON.stringify(this.thumbListBlackboard) !=JSON.stringify(this.thumbListBeforeBlackboard)) {
        editJudge = true;
      }

      if (this.uploadFileList.length > 0){
        editJudge = true;
      } else if (this.uploadFileListBlackboard.length > 0) {
        editJudge = true;
      }

      if (JSON.stringify(this.formData) != JSON.stringify(this.formDataBeofore)) {


        editJudge = true;
      }


      if (editJudge) {
        if (confirm('変更箇所があります。保存せずに画面を閉じます。よろしいですか？'))
        {
          this.modals.editAcceptanceDlg = false;
        }
      } else {
        this.modals.editAcceptanceDlg = false;
      }

    },
    /**
     * 受入検査結果情報編集画面（モーダル）更新ボタン押下時の処理
     */       
    async onClickSubmit() {

      await this.$refs.form.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          // 登録確認メッセージ
          let operation = null;
          let buttonLabel = '実行';
          if (this.editValueItem.rowId == null) {
            operation = this.addTitle;
            this.actionMode = 0;
            buttonLabel = '追加';
          } else {
            operation = this.editTitle;
            this.actionMode = 2;
            buttonLabel = '編集';
          }

          // 受入検査処理確認モーダル表示
          this.confirmTitle = operation;
          this.confirmMessage = `${operation}します。よろしいですか？`;
          this.confirmOK = buttonLabel;
          this.modals.confirmDlg = true;


        }.bind(this));

    },

    async deleteAcceptance()
    {

      let loader = this.showLoader();

      try {
        for (let i=0; i<this.selectedDatas.length; i++){
          // 写真ファイル削除
          for (let j=0; j<this.selectedDatas[i].acceptance.photos.length; j++){
            let photoInfo = this.selectedDatas[i].acceptance.photos[j];
            let sKey = await this.getPhotoS3FullPath(photoInfo.photoName, this.selectedDatas[i].acceptance.acceptanceId);
            await this.deletePhotos(sKey);
          }

          // 削除処理
          await AcceptanceModel.deleteAcceptance(this.selectedDatas[i]);

          // ログ出力
          //appLog.infoLog("PlanEdit.vue", this.$store.state.user.userId, `【Delete】 Volume： pk[${this.selectedDatas[i].pk}]、sk[${this.selectedDatas[i].sk}]、到着拠点ID [${this.selectedDatas[i].volume.endAreaId}]、打設箇所 [${this.selectedDatas[i].volume.pouringPosition}]、予定数量（㎥） [${this.selectedDatas[i].volume.totalVolume}]`)
        }          
        // 一覧情報再取得
        this.getAcceptanceList();
        // 完了メッセージ
        this.showBottomToast(`受入検査の削除処理が完了しました。`, 'info')
        
      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`受入検査の削除処理に失敗しました。`, 'error')
        // appLog.errLog("PlanEdit.vue", this.$store.state.user.userId, `${e.message}`)
      } finally {
        this.hideLoader(loader);
      }

    },

    async onClickConfirmOK() {
      // 受入検査結果処理確認モーダル非表示
      this.modals.confirmDlg = false;

      // 削除モードの場合は削除処理を呼び出す
      if (this.actionMode == 1) {
        this.deleteAcceptance();
        return;
      }

      // ------------------------------------------------------------------------
      // 登録、編集処理
      // ------------------------------------------------------------------------
      let loader = this.showLoader();
      try {
        // 新規登録
        if (this.editValueItem.rowId == null){
          // 登録用のレコード作成
          let targetDate = DateUtil.dateStringBase(this.formData.inspectionDateTime, "YYYYMMDD");
          let regData = await AcceptanceModel.getNewData(this.projectId, targetDate);

          // lsiStr1(キー作成：acceptance#プロジェクトID\予定数量ID)
          regData.lsiStr1 = `acceptance#${this.projectId}#${this.volumeId}`

          // ダイアログ画面で指定した値をセット
          regData.acceptance.weather = this.formData.weather;
          regData.acceptance.inspectionDateTime = DateUtil.dateStringDateTime(this.formData.inspectionDateTime);
          if (this.formData.slump == "") {
            regData.acceptance.slump = null;
          } else {
            regData.acceptance.slump = this.formData.slump;
          }
          if (this.formData.chlorideQuantity == "") {
            regData.acceptance.chlorideQuantity = null;  
          } else {
            regData.acceptance.chlorideQuantity = this.formData.chlorideQuantity;
          }
          if (this.formData.airVolume == "") {
            regData.acceptance.airVolume = null;
          } else {
            regData.acceptance.airVolume = this.formData.airVolume;
          }
          if (this.formData.ct == "") {
            regData.acceptance.ct = null;
          } else {
            regData.acceptance.ct = this.formData.ct;
          }
          if (this.formData.at == "") {
            regData.acceptance.at = null;
          } else {
            regData.acceptance.at = this.formData.at;
          }        
          regData.acceptance.memo = this.formData.memo;

          // 写真をS3にアップロード + DB登録用のアップロードパスを保持 ※黒板写真
          if (this.uploadFileListBlackboard.length > 0) {
            // 写真のアップロード
            const s3KeyList = await this.uploadPhotos(this.uploadFileListBlackboard, regData.acceptance.acceptanceId);
            s3KeyList.forEach(item => {
              let pushItem = {photoType: 0, photoName: item };
              regData.acceptance.photos.push(pushItem);              
            });
          }

          // 写真をS3にアップロード + DB登録用のアップロードパスを保持
          if (this.uploadFileList.length > 0) {
            // 写真のアップロード
            const s3KeyList = await this.uploadPhotos(this.uploadFileList, regData.acceptance.acceptanceId);
            s3KeyList.forEach(item => {
              let pushItem = {photoType: 1, photoName: item };
              regData.acceptance.photos.push(pushItem);              
            });
          }

          // DB更新
          await AcceptanceModel.addAcceptance(regData)

        // 編集時
        } else {

          // ダイアログ画面で指定した値をセット            
          let editData = this.selectedData;
          console.log(`editData: ${JSON.stringify(editData)}`)
          editData.acceptance.weather = this.formData.weather;
          editData.acceptance.inspectionDateTime = DateUtil.dateStringDateTime(this.formData.inspectionDateTime);
          if (this.formData.slump == "") {
            editData.acceptance.slump = null;  
          } else {
            editData.acceptance.slump = this.formData.slump;
          }
          if (this.formData.chlorideQuantity == "") {
            editData.acceptance.chlorideQuantity = null;
          } else {
            editData.acceptance.chlorideQuantity = this.formData.chlorideQuantity;
          }
          if (this.formData.airVolume == "") {
            editData.acceptance.airVolume = null;
          } else {
            editData.acceptance.airVolume = this.formData.airVolume;
          }
          if (this.formData.ct == "") {
            editData.acceptance.ct = null;
          } else {
            editData.acceptance.ct = this.formData.ct;
          }
          if (this.formData.at == "") {
            editData.acceptance.at = null;
          } else {
            editData.acceptance.at = this.formData.at;
          }          
          editData.acceptance.memo = this.formData.memo;

          // 一覧に表示するために追加したカラムを削除
          delete editData["inspectionCnt"]
          delete editData["isPhoto"]

          // 写真データ
          editData.acceptance.photos.splice(0, editData.acceptance.photos.length)
          if (this.thumbListBeforeBlackboard.length > 0){
            if (this.thumbListBlackboard.length == 0){
              await this.deletePhotos(this.thumbListBeforeBlackboard[0].path);
            } else {
              // ファイル名取得
              let sepPaths = this.thumbListBeforeBlackboard[0].path.split("/");
              let fileName = sepPaths[sepPaths.length-1];
              // DB登録用にファイル名追加
              let pushItem = {photoType: 0, photoName: fileName };
              editData.acceptance.photos.push(pushItem);
            }
          }

          for (let i=0; i<this.thumbListBefore.length; i++) {
            let isExist = false;
            let targetPath = null;
            for (let j=0; j<this.thumbList.length; j++){
              if (this.thumbListBefore[i].path == this.thumbList[j].path){
                isExist = true;
                targetPath = this.thumbList[j].path;
                break;
              }
            }

            if (isExist){
              // ファイル名取得
              let sepPaths = targetPath.split("/");
              let fileName = sepPaths[sepPaths.length-1];
              // DB登録用にファイル名追加
              let pushItem = {photoType: 1, photoName: fileName };
              editData.acceptance.photos.push(pushItem);  
            } else {
              await this.deletePhotos(this.thumbListBefore[i].path);
            }              
          }            
          

          // 写真をS3にアップロード + DB登録用のアップロードパスを保持
          let targetDate = DateUtil.dateStringBase(this.formData.inspectionDateTime, "YYYYMMDD");
          if (this.uploadFileList.length > 0) {
            // 写真のアップロード
            let s3KeyList = await this.uploadPhotos(this.uploadFileList, editData.acceptance.acceptanceId);
            s3KeyList.forEach(item => {
              let pushItem = {photoType: 1, photoName: item };
              editData.acceptance.photos.push(pushItem);              
            });
          }

          if (this.uploadFileListBlackboard.length > 0) {
            // 写真のアップロード
            let s3KeyList = await this.uploadPhotos(this.uploadFileListBlackboard, editData.acceptance.acceptanceId);
            s3KeyList.forEach(item => {
              let pushItem = {photoType: 0, photoName: item };
              editData.acceptance.photos.push(pushItem);              
            });
          }          

          // DB更新
          await AcceptanceModel.updateAcceptance(editData)

        }

        // モーダル画面閉じる
        this.modals.editAcceptanceDlg = false;

        // 一覧情報再取得
        this.getAcceptanceList();

        // 完了メッセージ
        this.showBottomToast(`受入検査結果情報更新処理が完了しました。`, 'info')

        // ログ出力
        //appLog.infoLog("AcceptanceList.vue", this.$store.state.user.userId, `【Update】 Vehicle： pk[${updateVeihcle.pk}]、sk[${updateVeihcle.sk}]、車両名 [${updateVeihcle.vehicle.name}]、最大積載量（㎥） [${updateVeihcle.vehicle.maxCapacity}]、モバイルアプリで表示しない [${updateVeihcle.invalid}]、メモ [${updateVeihcle.vehicle.memo}]`)

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`受入検査結果情報更新処理に失敗しました。`, 'error')
        this.errorLog("onCliclConfirmOK", this.parseErrorObject(e))

        throw e
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 受入検査結果処理確認モーダル キャンセルボタンクリックイベント
     */
    onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },
    /**
     * 画像アップロード
     */
    async uploadPhotos(files, acceptanceId){
      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()
      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      this.tenantStorage = new TenantStorage(user.group)

      const s3KeyList = []
      try {
        // ファイル数分繰り返し
        for (let i = 0; i < files.length;i++) {
          // ファイルのバイナリ先頭4バイトからファイルタイプを判定して拡張子取得
          const ext = await Mime.getExt(files[i].raw)
          if (!ext) {
            alert(`拡張子判定不可`)
            break
          }
          // ファイル名生成
          const fileName = `${DateUtil.dateStringBase(new Date(), `YYYY-MM-DD_HH-mm-ss-SSS`)}.${ext}`
          // S3キーを構成
          //const s3Key = `tenant/${user.group}/acceptance/${this.projectId}/${targetDate}/${this.volumeId}/${fileName}`
          const s3Key = await this.getPhotoS3FullPath(fileName, acceptanceId);
          // アップロード
          await this.tenantStorage.put(s3Key, files[i].raw)

          s3KeyList.push(fileName)
        }
      } catch (e) {
        console.log(`アップロードエラー ${JSON.stringify(e)}`)
        throw e
      }
      return s3KeyList
    },    
    /**
     * 画像削除
     */
    async deletePhotos(sKey){
      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()
      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      this.tenantStorage = new TenantStorage(user.group);

      try {
        await this.tenantStorage.delete(sKey);      
      } catch (e) {
        console.log(`削除エラー ${JSON.stringify(e)}`)
        throw e
      }
    },    

    /**
     * テーブルの行選択時の処理
     */       
    handleSelectRow(selection, row) {

      if (this.userAuthority == 0)
      {
        this.$refs.AcceptanceTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length  
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; 
      } else {
        this.isEdit = false; 
      }

      // チェックＯＮ、ＯＦＦ
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      // 1件の時は、選択行にセット（チェックＯＦＦした結果、別の行の1件がカレント行になるように）
      if (selection.length == 1){
        this.selectedData = selection[0]
      }

      // 選択行保持
      this.selectedDatas = selection
    },
    /**
     * テーブルの全チェックボックスをチェックした時の処理
     */       
    handleSelectAllRow(selection) {

      if (this.userAuthority == 0){
        this.$refs.PlanTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 選択行数をセット
      this.selectedDataCnt = selection.length
      // 選択行数が1件の場合は、編集とコピーボタン表示 
      if (this.selectedDataCnt == 1){
        this.isEdit = true; 
      } else {
        this.isEdit = false;
      }
      // 選択行をセット
      this.selectedDatas = selection

    },
    /**
     * テーブル行から指定したidのインデックスを取得します。
     * @params {string} id ID
     * @returns {number} インデックス
     */
    getRowIndex(id) {
      // 対象のテーブル
      let dataList = this.acceptances;

      let currentIndex = dataList.findIndex(item => {
        return item.id == id
      })

      return currentIndex;
    },
    /**
     * 受入検査結果情報を保存する（変更行のソート番号更新）
     */
    async saveVehicle(nowIndex, afterIndex) {
      try {
        
        // レコード移動前のソート番号を更新
        let updateItem = this.acceptances[nowIndex]
        delete updateItem["invaliddDisp"]

        await VehicleModel.updateVehicle(updateItem).then(() => {
        })

        // レコード移動後のソート番号を更新
        updateItem = this.acceptances[afterIndex]
        delete updateItem["invaliddDisp"]
        await VehicleModel.updateVehicle(updateItem).then(() => {
        })

        // 表示・非表示再設定
        this.acceptances.forEach(item => {
          if (item.invalid == true){
            item.invaliddDisp = "表示しない"
          }
        });

      } catch (e) {
        this.showBottomToast(`車両一覧のソート順番保存処理に失敗しました。`, 'error')
        this.errorLog("saveVehicle", this.parseErrorObject(e))
        
        throw e
      }
    },
  
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },         
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */    
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },    
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true
      
      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      // store.datestring = this.datestring
 
      await this.$store.commit("setTimeline", store);      

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });        
    }, 
    onDeletePhoto(key) {
      console.log(key);
      this.thumbList.splice(key, 1)

      for (let i=0; i < this.thumbList.length; i++){
        this.thumbList[i].index = i;
      }

    },
    onDeletePhotoBlackBord(){
      this.thumbListBlackboard = [];
      this.isBlackBordPhoto = false;
    },

    /**
     * フォームのバリデーション(検査日時)
     */
    checkInspectionDateTime(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, true, false, false, false)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(スランプ（cm）)
     */
    checkSlump(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, false, false, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(塩化物量（kg/㎥）)
     */
    checkChlorideQuantity(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, false, false, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(空気量（%）)
     */
    checkAirVolume(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, false, false, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(コンクリート温度（℃）)
     */
    checkCt(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, false, false, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(外気温度（℃）)
     */
    checkAt(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, false, false, true, true)
      ) {
        callback();
      }
    },   
    /**
     * フォームのバリデーション(メモ)
     */
    checkMemo(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, this.memoLengh, false, false, false, true)
      ) {
        callback();
      }
    },
    disabledDate(time) {
      // console.log(time);
      // 当日以降の日付は選択不可とする
      let ymd = DateUtil.dateStringBase(time, "YYYYMMDD")
      console.log(`time: ${ymd}`)
      
      if (!this.availableDays[ymd]) {
        return true
      }
      return time.getTime() > Date.now();
    },
    changeCalender(range){
      console.log(`range:${JSON.stringify(range)}`)
    },
    /**
     * 日付選択で日付が変更された時に呼び出されます。
     */
    async changeDate(dateAfter) {
      
      let loader = null
      try {
        loader = this.showLoader()
        this.datestring = DateUtil.dateStringBase(dateAfter, "YYYY/MM/DD");
        
        // 受入検査編集用変数初期化
        this.initEditListItem();

        // 対象日付の受入検査情報取得
        const targetDate = DateUtil.dateStringBase(this.datestring, "YYYYMMDD");
        let listdata = await AcceptanceModel.getAcceptanceList(this.projectId, targetDate); 

        if (listdata.length > 0) {
          // 予定数量ID取得
          this.volumeId = listdata[0].lsiStr1.split('#')[2];

          // 予定ID取得
          let planItem = await PlandModel.getPlanListFromYMD(this.projectId, targetDate);

          // 予定IDから予定数量取得
          let searchKey = `${this.projectId}#${planItem[0].plan.id}`;
          let volumItems = await VolumeModel.getVolumeList(searchKey);

          // 取得した予定数量一覧から、該当予定数量取得
          const target = volumItems.find((item) => item.volume.id === this.volumeId);

          // 拠点名称取得
          searchKey = `${this.projectId}#${target.volume.endAreaId}`;
          let baseAreaItem = await BaseAreaModel.getBaseAreaList(searchKey);          
          let baseAreaName = `${baseAreaItem[0].baseArea.areaName} ${target.volume.pouringPosition}`;

          // タイトルセット
          this.title = "受入検査結果一覧（" + baseAreaName + "）";

          // 受入検査一覧取得
          await this.getAcceptanceList();
        }


      } finally {
        if (loader) {
          this.hideLoader(loader)
        }
        
      }
      
    },
    /**
     * CSV出力ボタンクリック時の処理
     */
    async onClickCSV() {
      if (!this.acceptances) {
        this.showBottomToast(`対象となる受入検査結果がありません。`, `warning`);
        return;
      }
      if (this.acceptances.length == 0) {
        this.showBottomToast(`対象となる受入検査結果がありません。`, `warning`);
        return;
      }

      // CSV出力処理へ
      if (confirm("受入検査結果ＣＳＶファイルを作成しますか？")) {
        this.showBottomToast(
          `CSV作成中です。このままでしばらくお待ちください。`,
          `info`
        );

        this.downloadCsv().then(() => {

        });

      }
    },

    /**
     * CSVダウンロード
     */
    async downloadCsv() {
      // 処理中
      let loader = this.showLoader();

      // CSVファイル名初期化
      let fileName = null;

      try {
        // 検査回数、写真のありを設定
        let cnt = 0;
        let csvDatas = []

        for (let i = 0;i < this.acceptances.length;i++){
          let item = this.acceptances[i].acceptance
          let csvItem = {
            inspectionCnt: null,
            inspectionDateTime: null,
            slump: null,
            chlorideQuantity: null,
            airVolume: null,
            ct: null,
            at: null,
            photoFileNames: null,
            memo: null
          }
          csvItem.inspectionCnt = ++cnt,
          csvItem.inspectionDateTime = item.inspectionDateTime,
          csvItem.slump = item.slump,
          csvItem.chlorideQuantity = item.chlorideQuantity,
          csvItem.airVolume = item.airVolume,
          csvItem.ct = item.ct,
          csvItem.at = item.at,
          
          csvItem.memo = item.memo

          //item.inspectionCnt = ++cnt;
          if (item.photos.length > 0){
            let photoNames = []
            for (let j = 0; j < item.photos.length;j++) {
              let photoItem = item.photos[j]
              photoNames.push(photoItem.photoName)
            }
            
            csvItem.photoFileNames = photoNames.join("|")
          }
          csvDatas.push(csvItem)
          console.log(`csvItem: ${JSON.stringify(csvItem)}`)
        }

        
        let columns = this.createCsvColumns();

        const csv = stringify(csvDatas, {
          header: true,
          quoted: true,
          record_delimiter: "windows",
          columns: columns,
        });
        // console.log(csv);

        // BOM
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);

        // CSVフォーマットの文字列をblobに
        let csvBlob = new Blob([bom, csv], { type: "text/csv" });

        // ブラウザにダウンロードダイアログを表示する処理
        const a = document.createElement("a");
        a.href = URL.createObjectURL(csvBlob);

        // ファイル名生成 (yyyymmdd_工区名打設箇所.csv)
        let targetDate = DateUtil.getFormatString(this.dateString, "YYYYMMDD");
        const regex = /[\\\/:\*\?\"<>\|]/ig;
        let targetPouring = this.title.replace(regex, "-")
        fileName =
          targetDate +
          "_" +
          targetPouring +
          ".csv";

        a.download = fileName;

        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        this.showBottomToast(`CSVを出力しました。`, `info`);
      } catch (e) {
        this.showBottomToast(`CSVの作成に失敗しました`, `error`);
        console.log(e)
        throw e;
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * CSV列情報（ヘッダー）生成
     */
    createCsvColumns() {
      // 固定部
      
      let columns = [
        { key: "inspectionCnt", header: "検査回数" },
        { key: "inspectionDateTime", header: "日付" },
        { key: "slump", header: "スランプ(cm)" },
        { key: "chlorideQuantity", header: "塩化物量(KG/㎥)" },
        { key: "airVolume", header: "空気量(%)" },
        { key: "ct", header: "コンクリート温度(℃)" },
        { key: "at", header: "外気温度(℃)"},
        { key: "memo", header: "備考" },
        { key: "photoFileNames", header: "検査写真ファイル名" },
        
      ];

      return columns;
    },
    createCsvData(acceptanceItem) {
      let csvDatas = [];
      /**
       * <el-table-column label="検査回数" min-width="80px" prop="inspectionCnt"></el-table-column>
          <el-table-column label="検査日時" min-width="200px" prop="acceptance.inspectionDateTime"></el-table-column>
          <el-table-column label="スランプ（cm）" min-width="120px" prop="acceptance.slump" ></el-table-column> 
          <el-table-column label="塩化物量（kg/㎥）" min-width="130px" prop="acceptance.chlorideQuantity" ></el-table-column>
          <el-table-column label="空気量（%）" min-width="120px" prop="acceptance.airVolume" ></el-table-column>
          <el-table-column label="コンクリート温度（℃）" min-width="200px" prop="acceptance.ct" ></el-table-column>
          <el-table-column label="外気温度（℃）" min-width="120px" prop="acceptance.at" ></el-table-column>
          <el-table-column label="写真" min-width="120px" prop="isPhoto" ></el-table-column>
          <el-table-column label="備考" min-width="250px" prop="acceptance.memo" ></el-table-column>
       */
      let row = {
        inspectionCnt: acceptanceItem.inspectionCnt,
        inspectionDateTime: acceptanceItem.inspectionDateTime,
        slump: acceptanceItem.slump,
        chlorideQuantity: acceptanceItem.chlorideQuantity,
        airVolume: acceptanceItem.airVolume,
        ct: acceptanceItem.ct,
        photoFileNames: acceptanceItem.photoFileNames,
        memo: acceptanceItem.memo
      }

      return csvDatas
    },
    async downloadBlackBordUrl(index) {
      // ユーザー情報
      const user = await UserInfo.getUserInfo()
      // 対象ファイルのs3keyを取得
      let tenantStorage = new TenantStorage(user.group) 
    
      const s3key = this.thumbListBlackboard[index].path
      console.log(`s3key: ${s3key}`)
      // 親フォルダからファイル一覧を取得して存在チェック
      const data = await tenantStorage.get(s3key);
      const url = URL.createObjectURL(new Blob([data.Body]))
      console.log(`url:${url}`)
      const fileName = path.basename(s3key)
      
      // ブラウザにダウンロードダイアログを表示する処理
      const a = document.createElement('a');

      a.href = url
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.downloadedFlgs[index] = false
    },
    async downloadImageUrl(index) {
      // ユーザー情報
      const user = await UserInfo.getUserInfo()
      // 対象ファイルのs3keyを取得
      let tenantStorage = new TenantStorage(user.group) 
    
      const s3key = this.thumbList[index].path
      console.log(`s3key: ${s3key}`)
      // 親フォルダからファイル一覧を取得して存在チェック
      const data = await tenantStorage.get(s3key);
      const url = URL.createObjectURL(new Blob([data.Body]))
      console.log(`url:${url}`)
      const fileName = path.basename(s3key)
      
      // ブラウザにダウンロードダイアログを表示する処理
      const a = document.createElement('a');

      a.href = url
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.downloadedFlgs[index] = false
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
