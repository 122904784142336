/**
 * 実績表示算出処理
 */

import DateUtil from "@/appUtils/DateUtil";
import PlanModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import DriveModel from "@/appModel/Drive/DriveModel";
import Decimal from 'decimal.js';
 
export default {
  data() {
    return {
    };
  },

  created() {
    this.hello();
  },

  methods: {
    hello() {
      console.log(`MixinActualResults!: ${this.vuename}`);
    },

    /**
     * 算出結果値の初期化
     */
    async initCalcValue() {
      this.estimate.volume = 0;
      this.pouringEnd.volume = 0;
      this.pouringEnd.number = 0;
      this.pouringOrWaiting.volume = 0;
      this.pouringOrWaiting.number = 0;
      this.pouringOrWaiting.lastTime = "-";
      this.transporting.volume = 0;
      this.transporting.number = 0;
      this.remain.volume = 0;
      this.remain.number = 0;
      this.achive.volume = 0;
      this.achive.number = 0;
    },

    /**
     * 実績表示算出処理
     */
    async calcActualResults(projectId, targetDate, volumeId) {

      // 算出結果値の初期化
      await this.initCalcValue();  

      // 予定取得
      let planList = await PlanModel.getPlanListFromYMD(projectId, targetDate);
      if (planList.length == 0) {
        return;
      }
      let planId = planList[0].plan.id;

      // 予定数量取得
      const searchKey = `${this.projectId}#${planId}`
      let listVolum = await VolumeModel.getVolumeList(searchKey); 

      // 選択した打設箇所に紐づく予定数量を取得。 
      let volume = listVolum.filter(function(item) {
        return item.volume.id == volumeId
      }.bind(this))

      let baseAreaId = ""
      let estimate = new Decimal(0)
      if (volume.length > 0) {
        // 打設予定
        estimate = new Decimal(volume[0].volume.totalVolume)
        this.estimate.volume = estimate.toNumber().toFixed(2);
        baseAreaId = volume[0].volume.endAreaId
      } else {
        return;
      }

      // 指定プロジェクト、日付、拠点でDBから運行情報(drive#)取得する。コンクリートの運搬のみ対象
      // その後、打設箇所でフィルターする
      let currentDrives = (await DriveModel.getDriveListForSignage(projectId, targetDate, baseAreaId)).filter(function(item) {
        return item.drive.pouringPosition == volume[0].volume.pouringPosition && item.drive.carryType == "1"
      })

      // ------------------------------
      // 打設完了 設定
      // ------------------------------
      // 打設が終了したレコードを抽出
      let calcVolume = new Decimal(0.0).toNumber()
      let calcNumber = 0
      const pouringEndList = currentDrives.filter(function(item) {
        return item.drive.pouringEndTime != null && item.drive.pouringEndTime != ''
      }.bind(this))

      // 抽出したレコードから最大積載量と台数を計算
      pouringEndList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
      })

      // 表示用変数に値を設定
      this.pouringEnd.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.pouringEnd.number = calcNumber

      // ------------------------------
      // 打設/待機 設定
      // ------------------------------
      // 現場入場もしくは打設中レコードを抽出
      calcVolume = new Decimal(0.0).toNumber()
      calcNumber = 0
      let lastStartTime = '-'
      const pouringOrWaitingList = currentDrives
        .filter(function(item) {
          return ((item.drive.siteEndTime && !item.drive.pouringStartTime && !item.drive.pouringEndTime)
            || (item.drive.siteEndTime && item.drive.pouringStartTime && !item.drive.pouringEndTime))
        }.bind(this))
        .slice()
        .sort(function(a, b) {
          if (a.drive.pouringStartTime < b.drive.pouringStartTime) return -1;
          if (a.drive.pouringStartTime > b.drive.pouringStartTime) return 1;
        })

      // 抽出したレコードから最大積載量と台数を計算
      pouringOrWaitingList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
        if (element.drive.pouringStartTime) {
          lastStartTime = DateUtil.getFormatString(element.drive.pouringStartTime, "HH:mm")
        }
      });

      // 表示用変数に値を設定
      this.pouringOrWaiting.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.pouringOrWaiting.number = calcNumber
      this.pouringOrWaiting.lastTime = lastStartTime

      // ------------------------------
      // 運搬中 設定
      // ------------------------------
      // 運搬中のレコードを抽出
      calcVolume = new Decimal(0.0).toNumber()
      calcNumber = 0
      const transportingList = currentDrives.filter(function(item) {
        return item.drive.factoryStartTime && !item.drive.siteEndTime && !item.drive.pouringStartTime && !item.drive.pouringEndTime
      }.bind(this))

      // 抽出したレコードから最大積載量と台数を計算
      transportingList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
      })

      // 表示用変数に値を設定
      this.transporting.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.transporting.number = calcNumber      

      // ------------------------------
      // 実績 設定
      // ------------------------------
      //残量の計算。打設完了時刻のあるレコードを積算、予定量との差を出す--------------
      let transportEndDrives = currentDrives.filter(function(item) {
        //this.debugLog(`残量計算 ${JSON.stringify(item, null, "\t")}`)
        return item.drive.pouringPosition == volume[0].volume.pouringPosition && 
        (item.drive.pouringEndTime != "" && item.drive.pouringEndTime != null)
      }.bind(this))
      //this.debugLog(`運搬完了: ${transportEndDrives.length}`)
    
      let transportEndVolume = new Decimal(0);
      transportEndDrives.forEach(function(item) {
    
        let _volume = new Decimal(item.drive.maxCapacity)
        transportEndVolume = transportEndVolume.plus(_volume)
      })

      // 打設量実績----------------------------
      this.achive.volume = transportEndVolume.toNumber().toFixed(2);
      // 打設台数
      this.achive.number = transportEndDrives.length

      // ------------------------------
      // 残出荷数  設定
      // ------------------------------
      const remainList = currentDrives.slice().sort(function(a, b) {
        if (a.drive.maxCapacity > b.drive.maxCapacity) return -1;
        if (a.drive.maxCapacity < b.drive.maxCapacity) return 1;
      })
      let maxCapacity = 0.0
      if (remainList != null && remainList.length > 0) {
        maxCapacity = remainList[0].drive.maxCapacity
      }
      const remainVolume = this.estimate.volume - this.pouringEnd.volume - this.pouringOrWaiting.volume - this.transporting.volume 
      const remainNumber = maxCapacity == 0 ? 0 : Math.ceil(remainVolume / maxCapacity);
      this.remain.volume = new Decimal(remainVolume).toNumber().toFixed(2)
      this.remain.number = remainNumber < 0 ? 0: remainNumber;
    },

  },
};
 