<template>
  <div ref="mapContainer" class="map-container">
    <div id="map" class="map"></div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
  /* border:1px solid green; */
}

.map {
  /* width: 500px;
    height: 500px; */

  width: 100%;
  height: 100%;
  /* border:1px solid blue; */
}

/* :deep .leaflet-interactive {
  cursor: grab !important;
} */
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"

// // data()内に保持するとLeafletの挙動がおかしくなります。
// let mlMap = null;

export default {
  components: {},
  data() {
    return {
      drivehistoryList: [],
      initdrivehistoryList: [],
    };
  },

  computed: {
    vuename() {
      return this.$route.name;
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後

    // ※画面表示に関連しないメンバ変数を定義

    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`);

    // 地図生成
    this.init();

    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();
  },

  unmounted() {
    // Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.remove();
    }
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      console.log(`onResize`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },

    init() {
      //console.log("init")

      // 地図生成
      this.mapCreate();
    },

    /**
     * 地図の作成
     */
    mapCreate() {
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // --------------------
      // レイヤ追加
      // --------------------

      let bounds = [];
      bounds = this.addLayers();

      // --------------------
      // 表示オブジェクト追加
      // --------------------

      // 走行履歴のアイコンプロットメソッドは、親（DrivingHistoryから呼ばれる）

      // --------------------
      // コントロール
      // --------------------

      this.mlMap.setLayerControlVisible(true);
      this.mlMap.setMapInfoControlVisible(true);

      // --------------------
      // 初期表示位置へ移動
      // --------------------

      // 走行履歴のアイコンプロットメソッド内で初期表示位置に移動。

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();
    },

    /**
     * レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addLayers() {
      // 初期表示領域
      let bounds = [];

      // 背景レイヤの追加
      bounds = this.addBaseLayers();

      // アイコンレイヤ群追加
      this.addPoiLayers();

      return bounds;
    },

    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      let bounds = [];
      let ext = ``;

      // 地図
      bounds = this.addGsiLayers();

      return bounds;
    },

    /**
     * 国土地理院タイル地図レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addGsiLayers() {
      this.mlMap = null;
      this.mlMap = new MLMap("map", MLMap.VIEW_TYPE.MAP);

      this.mlMap.addGsiPaleLayer("地理院タイル 淡色地図", { opacity: 0.9 });
      this.mlMap.addGsiStdLayer("地理院タイル 標準地図", { opacity: 0.65 });
      this.mlMap.addGsiPhotoLayer("地理院タイル 写真", { opacity: 0.8 });

      this.mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([25, 120], [45, 150]);
      // const bounds = L.latLngBounds([-90, -180], [90, 180]);
      this.mlMap.setMaxBounds(bounds.pad(0.25));

      return bounds;
    },

    /**
     * アイコンレイヤ群を追加します。
     */
    addPoiLayers() {
      // 走行履歴レイヤ追加
      this.addDriveLocationLayers();
    },

    /**
     * 走行履歴レイヤを追加します。
     */
    addDriveLocationLayers() {
      // ラベルスタイル
      const poiLabelStyle = {
        color: "#263238",
        weight: 2,
        fillColor: "#FFFFFF",
        fontSize: 24,
        textBaseline: "middle",
      };

      const types = [
        {
          layerName: "走行履歴",
          iconUrl: "./img/concrete/ic_circle_red.png",
          iconSize: [48, 48],
          interactive: false,
        },
      ];

      for (let i = 0; i < types.length; i++) {
        const type = types[i];

        // アイコン種別
        let poiStyle = {
          iconUrl: type.iconUrl,
          iconSize: type.iconSize,
          interactive: type.interactive,
        };

        let layerName = type.layerName;

        // 走行履歴レイヤ追加
        this.mlMap.addPoiLayer(layerName, poiStyle, poiLabelStyle);
        // this.$nextTick(
        //   function() {
        //     // 走行履歴レイヤ追加
        //     this.mlMap.addPoiLayer(layerName, poiStyle, poiLabelStyle);
        //   }.bind(this)
        // );
      }
    },

    /**
     * 走行履歴位置をプロットし、地図を移動します
     * @param items [] 走行履歴情報
     */
    plotDrivingHistory(items) {
      // 現在表示走行履歴アイコンのクリア
      this.drivehistoryList.forEach((item) => {
        this.mlMap.removePoiById("走行履歴", item.id);
      });

      // 走行履歴情報から、アイコンをプロット
      this.drivehistoryList = [];
      for (let i = 0; i < items.length; i++) {
        // レイヤ名
        let layerName = "走行履歴";

        // アイコン図形をプロットを追加
        this.mlMap.addPoi(layerName, {
          id: items[i].id,
          x: items[i].x,
          y: items[i].y,
          label: items[i].id,
          layerName: layerName,
        });

        this.drivehistoryList.push(items[i]);
      }

      // アイコンをプロットした場合は、アイコンの位置に地図移動
      if (items.length > 0) {
        // 地図の表示領域を取得
        let bounds = this.getPoiBounds(items);

        // 地図移動
        this.mlMap.fitBounds(bounds);
      }
    },

    /**
     * 図形情報からBBX領域を取得します。
     *
     * @returns BBX領域
     */
    // async getPoiBounds() {
    getPoiBounds(items) {
      // 基準領域
      let bounds = L.latLngBounds(
        [items[0].y, items[0].x],
        [items[0].y, items[0].x]
      );

      // 走行履歴
      bounds = this.getBbx(items, bounds);

      // 16px分バッファ
      const min = bounds.getSouthWest();
      const bufferMin = this.mlMap.latLngToBounds(min, 16);
      const max = bounds.getNorthEast();
      const bufferMax = this.mlMap.latLngToBounds(max, 16);

      bounds = L.latLngBounds(
        bufferMin.getSouthWest(),
        bufferMax.getNorthEast()
      );

      return bounds;
    },

    /**
     * 図形情報からBBX領域を取得します。
     *
     * @param {Array} points 座標リスト
     * @param {Object} bounds 比較元領域
     * @returns BBX領域
     */
    getBbx(points, bounds) {
      let minX = bounds.getWest();
      let minY = bounds.getSouth();
      let maxX = bounds.getEast();
      let maxY = bounds.getNorth();

      points.forEach((item) => {
        if (item.x < minX) {
          minX = item.x;
        }

        if (item.y < minY) {
          minY = item.y;
        }

        if (item.x > maxX) {
          maxX = item.x;
        }

        if (item.y > maxY) {
          maxY = item.y;
        }
      });

      return L.latLngBounds([minY, minX], [maxY, maxX]);
    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // // サイズ変更
      // this.initMapEventResize();
      // クリック
      // this.initMapEventClick();
      // 作図開始/終了
      // this.initMapEventShapeDrawStartEnd();
      // 作図完了
      // this.initMapEventShapeDrawFinish();
      // 図形削除
      // this.initMapEventShapeDelete();
      // 地図移動後
      // this.mlMap.on('moveend', (e) => {
      //   let bounds = e.target.getBounds();
      //   console.log(`[${e.type}] bounds: ${bounds}`);
      //   this.saveBounds(bounds);
      // });
      // 縮尺変更後
      // this.mlMap.on('zoomend', (e) => {
      //   let bounds = e.target.getBounds();
      //   console.log(`[${e.type}] bounds: ${bounds}`);
      //   this.saveBounds(bounds);
      // });
    },
  },
};
</script>
