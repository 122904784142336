const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel");
const { Static } = require("vue");

/**
 * 岩種用のデータ取得モデルクラス
 *
 */
class RockModel extends baseModel {
  /**
  * テナント内の岩種リストを取得する
  *  @param {テナント(グループ)} tenantId
  *  @returns
  */
  static async getRockList(){
    return await super.getList({ beginsWith: `rocktype`})
  }

  /**
  *岩種レコードを削除する
  * @param {pk:テナントID, sk:sk}
  * @returns
  */
  static async deleteRock(data){
    await super.deleteData(data)
  }

  /**
   * 岩種レコードを追加する
   * @param {*} data
   */
  static async addRock(data){
    await super.addData(data)
  }

  /**
   * 岩種レコードを更新する
   *  @param {*} data
   */
  static async updateRock(data){
    await super.updateData(data)
  }

  /**
   * 岩種レコードを取得する
   *  @param {*} data
   */
  static async getRock(data){
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static crreateSk(rocktypeId){
    //rocktype#岩種ID(uuidで採番)
    return `rocktype#${rocktypeId}`
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(){
    let newRowData = await super.getNewData()

    // 現在のレコード数取得
    let currentList = await this.getRockList()
    let newOrder = 0
    if (currentList) {
      newOrder = currentList.length + 1
    }
    
    const id = short.generate()
    newRowData.sk = this.crreateSk(id)
    
    newRowData.rocktype = {
      id: id,
      name: "",
      loadCapacity: 0,
      order: newOrder
    }
    return newRowData
  }
}

module.exports = RockModel;