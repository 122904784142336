<template>
  <!-- <div> -->
  <div class="app-header d-flex flex-sm-wrap" :class="{'bg-zando': isZando}">
    <div class="d-flex flex-lg-fill align-items-center my-0">
      <div class="ml-2 mr-2 mt-2" v-if="title">
        <h3 style="color:white">{{ title }}</h3>
      </div>
      <div class="ml-2" v-if="isDatePicker">
        <el-date-picker
          class="ml-0"
          v-model="dateString"
          type="date"
          :clearable="false"
          size="medium"
          format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          :shortcuts="shortcuts"
          placeholder=""
          @change="changeDate($event)"
        >
        </el-date-picker>
      </div>
      <div class="ml-0 mt-2 mr-2" v-if="isNowTime && isDatePicker">
        <div v-if="!refreshdisabled">
          <!-- <h3 style="color:white">{{timeString}} 時点の位置 ({{refreshIntervalSecond / 60}}分間隔) -->
          <h3 style="color:white">
            {{ timeString }} 時点の位置 (<a
              href="#!"
              @click.prevent="onChangeMapInterval"
              >{{ refreshIntervalSecondValue / 60 }}分間隔</a
            >)
            <el-tooltip
              class="item"
              effect="dark"
              content="位置情報更新"
              placement="bottom"
            >
              <el-button
                type="primary"
                size="small"
                style="font-weight:bold;"
                :class="{'bg-zando-dark': isZando}"
                circle
                icon="fas fa-redo fa-lg"
                @click="onRefreshLocation"
              >
              </el-button>
            </el-tooltip>
          </h3>
        </div>
        <div v-if="refreshdisabled">
          <!-- <h3 style="color:white">{{timeString}} 時点の位置 ({{refreshIntervalSecond / 60}}分間隔) -->
          <h3 style="color:white">
            {{ timeString }} 時点の位置 (<a
              href="#!"
              @click.prevent="onChangeMapInterval"
              >{{ refreshIntervalSecondValue / 60 }}分間隔</a
            >)
            <el-tooltip
              class="item"
              effect="dark"
              content="位置情報更新"
              placement="bottom"
            >
              <el-button
                type="primary"
                size="small"
                style="font-weight:bold;"
                circle
                icon="fas fa-redo fa-lg"
                @click="onRefreshLocation"
                disabled
              >
              </el-button>
            </el-tooltip>
          </h3>
        </div>
      </div>
      <div class="mr-2 timeLineModeAreaList" v-if="isDatePicker">
        <el-select
          v-model="pouringPosition"
          :loading="isLoading"
          loading-text="お待ち下さい"
          placeholder="予定数量未登録"
          @change="chengePouringPosition"
        >
          <el-option
            v-for="item in signagePouringList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mr-0" v-if="isDatePicker">
        <!-- <el-tooltip class="item" effect="dark" content="実績表示" placement="bottom"> -->
        <el-button type="result" icon="el-icon-tickets" @click="onShowResult"
          >実績表示</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div
        v-if="isSignageMode"
        class="d-flex flex-lg-fill align-items-center"
        :class="{
           'justify-content-end': !isZando,
          'justify-content-start': isZando
        }"
      >
        <h3 
          v-if="!isZando" 
          style="color:white;font-size:2.3em;" 
          class="mb-0"
          >
          {{ signageDateString }}
        </h3>
        <h3 
          v-if="isZando" 
          style="color:white;font-size:2.3em;margin-left:2% " 
          class="mb-0"
          >
          {{ signageDateString }}
        </h3>

        <div class="mt-1 mb-1 ml-2 signageModeAreaList" v-if="!isZando">
          <base-input name="pouringPosition">
            <el-select
              size="large"
              :loading="isLoading"
              loading-text="お待ち下さい"
              v-model="pouringPosition"
              filterable
              @change="chengePouringPosition"
              allow-create
              placeholder="予定数量未登録"
            >
              <el-option
                v-for="item in signagePouringList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-if="!refreshdisabled && !isZando">
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="実績表示更新"
            placement="bottom"
          >
            <el-button
              type="primary"
              size="large"
              style="font-weight:bold;"
              circle
              icon="fas fa-redo fa-lg"
              @click="onRefreshSignage"
            >
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="!refreshdisabled && isZando">
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="実績表示更新"
            placement="bottom"
          >
            <el-button
              type="primary"
              size="large"
              style="font-weight:bold;"
              circle
              icon="fas fa-redo fa-lg"
              @click="onRefreshSignage"
            >
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="refreshdisabled && !isZando">
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="実績表示更新"
            placement="bottom"
          >
            <el-button
              type="primary"
              size="large"
              style="font-weight:bold;"
              circle
              icon="fas fa-redo fa-lg"
              @click="onRefreshSignage"
              disabled
            >
            </el-button>
          </el-tooltip>
        </div>
        <div v-if="refreshdisabled && isZando">
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="実績表示更新"
            placement="bottom"
          >
            <el-button
              type="primary"
              size="large"
              style="font-weight:bold;"
              circle
              icon="fas fa-redo fa-lg"
              @click="onRefreshSignage"
              disabled
            >
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <!-- <div class="mr-4">
          <el-button type="primary" size="large" circle icon="el-icon-refresh-right" @click="onRefreshLocation">
          </el-button>
        </div> -->
    </div>
    <div
      v-if="mobileView && false"
      class="d-flex flex-lg-fill align-items-center justify-content-end my-2"
    >
      <div class="mr-0" v-if="isDriveSpot">
        <el-tooltip
          class="item"
          effect="dark"
          content="運行スポット"
          placement="bottom"
        >
          <el-button
            type="lightprimary"
            icon="far fa-map-marker-exclamation"
            @click="onDriveSpot"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isCreateQRCode">
        <el-tooltip
          class="item"
          effect="dark"
          content="運行用QR"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="fas fa-qrcode"
            @click="onCreateQRCode"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isEditBase">
        <el-tooltip
          class="item"
          effect="dark"
          content="拠点編集"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="ni ni-square-pin"
            @click="onEditBase"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isEditVehicle">
        <el-tooltip
          class="item"
          effect="dark"
          content="車両編集"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="el-icon-truck"
            @click="onEditVehicle"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isCreatePlan">
        <el-tooltip
          class="item"
          effect="dark"
          content="予定数量"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="far fa-calendar-plus"
            @click="onCreatePlan"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isDriveLog">
        <el-tooltip
          class="item"
          effect="dark"
          content="走行履歴"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="el-icon-time"
            @click="onDriveLog"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isOutputCsv">
        <el-tooltip
          class="item"
          effect="dark"
          content="CSV出力"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="fas fa-file-export"
            @click="onOutputCsv"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="mr-0" v-if="isDriveVue">
        <el-tooltip
          class="item"
          effect="dark"
          content="運行情報画面"
          placement="bottom"
        >
          <el-button
            type="primary"
            icon="fas fa-arrow-right"
            @click="onDriveVue"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="!mobileView && false"
      class="d-flex flex-lg-fill align-items-center justify-content-end my-2"
    >
      <div class="mr-0" v-if="isDriveSpot">
        <!-- <el-tooltip class="item" effect="dark" content="運行スポット登録" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="fas fa-map-pin"
          @click="onDriveSpot"
          >運行スポット</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isCreateQRCode">
        <!-- <el-tooltip class="item" effect="dark" content="運行用QRコード" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="fas fa-qrcode"
          @click="onCreateQRCode"
          >運行用QR</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isEditBase">
        <!-- <el-tooltip class="item" effect="dark" content="拠点編集" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="ni ni-square-pin"
          @click="onEditBase"
          >拠点編集</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isEditVehicle">
        <!-- <el-tooltip class="item" effect="dark" content="車両編集" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="el-icon-truck"
          @click="onEditVehicle"
          >車両編集</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isCreatePlan">
        <!-- <el-tooltip class="item" effect="dark" content="予定数量登録" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="far fa-calendar-plus"
          @click="onCreatePlan"
          >予定数量登録</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isDriveLog">
        <!-- <el-tooltip class="item" effect="dark" content="走行履歴" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="el-icon-time"
          @click="onDriveLog"
          >走行履歴</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="mr-0" v-if="isOutputCsv">
        <!-- <el-tooltip class="item" effect="dark" content="CSV出力" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton mr1px"
          icon="fas fa-file-export"
          @click="onOutputCsv"
          >CSV出力</el-button
        >
        <!-- </el-tooltip> -->
      </div>
      <div class="pr-3" v-if="isDriveVue">
        <!-- <el-tooltip class="item" effect="dark" content="運行情報画面" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton"
          icon="fas fa-arrow-right"
          @click="onDriveVue"
          >運行情報画面</el-button
        >
        <!-- </el-tooltip> -->
      </div>
    </div>

    <!-- ドロップダウンメニュー -->
    <!-- <div class="pr-3" v-if="isDriveVue">
      <el-button
        type="primary"
        class="headerButton"
        icon="fas fa-arrow-right"
        @click="onDriveVue"
        >運行情報画面</el-button
      >
    </div> -->

    <!-- PC表示時のメニュー -->
    <div
      v-if="!mobileView"
      class="d-flex flex-lg-fill align-items-center justify-content-end my-2"
    >
      <base-dropdown
        menu-on-right
        menuClasses="main-menu"
        class="pr-3"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
        v-if="!isDriveVue"
      >
        <template v-slot:title-container>
          <el-button
            type="primary"
            class="headerButton pl-2 pr-2"
            icon="fas fa-bars"
            @click.prevent
            ><span class="font-weight-bold ml-2 mr-2">メニュー</span></el-button
          >
        </template>
        <!-- 拠点編集 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="車両の入場、出場を検知するエリアを作成"
          placement="left"
          v-if="isEditBase"
        >
          <a class="dropdown-item" @click="onEditBase">
            <i class="ni ni-square-pin pr-0 pl-1 w30p"></i>拠点編集</a
          ></el-tooltip
        >
        <!-- 予定数量登録 -->
        <el-tooltip
          class="item"
          effect="dark"
          :content= "isZando ? soilPlannedQuantity : plannedQuantity"
          placement="left"
          v-if="isCreatePlan"
        >
          <a class="dropdown-item" @click="onCreatePlan">
            <i class="far fa-calendar-plus pr-0 pl-1 w30p"></i>予定数量登録</a
          ></el-tooltip
        >
        <!-- 運行スポット -->
        <el-tooltip
          class="item"
          effect="dark"
          content="車両通過時に音声案内を行うポイントを作成"
          placement="left"
          v-if="isDriveSpot"
        >
          <a class="dropdown-item" @click="onDriveSpot">
            <i class="fas fa-map-pin pr-0 pl-2 w30p font-mdl"></i
            >運行スポット</a
          ></el-tooltip
        >

        <hr class="mt-1 mb-1" v-if="isCreateQRCode || isDriveBulkSetting" />
        <!-- 運行用QR -->
        <el-tooltip
          class="item"
          effect="dark"
          content="アプリ用の運行設定QRコードの作成"
          placement="left"
          v-if="isCreateQRCode"
        >
          <a class="dropdown-item" @click="onCreateQRCode">
            <i class="fas fa-qrcode pr-0 pl-1 w30p"></i>運行用QR</a
          ></el-tooltip
        >
        <!-- 運行先一括設定 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="ログイン済み端末に一斉に運行設定"
          placement="left"
          v-if="isDriveBulkSetting"
        >
          <a class="dropdown-item" @click="onDriveBulkSetting">
            <i class="fas fa-bolt pr-0 pl-1 w30p"></i>運行先一括設定</a
          ></el-tooltip
        >

        <hr class="mt-1 mb-1" v-if="isAcceptanceList || isOutputCsv" />
        <!-- 受入検査 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="登録されている受入検査結果の確認"
          placement="left"
          v-if="isAcceptanceList"
        >
          <a class="dropdown-item" @click="onAcceptanceList">
            <i class="fas fa-clipboard-check pr-0 pl-1 w30p"></i>受入検査</a
          ></el-tooltip
        >
        <!-- CSV出力 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="運行情報をCSVに出力します"
          placement="left"
          v-if="isOutputCsv"
        >
          <a class="dropdown-item" @click="onOutputCsv">
            <i class="fas fa-file-export pr-0 pl-1 w30p"></i>CSV出力</a
          ></el-tooltip
        >

        <hr class="mt-1 mb-1" v-if="isEditVehicle || isDriveLog" />
        <!-- 車両編集 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="運行中の車両番号の確認と編集"
          placement="left"
          v-if="isEditVehicle"
        >
          <a class="dropdown-item" @click="onEditVehicle">
            <i class="el-icon-truck pr-0 pl-0 w30p"></i>車両編集</a
          ></el-tooltip
        >
        <!-- 走行履歴 -->
        <el-tooltip
          class="item"
          effect="dark"
          content="車両の走行履歴を表示"
          placement="left"
          v-if="isDriveLog"
        >
          <a class="dropdown-item" @click="onDriveLog">
            <i class="el-icon-time pr-0 pl-0 w30p"></i>走行履歴</a
          ></el-tooltip
        >
      </base-dropdown>
      <div class="pr-3" v-if="isDriveVue">
        <!-- <el-tooltip class="item" effect="dark" content="運行情報画面" placement="bottom"> -->
        <el-button
          type="primary"
          class="headerButton"
          icon="fas fa-arrow-right"
          @click="onDriveVue"
          >運行情報画面</el-button
        >
        <!-- </el-tooltip> -->
      </div>
    </div>

    <div v-if="false" class="d-flex align-items-center">
      <div class="appmenu rounded" style="width:200px; height:41px;">
        <el-menu
          :default-active="1"
          class="namacon-app-menu"
          mode="horizontal"
          background-color="#ED7D31"
          :ellipsis="false"
          text-color="white"
          @select="handleSelect"
        >
          <el-sub-menu index="1" class="h30 text-right">
            <template #title><span class="font-mdl">メニュー</span></template>
            <el-menu-item index="1-0" class="font-mdl">
              <i class="fas fa-home pr-0 pl-1 w30p"></i>運行情報
            </el-menu-item>
            <el-menu-item index="1-1" class="font-mdl"
              ><i class="fas fa-map-pin pr-0 pl-2 w30p font-mdl"></i
              >運行スポット</el-menu-item
            >
            <el-menu-item index="1-2" class="font-mdl"
              ><i class="ni ni-square-pin pr-0 pl-1 w30p"></i
              >拠点編集</el-menu-item
            >
            <el-menu-item index="1-3" class="font-mdl"
              ><i class="el-icon-truck pr-0 pl-0 w30p"></i
              >車両編集</el-menu-item
            >
            <el-menu-item index="1-4" class="font-mdl"
              ><i class="far fa-calendar-plus pr-0 pl-1 w30p"></i
              >予定数量登録</el-menu-item
            >
            <el-menu-item index="1-5" class="font-mdl"
              ><i class="fas fa-qrcode pr-0 pl-1 w30p"></i
              >運行用QR</el-menu-item
            >
            <el-menu-item index="1-6" class="font-mdl" v-if="isNamacon"
              ><i class="fas fa-clipboard-check pr-0 pl-1 w30p"></i
              >受入検査</el-menu-item
            >
            <el-menu-item index="1-7" class="font-mdl"
              ><i class="fas fa-route pr-0 pl-1 w30p"></i
              >運行先一括設定</el-menu-item
            >
            <el-menu-item index="1-8" class="font-mdl"
              ><i class="el-icon-time pr-0 pl-0 w30p"></i>走行履歴</el-menu-item
            >
            <el-menu-item index="1-9" class="font-mdl"
              ><i class="fas fa-file-export pr-0 pl-1 w30p"></i
              >CSV出力</el-menu-item
            >
          </el-sub-menu>
        </el-menu>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>


<style lang="scss" src="./css/ApplicationHeader-scoped.scss" scoped />
<style lang="scss" src="./css/ApplicationHeader-global.scss" />

<style v-if="isZando" lang="scss" src="./css/ApplicationHeaderZando-scoped.scss" scoped />
<style v-if="isZando" lang="scss" src="./css/ApplicationHeaderZando-global.scss" />

<script>
import DateUtil from "@/appUtils/DateUtil";
//import { onMounted } from "@vue/runtime-core";
import appLog from "@/appUtils/AppLog";
import DriveModel from "@/appModel/Drive/DriveModel"
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {},
  mixins: [UseApps, Logger],
  props: {
    /**
     * 日付文字列
     */
    dateStringProp: {
      type: String,
      // default: DateUtil.dateStringBase(new Date(), 'YYYY/MM/DD'),
    },

    /**
     * 位置情報更新間隔(分)
     * ローカルストレージに利用者単位で設定します。(※初期開発は1分固定予定)
     */
    // updateIntervalMinutes: {
    //   type: Number,
    //   default: 1,
    // },

    /**
     * 日付選択表示フラグ
     */
    isDatePicker: {
      type: Boolean,
      default: true,
    },

    /**
     * 現在時刻（＋位置情報更新ボタン）表示フラグ
     */
    isNowTime: {
      type: Boolean,
      default: true,
    },

    /**
     * 運行スポット表示フラグ
     */
    isDriveSpot: {
      type: Boolean,
      default: false,
    },

    /**
     * 運行用QRコード表示フラグ
     */
    isCreateQRCode: {
      type: Boolean,
      default: false,
    },
    /**
     * 拠点編集表示フラグ
     */
    isEditBase: {
      type: Boolean,
      default: true,
    },

    /**
     * 車両編集表示フラグ
     */
    isEditVehicle: {
      type: Boolean,
      default: true,
    },

    /**
     * 予定数量登録表示フラグ
     */
    isCreatePlan: {
      type: Boolean,
      default: true,
    },

    /**
     * 走行履歴表示フラグ
     */
    isDriveLog: {
      type: Boolean,
      default: false,
    },

    /**
     * CSV出力表示フラグ
     */
    isOutputCsv: {
      type: Boolean,
      default: true,
    },
    /**
     * 運行情報画面表示フラグ
     */
    isDriveVue: {
      type: Boolean,
      default: false,
    },
    /**
     * 一括運行設定表示フラグ
     */
    isDriveBulkSetting: {
      type: Boolean,
      default: false,
    },
    /**
     * 受入検査表示フラグ
     */
    isAcceptanceList: {
      type: Boolean,
      default: false,
    },
    /**
     * 表示タイトル
     */
    title: {
      type: String,
      default: "",
    },

    isSignageMode: {
      type: Boolean,
      default: false,
    },

    /**
     * 地図再描画間隔（秒）
     */
    refreshIntervalSecond: {
      type: Number,
      default: 60,
    },

    /**
     * 予定数量の打設箇所ID
     */
    volumeId: {
      type: String,
      default: "",
    },
  },

  // setup() {
  //   // モバイル/PC切り替え
  //   let mobileView = true;

  //   // window幅
  //   let windowWidth = 0;

  //   const calculateWindowWidth = () => {
  //     windowWidth = window.innerWidth;
  //     console.log(`windowWidth:${windowWidth}`);

  //     if (windowWidth < 768) {
  //       mobileView = true;
  //     } else {
  //       mobileView = false;
  //     }
  //     console.log(`mobileView:${mobileView}`);
  //     return mobileView;
  //   }

  //   // resizeでウィンドウサイズ変更を検知
  //   onMounted(() => {
  //     window.addEventListener('resize', calculateWindowWidth);
  //   });

  //   return {
  //     // mobileView,
  //   }
  // },

  data() {
    return {
      /**
       * 時刻表示更新間隔(秒)（※位置情報表示更新間隔ではない）
       */
      timeIntervalSecond: 60,

      /**
       * 位置情報更新更新間隔(秒)
       * 初期値：60
       */
      refreshIntervalSecondValue: this.refreshIntervalSecond,

      /**
       * 日付文字列
       */
      dateString: "",
      // dateString: this.dateStringProp,

      /**
       * 時刻文字列
       */
      timeString: DateUtil.dateStringBase(new Date(), "HH:mm"),

      /**
       * 当日フラグ(falseの場合は過去履歴表示（※未来の場合も表示は過去履歴とする)
       */
      isToday: true,

      /**
       * 日付選択オプション
       */
      datePickerOptions: {
        disabledDate: this.disabledDate,
      },
      /**
       * 時刻表用間隔ID
       */
      timerIntervalId: undefined,
      /**
       * 表示更新用ID
       */
      refreshIntervalId: undefined,
      /**
       * 運行情報表示/非表示tooltip文字列
       */
      listOnOffContent: `運行情報を非表示`,
      /**
       * 運行情報表示/非表示
       */
      isTimelineShow: true,
      /**
       * 位置情報更新間隔(分)
       * ローカルストレージに利用者単位で設定します。(※初期開発は1分固定予定)
       */
      updateIntervalMinutes: 1,
      /**
       * リフレッシュボタンの活性、非活性
       */
      refreshdisabled: false,
      /**
       * ウィンドウ幅
       */
      windowWidth: 1120,
      mobileView: false,
      signageDateString: "",
      signagePouringList: [],
      pouringPosition: "",
      isLoading: true,
      /**
       * 日付選択ショートカット
       */
      shortcuts: [
        {
          text: "今日",
          value: new Date(),
        },
        {
          text: "昨日",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "1週間前",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
      availableDays: {},
      plannedQuantity: "日毎の打設計画。打設先と打設量の作成",
      soilPlannedQuantity: "日毎の土捨計画。土捨先と土捨量の作成"
    };
  },
  computed: {
    vuename() {
      return "ApplicationHeader.vue"
    }
  },
  /** ライフサクルメソッド */
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },

  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.windowWidth = window.innerWidth;
  },

  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    appLog.debugLog("ApplicationHeader", "", "mounted");

    // 呼び出し元で指定された日付を取得
    const storeDateString = this.$store.state.timeline.datestring;
    console.log(`datestring: ${storeDateString}`)
    appLog.debugLog(
      "ApplicationHeader",
      "",
      `store指定日付：${storeDateString}`
    );

    if (storeDateString) {
      this.dateString = storeDateString;
      this.signageDateString = storeDateString;
      appLog.debugLog("ApplicationHeader", "", this.dateString);
    }

    if (this.volumeId) {
      this.pouringPosition = this.volumeId;
    }

    // this.$nextTick(function() {
    //   if (this.isSignageMode) {
    //     //alert(this.signagePouringList[0].id)
    //     this.pouringPosition = this.signagePouringList[0].id
    //   }
    // }.bind(this))

    this.refreshIntervalSecondValue = this.refreshIntervalSecond;

    // 時刻更新用 ⇒※不要
    // this.startUpdateimeInterval();

    // カレンダーリスト制御用運行情報取得
    this.getDriveListForCalenderDisabled().then(() => {

    })

    // 位置情報更新用
    this.startRefreshLocationInterval();

    // // 表示モードの設定
    // console.log(this.dateString);
    // this.setMode(new Date(this.dateString));

    window.addEventListener("resize", this.calculateWindowWidth);
  },

  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },

  updated() {
    //データが更新され､且つ DOM に適用された後
    this.windowWidth = window.innerWidth;
    // console.log(`windowWidth:${this.windowWidth}`);
  },

  beforeUnmount() {
    //Vue インスタンスが破壊される前
    // this.stopUpdateimeInterval();
    this.stopRefreshLocationInterval();
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },

  /**
   * watchはvueインスタンスのプロパティ(dataで定義している値など)の変更を監視する
   */
  watch: {
    isNowTime() {
      // 当日でなければ表示位置更新を停止
      this.stopRefreshLocationInterval();

      if (this.isNowTime) {
        this.startRefreshLocationInterval();
      }
    },

    isToday() {
      if (this.dateString) {
        // 当日かどうか
        if (
          this.dateString != DateUtil.dateStringBase(new Date(), "YYYY/MM/DD")
        ) {
          // 当日でなければ表示位置更新を停止
          this.stopRefreshLocationInterval();
        }
      } else {
        this.stopRefreshLocationInterval();
      }
    },

    refreshIntervalSecond(value) {
      appLog.debugLog("ApplicationHeader", "", `変更後更新間隔：${value}`);
      this.refreshIntervalSecondValue = value;
    },
  },

  methods: {
    calculateWindowWidth() {
      let windowWidth = window.innerWidth;
      // console.log(`windowWidth:${windowWidth}`);

      if (windowWidth < 768) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      // console.log(`mobileView:${this.mobileView}`);
    },

    /**
     * 時刻表示更新間隔秒を取得します。※不要なのでコメント
     */
    // getTimeIntervalSecond() {
    //   const date = new Date();
    //   let nowMsec = `${DateUtil.dateStringBase(date, 'ss')}.${DateUtil.dateStringBase(date, 'mmm')}`;
    //   if (nowMsec < 1.0) {
    //     // 1秒以下は0扱い
    //     nowMsec = 0.0;
    //   }
    //   console.log(` 現在のミリ秒：${nowMsec}`);
    //   return 60 - nowMsec;
    // },

    /**
     * 時刻表示間隔を開始します。※不要なのでコメント
     */
    // startUpdateimeInterval() {
    //   this.timeIntervalSecond = this.getTimeIntervalSecond();
    //   console.log(this.timeIntervalSecond);
    //   this.timerIntervalId = setInterval(this.updateTime, 1000 * this.timeIntervalSecond);
    // },

    /**
     * 時刻表示更新間隔を停止します。※不要なのでコメント
     */
    // stopUpdateimeInterval() {
    //   // 停止
    //   clearInterval(this.timerIntervalId);
    // },

    /**
     * 位置情報更新間隔を開始します。
     */
    startRefreshLocationInterval() {
      const interval = this.refreshIntervalSecondValue;
      // const interval = this.refreshIntervalSecond - (60 - this.timeIntervalSecond);
      appLog.debugLog(
        "ApplicationHeader",
        "",
        `位置情報更新間隔秒：${interval}`
      );
      // console.log(`refreshIntervalSecond:${this.refreshIntervalSecond}`);
      // console.log(`setInterval value:${this.updateIntervalMinutes * 1000 * interval}`);
      this.refreshIntervalId = setInterval(
        this.onRefreshLocation,
        this.updateIntervalMinutes * 1000 * interval
      );
      // this.refreshIntervalId = setInterval(this.refreshLocation, this.updateIntervalMinutes * 1000 * 60);
      // // this.refreshIntervalId = setInterval(this.refreshLocation, this.updateIntervalMinutes * 1000 * interval);
    },

    /**
     * 位置情報更新間隔を停止します。
     */
    stopRefreshLocationInterval() {
      // 停止
      clearInterval(this.refreshIntervalId);
    },

    /**
     * 表示モードを設定します。
     */
    // setMode(date) {
    //   console.log(date);

    //   // 当日かどうか
    //   if (this.dateString == DateUtil.dateStringBase(new Date(), 'YYYY/MM/DD')) {
    //   // if (this.dateString == DateUtil.dateStringBase(date, 'YYYY/MM/DD')) {
    //     this.isToday = true;
    //   } else {
    //     // 過去表示
    //     this.isToday = false;
    //   }

    //   // レイアウト切り替え
    //   this.changeShowLayout(this.isToday);
    // },

    /**
     * 当日と過去の表示レイアウトを切り替えます。
     *
     * @param {Boolean} isToday 当日の場合true、さもなくばfalse
     */
    // changeShowLayout(isToday) {
    //   // const isShow = isToday;

    //   // this.isNowTime = isShow;
    //   // this.isEditBase = isShow;
    //   // this.isEditVehicle = isShow;
    //   // this.isCreatePlan = isShow;
    // },

    /**
     * 現在時刻表示を更新します。
     */
    updateTime() {
      // 現在の時刻を取得
      this.timeString = DateUtil.dateStringBase(new Date(), "HH:mm");

      // 時刻更新用のタイマーは不要
      // if (this.timeIntervalSecond != 60) {
      //   // 一時停止
      //   this.stopUpdateimeInterval();
      //   // 再開
      //   this.startUpdateimeInterval();
      // }
    },

    /**
     * リフレッシュボタンの活性・非活性の設定をします。
     */
    updateRefleshButton(value) {
      this.refreshdisabled = value;
    },

    /**
     * 位置情報更新を発行します。
     */
    refreshLocation() {
      // カレンダーリスト制御用運行情報取得
      this.getDriveListForCalenderDisabled().then(() => {

      })
      this.$emit("refresh");
    },

    /**
     * 位置情報更新ボタンクリック時の処理です。
     */
    onRefreshLocation() {
      this.refreshLocation();

      // 一度停止
      this.stopRefreshLocationInterval();
      this.startRefreshLocationInterval();

      // // 表示時刻も合わせて更新する（時刻の更新に合わせて位置情報が更新されるように見せる）
      // clearInterval(this.timerIntervalId);
      // this.startUpdateimeInterval();
    },
    async getDriveListForCalenderDisabled() {
      this.availableDays = {}
      const projectId = this.$store.state.timeline.projectid
      let listdata = await DriveModel.getPkSkOnlyList(projectId, "")
      for (let data of listdata) {
        let skElements = data.sk.split("#")
        this.availableDays[skElements[2]] = true
      }
      
      let now = DateUtil.dateStringBase(new Date(), "YYYYMMDD")
      // 当日は選択可能とするためセットしておく
      this.availableDays[now] = true
    },

    onRefreshSignage() {
      this.$emit("refreshSignage");
    },

    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDate(time) {
      let ymd = DateUtil.dateStringBase(time, "YYYYMMDD")
      

      // 当日以降の日付は選択不可とする
      console.log(`time: ${ymd}`)
      
      if (!this.availableDays[ymd]) {
        return true
      }
      return time.getTime() > Date.now();
    },

    /**
     * 日付選択で日付が変更された時に呼び出されます。
     */
    async changeDate(dateAfter) {
      const dateAfterString = DateUtil.dateStringBase(dateAfter, "YYYY/MM/DD");
      // console.log(dateAfterString);
      let store = this.$store.state.timeline;
      store.datestring = dateAfterString
      await this.$store.commit("setTimeline", store); 
      
      this.$emit("changeDate", dateAfter);
    },

    chengePouringPosition() {
      this.$emit("chengePouringPosition", this.pouringPosition);
    },

    /**
     * 運行スポット登録ボタンクリック時
     */
    onDriveSpot() {
      this.$emit("driveSpot");
    },

    /**
     * 運行用QRコードボタンクリック時
     */
    onCreateQRCode() {
      this.$emit("createQRCode");
    },

    /**
     * 拠点編集ボタンクリック時
     */
    onEditBase() {
      this.$emit("editBase");
    },

    /**
     * 車両編集ボタンクリック時
     */
    onEditVehicle() {
      this.$emit("editVehicle", this.dateString);
    },

    /**
     * 予定数量登録ボタンクリック時
     */
    onCreatePlan() {
      this.$emit("createPlan", this.dateString);
    },

    /**
     * 走行履歴ボタンクリック時
     */
    onDriveLog() {
      this.$emit("driveLog", this.dateString);
    },

    /**
     * CSV出力ボタンクリック時
     */
    onOutputCsv() {
      this.$emit("outputCsv");
    },

    /**
     * 運行情報画面ボタンクリック時
     */
    onDriveVue() {
      this.$emit("driveVue");
    },

    /**
     * 実績表示ボタンクリック時
     */
    onShowResult() {
      this.$emit("showResult");
    },
    /**
     * 受入検査結果リスト
     */
    onAcceptanceList() {
      this.$emit("showAcceptanceList", this.dateString);
    },

    onDriveBulkSetting() {
      this.$emit("showDriveBulkSetting");
    },

    /**
     * 打設箇所リストを親コンポーネントからセットするよう（実績表示）
     */
    setSignagePouringList(list, defaultValue) {
      if (String(list[0].id).length > 0) {
        this.signagePouringList = list;
      } else {
        this.signagePouringList = [];
      }
      // 渡されたvolumeIdが存在しているIDであるかチェックする
      let targetPouring = this.signagePouringList.find(item => {
        return item.id == defaultValue
      })
      if (targetPouring) {
        this.pouringPosition = defaultValue;
      } else {
        // なければ一旦未選択状態にする
        this.pouringPosition = ""
        console.log(`setSignagePouringList 存在しないvolumeId: ${defaultValue}`)
      }
      
      if (!this.pouringPosition) {
        if (this.signagePouringList.length > 0) {
          //1件目を初期選択状態とする
          this.pouringPosition = this.signagePouringList[0].id;
          console.log(`setSignagePouringList ${this.pouringPosition}`)
        }
      }

      this.isLoading = false;
    },

    onChangeMapInterval() {
      this.$emit("changeMapInterval");
    },

    /**
     * 位置情報更新間隔秒を更新します。
     * @param {Number} intervalSecond 更新間隔秒
     */
    updateRefreshIntervalSecond(intervalSecond) {
      this.refreshIntervalSecondValue = intervalSecond;

      // 位置情報を更新
      this.onRefreshLocation();
    },
    handleSelect(key, keyPath) {
      switch (key) {
        case "1-0":
          this.onDriveVue();
          break;
        case "1-1":
          // 運行スポット
          this.onDriveSpot();
          break;
        case "1-2":
          // 拠点編集
          this.onEditBase();
          break;
        case "1-3":
          // 車両編集
          this.onEditVehicle();
          break;
        case "1-4":
          // 予定数量登録
          this.onCreatePlan();
          break;
        case "1-5":
          // 運行用QR
          this.onCreateQRCode();
          break;
        case "1-6":
          // 受入検査
          this.onAcceptanceList();
          break;
        case "1-7":
          // 運行先一括設定
          this.onDriveBulkSetting();
          break;
        case "1-8":
          // 走行履歴
          this.onDriveLog();
          break;
        case "1-9":
          // CSV出力
          this.onOutputCsv();
          break;
      }
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
