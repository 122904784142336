class CoordinateUtil {
  /**
   *
   * 中心点経緯度と半径（m単位）から円形ポリゴン座標を取得します。
   * @param {*} latlng 経緯度オブジェクト([y, x])
   * @param {*} radius
   * @returns
   */
  static getCirclePolygonPoints(latlng, radius) {
    // console.log(latlng);
    // console.log(radius);

    let pos = L.latLng(latlng);
    // console.log(pos);

    // 36角形
    const vertex = 36;

    // 赤道半径(m) (WGS-84)
    const EquatorialRadius = 6378137;

    // 扁平率の逆数 : 1/f (WGS-84)
    const F = 298.257223563;

    // 離心率の２乗
    const E = (2 * F - 1) / Math.pow(F, 2);

    // 赤道半径 × π
    const PI_ER = Math.PI * EquatorialRadius;

    // 1 - e^2 sin^2 (θ)
    const TMP = 1 - E * Math.pow((pos.lat * Math.PI) / 180, 2);

    // 経度１度あたりの長さ(m)
    const arc_lat = (PI_ER * (1 - E)) / (180 * Math.pow(TMP, 3 / 2));

    // 緯度１度あたりの長さ(m)
    const arc_lng =
      (PI_ER * Math.cos((pos.lat * Math.PI) / 180)) /
      (180 * Math.pow(TMP, 1 / 2));

    let points = new Array();
    for (let i = 0; i <= vertex; i++) {
      let rad = (i / (vertex / 2)) * Math.PI;
      let lat = (radius / arc_lat) * Math.sin(rad) + pos.lat;
      let lng = (radius / arc_lng) * Math.cos(rad) + pos.lng;
      // points[i] = [lat, lng];
      points[i] = L.latLng(lat, lng);
    }
    // console.log(points);
    return points;
  }

  /**
   * 面上にある点を返します。
   * @param {Object} layer Leaflet Polygon Layer
   * @returns [経度, 緯度]
   */
  static pointOnSurface(layer) {
    // https://github.com/Turfjs/turf
    // https://qiita.com/tomopict/items/c5661b48aa705d289cce
    const { polygon } = require("@turf/helpers");
    const pointOnFeature = require("@turf/point-on-feature").default;

    // GeoJSONへ
    let geoJson = layer.toGeoJSON();

    // turfポリゴン生成
    let poly = polygon(geoJson.geometry.coordinates);

    // 面上にある点を取得
    let p = pointOnFeature(poly);

    return p.geometry.coordinates;
  }

  /**
   * 指定されたポリゴンの面積を取得します。
   * @param {Object} polygon Leaflet Polygon
   * @returns 面積
   */
  static polygonArea(polygon) {
    // 外形の座標のみ
    let latlngs = polygon.getLatLngs()[0];

    let area = 0;

    for (let i = 0; i < latlngs.length; i++) {
      let j = (i + 1) % latlngs.length;
      area += latlngs[i].lng * latlngs[j].lat;
      area -= latlngs[j].lng * latlngs[i].lat;
    }

    area *= 0.5;

    // console.log(`area: ${area}`);

    return area;
  }

  /**
   * 指定されたポリゴンが時計回りかどうかを取得します。
   * @param {Object} polygon Leaflet Polygon
   * @returns 時計回りの場合はtrue、さもなくばfalse
   */
  static isClockWise(polygon) {
    // 面積がマイナスの場合は時計回り
    // https://stackoverflow.com/questions/14505565/detect-if-a-set-of-points-in-an-array-that-are-the-vertices-of-a-complex-polygon
    return CoordinateUtil.polygonArea(polygon) < 0;
  }
}
module.exports = CoordinateUtil;
