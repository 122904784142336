const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 一括運行設定用のデータ取得モデルクラス
 */
class DriveSettingModel extends baseModel {
  /**
   * プロジェクト内の一括運行設定リストを取得する
   * @param {プロジェクト}} projectId
   * @returns
   */
  static async getDriveSettingList(projectId) {
    return await super.getList({ beginsWith: `drivesetting#${projectId}`})
  }

  /**
   * 一括運行設定レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteDriveSetting(data) {
    await super.deleteData(data)
  }
  
  /**
   * 一括運行設定レコードを追加する
   * @param {*} data
   */
  static async addDriveSetting(data) {
    await super.addData(data)
  }
  
  /**
   * 一括運行設定レコードを更新する
   * @param {*} data
   */
  static async updateDriveSetting(data) {
    await super.updateData(data)
  }
  
  /**
   * 一括運行設定レコードを取得する
   * @param {*} data
   */
  static async getDriveSetting(data) {
    return await super.getSingleData(data);
  }
  
  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    return `drivesetting#${projectId}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
      
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, id);
      
    newRowData.drivesetting = {
      driveSettingId: id,
      projectId: "",
      planId: "",
      volumeId: "",
      pouringPosition: "",
      projectType: "",
    }
    return newRowData  
  }
}
  
module.exports = DriveSettingModel;
  