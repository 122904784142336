/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nerbyVehicle = /* GraphQL */ `
  query NerbyVehicle($projectId: String!) {
    nerbyVehicle(projectId: $projectId) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const piplelineTest = /* GraphQL */ `
  query PiplelineTest($id: String!) {
    piplelineTest(id: $id)
  }
`;
export const getKonoikeConcreteLog = /* GraphQL */ `
  query GetKonoikeConcreteLog($pk: String!, $sk: String!) {
    getKonoikeConcreteLog(pk: $pk, sk: $sk) {
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      id
      geofenceJudgeCount
      lastLoginDate
      loginUser
      loginUserSub
      createdAt
      updatedAt
    }
  }
`;
export const listKonoikeConcreteLogs = /* GraphQL */ `
  query ListKonoikeConcreteLogs(
    $pk: String
    $sk: ModelStringKeyConditionInput
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKonoikeConcreteLogs(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKonoikeConcreteSetting = /* GraphQL */ `
  query GetKonoikeConcreteSetting($pk: String!, $sk: String!) {
    getKonoikeConcreteSetting(pk: $pk, sk: $sk) {
      pk
      sk
      id
      name
      value
      voicetext
      iconpath
      order
      intValue
      month
      temperature
      lowTemperature
      HighTemperature
      transitTime
      createdAt
      updatedAt
    }
  }
`;
export const listKonoikeConcreteSettings = /* GraphQL */ `
  query ListKonoikeConcreteSettings(
    $pk: String
    $sk: ModelStringKeyConditionInput
    $filter: ModelKonoikeConcreteSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKonoikeConcreteSettings(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        sk
        id
        name
        value
        voicetext
        iconpath
        order
        intValue
        month
        temperature
        lowTemperature
        HighTemperature
        transitTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKonoikeConcreteData = /* GraphQL */ `
  query GetKonoikeConcreteData($pk: String!, $sk: String!) {
    getKonoikeConcreteData(pk: $pk, sk: $sk) {
      id
      pk
      sk
      lsiStr1
      lsiStr2
      lsiStr3
      lsiStr4
      lsiInt1
      groups
      createdUser
      updatedUser
      invalid
      tenant {
        tenantId
        name
        contractStart
        contractEnd
        status
        availableOptions
        email
        initUserId
        geofenceCollectionId
        memo
        useApps
        deviceNum
        enableDemo
      }
      user {
        name
        userId
        usertype
        status
        period
        email
        deleted
        organizationId
      }
      project {
        id
        name
        intervalMinutes
        users
        options {
          id
          label
          value
        }
        numberAlert
        remainingTime
        projectType
      }
      baseArea {
        id
        areaName
        areaType
        x
        y
        radius
        geofence
        shapeType
      }
      location {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
        roadType
      }
      lastLocation {
        id
        x
        y
        driveId
        vehicleId
        endAreaId
        pouringPosition
        carryType
        sendDateTime
      }
      drive {
        id
        vehicleId
        driveNumber
        factoryStartTime
        siteEndTime
        pouringStartTime
        pouringEndTime
        siteStartTime
        factoryEndTime
        roadType
        endAreaId
        pouringPosition
        carryType
        maxCapacity
        pouringStartUser
        pouringEndUser
        congestionFlag
        congestionLog
        totalAmount
        startAreaId
        volumeId
        deliveryFlag
        unloadingTime
        unloadingUser
        rockTypeName
        loadCapacity
      }
      vehicle {
        id
        name
        maxCapacity
        memo
        order
        selected
        numberPlate
      }
      plan {
        id
        createDate
        temperature
      }
      volume {
        id
        startAreaId
        endAreaId
        pouringPosition
        totalVolume
        grade
        settingAlert
        numberAlert
        rockTypeId
        loadCapacity
      }
      ttl
      driveStatus
      spot {
        id
        spottypeid
        name
        value
        voicetext
        iconpath
        range
        spotType
        x
        y
        geofence
        shapeType
        congestionFlag
      }
      geofenceLog {
        EventType
        GeofenceId
        DeviceId
        SampleTime
        longitude
        latitude
      }
      drivesetting {
        driveSettingId
        projectId
        planId
        volumeId
        pouringPosition
        projectType
      }
      notice {
        id
        noticeType
        title
        content
        argment
        completedPush
      }
      noticeSegment {
        id
      }
      delivery {
        deliveryId
        vehicleId
        startTime
        endTime
        capacity
        deliveryPhoto
      }
      acceptance {
        acceptanceId
        inspectionDateTime
        slump
        chlorideQuantity
        airVolume
        ct
        at
        photos {
          photoType
          photoName
        }
        memo
        updateEditor
        updateUserID
        updateDate
        weather
      }
      route {
        routeId
        routeGeometry
        routeDistance
        roadType
      }
      spottype {
        spottypeId
        name
        voicetext
        iconpath
        spotType
        order
      }
      intervalsetting {
        delayTime
        dwellTime
      }
      judgetraffic {
        judgetrafficeId
        vehicleId
        roadType
        passageTime
      }
      mileage {
        driveId
        vehicleId
        maxCapacity
        totalMileage
        suddenStartCount
        suddenStopCount
        speedViolationCount
        dayAt00h
        dayAt01h
        dayAt02h
        dayAt03h
        dayAt04h
        dayAt05h
        dayAt06h
        dayAt07h
        dayAt08h
        dayAt09h
        dayAt10h
        dayAt11h
        dayAt12h
        dayAt13h
        dayAt14h
        dayAt15h
        dayAt16h
        dayAt17h
        dayAt18h
        dayAt19h
        dayAt20h
        dayAt21h
        dayAt22h
        dayAt23h
      }
      co2setting {
        speedThreshold
        suddenStartValue
        suddenStopValue
        ecoDriveParam
        AClassCount
        BClassCount
        CClassCount
        AClassFuelParam
        BClassFuelParam
        CClassFuelParam
        fuelMileage1
        fuelMileage2
        fuelMileage3
        fuelMileage4
        fuelMileage5
        ArankFuelMemo
        BrankFuelMemo
        CrankFuelMemo
        fuelMileage1Memo
        fuelMileage2Memo
        fuelMileage3Memo
        fuelMileage4Memo
        fuelMileage5Memo
      }
      rocktype {
        id
        name
        loadCapacity
        order
      }
      deviceId {
        deviceId
        modelName
        lastUsedDate
        createDate
        deviceType
        userId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listKonoikeConcreteData = /* GraphQL */ `
  query ListKonoikeConcreteData(
    $pk: String
    $sk: ModelStringKeyConditionInput
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKonoikeConcreteData(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKonoikeServiceSetting = /* GraphQL */ `
  query GetKonoikeServiceSetting($id: ID!) {
    getKonoikeServiceSetting(id: $id) {
      id
      iosMinVersion
      iosStoreUrl
      isMaintenance
      maintenanceText
      createdAt
      updatedAt
    }
  }
`;
export const listKonoikeServiceSettings = /* GraphQL */ `
  query ListKonoikeServiceSettings(
    $filter: ModelKonoikeServiceSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKonoikeServiceSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        iosMinVersion
        iosStoreUrl
        isMaintenance
        maintenanceText
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getKonoikeSetting = /* GraphQL */ `
  query GetKonoikeSetting($id: ID!) {
    getKonoikeSetting(id: $id) {
      id
      tenantArn
      createdAt
      updatedAt
    }
  }
`;
export const listKonoikeSettings = /* GraphQL */ `
  query ListKonoikeSettings(
    $filter: ModelKonoikeSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKonoikeSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantArn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogFromLsiStr1 = /* GraphQL */ `
  query ListLogFromLsiStr1(
    $pk: String
    $lsiStr1: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogFromLsiStr1(
      pk: $pk
      lsiStr1: $lsiStr1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogFromLsiStr2 = /* GraphQL */ `
  query ListLogFromLsiStr2(
    $pk: String
    $lsiStr2: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogFromLsiStr2(
      pk: $pk
      lsiStr2: $lsiStr2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogFromLsiStr3 = /* GraphQL */ `
  query ListLogFromLsiStr3(
    $pk: String
    $lsiStr3: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogFromLsiStr3(
      pk: $pk
      lsiStr3: $lsiStr3
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogFromLsiStr4 = /* GraphQL */ `
  query ListLogFromLsiStr4(
    $pk: String
    $lsiStr4: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogFromLsiStr4(
      pk: $pk
      lsiStr4: $lsiStr4
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listLogFromLsiInt1 = /* GraphQL */ `
  query ListLogFromLsiInt1(
    $pk: String
    $lsiInt1: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogFromLsiInt1(
      pk: $pk
      lsiInt1: $lsiInt1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        id
        geofenceJudgeCount
        lastLoginDate
        loginUser
        loginUserSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDataFromLsiStr1 = /* GraphQL */ `
  query ListDataFromLsiStr1(
    $pk: String
    $lsiStr1: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataFromLsiStr1(
      pk: $pk
      lsiStr1: $lsiStr1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDataFromLsiStr2 = /* GraphQL */ `
  query ListDataFromLsiStr2(
    $pk: String
    $lsiStr2: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataFromLsiStr2(
      pk: $pk
      lsiStr2: $lsiStr2
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDataFromLsiStr3 = /* GraphQL */ `
  query ListDataFromLsiStr3(
    $pk: String
    $lsiStr3: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataFromLsiStr3(
      pk: $pk
      lsiStr3: $lsiStr3
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDataFromLsiStr4 = /* GraphQL */ `
  query ListDataFromLsiStr4(
    $pk: String
    $lsiStr4: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataFromLsiStr4(
      pk: $pk
      lsiStr4: $lsiStr4
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDataFromLsiInt1 = /* GraphQL */ `
  query ListDataFromLsiInt1(
    $pk: String
    $lsiInt1: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataFromLsiInt1(
      pk: $pk
      lsiInt1: $lsiInt1
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pk
        sk
        lsiStr1
        lsiStr2
        lsiStr3
        lsiStr4
        lsiInt1
        groups
        createdUser
        updatedUser
        invalid
        tenant {
          tenantId
          name
          contractStart
          contractEnd
          status
          availableOptions
          email
          initUserId
          geofenceCollectionId
          memo
          useApps
          deviceNum
          enableDemo
        }
        user {
          name
          userId
          usertype
          status
          period
          email
          deleted
          organizationId
        }
        project {
          id
          name
          intervalMinutes
          users
          options {
            id
            label
            value
          }
          numberAlert
          remainingTime
          projectType
        }
        baseArea {
          id
          areaName
          areaType
          x
          y
          radius
          geofence
          shapeType
        }
        location {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
          roadType
        }
        lastLocation {
          id
          x
          y
          driveId
          vehicleId
          endAreaId
          pouringPosition
          carryType
          sendDateTime
        }
        drive {
          id
          vehicleId
          driveNumber
          factoryStartTime
          siteEndTime
          pouringStartTime
          pouringEndTime
          siteStartTime
          factoryEndTime
          roadType
          endAreaId
          pouringPosition
          carryType
          maxCapacity
          pouringStartUser
          pouringEndUser
          congestionFlag
          congestionLog
          totalAmount
          startAreaId
          volumeId
          deliveryFlag
          unloadingTime
          unloadingUser
          rockTypeName
          loadCapacity
        }
        vehicle {
          id
          name
          maxCapacity
          memo
          order
          selected
          numberPlate
        }
        plan {
          id
          createDate
          temperature
        }
        volume {
          id
          startAreaId
          endAreaId
          pouringPosition
          totalVolume
          grade
          settingAlert
          numberAlert
          rockTypeId
          loadCapacity
        }
        ttl
        driveStatus
        spot {
          id
          spottypeid
          name
          value
          voicetext
          iconpath
          range
          spotType
          x
          y
          geofence
          shapeType
          congestionFlag
        }
        geofenceLog {
          EventType
          GeofenceId
          DeviceId
          SampleTime
          longitude
          latitude
        }
        drivesetting {
          driveSettingId
          projectId
          planId
          volumeId
          pouringPosition
          projectType
        }
        notice {
          id
          noticeType
          title
          content
          argment
          completedPush
        }
        noticeSegment {
          id
        }
        delivery {
          deliveryId
          vehicleId
          startTime
          endTime
          capacity
          deliveryPhoto
        }
        acceptance {
          acceptanceId
          inspectionDateTime
          slump
          chlorideQuantity
          airVolume
          ct
          at
          photos {
            photoType
            photoName
          }
          memo
          updateEditor
          updateUserID
          updateDate
          weather
        }
        route {
          routeId
          routeGeometry
          routeDistance
          roadType
        }
        spottype {
          spottypeId
          name
          voicetext
          iconpath
          spotType
          order
        }
        intervalsetting {
          delayTime
          dwellTime
        }
        judgetraffic {
          judgetrafficeId
          vehicleId
          roadType
          passageTime
        }
        mileage {
          driveId
          vehicleId
          maxCapacity
          totalMileage
          suddenStartCount
          suddenStopCount
          speedViolationCount
          dayAt00h
          dayAt01h
          dayAt02h
          dayAt03h
          dayAt04h
          dayAt05h
          dayAt06h
          dayAt07h
          dayAt08h
          dayAt09h
          dayAt10h
          dayAt11h
          dayAt12h
          dayAt13h
          dayAt14h
          dayAt15h
          dayAt16h
          dayAt17h
          dayAt18h
          dayAt19h
          dayAt20h
          dayAt21h
          dayAt22h
          dayAt23h
        }
        co2setting {
          speedThreshold
          suddenStartValue
          suddenStopValue
          ecoDriveParam
          AClassCount
          BClassCount
          CClassCount
          AClassFuelParam
          BClassFuelParam
          CClassFuelParam
          fuelMileage1
          fuelMileage2
          fuelMileage3
          fuelMileage4
          fuelMileage5
          ArankFuelMemo
          BrankFuelMemo
          CrankFuelMemo
          fuelMileage1Memo
          fuelMileage2Memo
          fuelMileage3Memo
          fuelMileage4Memo
          fuelMileage5Memo
        }
        rocktype {
          id
          name
          loadCapacity
          order
        }
        deviceId {
          deviceId
          modelName
          lastUsedDate
          createDate
          deviceType
          userId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
