<template>
  <div class="main-content">
    <!-- <div class="d-flex ml-3 mr-3 mt-3"> -->
      <base-nav
        container-classes="container-fluid"
        class="navbar-top border-bottom navbar-expand mb-1"
        :class="{ 'bg-success navbar-dark': type === 'default' }"
      >
        <h3 class="flex-grow-1 inline-block align-self-center">{{title}}</h3>
        <ul class="navbar-nav align-items-center ml-auto ml-md-0">
          <base-dropdown
            menu-on-right
            class="nav-item"
            tag="li"
            title-tag="a"
            title-classes="nav-link pr-0"
          >
            <template v-slot:title-container>
              <a href="#" class="nav-link pr-0" @click.prevent>
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle far fa-user">
                  </span>
                  <div class="media-body ml-2 d-none d-lg-block">
                    <span class="mb-0 text-sm font-weight-bold">{{userInfo.userName}}</span>
                  </div>
                </div>
              </a>
            </template>

            <a class="dropdown-item" @click="signOut">
              <i class="fa fa-door-open"></i>ログアウト</a>
          </base-dropdown>
        </ul>
      </base-nav>
    <!-- </div> -->
    <router-view></router-view>
  </div>
  
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { Auth } from "aws-amplify";
import UserInfo from "../../appUtils/UserInfo";
import UserModel from "../../appModel/users/UserModel"

export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      userInfo: {},
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
      userData: null,
    };
  },
  computed: {
    title() {
      return `${this.$store.state.mainte.title}`;
    },
  },

  async mounted(){
    const loginInfo = await UserInfo.getUserInfo()
    const pk = this.group
    const sk = UserModel.createSk(loginInfo.sub)
    UserModel.getUser({pk: pk, sk: sk}).then(function(loginUser) {
      this.isNormal = !Number(loginUser.user.usertype);
    }.bind(this))
    const user = await UserInfo.getUserInfo()

    if (this.$store.state.user.userId == "") {
      Auth.signOut()
      this.$router.push({
        name: 'Signin'
      })
    }
    
  
    await this.initNavbarData()
  
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      // document.body.classList.add("bg-light-gray");
    },
    removeBackgroundColor() {
      // document.body.classList.remove("bg-light-gray");
    },
    async initNavbarData() {
      
      try {
        let userStore = this.$store.state.user
        this.userInfo = {
          userName: userStore.userName,
          group: userStore.group,
          sub: userStore.cognitoSub
        }
      } catch (e) {
        console.log(`ユーザー情報取得エラー`)
      }
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
    signOut: function() {
      Auth.signOut()
      this.$router.push({
        name: 'MainteSignin'
      })
    }
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
