<template>
  <div>
    <div class="container-fluid mt-1">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <h3>プロジェクト基本情報</h3>
            <div class="pl-lg-4">
              <base-input type="text" label="プロジェクト名">
                <template></template>
                <el-input
                  placeholder="プロジェクト名を入力してください"
                  ref="projectname"
                  v-model="projectData.project.name"
                ></el-input>
              </base-input>
            </div>
            <div class="pl-lg-4">
              <div class="d-flex">
                <div class="">
                  <div class="d-flex flex-column">
                    <div class="">
                      <div class="d-flex">
                        <h4>位置情報発信間隔</h4>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="iOSアプリから位置情報を送信する間隔です。標準値は1分です。1～60分の間で変更出来ます。"
                          placement="right"
                        >
                          <i
                            class="far fa-question-circle"
                            style="margin-bottom:8px; margin-left:5px;"
                          ></i>
                        </el-tooltip>
                      </div>
                    </div>
                  
                    <div  class="d-flex align-items-center">
                      <div style="width:500px;">
                        <el-input
                          v-if="false"
                          placeholder="Please input"
                          v-model="projectData.project.intervalMinutes"
                        ></el-input>
                        <el-slider v-model="projectData.project.intervalMinutes" show-input :format-tooltip="formatTooltip" :min="1" :max="60"> </el-slider>
                      </div>
                      <div class="ml-2">
                        分
                      </div>
                    </div>
                  </div>
                  <!-- </base-input> -->
                </div>
              </div>
            </div>
            <div class="pl-lg-4" v-if="!this.isZandoPath">
              <div class="d-flex">
                <div class="">
                  <div class="d-flex flex-column">
                    <div class="">
                      <div class="d-flex" >
                        <h4>経過時間表示閾値</h4>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="生コン運搬基準時間に対し残時間がこの値を下回った場合、運行情報の経過時間を表示します。"
                          placement="right"
                        >
                          <i
                            class="far fa-question-circle"
                            style="margin-bottom:8px; margin-left:5px;"
                          ></i>
                        </el-tooltip>
                      </div>
                    </div>
                    <div  class="d-flex align-items-center">
                      <div style="width:500px;">
                        <el-input
                          v-if="false"
                          placeholder="Please input"
                          v-model="projectData.project.remainingTime"
                        ></el-input>
                        <el-slider v-model="projectData.project.remainingTime" show-input :format-tooltip="formatTooltip" :min="1" :max="120"> </el-slider>
                      </div>
                      <div class="ml-2">
                        分
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-lg-4" v-if="!this.isZandoPath">
              <base-input type="text" label="残台数アラート">
                <template></template>
                  <div class="d-flex">
                    <el-input
                      class="col-md-2"
                      placeholder="残台数を入力してください"
                      ref="numberAlert"
                      v-model="projectData.project.numberAlert"
                    ></el-input>
                    <h4 style="margin-top:10px">台</h4>
                  </div>
              </base-input>
            </div>

          </card>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">CO2排出量算出設定</h5>
            </template>

            <el-form :model="formData" :rules="rules" ref="form" class="ml-2">
              <div>
                <div class="d-flex align-items-center ml-2">
                  <h4>5秒間速度超過<require-tag /></h4>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="5秒間の間、速度が値以上となった場合に速度超過と判定を行います。"
                    placement="right"
                  >
                    <i
                      class="far fa-question-circle"
                      style="margin-bottom:8px; margin-left:5px;"
                    ></i>
                  </el-tooltip>
                </div>
                <el-form-item prop="speedThreshold" class="ml-4">
                  <el-input
                    name="speedThreshold"
                    style="width:60px"
                    :disabled="editMode == 1"
                    autocomplete="off"
                    placeholder="数値でkm/h値を入力"
                    v-model="formData.speedThreshold"
                  ></el-input>
                  <span>&nbsp;km/h</span>
                </el-form-item>
              </div>

              <div class="d-flex align-items-center ml-2">
                <h4>急発進判定<require-tag /></h4>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="5秒間の間の速度変化量がこの値を超えていた場合に急発進と判定します。"
                  placement="right"
                >
                  <i
                    class="far fa-question-circle"
                    style="margin-bottom:8px; margin-left:5px;"
                  ></i>
                </el-tooltip>
              </div>
              <el-form-item prop="suddenStartValue" class="ml-4 d-flex">
                <span class="mr-2">5秒間で</span>
                <el-input
                  name="suddenStartValue"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="km/hで入力"
                  v-model="formData.suddenStartValue"
                ></el-input>
                <span>km/h 以上の変化時</span>
              </el-form-item>
              <div class="d-flex align-items-center ml-2">
                <h4>急停止判定<require-tag /></h4>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="五秒間の間の速度変化量がこの値を超えていた場合に急停車と判定します。"
                  placement="right"
                >
                  <i
                    class="far fa-question-circle"
                    style="margin-bottom:8px; margin-left:5px;"
                  ></i>
                </el-tooltip>
              </div>
              <el-form-item prop="suddenStopValue" class="ml-4 d-flex">
                <span class="mr-2">5秒間で</span>
                <el-input
                  name="suddenStopValue"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="km/hで入力"
                  v-model="formData.suddenStopValue"
                ></el-input>
                <span>km/h 以上の変化時</span>
              </el-form-item>

              <div class="d-flex align-items-center ml-2">
                <h4>エコ運転判定<require-tag /></h4>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="急発進、急停車、速度超過をカウントします。合計回数にてエコ運転の達成度合いを判定します。"
                  placement="right"
                >
                  <i
                    class="far fa-question-circle"
                    style="margin-bottom:8px; margin-left:5px;"
                  ></i>
                </el-tooltip>
              </div>

              <div>
                <div class="d-flex pb-2 mt-3 ml-4" style="border-bottom: 1px solid #666666;">
                  <div class="w150 text-center font-weight-bold" style="border:0px solid red;">
                    ランク
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    判定回数
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    燃費係数
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    予想燃費(10t車)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    備考
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    A判定
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="AClassCount" class="ml-4 d-flex">
                      <el-input
                        name="AClassCount"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        ref="AClassCount"
                        v-model="formData.AClassCount"
                      ></el-input>
                      <span>回以下</span>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="AClassFuelParam" class="ml-4 d-flex">
                      <el-input
                        name="AClassFuelParam"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        ref="AClassFuelParam"
                        v-model="formData.AClassFuelParam"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    {{ArankFuel}}
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="ArankFuelMemo" class="ml-6 d-flex">
                      <el-input
                        name="ArankFuelMemo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.ArankFuelMemo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    B判定
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="BClassCount" class="ml-4 d-flex">
                      <el-input
                        name="BClassCount"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.BClassCount"
                      ></el-input>
                      <span>回以下</span>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="BClassFuelParam" class="ml-4 d-flex">
                      <el-input
                        name="BClassFuelParam"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        ref="BClassFuelParam"
                        v-model="formData.BClassFuelParam"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    {{BrankFuel}}
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="BrankFuelMemo" class="ml-6 d-flex">
                      <el-input
                        name="BrankFuelMemo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.BrankFuelMemo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    C判定
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="CClassCount" class="ml-4 d-flex">
                      <!-- <el-input
                        name="CClassCount"
                        class="w100 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.CClassCount"
                      ></el-input>
                      <span>回以下</span> -->
                      <span>B判定を超過</span>
                    </el-form-item>

                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="CClassFuelParam" class="ml-4 d-flex">
                      <el-input
                        name="CClassFuelParam"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.CClassFuelParam"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    {{CrankFuel}}
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="CrankFuelMemo" class="ml-6 d-flex">
                      <el-input
                        name="CrankFuelMemo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.CrankFuelMemo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center ml-2">
                <h4>基準燃費<require-tag /></h4>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="車両サイズ別の燃費既定値を定義します。CO2排出量の計算で使用します。"
                  placement="right"
                >
                  <i
                    class="far fa-question-circle"
                    style="margin-bottom:8px; margin-left:5px;"
                  ></i>
                </el-tooltip>
              </div>
              <div class="ml-4">
                <span>各最大積載量に応じた経由での燃費を入力してください。</span>
              </div>

              <div>
                <div class="d-flex pb-2 mt-3 ml-4" style="border-bottom: 1px solid #666666;">
                  <div class="w300 text-center font-weight-bold" style="border:0px solid red;">
                    最大積載量
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    燃費(km/l)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    備考
                  </div>
                  <div class="col-md-2" style="margin-left:auto">
                    <base-button
                      type="primary"              
                      size="sm"
                      block
                      @click="onClickDefault"
                      >初期値にリセット</base-button
                    >
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    2000～3999kg (約0.8～1.3㎥)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="fuelMileage1" class="ml-4 d-flex">
                      <el-input
                        name="fuelMileage1"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.fuelMileage1"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="fuelMileage1Memo" class="ml-6 d-flex">
                      <el-input
                        name="fuelMileage1Memo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.fuelMileage1Memo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    4000～5999kg (約1.4～2.3㎥)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="fuelMileage2" class="ml-4 d-flex">
                      <el-input
                        name="fuelMileage2"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.fuelMileage2"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="fuelMileage2Memo" class="ml-6 d-flex">
                      <el-input
                        name="fuelMileage2Memo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.fuelMileage2Memo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    6000～7999kg (約2.4～3.1㎥)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="fuelMileage3" class="ml-4 d-flex">
                      <el-input
                        name="fuelMileage3"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.fuelMileage3"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="fuelMileage3Memo" class="ml-6 d-flex">
                      <el-input
                        name="fuelMileage3Memo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.fuelMileage3Memo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    8000～9999kg (約3.2～3.9㎥)
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="fuelMileage4" class="ml-4 d-flex">
                      <el-input
                        name="fuelMileage4"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.fuelMileage4"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="fuelMileage4Memo" class="ml-6 d-flex">
                      <el-input
                        name="fuelMileage4Memo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.fuelMileage4Memo"
                      ></el-input>
                    </el-form-item>
                  </div>                  
                </div>

                <div class="d-flex pb-2 mt-2 ml-4">
                  <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
                    10000～11999kg （約4.0㎥～）
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
                    <el-form-item prop="fuelMileage5" class="ml-4 d-flex">
                      <el-input
                        name="fuelMileage5"
                        class="mr-2"
                        style="width:60px"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="数値で入力"
                        v-model="formData.fuelMileage5"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="w200 text-center font-weight-bold" style="border:0px solid red">
                    <el-form-item prop="fuelMileage5Memo" class="ml-6 d-flex">
                      <el-input
                        name="fuelMileage5Memo"
                        class="w400 mr-2"
                        :disabled="editMode == 1"
                        autocomplete="off"
                        placeholder="備考を入力"
                        v-model="formData.fuelMileage5Memo"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-form>

          </card>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <card>
            <template v-slot:header>
              <h5 class="h3 mb-0">プロジェクトユーザー</h5>
            </template>
            <el-table
              class="table-responsive table-flush"              
              header-row-class-name="app-th-row"
              ref="userTable"
              row-key="userId"
              :data="queriedData"
              :default-sort="{ prop: 'userId', order: 'ascending' }"
              @sort-change="runSort"
              @select="onSelectionChangeUser"
              @select-all="onSelectAll"
            >
              <el-table-column type="selection" :reserve-selection="true" align="left" min-width="20px"></el-table-column>
              <el-table-column
                label="ユーザー名"
                min-width="220px"
                prop="name"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  <b>{{ row.name }}</b>
                </template>
              </el-table-column>

              <el-table-column
                label="ユーザーID"
                min-width="220px"
                prop="userId"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  {{ row.userId }}
                </template>
              </el-table-column>

              
              
              <el-table-column label="権限" prop="usertype" sortable="'custom'">
                <template v-slot="{ row }">
                  <span v-if="row.usertype == '0'">一般</span>
                  <span v-if="row.usertype == '1'">管理者</span>
                  <span v-if="row.usertype == '2'">システム管理者</span>
                  <span v-if="row.usertype == '3'">ドライバー</span>
                  <span></span>
                </template>
              </el-table-column>

            </el-table>
            <template v-slot:footer>
              <div
                class="
                  col-12
                  d-flex
                  justify-content-center justify-content-sm-between
                  flex-wrap
                "
              >
                <div class="">
                  <p class="card-category">
                    
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </template>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card>
            <base-button
              block
              type="primary"
              @click="onClickCreateProject"
              v-if="isNewProject"
              >プロジェクトを作成する</base-button
            >
            <base-button
              block
              type="primary"
              @click="onClickSaveProject"
              v-if="!isNewProject"
              >プロジェクトを保存する</base-button
            >
          </card>
        </div>
      </div>
    </div>
  </div>

  <!-- 処理確認モーダル -->
  <modal v-model:show="modals.confirmDlg">
    <template v-slot:header>
        <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
    </template>
    <div class="mb-5">
      {{confirmMessage}}
    </div>
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
      </div>
      <div class="col-sm-2">
      </div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickConfirmOK">{{execBtnCaption}}</base-button>
      </div>
    </div>
  </modal>

</template>
<script>

import { Storage } from "aws-amplify";
import Card from "../../components/Cards/Card.vue";
import UserModel from "../../appModel/users/UserModel";
import UserInfo from "../../appUtils/UserInfo";
import ProjectModel from "../../appModel/project/ProjectModel";
import { useToast } from "vue-toastification";
import DateUtil from '@/appUtils/DateUtil';
import SortUtil from '../../appUtils/SortUtil';
import SpotTypeBaseModel from "@/appModel/Spot/SpotTypeBaseModel";
import TenantStorage from "@/appUtils/TenantStorage"
import path from 'path'
import SpotTypeModel from "@/appModel/Spot/SpotTypeModel";
import AppLog from "@/appUtils/AppLog";
import CO2SettingModel from "@/appModel/CO2Setting/CO2SettingModel";
import ValidateUtil from "@/appUtils/ValidateUtil";
import Modal from "@/components/Modal";
import IntervalSettingModel from "@/appModel/Spot/IntervalSettingModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import { Auth } from "aws-amplify";

export default {
  components: {
    Card,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  /**
   * プロパティ
   */
  data() {
    return {
      groupId: "",
      projectId: "",
      isNewProject: true,
      userList: [],
      projectData: { project: {} },
      project: {
        name: "あいうえお",
        options: [],
        users: [],
        maxOption: 5,
        maxUsers: 10,
        remainingTime: 30,
        numberAlert: 10,
      },      
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      projectLengh: 50,
      /**
       * 初期ソート（ユーザーID=>昇順)
       */
      sortColumn: {
        prop: 'userId',
        order: 'ascending',
      },
      currentUserInfo: null,
      logger: null,
      // CO2排出量関連
      co2Setting: null,
      formData: {
        speedThreshold: null,
        suddenStartValue: null,
        suddenStopValue: null,
        ecoDriveParam: null,
        AClassCount: 1,
        BClassCount: 2,
        CClassCount: 3,
        AClassFuelParam: 1.2,
        BClassFuelParam: 1.0,
        CClassFuelParam: 0.8,
        fuelMileage1: 0,
        fuelMileage2: 0,
        fuelMileage3: 0,
        fuelMileage4: 0,
        fuelMileage5: 0,
        fuelMileage1Memo: null,
        fuelMileage2Memo: null,
        fuelMileage3Memo: null,
        fuelMileage4Memo: null,
        fuelMileage5Memo: null,
        ArankFuelMemo: null,
        BrankFuelMemo: null,
        CrankFuelMemo: null,
      },
      modals: {
        confirmDlg: false,
      },
      confirmTitle: '基本燃料初期化',
      confirmMessage: '基本燃料の燃費の値を初期化しますか？', 
      execBtnCaption: '実行',
      rules: {
        speedThreshold: [ // 速度超過判定閾値
          { required: false, validator: this.checkSpeedThreshold, trigger: "blur" },
        ],
        suddenStartValue: [ // 急発進判定
          { required: false, validator: this.checkSuddenStartValue, trigger: "blur" },
        ],
        suddenStopValue: [ // 急停止判定
          { required: false, validator: this.checkSuddenStopValue, trigger: "blur" },
        ],
        ecoDriveParam: [ // エコ運転判定
          { required: false, validator: this.checkEcoDriveParam, trigger: "blur" },
        ],
        AClassCount: [ // A判定 判定回数
          { required: false, validator: this.checkAClassCount, trigger: "blur" },
        ],
        BClassCount: [ // B判定 判定回数
          { required: false, validator: this.checkBClassCount, trigger: "blur" },
        ],
        CClassCount: [ // C判定 判定回数
          { required: false, validator: this.checkCClassCount, trigger: "blur" },
        ],
        AClassFuelParam: [ // A判定 燃費係数
          { required: false, validator: this.checkAClassFuelParam, trigger: "blur" },
        ],
        BClassFuelParam: [ // B判定 燃費係数
          { required: false, validator: this.checkBClassFuelParam, trigger: "blur" },
        ],
        CClassFuelParam: [ // C判定 燃費係数
          { required: false, validator: this.checkCClassFuelParam, trigger: "blur" },
        ],
        fuelMileage1: [ // 燃費1
          { required: false, validator: this.checkFuelMileage1, trigger: "blur" },
        ],
        fuelMileage2: [ // 燃費2
          { required: false, validator: this.checkFuelMileage2, trigger: "blur" },
        ],
        fuelMileage3: [ // 燃費3
          { required: false, validator: this.checkFuelMileage3, trigger: "blur" },
        ],
        fuelMileage4: [ // 燃費4
          { required: false, validator: this.checkFuelMileage4, trigger: "blur" },
        ],
        fuelMileage5: [ // 燃費5
          { required: false, validator: this.checkFuelMileage5, trigger: "blur" },
        ],
      }
    };
  },
  /**
   * コンピューテッドプロパティ
   */
  computed: {
    ArankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.AClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },
    BrankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.BClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },
    CrankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.CClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },    
    // ユーザマスタにプロジェクトへの存在有無を反映させた結果
    tableData() {
      this.userList.forEach((data) => {
        data.joined = false;        
        
        if (this.projectData.project.users) {
          const index = this.projectData.project.users.findIndex(
            (projectUser) => {
              return projectUser == data.cognitoUserId;
            }
          );
          if (index >= 0) {
            data.joined = true;
          }
        }

      });
      return this.userList;
    },
    // ページングによる絞り込みを行った結果
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      // 検索条件が指定されていない場合はページング後の配列を返す
      if (!this.searchQuery) {
        return this.pagedData;
      }
      // 検索条件によるフィルタ処理
      let result = this.tableData.filter((row) => {
        // 該非判定フラグ
        let isIncluded = false;
        // 検索条件項目分ループ
        for (let key of this.propsToSearch) {
          // 検索条件の値取得
          let rowValue = row[key].toString();
          // 文字列がデータ内に含まれているかチェック
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            // 含まれている場合
            isIncluded = true;
          }
        }

        return isIncluded;
      });
      // 検索結果をページングして返す
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.userList.length;
    },
    vuename() {
      return "ProjectEditor"
    },
    isZandoPath() {
      if (this.$route.path == "/zandonewproject" || this.$route.path == "/zandoeditproject") {
        return true
      }
      return false
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  async created() {
    const loader = this.showLoader()
    try {
      this.currentUserInfo = await UserInfo.getUserInfo()
      this.groupId = this.currentUserInfo.group
      this.logger = new AppLog(this.currentUserInfo.group, this.currentUserInfo.userName)
      this.infoLog(`created`, `Start Vuex:projectId(${this.$store.state.project.projectId})`)
      
      //インスタンスが生成され､且つデータが初期化された後
      await this.getProjectUsers();
      await this.runSort(this.sortColumn);
      let project = this.$store.state.project;
      this.isNewProject = this.$route.meta.isNewProject == "1";
      
      if (this.isNewProject) {
        // 新規プロジェクト
        this.infoLog(`created`, `新規プロジェクト`);
        await this.newProject();
      } else {
        // プロジェクト編集
        this.infoLog(`created`, `プロジェクト編集: ${JSON.stringify(project)}`);
        await this.setProject(project.projectId);
        
        this.initTableSelection()
        
      }

      // タイトルをセット
      let navInfo = this.$store.state.navInfo
      navInfo.title = this.isNewProject ? "新規プロジェクトを追加" : "プロジェクト情報の編集"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)
    } catch (e) {
      this.errorLog(`created`, `${this.parseErrorObject(e)}`);
      this.showBottomToast("プロジェクト作成、編集時に問題が発生しました", "error");

      this.$router.push({
        path: this.getRoutePath('projects')
      });
    } finally {
      this.$nextTick(function() {
        this.hideLoader(loader);
      }.bind(this))
    }
    
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    
    
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    this.infoLog(`beforeRouteLeave`, `End`)
  },
  
    
  methods: {
    formatTooltip(val) {
      return `${val}分`
    },
    /**
     * プロジェクト編集時のユーザー選択状態復元
     */
    initTableSelection() {
      let userHash = {}
      this.projectData.project.users.forEach(function(user) {
        userHash[user] = user
      }.bind(this))
      
      this.userList.forEach(function(user){
        let flg = false
        if (userHash[user.cognitoUserId]) {
          flg = true
        }
        this.$refs.userTable.toggleRowSelection(user, flg)
      }.bind(this))
    },
    /**
     * プロジェクト編集時の表示データ取得
     */
    async setProject(projectId) {
      this.projectData = { project: {} };
      
      

      const pk = (await UserInfo.getUserInfo()).group;
      const sk = `project#${projectId}`;
      this.projectData = await ProjectModel.getProject({ pk: pk, sk: sk });
      
      // CO2排出関係設定値取得
      this.co2Setting = await CO2SettingModel.getCO2SettingbyProjectId(projectId);
      this.formData = this.co2Setting.co2setting;
    },
    /**
     * 新規プロジェクト作成時の表示データ取得
     */
    async newProject() {
      this.projectData = { project: {} };
      this.projectData = await ProjectModel.getNewData();
      const projectType = this.isZandoPath ? "1" : "0"
      this.projectData.lsiStr1 = `project#${projectType}#${this.projectData.project.id}`
      this.projectData.project.projectType = projectType
      const group = (await UserInfo.getUserInfo()).group;
      this.projectData.pk = group;
      this.projectData.groups.push(group);

      // CO2排出関係設定値取得
      let co2Setting = await CO2SettingModel.getCO2Setting();
      if (co2Setting) {
        this.formData = co2Setting.co2setting;
      }
    },
    /**
     * プロジェクトに紐づくユーザ取得
     */
    async getProjectUsers() {
      const userInfo = await UserInfo.getUserInfo();
      const result = await UserModel.getUserList();
      
      this.userList = [];
      // 
      result.forEach((data) => {
        // 管理者と削除ユーザは一覧に含めない
        if (data.user.usertype != "2" && !data.user.deleted) {

          let item = Object.assign({}, data.user);
          item.cognitoUserId = data.sk.split("#")[1]
          this.userList.push(item);
        }  
      });
    },
    /**
     * 一括選択のイベント
     * selectionには選択中のアイテムが渡される。
     * 3ページ(30件)ユーザがあるとして1ページを選択解除するとselectionは20件で渡される。
     * 3ページすべて選択となれば30件渡される。
     */
    onSelectAll(selection) {
      // 一旦初期化する
      this.projectData.project.users = []
      // 選択中のデータのCognitoIdをセットする
      selection.forEach(function(item) {
        this.projectData.project.users.push(item.cognitoUserId);
      }.bind(this))
    },
    /**
     * １行単位でのユーザの選択
     */
    onSelectionChangeUser(rows) {
      this.projectData.project.users = []
      for (let key in rows) {
        this.projectData.project.users.push(rows[key].cognitoUserId);
      }
    },
    /**
     * プロジェクトの作成処理
     */
    async onClickCreateProject() {

      // システム利用区分チェック
      if (!await this.isValidProjectType()) {
        return
      }

      await this.$refs.form.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          // 入力チェック
          let ret = this.validation()
          if (ret == false) return 

          let loader = this.showLoader();
          // ログインユーザーが未選択であれば選択する
          // ユーザーリストにログインユーザーがリストアップされているかのフラグ
          let isFindLoginUserInList = false
          // ユーザーリストを検索
          this.userList.forEach(function(item) {
            console.log(`userList cognitoUserId ${item.cognitoUserId} current ${this.currentUserInfo.sub}`)
            if (item.cognitoUserId == this.currentUserInfo.sub){
              isFindLoginUserInList = true
            }
          }.bind(this))
          console.log(`ユーザーチェック ${isFindLoginUserInList}`)
          if (isFindLoginUserInList) {
            // プロジェクトのユーザーリストを検索
            if (!this.projectData.project.users.includes(this.currentUserInfo.sub)) {
              // プロジェクトのユーザー一覧に存在しなければ追加する
              this.projectData.project.users.push(this.currentUserInfo.sub)
            }
          }
          try {
            // プロジェクト登録
            let ret = await ProjectModel.addProject(this.projectData);
            console.log(`addProject: ${JSON.stringify(ret)}`)
            // 運行スポット登録
            await this.createDefaultSpot()
            // CO2排出量設定登録
            let regData = await CO2SettingModel.getNewPerProjectData(this.projectData.project.id);
            regData.co2setting = this.formData;        
            await CO2SettingModel.addCO2Setting(regData);
            // 運行間隔設定登録
            regData = await IntervalSettingModel.getNewData(this.projectData.project.id);
            regData.intervalsetting.delayTime = 5;
            regData.intervalsetting.dwellTime = 5;
            await IntervalSettingModel.addIntervalSetting(regData);

            // プロジェクト作成後はタイムライン画面に遷移する
            // プロジェクトIDを取得
            let id = this.projectData.project.id;
            //alert(id)
            let timelineData = {
              projectid: `${id}`,
              projectType: this.projectData.project.projectType,
              projectname: this.projectData.project.name,
              datestring: DateUtil.getDateString('YYYY/MM/DD')
            }
            this.$store.commit("setTimeline", timelineData);

            this.$router.push({
              path: this.getRoutePath('timeline')
            });

          } catch (e) {
            this.errorLog(`onClickCreateProject`, `${this.parseErrorObject(e)}`);
            this.showBottomToast("プロジェクトの作成に失敗しました。", "error");
          } finally {
            this.hideLoader(loader);
          }
        }.bind(this));        
    },
    /**
     * プロジェクト編集時の保存処理
     */
    async onClickSaveProject() {
      this.infoLog(`onClickSaveProject`, `${JSON.stringify(this.projectData)}`)

      // システム利用区分チェック
      if (!await this.isValidProjectType()) {
        return
      }
            
      await this.$refs.form.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          // 入力チェック
          let ret = this.validation()
          if (ret == false) return 

          let loader = this.showLoader();
          // ログインユーザーが未選択であれば選択する
          // ユーザーリストにログインユーザーがリストアップされているかのフラグ
          let isFindLoginUserInList = false
          // ユーザーリストを検索
          this.userList.forEach(function(item) {
            if (item.cognitoUserId == this.currentUserInfo.sub){
              isFindLoginUserInList = true
            }
          }.bind(this))
          if (isFindLoginUserInList) {
            // プロジェクトのユーザーリストを検索
            if (!this.projectData.project.users.includes(this.currentUserInfo.sub)) {
              // プロジェクトのユーザー一覧に存在しなければ追加する
              this.projectData.project.users.push(this.currentUserInfo.sub)
            }
          }
          try {

            // ProjectModel.updateProject(this.projectData)
            await ProjectModel.updateProject(this.projectData);
            // CO２排出量設定更新
            this.co2Setting.co2setting = this.formData;
            await CO2SettingModel.updateCO2Setting(this.co2Setting);

            // プロジェクト作成後はタイムライン画面に遷移する
            // プロジェクトIDを取得
            let id = this.projectData.project.id;
            //alert(id)
            let timelineData = {
              projectid: `${id}`,
              projectType: this.projectData.project.projectType,
              projectname: this.projectData.project.name,
              datestring: DateUtil.getDateString('YYYY/MM/DD')
            }
            this.$store.commit("setTimeline", timelineData);

            this.$router.push({
              path: this.getRoutePath('timeline')
            });

          } catch (e) {
            this.errorLog(`onClickSaveProject`, `${this.parseErrorObject(e)}`);
            this.showBottomToast("プロジェクトの保存に失敗しました。", "error");
          } finally {
            this.hideLoader(loader);
          }

        }.bind(this));

    },

    /**
     * 運行スポットの初期レコードを作成する
     * 本処理はプロジェクト新規作成後に呼び出しを行う。
     * 作成に失敗した場合は運行スポットの初期レコードが無い状態となる。
     * プロジェクトの使用そのものには支障は無いようにする。
     */
    async createDefaultSpot() {
      
      console.log(`createDefaultSpot`)
      // 作成済みレコード情報(例外発生時に削除処理で参照する)
      let recordSuccessList = []
      // アップロード済みアイコン情報(例外発生時に削除処理で参照する)
      let uploadedIconList = []

      try {
        
        // 登録したプロジェクトのデータからプロジェクトIDを取得する
        let projectId = this.projectData.project.id;
        let tenantStorage = new TenantStorage(this.groupId)
        // 運行スポットの固定レコードを取得
        // 固定レコードには運行スポットと運行間隔スポットの2種類がある￥
        let baseList = await SpotTypeBaseModel.getSpotTypeBaseList();
        
        // 基本画像をアップロード
        const config = {level: "public", download: true}
        let newRecordList = []
        for (const base of baseList) {
          // 運行スポットか運行間隔スポットかを判定する
          const skList = base.sk.split("#")
          let spotType = 0
          if (skList.length >= 3) {
            if (skList[1] == "1") {
              spotType = 1
            }
          }
          const iconFileName = path.basename(base.iconpath)
          // パブリックフォルダのアイコンをダウンロード
          let iconData = await Storage.get(base.iconpath, config)
          // テナント別のS3パスを作成
          const s3Key = `tenant/${this.groupId}/${projectId}/spottype/${iconFileName}`
          // テナントのパスへアップロード
          // this.infoLog(`createDefaultSpot`, `アップロード開始 ${JSON.stringify(s3Key)}`)
          const response = await tenantStorage.put(s3Key, iconData.Body)
          if (!response) {
            throw new Error(`アップロード失敗`)
          }
          
          uploadedIconList.push(s3Key)
          // this.infoLog(`createDefaultSpot`, `アップロード完了`)
          
          // DBに登録情報を作成
          let newRecord = await SpotTypeModel.getNewData(projectId);
          newRecord.spottype.name = base.name;
          newRecord.spottype.voicetext = base.voicetext;
          newRecord.spottype.spotType = spotType;
          newRecord.spottype.order = base.order;
          newRecord.spottype.iconpath = s3Key;

          newRecordList.push(newRecord)
          
        }

        // DB更新
        try {
          for (const record of newRecordList) {
            this.infoLog(`createDefaultSpot`, `SpotType追加開始: ${JSON.stringify(record)}`)
            await SpotTypeModel.addSpotType(record);
            recordSuccessList.push(record)
            this.infoLog(`createDefaultSpot`, `SpotType追加成功`)
          }
        } catch (e) {
          this.errorLog(`createDefaultSpot:初期SpotType追加失敗`, `${this.parseErrorObject(e)}`);          
          throw e
        }
        
      } catch (e) {
        this.errorLog(`createDefaultSpot`, `${this.parseErrorObject(e)}`);
        
        // レコード削除
        for (const deleteRecord of recordSuccessList) {
          try {
            SpotTypeModel.deleteSpotType(deleteRecord)
          } catch (e) {
            console.log(`削除失敗`)
          }
        }

        // アイコン削除
        let tenantStorage = new TenantStorage(this.groupId)
        for (const icon of uploadedIconList) {
          tenantStorage.delete(icon)
        }
      }
      
    },

    validation(){

      // 入力チェック
      if (this.projectData.project.name == "") {
        this.showBottomToast(`プロジェクト名を入力してください`, `error`)
        this.$refs.projectname.focus();
        return false
      }
      
      // プロジェクト名の文字長さチェック
      if (String(this.projectData.project.name).length > this.projectLengh){
        this.showBottomToast(`プロジェクト名の文字数は ${this.projectLengh} 文字以内で入力してください。`, 'warning')
        this.$refs.projectname.focus();
        return false
      }
      // プロジェクト名の禁則文字チェック
      let kinsokuflg = false
      const kinsoku_str = process.env.VUE_APP_KINSOKU_CHARACTERS
      for (let i=0; i<String(kinsoku_str).length;i++) {
        let check_str = String(kinsoku_str).substr(i+1, 1)
        let ret = String('dummy' + this.projectData.project.name).indexOf(check_str)
        if (ret>0) {
          kinsokuflg = true
          break
        }
      }
      if (kinsokuflg){
        this.showBottomToast(`プロジェクト名に禁則文字が指定されてます。\n禁則文字（${kinsoku_str.substr(1, kinsoku_str.length - 1)}）`, 'warning')
        this.$refs.projectname.focus();
        return false 
      }

      // 残台数アラート台数の入力チェック
      let pattern = /^([1-9]\d*|1)$/;
      if (!pattern.test(this.projectData.project.numberAlert)){
        this.showBottomToast(`残台数アラートには1以上の数値を入力してください。`, 'warning')
        this.$refs.numberAlert.focus();
        return false
      } 
      
      //判定回数の入力チェック
      if (this.formData.AClassCount >= this.formData.BClassCount){
        this.showBottomToast('A判定の判定回数は、B判定の判定回数より小さい値を指定してください', 'warning')
        this.$refs.AClassCount.focus();
        return false
      } 
      // else if (this.formData.BClassCount >= this.formData.CClassCount){
      //   this.showBottomToast('B判定の判定回数は、C判定の判定回数より小さい値を指定してください', 'warning')
      //   return false
      // }
            
      //燃費係数の入力チェック
      if (this.formData.AClassFuelParam <= this.formData.BClassFuelParam){
        this.showBottomToast('A判定の燃費係数は、B判定の燃費係数より大きい値を指定してください', 'warning')
        this.$refs.AClassFuelParam.focus();
        return false
      } else if (this.formData.BClassFuelParam <= this.formData.CClassFuelParam){
        this.showBottomToast('B判定の燃費係数は、C判定の燃費係数より大きい値を指定してください', 'warning')
        this.$refs.BClassFuelParam.focus();
        return false
      }
      
      return true
      
    },

    async setStoreProject() {
      let project = this.$store.state.project;
      project.projectId = this.projectData.project.id;
      this.$store.commit("setProject", project);
    },

    /**
     * ソートを実行します。
     */
    async runSort(column) {
      // this.$nextTick(async function() {
      await this.changeTableSort(column);
      // }.bind(this))

    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      // console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;      

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = false;
      if (fieldName == "user.name") {
        isKana = true;
      }    

      this.userList = SortUtil.sort(this.userList, fieldName, sortingType, isKana);
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },

    /**
     * 初期値にリセットボタンクリック時の処理
     */
    async onClickDefault(){
      // 確認ダイアログ表示
      this.modals.confirmDlg = true;
    },

    /**
     * 確認ダイアログ キャンセルボタンクリック時の処理
     */
    async onClickConfirmCancel(){
      // 確認ダイアログ非表示
      this.modals.confirmDlg = false;
    },

    /**
     * 確認ダイアログ OKボタンクリック時の処理
     */
    async onClickConfirmOK(){
      // 確認ダイアログ非表示
      this.modals.confirmDlg = false;
      
      // 基準燃料初期化
      this.formData.fuelMileage1 = 4.58;
      this.formData.fuelMileage2 = 3.79;
      this.formData.fuelMileage3 = 3.38;
      this.formData.fuelMileage4 = 3.09;
      this.formData.fuelMileage5 = 2.89;

      // バリデーションを行う
      await this.$refs.form.validate()
      
    },

    /**
     * フォームのバリデーション(速度超過判定閾値)
     */
    checkSpeedThreshold(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(急発進判定)
     */
    checkSuddenStartValue(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(急停止判定)
     */
    checkSuddenStopValue(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(エコ運転判定)
     */
    checkEcoDriveParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(A判定 判定回数)
     */
    checkAClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(B判定 判定回数)
     */
    checkBClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(C判定 判定回数)
     */
    checkCClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(A判定 燃費係数)
     */
    checkAClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(B判定 燃費係数)
     */
    checkBClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(C判定 燃費係数)
     */
    checkCClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費1)
     */
    checkFuelMileage1(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費2)
     */
    checkFuelMileage2(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費3)
     */
    checkFuelMileage3(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費4)
     */
    checkFuelMileage4(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費5)
     */
    checkFuelMileage5(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
