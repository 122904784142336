import TenantModel from "@/appModel/Tenant/TenantModel";
import UserInfo from "@/appUtils/UserInfo"
import { useToast } from "vue-toastification";
export default {
  data() {
    return {
      useApps: null,
      currentProjectType: "0",
      allowNamacon: false,
      allowZando: false,
    }
  },
  async created() {
    if (!this.useApps) {
      await this.getUseApp()
    }
  },
  async mounted() {
    if (!this.useApps) {
      await this.getUseApp()
    }
  },
  watch: {
    useApps: function() {
      this.allowNamacon = this.useApps == "01" || this.useApps == "11"
      this.allowZando = this.useApps == "10" || this.useApps == "11"
    }
  },
  computed: {
    _allowNamacon() {
      this.getUseApp()
      return this.useApps == "01" || this.useApps == "11"
    },
    _allowZando() {
      this.getUseApp()
      return this.useApps == "10" || this.useApps == "11"
    },
    isNamacon() {
      this.getProjectInfo()
      return this.currentProjectType == "0"
    },
    isZando() {
      this.getProjectInfo()
      return this.currentProjectType == "1"
    },
  },
  methods: {
    /**
     * 各画面から呼び出されるメソッド。
     */
    async getUseApp() {
      try {
        const user = await UserInfo.getUserInfo();
        console.log(`UseApps: ${user.group}`)
        let tenant = await TenantModel.getTenant(user.group);
        this.useApps = tenant.tenant.useApps
        console.log(`getUseApp: ${this.useApps}`)
      } catch (e) {
        console.log(e)
      }
      
    },
    getProjectInfo() {
      let timeline = this.$store.state.timeline
      if (timeline) {
        if (timeline.projectType) {
          console.log(`this.currentProjectType: ${timeline.projectType}`)
          this.currentProjectType = timeline.projectType
        }
      }
      console.log(`this.currentProjectType: ${this.currentProjectType}`)
    },
    getRoutePath(name){
      let _name = name.toLowerCase()
      switch (_name) {
        case 'projects':
          return this.isZando ? "/zandoprojects" : "/projects"
        case 'editproject':
          return this.isZando ? "/zandoeditproject" : "/editproject"
        case 'newproject':
          return this.isZando ? "/zandonewproject" : "/newproject"
        case 'vehiclelist':
          return this.isZando ? "/zandovehiclelist" : "/vehiclelist"
        case 'planlist':
          return this.isZando ? "/zandoplanList" : "/planList"
        case 'planedit':
          return this.isZando ? "/zandoplanEdit" : "/planEdit"
        case 'signage':
          return this.isZando ? "/zandosignage" : "/signage"
        case 'timeline':
          return this.isZando ? "/zandotimeline" : "/timeline"
        case 'drivinghistory':
          return this.isZando ? "/zandodrivingHistory" : "/drivingHistory"
        case 'mobiletimeline':
          return this.isZando ? "/zandomobiletimeline" : "/mobiletimeline"
        case 'mobilesignage':
          return this.isZando ? "/zandomobilesignage" : "/mobilesignage"
        case 'basearea':
          return this.isZando ? "/zandobaseArea" : "/baseArea"
        case 'drivespot':
          return this.isZando ? "/zandodriveSpot" : "/driveSpot"
        case 'drivespottypeedit':
          return this.isZando ? "/zandodriveSpotTypeEdit" : ""
        case 'drivespotsetting':
          return this.isZando ? "/zandodriveSpotSetting" : ""
        default:
          return ""
          
      }
    },
    /**
     * システム利用区分で許可された機能(生コンor残土)を使用しているかチェック
     */
    async isValidProjectType() {
      await this.getUseApp()
      let allowNamacon = false
      let allowZando = false
      allowNamacon = this.useApps == "01" || this.useApps == "11"
      allowZando = this.useApps == "10" || this.useApps == "11"
      console.log(`isValidProjectType ${this.useApps} allowNamacon:${allowNamacon} allowZando:${allowZando}`)
      // 生コン残土関連画面を開いている場合、生コンのシステム利用区分があればtrue なければfalse
      if (this.isNamacon && !allowNamacon) {
        this.showBottomToast("ログインセッションに問題が発生しました。再ログインをお願い致します。", "error")
        // ログイン画面に戻る
        this.$router.push({
          path: "/"
        });
        return false
      }
      // 残土関連画面を開いている場合、残土のシステム利用区分があればtrue なければfalse
      if (this.isZando && !allowZando) {
        this.showBottomToast("ログインセッションに問題が発生しました。再ログインをお願い致します。", "error")
        // ログイン画面に戻る
        this.$router.push({
          path: "/"
        });
        return false
      }
      return true
    },

    /**
     * システム利用区分で残土が許可されているかチェック
     */
    async isAllowZando() {
      return this._allowZando()
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },
  }
}