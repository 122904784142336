<template>
  <!-- ヘッダ1 24px -->
  <div class="d-flex" style="height:24px;">
    <div class="flex-fill align-self-center">
      <a style="font-size: 14px" @click="onClickProject"
        ><u>{{ projectName }}</u></a
      >
    </div>
    <el-button
      class="mr-1 align-self-center"
      type="result"
      size="small"
      @click="onClickDriveVue"
      >運行情報</el-button
    >
    <a
      class="mr-1 align-self-center"
      style="font-size: 11px"
      @click="displayAsPC"
      v-if="!isPhone"
      ><u>PC表示へ</u></a
    >
  </div>

  <!-- ヘッダ2 48px -->
 <div
   :class="[
    'd-flex',
    'flex-sm-wrap',
    isZando ? 'zando-header' : 'app-header',
  ]"
  style="height: 48px;"
  >
    <h3
      style="color:white;font-size:1.2em;"
      class="mx-1 align-self-center mb-0"
    >
      {{ targetDate }}
    </h3>
    <div
      class="ml-1 mr-1 timeLineModeAreaList flex-fill d-flex align-self-center"
      v-if="!isZando"
    >
      <el-select
        class="flex-fill"
        v-model="headerPouringPosition"
        loading-text="お待ち下さい"
        placeholder="予定数量未登録"
        @change="onChangePouringPosition"
      >
        <el-option
          v-for="item in signagePouringList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="align-self-center zando" v-if="isNowTime && !refreshdisabled">
      <el-button
        type="primary"
        size="small"
        style="font-weight:bold;"
        circle
        icon="fas fa-redo fa-lg"
        @click="onRefreshSignage"
      ></el-button>
    </div>
    <div class="align-self-center zando" v-if="isNowTime && refreshdisabled">
      <el-button
        type="primary"
        size="small"
        style="font-weight:bold;"
        circle
        icon="fas fa-redo fa-lg"
        @click="onRefreshSignage"
        disabled
      ></el-button>
    </div>
  </div>

  <!-- 実績 -->
  <!-- 生コン -->
  <div
    v-if="!isZando"
    class="timeline-main mt-0 signage-content"
    style="border:0px solid red; height: 100vh"
  >
    <div class="row signage-font-main no-gutters">
      <div class="col-12">
        <div class="signage-content">
          <div class="row signage-backcolor no-gutters d-flex">
            <div class="col-4 align-self-center d-flex">
              <span class="align-self-center" style="font-size:min(min(4vw,6.8vh),40px)"
                >打設実績情報</span
              >
            </div>
            <div class="col-4 align-self-center d-flex">
              <span
                class="align-self-center"
                style="font-size:min(min(4vw,6.8vh),40px)"
                v-if="isToday"
                >{{ timeString }} 現在</span
              >
            </div>
            <div class="col-4 align-self-center">
              <div class="average-time" style="font-size:min(min(3vw,5.1vh),30px)">
                平均到着時間 {{ average.arrive }}分<br />平均帰着時間
                {{ average.return }}分
              </div>
            </div>
          </div>

          <div class="row no-gutters">
            <div
              class="col-5 d-flex"
              style="max-height:15vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/note@3x.png"
                class="black ml-2 mr-2 align-self-center"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >予定</span
              >
            </div>
            <div class="col-4 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ estimate.volume }}㎥</span
              >
            </div>
            <div class="col-3 d-flex">
              <span
                class="align-self-center"
                style="font-size:min(min(5vw,8.5vh),100px)"
              ></span>
            </div>
          </div>
          <div class="row blink no-gutters" v-if="isToday">
            <div
              class="col-5 d-flex"
              style="max-height:20vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/hopper@3x.png"
                class="red ml-2 mr-2 align-self-center"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >打設完了</span
              >
            </div>
            <div class="col-4 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ pouringEnd.volume }}㎥</span
              >
            </div>
            <div class="col-3 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ pouringEnd.number }}台</span
              >
            </div>
          </div>
          <div class="row blink no-gutters" v-if="isToday">
            <div
              class="col-5 d-flex"
              style="max-height:20vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/hopper@3x.png"
                class="red ml-2 mr-2 align-self-center"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >打設/待機</span
              >
            </div>
            <div class="col-4 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ pouringOrWaiting.volume }}㎥</span
              >
            </div>
            <div class="col-3 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ pouringOrWaiting.number }}台</span
              >
            </div>
          </div>
          <div class="row blink no-gutters" v-if="isToday">
            <div class="col-9"></div>
            <div
              class="col-3 signage-subtext d-flex"
              style="font-size:min(min(1.5vw,2.55vh),30px)"
            >
              打設開始({{ pouringOrWaiting.lastTime }})
            </div>
          </div>
          <div class="row no-gutters" v-if="isToday">
            <div
              class="col-5 d-flex"
              style="max-height:20vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/mixer@3x.png"
                class="black ml-2 mr-2 align-self-center"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >運搬中</span
              >
            </div>
            <div class="col-4 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ transporting.volume }}㎥</span
              >
            </div>
            <div class="col-3 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ transporting.number }}台</span
              >
            </div>
          </div>
          <div class="row no-gutters" v-if="!isToday">
            <div
              class="col-5"
              style="max-height:20vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/hopper@3x.png"
                class="black ml-2 mr-2"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span style="font-size:min(5vw,80px)">実績</span>
            </div>
            <div class="col-4">
              <span style="font-size:min(5vw,80px)">{{ achive.volume }}㎥</span>
            </div>
            <div class="col-3">
              <span style="font-size:min(5vw,80px)">{{ achive.number }}台</span>
            </div>
          </div>
          <div class="row no-gutters" v-if="isToday">
            <div
              class="col-5 d-flex"
              style="max-height:20vh;min-height:1vh;border:0px solid red;"
            >
              <img
                src="@/assets/sandtimer@3x.png"
                class="black ml-2 mr-2 align-self-center"
                style="width: min(5vw,100px);height: min(8.5vh,100px);object-fit: contain;-webkit-backface-visibility: hidden;"
              />
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >残出荷数</span
              >
            </div>
            <div class="col-4 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ remain.volume }}㎥</span
              >
            </div>
            <div class="col-3 d-flex">
              <span class="align-self-center" style="font-size:min(min(5vw,8.5vh),100px)"
                >{{ remain.number }}台</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 残土 -->
  <div v-if= isZando class="timeline-main mt-0" style="border:0px solid red">
    <div class="row signage-font-main no-gutters ">
      <div class="col-12 zando-signage-backcolor">
        <div class="row align-items-end zando-signage-backcolor no-gutters">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between">
              <div class="text-nowrap">
                <span
                  style="font-size:min(4vw,50px); margin-left: 2%; d-flex align-items-center"
                >
                  運搬実績情報
                </span>
              </div>
              <div class="pl-2 text-nowrap ">
                <span style="font-size:min(min(4vw,6.8vh),40px)" v-if="isToday">
                  {{timeString}} 現在
                </span>
              </div>
              <div class="pr-2" style="font-size:1rem;">
                <div class="d-flex align-items-center justify-content-end">
                  <!-- コンボボックス -->
                  <div class="text-right">
                    <el-select
                      v-model="dumpSiteAverage"
                      placeholder="予定数量未登録"
                      @change="onChangeDumpAverage(dumpSiteAverage)"
                      style="width:60%;"
                    >
                      <el-option
                        v-for="item in dumpSiteAverageList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div
                    class="flex-grow-1 ml-2"
                    style=""
                  >
                    <div
                      v-for="item in averageList"
                      :key="item.endAreaId"
                      style="display: flex; align-items: center; justify-content: space-between; font-size: 20px; line-height: 1.2; font-weight: 300;"
                    >
                      
                      <span class="text-nowrap" style="text-align: right;font-size:min(min(3vw,5.1vh),30px);"
                        >{{ item.title }} {{ item.averageArrive
                        }}{{ item.averageReturn }} 分</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-6 col-md-12">
            <!-- コンボボックス -->
            <div class="col-12"  style="font-size:1rem;">
              <el-select 
                v-model="dumpSite"
                placeholder="予定数量未登録"
                @change="onChangeDumpSite(dumpSite)"
                style="width:60%;"
                >
                <el-option
                v-for="item in dumpSiteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >   
                </el-option>
              </el-select>
            </div>

              <!-- 土捨場と岩種のリスト -->
            <!-- <div class="w-100"></div>
            <div class="d-flex">
              <div class="col-12" style="height: 75px; overflow: auto; margin-top: 1%; border: 1px solid #000000;">
                <div
                  v-for="item in this.zandoVolumeList"
                  :key="item.rockName"
                  style="display: flex; align-items: center; justify-content: space-between; font-size: 20px; line-height: 1.2; font-weight: 300;"
                >
                  <span style="text-align: left; font-size: min(5vw, 20px)">{{ item.endAreaId }} {{ item.rockName }}：</span>
                  <span style="text-align: right; font-size: min(5vw, 20px)">{{ item.totalLoadCapacity }}㎥</span>
                </div>
              </div>
            </div> -->
            <div class="col-12" style="padding: 0">
              <div
                class="row no-gutters row-line-height"
                style="margin-top: 1%;"
              >
                <div
                  class="col-8 d-flex align-items-center"
                  style="border: 0px solid red;"
                >
                  <div class="d-flex align-items-center text-nowrap">
                    <img
                      src="@/assets/note@3x.png"
                      class="black ml-2 mr-2"
                      style="max-width: 5vw; height: auto; object-fit: contain; max-height: 15vh;"
                    />
                    <span style="font-size: min(5vw, 60px); line-height: 1;"
                      >総搬出量</span
                    >
                  </div>
                </div>
                <div
                  class="col-4 d-flex align-items-center justify-content-end"
                >
                  <span class="text-nowrap" style="font-size: min(4vw, 60px); line-height: 1;"
                    >{{ amountAll }}㎥</span
                  >
                  <el-button
                    class="listButton"
                    size="medium"
                    style="margin-left:5px"
                    @click="onClickOpenDlg"
                    >岩種詳細</el-button
                  >
                </div>
              </div>
            </div>
                
            <div class="zando-signage-content ml-2 mr-2 mb-4">
              
              <div class="col-12">
                <span style="font-size:min(4vw,30px)"> {{ targetDate }} 分 </span>

                <div
                  class="row blink no-gutters row-line-height"
                  style="margin-bottom:1.8vw;"
                  v-if="isToday"
                >
                  <div
                    class="col-8 d-flex align-items-center"
                    style="max-height:20vh;min-height:1vh;border:0px solid red;"
                  >
                    <img
                      src="img/concrete/dump_upload_black.png"
                      class="red ml-2 mr-2 d-flex"
                      style="min-width:10px;max-width:5vw;width:100%;min-height:10px;max-height:5vw;height:100%;object-fit: contain;-webkit-backface-visibility: hidden;"
                    />
                    <span style="font-size:min(5vw,50px)">搬出量</span>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <span style="font-size:min(5vw,50px)"
                      >{{ dateAllAmount }}㎥</span
                    >
                  </div>
                </div>
                <div
                  class="row no-gutters row-line-height"
                  style="margin-bottom:1.8vw;"
                  v-if="!isToday"
                >
                  <div
                    class="col-8 d-flex align-items-center"
                    style="max-height:20vh;min-height:1vh;border:0px solid red;"
                  >
                    <img
                      src="img/concrete/dump_upload_black.png"
                      class="black ml-2 mr-2"
                      style="min-width:10px;max-width:5vw;width:100%;min-height:10px;max-height:5vw;height:100%;object-fit: contain;-webkit-backface-visibility: hidden;"
                    />
                    <span style="font-size:min(5vw,50px)">搬出量</span>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <span style="font-size:min(5vw,50px)"
                      >{{ dateAllAmount }}㎥</span
                    >
                  </div>
                </div>
                <div
                  class="row no-gutters row-line-height"
                  style="margin-bottom:1.8vw;"
                  v-if="isToday && isDatePlanTotalVolume"
                >
                  <div
                    class="col-8 d-flex align-items-center"
                    style="max-height:20vh;min-height:1vh;border:0px solid red;"
                  >
                    <img
                      src="img/concrete/dump_black.png"
                      class="black ml-2 mr-2"
                      style="min-width:10px;max-width:5vw;width:100%;min-height:10px;max-height:5vw;height:100%;object-fit: contain;-webkit-backface-visibility: hidden;"
                    />
                    <span class="text-nowrap" style="font-size:min(5vw,50px)">予定量</span>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <span style="font-size:min(5vw,50px)"
                      >{{ datePlanTotalVolume }}㎥</span
                    >
                  </div>
                </div>
                <div
                  class="row no-gutters row-line-height"
                  style="margin-bottom:1.8vw;"
                  v-if="isToday && isUnitNumber"
                >
                  <div
                    class="col-8 d-flex align-items-center"
                    style="max-height:20vh;min-height:1vh;border:0px solid red;"
                  >
                    <img
                      src="img/concrete/dump_enpty_black.png"
                      class="black ml-2 mr-2"
                      style="min-width:10px;max-width:5vw;width:100%;min-height:10px;max-height:5vw;height:100%;object-fit: contain;-webkit-backface-visibility: hidden;"
                    />
                    <span class="text-nowrap" style="font-size:min(5vw,50px)">残必要台数</span>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <span style="font-size:min(5vw,50px)"
                      >{{ unitNumber }}台</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12 text-right">
            <div class="d-flex ">
              <div class="w-100 h-100 align-self-end">
            <!-- <div style="height:200px;width:300px "> -->
              <Line
                style="height:100%;width:95%;"
                :chart-options="signageChartLineOptions"
                :chart-data="signagechartLineData"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

     <!-- 岩種リスト表示モーダル -->
  <modal v-model:show="modals.rockTypeDlg">
    <template v-slot:header>
        <h5 class="modal-title" id="modalLabel">岩種毎の排出量</h5>
    </template>

    <!-- 土捨場と岩種のリスト -->
    <div class="w-100"></div>
    <div class="col-12" style="height: 75px; overflow: auto; border: 1px solid #000000;">
      <div
        v-for="item in this.zandoVolumeList"
        :key="item.rockName"
        style="display: flex; align-items: center; justify-content: space-between; font-size: 20px; line-height: 1.2; font-weight: 300;"
      >
        <span style="text-align: left;">{{ item.endAreaId }} {{ item.rockName }}：</span>
        <span style="text-align: right;">{{ item.totalLoadCapacity }}㎥</span>
      </div>
    </div>

    <div class="row mt-2 mb-2 ml-4 mr-4">
      <div class="col-sm-4 mx-auto zando">
        <base-button type="primary" block @click="modals.rockTypeDlg = false">OK</base-button>
      </div>
    </div>
  </modal>

</template>

<style scoped>
/** このVueだけのスタイル */

.app-header {
  height: auto;
  width: 100%;
  background-color: #154ca3;
}
.zando-header {
  height: auto;
  width: 100%;
  background-color: #5A8D1D;
}

.el-button--primary {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
  padding-left: 4px;
  padding-right: 4px;
}

.el-button--result {
  min-height: 18px;
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
  padding: 2px 2px;
  font-size: 11px;
}

.signage-font-main {
  font-size: 3em;
  font-weight: 800;
  color: #001437;
}
.signage-backcolor {
  background-color: #dae3f3;
}
.zando-signage-backcolor{
  background-color: #fff;
}
.signage-subtext {
  font-size: 0.5em;
}
.signage-content {
  background-color: #fff4e7;
}
.zando-signage-content{
  background-color: #FFF;
  border: 1px solid #000000;
  }
.summary-content {
  font-size: 0.5em;
  color: #333333;
}
.remain-content {
  color: #ff0000;
}
.fa .fas {
  line-height: 1.5em;
}
.blink {
  animation: blinkAnime 0.3s infinite alternate;
}
@keyframes blinkAnime {
  0% {
    color: #800000;
    text-decoration: none;
    text-shadow: none;
  }
  100% {
    color: #ff2222; /*text-shadow: -1px 1px 8px #ff7777, 1px -1px 8px #ff7777;*/
  }
}
.red {
  filter: invert(15%) sepia(95%) saturate(6932%) hue-rotate(358deg)
    brightness(95%) contrast(112%);
}
.black {
  filter: brightness(0%);
}
.average-time {
  line-height: 1.5 !important;
}
.row-line-height {
  line-height: 1.05;
}
</style>

<style lang="scss" scoped>
.timeLineModeAreaList ::v-deep .el-input__inner {
  height: 36px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: #fff;
  background-color: #154ca3 !important;
  border-color: #fff;
  font-size: 14pt;
  font-weight: 600;
  height: 36px !important;
  padding-right: 0px !important;
}

.zando .el-button--primary {
  color: #fff;
  background-color: #2A4A03;
  border-color: #3c6904;
}
</style>

<style></style>

<script>
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
import MixinSignage from "./MixinSignage.js";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
// mixin対応コメント化
// import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
// import PlanModel from "@/appModel/Plan/PlanModel";
// import VolumeModel from "@/appModel/Volume/VolumeModel";
// import DriveModel from "@/appModel/Drive/DriveModel";
// import { useToast } from "vue-toastification";
// import Decimal from "decimal.js";
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Filler, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale, Filler);
import Modal from "@/components/Modal";
import Logger from "@/appViews/common/Logger.js";
export default {
  components: {
    Line,
    Modal
  },

  mixins: [MixinSignage, ValidSession, UseApps, Logger],

  data() {
    return {
      userId: "",
      projectId: "",
      projectName: "",
      targetDate: "",
      isNowTime: true,
      signagePlanId: "",
      volumeId: "",
      signagePlanList: [],
      signageListArea: [],
      signageVolumlist: [],
      pouringList: [],
      estimate: { volume: 0 },
      pouringEnd: { volume: 0, number: 0 },
      pouringOrWaiting: { volume: 0, number: 0, lastTime: "-" },
      transporting: { volume: 0, number: 0 },
      remain: { volume: 0, number: 0 },
      average: { arrive: 0, return: 0 },
      achive: { volume: 0, number: 0 },
      timeString: DateUtil.dateStringBase(new Date(), "HH:mm"),
      timer: null,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）
      isPhone: false,
      headerPouringPosition: "",
      signagePouringList: [],
      refreshdisabled: false,
      refreshIntervalSecond: 60,
      updateIntervalMinutes: 1,
      refreshIntervalId: undefined,
      dumpSiteList: [],
      dumpSiteAverageList: [],
      dumpSite: "",
      dumpSiteAverage: "",
      endAreaId: "",
      zandoVolumeList: [],
      averageList: [],
      averageAllList: [],
      amountAll: 0,
      dateAllAmount: 0,
      datePlanTotalVolume: 0,
      unitNumber: 0,
      signagechartLineData: {
        responsive: true,
        // maintainAspectRatio: false,
        labels: [],
        datasets: [
          {
            label: null,
            data: [],
            borderColor: null,
            backgroundColor: null,
            lineTension: 0,            
            stack: 1, 
            fill: 'origin',
          }                  
        ],
      },
      signageChartLineOptions: {
        responsive: true,
        // maintainAspectRatio: false,
        animation: false,
        scales: {
          x: {
            categoryPercentage: 0.5,
            // stacked: true,
          },
          y: {
            type: 'linear',
            min: 0,
            stacked: true,
          },
              
        },
        plugins: {
          filler: {
            // drawTime: 'beforeDraw',
            propagate: true,
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontSize: 14,
              fontColor: 'rgb(255, 255, 255)',
              color: '#000000'
            }
          }          
        },
      },
      modals: {
        rockTypeDlg: false,
      },              
    };
  },
  computed: {
    vuename() {
      return "MobileSignageInfo.vue";
    },
    isToday() {
      return (
        DateUtil.dateStringBase(new Date(), "YYYY/MM/DD") == this.targetDate
      );
    },
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.infoLog(
      "created",
      `Start Vuex：projectId(${this.$store.state.signage.projectId})、projectName(${this.$store.state.signage.projectName})、targetDate(${this.$store.state.signage.targetDate})`
    );
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  async mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.infoLog(`mounted`, `Start ${this.vuename}`);
    await this.init();
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    if (this.refreshIntervalId) clearInterval(this.refreshIntervalId);
  },
  beforeRouteLeave() {
    this.infoLog(`beforeRouteLeave`, `End`);
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      // 処理中インジケーター表示
      let loader = this.showLoader();
      try {
        this.debugLog("init");
        // プロジェクトID
        this.projectId = this.$store.state.signage.projectId;
        this.userId = this.$store.state.user.userId;
        // プロジェクト名
        this.projectName = this.$store.state.signage.projectName;
        // 表示対象日
        this.targetDate = this.$store.state.signage.targetDate;
        // 予定数量の打設箇所ID
        this.volumeId = this.$store.state.signage.volumeId;

        if (!this.projectId) {
          // プロジェクトIDが指定されていない場合は、プロジェクト一覧画面に戻す
          this.$router.push({
            path: "/projects",
          });
        }

        // 当日かどうか
        let isToday = false;
        if (
          this.targetDate == DateUtil.dateStringBase(new Date(), "YYYY/MM/DD")
        ) {
          isToday = true;
        }
        this.isNowTime = isToday;

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        if (window.innerWidth < 768) {
          this.isPhone = true;
        }

        // 運行情報
        this.$nextTick(async function(){
          await this.getListItemData()
          //this.timer = window.setInterval(this.reload, 60000);
          if (this.isZando) {
            await this.updateZandoUI();
            // 平均時間表示
            this.setAverageValue(this.dumpSite);
          } else {
            this.updateUI()
          }
        }.bind(this))

        // 連打防止
        this.repeatedHitsFlg = false;
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    async getListItemData() {
      await this.getSignagePlan()
      await this.getSignageAreaList()
      await this.getSignageVolumeList()

      if (this.isZando) {
        // セレクトボックスの値生成（生コン用）   
        await this.makeDumpSiteList();
        if (this.endAreaId == "") {
          this.dumpSite = ""
        }
        await this.getAllVolumeList();
      } else {
        // セレクトボックスの値生成（生コン用）
        await this.makePouringList()
        // 初期表示用に打設IDをセットしておく
        if (this.volumeId == "") {
          if (this.signageVolumlist.length > 0) {
            this.volumeId = this.signageVolumlist[0].volume.id
          }
        }
        //alert(`${this.volumList[0].volume.id} ${this.volumeId}`)
        if (String(this.pouringList[0].id).length > 0) {
          this.signagePouringList = this.pouringList;
        } else {
          this.signagePouringList = [];
        }

        this.headerPouringPosition = this.volumeId;
        if (!this.headerPouringPosition) {
          if (this.signagePouringList.length > 0) {
            //1件目を初期選択状態とする
            this.headerPouringPosition = this.signagePouringList[0].id;
          }
        }
      }

      //this.isLoading = false;
    },

    async onRefreshSignage() {
      // リフレッシュボタンを非活性にする
      this.refreshdisabled = true;
      this.timer = setTimeout(this.freeReflesh, 3000);

      await this.reload();
    },

    /**
     * リフレッシュボタンを活性化する
     */
    freeReflesh() {
      clearTimeout(this.timer);
      this.refreshdisabled = false;
    },

    onClickProject() {
      
      this.$router.push({
        path: this.getRoutePath(`projects`),
      });
    },

    displayAsPC() {
      
      this.$router.push({
        path: this.getRoutePath(`signage`),
      });
    },

    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.targetDate;
      store.volumeid = this.volumeId;

      await this.$store.commit("setTimeline", store);

      // 運行情報画面表示
      this.$router.push({
        path: "/mobiletimeline",
      });
    },
    
    async onChangePouringPosition(id) {
      this.volumeId = id;
      await this.reload();
    },

    async onChangeDumpSite(id) {
      this.endAreaId = id;
      this.pouringPosition = null;
      await this.reload()
      // 平均時間表示
      this.setAverageValue(id);
    },

    //平均時間の土捨場選択コンボボックス
    async onChangeDumpAverage (id) {
      // 平均時間表示
      this.setAverageValue(id);
    },

    // 平均時間表示
    async setAverageValue(id) {
      // idが未設定の時は「全て」
      if (id == null || id == "") {
        id = this.averageAllList[0].endAreaId
      }

      // 対象の土捨場の到着、帰着平均時間を表示
      this.averageList = [];
      for (let i=0; i<this.averageAllList.length; i++) {
        let work = this.averageAllList[i];
        if (work.endAreaId == id) {
          this.averageList.push(work);
        }
      }
    },

    //岩種詳細モーダルを開く
    async onClickOpenDlg() {
      this.modals.rockTypeDlg = true;
    },

    // mixin対応コメント化 ▼    
    // async reload() {
    //   // 処理中インジケーター表示
    //   let loader = this.showLoader();
    //   try {
    //     await this.getPlan();
    //     await this.getAreaList();
    //     await this.getVolumeList();
    //     await this.makePouringList();
    //     await this.updateUI();
    //   } catch (e) {
    //     this.errorLog(`${e.message}`);
    //   } finally {
    //     this.hideLoader(loader);
    //   }
    // },
    // async getDrive() {},
    // /**
    //  * 打設予定を登録
    //  */
    // async getPlan() {
    //   let targetYmd = this.targetDate.replaceAll("/", "");
    //   this.planList = await PlanModel.getPlanListFromYMD(
    //     this.projectId,
    //     targetYmd
    //   );
    //   if (this.planList.length == 0) {
    //     return;
    //   }
    //   // 指定日でplanレコードは1件だけ
    //   this.planId = this.planList[0].plan.id;
    //   this.debugLog(`getPlan ${this.planId}`);
    // },

    // /**
    //  * 表示内容の更新
    //  */
    // async updateUI() {
    //   this.infoLog(
    //     `projectId: ${this.projectId} userid: ${this.userId} targetDate: ${this.targetDate}`
    //   );

    //   this.debugLog(`updateUI ${this.volumeId}`);
    //   // 現在時刻を更新
    //   this.timeString = DateUtil.dateStringBase(new Date(), "HH:mm");

    //   // 打設予定を取得 ------------------------
    //   // 選択した打設箇所に紐づく予定数量を取得。
    //   let volume = this.volumList.filter(
    //     function(item) {
    //       return item.volume.id == this.volumeId;
    //     }.bind(this)
    //   );

    //   let baseAreaId = "";
    //   let estimate = new Decimal(0);
    //   if (volume.length > 0) {
    //     // 打設予定
    //     estimate = new Decimal(volume[0].volume.totalVolume);
    //     this.estimate.volume = estimate.toNumber().toFixed(2);
    //     baseAreaId = volume[0].volume.endAreaId;
    //   } else {
    //     return;
    //   }
    //   let targetYmd = this.targetDate.replaceAll("/", "");
    //   // 指定プロジェクト、日付、拠点でDBから運行情報(drive#)取得する。コンクリートの運搬のみ対象
    //   // その後、打設箇所でフィルターする
    //   let currentDrives = (
    //     await DriveModel.getDriveListForSignage(
    //       this.projectId,
    //       targetYmd,
    //       baseAreaId
    //     )
    //   ).filter(function(item) {
    //     return (
    //       item.drive.pouringPosition == volume[0].volume.pouringPosition &&
    //       item.drive.carryType == "1"
    //     );
    //   });

    //   // ------------------------------
    //   // 打設完了 設定
    //   // ------------------------------
    //   // 打設が終了したレコードを抽出
    //   let calcVolume = new Decimal(0.0).toNumber();
    //   let calcNumber = 0;
    //   const pouringEndList = currentDrives.filter(
    //     function(item) {
    //       return (
    //         item.drive.pouringEndTime != null && item.drive.pouringEndTime != ""
    //       );
    //     }.bind(this)
    //   );

    //   // 抽出したレコードから最大積載量と台数を計算
    //   pouringEndList.forEach((element) => {
    //     calcVolume += element.drive.maxCapacity;
    //     calcNumber++;
    //   });

    //   // 表示用変数に値を設定
    //   this.pouringEnd.volume = new Decimal(calcVolume).toNumber().toFixed(2);
    //   this.pouringEnd.number = calcNumber;

    //   // ------------------------------
    //   // 打設/待機 設定
    //   // ------------------------------
    //   // 現場入場もしくは打設中レコードを抽出
    //   calcVolume = new Decimal(0.0).toNumber();
    //   calcNumber = 0;
    //   let lastStartTime = "-";
    //   const pouringOrWaitingList = currentDrives
    //     .filter(
    //       function(item) {
    //         return (
    //           (item.drive.siteEndTime &&
    //             !item.drive.pouringStartTime &&
    //             !item.drive.pouringEndTime) ||
    //           (item.drive.siteEndTime &&
    //             item.drive.pouringStartTime &&
    //             !item.drive.pouringEndTime)
    //         );
    //       }.bind(this)
    //     )
    //     .slice()
    //     .sort(function(a, b) {
    //       if (a.drive.pouringStartTime < b.drive.pouringStartTime) return -1;
    //       if (a.drive.pouringStartTime > b.drive.pouringStartTime) return 1;
    //     });

    //   // 抽出したレコードから最大積載量と台数を計算
    //   pouringOrWaitingList.forEach((element) => {
    //     calcVolume += element.drive.maxCapacity;
    //     calcNumber++;
    //     if (element.drive.pouringStartTime) {
    //       lastStartTime = DateUtil.getFormatString(
    //         element.drive.pouringStartTime,
    //         "HH:mm"
    //       );
    //     }
    //   });

    //   // 表示用変数に値を設定
    //   this.pouringOrWaiting.volume = new Decimal(calcVolume)
    //     .toNumber()
    //     .toFixed(2);
    //   this.pouringOrWaiting.number = calcNumber;
    //   this.pouringOrWaiting.lastTime = lastStartTime;

    //   // ------------------------------
    //   // 運搬中 設定
    //   // ------------------------------
    //   // 運搬中のレコードを抽出
    //   calcVolume = new Decimal(0.0).toNumber();
    //   calcNumber = 0;
    //   const transportingList = currentDrives.filter(
    //     function(item) {
    //       return (
    //         !item.drive.siteEndTime &&
    //         !item.drive.pouringStartTime &&
    //         !item.drive.pouringEndTime
    //       );
    //     }.bind(this)
    //   );

    //   // 抽出したレコードから最大積載量と台数を計算
    //   transportingList.forEach((element) => {
    //     calcVolume += element.drive.maxCapacity;
    //     calcNumber++;
    //   });

    //   // 表示用変数に値を設定
    //   this.transporting.volume = new Decimal(calcVolume).toNumber().toFixed(2);
    //   this.transporting.number = calcNumber;

    //   // ------------------------------
    //   // 実績 設定
    //   // ------------------------------
    //   //残量の計算。打設完了時刻のあるレコードを積算、予定量との差を出す--------------
    //   let transportEndDrives = currentDrives.filter(
    //     function(item) {
    //       this.debugLog(`残量計算 ${JSON.stringify(item, null, "\t")}`);
    //       return (
    //         item.drive.pouringPosition == volume[0].volume.pouringPosition &&
    //         item.drive.pouringEndTime != "" && item.drive.pouringEndTime != null
    //       );
    //     }.bind(this)
    //   );
    //   this.debugLog(`運搬完了: ${transportEndDrives.length}`);

    //   let transportEndVolume = new Decimal(0);
    //   transportEndDrives.forEach(function(item) {
    //     let _volume = new Decimal(item.drive.maxCapacity);
    //     transportEndVolume = transportEndVolume.plus(_volume);
    //   });

    //   // 打設量実績----------------------------
    //   this.achive.volume = transportEndVolume.toNumber().toFixed(2);
    //   // 打設台数
    //   this.achive.number = transportEndDrives.length

    //   // ------------------------------
    //   // 残出荷数  設定
    //   // ------------------------------
    //   const remainList = currentDrives.slice().sort(function(a, b) {
    //     if (a.drive.maxCapacity > b.drive.maxCapacity) return -1;
    //     if (a.drive.maxCapacity < b.drive.maxCapacity) return 1;
    //   });
    //   let maxCapacity = 0.0;
    //   if (remainList != null && remainList.length > 0) {
    //     maxCapacity = remainList[0].drive.maxCapacity;
    //   }
    //   const remainVolume =
    //     this.estimate.volume -
    //     this.pouringEnd.volume -
    //     this.pouringOrWaiting.volume -
    //     this.transporting.volume;
    //   const remainNumber =
    //     maxCapacity == 0 ? 0 : Math.ceil(remainVolume / maxCapacity);
    //   this.remain.volume = new Decimal(remainVolume).toNumber().toFixed(2);
    //   this.remain.number = remainNumber < 0 ? 0 : remainNumber;

    //   // 平均到着時間、平均帰着時間を算出----------------
    //   let arriveDiffList = [];
    //   let returnDiffList = [];

    //   currentDrives.forEach(function(item) {
    //     if (
    //       item.drive.factoryStartTime != "" &&
    //       item.drive.factoryStartTime != null &&
    //       item.drive.siteEndTime != "" &&
    //       item.drive.siteEndTime != null
    //     ) {
    //       // 工場出発時刻と現場到着時刻の差分を取得する
    //       arriveDiffList.push(
    //         DateUtil.diff(
    //           item.drive.siteEndTime,
    //           item.drive.factoryStartTime,
    //           "m",
    //           false
    //         )
    //       );
    //     }

    //     if (
    //       item.drive.siteStartTime != "" &&
    //       item.drive.siteStartTime != null &&
    //       item.drive.factoryEndTime != "" &&
    //       item.drive.factoryEndTime != null
    //     ) {
    //       // 工場出発時刻と現場到着時刻の差分を取得する
    //       returnDiffList.push(
    //         DateUtil.diff(
    //           item.drive.factoryEndTime,
    //           item.drive.siteStartTime,
    //           "m",
    //           false
    //         )
    //       );
    //     }
    //   });

    //   // 平均到着時間を算出
    //   if (arriveDiffList.length > 0) {
    //     this.average.arrive = Math.round(
    //       arriveDiffList.reduce((sum, value) => {
    //         return sum + value;
    //       }) / arriveDiffList.length
    //     );
    //   } else {
    //     this.average.arrive = 0;
    //   }

    //   // 平均帰着時間を算出
    //   if (returnDiffList.length > 0) {
    //     this.average.return = Math.round(
    //       returnDiffList.reduce((sum, value) => {
    //         return sum + value;
    //       }) / returnDiffList.length
    //     );
    //   } else {
    //     this.average.return = 0;
    //   }
    // },
    // /**
    //  * 工区の一覧取得し、セレクトボックスに値セット
    //  */
    // async getAreaList() {
    //   const lists = await BaseAreaModel.getBaseAreaList(this.projectId);

    //   lists.forEach(
    //     function(list) {
    //       let item = {
    //         id: list.baseArea.id,
    //         name: list.baseArea.areaName,
    //       };
    //       this.listArea.push(item);
    //     }.bind(this)
    //   );
    //   // console.log(`getAreaList ${JSON.stringify(this.listArea)}`)
    // },
    // async getVolumeList() {
    //   // プロジェクトID、予定IDを指定して取得
    //   if (this.planId == "") {
    //     // 指定日時での予定が無いため終了
    //     return;
    //   }
    //   const searchKey = `${this.projectId}#${this.planId}`;
    //   let listVolum = await VolumeModel.getVolumeList(searchKey);
    //   // console.log(`getVolumeList listVolum ${JSON.stringify(listVolum)}`)
    //   // 到着拠点IDを名称に変更
    //   listVolum.forEach((Item) => {
    //     for (let i = 0; i < this.listArea.length; i++) {
    //       // console.log(`getVolumeList find ${this.listArea[i].id} ${Item.volume.endAreaId}`)
    //       if (this.listArea[i].id == Item.volume.endAreaId) {
    //         Item.volume.endAreaName = this.listArea[i].name;
    //         break;
    //       }
    //     }
    //   });

    //   // ソートして一覧に表示（工区名称でソート）
    //   this.volumList = listVolum.sort(function(a, b) {
    //     return a.volume.endAreaName < b.volume.endAreaName ? -1 : 1;
    //   });
    // },
    // async makePouringList() {
    //   this.pouringList = [];
    //   // リスト選択用に値と表示名の単純なリストを作成する
    //   this.volumList.forEach(
    //     function(item) {
    //       this.pouringList.push({
    //         id: item.volume.id,
    //         name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
    //       });
    //     }.bind(this)
    //   );

    //   if (this.pouringList.length == 0) {
    //     this.pouringList.push({
    //       id: "",
    //       name: ``,
    //       endAreaId: ``,
    //       pouringPosition: ``,
    //     });
    //     this.showBottomToast("予定が登録されていません。", "info");
    //   }
    // },

    // /**
    //  * トーストでメッセージを表示
    //  */
    // showBottomToast(message, type) {
    //   this.runToast(message, "bottom-center", type);
    // },
    // /**
    //  * トーストでメッセージを表示（処理）
    //  */
    // runToast(message, pos, type) {
    //   const toast = useToast();
    //   toast[type](message, {
    //     hideProgressBar: true,
    //     icon: false,
    //     toastClassName: ["custome-toast-class"],
    //     closeButton: false,
    //     position: pos,
    //   });
    // },
    // debugLog(message) {
    //   appLog.debugLog(this.vuename, this.$store.state.user.userId, message);
    // },
    // infoLog(message) {
    //   appLog.infoLog(this.vuename, this.$store.state.user.userId, message);
    // },
    // errorLog(message) {
    //   appLog.errLog(this.vuename, this.$store.state.user.userId, message);
    // },

    // /**
    //  * 処理中インジケーターを表示します。
    //  * @returns 表示したインジケーター
    //  */
    // showLoader() {
    //   return this.$loading.show({
    //     container: null,
    //     canCancel: false,
    //     color: "#003C9C",
    //     width: 64,
    //     height: 64,
    //     backgroundColor: "#ffffff",
    //     opacity: 0.5,
    //     isFullPage: true,
    //     zIndex: 9999,
    //   });
    // },
    // /**
    //  * 処理中インジケーターを閉じます。
    //  * @paramas {Object} loader 表示したインジケーター
    //  */

    // hideLoader(loader) {
    //   loader.hide();
    // },
  },
};
</script>
