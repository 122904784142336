<template>
  <application-header
    :title="appTitle"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>

    <!----------------------------------------------------
      運行スポット登録、運行スポットタイプ編集ボタン
    ------------------------------------------------------>
    <div class="d-flex button-area">
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpot">スポット登録</base-button>
        </div>
        
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpotTypeEdit">スポットタイプ編集</base-button>
        </div>
        <div class="col-sm-2">
            <base-button type="primary" block :disabled="true" @click="onClickDriveSpotSetting">スポット設定</base-button>
        </div>

        <div class="col-sm-5"></div> 

        <div class="col-sm-1">
            <base-button type="primary" block @click="onClickSaveIntervalsetting">保存</base-button>
        </div>

    </div>

    <!----------------------------------------------------
      設定
    ------------------------------------------------------>
    <div class="setting_area">
        <div style="margin-top:5px;margin-left:30px">
            <h2>運行間隔設定</h2>

        </div>

        <div style="margin-left:50px;margin-top:20px" >
            <div class="d-flex align-items-center">
            <h3>運行間隔遅延時間<require-tag /></h3>
              <el-tooltip
                  class="item"
                  effect="dark"
                  content="クリックでマニュアルを表示します"
                  placement="right"
                >
                <a href="#" class="" v-on:click.stop.prevent="toManual">
                  <i class="fas fa-book mb-2 ml-2"></i>
                </a>
              </el-tooltip>
            </div>
            <div class="d-flex align-items-center mb-3">
                <el-input-number
                class="w-20"
                v-model="settingFormData.delayTime"
                :controls="false"
                :min="1"
                :max="60"
                step-strictly
                placeholder="数値で入力"
                ></el-input-number>
                <h5 class="mx-2 mt-3">分</h5>
            </div>
            <h5 v-if="false" style="margin-left:10px">運行間隔スポット侵入時に直前の車両との時間差がこの値以下であれば、設定されているメッセージが端末から流れます。</h5>
            <table v-if="false" border="1" style="margin-left:10px">
              <tr bgcolor="yellow"><th width="200px"><h5>直前車両との時間差</h5></th><th width="400px"><h5>動作</h5></th></tr>
              <tr><th><h5>直前車両無し</h5></th><th><h5>音声なし</h5></th></tr>
              <tr><th><h5>時間差 >= 設定値</h5></th><th><h5>およそ◯分前に車両が通過しました。距離は◯ｍです</h5></th></tr>
              <tr><th><h5>時間差 &lt; 設定値</h5></th><th><h5>およそ◯分前に車両が通過しました。距離は◯ｍです<br>[運行間隔スポット定義のメッセージ]</h5></th></tr>
            </table>  
        </div> 

        <div style="margin-left:50px;margin-top:50px" >
            <div class="d-flex align-items-center">
              <h3>渋滞滞留時間<require-tag /></h3>
              <el-tooltip
                  class="item"
                  effect="dark"
                  content="クリックでマニュアルを表示します"
                  placement="right"
                >
                <a href="#" class="" v-on:click.stop.prevent="toManual">
                  <i class="fas fa-book mb-2 ml-2"></i>
                </a>
              </el-tooltip>
              
            </div>        
            
            <div class="d-flex align-items-center mb-3">
                <el-input-number
                class="w-20"
                v-model="settingFormData.dwellTime"
                :controls="false"
                :min="1"
                :max="60"
                step-strictly
                placeholder="数値で入力"
                ></el-input-number>
                <h5 class="mx-2 mt-3">分</h5>
            </div>
            
            <!-- <h5 style="margin-left:10px">運行間隔スポットエリア内に指定された時間以上とどまっていると渋滞と判定します。</h5>
            <h5 style="margin-left:10px">端末から「渋滞しています」の音声案内が行われます。</h5>
            <h5 style="margin-left:10px">運行情報画面では以下のような表示となります。</h5>
            <div class="d-flex">
              <div>
                <img style="margin-left:10px;width:279px;height:84px" src="img/concrete/inTraffic_List.png">
                <h5 style="margin-left:20px">※運行一覧</h5>
              </div>
              <div>
                <img style="margin-left:20px;width:282px" src="img/concrete/inTraffic_Map.png">
                <h5 style="margin-left:30px">※地図</h5>
              </div> 
            </div>  -->
        </div>
        
        <!-- <div class="col-sm-1" style="margin-left:50px;margin-top:20px">
            <base-button type="primary" block @click="onClickSaveIntervalsetting"
                >保存</base-button>
        </div> -->
    </div>

    <!-- 設定（運行間隔）編集確認モーダル -->
    <modal v-model:show="modals.confirmIntervalSettingDlg">
        <template v-slot:header>
            <h5 class="modal-title" id="modalLabel">運行間隔設定</h5>
        </template>
        <div class="mb-5">
            運行間隔設定値を更新します。よろしいですか？
        </div>
        <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
            <base-button type="secondary" block @click="modals.confirmIntervalSettingDlg = false">キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
            <base-button type="primary" block @click="onClickConfirmIntervalSettingOK">更新</base-button>
            </div>
        </div>
    </modal>

</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  /* position:absolute; */
  margin-top: 5px;
  width: 100%;
  height: 10px;
}
.setting_area {
  /* position:absolute; */
  margin-top: 50px;
  width: 100%;
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal";
import IntervalSettingModel from "@/appModel/Spot/IntervalSettingModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
export default {
  components: {
    ApplicationHeader,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "運行スポット設定",
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      spotTypeList: [],
      additionalList: [], // 後で削除
      modificationList: [], // 後で削除
      removalList: [], // 後で削除
      modals: {
        confirmIntervalSettingDlg: false,     
      },
      settingFormData: {
        delayTime: 3,
        dwellTime: 3,
      },
      settingValue: null,
    };
  },

  // コンピュートプロパティ
  computed: {
    vuename() {
      return "DriveSpotSetting.vue"
    }
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "DriveSpotSetting.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
  },

  async beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  setup() {
  },

  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    if (this.confirm() === false) return;
    appLog.infoLog("DriveSpotSetting.vue", this.$store.state.user.userId, `End`);
    next();
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * ページ離脱時の、処理
     */
    confirm() {
      // 変更の有無をチェック
      if (this.isChange() == true) {
        this.repeatedHitsFlg = false;
        return window.confirm(
          "運行スポットの変更をしていますが、本ページから運行情報画面へ遷移しますか？"
        );
      } else {
        return true;
      }
    },

    /**
     * 編集の有無を確認（編集があった場合はtrueを返却）
     */
    isChange() {
      let isChange = false;

      isChange =
        this.additionalList.length > 0 ||
        this.removalList.length > 0 ||
        this.modificationList.length > 0;

      return isChange;
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },

    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.drivespot.projectid;
        this.datestring = this.$store.state.drivespot.datestring;
        this.projectName = this.$store.state.drivespot.projectname;
        this.spotTypeList = this.$store.state.drivespot.spotTypeList;

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        // 運行間隔値取得
        await this.getIntervalsetting();

        // 連打防止
        this.repeatedHitsFlg = false;

      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpotSetting.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },
   
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 運行情報画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },


    // 設定タブ -------------------------------------------------------------------------------------------------------
    /**
     * 運行間隔設定値取得
     */       
    async getIntervalsetting(){

      const projectId = this.projectId;
      let listdata = await IntervalSettingModel.getIntervalSettingList(projectId); 
      
      if (listdata.length == 0) {
        this.settingValue = null;
      } else {
        this.settingValue = listdata[0];
        this.settingFormData.delayTime = listdata[0].intervalsetting.delayTime;
        this.settingFormData.dwellTime = listdata[0].intervalsetting.dwellTime;
      }     
    },        
    /**
     * 保存ボタン押下時 ⇒ 確認ダイアログ表示
     */       
    async onClickSaveIntervalsetting()
    {
      this.modals.confirmIntervalSettingDlg = true;
    },
    /**
     * 運行間隔設定情報登録 (確認ダイアログ更新ボタン押下時)
     */
    async onClickConfirmIntervalSettingOK() {

      try {

        // 入力されているかチェック
        // 運行間隔遅延時間
        if (this.settingFormData.delayTime == "" || this.settingFormData.delayTime == null) {
          this.showBottomToast(`運行間隔遅延時間に数値を入力してください。`, 'warning')
          return;
        }
        // 渋滞滞留時間
        if (this.settingFormData.dwellTime == "" || this.settingFormData.dwellTime == null) {
          this.showBottomToast(`渋滞滞留時間に数値を入力してください。`, 'warning')
          return;
        }                  

        if (!this.settingValue){
          // 登録処理
          let regData = await IntervalSettingModel.getNewData(this.projectId);
          regData.intervalsetting.delayTime = this.settingFormData.delayTime;
          regData.intervalsetting.dwellTime = this.settingFormData.dwellTime;

          // DB更新
          await IntervalSettingModel.addIntervalSetting(regData)

        } else {
          // 更新処理          
          let editData = this.settingValue;
          editData.intervalsetting.delayTime = this.settingFormData.delayTime;
          editData.intervalsetting.dwellTime = this.settingFormData.dwellTime;

          // DB更新
          await IntervalSettingModel.updateIntervalSetting(editData)
        } 

        // モーダル画面閉じる
        this.modals.confirmIntervalSettingDlg = false;

        // 完了メッセージ
        this.showBottomToast(`運行間隔設定情報更新処理が完了しました。`, 'info')

        // ログ出力
        //appLog.infoLog("DriveSpot.vue", this.$store.state.user.userId, `【Update】 intervalsetting： pk[${updateVeihcle.pk}]、sk[${updateVeihcle.sk}]、車両名 [${updateVeihcle.vehicle.name}]、最大積載量（㎥） [${updateVeihcle.vehicle.maxCapacity}]、モバイルアプリで表示しない [${updateVeihcle.invalid}]、メモ [${updateVeihcle.vehicle.memo}]`)

      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`運行間隔設定情報更新処理に失敗しました。`, 'error')
        this.errorLog("onClickConfirmIntervalSettingOK", this.parseErrorObject(e))
        // appLog.errLog("DriveSpot.vue", this.$store.state.user.userId, `${e}`)
        throw e
      }                       
    },
    /**
     * スポット登録ボタンクリック時の処理
     */
    async onClickDriveSpot() {

      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;
      store.spotTypeList = this.spotTypeList;
      await this.$store.commit("setDriveSpot", store);

      // スポット登録画面起動
      this.$router.push({
        path: "/driveSpot",
      });
    },
    /**
     * スポットタイプ編集ボタンクリック時の処理
     */
    async onClickDriveSpotTypeEdit() {

      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;
      store.spotTypeList = this.spotTypeList;
      await this.$store.commit("setDriveSpot", store);

      // スポットタイプ編集画面起動
      this.$router.push({
        path: "/driveSpotTypeEdit",
      });

    },
    /**
     * 運行ストップのマニュアル表示
     */
    toManual() {
      window.open("./manual/web-drivespot/#_9", "_blank", "noreferrer")
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
