const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil");
const baseModel = require("../baseModel");
const vehicleModel = require("../Vehicle/VehicleModel");
const baseAreaModel = require("../BaseArea/BaseAreaModel");
const LocationModel = require("../Location/LocationModel");

/**
 * 運行用のデータ取得モデルクラス
 */
class DriveModel extends baseModel {
  /**
   * テナント内の運行リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getDriveList(projectId) {
    // 運行情報のリスト取得
    let driveList = await super.getList({ beginsWith: `drive#${projectId}` });

    // 有効な運行リスト取得
    return this.getExceptInvalidDriveList(driveList);
  }
  /**
   * プロジェクトID、対象日、到着拠点IDを指定して運行リスト取得
   * @param {*} projectId 
   * @param {*} targetDate 
   * @param {*} endAreaId 
   * @returns
   */
  static async getDriveListbyAreaId(projectId, targetDate, endAreaId) {
    // 運行情報のリスト取得
    let driveList = await super.getList({ beginsWith: `drive#${projectId}#${targetDate}#${endAreaId}` });

    // 有効な運行リスト取得
    return this.getExceptInvalidDriveList(driveList);
  }  
  /**
   * PK,SKのみのリスト
   * @param {*} projectId 
   * @param {*} targetDate 
   * @returns 
   */
  static async getPkSkOnlyList(projectId, targetDate) {
    return await super.getListPkSk({ beginsWith: `drive#${projectId}#${targetDate}`})
  }

  /**
   * テナント内の運行リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getDriveListForSignage(projectId, YMDString, baseAreaId) {
    // 運行情報のリスト取得
    let driveList = await super.getList({
      beginsWith: `drive#${projectId}#${YMDString}#${baseAreaId}`,
    });

    // 有効な運行リスト取得
    return this.getExceptInvalidDriveList(driveList);
  }

  /**
   * １：運行ステータスが１（運行終了）でかつ、打設終了時刻が未指定の場合は、レコードを論理削除（無効フラグをtrueに設定）
   * ２：無効フラグがtrueになっていない運行情報を返却
   * @param {運行情報リスト} driveList
   * @returns
   */
  static async getExceptInvalidDriveList(driveList) {
    // １：運行ステータスが１（運行終了）でかつ、打設終了時刻が未指定の場合は、レコードを論理削除（無効フラグをtrueに設定）

    // 運行終了のレコード抽出
    let delTargetList = driveList.filter((list) => list.driveStatus === "1");

    // 打設箇所が設定されていないレコードは論理削除
    if (delTargetList.length > 0) {
      for (let i = 0; i < delTargetList.length; i++) {
        let element = delTargetList[i];

        if (
          element.drive.pouringEndTime == null ||
          element.drive.pouringEndTime == ""
        ) {
          if (element.invalid == false) {
            // 既に無効フラグがtrueのレコードは更新しない
            // レコードを無効
            element.invalid = true;
            // レコード更新
            await this.updateDrive(element);
          }
        }
      }
    }

    // ２：無効フラグがtrueになっていない運行情報を返却
    return driveList.filter((list) => list.invalid !== true);
  }

  /**
   * 運行情報リストの変換リストを取得します。
   * @param {String} projectId プロジェクトID
   * @param {Array} results 運行情報リスト
   * @param {String} pouringPosition 打設箇所
   * @param {Array} baseAreaList 拠点情報リスト
   * @param {Array} deliverys 納入伝票情報
   * @returns 変換後リスト
   */
  static async convertDriveListData(
    projectId,
    results,
    pouringPosition,
    baseAreaList,
    deliverys
  ) {
    let list = [];

    let latestDrives;      
    if (pouringPosition == ``) {
      // 残土対応-全て選択時
      latestDrives = results;
    } else {
      // 打設箇所で
      let filterPouringPosition = results.filter(
        (item) => item.drive.pouringPosition === pouringPosition
      );

      // 運行情報リストを運行ID(タイムスタンプ)の降順でソート（最新順）
      latestDrives = filterPouringPosition.sort(function(a, b) {
        return a.drive.id > b.drive.id ? -1 : 1;
      });
    }


    // // 車両IDで重複を削除
    // latestDrives = latestDrives.filter((element, index, self) =>
    //   self.findIndex(e =>
    //     e.drive.vehicleId === element.drive.vehicleId
    //   ) === index
    // );

    // 車両情報
    const vehicleList = await vehicleModel.getVehicleList(projectId);
    // console.log(`vehicleList: ${JSON.stringify(vehicleList, null, "\t")}`);

    // 工区情報
    // const baseAreaList = await baseAreaModel.getBaseAreaList(projectId);
    // console.log(`baseAreaList: ${JSON.stringify(baseAreaList, null, "\t")}`);

    for (let i = 0; i < latestDrives.length; i++) {
      const row = latestDrives[i];

      // 車両情報(名称、表示順）
      const vehicleData = vehicleModel.getVehicleData(
        row.drive.vehicleId,
        vehicleList
      );
      // console.log(`vehicleData: ${JSON.stringify(vehicleData, null, "\t")}`);

      // 工区名称
      const endAreaName = this.getEndAreaName(row, baseAreaList);
      // console.log(`endAreaName: ${JSON.stringify(endAreaName, null, "\t")}`);

      const item = row.drive;

      // 納入伝票情報取得      
      let calcFactoryStartTime = item.factoryStartTime;
      let calcpouringStartTime = item.pouringStartTime;
      let rowDelivery = null;

      // 納入伝票情報リストから、運行情報に紐づく納入伝票情報を取得
      if (deliverys) {

        for (let i=0; i<deliverys.length; i++){
          // 運行情報ID取得
          let driveID = deliverys[i].lsiStr1.split(`#`)[2];
          if (driveID == item.id){

            // 納入伝票の出発、到着時間は時間のみなので、日付を付与する
            let dateString = row.sk.split("#")[2];
            dateString = dateString.substr(0, 4) + "-" + dateString.substr(4, 2) + "-" + dateString.substr(6, 2);
            if (deliverys[i].delivery.startTime != null && deliverys[i].delivery.startTime != "") {
              if (deliverys[i].delivery.startTime.indexOf(dateString) == -1){
                deliverys[i].delivery.startTime = DateUtil.dateStringDateTime(`${dateString} ${deliverys[i].delivery.startTime}:00`);
              }  
            }
            if (deliverys[i].delivery.endTime != null && deliverys[i].delivery.endTime != "") {
              if (deliverys[i].delivery.endTime.indexOf(dateString) == -1){
                deliverys[i].delivery.endTime = DateUtil.dateStringDateTime(`${dateString} ${deliverys[i].delivery.endTime}:00`);
              } 
            }

            // 工場出発時間（納入伝票の出発時間、工場出発時間両方に値が入っている場合は、納入伝票の出発時間をセット）
            if (item.factoryStartTime != null && deliverys[i].delivery.startTime != null && deliverys[i].delivery.startTime != ""){
              calcFactoryStartTime = deliverys[i].delivery.startTime;
            }
            // 打設開始時間（納入伝票の到着時間、打設開始時間両方に値が入っている場合は、納入伝票の到着時間をセット）
            if (item.pouringStartTime != null && deliverys[i].delivery.endTime != null && deliverys[i].delivery.endTime != ""){
              calcpouringStartTime = deliverys[i].delivery.endTime;
            }
            
            // 納入伝票情報をセット
            rowDelivery = deliverys[i];
            break;
          } 
        }
      }

      list.push({
        id: item.id,
        vehicleId: item.vehicleId,
        driveNumber: item.driveNumber,
        //driveNumber: i + 1,
        factoryStartTime: calcFactoryStartTime,
        siteEndTime: item.siteEndTime,
        pouringStartTime: calcpouringStartTime,
        pouringEndTime: item.pouringEndTime,
        siteStartTime: item.siteStartTime,
        factoryEndTime: item.factoryEndTime,
        roadType: item.roadType,
        endAreaId: item.endAreaId,
        pouringPosition: item.pouringPosition,
        carryType: item.carryType,
        maxCapacity: item.maxCapacity,
        pouringStartUser: item.pouringStartUser,
        pouringEndUser: item.pouringEndUser,
        totalAmount: item.totalAmount,
        congestionFlag: item.congestionFlag,
        unloadingTime: item.unloadingTime,
        unloadingUser: item.unloadingUser,
        // 車両情報
        vehicleName: vehicleData.name,
        vehicleOrder: vehicleData.order,
        numberPlate: vehicleData.numberPlate,
        // 工区名
        endAreaName: endAreaName,
        // DBデータ(更新用)
        rawData: Object.assign({}, row),
        rowDeliveryData: Object.assign({}, rowDelivery)
      });
    }

    return list;
  }

  /**
   * テナント内の運行リストを取得する(運行情報テーブル用)
   * @param {String} sk 取得キー(projectId#yyyyMMDD)
   * @param {Number} orderType 表示順序(1:工場出発順、2:車両番号順)
   * @param {Array} drives 運行情報リスト
   * @param {Array} vehicleMapTextOrders 車両IDと地図アイコン注記用表示順のリスト
   * @param {String} pouringPosition 対象の打設箇所
   * @param {Array} baseAreaList 拠点情報リスト
   * @param {Array} deliverys 納入伝票情報
   * @param {Number} deliveryTime 運搬時間（単位：分）
   * @param {Number} remainingTime 表示までの残時間（単位：分）
   * @returns 運行情報テーブル用データリスト
   */
  static async getDriveTableList(
    sk,
    orderType,
    drives,
    vehicleMapTextOrders,
    pouringPosition,
    baseAreaList,
    deliverys,
    deliveryTime,
    remainingTime
  ) {
    // console.log(`sk: ${sk}`);
    const projectId = sk.split("#")[0];

    // 運行情報
    let driveList = await this.convertDriveListData(
      projectId,
      drives,
      pouringPosition,
      baseAreaList,
      deliverys
    );
    // let driveList = await this.convertDriveListData(projectId, await this.getDriveList(sk));
    // console.log(driveList);

    // 最新運行情報リスト
    let forLatestList = JSON.parse(JSON.stringify(driveList));
    // console.log(driveList);
    // console.log(forLatestList);
    const latestVehicleDrives = this.getLatestVehicles(
      forLatestList,
      orderType,
      vehicleMapTextOrders,
      pouringPosition,
      deliveryTime,
      remainingTime
    );

    // 車両別全運行情報リスト
    const vehicleAllDrives = this.getVehicleAllDrives(
      driveList,
      vehicleMapTextOrders
    );

    const results = {
      latestVehicleDrives: latestVehicleDrives,
      vehicleAllDrives: vehicleAllDrives,
    };

    return results;

    // // 先に並び替え
    // if (orderType == 1) {
    //   // 出発順(※運行ID（タイムスタンプ)順）
    //   driveList = driveList.sort(function(a, b) {
    //     return (a.id < b.id) ? -1: 1;
    //     // return (a.factoryStartTime < b.factoryStartTime) ? -1: 1;
    //   });
    // } else {
    //   // 車両番号順
    //   driveList = driveList.sort(function(a, b) {
    //     return (a.vehicleOrder < b.vehicleOrder) ? -1: 1;
    //   });
    // }
    // // console.log(`driveList: ${JSON.stringify(driveList, null, "\t")}`);

    // let results = {};
    // let tables = [];

    // for (let i = 0; i < driveList.length; i++) {
    //   const item = driveList[i];
    //   const vehicleMapTextOrder = vehicleMapTextOrders.find(vehicle => vehicle.vehicleId === item.vehicleId);
    //   const order = vehicleMapTextOrder.iconTextOrder;
    //   // const order = i + 1;

    //   // 車両情報行をセット
    //   let vehicle = this.getVehicleRow(item, order);
    //   tables.push(vehicle);

    //   // タイムライン1行目をセット
    //   let timeLine1 = this.getTimeLine1Row(item);
    //   tables.push(timeLine1);

    //   // タイムライン2行目をセット
    //   let timeLine2 = this.getTimeLine2Row(item);
    //   tables.push(timeLine2);
    // }

    // return tables;
  }

  /**
   * 車両別の全運行データを取得します。
   * @param {*} driveList 運行情報元データ
   * @param {Array} vehicleMapTextOrders 車両IDと地図アイコン注記用表示順のリスト
   */
  static getVehicleAllDrives(driveList, vehicleMapTextOrders) {
    let sortedList = driveList.sort(function(a, b) {
      // 車両ID順で並び替え
      if (a.vehicleId !== b.vehicleId) {
        // 文字列は大小で比較する必要がある
        if (a.vehicleId > b.vehicleId) return 1;
        if (a.vehicleId < b.vehicleId) return -1;
      }

      // 運行回数で並び替え
      if (a.driveNumber !== b.driveNumber) {
        // 昇順にする
        return a.driveNumber - b.driveNumber;
        // // -1 かけて降順にする
        // return (a.score - b.score) * -1
      }

      return 0;

      // return (a.vehicleId < b.vehicleId) ? -1: 1;
    });
    // console.log(sortedList);

    let tables = [];
    let tempVehicleId = "";
    let data = {};
    let vehicleMapTextOrder = null;
    let order = -1;

    for (let i = 0; i < sortedList.length; i++) {
      const item = sortedList[i];

      if (tempVehicleId !== item.vehicleId) {
        // console.log(item.vehicleId);

        // 車両IDが異なる
        if (data.vehicleId) {
          // // 1つ前のループまでのデータを運行回数昇順ソート
          // let sortedData = data.list.sort(function(a, b) {
          //   return (a.driveNumber > b.driveNumber) ? -1: 1;
          // });

          // 返却データに追加
          tables.push(data);
          // tables.push(sortedData);
        }

        // データを初期化
        data = { vehicleId: item.vehicleId, list: [] };
        tempVehicleId = item.vehicleId;

        // 地図アイコン注記用文字列
        vehicleMapTextOrder = vehicleMapTextOrders.find(
          (vehicle) => vehicle.vehicleId === item.vehicleId
        );
        order = vehicleMapTextOrder.iconTextOrder;

        // 車両情報行をセット
        let vehicle = this.getVehicleRow(item, order);
        data.list.push(vehicle);

        // タイムライン1行目をセット
        let timeLine1 = this.getTimeLine1Row(item);
        data.list.push(timeLine1);

        // タイムライン2行目をセット
        let timeLine2 = this.getTimeLine2Row(item);
        data.list.push(timeLine2);
      } else {
        // 車両IDが同じ
        // 車両情報行をセット
        let vehicle = this.getVehicleRow(item, order);
        data.list.push(vehicle);

        // タイムライン1行目をセット
        let timeLine1 = this.getTimeLine1Row(item);
        data.list.push(timeLine1);

        // タイムライン2行目をセット
        let timeLine2 = this.getTimeLine2Row(item);
        data.list.push(timeLine2);
        // console.log(data);
      }
    }

    // 1つ前のループまでのデータを運行回数昇順ソート
    // let sortedData = data.list.sort(function(a, b) {
    //   return (a.driveNumber > b.driveNumber) ? -1: 1;
    // });

    // 返却データに追加
    tables.push(data);
    // tables.push(sortedData);

    // console.log(tables);
    return tables;
  }

  /**
   * 最新の運行リストデータを取得します。
   * @param {*} driveList 運行情報元データ
   * @param {Number} orderType 表示順序(1:工場出発順、2:車両番号順)
   * @param {Array} vehicleMapTextOrders 車両IDと地図アイコン注記用表示順のリスト
   * @param {String} pouringPosition 対象の打設箇所
   * @param {Number} deliveryTime 運搬時間（単位：分）
   * @param {Number} remainingTime 表示までの残時間（単位：分）
   * @returns 最新の運行リストデータ
   */
  static getLatestVehicles(
    driveList,
    orderType,
    vehicleMapTextOrders,
    pouringPosition,
    deliveryTime,
    remainingTime
  ) {
    // 車両IDで重複を削除
    let latestDrives = driveList.filter(
      (element, index, self) =>
        self.findIndex(
          (e) =>
            e.vehicleId === element.vehicleId &&
            e.baseAreaId === element.baseAreaId &&
            e.pouringPosition === element.pouringPosition
        ) === index
    );

    // 先に並び替え
    let sortedList = [];

    // 車両番号順（車両順が重複している場合があるので、1:車両順、2：車両IDで並び替えて、採番しなおす）
    sortedList = latestDrives.sort(function(a, b) {
      //return (a.vehicleOrder < b.vehicleOrder) ? -1: 1;
      if (a.vehicleOrder > b.vehicleOrder) return 1;
      if (a.vehicleOrder < b.vehicleOrder) return -1;

      if (a.vehicleId > b.vehicleId) return 1;
      if (a.vehicleId < b.vehicleId) return -1;
      return 0;
    });

    // もし重複があった場合、重複をなくすために、採番しなおす。
    for (let i = 0; i < sortedList.length; i++) {
      let item = sortedList[i];
      item.vehicleOrder = i;
    }

    // 表示順が工場出発順の時は、並び替え実行
    if (orderType == 1) {
      sortedList = [];
      // 出発順(※運行ID（タイムスタンプ)降順）
      sortedList = latestDrives.sort(function(a, b) {
        return a.id < b.id ? 1 : -1;
        // return (a.id < b.id) ? -1: 1;
        // return (a.factoryStartTime < b.factoryStartTime) ? -1: 1;
      });
    }
    // console.log(`driveList: ${JSON.stringify(driveList, null, "\t")}`);

    let tables = [];
    for (let i = 0; i < sortedList.length; i++) {
      const item = sortedList[i];
      const vehicleMapTextOrder = vehicleMapTextOrders.find(
        (vehicle) => vehicle.vehicleId === item.vehicleId
      );
      const order = vehicleMapTextOrder.iconTextOrder;
      // const order = i + 1;

      // 車両に紐づく、運行情報の件数取得
      let drivehistory = driveList.filter(
        (element) =>
          item.vehicleId === element.vehicleId &&
          item.baseAreaId === element.baseAreaId &&
          item.pouringPosition === element.pouringPosition
      );

      // let totalAmount = 0;
      // // drivehistory.forEach(Item => {
      // //   totalAmount = totalAmount + Item.maxCapacity
      // // });
      // // 累計の降順でソート
      // drivehistory = drivehistory.sort(function(a, b){
      //   if (a.totalAmount > b.totalAmount) return -1;
      //   if (a.totalAmount < b.totalAmount) return 1;
      //   return 0
      // })
      // totalAmount = drivehistory[0].totalAmount        

      // console.log("▲▲" + totalAmount + " : " + item.totalAmount);
      // console.log("▲▲" + item.congestionFlag);

      // 車両情報行をセット
      let vehicle = this.getVehicleRow(item, order, drivehistory.length);
      tables.push(vehicle);

      // タイムライン1行目をセット
      let timeLine1 = this.getTimeLine1Row(item, deliveryTime, remainingTime);
      tables.push(timeLine1);

      // タイムライン2行目をセット
      let timeLine2 = this.getTimeLine2Row(item);
      tables.push(timeLine2);
    }

    // 工場出発、現場出発時間が未設定の場合は、一覧の一番下にする
    let reSortedList = [];
    let underList = []; 

    for (let i=0; i<tables.length; i++) {
      // 3レコードワンセット
      // console.log("▲▲" + JSON.stringify(tables[i]))
      // console.log("▲▲" + JSON.stringify(tables[i+1]))
      // console.log("▲▲" + JSON.stringify(tables[i+2]))

      // 工場出発、現場出発時刻取得
      let startFactory = tables[i+1].col1;
      let startSite = tables[i+2].col2;

      // 両時刻未設定の場合は、再配置用のリストに保持
      if (startFactory == "" && startSite == ""){
        underList.push(tables[i]);
        underList.push(tables[i+1]);
        underList.push(tables[i+2]);
      } else {
        reSortedList.push(tables[i]);
        reSortedList.push(tables[i+1]);
        reSortedList.push(tables[i+2]);
      }         
      i = i + 2;
    }

    // 再配置用リストの値を追加
    for (let i=0; i<underList.length; i++)
    {
      reSortedList.push(underList[i]);
    }

    // 並び替えた分を再セット
    tables = reSortedList;

    return tables;
  }

  /**
   * 車両情報行データを取得します。
   * @param {Object} item 運行情報行アイテム
   * @param {Number} order 表示順
   * @param {Number} drivehistorycnt 運行履歴数
   * @param {Number} totalAmount 累計積載量
   * @returns 車両情報行データ
   */
  static getVehicleRow(item, order, drivehistorycnt) {
    // sample
    // {
    //   type: 'vehicle',
    //   img: './img/concrete/ic_circle_yellow.png',
    //   order: '1',
    //   driveNumber: '2',
    //   maxCapacity: '5.0',
    //   vehicleName: 'A-1234',
    //   vehicleId: 'hogehoge',
    //   driveOrder: 20211124120201123-1
    //   vehicleOrder: 1-1
    //   以下は旧版（現在はなし（予定））
    //   col1: '',
    //   col2: 'A-1234：5.0m3',
    // },

    //const drive = item.drive;

    // 車両名：最大積載量
    // const vehicleNameCapacity = `${item.vehicleName}：${item.maxCapacity}m3`;

    // アイコン
    const iconPath = this.getImagePath(item);

    // 車両数
    let driveCnt = 0;
    if (drivehistorycnt != null && drivehistorycnt != "") {
      driveCnt = drivehistorycnt;
    } else {
      driveCnt = item.driveNumber;
    }

    // console.log(`最大積載量：${item.maxCapacity}`)

    // 打設開始ボタン表示するか（現場到着時間が設定されている AND 打設開始時刻が未設定）
    let dispPouringStartBtn = false;
    if (item.siteEndTime && (item.pouringStartTime == null || item.pouringStartTime == ""))
    {
      dispPouringStartBtn = true;
    }

    // 打設終了ボタン表示するか（現場到着時間が設定されている AND 打設終了時刻が未設定）
    let dispPouringEndBtn = false;
    if (item.pouringStartTime && (item.pouringEndTime == null || item.pouringEndTime == ""))
    {
      dispPouringEndBtn = true;
    }

    // 運行情報に納入伝票に紐づいている場合は、納入伝票有マークを表示
    let dispDelveryMark = false;
    if (Object.keys(item.rowDeliveryData).length > 0) {
      dispDelveryMark = true
    }

    // 積荷開始ボタン表示するか (打設開始（積荷開始）時刻が未設定)
    let cargoStartBtn = false;
    if (item.pouringStartTime == null || item.pouringStartTime == "") {
      cargoStartBtn = true;
    }

    // 積荷終了ボタン表示するか (打設開始（積荷開始）時刻が設定されている AND 打設終了（積荷終了）時刻が未設定)  
    let cargoEndBtn = false;
    if (item.pouringStartTime && (item.pouringEndTime == null || item.pouringEndTime == ""))
    {
      cargoEndBtn = true;
    }

    // 荷降時間ボタン表示するか (現場到着時刻（土捨場到着）が設定されている AND 荷降時間が未設定)
    let unloadingTimeBtn = false;
    if (item.siteEndTime && (item.unloadingTime == null || item.unloadingTime == ""))
    {
      unloadingTimeBtn = true;
    }
    
    return {
      type: "vehicle",
      // col1: '',
      // col2: vehicleNameCapacity,
      img: iconPath,
      order: order,
      driveNumber: driveCnt,
      maxCapacity: item.maxCapacity.toFixed(2),
      vehicleName: item.vehicleName,
      numberPlate: item.numberPlate,
      vehicleId: item.vehicleId,
      totalAmount: item.totalAmount,
      congestionFlag: item.congestionFlag,
      skDrive: item.rawData.sk,
      dispPouringStartBtn: dispPouringStartBtn,
      dispPouringEndBtn: dispPouringEndBtn,
      isDelivery: dispDelveryMark,
      cargoStartBtn: cargoStartBtn,
      cargoEndBtn: cargoEndBtn,
      unloadingTimeBtn: unloadingTimeBtn,
      // ソート用
      driveOrder: item.id + "-1",
      vehicleOrder: ("00" + item.vehicleOrder).slice(-2) + "-1",
    };
  }

  /**
   * 車両情報行データを取得します。(運行情報テーブルの1行目)
   * @param {Object} item 運行情報行アイテム
   * @param {Number} deliveryTime 運搬時間（単位：分）
   * @param {Number} remainingTime 表示までの残時間（単位：分）
   * @returns 車両情報行データ
   */
  static getTimeLine1Row(item, deliveryTime, remainingTime) {
    // sample
    // {
    //   type: 'timeline',
    //   col1: '10:20',
    //   col2: '10:38',
    //   col3: '00:18',
    //   col4: '10:40',
    //   col5: '11:32',
    //   col6: '00:52',
    //   col7: 'シガンシナ区',
    //   col8: 'ウォール・マリア南側',
    //   col9: '立体機動装置',
    //   pouringStartUser: 'iosuser',
    //   pouringEndUser: 'system',
    //   driveOrder: 20211124120201123-2
    //   vehicleOrder: 1-2
    // },

    // 運行回数
    const driveNumber = `(${item.driveNumber})`;

    // 時刻表示フォーマット
    const format = "HH:mm";

    // 所要時間
    const timeSpan = this.getNullTimeString(
      DateUtil.getTimeDifference(item.factoryStartTime, item.siteEndTime)
    );
    // console.log(`所要時間${timeSpan}`)

    // 打設時間 20211116103835
    //         20211116103906
    //         YYYYMMDDHHmmSS
    const pouringFormat = "YYYYMMDDHHmmss;";
    const start = item.pouringStartTime ?? "";
    // const pouringStartTime = `${start.substr(0, 4)}/${start.substr(4, 2)}/${start.substr(6, 2)} ${start.substr(8, 2)}:${start.substr(10, 2)}`
    // const pouringStartTime = `${start.substr(0, 4)}/${start.substr(4, 2)}/${start.substr(6, 2)} ${start.substr(8, 2)}:${start.substr(10, 2)}`
    // console.log(`pouringStartTime:${pouringStartTime}`)
    const end = item.pouringEndTime ?? "";
    // const pouringEndTime = `${end.substr(0, 4)}/${end.substr(4, 2)}/${end.substr(6, 2)} ${end.substr(8, 2)}:${end.substr(10, 2)}`
    // console.log(`pouringEndTime:${pouringEndTime}`)
    let pouringTimeSpan = this.getNullTimeString(
      DateUtil.getTimeDifference(start, end)
    );

    // 打設時間が決まらない場合は、工場出発時間が登録済みで、打設開始時間が未登録の時に X分経過あとY分 を表示
    // 表示形式：X分経過あとY分
    // X：現在時刻 - 工場出発時刻
    // Y：工場出発時刻 + 設定運搬時間 - 現在時刻
    if (pouringTimeSpan == "") {      
      // 経過時間 敷居値 (仮2)
      // 工場出発時間が登録済みで、打設開始時間がまだの場合
      if (item.factoryStartTime && end == "" && deliveryTime != null && deliveryTime != ""){
        // 工場出発時刻 秒の部分を0秒にする
        let factoryStarTime = DateUtil.dateStringDateTimeAtSecond0(item.factoryStartTime);

        // 到着予測時間（工場出発時刻 + 設定運搬時間）
        let predictArrivalTime = DateUtil.dateStringDateTime(DateUtil.addMinute(factoryStarTime, deliveryTime));

        // 現在時刻取得 秒の部分は0秒にする
        let nowDateTime = DateUtil.dateStringDateTimeAtSecond0();

        // 経過時間 (X：現在時刻 - 工場出発時刻)
        let elapsedTime = DateUtil.diff(DateUtil.dateStringDateTime(nowDateTime), DateUtil.dateStringDateTime(factoryStarTime));

        // 残り時間(Y：到着予測時間 - 現在時刻)
        let timeLeft = DateUtil.diff(DateUtil.dateStringDateTime(predictArrivalTime), DateUtil.dateStringDateTime(nowDateTime));

        console.log("▲▲到着予測時間：" + DateUtil.dateStringDateTime(predictArrivalTime));
        console.log("▲▲現在時間：" + DateUtil.dateStringDateTime(nowDateTime));
        console.log("▲▲経過時間：" + elapsedTime);
        console.log("▲▲残り時間：" + timeLeft);

        // 残り時間がプロジェクトの閾値（仮に30分）以下の場合に表示する
        if (timeLeft <= remainingTime) {
          if (timeLeft < 0) {
            pouringTimeSpan = `${elapsedTime}分経過,${Math.abs(timeLeft)}分超過`;
          } else {
            pouringTimeSpan = `${elapsedTime}分経過,残り${timeLeft}分`;
          }          
        }
      }
    }    
    // const pouringTimeSpan = this.getNullTimeString(DateUtil.getDifferentialTime(pouringStartTime, pouringEndTime));
    // console.log(`打設時間${pouringTimeSpan}`)
    // const pouringTimeSpan = DateUtil.getDifferentialTime(item.pouringStartTime, item.pouringEndTime);

    // 積載物
    const carryName = this.getCarryName(item.carryType);

    return {
      type: "timeline1",
      // col1: driveNumber,
      col1: this.getNullTimeString(
        DateUtil.getFormatString(item.factoryStartTime, format)
      ),
      col2: this.getNullTimeString(
        DateUtil.getFormatString(item.siteEndTime, format)
      ),
      // col2: DateUtil.getFormatString(item.factoryStartTime, format),
      // col3: DateUtil.getFormatString(item.siteEndTime, format),
      col3: timeSpan,
      col4: this.getNullTimeString(
        DateUtil.getFormatString(item.pouringStartTime, format)
      ),
      col5: this.getNullTimeString(
        DateUtil.getFormatString(item.pouringEndTime, format)
      ),
      // col5: DateUtil.getFormatString(item.pouringStartTime, format),
      // col6: DateUtil.getFormatString(item.pouringEndTime, format),
      col6: pouringTimeSpan,
      col7: item.endAreaName,
      col8: item.pouringPosition,
      col9: carryName,
      co20: this.getNullTimeString(
        DateUtil.getFormatString(item.unloadingTime, format)
      ),
      pouringStartUser: item.pouringStartUser,
      pouringEndUser: item.pouringEndUser,
      unloadingUser: item.unloadingUser,
      // 編集用
      vehicleName: item.vehicleName,
      rawData: item.rawData,
      rowDeliveryData: item.rowDeliveryData,
      // ソート用
      driveOrder: item.id + "-2",
      vehicleOrder: ("00" + item.vehicleOrder).slice(-2) + "-2",
    };
  }

  /**
   * 車両情報行データを取得します。(運行情報テーブルの2行目)
   * @param {Object} item 運行情報行アイテム
   * @returns 車両情報行データ
   */
  static getTimeLine2Row(item) {
    // sample
    // {
    //   type: 'timeline',
    //   col1: '11:47',
    //   col2: '11:32',
    //   col3: '00:15',
    //   pouringStartUser: 'iosuser',
    //   pouringEndUser: 'system',
    //   driveOrder: 20211124120201123-3
    //   vehicleOrder: 1-3
    // },

    // 運行回数
    // const driveNumber = `(-1)`;

    // 時刻表示フォーマット
    const format = "HH:mm";

    // 所要時間（復路）
    const timeSpan = this.getNullTimeString(
      DateUtil.getTimeDifference(item.siteStartTime, item.factoryEndTime)
    );
    // const timeSpan = DateUtil.getDifferentialTime(item.siteStartTime, item.factoryEndTime);

    return {
      type: "timeline2",
      // col1: driveNumber,
      col1: this.getNullTimeString(
        DateUtil.getFormatString(item.factoryEndTime, format)
      ),
      col2: this.getNullTimeString(
        DateUtil.getFormatString(item.siteStartTime, format)
      ),
      // col2: DateUtil.getFormatString(item.factoryEndTime, format),
      // col3: DateUtil.getFormatString(item.siteStartTime, format),
      col3: timeSpan,
      pouringStartUser: item.pouringStartUser,
      pouringEndUser: item.pouringEndUser,
      // 編集用
      vehicleName: item.vehicleName,
      rawData: item.rawData,
      rowDeliveryData: item.rowDeliveryData,
      // ソート用
      driveOrder: item.id + "-3",
      vehicleOrder: ("00" + item.vehicleOrder).slice(-2) + "-3",
    };
  }

  /**
   * ToDo VehicleModel.jsへ移設
   * 車両IDから車両名称を取得します。
   * @param {String} id 車両ID
   * @param {Array} vehicleList 車両情報リスト
   * @returns 車両名称
   */
  static async getVehicleName(id, vehicleList) {
    let vehiclName = ``;

    for (let i = 0; i < vehicleList.length; i++) {
      const vehicle = vehicleList[i];

      if (vehicle.vehicle.id == id) {
        vehiclName = vehicle.vehicle.name;
        break;
      }
    }

    return vehiclName;
  }

  /**
   * 到着拠点名称を取得します。
   * @param {Object} driveRow 運行情報行データ
   * @param {Array} baseAreaList 工区データリスト
   * @returns 到着拠点名称
   */
  static getEndAreaName(driveRow, baseAreaList) {
    return baseAreaModel.getBaseAreaName(
      driveRow.drive.endAreaId,
      baseAreaList
    );
  }

  /**
   * 積載物リストを取得します。
   * @returns 積載物リスト
   */
  static getCarryTypeList() {
    let list = [
      {
        id: 1,
        name: `コンクリート`,
      },
      {
        id: 2,
        name: `モルタル`,
      },
      {
        id: -1,
        name: `不良品`,
      },
    ];

    return list;
  }

  /**
   * 積載物名称を取得します。
   * @param {Number} carryType 積載物種別 1:コンクリート、2：モルタル、-1:不良品
   * @returns 積載物名称
   */
  static getCarryName(carryType) {
    const types = this.getCarryTypeList();

    for (let i = 0; i < types.length; i++) {
      const type = types[i];

      if (type.id == carryType) {
        return type.name;
      }
    }

    return ``;
  }

  /**
   * アイコンイメージを取得します。
   * @param {Object} item アイコン表示情報
   * @returns アイコンイメージパス
   */
  static getImagePath(location) {
    let path = ``;

    switch (location.roadType) {
      case 1:
        path = "./img/concrete/ic_circle_yellow.png";
        break;
      case 2:
        path = "./img/concrete/ic_circle_blue.png";
        break;
      default:
        if (this.isGenba(location)) {
          path = "./img/concrete/ic_circle_red.png";
        } else {
          path = "./img/concrete/ic_circle_grey.png";
        }

        break;
    }

    return path;
  }

  /**
   * 現場内であるかを判断する
   * @param {*} location
   * @returns
   */
  static isGenba(location) {
    try {
      return (
        // location.factoryStartTime &&
        location.siteEndTime && !location.factoryEndTime
      );
    } catch (e) {
      return false;
    }
  }

  /**
   * 運行を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteDrive(data) {
    await super.deleteData(data);
  }

  /**
   * 運行管理レコードを追加する
   * @param {*} data
   */
  static async addDrive(data) {
    await super.addData(data);
  }

  /**
   * 運行管理レコードを更新する
   * @param {*} data
   */
  static async updateDrive(data, condition = null) {
    await super.updateData(data, condition);
  }

  /**
   * 運行管理レコードを取得する
   * @param {*} data
   */
  static async getDrive(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, day, baseAreaId, vehicleId, id) {
    // drive#プロジェクトID#yyyyMMDD#到着拠点ID#車両ID#運行情報id
    return `drive#${projectId}#${day}#${baseAreaId}#${vehicleId}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, day, baseAreaId, vehicleId) {
    let newRowData = await super.getNewData();

    const id = short.generate();
    newRowData.sk = this.createSk(projectId, day, baseAreaId, vehicleId, id);

    newRowData.drive = {
      id: id,
      vehicleId: vehicleId,
      driveNumber: 1,
      factoryStartTime: "",
      siteEndTime: "",
      pouringStartTime: "",
      pouringEndTime: "",
      siteStartTime: "",
      factoryEndTime: "",
      roadType: 1,
      endAreaId: "",
      pouringPosition: "",
      carryType: 1,
      maxCapacity: 0.0,
      pouringStartUser: "system",
      pouringEndUser: "system",
      unloadingTime: "",
      unloadingUser: "system",
    };

    return newRowData;
  }

  /**
   * 一致する運行情報行データを取得します。
   * @param {String} endAreaId 到着拠点ID
   * @param {String} pouringPosition 打設箇所
   * @param {String} vehicleId 車両ID
   * @param {String} driveId 運行情報ID
   * @param {Array} driveList 運行情報リスト
   * @returns 運行情報行データ
   */
  static getDriveData(
    endAreaId,
    pouringPosition,
    vehicleId,
    driveId,
    driveList
  ) {
    let result = null;

    for (let i = 0; i < driveList.length; i++) {
      const row = driveList[i].drive;

      if (
        row.endAreaId == endAreaId &&
        row.pouringPosition == pouringPosition &&
        row.vehicleId == vehicleId &&
        row.id == driveId
      ) {
        result = driveList[i];
        break;
      }
    }

    // 2021.12.17 アイコンの位置が、ラストロケーションから取得した位置になる。（ドライブIDから、最終位置を再取得する必要があるが、一旦、アイコンは非表示にする。
    // // 該当レコードがなかった場合、driveIdを条件から外し、運行順の降順でレコード取得（driveIdが紐づかないのは、運行状態が1でかつ打設終了時間が空のレコードがある場合）
    // let changeDriveList = [];
    // if (result == null){
    //   // 運行回数の降順で取得
    //   changeDriveList = await driveList.filter((row) => row.drive.endAreaId === endAreaId && row.drive.pouringPosition === pouringPosition && row.drive.vehicleId === vehicleId)
    //     .slice()
    //     .sort(function(a, b) {
    //       if (a.drive.driveNumber < b.drive.driveNumber) return 1;
    //       if (a.drive.driveNumber > b.drive.driveNumber) return -1;
    //       return 0;
    //     });
    // }
    // // 該当レコードがあった場合は、採用する。
    // if (changeDriveList.length > 0){
    //   result = changeDriveList[0];
    // }

    return result;
  }

  /**
   * 時刻文字列が不正の場合は'--:--'文字列または空文字で返します。
   * @param {Time} timeString 対象時刻文字列
   * @returns 不正時刻の場合は'--:--'文字列または空文字
   */
  static getNullTimeString(timeString) {
    // console.log(`timeString:${timeString}`);

    if (timeString.indexOf("NaN") != -1) {
      timeString = timeString.replace("NaN", "--");
      timeString = timeString.replace("NaN", "--");
    } else if (timeString.indexOf("Invalid") != -1) {
      // nullの場合は空文字
      timeString = "";
    }

    // console.log(`timeString:${timeString}`);
    return timeString;
  }

  /**
   * テナント内の納入伝票を取得する
   * @param {beginsWidthsk}} sk (プロジェクトID#yyyyMMDD)
   * @returns
   */
  static async getDelivaryList(beginsWidthsk) {
    // 納入伝票のリスト取得
    return await super.getList({ beginsWith: `delivery#${beginsWidthsk}` });
  }

  /**
   * 納入伝票レコードを更新する
   * @param {*} data
   */
  static async updateDelivery(data, condition = null) {
    await super.updateData(data, condition);
  }  

  // /**
  //  * 車両ID、地図アイコン注記用表示順リストを取得します。
  //  * @param {*} sk
  //  */
  // static async getVehicleMapTextOrders(driveList) {
  //   // 新しい順にソート（降順）

  //   // 車両IDで重複除外

  //   // 1から連番を付与

  //   // 車両ID、地図アイコン注記用表示順(1~)
  //   let vehicleMapTextOrders;
  // }
}

module.exports = DriveModel;
