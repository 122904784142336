export default {
  editUserId: null,
  createFlg: false,
  fromNavbar: false,
  cognitoSub: "", //Cognitoで管理する一意なUserId
  userId: "", //ログイン時に使用するユーザーID
  userType: "0", // 0:一般 1:管理者 2:スーパーユーザー 3:iPhone(ドライバー）ユーザー 99:NCSメンテナンスユーザー
  userName: "", // ユーザー名
  loginDateTIme: "", // ログイン日時（ログインからn日後にログアウトさせるような場合に使用する）
  group: "" // 所属するユーザーグループ
}