<template>
  <div>
    <div class="container-fluid mt-1">
      
      <base-input
        
        label="日付"
      ><template></template>
        <el-date-picker
          v-model="date1"
          type="date"
          class="w-100"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="日付を選択"
          >
        </el-date-picker>
      </base-input>

      <base-input name="select" label="リスト選択" >
          <el-select placeholder=""
            v-model="data2"
            @change="changeData2"
            filterable>
            <el-option v-for="item in list"
                      class="select-primary"
                      :value="item.id"
                      :label="item.name"
                      :key="item.name">
            </el-option>
          </el-select>
        </base-input>

       <!-- バリデーション付きフォームの例 -->
      <el-form ref="form" :rules="rules" :model="modelData" label-position="top">
        <h4>名前 <require-tag /></h4>
        <el-form-item prop="name">
          <el-input name="name" autocomplete="off" :placeholder="名前を入力" v-model="modelData.name" ></el-input>
        </el-form-item>
      </el-form>

      <el-select
        placeholder=""
        @change="changeIconType(item, $event)"
        size="small"
        v-model="selectValue"
        >
      
        <el-option v-for="item in selectList"
                  class="select-primary"
                  :value="item"
                  :label="item"
                  :key="item">
                  <img class="statusIcon" style="width:24px;height:24px;" :src="imagePath" /><span class="ml-1">{{item}}</span>
        </el-option>
        <template #prefix>
          <!-- <img class="statusIcon" :src="systemIconFileList[(item.systemIconType - 0) > 0 ? (item.systemIconType - 0) - 1 : (item.systemIconType - 0)].path" /> -->
          <img class="statusIcon" style="width:24px;height:24px;" :src="imagePath" />
          
        </template>
      </el-select>
      <br /><br /><br />
      <el-radio-group v-model="radio1" size="large" :fill="radio1=='0' ? '#154CA3' : '#FF0000'">
        <el-radio-button label="0" >作成</el-radio-button>
        <el-radio-button label="1" >削除</el-radio-button>
      </el-radio-group>
      {{radio1}}
      <br />
      <h3>アップロードサンプル</h3>
      <br />
      <el-upload
        class="upload-demo m-1"
        action=""
        :on-change="handleOnChange"
        :on-remove="handleRemove"
        :file-list="uploadFileList"
        :multiple="true"
        :auto-upload="false"
        list-type="picture-card"
      >
        <el-button size="small" type="primary" class="mb-0">写真を選択</el-button>
        <template #file="{ file }">
          <div>
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <el-icon><zoom-in /></el-icon>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleDownload(file)"
              >
                <el-icon><Download /></el-icon>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <el-icon><Delete /></el-icon>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <div style="width:200px;">
        <base-button
          block
          type="primary"
          @click="onClickSave"
          >アップロード</base-button
        >
      </div>
      <br />
      <base-button
          block
          type="primary"
          @click="sendDriveSpot"
          >drivespotAPIテスト</base-button
        >
    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog"
import UserInfo from "@/appUtils/UserInfo.js";
import DateUtil from "@/appUtils/DateUtil.js";
import Mime from "@/appUtils/Mime.js";
import ValidateUtil from "@/appUtils/ValidateUtil"
import TenantStorage from "@/appUtils/TenantStorage"
import API from '@aws-amplify/api';

export default {
  components: {
    
  },
  data() {
    return {
      date1: "",
      data2: "",
      list: [
        {id: "0", name: "項目１"},
        {id: "1", name: "項目２"},
        {id: "2", name: "項目３"},
        {id: "3", name: "項目４"}
      ],
      modelData: {
        name: ""
      },
      rules: {
        name: [{ required: true, validator: this.checkName, trigger: 'blur' }],
      },
      selectValue: "",
      selectList: ["A", "B", "C"],
      imagePath: "/img/concrete/ic_circle_blue.png",
      radio1: '0',
      uploadFileList: [],
    };
  },
  computed: {
    vuename() {
      return this.$route.name
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  async mounted() {
    //インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    await this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      console.log("init")
      UserInfo.getUserInfo()
    },
    changeData2() {
      alert(this.data2)
    },
    checkName(rule, value, callback){
      if (ValidateUtil.checkBase(value, callback, 100, true, false, false)) {
        callback()
      }
    },
    handleOnChange(file, fileList){
      this.uploadFileList = fileList
    },
    handleRemove(file, fileList){
      this.uploadFileList = fileList
    },
    handleDownload(file) {
      console.log(file)
    },
    upload() {
      
    },
    async onClickSave() {
      try {
        this.showLoader();
        if (this.uploadFileList.length > 0) {

          // 写真のアップロード
          const s3KeyList = await this.uploadPhotos(this.uploadFileList)
        }
      } catch (e) {
        console.log(JSON.stringify(e))
        throw e
      } finally {
        this.hideLoader();
      }
    },
    /**
     * 画像アップロード
     */
    async uploadPhotos(files){
      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()
      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      this.tenantStorage = new TenantStorage(user.group)

      const s3KeyList = []
      try {
        // ファイル数分繰り返し
        for (let i = 0; i < files.length;i++) {
          // ファイルのバイナリ先頭4バイトからファイルタイプを判定して拡張子取得
          const ext = await Mime.getExt(files[i].raw)
          if (!ext) {
            alert(`拡張子判定不可`)
            break
          }
          // ファイル名生成
          const fileName = `${DateUtil.dateStringBase(new Date(), `YYYY-MM-DD_HH-mm-ss-SSS`)}.${ext}`
          // S3キーを構成
          const s3Key = `tenant/${user.group}/uisample/${fileName}`
          // アップロード
          await this.tenantStorage.put(s3Key, files[i].raw)

          s3KeyList.push(s3Key)
        }
      } catch (e) {
        console.log(`アップロードエラー ${JSON.stringify(e)}`)
        throw e
      }
      return s3KeyList
    },
    showLoader() {
      this.loader = this.$loading.show({
        container: null,
        canCancel: false,
        color: "#E4007F",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    hideLoader() {
      this.loader.hide();
    },
    /**
     * アップロード用REST API呼び出しテストコード
     */
    async sendDriveSpot() {
      try {

        const apiName = 'konoikeconcreteapi';
        const path = '/drivespot';
        const checksum = ""

        console.log(`checksum: ${checksum}`)
        const myInit = {
          response: true,
          body: {
            time: "2023/05/25 16:00:00",
            roadType: "1",
            driveSk: "ddrive#auxbJdKhCrSpdYKaS3JBhB#20230525#20221219150227990#5KKtywD1MqReCEKx8rLfqG#20230525125610700",
            spotId: "xvbPA2wk6SUiovTW7UhehT"
          }
        };

        API.post(apiName, path, myInit).then(response => {
          // Add your code here
          console.log(`API: ${JSON.stringify(response, null, "\t")}`)
          // const data = response.data
          
        })
          .catch(error => {
            alert(`ng : ${error}`)
            console.log(error);
          });
      } catch (e) {

        console.log(`error: ${e}`)
        throw e
      }
      
    },
  },
};
</script>
