const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 検査箇所用のデータ取得モデルクラス
 */
class InspectPlaceModel extends baseModel {

  /**
   * テナント内の検査箇所リストを取得する
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {テナント内の検査箇所リスト}
   */
  static async getInspectPlaceList(projectId, nodeId) {
    return await this.filterNotDeleteList(await super.getList({ beginsWith: this.createGetSk(projectId, nodeId) }));
    // return await super.getList({ beginsWith: this.createGetSk(projectId, nodeId) });
    // return await super.getList({ beginsWith: "inspectPlace" })
  }

  /**
   * テナント内の検査箇所リストから削除データ以外を取得する
   * @param {array} results テナント内の検査箇所リスト
   * @returns 削除データ以外の検査箇所リスト
   */
  static async filterNotDeleteList(results) {

    return results.filter(function (item) {
      // console.log(item)
      if (!item.inspectPlace.deleteFlag) {
        // deleteFlgが未定義 -> 表示対象
        return item;
      }
      if (item.inspectPlace.deleteFlag !== true) {
        // deleteFlgがfalse -> 表示対象
        return item;
      }

    })
  }

  /**
   * 検査箇所を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteInspectPlace(data) {
    await super.deleteData(data)
  }

  /**
   * 検査箇所管理レコードを追加する
   * @param {*} data
   */
  static async addInspectPlace(data) {
    await super.addData(data)
  }

  /**
   * 検査箇所管理レコードを更新する
   * @param {*} data
   */
  static async updateInspectPlace(data) {
    await super.updateData(data)
  }

  /**
   * 検査箇所管理レコードを取得する
   * @param {*} data
   */
  static async getInspectPlace(data) {
    return await super.getSingleData(data);
  }

  /**
   * 取得用skの文字列を生成
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {取得用skの文字列}
   */
  static createGetSk(projectId, nodeId) {
    const prefix = `inspectPlace`;
    const getSk = `${prefix}#${projectId}#${nodeId}`;
    console.log('getSk:' + getSk);
    return getSk;
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, nodeId, id) {
    // inspectPlace#プロジェクトID#ノードID#箇所ID(uuidで採番)
    const prefix = `inspectPlace`;
    return `${prefix}#${projectId}#${nodeId}#${id}`;
    // return `${prefix}#${this.projectId}#${this.nodeId}`
    // return `${projectNodeId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, nodeId) {
    let newRowData = await super.getNewData()

    const id = short.generate()
    newRowData.sk = this.createSk(projectId, nodeId, id)

    newRowData.inspectPlace = {
      placeId: "",
      name: "",
      x: 0,
      y: 0,
      latitude: 0,
      longitude: 0,
      createUser: "",
      inspectMasterId: "",
      lineName: "",
      iconClass: "",
      deleteFlag: false,
      placeImagePath: "",
      iconWidth: 0,
      iconHeight: 0,
      iconOffsetX: 0,
      iconOffsetY: 0,
      textValue: "",
      textSize: 0,
      textColor: "",
      textEdgeColor: "",
    }

    return newRowData

  }
}

module.exports = InspectPlaceModel;
