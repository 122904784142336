<template>
  <application-header
    :title="appTitle"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>

    <!----------------------------------------------------
      運行スポットタイプ編集、運行スポット設定ボタン
    ------------------------------------------------------>
    <div class="d-flex button-area">
      <div class="col-sm-2">
            <base-button type="primary" :disabled="true" block @click="onClickDriveSpot">スポット登録</base-button>
        </div>
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpotTypeEdit">スポットタイプ編集</base-button>
        </div>
        <div class="col-sm-2">
            <base-button type="primary" block @click="onClickDriveSpotSetting">スポット設定</base-button>
        </div>
    </div>

      <!-- 説明・運行スポット件数 -->
      <div class="d-flex" style="margin-top:50px">
        <div class="my-box flex-fill pl-1">
          <h3>
            スポット範囲とタイプを選択し、運行スポットを設定したい箇所を地図上でクリックしてください（最大100箇所まで）
          </h3>
        </div>
        <div class="my-box pr-1">
          <h3>{{ spotCount }} 件</h3>
        </div>
      </div>

      <div class="d-flex drivespot-main">
        <!-- メニュー -->
        <div>
          <div class="mx-1 p-2 rounded drivespot-list bgcolor-light" >
            <h4>スポット種別</h4>
            <el-radio-group
              class="mb-2"
              v-model="operationType"
              size="large"
              :fill="'#154CA3'"
              @change="changeType"
            >
              <el-radio-button label="1">車両間隔</el-radio-button>
              <el-radio-button label="0">運行スポット</el-radio-button>
            </el-radio-group>

            <h4>形状</h4>
            <el-radio-group
              class="mb-2"
              v-model="operationShape"
              size="large"
              :fill="'#154CA3'"
              @change="changeShape"
            >
              <el-radio-button label="0">円</el-radio-button>
              <el-radio-button label="1">ポリゴン</el-radio-button>
              <el-radio-button label="2">地図操作</el-radio-button>
            </el-radio-group>

            <h4>操作モード</h4>
            <el-radio-group
              class="mb-2"
              v-model="operationMode"
              size="large"
              :fill="operationMode == '2' ? '#FF0000' : '#154CA3'"
              @change="changeMode"
            >
              <el-radio-button label="0">作成</el-radio-button>
              <el-radio-button label="1">編集</el-radio-button>
              <el-radio-button label="2">削除</el-radio-button>
            </el-radio-group>

            <div v-if="operationShape == 0">
              <h4>スポットの範囲<require-tag /></h4>
              <div class="d-flex align-items-center mb-3">
                <el-input-number
                  ref="startRadius"
                  class="w-50"
                  v-model="spotRange"
                  :controls="false"
                  :min="1"
                  :max="999"
                  step-strictly
                  placeholder="数値で入力"
                ></el-input-number>
                <h5 class="mx-2 mt-1">m</h5>
              </div>
            </div>

            <div
              class="d-flex align-items-center my-2"
              v-if="operationShape == 1"
            >
              <el-button
                class="button-menu"
                size="medium"
                round
                @click="onClickCancelStartShapeMode"
              >
                <span class="fas fa-eraser mr-2"></span
                ><span>作図キャンセル</span>
              </el-button>
            </div>

            <h4 class="">スポットのタイプ</h4>
            <base-input name="spotType">
              <el-select
                v-model="spotType"
                readonly="true"
                reserve-keyword
                placeholder="選択してください"
                @change="changeSpotType"
              >
                <el-option
                  v-for="item in this.spotTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.name"
                >
                  <img
                    class="statusIcon"
                    style="width:27px;height:27px;"
                    :src="item.iconUrl"
                  /><span class="">{{ item.name }}</span>
                </el-option>
                <template #prefix>
                  <img
                    v-if="iconUrl"
                    class="statusIcon"
                    style="width:32px;height:32px;"
                    :src="iconUrl"
                  />
                </template>
              </el-select>
            </base-input>

            <div v-if="operationMode == 1">
              <div class="row mt-2 mb-2 ml-1 mr-1">
                <div class="col-sm-6">
                  <base-button
                    type="secondary"
                    block
                    @click="onClickDeselection"
                    style="padding: 10px 0px;"
                    >選択解除</base-button
                  >
                </div>

                <div class="col-sm-6">
                  <base-button
                    type="primary"
                    block
                    @click="onClickReflect"
                    style="padding: 10px 0px;"
                    >反映</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 地図 -->
        <div class="flex-fill">
          <drive-spot-map class="drivespot-map"
            ref="map"
            @onAreaClickAdd="onAreaClickAdd"
            @onAreaClickSelect="onAreaClickSelect"
            @onAreaClickMove="onAreaClickMove"
            @onAreaClickRemove="onAreaClickRemove"
            @onDrawPolygonFinish="onDrawPolygonFinish"
          ></drive-spot-map>

          <div class="my-3">
            <div style="width: 480px; margin:0 auto;">
              <div class="row mt-2 mb-2 ml-2 mr-2">
                <!-- <div class="col-sm-5">
                  <base-button type="secondary" block @click="onClickDriveVue"
                    >前に戻る</base-button
                  >
                </div> -->

                <div class="col-sm-2"></div> 

                <div class="col-sm-6">
                  <base-button type="primary" block @click="onClickSave"
                    >保存</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 凡例 -->
        <div class="guide-list ml-2 mr-0">
          <h5>凡例</h5>
          <h5 class="m-0 p-1 bg-light rounded-top">拠点</h5>
          <div class="border rounded-bottom mb-1">
            <div class="d-flex align-items-center ml-1">
              <div v-if= !isZando class="start-point"></div>
              <div v-if= isZando class="start-zandopoint"></div>
              <h4 class="mx-2 mt-1 font-sm">出発地点</h4>
            </div>
            <div class="d-flex align-items-center ml-1">
              <div v-if= !isZando class="end-point"></div>
              <div v-if= isZando class="end-zandopoint"></div>
              <h4 class="mx-2 mt-1 font-sm">到着地点</h4>
            </div>
          </div>
          <h5 class="m-0 p-1 bg-light rounded-top">運行スポット</h5>
          <div class="border rounded-bottom mb-1">
            <el-scrollbar :always=true style="height:150px;" >
              <div
                class="d-flex align-items-center ml-1 mt-2"
                v-for="item in driveSpotList"
                :key="item.name"
              >
                <div
                  class="spot-icon"
                  :style="{ 'background-image': 'url(' + item.iconUrl + ')' }"
                  style="margin-top: 0px"
                ></div>
                <h4 class="mx-2 mt-0 font-sm">{{ item.name }}</h4>
              </div>
            </el-scrollbar>
          </div>
          <h5 class="m-0 p-1 bg-light rounded-top">運行間隔スポット</h5>
          <div class="border rounded-bottom mb-1">
            <el-scrollbar :always=true style="height:150px;" class="border-secondary rounded">
            <div
              class="d-flex align-items-center ml-1 mt-2"
              v-for="item in intervalSpotList"
              :key="item.name"
            >
              <div
                class="spot-icon"
                :style="{ 'background-image': 'url(' + item.iconUrl + ')' }"
                style="margin-top: 0px"
              ></div>
              <h4 class="mx-2 mt-0 font-sm">{{ item.name }}</h4>
            </div>
          </el-scrollbar>
          </div>
          <div class="">
            <h5 class="m-0">表示</h5>
            <div class="d-flex ml-1">
              <el-checkbox
                v-model="checkDispLayDispLayer.dispInterval"
                label="車両間隔"
                @change="changeChkDispIntervalLayer(checkDispLayDispLayer.dispInterval)"
              />
            </div>
            <div class="d-flex ml-1" style="margin-top:-20px">
              <el-checkbox
                v-model="checkDispLayDispLayer.dispSpot"
                label="運行スポット"
                @change="changeChkDispSpotLayer(checkDispLayDispLayer.dispSpot)"
              />
            </div>  
          </div>

        </div>
      </div>
    <!-- </el-tab-pane> -->

</template>

<style></style>
<style scoped>
/** このVueだけのスタイル */
.button-area {
  margin-top: 5px;
  width: 100%;
  height: 10px;
}
.drivespot-main {
  overflow-x: hidden;
  border-radius: 0;
  height: calc(100% - 180px);
}
.drivespot-list {
  width: 270px;
  height: 100%;
}
.drivespot-map {
  height: calc(100% - 80px);
}
.guide-list {
  height: calc(100% - 20px);
}
.start-point {
  width: 27px;
  height: 27px;
  background-image: url("/img/concrete/icon_2y_64.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 27px;
}
.end-point {
  width: 27px;
  height: 27px;
  background-image: url("/img/concrete/icon_1r_64.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 27px;
}
.start-zandopoint {
  width: 27px;
  height: 27px;
  background-image: url("/img/concrete/icon_1r_64.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 27px;
}
.end-zandopoint {
  width: 27px;
  height: 27px;
  background-image: url("/img/concrete/soil_goal.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 27px;
}
.spot-icon {
  width: 27px;
  height: 27px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 27px 27px;
}
</style>

<script>
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import appLog from "@/appUtils/AppLog";
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import DriveSpotMap from "@/appViews/Projects/DriveSpotMap.vue";
import SpotModel from "@/appModel/Spot/SpotModel";
const { Storage } = require("aws-amplify");
import { useToast } from "vue-toastification";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import SpotTypeModel from "@/appModel/Spot/SpotTypeModel";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage";

import IntervalSettingModel from "@/appModel/Spot/IntervalSettingModel";
import TableToolMenu from "../components/Menu/TableToolMenu";
import Modal from "@/components/Modal";
import ValidateUtil from "@/appUtils/ValidateUtil";
import DateUtil from "@/appUtils/DateUtil";
import Mime from "@/appUtils/Mime.js";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

// ポリゴン形状ジオフェンスの最大頂点数
const MAX_POLYGON_VERTEX_COUNT = 100;

// 図形種別: 円
const ID_SHAPE_TYPE_CIRCLE = "0";

// 図形種別: ポリゴン
const ID_SHAPE_TYPE_POLYGON = "1";

// 図形種別: なし（地図操作）
const ID_SHAPE_TYPE_NON = "2";

export default {
  components: {
    ApplicationHeader,
    DriveSpotMap,
    // TableToolMenu,
    // Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      acctivePane: "0",
      beforeAcctivePane: "0",
      projectId: null,
      projectName: null,
      datestring: null,
      appTitle: "運行スポット",
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      operationType: 1, // 操作スポット種別 - 0: 運行スポット, 1: 車両間隔
      operationShape: 0, // 操作描画形状 - 0: 円, 1: ポリゴン, 2: なし
      operationMode: 0, // 操作モード - 0: 作成, 1: 編集, 2: 削除
      spotTypeList: [],
      spotTypeGuideList: [],
      iconUrl: null,
      baseAreaList: [],
      spotList: [],
      additionalList: [],
      modificationList: [],
      removalList: [],
      spotCount: 0,
      spotRange: 10,
      spotType: null,
      listIconElement: "",
      selectedSpot: [],
      confirmResultOK: true, 
      checkDispLayDispLayer: {
        /** 運行スポット表示フラグ (初期値：true) */
        dispSpot: true,
        /** 車両間隔表示フラグ (初期値：true) */
        dispInterval: true,
      },
      spotTypeEditList: [],
      icomImageList: {},
      spotDivList: [
        {id: 0, name: '運行スポット'}, 
        {id: 1, name: '運行間隔'}, 
      ],
    };
  },

  // コンピュートプロパティ
  computed: {
    // 運行スポットリスト取得
    driveSpotList: function(){
      return this.spotTypeGuideList.filter((list) => list.spotType == 0); 
    },
    // 運行間隔スポットリスト取得
    intervalSpotList: function(){
      return this.spotTypeGuideList.filter((list) => list.spotType == 1); 
    },
    vuename() {
      return "DriveSpot.vue"
    }
    // selectRow() {
    //   if (this.userAuthority == 0)
    //   {
    //     return false;
    //   }
    //   if (this.selectedDataCnt > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    //},    
    // /***
    //  * ページング用のデータをかえす
    //  */
    // pagedData() {
    //   return this.spotTypeEditList.slice(this.from, this.to)
    // },
    // /**
    //  * ページング制御用
    //  */
    // to() {
    //   let highBound = this.from + this.pagination.perPage;
    //   if (this.total < highBound) {
    //     highBound = this.total;
    //   }
    //   return highBound;
    // },
    // /**
    //  * ページング制御用
    //  */
    // from() {
    //   return this.pagination.perPage * (this.pagination.currentPage - 1);
    // },
    // /**
    //  * ページング制御用
    //  */
    // total() {
    //   return this.spotTypeEditList.length;
    // },
    
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog(
      "BaseArea.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivespot.projectid})、projectname(${this.$store.state.drivespot.projectname})、datestring(${this.$store.state.drivespot.datestring})`
    );

    this.init();
  },

  async beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  setup() {
    // same as beforeRouteLeave option with no access to `this`
    // onBeforeRouteLeave((to, from) => {
    //   const answer = window.confirm(
    //     'Do you really want to leave? you have unsaved changes!'
    //   )
    //   // cancel the navigation and stay on the same page
    //   if (!answer) return false
    // })
  },

  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    if (this.confirm() === false) return;
    appLog.infoLog("BaseArea.vue", this.$store.state.user.userId, `End`);
    next();
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * ページ離脱時の、処理
     */
    confirm() {
      // 変更の有無をチェック
      if (this.isChange() == true) {
        this.repeatedHitsFlg = false;
        return window.confirm(
          "運行スポットの変更をしていますが、本ページから運行情報画面へ遷移しますか？"
        );
      } else {
        return true;
      }
    },

    async tabClick() {
      if (this.acctivePane == 0 && this.confirmResultOK) {

        // スポット図形削除  
        this.spotList.forEach(data => {
          let remList = this.removalList.filter((list) => list.spot.id == data.spot.id);  
          if (remList.length == 0) {
            this.$refs.map.deleteShapeById(data.spot.id);
            this.getSpotTypeName(data.spot.spottypeid).then((name) => {
              this.$refs.map.deletePoiById(data.spot.id, name);
            })                            
          }
        });

        // スポット図形削除（編集追加分）  
        this.additionalList.forEach(data => {
          this.$refs.map.deleteShapeById(data.spot.id);
          this.getSpotTypeName(data.spot.spottypeid).then((name) => {
            this.$refs.map.deletePoiById(data.spot.id, name);
          })
        });
                
        // スポット図形を再描画
        this.spotCount = this.spotList.length;
        this.drawSpot(this.spotList);

        // 追加、削除、編集リストの初期化
        this.additionalList = [];
        this.modificationList = [];
        this.removalList = [];        

      }
      
    },

    /**
     * 編集の有無を確認（編集があった場合はtrueを返却）
     */
    checkEdited() {

      if (this.isChange() && this.acctivePane == 0) {
        
        this.confirmResultOK = true;
        let msg = "運行スポットの変更をしていますが、本タブから選択したタブに変更しますか？";
        return new Promise((resolve, reject) => {
          if (confirm(msg)) {
            resolve()
          } else {
            this.confirmResultOK = false;
            reject()
          }
        })    
      }
    },

    /**
     * 編集の有無を確認（編集があった場合はtrueを返却）
     */
    isChange() {
      let isChange = false;

      isChange =
        this.additionalList.length > 0 ||
        this.removalList.length > 0 ||
        this.modificationList.length > 0;

      return isChange;
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },

    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();
      try {
        // store(vuex)から値取得
        this.projectId = this.$store.state.drivespot.projectid;
        this.datestring = this.$store.state.drivespot.datestring;
        this.projectName = this.$store.state.drivespot.projectname;
        this.spotTypeList = this.$store.state.drivespot.spotTypeList;
        this.spotTypeGuideList = this.spotTypeList;

        // ProjectIDがセットされているかチェック
        if (this.projectId == null || this.projectId == "") {
          this.showBottomToast(
            `拠点編集画面表示処理に失敗しました。（プロジェクトIDが不正）`,
            "error"
          ).then(() => {});
          this.$router.push({
            path: "/projects",
          });
        }

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType;
        if (this.userAuthority == 0) {
          this.editableFlag = false;
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        // 操作スポット種別
        this.operationType = "1";

        // 操作描画形状
        this.operationShape = ID_SHAPE_TYPE_NON;
        // 操作モードを設定
        this.operationMode = "0";

        // スポットタイプ一覧取得
        await this.getSpotTypeList();

        // スポットのタイプを取得
        if (this.spotTypeList.length > 0){
          this.iconUrl = this.spotTypeList[0].iconUrl;
          this.spotType = this.spotTypeList[0].name;
        }
        

        // 拠点取得
        this.baseAreaList = await BaseAreaModel.getBaseAreaList(this.projectId);

        // Polygon描画機能追加前の既存円は
        // shapeTypeとgeofenceがない場合があるのでここで設定しておく
        this.baseAreaList.forEach((item) => {
          // 図形種別
          item.baseArea.shapeType = this.normalizeShapeType(
            item.baseArea.shapeType
          );

          // ジオフェンス座標圧縮文字列
          if (!item.baseArea.geofence) {
            item.baseArea.geofence = this.getCompressCoordsText(
              item.baseArea,
              null
            );
          }
        });

        // 運行スポット取得
        this.spotList = await SpotModel.getSpotList(this.projectId);
        this.spotCount = this.spotList.length;

        // // スポット区分の値を更新
        // await this.resetSpotTypeSpotList();    

        // 地図作成
        this.$refs.map.spotTypeList = this.spotTypeGuideList;
        this.$refs.map.mapCreate();

        // 拠点・運行スポット表示
        await this.drawBaseAriaAndSpot();

        // アイコンセレクトボックスを操作スポット種別に絞り込む（車両間隔）
        await this.changeType();

        // 連打防止
        this.repeatedHitsFlg = false;
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * スポットタイプ一覧取得（spottype.orderカラム昇順でソート）
     */  
    async getSpotTypeList(){

      // テーブル関連編集初期化
      //   this.selectedData = null;      
      //   this.selectedDatas = null;      
      //   this.selectedDataCnt = 0; 
      this.spotTypeEditList = [];

      // アイコンのイメージを取得、スポットタイプのリスト取得
      let listdata = await this.getSpotTypeListAndmakeIconImage();

      // 取得した一覧を表示
      listdata.forEach(item => {    
        this.spotTypeEditList.push(item);
      });

      // メニューを非表示にする
      //this.selectedData = null;      
    },    

    /**
     * アイコンのイメージを取得、スポットタイプ一覧取得
     */  
    async getSpotTypeListAndmakeIconImage() {

      const projectId = this.projectId;
      let listdata = await SpotTypeModel.getSpotTypeList(projectId); 

      // ソートして一覧に表示
      listdata = listdata.sort(function(a, b){
        if (a.spottype.order > b.spottype.order) return 1;
        if (a.spottype.order < b.spottype.order) return -1;
        return 0
      })

      // ユーザー情報取得
      const user = await UserInfo.getUserInfo()

      // テナント毎のフォルダにアップロードするユーティリティクラスを初期化
      let tenantStorage = new TenantStorage(user.group)

      // 一覧表示用にデータ加工
      for (let i=0; i<listdata.length; i++){

        let item = listdata[i];

        // スポット区分名を取得
        for (let i=0; i<this.spotDivList.length; i++) {
          if (this.spotDivList[i].id == item.spottype.spotType) {
            item.spotDivName = this.spotDivList[i].name;
            break;
          }
        }

        // アイコンイメージダウンロード
        let response = await tenantStorage.get(item.spottype.iconpath);
        // Base64に変換しイメージを保持
        this.icomImageList[item.spottype.spottypeId] = await response.toBase64();

      } 

      // 一覧を返却
      return listdata;
    },

    /**
     * 正常なジオフェンス図形種別を取得します。
     * 図形種別が設定されていない場合は円の図形種別を返します。
     * @param {string} shapeType 図形種別
     * @returns {string} 図形種別
     */
    normalizeShapeType(shapeType) {
      // 設定されていない場合は円をデフォルト
      if (!shapeType) {
        return ID_SHAPE_TYPE_CIRCLE;
      }

      return shapeType;
    },

    /**
     * 拠点ジオフェンスの座標圧縮文字列を取得します。
     * @param {Object} baseArea 拠点情報
     * @param {Object} polygon Leaflet Polygon Layer(拠点形状がポリゴンの場合のみ)
     */
    getCompressCoordsText(baseArea, polygon) {
      // 設定されていない場合は円をデフォルト
      let shapeType = this.normalizeShapeType(baseArea.shapeType);

      let latlngs;

      if (shapeType == ID_SHAPE_TYPE_CIRCLE) {
        // 中心座標、半径から、ポリゴン座標を取得
        latlngs = CoordinateUtil.getCirclePolygonPoints(
          new L.LatLng(baseArea.y, baseArea.x),
          baseArea.radius
        );
      } else {
        // 外形の座標のみ
        latlngs = polygon.getLatLngs()[0];
      }

      // ポリゴン座標を座標圧縮文字列にする
      return MLCompressUtil.latLngArrayToBase64(latlngs);
    },

    /**
     * 画像ダウンロードとBase64エンコード
     */
    async getBase64Url(iconpath) {
      // バイナリとしてダウンロード
      const config = { level: "public", download: true };
      let data = await Storage.get(iconpath, config);

      // Base64にエンコードした値を返却する
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(data.Body);
      });
    },

    /**
     * 拠点と運行スポットを描画
     */

    async drawBaseAriaAndSpot() {
      // 拠点を描画
      let parameters = [];

      this.baseAreaList.forEach((item) => {
        parameters.push({
          id: item.baseArea.id,
          shapeType: item.baseArea.shapeType,
          areaType: item.baseArea.areaType,
          x: item.baseArea.x,
          y: item.baseArea.y,
          radius: item.baseArea.radius,
          geofence: item.baseArea.geofence,
        });
      });

      if (parameters.length > 0) {
        this.$refs.map.drawBaseArea(parameters);
      }

      // スポットを描画
      this.drawSpot(this.spotList);

      // 表示位置設定
      await this.$refs.map.setDisplayPosition();
    },

    /**
     * 運行スポットを描画
     */
    async drawSpot(list) {
      let parameters = [];
      let applicableList = [];
      let item = null;

      for (let index = 0; index < list.length; index++) {
        item = list[index];

        // 削除済みのスポットは表示しない
        if (this.removalList.length > 0) {
          applicableList = this.removalList.filter(
            (ret) => ret.spot.id == item.spot.id
          );

          if (applicableList.length > 0) continue;
        }

        let name = await this.getSpotTypeName(item.spot.spottypeid);
        parameters.push({
          id: item.spot.id,
          spottypeid: item.spot.spottypeid,
          //spotTypeName: item.spot.name,
          spotTypeName: name,
          range: item.spot.range,
          x: item.spot.x,
          y: item.spot.y,
          shapeType: item.spot.shapeType,
          geofence: item.spot.geofence
        });
      }

      if (parameters.length > 0) {
        this.$refs.map.drawSpot(parameters);
      }
    },

    /**
     * スポットタイプＩＤから名称取得
     */
    async getSpotTypeName(spottypeId){

      let list = this.spotTypeEditList.filter((ret)=> ret.spottype.spottypeId == spottypeId);
      return list[0].spottype.name;
    },

    /**
     * スポットタイプＩＤからスポット区分（0:運行スポット,1:車両間隔）取得
     */
    async getSpotType(spottypeId){
      let list = this.spotTypeEditList.filter((ret)=> ret.spottype.spottypeId == spottypeId);
      return list[0].spottype.spotType;
    },

    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 運行情報画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },

    /**
     * 作図キャンセルクリック
     */
    onClickCancelStartShapeMode() {
      this.$refs.map.stopDrawShapeMode();
      this.$refs.map.startDrawPolygonMode();
    },

    /**
     * 凡例、運行スポットチェックボックスON、OFFイベント
     */
    changeChkDispSpotLayer(value) {
      this.onOffLayer(0, value);
    },

    /**
     * 凡例、車両間隔チェックボックスON、OFFイベント
     */
    changeChkDispIntervalLayer(value) {
      this.onOffLayer(1, value);
    },

    /**
     * 地図上のアイコンレイヤ、ON・OFF処理
     */
    async onOffLayer(targetType, isLayerOn) {

      if (isLayerOn) {
        // レイヤON
        this.spotList.forEach(data => {
          // 選択しているスポット区分を対象
          if (data.spot.spotType == targetType) {
            // 削除スポット図形リストにある場合は、描画しない
            let remList = this.removalList.filter((list) => list.spot.id == data.spot.id);              
            if (remList.length == 0) {
              this.getSpotTypeName(data.spot.spottypeid).then((name) => {
                // スポット図形の描画
                let parameters = [{
                  shapeType: data.spot.shapeType,
                  geofence: data.spot.geofence,
                  id: data.spot.id,
                  spottypeid: data.spot.spottypeid,
                  spotTypeName: name,
                  range: data.spot.range,
                  x: data.spot.x,
                  y: data.spot.y,
                }, ];
                this.$refs.map.drawSpot(parameters);
              }) 
            }
          }
        });

        // 追加スポット図形一覧
        this.additionalList.forEach(data => {
          if (data.spot.spotType == targetType) {
            this.getSpotTypeName(data.spot.spottypeid).then((name) => {
              let parameters = [{
                shapeType: data.spot.shapeType,
                geofence: data.spot.geofence,
                id: data.spot.id,
                spottypeid: data.spot.spottypeid,
                spotTypeName: name,
                range: data.spot.range,
                x: data.spot.x,
                y: data.spot.y,
              }, ];
              this.$refs.map.drawSpot(parameters);
            })
          }
        });

      } else {
        // レイヤOFF
        this.spotList.forEach(data => {
          // 選択しているスポット区分を対象
          if (data.spot.spotType == targetType) {
            // 削除スポット一覧にある場合は、描画しない
            let remList = this.removalList.filter((list) => list.spot.id == data.spot.id);  
            if (remList.length == 0) {
              // アイコン、図形の削除（レイヤOFF）
              this.$refs.map.deleteShapeById(data.spot.id);
              this.getSpotTypeName(data.spot.spottypeid).then((name) => {
                this.$refs.map.deletePoiById(data.spot.id, name);
              })                            
            }
          }
        });

        // 追加スポット図形一覧
        this.additionalList.forEach(data => {
          if (data.spot.spotType == targetType) {
            // アイコン、図形の削除
            this.$refs.map.deleteShapeById(data.spot.id);
            this.getSpotTypeName(data.spot.spottypeid).then((name) => {
              this.$refs.map.deletePoiById(data.spot.id, name);
            })
          }
        });

      }
    },
    
    /**
     * スポットタイプ変更イベント
     */
    changeType() {

      // スポットタイプセレクトボックス初期化
      this.spotTypeList = [];
      this.spotType = null;
      this.iconUrl = null;
 
      // スポットタイプリストから、対象をスポットタイプセレクトボックスを再作成する
      this.spotTypeEditList.forEach(item => {  
        if (item.spottype.spotType == this.operationType) {
          this.spotTypeList.push({
            name: item.spottype.name,
            value: item.spottype.spottypeId,
            voicetext: item.spottype.voicetext,
            iconpath: item.spottype.iconpath,
            iconUrl: this.icomImageList[item.spottype.spottypeId],
            spotType: item.spottype.spotType}
          )
        }
      });

      // カレント設定
      if (this.spotTypeList.length > 0){
        this.iconUrl = this.spotTypeList[0].iconUrl;
        this.spotType = this.spotTypeList[0].name;      
      }

    },

    /**
     * 操作描画形状変更イベント
     */
    changeShape() {
      // 地図側に操作図形種別をセット
      this.$refs.map.operationShape = this.operationShape;

      // 編集モードで、編集対象図形を未選択の場合は、処理しない
      if (this.operationMode == 1) {
        if (this.selectedSpot.length == 0){
          return;
        }
      }

      // 操作図形がポリゴンの時は、ポリゴン描画モードにする
      if (this.operationShape == ID_SHAPE_TYPE_POLYGON) {
        this.$refs.map.startDrawPolygonMode();
      } else {
        this.$refs.map.stopDrawShapeMode();
      }      
    },

    /**
     * 操作モード変更イベント
     */
    changeMode() {
      this.$refs.map.operationMode = this.operationMode;
      this.$refs.map.isSelected = false;
      this.$refs.map.clearTempPoi();
      this.selectedSpot = [];
      this.$refs.map.stopDrawShapeMode();

      if (this.operationMode == 0 && this.operationShape == ID_SHAPE_TYPE_POLYGON){
        this.$refs.map.startDrawPolygonMode();
      }

    },

    /**
     * スポットのタイプ変更イベント
     */
    changeSpotType() {
      let list = this.spotTypeList.filter((list) => list.name == this.spotType);
      if (list.length > 0) {
        this.iconUrl = list[0].iconUrl;
      }
    },

    /**
     * DriveSpotMap.vueの地図イベント(ポリゴン頂点指定終了)
     */
    async onDrawPolygonFinish(params) {

      if (this.operationShape != 1) return;

      // 頂点数チェック
      let vertexCount = params.layer.getLatLngs()[0].length;

      if (MAX_POLYGON_VERTEX_COUNT < vertexCount) {
        this.showBottomToast(
          `ポリゴンの頂点数は ${MAX_POLYGON_VERTEX_COUNT} までです。`,
          "warning"
        );
        return;
      }

      if (this.operationMode == 0){
        this.addSpotPolygon(params);
      } else if (this.operationMode == 1){
        this.editSpotPolygon(params);
      }

    },

    /**
     * スポット図形（ポリゴン）追加処理
     */
    async addSpotPolygon(params){
      try {
        // 面上にある点を取得
        let p = CoordinateUtil.pointOnSurface(params.layer);

        // 追加リストに追加
        const type = this.spotTypeList.filter(
          (list) => list.name == this.spotType
        )[0];
        console.log(`addSpotPolygon spotType:${type.spotType}`)
        let data = await SpotModel.getNewData(this.projectId);
        data.spot.spotType = type.spotType;
        data.spot.spottypeid = type.value;
        data.spot.shapeType = ID_SHAPE_TYPE_POLYGON;
        data.spot.range = 0;
        data.spot.x = p[0];
        data.spot.y = p[1];
        data.spot.geofence = MLCompressUtil.latLngArrayToBase64(params.layer.getLatLngs()[0]);
        this.additionalList.push(data);      

        // 表示用運行スポットリストを更新
        this.spotCount =
          this.spotList.length +
          this.additionalList.length -
          this.removalList.length;

        // 地図再読み込み
        let parameters = [
          {
            shapeType: data.spot.shapeType,
            geofence: data.spot.geofence,
            id: data.spot.id,
            spottypeid: data.spot.spottypeid,
            spotTypeName: type.name,
            range: data.spot.range,
            x: data.spot.x,
            y: data.spot.y,
          },
        ];
        this.$refs.map.drawSpot(parameters);

      } catch (e) {
        this.errorLog("addSpotPolygon", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }        
    },

    /**
     * スポット図形（ポリゴン）編集処理
     */
    async editSpotPolygon(params){
      try {

        // 編集前アイコン・図形削除
        this.$refs.map.deleteShapeById(this.selectedSpot.spot.id);
        let name = await this.getSpotTypeName(this.selectedSpot.spot.spottypeid)
        this.$refs.map.deletePoiById(this.selectedSpot.spot.id, name);
        this.$refs.map.clearTempPoi(); 

        // 該当のスポットタイプを取得
        const type = this.spotTypeList.filter(
          (list) => list.name == this.spotType
        )[0];

        // 選択中スポットの値を変更 
        this.selectedSpot.spot.name = type.name;
        this.selectedSpot.spot.spotType = type.spotType;
        this.selectedSpot.spot.spottypeid = type.value;
        this.selectedSpot.spot.shapeType = ID_SHAPE_TYPE_POLYGON;
        this.selectedSpot.spot.range = 0;        
        if (params != null){
          // 面上にある点を取得
          let p = CoordinateUtil.pointOnSurface(params.layer);                
          this.selectedSpot.spot.x = p[0];        
          this.selectedSpot.spot.y = p[1];        
          this.selectedSpot.spot.geofence = MLCompressUtil.latLngArrayToBase64(params.layer.getLatLngs()[0]);       
        }
        // IDが同じデータを変更リストから削除
        this.modificationList = this.modificationList.filter(
          (list) => list.spot.id != this.selectedSpot.spot.id
        );

        // リストに追加
        if (
          this.additionalList.filter(
            (list) => list.spot.id == this.selectedSpot.spot.id
          ).length > 0
        ) {
          // 追加リストに存在する場合
          // 既存データを削除
          this.additionalList = this.additionalList.filter(
            (list) => list.spot.id != this.selectedSpot.spot.id
          );

          // 変更データを追加リストに再度追加
          this.additionalList.push(this.selectedSpot);
        } else {
          // 追加リストに存在しない場合
          // 変更リストに追加
          this.modificationList.push(this.selectedSpot);
        }

        // 変更後アイコン・図形追加
        let parameters = [
          {
            shapeType: this.selectedSpot.spot.shapeType,
            geofence: this.selectedSpot.spot.geofence,
            id: this.selectedSpot.spot.id,
            spottypeid: this.selectedSpot.spot.spottypeid,
            spotTypeName: this.selectedSpot.spot.name,
            range: this.selectedSpot.spot.range,
            x: this.selectedSpot.spot.x,
            y: this.selectedSpot.spot.y,
          },
        ];
        this.$refs.map.drawSpot(parameters);

        // DriveSpotMapの選択フラグを変更
        this.$refs.map.isSelected = false;

        // 選択中スポットを初期化
        this.selectedSpot = [];

        // ポリゴン作図モード終了
        this.$refs.map.stopDrawShapeMode();

      } catch (e) {
        this.errorLog("editSpotPolygon", this.parseErrorObject(e))

        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }  
    },

    /**
     * DriveSpotMap.vueの地図クリックイベント(作成モード)
     */
    async onAreaClickAdd(params) {
      try {

        // 操作描画形状が円以外は処理を抜ける
        if (this.operationShape != 0) return;

        // スポット範囲バリデーション
        if (!Number.isInteger(this.spotRange)) {
          this.showBottomToast(
            `スポット範囲は整数で入力してください`,
            "warning"
          );
          return;
        }

        // 件数チェック
        if (this.spotCount >= 100) {
          this.showBottomToast(
            `運行スポットが100件に達しています。`,
            "warning"
          );
          return;
        }

        // 追加リストに追加
        const type = this.spotTypeGuideList.filter(
          (list) => list.name == this.spotType
        )[0];
        let data = await SpotModel.getNewData(this.projectId);
        console.log(`onAreaClickAdd spotType:${this.spotType} type:${JSON.stringify(type, null, "\t")}`)
        console.log(`onAreaClickAdd ${type.spotType}`)
        // data.spot.name = type.name;
        // data.spot.value = type.value;
        // data.spot.voicetext = type.voicetext;
        // data.spot.iconpath = type.iconpath;
        data.spot.range = this.spotRange;
        data.spot.x = params.x;
        data.spot.y = params.y;
        data.spot.shapeType = ID_SHAPE_TYPE_CIRCLE,
        data.spot.geofence = null,
        data.spot.spotType = type.spotType,
        data.spot.spottypeid = type.value
        this.additionalList.push(data);

        // 表示用運行スポットリストを更新
        this.spotCount =
          this.spotList.length +
          this.additionalList.length -
          this.removalList.length;

        // 地図再読み込み
        let parameters = [
          {
            id: data.spot.id,
            spottypeid: data.spot.spottypeid,
            //spotTypeName: data.spot.name,
            spotTypeName: type.name,
            range: data.spot.range,
            x: data.spot.x,
            y: data.spot.y,
          },
        ];

        this.$refs.map.drawSpot(parameters);
      } catch (e) {
        this.errorLog("onAreaClickAdd", this.parseErrorObject(e))

        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }
    },

    /**
     * DriveSpotMap.vueの地図クリックイベント(編集モード・選択)
     */
    onAreaClickSelect(item) {
      try {
        // 該当の運行スポットとスポットタイプを取得
        let spot = this.spotList.filter((list) => list.spot.id == item.id);

        if (spot != null && spot.length > 0) {
          this.selectedSpot = spot[0];
        } else {
          this.selectedSpot = this.additionalList.filter(
            (list) => list.spot.id == item.id
          )[0];
        }

        const type = this.spotTypeList.filter(
          (list) => list.name == item.layerName
        )[0];

        // スポットの範囲とタイプを設定
        this.spotRange = this.selectedSpot.spot.range;
        try {
          this.spotType = type.name;
          this.iconUrl = type.iconUrl;
        } catch (e) {/* 選択しているスポット種別に選択したスポットがない場合は無視*/}
        // 図形の種別をセット
        this.operationShape = this.selectedSpot.spot.shapeType;
        if (this.operationShape == ID_SHAPE_TYPE_POLYGON){
          this.$refs.map.startDrawPolygonMode();
        }

        // DriveSpotMapの選択フラグを変更
        this.$refs.map.isSelected = true;
      } catch (e) {
        this.errorLog("onAreaClickSelect", this.parseErrorObject(e))

        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }
    },

    /**
     * DriveSpotMap.vueの地図クリックイベント(編集モード・移動)
     */
    onAreaClickMove(params) {
      if (this.operationShape == ID_SHAPE_TYPE_CIRCLE) {
        this.reflectChanges(params);
      }
    },

    /**
     * 編集中の内容を反映
     */
    async reflectChanges(params) {
      try {
        // スポット範囲バリデーション
        if (!Number.isInteger(this.spotRange)) {
          this.showBottomToast(
            `スポット範囲は整数で入力してください`,
            "warning"
          );
          return;
        }

        // 編集前アイコン・図形削除
        this.$refs.map.deleteShapeById(this.selectedSpot.spot.id);
        let name = await this.getSpotTypeName(this.selectedSpot.spot.spottypeid);
        this.$refs.map.deletePoiById(
          this.selectedSpot.spot.id,
          name
        );
        this.$refs.map.clearTempPoi();

        // 該当のスポットタイプを取得
        const type = this.spotTypeList.filter(
          (list) => list.name == this.spotType
        )[0];

        // 選択中スポットの値を変更
        this.selectedSpot.spot.name = type.name;
        this.selectedSpot.spot.spotType = type.spotType;
        this.selectedSpot.spot.value = type.value;
        this.selectedSpot.spot.spottypeid = type.value;
        this.selectedSpot.spot.voicetext = type.voicetext;
        this.selectedSpot.spot.iconpath = type.iconpath;
        this.selectedSpot.spot.range = this.spotRange;

        if (params) {
          this.selectedSpot.spot.x = params.x;
          this.selectedSpot.spot.y = params.y;
        }

        // IDが同じデータを変更リストから削除
        this.modificationList = this.modificationList.filter(
          (list) => list.spot.id != this.selectedSpot.spot.id
        );

        // リストに追加
        if (
          this.additionalList.filter(
            (list) => list.spot.id == this.selectedSpot.spot.id
          ).length > 0
        ) {
          // 追加リストに存在する場合
          // 既存データを削除
          this.additionalList = this.additionalList.filter(
            (list) => list.spot.id != this.selectedSpot.spot.id
          );

          // 変更データを追加リストに再度追加
          this.additionalList.push(this.selectedSpot);
        } else {
          // 追加リストに存在しない場合
          // 変更リストに追加
          this.modificationList.push(this.selectedSpot);
        }

        // 変更後アイコン・図形追加
        let parameters = [
          {
            id: this.selectedSpot.spot.id,
            spottypeid: this.selectedSpot.spot.spottypeid,            
            spotTypeName: this.selectedSpot.spot.name,
            range: this.selectedSpot.spot.range,
            x: this.selectedSpot.spot.x,
            y: this.selectedSpot.spot.y,
          },
        ];
        this.$refs.map.drawSpot(parameters);

        // DriveSpotMapの選択フラグを変更
        this.$refs.map.isSelected = false;

        // 選択中スポットを初期化
        this.selectedSpot = [];
      } catch (e) {
        this.errorLog("reflectChanges", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }
    },

    /**
     * DriveSpotMap.vueの地図クリックイベント(削除モード)
     */
    async onAreaClickRemove(params) {
      try {
        // オリジナル・追加リスト確認
        const hasOriginal =
          this.spotList.filter((list) => list.spot.id == params.id).length > 0;
        const hasAdditional =
          this.additionalList.filter((list) => list.spot.id == params.id)
            .length > 0;

        // 追加リスト・変更リストから削除
        this.additionalList = this.additionalList.filter(
          (list) => list.spot.id != params.id
        );
        this.modificationList = this.modificationList.filter(
          (list) => list.spot.id != params.id
        );

        // オリジナルリストに存在する、もしくは追加リストに存在しない場合
        // 除去リストに追加
        if (hasOriginal || !hasAdditional) {
          let spots = this.spotList.filter((list) => list.spot.id == params.id);
          if (spots.length > 0) {
            let data = await SpotModel.getNewData(this.projectId);
            data.sk = SpotModel.createSk(this.projectId, params.id);
            data.spot.x = params.x;
            data.spot.y = params.y;
            data.spot.id = params.id;
            this.removalList.push(data);
          }
        }

        // 件数反映
        this.spotCount =
          this.spotList.length +
          this.additionalList.length -
          this.removalList.length;
      } catch (e) {
        this.errorLog("onAreaClickRemove", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      }
    },

    /**
     * 選択解除ボタンクリックイベント
     */
    onClickDeselection() {
      this.$refs.map.isSelected = this.false;
      this.$refs.map.clearTempPoi();
      this.selectedSpot = [];
    },

    /**
     * 反映ボタンクリックイベント
     */
    onClickReflect() {

      // 図形未選択の場合は処理を抜ける
      if (this.selectedSpot.length == 0) {
        this.showBottomToast(`反映対象の図形が選択されていません。\n図形を選択してください。`, "warning");
        return;
      }

      // 図形種別に合わせて処理分岐      
      if (this.selectedSpot.spot.shapeType == ID_SHAPE_TYPE_CIRCLE){
        this.reflectChanges(null);
      } else if (this.selectedSpot.spot.shapeType == ID_SHAPE_TYPE_POLYGON){
        this.editSpotPolygon(null);
      }
    },

    /**
     * 保存ボタンクリックイベント
     */
    async onClickSave() {
      let loader = this.showLoader();
      try {

        this.infoLog("onClickSave", `追加${this.additionalList.length} 更新${this.modificationList.length} 削除${this.removalList.length}`)
        // 追加リストのデータをDBに登録
        for await (let data of this.additionalList) {
          console.log(`追加 ${JSON.stringify(data, null, "\t")}`)
          await SpotModel.addSpot(data);
        }
        this.additionalList = [];

        // 変更リストのデータでDBを更新
        for await (let data of this.modificationList) {
          await SpotModel.updateSpot(data);
        }
        this.modificationList = [];

        // 除去リストのデータをDBから削除
        for await (let data of this.removalList) {
          await SpotModel.deleteSpot({ pk: data.pk, sk: data.sk });
        }
        this.removalList = [];

        // 運行情報画面に遷移する
        await this.onClickDriveVue();
        
      } catch (e) {
        this.errorLog("onClickSave", this.parseErrorObject(e))

        // appLog.errLog(
        //   "DriveSpot.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },

    /**
     * スポットタイプ編集ボタンクリック時の処理
     */
    async onClickDriveSpotTypeEdit() {
    
      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;
      store.spotTypeList = this.spotTypeGuideList;
      await this.$store.commit("setDriveSpot", store);

      // スポットタイプ編集画面起動
      this.$router.push({
        path: "/driveSpotTypeEdit",
      });

    },
    /**
     * スポット設定ボタンクリック時の処理
     */
    async onClickDriveSpotSetting() {

      // ストアに値セット
      let store = this.$store.state.drivespot;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;
      store.spotTypeList = this.spotTypeGuideList;
      await this.$store.commit("setDriveSpot", store);

      // スポット設定画面起動
      this.$router.push({
        path: "/driveSpotSetting",
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
