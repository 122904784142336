<template>
  <div ref="mapContainer" class="map-container">
    <div id="map" class="map">
      <base-button
        class="btn-white btn-icon-only overmap-bottom-right leaflet-bar leaflet-touch"
        size="sm"
        @click="moveCurrentLocation"
      >
        <img src="img\concrete\gpsicon.png" style="width: 100%;" />
      </base-button>
    </div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
  /* border:1px solid green; */
}
.map {
  /* width: 500px;
    height: 500px; */

  width: 100%;
  height: 100%;
  /* border:1px solid blue; */
}

/* 円作図時のマウスカーソル */
.leaflet-grab {
  cursor: crosshair;
}

/* 円作図時のマウスカーソル */
.leaflet-dragging .leaflet-grab {
  cursor: grabbing;
}

/* ポリゴン作図時のマウスカーソル */
:deep [role="button"] {
  cursor: crosshair;
}

.overmap-bottom-right {
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 32px;
  height: 32px;
  padding: 0px 0px 0px 0px;
  z-index: 1001;
}

/* :deep .leaflet-interactive {
  cursor: grab !important;
} */
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import short from "short-uuid";
import Logger from "@/appViews/common/Logger.js";
import xss from 'xss';

export default {
  // mixins: [Logger],
  components: {},
  /**
   * プロパティ
   */
  props: {
    /**
     * 地図ID
     */
    mapId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mapStartBaseAreaId: "map1",
      mapEndBaseAreaId: "map2",
      // beforeDrawFigureId: [],
      isMovingCurrentLocation: false,
      beforeDrawCurrentPositionId: null,
    };
  },

  computed: {
    vuename() {
      return "BaseAreaMap";
    },
  },

  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後

    // ※画面表示に関連しないメンバ変数を定義

    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 前回作図した図形のID
    this.beforeDrawFigureId = null;

    // 前回作成した現在地アイコンのID
    this.beforeDrawCurrentPositionId = null

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;

    this.poiStyleList = [
      {
        layerName: "拠点アイコン",
        iconUrl: "./img/concrete/ic_marker_red.png",
        iconSize: [48, 48],
        iconOffset: [-24, -48],
        interactive: false,
      },
      {
        layerName: "現在地アイコン",
        iconUrl: "./img/concrete/gpsicon.png",
        iconSize: [48, 48],
        iconOffset: [-24, -48],
        interactive: false,
      },
    ];

    // ラベルスタイル
    this.poiLabelStyle = {
      color: "#263238",
      weight: 2,
      fillColor: "#FFFFFF",
      fontSize: 24,
      textBaseline: "middle",
      fillOpacity: 0,
      opacity: 0,
    };

    // ジオフェンス描画スタイル
    this.geofenceStyle = {
      color: "#E65100",
      weight: 2.5,
      fill: true,
      fillColor: "#E65100",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // ポリゴンジオフェンス描画中(ラバーバンド)スタイル
    this.geofenceDrawStyle = {
      color: "#021aee",
      weight: 2.5,
      opacity: 0.9,
      fillOpacity: 0.35,
      interactive: false,
    };
  },

  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    console.log(`mounted`)
    //インスタンスが DOM 要素にマウントされた後
    // this.infoLog(`mounted`, `Start ${this.vuename}`);

    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();
  },

  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },

  updated() {
    //データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    //Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();
  },

  unmounted() {
    //Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.off("click", this.onMapClick);
      this.mlMap.off(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);

      this.mlMap.remove();
    }
  },

  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      console.log(`onResize: ${this.mapId}`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },

    /**
     * 地図の作成
     * @param 初期化フラグ
     */
    mapCreate() {
      console.log(`mapCreate`)
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // --------------------
      // レイヤ追加
      // --------------------
      let bounds = [];
      bounds = this.addLayers();

      // --------------------
      // 表示オブジェクト追加
      // --------------------

      // --------------------
      // コントロール
      // --------------------

      this.mlMap.setLayerControlVisible(true);
      this.mlMap.setMapInfoControlVisible(true);

      // --------------------
      // 初期表示位置へ移動
      // --------------------

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();

      // --------------------
      // イベント
      // --------------------
      this.initMapEvent();

      // 作図スタイル
      this.initDrawShapeStyle();
    },

    /**
     * レイヤを追加します。
     * @returns 地図表示初期領域
     */
    addLayers() {
      // 初期表示領域
      let bounds = [];

      // 背景レイヤの追加
      bounds = this.addBaseLayers();

      // 拠点ジオフェンス範囲
      this.mlMap.addShapeLayer("拠点範囲");

      // アイコンレイヤ群追加
      this.addPoiLayers();

      return bounds;
    },

    /**
     * 背景レイヤを追加します。
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      let bounds = [];
      // let ext = ``;

      // 地図
      bounds = this.addGsiLayers();

      return bounds;
    },

    /**
     * 国土地理院タイル地図レイヤを追加します。
     * @returns 地図表示初期領域
     */
    addGsiLayers() {
      // console.log(`mapId:${this.mapId}`);

      this.mlMap = new MLMap(this.mapId, MLMap.VIEW_TYPE.MAP);

      this.mlMap.addGsiPaleLayer("地理院タイル 淡色地図", { opacity: 0.9 });
      this.mlMap.addGsiStdLayer("地理院タイル 標準地図", { opacity: 0.65 });
      this.mlMap.addGsiPhotoLayer("地理院タイル 写真", { opacity: 0.8 });

      this.mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([25, 120], [45, 150]);
      // const bounds = L.latLngBounds([-90, -180], [90, 180]);
      this.mlMap.setMaxBounds(bounds.pad(0.25));

      return bounds;
    },

    /**
     * アイコンレイヤ群を追加します。
     */
    addPoiLayers() {
      // 走行履歴レイヤ追加
      //
      this.addBaseAreaLayers();
    },

    /**
     * 拠点アイコンレイヤを追加します。
     */
    addBaseAreaLayers() {
      for (let i = 0; i < this.poiStyleList.length; i++) {
        const style = this.poiStyleList[i];

        // アイコン種別
        let poiStyle = {
          iconUrl: style.iconUrl,
          iconSize: style.iconSize,
          iconOffset: style.iconOffset,
          interactive: style.interactive,
        };

        let layerName = style.layerName;

        // 拠点アイコンレイヤ追加
        this.mlMap.addPoiLayer(xss(layerName), poiStyle, this.poiLabelStyle);
      }
    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // クリック
      this.mlMap.on("click", this.onMapClick);

      // 作図完了
      this.mlMap.on(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);
    },

    /**
     * 地図クリックイベント
     * @param {Object} e イベントデータ
     */
    onMapClick(e) {
      // console.log(`[${e.type}] lnglat: ${e.latlng.lng}, ${e.latlng.lat}`);

      if (this.isMovingCurrentLocation) {
        this.isMovingCurrentLocation = false;
        return;
      }
      
      // 親のBaseArea.vueの処理を呼ぶ
      this.$emit("areaClick", {
        id: this.mapId,
        x: e.latlng.lng,
        y: e.latlng.lat,
      });
    },

    /**
     * 作図完了イベント
     * @param {Object} e イベントデータ
     */
    onMapShapeDrawFinish(e) {
      console.log(`${e.type}`);

      // AWSに登録するジオフェンスポリゴンは時計回りをサポートしていないため、
      // 時計回りポリゴンの場合は座標配列要素を反転させ反時計回りポリゴンにする
      if (e.shapeType === MLMap.SHAPE_TYPE.POLYGON) {
        let isClockWise = CoordinateUtil.isClockWise(e.layer);
        console.log(`isClockWise: ${isClockWise}`);

        if (isClockWise) {
          // 座標配列要素を反転
          e.layer.getLatLngs()[0].reverse();

          let isClockWise = CoordinateUtil.isClockWise(e.layer);
          console.log(`Reverse isClockWise: ${isClockWise}`);
        }
      }

      // 親のBaseArea.vueの処理を呼ぶ
      this.$emit("drawFinish", {
        id: this.mapId,
        layer: e.layer,
      });
    },

    /**
     * ジオフェンス描画スタイルの初期処理を実施します。
     */
    initDrawShapeStyle() {
      // 作図コントロールの描画オプションを変更
      let style = this.mlMap.drawControlOptions.draw.polygon.shapeOptions;

      style.color = this.geofenceDrawStyle.color;
      style.weight = this.geofenceDrawStyle.weight;
      style.opacity = this.geofenceDrawStyle.opacity;
      style.fillOpacity = this.geofenceDrawStyle.fillOpacity;
    },

    /**
     * 拠点の削除、地図の移動を行います
     * @params 拠点情報
     */
    moveMapAndDrawFigure(item) {
      console.log(`moveMapAndDrawFigure: ${item.id}, ${item.shapeType}`);

      // 座標X、Y（0，0）の時は、何もせず戻す
      if (item.x == 0 && item.y == 0) {
        // 前の図形を削除して、処理を終わらす
        this.deleteBeforeDrawFigure();
        return;
      }

      // 出発地、到着地の前回図形を削除
      this.deleteBeforeDrawFigure();

      // 出発地、到着地の描画
      this.addGeofence(item);

      // 現在地アイコンの前回図形を削除
      this.deleteBeofreCurrentLocationIcon();

      // 現在地アイコンの描画
      this.drawCurrentLocationIcon();

      // 拠点を中心に表示
      this.fitBounds(item);
    },

    /**
     * ジオフェンス、アイコン図形を削除します
     * @params 拠点情報
     */
    deleteBeforeDrawFigure() {
      if (this.beforeDrawFigureId != null) {
        // ジオフェンス図形削除
        this.mlMap.removeShapeById(this.beforeDrawFigureId);

        // アイコン削除
        this.mlMap.removePoiById("拠点アイコン", this.beforeDrawFigureId);
      }
    },

    /**
     * 拠点のジオフェンス作図
     */
    addGeofence(item) {
      // -------------------------
      // ジオフェンスの作図
      // -------------------------
      let shapeItem = { id: item.id };

      this.mlMap.addShape(
        MLMap.SHAPE_TYPE.POLYGON,
        item.geofence,
        shapeItem,
        this.geofenceStyle
      );

      // -------------------------
      // アイコンの作図
      // -------------------------
      let layerName = "拠点アイコン";

      this.mlMap.addPoi(layerName, {
        id: item.id,
        x: item.x,
        y: item.y,
        layerName: layerName,
      });

      // -------------------------
      // 作図したIDを前回作図IDとして保持
      // -------------------------
      this.beforeDrawFigureId = item.id;
    },

    /**
     * 現在地アイコン描画
     */
    async drawCurrentLocationIcon() {

      // 現在地座標取得
      let pos = await this.getPosition()

      if (pos.x != 0 && pos.y !=0) {
        // id生成
        let id = short.generate();  
        // アイコン描画
        let layerName = "現在地アイコン";
        this.mlMap.addPoi(layerName, {
          id: id,
          x: pos.x,
          y: pos.y,
          layerName: xss(layerName),
        });
        // 作図したIDを前回作図IDとして保持
        this.beforeDrawCurrentPositionId = id;
      }
    },

    /**
     * 現在地アイコン削除（前回描画分）
     */
    async deleteBeofreCurrentLocationIcon() {
      // アイコン削除
      this.mlMap.removePoiById("現在地アイコン", this.beforeDrawCurrentPositionId);
    },

    /**
     * 現在値を取得
     */
    getPosition() {
      return new Promise((resolve, reject) => {
        // 現在地を取得
        navigator.geolocation.getCurrentPosition(
        // 取得成功した場合
          (position) => {
            const lon = position.coords.longitude; // 経度
            const lat = position.coords.latitude; // 緯度
            const result = {
              x: lon,
              y: lat
            };
            resolve(result);
          },
          // 取得失敗した場合
          (error) => {
            switch (error.code) {
              case 1: //PERMISSION_DENIED
                console.log("BaseAreaMap.getPosition：位置情報の利用が許可されていません。");
                break;
              case 2: //POSITION_UNAVAILABLE
                console.log("BaseAreaMap.getPosition：現在位置が取得できませんでした。");
                break;
              case 3: //TIMEOUT
                console.log("BaseAreaMap.getPosition：タイムアウトになりました。");
                break;
              default:
                console.log("BaseAreaMap.getPosition：その他のエラー(エラーコード:" + error.code + ")");
                break;
            }
            const result = {
              x: 0,
              y: 0
            };
            reject(result);
          }
        );
      });
    },

    /**
     * 指定された拠点が収まるように地図を移動します。
     * @param {*} item 拠点
     */
    fitBounds(item) {
      // 余白
      const PAD = 0.5;

      // 表示領域
      let bounds;

      if (item.geofence) {
        // ジオフェンス座標圧縮文字列が設定されている場合は、
        // その座標が収まる領域
        let latlngs = MLCompressUtil.base64ToLatLngArray(item.geofence);
        let polygon = L.polygon(latlngs);
        bounds = polygon.getBounds().pad(PAD);
      } else {
        // 代表点座標＋半径（ｍ）
        bounds = L.latLng(item.y, item.x)
          .toBounds(item.radius * 2)
          .pad(PAD);
      }

      // 地図移動
      console.log(`BaseAreaMap bounds: ${bounds}`)
      this.mlMap.fitBounds(bounds);
    },

    /**
     * ジオフェンスポリゴン作図モードを開始します。
     */
    startDrawPolygonMode() {
      this.mlMap.startDirectDrawShapeMode(MLMap.SHAPE_TYPE.POLYGON);
    },

    /**
     * ジオフェンス作図モードを終了します。
     */
    stopDrawShapeMode() {
      this.mlMap.stopDirectDrawShapeMode();
    },

    // 画面の中心を現在位置に移動する
    moveCurrentLocation() {
      this.isMovingCurrentLocation = true;
      this.mlMap.map.locate({ setView: true });

      // 現在地アイコンの前回図形を削除
      this.deleteBeofreCurrentLocationIcon();
      // 現在地アイコンの描画
      this.drawCurrentLocationIcon();
    },
    
  },
};
</script>
