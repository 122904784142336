const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * 拠点用のデータ取得モデルクラス
 */
class BaseAreaModel extends baseModel {
  /**
   * テナント内の拠点リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getBaseAreaList(projectId) {
    return await super.getList({ beginsWith: `baseArea#${projectId}` })
  }

  /**
   * 拠点を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteBaseArea(data) {
    await super.deleteData(data)
  }

  /**
   * 拠点管理レコードを追加する
   * @param {*} data
   */
  static async addBaseArea(data) {
    await super.addData(data)
  }

  /**
   * 拠点管理レコードを更新する
   * @param {*} data
   */
  static async updateBaseArea(data) {
    await super.updateData(data)
  }

  /**
   * 拠点管理レコードを取得する
   * @param {*} data
   */
  static async getBaseArea(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    // baseArea#プロジェクトID#拠点ID(uuidで採番)

    return `baseArea#${projectId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    //const id = short.generate()
    let date = new Date()
    const id = DateUtil.dateStringBase(date, "YYYYMMDDHHmmssSSS")

    newRowData.sk = this.createSk(projectId, id)
    
    newRowData.baseArea = {
      id: id,
      areaName: "",
      areaType: 1,
      shapeType: "1",
      x: 0,
      y: 0,
      radius: 0,
      geofence: "",
    }

    return newRowData
 
  }

  /**
   * 拠点名称を取得します。
   * @param {*} id 拠点ID
   * @param {*} baseAreaList 拠点情報リスト
   * @returns 拠点名称
   */
  static getBaseAreaName(id, baseAreaList) {
    let name = ``;

    for (let i=0; i<baseAreaList.length; i++) {
      const area = baseAreaList[i];

      if (area.baseArea.id == id)
      {
        name = area.baseArea.areaName;
        break
      }
    }

    return name;
  }

}

module.exports = BaseAreaModel;
