const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * デバイス用のデータ取得モデルクラス
 */
class DeviceModel extends baseModel {

  /**
   * lsiStr1にてレコード取得(deviceId)
   * @param {*} deviceId
   */
  static async getDeviceList(deviceId){
    return await super.getList({ beginsWith: "deviceid" });
  }

  /**
   * デバイスを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteDevice(data) {
    return await super.deleteData(data)
  }

  /**
   * デバイス管理レコードを追加する
   * @param {*} data
   */
  static async addDevice(data) {
    return await super.addData(data)
  }

  /**
   * デバイス管理レコードを更新する
   * @param {*} data
   */
  static async updateDevice(data) {
    return await super.updateData(data)
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(id) {
    return `deviceid#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(id)
    
    newRowData.deviceId = {
      deviceId: "",
      modelName: "",
      lastUsedDate: "",
      createDate: "",
      deviceType: 1,
      userId: ""
    }

    return newRowData
    
  }
}

module.exports = DeviceModel;
