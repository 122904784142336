const { API, graphqlOperation } = require("aws-amplify");
const {
  listKonoikeConcreteSettings,
  getKonoikeConcreteSetting,
} = require("@/graphql/queries");
const { updateKonoikeConcreteSetting } = require("@/graphql/mutations");
const lodash = require("lodash");

/**
 * KonoikeConcreteData-settingテーブルのデータ取得モデルクラス
 */
class settingModel {
  static pk = "monthTemperature";

  /**
   * リストを取得する
   * @param {pk}} pk
   * @param {sk}} skCondition
   * @returns
   */
  static async getList(pk, skCondition) {
    let nextToken = "";
    let dataList = [];

    while (nextToken !== null) {
      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(listKonoikeConcreteSettings, {
            pk: pk,
            sk: skCondition, //{ beginsWith: "spottypebase" },
            nextToken,
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(listKonoikeConcreteSettings, {
            pk: pk,
            sk: skCondition,
          })
        );
      }

      if (result) {
        if (result.data) {
          dataList = dataList.concat(
            result.data.listKonoikeConcreteSettings.items
          );

          nextToken = result.data.listKonoikeConcreteSettings.nextToken;
        }
      }

    }

    return dataList;
  }
  static async getProjectLimit() {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteSetting, {
        pk: "projectLimit",
        sk: "projectLimit#0",
      })
    );

    return result.data.getKonoikeConcreteSetting;
  }
  static async getBaseAreaLimit() {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteSetting, {
        pk: "baseAreaLimit",
        sk: "baseAreaLimit#0",
      })
    );

    return result.data.getKonoikeConcreteSetting;
  }
  static async getUserLimit() {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteSetting, {
        pk: "userLimit",
        sk: "userLimit#0",
      })
    );

    return result.data.getKonoikeConcreteSetting;
  }
  /**
   * ミーティング管理レコードを取得する
   * @param {*} data
   */
  static async getSingleData(data) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteSetting, { pk: this.pk, sk: data.sk })
    );

    return result.data.getKonoikeConcreteSetting;
  }
  /**
   * テナント管理連番をインクリメントしてレスポンス
   */
  static async incrementTenantIndex() {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteSetting, {
        pk: "tenantIndex",
        sk: "tenantIndex",
      })
    );
    let data = lodash.cloneDeep(result.data.getKonoikeConcreteSetting);
    console.log(`getKonoikeConcreteSetting ${JSON.stringify(data)}`);
    if (data.intValue === null || data.intValue === undefined) {
      data.intValue = 0;
    } else {
      data.intValue += 1;
    }
    let newValue = data.intValue;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__typename;
    let keys = Object.keys(data)
    keys.forEach(key => {
      if (!data[key]) {
        delete data[key]
      }
    })
    
    console.log(`getKonoikeConcreteSetting ${JSON.stringify(data)}`);
    await API.graphql(
      graphqlOperation(updateKonoikeConcreteSetting, { input: data })
    );
    return newValue;
  }
  // /**
  //  * 新規登録用のスキーマ
  //  */
  // static async getNewData() {
  //   const loginInfo = await UserInfo.getUserInfo()
  //   if (!loginInfo.group) {
  //     return null
  //   }
  //   const tenantId = loginInfo.group
  //   return {
  //     pk: `${tenantId}`,
  //     sk: "",
  //     lsiStr1: "-",
  //     lsiStr2: "-",
  //     lsiStr3: "-",
  //     lsiStr4: "-",
  //     lsiInt1: 0,
  //     groups: [tenantId],
  //     invalid: false,
  //     ttl: 0

  //   };
  // }
}

module.exports = settingModel;
