const settingModel = require("../settingModel")

/**
 * 温度から運搬時間設定のデータ取得モデルクラス
 */
class DeliveryLimitModel extends settingModel {
  /**
   * 温度から運搬時間設定リストを取得する
   * @returns
   */
  static async getDeliveryLimitList() {
    return await super.getList("deliveryLimit", { beginsWith: `deliveryLimit#` })
  }
}

module.exports = DeliveryLimitModel;
