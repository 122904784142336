<template>
  <div class="container-fluid mt-1" v-if="userData">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <el-form :model="formData" :rules="rules" ref="form">
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-lg-2">
                  <button
                    type="button"
                    class="btn btn-secondary w-100"
                    @click="cancel"
                    v-if="!fromNavbar"
                  >
                    キャンセル
                  </button>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-5" v-if="!isEdit || allowChangePassword"></div>
                <div class="col-lg-4" v-if="isEdit && !allowChangePassword"></div>
                <div class="col-lg-2" v-if="!isEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="makeUser"
                  >
                    ユーザー作成
                  </button>
                </div>
                <div class="col-lg" v-if="!fromNavbar && isEdit && !allowChangePassword"></div>
                <div class="col-lg-2" v-if="isEdit && allowEdit">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    @click="updateUser"
                  >
                    ユーザー更新
                  </button>
                </div>
                <!-- <div class="col-lg-3" v-if="isEdit && !allowChangePassword"></div> -->
                <!-- <div class="col-lg text-center" v-if="!fromNavbar && isEdit && !allowChangePassword"> -->
                <div class="col-lg text-center" v-if="isEdit && !isLoginUser && !isSuperKanri">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-ellipsis-v mt-3"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="deleteUser" icon="el-icon-delete">ユーザーを削除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                  <!--
                  <button
                    type="button"
                    class="btn btn-danger w-100"
                    @click="clickDeleteUser"
                    v-if="!fromNavbar && isEdit && !allowChangePassword"
                  >
                    ユーザーを削除
                  </button>
                  -->
                </div>
                <div class="col-12">
                  <el-divider></el-divider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <h4>ログインユーザーID<require-tag /></h4>
                  <el-form-item prop="userId">
                    <el-input
                      placeholder="ログインユーザーID"
                      v-model="formData.userId"
                      :disabled="isEdit"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12">
                  <h4>ユーザー名<require-tag /></h4>
                  <el-form-item prop="name">
                    <el-input
                      placeholder="ユーザー名"
                      v-model="formData.name"
                      :disabled="!allowEdit"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-12">
                  
                  <h4>権限<require-tag /></h4>
                  <el-form-item prop="usertype">
                    <el-select
                      v-if="formData.usertype != '2'"
                      class="w-100"
                      placeholder=""
                      v-bind:disabled="isNormal || allowChangePassword"
                      v-model="formData.usertype" >
                      <el-option v-for="item in userTypeist"
                                class="select-primary"
                                :value="item.value"
                                :label="item.name"
                                :key="item.name">
                      </el-option>
                    </el-select>
                    <el-select
                      v-else
                      class="w-100"
                      placeholder=""
                      v-bind:disabled="isNormal || allowChangePassword || !allowEdit"
                      v-model="formData.usertype" >
                      <el-option v-for="item in userTypeistSuper"
                                class="select-primary"
                                :value="item.value"
                                :label="item.name"
                                :key="item.name">
                      </el-option>
                    </el-select>  
                  </el-form-item>
                  
                </div>
                <div class="col-lg-12" v-if="isEdit && allowUserDisable">
                  <el-divider></el-divider>
                  <base-checkbox class="mb-3 text-primary" v-model="isDisable">
                      このユーザーを無効にする
                  </base-checkbox>
                  <el-divider></el-divider>
                </div>
                <div class="col-lg-12" v-if="isEdit && allowChangePassword">

                  <base-checkbox class="mb-3 text-primary" v-model="changePassword" @change="changeChangePassword">
                      パスワードを変更する
                  </base-checkbox>

                  <div class="pl-lg-4">
                    <div class="col-lg-12">
                      <h4>現在のパスワード<require-tag v-if="changePassword" /></h4>
                      <el-form-item prop="currentPassword">
                        <el-input
                          type="password"
                          placeholder="現在のパスワード"
                          v-model="formData.currentPassword"
                          :disabled="!changePassword"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <div class="col-lg-12">
                      <h4>パスワード<require-tag v-if="changePassword" /></h4>
                      <el-form-item prop="password">
                        <el-input
                          type="password"
                          placeholder="パスワード"
                          v-model="formData.password"
                          :disabled="!changePassword"
                        ></el-input>
                      </el-form-item>
                    </div>

                    <div class="col-lg-12">
                      <h4>パスワードの確認<require-tag v-if="changePassword" /></h4>
                      <el-form-item prop="confirmPassword">
                        <el-input
                          type="password"
                          placeholder="パスワードの確認"
                          v-model="formData.confirmPassword"
                          :disabled="!changePassword"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12" v-if="!isEdit">
                  <h4>パスワード<require-tag /></h4>
                  <el-form-item prop="password">
                    <el-input
                      type="password"
                      placeholder="パスワード"
                      v-model="formData.password"
                    ></el-input>
                  </el-form-item>
                </div>

                <div class="col-lg-12" v-if="!isEdit">
                  <h4>パスワードの確認<require-tag /></h4>
                  <el-form-item prop="confirmPassword">
                    <el-input
                      type="password"
                      placeholder="パスワードの確認"
                      v-model="formData.confirmPassword"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </card>
      </div>
      <modal v-model:show="modal.isShow">
        <template v-slot:header>
            <h5 class="modal-title" id="modalLabel">{{modal.title}}</h5>
        </template>
        <div>
          {{modal.message}}
        </div>
        <template v-slot:footer>
          <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
          <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import API from "@aws-amplify/api";
import { Auth } from 'aws-amplify'
import UserInfo from "../../appUtils/UserInfo.js";
import UserModel from "../../appModel/users/UserModel"
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal";
import appLog from "@/appUtils/AppLog"
import ValidateUtil from "@/appUtils/ValidateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
export default defineComponent({
  name: "MakeUser",
  props: {
    msg: String,

    userId: String
  },
  /**
   * コンポーネント
   */
  components: {
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      username: "",
      email: "",
      group: "",
      currentPassword: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
      editUserId: "",
      userData: null,
      changePassword: false,
      isDisable: false,
      isBackUserList: false,
      isEdit: false,
      fromNavbar: false,
      allowChangePassword: false,
      allowUserDisable: false,
      ruleForm: {
        name: '',
      },
      rules: {
        userId: [
          { required: true, validator: this.checkUserId, trigger: "blur" },
        ],
        name: [
          { required: true, validator: this.checkName, trigger: "blur" },
        ],
        usertype: [
          { required: true, validator: this.checkUsertype, trigger: "blur" },
        ],
        currentPassword: [
          { required: true, validator: this.checkCurrentPassword, trigger: "blur" },
        ],
        password: [
          { required: true, validator: this.checkPassword, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, validator: this.checkConfirmPassword, trigger: "blur" },
        ],
      },
      userTypeist: [
        {value: "1", name: "管理者"},
        {value: "0", name: "一般"},
        {value: "3", name: "iOSアプリ"}
      ],
      userTypeistSuper: [
        {value: "2", name: "スーパー管理者"}
      ],
      loader: null,
      modal: {
        isShow: false,
        title: "",
        message: "",
        okLabel: "OK",
        okType: "secondary",
        okCallBack: function() {
          alert(`push Ok`)
          this.modal.isShow = false
        },
        cancelLabel: "キャンセル",
        cancelType: "primary",
        cancelCallBack: function() {
          alert(`push cancel`)
          this.modal.isShow = false
        },
      },
      isNormal: false,
      userIdLength: 50,
      userNameLength: 50,
      userPasswordLenght: 50,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）
      isLoginUser: false,
      isSuperKanri: false,
      allowEdit: true,
      formData: {
        userId: "",
        name: "",
        usertype: "0",
        currentPassword: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  computed: {
    vuename() {
      return "MakeUser.vue"
    }
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog("MakeUser.vue", this.$store.state.user.userId, `Start Vuex：editUserId(${this.$store.state.user.editUserId})、createFlg(${this.$store.state.user.createFlg})、fromNavbar(${this.$store.state.user.fromNavbar})、cognitoSub(${this.$store.state.user.cognitoSub})、userId(${this.$store.state.user.userId})、userType(${this.$store.state.user.userType})、userName(${this.$store.state.user.userName})、loginDateTIme(${this.$store.state.user.loginDateTIme})、group(${this.$store.state.user.group})`)
    this.init()
  },
  mounted() {
    this.init()

  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  beforeRouteLeave() {
    appLog.infoLog("MakeUser.vue", this.$store.state.user.userId, `End`)
  },
  methods: {

    init() {
      this.infoLog("init", "start")
      // 編集するユーザーID
      const params = this.$store.state.user
      if (params.editUserId == null && params.createFlg == false){
        // 対象のユーザーIDも新規作成フラグもない場合は例外をスローする
        // console.log(`必須パラメータ不足`)
        this.$router.push({
          name: "Dashboard",
        });
        return
      }

      this.editUserId = params.editUserId
      this.isEdit = !params.createFlg
      this.fromNavbar = params.fromNavbar

      // console.log(`MakeUser init ${this.editUserId}`)

      UserInfo.getUserInfo().then(function(loginInfo) {
        // console.log(loginInfo)
        
        // 編集対象がログインユーザかどうかを判定
        this.isLoginUser = this.editUserId == loginInfo.sub;

        this.group = loginInfo.group
        this.allowChangePassword = params.editUserId == loginInfo.sub
        // this.allowChangePassword = (params.editUserId == loginInfo.userName)

        // ログインユーザー―が一般の場合、権限は変更不可
        const pk = this.group
        const sk = UserModel.createSk(loginInfo.sub)
        UserModel.getUser({pk: pk, sk: sk}).then(function(loginUser) {
          this.isNormal = !Number(loginUser.user.usertype);

        }.bind(this))
      }.bind(this))

      if (this.isEdit) {
        //更新
        try {
          this.getUser()
          

        } catch (e) {
          // console.log(`更新データ取得エラー`)
          // this.errorLog("MakeUser.vue", this.$store.state.user.userId, `${e.message}`)
          this.errorLog("init", this.parseErrorObject(e))
          throw e
        }

      } else {
        //新規

        try {
          this.newUser()
        } catch (e) {
        // console.log(`更新データ取得エラー`)
          // appLog.errLog("MakeUser.vue", this.$store.state.user.userId, `${e.message}`)
          this.errorLog("init", this.parseErrorObject(e))
          throw e
        }
      }

      // タイトルをセット
      let navInfo = this.$store.state.navInfo
      navInfo.title = this.isEdit ? "ユーザー情報の編集" : "新規ユーザーを追加"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)

      // 確認モーダル
      this.modal.isShow = false;

      // 連打防止
      this.repeatedHitsFlg = false
      this.infoLog("init", "end")
    },
    /**
     * 更新対象のユーザー情報を取得
     */
    async getUser() {
      // ログインユーザーのDB情報取得
      let loginInfo = await UserInfo.getUserInfo()
      let loginUserData = await UserModel.getUser({pk: this.group, sk: UserModel.createSk(loginInfo.sub)})
      // 編集対象ユーザーのDB情報取得
      this.userData = await UserModel.getUser({sk: UserModel.createSk(this.editUserId)})
      this.isDisable = this.userData.user.status === "0"
      // 編集対象がスーパー管理者であるか判定
      this.isSuperKanri = this.userData.user.usertype == "2"
      // 編集データがスーパー管理者であり、かつログインユーザーがスーパー管理者ではない場合は編集不可とする
      if (this.isSuperKanri && loginUserData.user.usertype != "2") {
        this.allowEdit = false
      }
      // ユーザー情報をフォーム情報に設定
      this.setFormData();
    },
    /**
     * 新規ユーザー情報を取得
     */
    async newUser() {
      const loginInfo = await UserInfo.getUserInfo()
      let newUserRow = await UserModel.getNewData()

      newUserRow.pk = loginInfo.group

      this.userData = newUserRow

      // ユーザー情報をフォーム情報に設定
      this.setFormData();
    },
    /**
     * ユーザー情報をフォーム情報に設定
     */
    setFormData() {
      this.formData.userId = this.userData.user.userId;
      this.formData.name = this.userData.user.name;
      this.formData.usertype = this.userData.user.usertype;
      this.formData.currentPassword = "";
      this.formData.password = "";
      this.formData.confirmPassword = "";
    },
    /**
     * ユーザー情報を更新
     */
    async updateUser() {
      await this.$refs.form.validate(
        async function(result) {
          // 連打防止
          if (this.repeatedHitsFlg) return;
          this.repeatedHitsFlg = true;

          try {
            this.loader = this.$loading.show();

            // フォーム入力基本チェック
            if (!result) {
              console.log(`error`);
              this.showBottomToast("入力内容にエラーがあります。", "error");
              return;
            }

            if (this.changePassword) {
              if (this.formData.password != this.formData.confirmPassword) {
                //不一致はエラー
                this.showBottomToast(`パスワードが一致しません`, `error`);
                return;
              }
            }

            // フォーム情報をユーザー情報に反映
            this.getFormData();
            this.userData.user.status = this.isDisable ? "0" : "1";

            // ユーザー情報更新はAPIにて行う
            const apiName = "konoikeconcreteapi";
            const path = "/updateuser";

            const userdata = {
              response: true,
              body: {
                userId: this.userData.user.userId,
                password: this.formData.password,
                groupName: this.group,
                userType: this.userData.user.usertype,
                email: "dammy-email@dammy.aaa",
                userName: this.userData.user.name,
                changePasswordFlg: this.changePassword
              },
            };

            const response = await API.post(apiName, path, userdata);
            //  以下の処理は廃止する

            // await UserModel.updateUser(this.userData);

            // パスワード変更処理
            // if (this.changePassword) {
            //   const user = await Auth.currentAuthenticatedUser(); // ログイン中のユーザー情報
            //   await Auth.changePassword(
            //     user,
            //     this.formData.currentPassword,
            //     this.formData.password,
            //   );
            // }
            console.log(`updateuser: ${JSON.stringify(response)}`)
            let apiSuccess = false
            if (response) {
              if (response.data) {
                apiSuccess = response.data.success
              }
            }
            if (apiSuccess) {
              // 成功
              if (!this.fromNavbar) {
                this.$router.push({
                  name: "UserList",
                });
                return
              } else {
                this.showBottomToast(`更新しました`, `info`);
                return
              }
            } else {
              // 失敗
              this.showBottomToast(`更新に失敗しました`, `error`);
              return;
            }
            
          } catch (e) {
            // appLog.errLog("MakeUser.vue", this.$store.state.user.userId, `${e.message}`);
            this.errorLog("init", this.parseErrorObject(e))
            if (e.code == "NotAuthorizedException") {
              this.showBottomToast(`現在のパスワードが違います`, `error`);
            } else {
              this.showBottomToast(`更新時にエラーが発生しました`, `error`);
            }
            throw e;
          } finally {
            // 連打防止
            this.repeatedHitsFlg = false;
            this.loader.hide();
            this.loader = null;
          }

        }.bind(this));
    },
    /**
     * フォーム情報をユーザー情報に反映
     */
    getFormData() {
      this.userData.user.userId = this.formData.userId;
      this.userData.user.name = this.formData.name;
      this.userData.user.usertype = this.formData.usertype;
    },
    /**
     * キャンセルボタン押下
     */
    cancel(){

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true
      
      this.$router.push({
        name: "UserList",
      });
    },
    /**
     * 新規ユーザー追加
     */
    async makeUser() {
      this.$refs.form.validate(
        async function(result) {
          try {
            this.loader = this.$loading.show();

            // フォーム入力基本チェック
            if (!result) {
              console.log(`error`);
              this.showBottomToast("入力内容にエラーがあります。", "error");
              return;
            }

            if (this.formData.password != this.formData.confirmPassword) {
              //不一致はエラー
              this.showBottomToast(`パスワードが一致しません`, `error`);
              return;
            }

            // ユーザーIDの重複チェック
            let existUsers = await UserModel.getUserList()
            let sameUsers = existUsers.filter(function (value){
              value.user.userId == this.formData.userId
            }.bind(this))
            // 同じユーザーIDが存在するか
            if (sameUsers.length > 0) {
              this.showBottomToast(`ユーザーIDに使用できない文字が含まれています。`, `error`);
              return
            }

            // フォーム情報をユーザー情報に反映
            this.getFormData();

            //const apiName = 'apimapletfscloudappuser';
            const apiName = "konoikeconcreteapi";
            const path = "/user";

            const userdata = {
              response: true,
              body: {
                userName: this.userData.user.userId,
                password: this.formData.password,
                groupName: this.group,
                userType: this.userData.user.usertype,
                attributes: [
                  { Name: "email", Value: "dammy-email@dammy.aaa" },
                  { Name: "name", Value: this.userData.user.name },
                  { Name: "email_verified", Value: "true" },
                ]
              },
            };

            const response = await API.post(apiName, path, userdata);
            // console.log(`konoikeconcreteapi API Response: ${JSON.stringify(response, null, "\t")}`)
            const data = response.data;
            if (data.success != "") {
              
              switch ("" + data.code) {
                case "1":
                  throw 'ユーザー数の上限に達しています。';
                case "UsernameExistsException":
                  throw 'このユーザIDは使用できません。別のユーザーIDを指定して下さい。';
                default:
                  throw `ユーザー作成に失敗しました。`;
              }
            }

            // DBのskにはCognitoのSub属性値を使用する。
            // this.userData.sk = UserModel.createSk(data.sub);

            // await UserModel.addUser(this.userData);
          } catch (e) {
            // appLog.errLog("MakeUser.vue", this.$store.state.user.userId, `${e.message}`);
            this.errorLog("makeUser", this.parseErrorObject(e))
            this.showBottomToast(e, `error`);
            throw e;
          } finally {
            this.loader.hide();
            this.loader = null;
          }

          this.$router.push({
            name: "UserList",
          });
        }.bind(this));
    },

    /**
     * ユーザー削除ボタンのクリック
     */
    clickDeleteUser() {

      // モーダルを表示
      const _this = this;
      this.$nextTick(function() {
        this.modal = {
          isShow: true,
          title: "ユーザーの削除",
          message: `${_this.userData.user.name}を削除します。よろしいですか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            _this.deleteUser();

            // 閉じる
            _this.modal.isShow = false

          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {

            _this.modal.isShow = false

          },
        };
      });

    },

    /**
     * ユーザー情報を削除
     * API内でDB削除とCognito削除を行う
     */
    async deleteUser() {
      // let dbSucces = false
      try {
        this.loader = this.$loading.show();

        // 削除APIを実行
        const apiName = "konoikeconcreteapi";
        const path = "/user"
        let sub = this.userData.sk.split("#")[1]
        const userdata = {
          response: true,
          body: {
            sub: sub,
            userId: this.userData.user.userId
          },
        };

        const response = await API.del(apiName, path, userdata);
        const data = response.data;
        if (data.success != "") {  
          throw `ユーザー削除に失敗しました。`
        }
      } catch (e) {
        appLog.errLog("MakeUser.vue", this.$store.state.user.userId, `${e.message}`)
        this.showBottomToast(`削除時にエラーが発生しました`, `error`)
        throw e
      } finally {
        this.loader.hide()
        this.loader = null
      }
      this.$router.push({
        name: "UserList",
      });
    },
    /**
     * フォームのバリデーション(ユーザーID)
     */
    checkUserId(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(ユーザー名)
     */
    checkName(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 100, true, false, false, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(権限)
     */
    checkUsertype(rule, value, callback) {
      if (
        ValidateUtil.checkBase(value, callback, 0, true, false, false, false)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(現在のパスワード)
     */
    checkCurrentPassword(rule, value, callback) {
      let length;
      let isRequire;

      if (this.isEdit && this.allowChangePassword && this.changePassword) {
        length = 100;
        isRequire = true;
      } else {
        length = 0;
        isRequire = false;
      }

      if (
        ValidateUtil.checkBase(value, callback, length, isRequire, false, false, false)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(パスワード)
     */
    checkPassword(rule, value, callback) {
      let length;
      let isRequire;

      if (
        (this.isEdit && this.allowChangePassword && this.changePassword)
        || !this.isEdit
      ) {
        length = 100;
        isRequire = true;
      } else {
        length = 0;
        isRequire = false;
      }

      if (
        ValidateUtil.checkBase(value, callback, length, isRequire, false, false, false)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(パスワードの確認)
     */
    checkConfirmPassword(rule, value, callback) {
      let length;
      let isRequire;

      if (
        (this.isEdit && this.allowChangePassword && this.changePassword)
        || !this.isEdit
      ) {
        length = 100;
        isRequire = true;
      } else {
        length = 0;
        isRequire = false;
      }

      if (
        ValidateUtil.checkBase(value, callback, length, isRequire, false, false, false)
      ) {
        callback();
      }
    },
    /**
     * パスワードを変更するチェックボックス変更イベント
     */
    changeChangePassword() {
      // チェックボックスON時は処理を終了する
      if (this.changePassword) return;

      // チェック処理を実行する
      this.$refs.form.validate(async function(result) { }.bind(this));
    },
    /**
     * メニュークリック時の処理です。
     * @param {String} command クリックしたメニューのコマンド
     */
    handleCommand(command) {
      switch (command) {
        case "deleteUser":
          this.clickDeleteUser();
          break;
      }
    },

    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
});
</script>

<style></style>
