<template>
  <!-- ヘッダ1 24px -->
  <div class="d-flex" style="height:24px;">
    <div class="flex-fill align-self-center">
      <a style="font-size: 14px" @click="onClickProject"
        ><u>{{ projectName }}</u></a
      >
    </div>
    <el-button
      class="mr-1 align-self-center"
      type="result"
      size="small"
      icon="el-icon-tickets"
      @click="onClickShowResult"
      >実績表示</el-button
    >
    <a
      class="mr-1 align-self-center"
      style="font-size: 11px"
      @click="displayAsPC"
      v-if="!isPhone"
      ><u>PC表示へ</u></a
    >
  </div>

  <!-- ヘッダ2 48px -->
 <div
   :class="[
    'd-flex',
    'flex-sm-wrap',
    isZando ? 'zando' : 'app-header',
  ]"
  style="height: 48px;"
  >
    <div class="ml-1 align-self-center "
     :class="isZando ? 'zando-datePicker' : 'datePicker'">
      <el-date-picker
        class="ml-0"
        v-model="dateString"
        type="date"
        :clearable="false"
        size="medium"
        format="MM/DD"
        :disabled-date="disabledDate"
        placeholder=""
        @change="onChangeDate($event)"
      >
      </el-date-picker>
    </div>

  <div class="align-self-center" style="margin-left: -6px" v-if="isNowTime">
    <div
      style="width: 70px; font-size: 14px; color: white; font-weight: bold;"
    >
      {{ timeString }} 時点<br />
      ({{ refreshIntervalSecond / 60 }}分間隔)
    </div>
  </div>

    <div
      class="ml-1 mr-1 flex-fill d-flex align-self-center"
      :class="isZando ? 'zando-timeLineModeAreaList' : 'timeLineModeAreaList'"
    >
      <el-select
        class="flex-fill"
        v-model="headerPouringPosition"
        loading-text="お待ち下さい"
        placeholder="予定数量未登録"
        @change="onChangePouringPosition"
      >
        <el-option
          v-for="item in signagePouringList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>

    <div class="align-self-center" v-if="isNowTime && !refreshdisabled">
      <el-button
        type="primary"
        size="small"
        style="font-weight:bold;"
        circle
        icon="fas fa-redo fa-lg"
        @click="onRefreshLocation"
      ></el-button>
    </div>
    <div class="align-self-center" v-if="isNowTime && refreshdisabled">
      <el-button
        type="primary"
        size="small"
        style="font-weight:bold;"
        circle
        icon="fas fa-redo fa-lg"
        @click="onRefreshLocation"
        disabled
      ></el-button>
    </div>
  </div>

  <!-- 地図 -->
  <div class="my-box timeline-map flex-fill" :style="mapInfoHeight">
    <!-- 地図ヘッダ -->
    <div class="app-subheader d-flex">
      <div class="d-flex align-items-center my-0">
        <div class="ml-1 mt-0">
          <el-tooltip
            class="item"
            effect="dark"
            content="運行情報の表示・非表示"
            placement="bottom"
          >
            <el-button
              size="small"
              class="mr-3"
              style="border:0;"
              circle
              @click="showTimeLine"
            >
              <i
                class="fas"
                :class="[isTimelineShow ? 'fa-expand' : 'fa-compress']"
                style="background-color:white;border:0;"
              ></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <div
        class="d-flex flex-fill justify-content-center align-items-center mt-0"
      >
        <div v-for="summary in roundSummarys" :key="summary.label">
          <img
            :src="summary.img"
            class="bg-white avatar avatar-sm small mr-0"
            style="height: 20px; width: 20px;"
          />
          <span class="mr-2" style="color:black; font-size: 12px;">
            {{ summary.label }}：{{ summary.count }}台
          </span>
        </div>
      </div>
    </div>

    <!-- 図面表示 -->
    <time-line-map
      ref="map"
      :style="mapHeight"
      @updateSummarys="updateSummarys"
    ></time-line-map>
  </div>

  <!-- 運行情報リスト -->
  <div
    class="overflow-auto"
    :style="
      'height: calc(100vh - ' +
        376 +
        'px);height: calc(100dvh - ' +
        376 +
        'px);'
    "
    v-if="isTimelineShow"
  >
    <el-table
      ref="timeLineTable"
      row-key="timelineId"
      class="table-timeline"
      header-row-class-name=""
      :data="timeLineData"
      :span-method="arraySpanMethod"
      :cell-style="{
        padding: '0px 0px 0px 0px',
        height: '14px',
        background: '#FFF',
        color: '#000',
      }"
      :cell-class-name="tableCellClassName"
      :header-cell-class-name="headerCellClassName"
      :header-cell-style="headerCellStyle"
      border
      size="mini"
      height="100%"
      style="width: 100%"
      empty-text="運行情報がありません"
      highlight-current-row
      @current-change="handleTimeLineCurrentChange"
    >
    <!-- 生コン用 -->
      <el-table-column
        v-if= !isZando
        prop="col1"
        label="工場発着"
        min-width="60px"
        align="center"
        :resizable="false"
      >
        <template #default="scope">
          <!-- class="align-items-center row" -->
          <div
            v-if="scope.row.type === 'vehicle'"
            :class="vehicleRowStyle"
          >
            <div
              v-if="!selectedVehicleId"
            >
                <div class="driveInfo">
                  <font style="font-size:min(12px);color:blue">
                    車両番号：{{ scope.row.vehicleName }}
                  </font>
                </div>  
                <div class="d-flex align-items-center">
                  <div class="driveIconBox">
                    <img :src="scope.row.img" class="bg-white avatar-sm" />
                    <p style="font-size:min(16px)" class="stroke text-nowrap">
                      {{ scope.row.order }}
                    </p>
                  </div>
                  <font style="font-size:min(18px);color:black;font-weight:bold">
                    台目 累計{{ scope.row.totalAmount }} ㎥
                  </font>
                  <div v-if="scope.row.dispPouringStartBtn && !isPhone" class="ml-9">
                    <el-button
                      class="pouringStartButton"
                      size="mini"
                      @click="onClickPouringStart(scope.row)"
                      ><span class="font-weight-bold">打設開始</span></el-button
                    >                    
                  </div>
                  <div v-if="scope.row.dispPouringEndBtn && !isPhone" class="ml-9">
                    <el-button
                      class="pouringEndButton"
                      size="mini"
                      @click="onClickPouringEnd(scope.row)"
                      ><span class="font-weight-bold">打設終了</span></el-button
                    >                    
                  </div>
                </div>               
            </div>
          </div>
          <span class="" v-else>{{ scope.row.col1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if= !isZando
        prop="col2"
        label="現場発着"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= !isZando
        prop="col3"
        label="所要時間"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= !isZando
        prop="col4"
        label="打設開始"
        min-width="60px"
        align="center"
        :resizable="false"
      >
        <template #default="scope">
          <div
            v-if="scope.row.type === 'timeline1'"
            class="justify-content-center align-items-center row"
          >
            <div v-if="scope.row.pouringStartUser === 'system'">
              <b
                style="font-size:min(18px);color:red;background-color:yellow"
                key="pouringStartTIme"
              >
                {{ scope.row.col4 }}
              </b>
            </div>
            <span
              class="justify-content-center align-items-center row"
              key="pouringStartTIme"
              v-else
              >{{ scope.row.col4 }}</span
            >
          </div>
          <span
            class="justify-content-center align-items-center row"
            key="pouringStartTIme"
            v-else
            >{{ scope.row.col4 }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if= !isZando
        prop="col5"
        label="打設終了"
        min-width="60px"
        align="center"
        :resizable="false"
      >
        <template #default="scope">
          <div
            v-if="scope.row.type === 'timeline1'"
            class="justify-content-center align-items-center row"
          >
            <div v-if="scope.row.pouringEndUser === 'system'">
              <b style="font-size:min(18px);color:red;background-color:yellow">
                {{ scope.row.col5 }}
              </b>
            </div>
            <span
              class="justify-content-center align-items-center row"
              v-else
              >{{ scope.row.col5 }}</span
            >
          </div>
          <span class="justify-content-center align-items-center row" v-else>{{
            scope.row.col5
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if= !isZando
        prop="col6"
        label="打設/経過時間"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= !isZando
        prop="col7"
        label="工区"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= !isZando
        prop="col8"
        label="打設箇所"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= !isZando
        prop="col9"
        label="積載物"
        min-width="60px"
        align="center"
        :resizable="false"
      />

      <!-- 残土用 -->
      <el-table-column
        v-if= isZando
        prop="col1"
        label="現場発着"
        min-width="60px"
        align="center"
        :resizable="false"
      >
        <template #default="scope">
          <!-- class="align-items-center row" -->
          <div
            v-if="scope.row.type === 'vehicle'"
            :class="vehicleRowStyle"
          >
            <div
              v-if="!selectedVehicleId"
            >
                <div class="driveInfo">
                  <font style="font-size:min(12px);color:blue">
                    車両番号：{{ scope.row.vehicleName }}
                  </font>
                </div>  
                <div class="d-flex align-items-center">
                  <div class="driveIconBox">
                    <img :src="scope.row.img" class="bg-white avatar-sm" />
                    <p style="font-size:min(12px)" class="stroke text-nowrap">
                      {{ scope.row.numberPlate == null || scope.row.numberPlate == "" ? scope.row.vehicleName : scope.row.numberPlate }}
                    </p>
                  </div>
                  <font style="font-size:min(18px);color:black;font-weight:bold">
                    累計{{ scope.row.totalAmount }} ㎥
                  </font>
                 <div v-if="scope.row.cargoStartBtn && isZando && !isPhone" class="ml-9">
                      <el-button
                        class="cargoStartButton"
                        size="mini"
                        @click="onClickCargoStart(scope.row)"
                        ><span class="font-weight-bold">積込開始</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.cargoEndBtn && isZando && !isPhone" class="ml-9">
                      <el-button
                        class="cargoEndButton"
                        size="mini"
                        @click="onClickCargoEnd(scope.row)"
                        ><span class="font-weight-bold">積込終了</span></el-button
                      >                    
                    </div>
                    <div v-if="scope.row.unloadingTimeBtn && isZando && !isPhone" class="ml-9">
                      <el-button
                        class="unloadingTimeButton"
                        size="mini"
                        @click="onClickCargoUnloadingTime(scope.row)"
                        ><span class="font-weight-bold">荷降開始</span></el-button
                      >                    
                    </div>
                </div>               
            </div>
          </div>
          <span class="" v-else>{{ scope.row.col1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if= isZando
        prop="col2"
        label="土捨場発着"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= isZando
        prop="col3"
        label="所要時間"
        min-width="60px"
        align="center"
        :resizable="false"
      />
      <el-table-column
        v-if= isZando
        prop="col4"
        label="積込開始"
        min-width="60px"
        align="center"
        :resizable="false"
      >
      <template #default="scope">
          <div
            v-if="scope.row.type === 'timeline1'"
            class="justify-content-center align-items-center row"
          >
            <div v-if="scope.row.pouringStartUser === 'system'">
              <b
                style="font-size:min(18px);color:red;background-color:yellow"
                key="pouringStartTIme"
              >
                {{ scope.row.col4 }}
              </b>
              </div>
              <span
                class="justify-content-center align-items-center row"
                key="pouringStartTIme"
                v-else
                >{{ scope.row.col4 }}</span
              >
              </div>
              <span
                class="justify-content-center align-items-center row"
                key="pouringStartTIme"
                v-else
                >{{ scope.row.col4 }}</span
              >
            </template>
          </el-table-column>      
          <el-table-column
            v-if= isZando
            prop="col5"
            label="積込終了"
            min-width="60px"
            align="center"
            :resizable="false"
          >
          <template #default="scope">
              <div
                v-if="scope.row.type === 'timeline1'"
                class="justify-content-center align-items-center row"
              >
                <div v-if="scope.row.pouringEndUser === 'system'">
                  <b
                    style="font-size:min(18px);color:red;background-color:yellow"
                  >
                    {{ scope.row.col5 }}
                  </b>
                </div>
                <span
                  class="justify-content-center align-items-center row"
                  v-else
                  >{{ scope.row.col5 }}</span
                >
              </div>
              <span
                class="justify-content-center align-items-center row"
                v-else
                >{{ scope.row.col5 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if= isZando
            prop="col6"
            label="積込時間"
            min-width="60px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= isZando
            prop="co20"
            label="荷降時間"
            min-width="60px"
            align="center"
            :resizable="false"
          ><template #default="scope">
            <div
              v-if="scope.row.type === 'timeline1'"
              class="justify-content-center align-items-center row"
            >
              <div v-if="scope.row.unloadingUser === 'system'">
                <b
                  style="font-size:min(18px);color:red;background-color:yellow"
                  key="unloadingTime"
                >
                {{ scope.row.co20 }}
                </b>
              </div>
            <span
              class="justify-content-center align-items-center row"
              key="unloadingTime"
              v-else
              > {{ scope.row.co20 }}</span>
            </div>
          </template>

          </el-table-column>
          <el-table-column
            v-if= isZando
            prop="col7"
            label="土捨場"
            min-width="60px"
            align="center"
            :resizable="false"
          />
          <el-table-column
            v-if= isZando
            prop="col8"
            label="岩種"
            min-width="60px"
            align="center"
            :resizable="false"
          />



    </el-table>
  </div>

  <!-- 生コン編集用のモーダル(運行情報) -->
  <modal v-model:show="modals.editTimeLineDlg" size="lg" body-classes="p-0">
    <template v-slot:header>
      <h6 class="modal-title">
        [{{ editTimeLineVehicleName }}] 運行履歴の編集
      </h6>
    </template>

  <el-tabs
    v-if= !isZando
    type="card"
    v-model="acctivePane"
    @tab-click="tabClick"
  >
    <!----------------------------------------------------
      1つめのタブ（受入検査項目）
      ---------------------------------------------------->
    <el-tab-pane v-if= !isZando name="0">
      <template #label>
        <span class="font-size-middle font-weight-bold">運行履歴</span>
      </template>

    <card
      v-if= !isZando
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-2 py-lg-2"
      class="border-0 mb-0"
    >
      <div class="d-flex justify-content-center">
        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input
              v-if= !isZando
              name="endAreaId"
              label="工区"
              input-classes="form-control-sm"
            >
              <el-select
                placeholder=""
                class="timeline-modal-input"
                size="mini"
                v-model="editTimeLineData.drive.endAreaId"
                readonly="true"
                @change="onChangeAreaDrive(editTimeLineData.drive.endAreaId)"
              >
                <el-option
                  v-for="item in listDriveArea"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="factoryStartTime" label="工場出発時刻">
              <el-time-picker
                ref="timePickFactoryStartTime"
                class="w-100"
                v-model="editTimeLineData.drive.factoryStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringStartTime" label="打設開始時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="siteStartTime" label="現場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>

        <div class="flex-column">

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringPosition" label="打設箇所">
              <el-select
                v-model="editTimeLineData.drive.pouringPosition"
                reserve-keyword
                placeholder="選択してください。"
              >
                <el-option
                  v-for="item in listDrivePouringPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="siteEndTime" label="現場到着時刻">
              <el-time-picker
                ref="timePickSiteEndTime"
                @focus="onFocusTimePickSiteEndTime"
                class="w-100"
                v-model="editTimeLineData.drive.siteEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="pouringEndTime" label="打設終了時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= !isZando name="factoryEndTime" label="工場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>

        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input v-if= !isZando name="carryType" label="積載物">
              <el-select
                placeholder=""
                v-model="editTimeLineData.drive.carryType"
                readonly="true"
              >
                <el-option
                  v-for="item in carryTypes"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

        </div>

      </div>
    </card>

    </el-tab-pane>

    <el-tab-pane v-if= !isZando name="1">
      <template #label>
        <span class="font-size-middle font-weight-bold">納品書写真</span>
      </template>

        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0"
        >
          <!-- 納入伝票がないとき-->
          <div v-if="thumbDeliveryPhoto == null">
            <div style="margin-left:50px;margin-top:10px">
              <h3>該当する納入伝票は存在しません。</h3>
            </div>
          </div>

          <!-- 納入伝票情報表示-->
          <div v-if="thumbDeliveryPhoto != null" class="d-flex justify-content-start">
            <div class="flex-column" style="margin-left:20px">
              <small>納品書写真</small>
              <div>
                <el-image style="width: 160px; height: 160px" :fit="`cover`" :src="thumbDeliveryPhoto" :key="thumbDeliveryPhoto" :preview-src-list="[thumbDeliveryPhoto]" />
              </div>
            </div>

            <div class="flex-column" style="margin-left:60px;margin-top:20px">
              <base-input name="factoryEndTime" label="出発時刻">
                <el-time-picker
                  class="w-100"
                  v-model="editDeliveryStartTime"
                  placeholder=""
                  :clearable="false"
                  size="medium"
                  format="HH:mm"
                >
                </el-time-picker>
              </base-input>

              <base-input name="factoryEndTime" label="到着時刻">
                <el-time-picker
                  class="w-100"
                  v-model="editDeliveryEndTime"
                  placeholder=""
                  :clearable="false"
                  size="medium"
                  format="HH:mm"
                >
                </el-time-picker>
              </base-input>
            </div>         
          </div>

        </card>

    </el-tab-pane>
  </el-tabs>
   <!----------------------------------------------------
    残土（運行履歴の編集）
    ---------------------------------------------------->
   <card
      v-if= isZando
      type="secondary"
      class="zando-card-body"
    >
    <div class="d-flex justify-content-center">
        <div class="flex-column">
          
          <div class="col-md-12">
            <base-input
              v-if= isZando
              name="endAreaId"
              label="土捨場"
              input-classes="form-control-sm"
            >
              <el-select
                placeholder=""
                class="timeline-modal-input"
                size="mini"
                v-model="editTimeLineData.drive.endAreaId"
                readonly="true"
                @change="onChangeAreaDrive(editTimeLineData.drive.endAreaId)"
              >
                <el-option
                  v-for="item in listDriveArea"
                  class="select-primary"
                  :value="item.id"
                  :label="item.name"
                  :key="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input v-if= isZando name="pouringStartTime " label="積込開始時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringStartTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
          <div class="col-md-12">
            <base-input v-if= isZando name="factoryStartTime " label="現場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryStartTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
          <div class="col-md-12">
            <base-input v-if= isZando name="siteStartTime " label="土捨場出発時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteStartTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>
        
        </div>
      
      <div class="flex-column">

        <div class="col-md-12">
            <base-input v-if= isZando name="pouringPosition" label="岩種">
              <el-select
                v-model="editTimeLineData.drive.pouringPosition"
                reserve-keyword
                placeholder="選択してください。"
              >
                <el-option
                  v-for="item in listDrivePouringPosition"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="pouringEndTime " label="積込終了時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.pouringEndTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="siteEndTime " label="土捨場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.siteEndTime "
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
        </div>
        
        <div class="col-md-12">
            <base-input v-if= isZando name="factoryEndTime" label="現場到着時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.factoryEndTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

      </div>
      <div class="flex-column" style="margin-top: 150px;">
          
          <div class="col-md-12">
            <base-input v-if= isZando name="unloadingTime" label="荷降時刻">
              <el-time-picker
                class="w-100"
                v-model="editTimeLineData.drive.unloadingTime"
                placeholder=""
                :clearable="false"
                size="medium"
                format="HH:mm"
              >
              </el-time-picker>
            </base-input>
          </div>

        </div>
      </div>
   </card>

    <!-- キャンセル、更新ボタン -->
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="onClickCancelUpdateTimeline"
          >キャンセル</base-button
        >
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-5">
         <base-button v-if= !isZando type="primary" block @click="onClickUpdateTimeline"
          >更新</base-button
        >
        <base-button v-if= isZando  type="success" block @click="onClickUpdateTimeline"
          >更新</base-button
        >
      </div>
    </div>
  </modal>

  <!-- 処理確認モーダル -->
  <modal v-model:show="modals.confirmDlg">
    <template v-slot:header>
        <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
    </template>
    <div class="mb-5">
      {{confirmMessage}}
    </div>
    <div class="row mt-2 mb-2 ml-2 mr-2">
      <div class="col-sm-5">
        <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
      </div>
      <div class="col-sm-2">
      </div>
      <div class="col-sm-5">
        <base-button type="primary" block @click="onClickConfirmOK">設定</base-button>
      </div>
    </div>
  </modal>

</template>

<style scoped>
.form-group {
  margin-bottom: 0;
}

.app-header {
  height: auto;
  width: 100%;
  background-color: #154ca3;
}
.zando {
  height: auto;
  width: 100%;
  background-color: #5A8D1D;
}

.el-button--primary {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
  padding-left: 4px;
  padding-right: 4px;
}
.zando .el-button--primary {
  color: #fff;
  background-color: #2A4A03;
  border-color: #3c6904;
  /* // padding-left: 4px;
  // padding-right: 4px; */
}

.el-button--result {
  min-height: 18px;
  color: #fff;
  background-color: #1b7301;
  border-color: #4e9c00;
  padding: 2px 2px;
  font-size: 11px;
}

:deep .el-date-editor.el-input {
  width: 100px;
}

.date-picker-ex {
  color: #fff;
  background-color: #154ca3;
  border-color: #003c9c;
}

.el-button--small.is-circle {
  margin-right: 2px;
  padding: 8px;
}

:deep .el-input__suffix {
  right: 0px;
}

.active {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
}

.active:hover {
  color: white;
}

.app-subheader {
  height: auto;
  width: 100%;
  background-color: #fff;
}

:deep .el-table__header-wrapper thead th .cell {
  min-height: 18px !important;
  font-size: 11px;
}

:deep .el-table__header-wrapper thead th {
  padding: 0px 0px 0px 5px !important;
}

:deep .el-table--border .el-table__cell:first-child .cell {
  padding-left: 0px;
}

.zando-card-body{
  background-color: #f3ffe8 !important;
}

.pouringStartButton {
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.cargoStartButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.cargoEndButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.unloadingTimeButton{
  color: #fff;
  background-color: #146F00;
  border: 1px solid #5dee3c;
  font-size: 0.85rem;
}

.pouringEndButton {
  color: #fff;
  background-color: #f5127c;
  border: 1px solid #ec4a96;
  font-size: 0.85rem;
}

.driveInfo {
  position: relative;
  left: 5px;
  text-align: left;
}

.driveIconBox {
  position: relative;
}

.driveIconBox p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  font-size: 16 !important;
  font-weight: 1000;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: grey;
}

.fixed-col-center {
  position: sticky;
  left: 200 !important;
}
</style>
<style lang="scss" scoped>
.signageModeAreaList ::v-deep .el-input__inner {
  font-size: 2rem !important;
  height: 2.5rem !important;
  width: 640px !important;
}

.timeLineModeAreaList ::v-deep .el-input__inner {
  height: 36px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: #fff;
  background-color: #154ca3 !important;
  border-color: #fff;
  font-size: 14pt;
  font-weight: 600;
  height: 36px !important;
  padding-right: 0px !important;
}
.zando-timeLineModeAreaList ::v-deep .el-input__inner {
  height: 36px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: #fff;
  background-color: #5A8D1D !important;
  border-color: #fff;
  font-size: 14pt;
  font-weight: 600;
  height: 36px !important;
  padding-right: 0px !important;
}

.datePicker ::v-deep .el-input__inner {
  height: 36px !important;
  padding-right: 2px !important;
  color: #fff;
  background-color: #154ca3 !important;
  border-color: #fff;
  font-size: 14pt;
  font-weight: 600;
  height: 36px !important;
  padding-right: 0px !important;
}

.zando-datePicker ::v-deep .el-input__inner {
  height: 36px !important;
  padding-right: 2px !important;
  color: #fff;
  background-color: #5A8D1D !important;
  border-color: #fff;
  font-size: 14pt;
  font-weight: 600;
  height: 36px !important;
  padding-right: 0px !important;
}
.timeLineModeAreaList ::v-deep .el-input__icon {
  color: #fff;
  font-size: 14pt;
  font-weight: bold;
}

.datePicker ::v-deep .el-input__icon {
  color: #fff;
  font-size: 14pt;
  font-weight: bold;
}
.zando-datePicker ::v-deep .el-input__icon {
  color: #fff;
  font-size: 14pt;
  font-weight: bold;
}

.timeLineModeAreaList ::v-deep .el-input--prefix .el-input__inner {
  width: calc(100% - 13px) !important;
}

.datePicker ::v-deep .el-input--prefix .el-input__inner {
  width: calc(100% - 13px) !important;
}
.zando-datePicker ::v-deep .el-input--prefix .el-input__inner {
  width: calc(100% - 13px) !important;
}
.table-header-latest {
  background-color: #b9e3f9 !important;
  color: #5676d9 !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.table-header-latest-zando {
  background-color: #f3ffe8 !important;
  color: #006400 !important;
  font-weight: 600 !important;
  text-align: center !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>

<script>
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
import TimeLineMap from "@/appViews/Projects/TimeLineMap.vue";

const stringify = require("csv-stringify/lib/sync");
import { useToast } from "vue-toastification";
import DriveModel from "@/appModel/Drive/DriveModel";
import baseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import PlanModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import Modal from "@/components/Modal";

import MixinTimeLine from "./MixinTimeLine.js";
import UserInfo from "@/appUtils/UserInfo.js";
import TenantStorage from "@/appUtils/TenantStorage"
import path from 'path'
import PorjectModel from "@/appModel/project/ProjectModel";
import DeliveryLimitModel from "@/appModel/Temperature/DeliveryLimitModel";
import RouteModel from "@/appModel/Route/RouteModel";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {
    TimeLineMap,
    Modal,
  },

  mixins: [MixinTimeLine, ValidSession, UseApps, Logger],

  data() {
    return {
      projectType: "0", // 0:生コン、1：残
      timeLineAllData: {},
      timeLineLatests: [],
      timeLineVehicles: [],
      selectedVehicleId: "",
      selectedVehicleData: [
        {
          img: "./img/concrete/ic_circle_yellow.png",
          order: "1",
          driveNumber: "2",
          maxCapacity: "5.0",
          vehicleName: "A-1234",
          vehicleId: "hogehoge",
          totalAmount: "24.0",          
        },
      ],
      projectId: "",
      projectName: "",
      endAreaId: "",
      pouringPosition: "",
      headerPouringPosition: "",
      dateString: DateUtil.dateStringBase(new Date(), "YYYY/MM/DD"),
      refreshIntervalSecond: 60,
      // 1:工場出発順、2:車両番号順
      orderType: 1,
      isNowTime: true,
      // sample
      title: "",
      modals: {
        editTimeLineDlg: false,
        confirmDlg: false,
      },
      confirmTitle: '',
      confirmMessage: '',      
      confirmDlgMode: 0, // 0：運行先一括設定、1：打設開始時刻設定、2：打設終了時刻設定、3:積込開始時刻設定、4：積込終了時刻設定、５：荷降時刻設定
      // // 到着地点（現場・工区）のリスト
      // listArea: [],
      // // 出発地点（工場）＋ 到着地点（現場・工区）のリスト
      // listBaseArea: [],
      // 運行情報編集用（工区、打設箇所）リスト
      listDriveAreaAndPouring: [],
      listDriveArea: [],
      listDrivePouringPosition: [],
      editTimeLineVehicleName: ``,
      editTimeLineData: {
        drive: {
          factoryStartTime: "",
          siteEndTime: "",
          pouringStartTime: "",
          pouringEndTime: "",
          siteStartTime: "",
          factoryEndTime: "",
          endAreaId: "",
          pouringPosition: "",
          carryType: 1,
          unloadingTime: "",
        },
      },
      keepCurrentRow: null,
      timer: null,
      editTimeLineDataBefore: this.editTimeLineData,
      carryTypes: DriveModel.getCarryTypeList(),
      userStore: null,
      // 車両ID、地図アイコン注記用表示順(1~)
      vehicleMapTextOrders: [
        // {
        //   vehicleId: 'hogehoge',
        //   iconTextOrder: 1,
        // }
      ],
      driveList: [],
      deliveryList: [],
      // planId: "",
      // planList: [],
      // volumeList: [],mixinに書いてあるため
      pouringList: [],
      selectedVolumeId: "",
      datePickerOptions: {
        disabledDate: this.disabledDate,
        disabledDateForQRCode: this.disabledDateForQRCode,
      },
      // LoadingFlag: false,
      beforeDriveLastTime: "", // 運行情報変更モーダル表示時、編集対象の運行情報の１つ前の運行情報内で最終時間保持用
      afterDriveFirstTime: "", // 運行情報変更モーダル表示時、編集対象の運行情報の１つ後の運行情報内で最も最初の時間保持用
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      roundSummarys: [
        {
          label: `往路`,
          img: "./img/concrete/ic_circle_yellow.png",
          count: 0,
        },
        {
          label: `復路`,
          img: "./img/concrete/ic_circle_blue.png",
          count: 0,
        },
      ],
      timeString: DateUtil.dateStringBase(new Date(), "HH:mm"),
      refreshIntervalId: undefined,
      /**
       * 位置情報更新間隔(分)
       * ローカルストレージに利用者単位で設定します。(※初期開発は1分固定予定)
       */
      updateIntervalMinutes: 1,
      refreshdisabled: false,
      isTimelineShow: true,
      mapInfoHeight: "max-height: 304px;",
      mapHeight: "height: 276px;",
      isPhone: false,
      vehicleRowStyle: "pl-5",
      signagePouringList: [],
      availableDays: {},
      thumbDeliveryPhoto: null,
      editDeliveryStartTime: "",
      editDeliveryEndTime: "",
      deliveryLimitList: [],
      remainingTime: 30,
      routeIdList: [],
      pouringPositionAreaName: ""
    };
  },

  computed: {
    vuename() {
      return "MobileTimeLine.vue";
    },

    timeLineData() {
      if (this.selectedVehicleId) {
        return this.selectedVehicleData;
      } else {
        return this.timeLineLatests;
      }
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
    this.infoLog(
      `Start Vuex：projectid(${this.$store.state.timeline.projectid})、projectname(${this.$store.state.timeline.projectname})、datestring(${this.$store.state.timeline.datestring})`
    );
  },

  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前

    // console.log("beforeMount")

    // 地図の更新間隔設定
    this.refreshIntervalSecond = this.getMapInterval();

    // // 呼び出し元で指定されたプロジェクトID、日付を取得
    // this.projectId = this.$store.state.timeline.projectid;
    // const storeDateString = this.$store.state.timeline.datestring;
    // console.log(`store指定日付：${storeDateString}`);

    // if (!storeDateString) {
    //   this.dateString = storeDateString;
    // }
  },

  async mounted() {
    // インスタンスが DOM 要素にマウントされた後

    // appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    await this.init();
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
    // console.log(`updated`);
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.editTimeLineDlg = false;

    this.infoLog(`End`);
  },

  setup() {},

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {

      // 処理中
      let loader = this.showLoader();

      try {
        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        }

        // 呼び出し元で指定されたプロジェクトID、プロジェクト名、日付、選択中のVolume IDを取得
        this.projectId = this.$store.state.timeline.projectid;
        this.projectName = this.$store.state.timeline.projectname;
        const storeDateString = this.$store.state.timeline.datestring;
        //console.log(`☆store指定日付：${storeDateString}`);

        if (this.$store.state.timeline.volumeid != undefined) {
          this.selectedVolumeId = this.$store.state.timeline.volumeid;
        }

        // タイトルをセット
        this.setNavInfo();

        // ユーザー情報の取得
        this.userStore = await this.$store.state.user;
        // console.log(`ユーザー情報：${JSON.stringify(this.userStore, null, "\t")}`);

        if (window.innerWidth < 768) {
          this.isPhone = true;
          this.vehicleRowStyle = "pl-5";
        } else {
          this.vehicleRowStyle = "justify-content-center";
        }

        // 拠点一覧取得(判定用に保持)
        this.listBaseArea = await baseAreaModel.getBaseAreaList(this.projectId);

        // 子Map初期化
        this.$refs.map.projectId = this.projectId;
        await this.$refs.map.init(this.listBaseArea);

        // モーダル画面のセレクトボックス設定
        this.getAreaList();

        if (storeDateString) {
          this.dateString = storeDateString;

          // 日付変更イベントを発火
          await this.onChangeDate(new Date(this.dateString));
        }

        // 温度毎の運搬時間（分）の設定リスト取得
        this.deliveryLimitList = await DeliveryLimitModel.getDeliveryLimitList();

        // 残時間（閾値）取得
        this.remainingTime = await this.getProjectRemainingTime();        
        //連打防止
        this.repeatedHitsFlg = false;
      } finally {
        this.hideLoader(loader);
      }
    },

    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // row = 表示するデータが入っている（行番号ではない）値で判定するならこれを使う
      // column = カラムのプロパティが入っている

      if (row.type === "vehicle") {
        // 車両情報
        //return [1, 9]
        return {
          rowspan: 1,
          colspan: 9,
        };
      } else if (row.type === "timeline1") {
        if (
          column.property !== "col1" &&
          column.property !== "col2" &&
          column.property !== "col3"
        ) {
          // console.log(`arraySpanMethod ${column.property} 縦2スパン`)
          return [2, 1];
        } else {
          // console.log(`arraySpanMethod ${column.property} スパンなし`)
          return;
        }
      } else {
        return;
      }
    },

    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      // console.log(row)

      if (row.type === "vehicle") {
        // 車両情報
        // 基本スタイル
        return `{padding: '4px', height: '24px', background: '#FFF', color: '#000'}`;
      } else if (row.type === "timeline1") {
        if (
          column.property === "col4" &&
          column.property !== "col5" &&
          column.property !== "col6" &&
          column.property !== "co20"
        ) {
          if (column.property === "col4") {
            if (row.pouringStartUser === "system") {
              return ".warning-cell";
            }
          } else if (column.property === "col5") {
            if (row.pouringEndUser === "system") {
              return ".warning-cell";
            }
          } else if (column.property === "co20"){
            if (row.unloadingUser === "system"){
              return ".warning-cell";
            }
          }
        }

        // 基本スタイル
        return `{padding: '4px 0px 4px 0px', height: '24px', background: '#FFF', color: '#000'}`;
      }
    },

    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      if (this.selectedVehicleId) {
        return "table-header-vehicles";
      } else {
        //生コンのとき
        if (this.projectType == "0"){
          return "table-header-latest";
        //残土のとき
        } else if (this.projectType == "1"){
          return "table-header-latest-zando";
        }    
      }
    },

    headerCellStyle() {
      return "text-align:center";
    },

    highLight(text, user) {
      // console.log(`value:${text} user:${user}`)
      if (user !== "system") return text;

      // システム（ジオフェンスが登録していたら強調表示
      const re = new RegExp(text, "ig");
      return text.replace(re, function(text) {
        return (
          '<span style="background-color:yellow;font-weight:bold">' +
          text +
          "</span>"
        );
      });
    },

    onClickProject() {
      this.$router.push({
        path: `/projects`,
      });
    },

    displayAsPC() {
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },

    /**
     * 位置情報更新要求時の処理です。
     */
    async onRefreshLocation() {
      // リフレッシュボタンを非活性にする
      this.refreshdisabled = true;
      this.timer = setTimeout(this.freeReflesh, 3000);

      // console.log("onRefreshLocation")
      if (!this.$refs.map) {
        return;
      }

      if (!this.$refs.map.refreshLocation) {
        return;
      }

      const skMap = `${this.projectId}#${DateUtil.getFormatString(
        this.dateString,
        "YYYYMMDD"
      )}`;
      const sk = `${skMap}#${this.endAreaId}`;

      // 先に車両名：工場出発順のリストを取得し、地図側にも渡しておく
      // 更にメソッド内部で運行情報リストを取得、保持
      this.vehicleMapTextOrders = {};
      // this.vehicleMapTextOrders = await this.getVehicleMapTextOrders(sk);
      this.vehicleMapTextOrders = await this.getVehicleMapTextOrders(sk, this.selectedVolumeId);
      this.$refs.map.setVehicleMapTextOrders(this.vehicleMapTextOrders);

      // 高速化のためawaitで待たない
      this.$refs.map.refreshLocation(skMap, this.orderType);

      // 高速化のためawaitで待たない
      // 運行表示テーブルの更新
      this.refreshTimeLine(sk);

      this.getDriveListForCalenderDisabled();

      // ヘッダーの時刻を更新
      this.timeString = DateUtil.dateStringBase(new Date(), "HH:mm");
    },

    /**
     * 運行情報がある日のみカレンダーの日付が有効になるようにします。
     */
    async getDriveListForCalenderDisabled() {
      this.availableDays = {}
      const projectId = this.$store.state.timeline.projectid
      let listdata = await DriveModel.getPkSkOnlyList(projectId, "")
      for (let data of listdata) {
        let skElements = data.sk.split("#")
        this.availableDays[skElements[2]] = true
      }

      let now = DateUtil.dateStringBase(new Date(), "YYYYMMDD")
      // 当日は選択可能とするためセットしておく
      this.availableDays[now] = true
    },
    
    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDate(time) {
      let ymd = DateUtil.dateStringBase(time, "YYYYMMDD")
      // 当日以降の日付は選択不可とする
      // console.log(`☆time: ${ymd}`)
      
      if (!this.availableDays[ymd]) {
        return true
      }
      return time.getTime() > Date.now();
    },

    /**
     * リフレッシュボタンを活性化する
     */
    freeReflesh() {
      clearTimeout(this.timer);
      this.refreshdisabled = false;
    },

    /**
     * 運行表示テーブルを更新します。
     * @param {String} sk 取得キー(projectId#yyyyMMDD)
     */
    async refreshTimeLine(sk) {
      
      try {
      // 運搬時間取得
        let deliveryTime = await this.getDeliveryTime();

        // DBからテーブル情報を取得（model内でテーブル用に成型済み）
        const newTimeLine = await DriveModel.getDriveTableList(
          sk,
          this.orderType,
          this.driveList,
          this.vehicleMapTextOrders,
          this.pouringPosition,
          this.listBaseArea,
          this.deliveryList,
          deliveryTime,
          this.remainingTime
        );
        // console.log(`timeLineList: ${JSON.stringify(newTimeLine, null, "\t")}`);

        // 前回と一致していれば更新しない
        if (JSON.stringify(this.timeLineAllData) == JSON.stringify(newTimeLine)) {
          return;
        }

        // console.log(`timelineテーブルの更新: ${JSON.stringify(newTimeLine, null, "\t")}`);

        // 最新表示用
        this.timeLineLatests = newTimeLine.latestVehicleDrives;

        // 車両別全運行表示用
        this.timeLineVehicles = newTimeLine.vehicleAllDrives;

        // 車両別全運行表示時
        if (this.selectedVehicleId) {
          const id = this.selectedVehicleId;
          // いったんクリアし、新しいリストに同じ車両IDがあれば設定
          this.selectedVehicleId = "";
          const item = this.timeLineVehicles.find(
            (vehicle) => vehicle.vehicleId === id
          );

          if (item) {
            this.selectedVehicleId = item.vehicleId;
          }
        }

        // 比較用
        this.timeLineAllData = newTimeLine;
      } finally {
        console.log(`[END] refreshTimeLine`);
      }
    },

    /**
     * 日付変更時の処理です。
     */
    async onChangeDate(dateAfter) {
      // 処理中表示
      let loader = this.showLoader();

      try {
        const dateAfterString = DateUtil.dateStringBase(
          dateAfter,
          "YYYY/MM/DD"
        );
        // console.log(`onChangeDate:${dateAfterString}`);

        // 当日かどうか
        const isToday = this.isToday(dateAfterString);

        this.isNowTime = isToday;

        // 現在選択日付更新
        this.dateString = dateAfterString;

        // 到着地点（現場/工区)、打設箇所セレクタ更新
        await this.getPlan();
        await this.getVolumeList();
        this.makePouringList();
        
        await this.getAreaAndPouringListforDrive(dateAfter);

        // 到着拠点(現場/工区)リスト設定
        let pouringListIndex = -1;
        // pouringListIndex = this.getPouringListIndex();
        let selectedPouring = this.pouringList.filter(item => {
          return item.volumeId == this.selectedVolumeId
        })
        if (selectedPouring.length > 0) {
          console.log(`onChangeDate ${selectedPouring[0].id}`)
          pouringListIndex = selectedPouring[0].id
        }

        if (pouringListIndex == -1) {
          this.setSignagePouringList(this.pouringList);
          if (!this.isZando) {
            this.selectedVolumeId = this.pouringList[0].volumeId
          }
        } else {
          this.setSignagePouringList(
            this.pouringList, 
            pouringListIndex
          );
          this.onChangePouringPosition(pouringListIndex);
        }
        
        // 運行位置再描画
        await this.onRefreshLocation();

        // 日付変更時は車両選択をクリア
        this.selectedVehicleId = "";

        // 打設箇所のカレント情報クリア
        // this.selectedVolumeId = "";

        if (this.isNowTime) {
          this.startRefreshLocationInterval();
        } else {
          this.stopRefreshLocationInterval();
        }        
      } catch (e) {
        this.errorLog(`onChangeDate`, `${this.parseErrorObject(e)}`);
        // this.errorLog(`${e.message} `);
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * 打設箇所リストを親コンポーネントからセットするよう（実績表示）
     */
    setSignagePouringList(list, defaultValue) {
      if (String(list[0].id).length > 0) {
        this.signagePouringList = list;
      } else {
        this.signagePouringList = [];
      }

      this.headerPouringPosition = defaultValue;

      if (!this.headerPouringPosition) {
        if (this.signagePouringList.length > 0) {
          //1件目を初期選択状態とする
          this.headerPouringPosition = this.signagePouringList[0].name;
        }
      }

      //this.isLoading = false;
    },

    /**
     * 運行情報の中から、最終時刻を取得
     * @param {Object} driveinfo 運行情報
     * @return 運行情報より、最も遅い時間を返却
     */
    getDriveLastTime(driveinfo) {
      let retValue = null;

      // 工場出発時間
      if (driveinfo.factoryStartTime) {
        retValue = driveinfo.factoryStartTime;
      }

      // 工場到着時間
      if (driveinfo.factoryEndTime) {
        if (retValue == null) {
          retValue = driveinfo.factoryEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.factoryEndTime)) {
            retValue = driveinfo.factoryEndTime;
          }
        }
      }

      // 現場出発時間
      if (driveinfo.siteStartTime) {
        if (retValue == null) {
          retValue = driveinfo.siteStartTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.siteStartTime)) {
            retValue = driveinfo.siteStartTime;
          }
        }
      }

      // 現場到着時間
      if (driveinfo.siteEndTime) {
        if (retValue == null) {
          retValue = driveinfo.siteEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.siteEndTime)) {
            retValue = driveinfo.siteEndTime;
          }
        }
      }

      // 打設開始時間
      if (driveinfo.pouringStartTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringStartTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.pouringStartTime)) {
            retValue = driveinfo.pouringStartTime;
          }
        }
      }

      // 打設終了時間
      if (driveinfo.pouringEndTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringEndTime;
        } else {
          if (DateUtil.isBefore(retValue, driveinfo.pouringEndTime)) {
            retValue = driveinfo.pouringEndTime;
          }
        }
      }

      return retValue;
    },

    /**
     * 運行情報の中から、最初の時刻を取得
     * @param {Object} driveinfo 運行情報
     * @return 運行情報より、最も早い時間を返却
     */
    getDriveFirstTime(driveinfo) {
      let retValue = null;

      // 工場出発時間
      if (driveinfo.factoryStartTime) {
        retValue = driveinfo.factoryStartTime;
      }

      // 工場到着時間
      if (driveinfo.factoryEndTime) {
        if (retValue == null) {
          retValue = driveinfo.factoryEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.factoryEndTime)) {
            retValue = driveinfo.factoryEndTime;
          }
        }
      }

      // 現場出発時間
      if (driveinfo.siteStartTime) {
        if (retValue == null) {
          retValue = driveinfo.siteStartTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.siteStartTime)) {
            retValue = driveinfo.siteStartTime;
          }
        }
      }

      // 現場到着時間
      if (driveinfo.siteEndTime) {
        if (retValue == null) {
          retValue = driveinfo.siteEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.siteEndTime)) {
            retValue = driveinfo.siteEndTime;
          }
        }
      }

      // 打設開始時間
      if (driveinfo.pouringStartTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringStartTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.pouringStartTime)) {
            retValue = driveinfo.pouringStartTime;
          }
        }
      }

      // 打設終了時間
      if (driveinfo.pouringEndTime) {
        if (retValue == null) {
          retValue = driveinfo.pouringEndTime;
        } else {
          if (DateUtil.isAfter(retValue, driveinfo.pouringEndTime)) {
            retValue = driveinfo.pouringEndTime;
          }
        }
      }

      return retValue;
    },

    /**
     * 運行情報一覧のカレント行が変更された時に呼び出されます。
     */
    async handleTimeLineCurrentChange(currentRow) {
      // console.log(`currentRow: ${JSON.stringify(currentRow, null, "\t")}`);

      if (!currentRow) {
        return;
      }

      if (currentRow.type == "vehicle") {
        // 選択行を解除するのみで処理しない
        this.$refs.timeLineTable.setCurrentRow();
        return;
      }

      // ToDo 参照権限ユーザーは何もしない
      if (this.userStore.userType === "0") {
        this.infoLog(`参照権限ユーザー運行情報モーダル非表示`);
        // console.log(`参照権限ユーザー運行情報モーダル非表示: ${this.userStore.userName}`);
        return;
      }

      // スマートフォン表示の場合は表示しない
      if (this.isPhone) {
        return;
      }

      // モーダルの値セット
      let editBeforeInfo = JSON.parse(JSON.stringify(currentRow.rawData));
      const setPouringPosition = editBeforeInfo.drive.pouringPosition;
      const setCaryyType = editBeforeInfo.drive.carryType;

      this.editTimeLineVehicleName = currentRow.vehicleName;
      currentRow.rawData = editBeforeInfo;
      this.editTimeLineData = null;
      this.editTimeLineData = currentRow.rawData;
      this.editTimeLineData.deliveryInfo = null;
      this.editTimeLineData.deliveryInfo = currentRow.rowDeliveryData;

      // 納入タブ値セット
      this.thumbDeliveryPhoto = null;
      if (Object.keys(currentRow.rowDeliveryData).length){
        if (currentRow.rowDeliveryData.delivery.deliveryPhoto != null){

          // 出発、到着時刻設定
          this.editDeliveryStartTime = currentRow.rowDeliveryData.delivery.startTime;
          this.editDeliveryEndTime = currentRow.rowDeliveryData.delivery.endTime;

          // 納入写真設定
          const user = await UserInfo.getUserInfo();
          const tenantStorage = new TenantStorage(user.group);
          const photoFileFullPath = `tenant/${user.group}/${this.projectId}/${DateUtil.getFormatString(this.dateString, 'YYYYMMDD')}/${editBeforeInfo.drive.id}/${currentRow.rowDeliveryData.delivery.deliveryPhoto}`
          const photoKey = `${path.dirname(photoFileFullPath)}/${path.basename(photoFileFullPath)}`
          const result = await tenantStorage.get(photoKey);
          this.thumbDeliveryPhoto = await result.toBase64();
        }
      } 

      // 打設箇所（選択されている工区から）、積載物のリストを再設定
      this.onChangeAreaDrive(currentRow.rawData.drive.endAreaId);
      this.editTimeLineData.drive.pouringPosition = null;
      this.editTimeLineData.drive.pouringPosition = setPouringPosition;
      this.editTimeLineData.drive.carryType = null;
      this.editTimeLineData.drive.carryType = setCaryyType;

      this.keepCurrentRow = currentRow;

      // 比較用に保持
      this.editTimeLineDataBefore = JSON.parse(
        JSON.stringify(this.editTimeLineData)
      );

      // 運行情報から、対象の工区・打設箇所で絞り込み
      let filterDrive = this.driveList
        .filter(
          (item) =>
            item.drive.vehicleId === currentRow.rawData.drive.vehicleId &&
            item.drive.endAreaId === currentRow.rawData.drive.endAreaId &&
            item.drive.pouringPosition ===
              currentRow.rawData.drive.pouringPosition
        )
        .slice()
        .sort(function(a, b) {
          if (a.drive.driveNumber < b.drive.driveNumber) return -1;
          if (a.drive.driveNumber > b.drive.driveNumber) return 1;
        });

      // 時間の整合性をチェックするために前の運行情報と後の運行情報の時間取得
      this.beforeDriveLastTime = "";
      this.afterDriveFirstTime = "";

      for (let i = 0; i < filterDrive.length; i++) {
        if (currentRow.rawData.drive.id == filterDrive[i].drive.id) {
          if (i == 0) {
            // 最初のレコードの為、次の運行情報の最初の時間を取得
            if (filterDrive.length > 1) {
              this.afterDriveFirstTime = this.getDriveFirstTime(
                filterDrive[1].drive
              );
            }
          } else if (i == Number(filterDrive.length - 1)) {
            // 最後のレコードの為、前の運行情報の最後の時間を取得
            this.beforeDriveLastTime = this.getDriveLastTime(
              filterDrive[i - 1].drive
            );
          } else {
            // 前後の運行情報の時間を取得（前の運行情報からは最後の時間、後の運行情報からは最初の時間を取得）
            this.beforeDriveLastTime = this.getDriveLastTime(
              filterDrive[i - 1].drive
            );
            this.afterDriveFirstTime = this.getDriveFirstTime(
              filterDrive[i + 1].drive
            );
          }

          break;
        }
      }
      //console.log(`---------------- beforeDriveLastTime: ${this.beforeDriveLastTime}`);
      //console.log(`---------------- afterDriveFirstTime: ${this.afterDriveFirstTime}`);

      this.modals.editTimeLineDlg = true;

      // 選択行を解除しておく
      this.$refs.timeLineTable.setCurrentRow();
    },

    /**
     * 実績表示クリック時の処理です。
     */
    onClickShowResult() {
      // 連打防止
      if (this.repeatedHitsFlg) return;

      this.repeatedHitsFlg = true;
      console.log("onClickShowResult");
      let signage = this.$store.state.signage;
      signage.projectId = this.projectId;
      signage.projectName = this.projectName;
      signage.targetDate = this.dateString;
      signage.volumeId = this.selectedVolumeId;
      this.$store.commit("setSignage", signage);

      let timeline = this.$store.state.timeline;
      timeline.datestring = this.dateString;
      this.$store.commit("setTimeline", timeline);

      // ToDo 実績表示
      const ua = navigator.userAgent.toLowerCase();
      if (
        /android|ipod|ipad|iphone|macintosh/.test(ua) &&
        "ontouchend" in document
      ) {
        this.$router.push({
          path: this.getRoutePath(`mobilesignage`),
        });
      } else {
        this.$router.push({
          path: this.getRoutePath(`signage`),
        });
      }
    },

    /**
     * 到着拠点（現場/工区） 打設箇所選択変更時処理です。
     * @param index 選択したリストのインデックス
     */
    async onChangePouringPosition(index) {
      // 処理中
      let loader = this.showLoader();
      try {
        //残土対応
        if (this.isZando){
          if (index == 0){
            //index==0は全てを選択時
            this.selectedVolumeId = ``
          } else {
            // 全てを追加しているため、セレクトボックスの選択行-1の値を参照
            // this.selectedVolumeId = this.volumList[index-1].volume.id;
            this.selectedVolumeId = this.pouringList[index].volumeId;
          }
        } else {
          // this.selectedVolumeId = this.volumList[index].volume.id;
          this.selectedVolumeId = this.pouringList[index].volumeId;
        }
       
        // console.log(`☆onChangePouringPosition ${JSON.stringify(this.volumList[index])}`)
        const selectedList = this.pouringList[index];
        this.endAreaId = selectedList.endAreaId;
        this.pouringPosition = selectedList.pouringPosition;

        let endAreaName = "";
        for (let i = 0; i < this.listArea.length; i++) {
          if (this.listArea[i].id == selectedList.endAreaId) {
            endAreaName = this.listArea[i].name;
            break;
          }
        }

        this.pouringPositionAreaName = `${endAreaName} ${selectedList.pouringPosition}`;  
        // 地図更新
        if (this.selectedVolumeId == ``) {

          // 全て選択時の地図への反映
          let endAreaPouringPositions = [];

          // 全ての到着拠点ID、打設箇所（岩種）を地図側に渡す
          for (let i=1; i<this.pouringList.length; i++)
          {
            endAreaPouringPositions.push({
              endAreaId: this.pouringList[i].endAreaId,
              pouringPosition: this.pouringList[i].pouringPosition});
          }
          // 地図描画（到着拠点、走行位置）
          this.$refs.map.setSelectedEndAreaPouringPosition(
            endAreaPouringPositions
          );
          // ルート描画（全て）
          this.drawAllRoute();

        } else {
          // 選択した到着拠点（土捨場）、打設箇所（岩種）の地図表示  
          let endAreaPouringPositions = [];
          endAreaPouringPositions.push({
            endAreaId: this.endAreaId,
            pouringPosition: this.pouringPosition});

          // 地図描画（到着拠点、走行位置）
          this.$refs.map.setSelectedEndAreaPouringPosition(
            endAreaPouringPositions
          );
          // ルート描画
          this.drawRoute();
        }
        // 更新
        await this.onRefreshLocation();

        // 到着拠点（現場/工区） 打設箇所変更時は車両選択をクリア
        this.selectedVehicleId = "";
      } catch (e) {
        if (this.projectType == "0"){
          this.showBottomToast(
            `工区、打設箇所の切り替え処理に失敗しました。`,
            `error`
          );
        } else if (this.projectType == "1"){
          this.showBottomToast(
            `岩種、土捨場の切り替え処理に失敗しました。`,
            `error`
          );
        }
        // this.errorLog(e.message);
        this.errorLog(`onChangePouringPosition`, `${this.parseErrorObject(e)}`);
      } finally {
        this.hideLoader(loader);
      }
    },
    async getRouteDatas() {
      this.currentRouteData = null
      // プロジェクトに紐づくデータをすべて取得
      let routeList = await RouteModel.getRouteList(this.projectId)
      if (routeList.length > 0) {
        return routeList
      }
      return []
    },
    getRouteDrawParameters(route) {
      // 描画用パラメータセット
      let parameters = {
        routeId: route.routeId,
        routeGeometry: route.routeGeometry,
        roadType: route.roadType
      };
      return parameters;
    },
    async drawRoute(){
      // ルート描画
      this.getRouteDatas().then(routeList => {
        // 一旦すべてのルートを削除する
        this.$refs.map.deleteShape(this.routeIdList)
        // console.log(`☆makePouringList: ${this.routeIdList.length}`)
        this.routeIdList = []

        if (routeList.length > 0 && this.endAreaId != "") {
          for (let route of routeList) {
            let startAreaList = this.listBaseArea.filter(item => {
              return item.baseArea.areaType == "1"
            })

            if (startAreaList.length > 0) {
              for (let startArea of startAreaList) {
                let startAreaId = startArea.baseArea.id
                let endAreaId = this.endAreaId
                let skPrefix = `route#${this.projectId}#${startAreaId}#${endAreaId}`
                
                if (route.sk.includes(skPrefix)) {
                  let param = this.getRouteDrawParameters(route.route)
                  this.$refs.map.addRoute(param)
                  this.routeIdList.push(route.route.routeId)
                }
              }   
            }
          }
        }
      })      
    },
    async drawAllRoute(){
      // ルート描画
      this.getRouteDatas().then(routeList => {

        // 一旦すべてのルートを削除する
        this.$refs.map.deleteShape(this.routeIdList)
        this.routeIdList = []

        if (routeList.length > 0 && this.pouringList.length > 0) {
          for (let route of routeList) {
            let startAreaList = this.listBaseArea.filter(item => {
              return item.baseArea.areaType == "1"
            })

            if (startAreaList.length > 0) {
              for (let startArea of startAreaList) {
                let startAreaId = startArea.baseArea.id

                let isDraw = false;
                for (let i=0; i<this.pouringList.length; i++){
                  if (this.pouringList[i].endAreaId != ``){
                    let skPrefix = `route#${this.projectId}#${startAreaId}#${this.pouringList[i].endAreaId}`
                    if (route.sk.includes(skPrefix)) {
                      isDraw = true;
                    }

                    if (isDraw) {
                      let param = this.getRouteDrawParameters(route.route)
                      this.$refs.map.addRoute(param)
                      this.routeIdList.push(route.route.routeId)
                      break;
                    }
                  }
                }
              }   
            }
          }
        }
      })      
    },
    /**
     * 位置情報更新間隔を開始します。
     */
    startRefreshLocationInterval() {
      const interval = this.refreshIntervalSecond;

      appLog.debugLog(
        "ApplicationHeader",
        "",
        `位置情報更新間隔秒：${interval}`
      );

      this.refreshIntervalId = setInterval(
        this.onRefreshLocation,
        this.updateIntervalMinutes * 1000 * interval
      );
    },

    /**
     * 位置情報更新間隔を停止します。
     */
    stopRefreshLocationInterval() {
      // 停止
      clearInterval(this.refreshIntervalId);
    },

    /**
     * リスト選択用に値と表示名のリストを作成します
     */
    makePouringList() {
      // リスト選択用に値と表示名の単純なリストを作成する
      // ※地図、タイムラインフィルター用の要素も追加しておく
      // タイムラインではIDにはインデックスを設定
      this.pouringList = [];
      if (this.volumList.length > 0) {
        if (this.isZando){
          this.pouringList.push({
            id: 0,
            name: `全て`,
            endAreaId: ``,
            pouringPosition: ``,
            volumeId: ``
          });
          this.volumList.forEach(
            function(item, index) {
              this.pouringList.push({
                id: index + 1,
                name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
                endAreaId: `${item.volume.endAreaId}`,
                pouringPosition: `${item.volume.pouringPosition}`,
                volumeId: item.volume.id
              });
            }.bind(this)
          );
        } else {
          this.volumList.forEach(
            function(item, index) {
              this.pouringList.push({
                id: index,
                name: `${item.volume.endAreaName} ${item.volume.pouringPosition}`,
                endAreaId: `${item.volume.endAreaId}`,
                pouringPosition: `${item.volume.pouringPosition}`,
                volumeId: item.volume.id
              });
            }.bind(this)
          );
        }
        
      } else {
        // 予定も拠点も未作成時
        this.pouringList.push({
          id: "",
          name: ``,
          endAreaId: ``,
          pouringPosition: ``,
        });
      }

      console.log(this.pouringList)
      this.endAreaId = "";
      this.pouringPosition = "";

      // 先頭を初期値で設定
      this.endAreaId = this.pouringList[0].endAreaId;
      this.pouringPosition = this.pouringList[0].pouringPosition;
      this.pouringPositionAreaName = this.pouringList[0].name;

      // 地図へ変更内容を設定
      // this.$refs.map.setSelectedEndAreaPouringPosition(
      //   this.endAreaId,
      //   this.pouringList[0].pouringPosition
      // );
      //地図更新
      if (this.endAreaId == ``){
        //全て選択時の地図への反映
        let endAreaPouringPositions = [];
        
        //全ての到着拠点ID、打設箇所（岩種）を地図側に渡す
        for (let i=1; i<this.pouringList.length; i++)
        {
          endAreaPouringPositions.push(
            {
              endAreaId: this.pouringList[i].endAreaId,
              pouringPosition: this.pouringList[i].pouringPosition
            });
        }
        //地図描画（到着拠点、走行位置）
        this.$refs.map.setSelectedEndAreaPouringPosition(
          endAreaPouringPositions
        );
        //ルート描画（全て）
        this.drawAllRoute();

      } else {
        //選択した到着拠点（土捨場）、打設箇所（岩種）の地図表示
        let endAreaPouringPositions = [];
        endAreaPouringPositions.push(
          {
            endAreaId: this.endAreaId,
            pouringPosition: this.pouringList[0].pouringPosition
          });

        // 地図描画（到着拠点、走行位置）  
        this.$refs.map.setSelectedEndAreaPouringPosition(
          endAreaPouringPositions
        );
        // ルート描画
        this.drawRoute();
      }
    },

    /**
     * 運行情報表示切替
     */
    showTimeLine() {
      // 運行情報表示を切替
      this.isTimelineShow = !this.isTimelineShow;

      if (this.isTimelineShow) {
        // 運行情報表示時は地図を通常表示
        this.mapInfoHeight = "max-height: 304px;";
        this.mapHeight = "height: 276px;";
      } else {
        // 運行情報非表示時は地図を拡大表示
        this.mapInfoHeight =
          "height: calc(100% - 72px);max-height: calc(100% - 72px);";
        this.mapHeight =
          "height: calc(100% - 32px);max-height: calc(100% - 32px);";
      }
    },

    /**
     * 運行表示編集モーダルキャンセルボタンクリック時
     */
    onClickCancelUpdateTimeline() {
      // 編集内容を破棄し、timelineの一覧に戻の値をセットする
      this.keepCurrentRow.rawData = this.editTimeLineDataBefore;
      this.keepCurrentRow.rowDeliveryData = this.editTimeLineDataBefore.deliveryInfo;

      // モーダルを閉じる
      this.modals.editTimeLineDlg = false;
    },

    /**
     * 運行表示編集モーダル更新ボタンクリック時
     */
    async onClickUpdateTimeline() {
      let loader = null;

      try {
        // 入力チェック
        let isValid = await this.validateTimeLine();
        if (!isValid) {
          return;
        }

        // 処理中
        loader = this.showLoader();

        // ユーザーを更新
        const loginUserId = this.userStore.userId;
        this.editTimeLineData.updatedUser = loginUserId;

        const beforeDrive = this.editTimeLineDataBefore.drive;
        const editDrive = this.editTimeLineData.drive;

        // console.log(beforeDrive);
        // console.log(editDrive);

        // 更新ユーザーの設定
        if (beforeDrive.pouringStartTime != editDrive.pouringStartTime) {
          // 打設開始時間
          this.editTimeLineData.drive.pouringStartUser = loginUserId;
        }
        if (beforeDrive.pouringEndTime != editDrive.pouringEndTime) {
          // 打設終了時間
          this.editTimeLineData.drive.pouringEndUser = loginUserId;
        }
        
        if (beforeDrive.unloadingTime != editDrive.unloadingTime){
          //荷降時間
          this.editTimeLineData.drive.unloadingUser = loginUserId;
        }


        // DB更新
        await this.updateDrive(this.editTimeLineData);

        // 運行表示を更新
        this.driveList = [];
        this.deliveryList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(
          this.dateString,
          "YYYYMMDD"
        )}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);

        // vueの更新用に再設定
        if (this.selectedVehicleId) {
          // 一致する車両IDのリスト
          const id = this.selectedVehicleId;
          this.selectedVehicleId = "";
          const item = this.timeLineVehicles.find(
            (vehicle) => vehicle.vehicleId === id
          );
          this.selectedVehicleData = item.list;

          // 選択車両IDを設定して一覧ビューを切り替え
          this.selectedVehicleId = id;
        }

        // モーダルを閉じる
        this.modals.editTimeLineDlg = false;
      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }
    },

    /**
     * 運行表示編集モーダルの入力をチェックします。
     * @returns 入力エラーが無い場合true、さもなくばfalse
     */
    async validateTimeLine() {
      let result = false;
      let timeEditFlg = false;

      // 先に時刻フォーマットを変換（El-UIで変更した時刻がUTC表示になるため）
      Object.keys(this.editTimeLineData.drive).forEach((key) => {
        if (
          key === "factoryStartTime" ||
          key === "siteEndTime" ||
          key === "pouringStartTime" ||
          key === "pouringEndTime" ||
          key === "siteStartTime" ||
          key === "factoryEndTime" ||
          key === "unloadingTime"
        ) {
          const toString = Object.prototype.toString;
          let value = this.editTimeLineData.drive[key];
          if (
            toString
              .call(value)
              .slice(8, -1)
              .toLowerCase() !== "string"
          ) {
            // console.log(value);
            if (value) {
              // 日付が当日になってしまうので、選択日付に置き換える
              value = `${DateUtil.dateStringDate(
                new Date(this.dateString)
              )} ${DateUtil.dateStringBase(value, "HH:mm:ss")}`;
              // value = DateUtil.dateStringDateTime(value);
              // console.log(value);
              this.editTimeLineData.drive[key] = value;

              // 時間の変更あり
              timeEditFlg = true;
            }
          }

          // ※時間を保持している全てのデータの秒を00にする
          let ssValue = this.editTimeLineData.drive[key];
          if (ssValue) {
            // 秒を00にする
            ssValue = `${DateUtil.dateStringDate(
              new Date(this.dateString)
            )} ${DateUtil.dateStringBase(ssValue, "HH:mm:00")}`;
            // 値を更新する
            this.editTimeLineData.drive[key] = ssValue;
          }
        }
      });

      // 時間のカラムが一つでも変更されていた場合は時間の整合性チェック
      if (timeEditFlg == true) {
        // 工場出発時刻
        if (!this.editTimeLineData.drive.factoryStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`工場出発時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`現場出発時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 現場到着時刻
        if (!this.editTimeLineData.drive.siteEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`現場到着時刻を指定してください。`, `warning`);
            // this.$refs.timePickSiteEndTime.select();
            return result;
          } else if (this.projectType == "1"){           
            this.showBottomToast(`土捨場到着時刻を指定してください。`, `warning`);
            return result;
          }         
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.factoryStartTime,
            this.editTimeLineData.drive.siteEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `現場到着時刻は、工場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`土捨場到着時刻は、現場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.factoryStartTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `工場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(`現場出発時刻が１つ前の運行情報の最終時刻より、前の時間に設定されています。\n${DateUtil.dateStringBase(
                this.beforeDriveLastTime,
                "HH:mm"
              )}より、後の時間を設定してください。`,
              `warning`
              );
              return result;
            }
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.siteEndTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `現場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `土捨場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }            
          }
        }

        // 打設開始時刻
        if (!this.editTimeLineData.drive.pouringStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`打設開始時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`積込開始時刻を指定してください。`, `warning`);
            return result;
          }
          
        }

        // 1つ前の時刻が新しければ不正
        if (this.projectType === "0") {
          if (
            DateUtil.isAfter(
              this.editTimeLineData.drive.siteEndTime, 
              this.editTimeLineData.drive.pouringStartTime)) {
            this.showBottomToast(
              `打設開始時刻は、現場到着時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        } else if (this.projectType === "1") {
          if (
            DateUtil.isBefore(
              this.editTimeLineData.drive.siteEndTime,
              this.editTimeLineData.drive.pouringStartTime)) {
            this.showBottomToast(
              `積込開始時刻は、土捨場到着時刻よりも前の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }

        // 打設終了時刻
        if (!this.editTimeLineData.drive.pouringEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`打設終了時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`積込終了時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.pouringStartTime,
            this.editTimeLineData.drive.pouringEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `打設終了時刻は、打設開始時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `積込終了時刻は、積込開始時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.pouringStartTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `打設開始時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `積込開始時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            }
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.pouringEndTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `打設終了時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `積込終了時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }
          }
        }

        // 現場出発時刻
        if (!this.editTimeLineData.drive.siteStartTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`現場出発時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`土捨場到着時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.pouringEndTime,
            this.editTimeLineData.drive.siteStartTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `現場出発時刻は、打設終了時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `土捨場出発時刻は、積込終了時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result
          }
        }

        // 工場到着時刻
        if (!this.editTimeLineData.drive.factoryEndTime) {
          if (this.projectType == "0"){
            this.showBottomToast(`工場到着時刻を指定してください。`, `warning`);
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(`現場到着時刻を指定してください。`, `warning`);
            return result;
          }
        }

        // 1つ前の時刻が新しければ不正
        if (
          DateUtil.isAfter(
            this.editTimeLineData.drive.siteStartTime,
            this.editTimeLineData.drive.factoryEndTime
          )
        ) {
          if (this.projectType == "0"){
            this.showBottomToast(
              `工場到着時刻は、現場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          } else if (this.projectType == "1"){
            this.showBottomToast(
              `現場到着時刻は、土捨場出発時刻よりも後の時間を指定してください。`,
              `warning`
            );
            return result;
          }          
        }

        // 1つ前の運行情報の最終時間より、前の時間を指定していないかチェック
        if (this.beforeDriveLastTime != "") {
          if (
            DateUtil.isAfter(
              this.beforeDriveLastTime,
              this.editTimeLineData.drive.siteStartTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `現場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `土捨場出発時刻が１つ前の運行情報の最終時刻より、前の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.beforeDriveLastTime,
                  "HH:mm"
                )}より、後の時間を指定してください。`,
                `warning`
              );
              return result;
            }          
          }
        }

        // 1つ後の運行情報の最新時間より、後の時間を指定していないかチェック
        if (this.afterDriveFirstTime != "") {
          if (
            DateUtil.isBefore(
              this.afterDriveFirstTime,
              this.editTimeLineData.drive.factoryEndTime
            )
          ) {
            if (this.projectType == "0"){
              this.showBottomToast(
                `工場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            } else if (this.projectType == "1"){
              this.showBottomToast(
                `現場到着時刻が１つ後の運行情報の最新時刻より、後の時間が指定されています。\n${DateUtil.dateStringBase(
                  this.afterDriveFirstTime,
                  "HH:mm"
                )}より、前の時間を指定してください。`,
                `warning`
              );
              return result;
            }
          }
        }
      }

      if (!this.editTimeLineData.drive.endAreaId) {
        if (this.projectType == "0"){
          this.showBottomToast(`工区を指定してください。`, `warning`);
          return result;
        } else if (this.projectType == "1"){
          this.showBottomToast(`土捨場を指定してください。`, `warning`);
          return result;
        }
      }
      // console.log(this.editTimeLineData.drive.pouringPosition)

      if (!this.editTimeLineData.drive.pouringPosition) {
        if (this.projectType == "0"){
          this.showBottomToast(`打設箇所を指定してください。`, `warning`);
          return result;
        } else if (this.projectType == "1"){
          this.showBottomToast(`岩種を指定してください。`, `warning`);
          return result;
        }
      }

      if (this.projectType == "0"){
        if (!this.editTimeLineData.drive.carryType) {
          this.showBottomToast(`積載物を指定してください。`, `warning`);
          return result;
        }
        return true;
      }
      if (this.projectType == "1"){
        if (!this.editTimeLineData.drive.unloadingTime){
          this.showBottomToast(`荷降時間を指定してください`, `warning`);
          return result;
        }
        return true;
      }
    },

    /**
     * 運行情報を更新します。
     * @param {Object} driveRow 運行情報データ
     */
    async updateDrive(driveRow) {
      const pk = driveRow.pk;
      const sk = driveRow.sk;
      const user = this.$store.state.user.userId;

      try {
        // 時刻を更新
        const exceptedTimeStamp = driveRow.updatedAt;

        let condition = {};
        condition.and = [
          {
            updatedAt: { eq: exceptedTimeStamp },
          },
        ];

        // 納入伝票更新
        if (Object.keys(driveRow.deliveryInfo).length){

          // 出発時刻（日付を外して時間だけにする）
          if (this.editDeliveryStartTime){
            driveRow.deliveryInfo.delivery.startTime = DateUtil.dateStringDateTime(this.editDeliveryStartTime).substr(11, 5);
          } else {
            driveRow.deliveryInfo.delivery.startTime = null;
          }

          // 到着時刻（日付を外して時間だけにする）
          if (this.editDeliveryEndTime) {
            driveRow.deliveryInfo.delivery.endTime = DateUtil.dateStringDateTime(this.editDeliveryEndTime).substr(11, 5);
          } else {
            driveRow.deliveryInfo.delivery.endTime = null;
          }

          // 納入伝票情報更新
          const updataDelivery = driveRow.deliveryInfo;
          await DriveModel.updateDelivery(updataDelivery);

        }

        // 新しい値
        // driveRow.updatedAt = DateUtil.getUnixTimeStampOfMilliSecond();

        // 第2引数に指定 ※単独更新でもエラーとなるのでコメント
        // await DriveModel.updateDrive(driveRow, condition);

        delete driveRow["deliveryInfo"];
        await DriveModel.updateDrive(driveRow);

        this.showBottomToast(`運行情報を更新しました。`, `info`);
        // ※driveを渡すと空文字になってしまう（文字数制限越え？）ので、pk、skを出力
        // appLog.infoLog(`${this.vuename}`, user, this.getUpdateDriveInfo(driveRow));
        this.infoLog(`【Update】 Drive : pk[${pk}] sk[${sk}]`);
      } catch (ex) {
        if (ex.errors) {
          if (ex.errors.length > 0) {
            switch (ex.errors[0].errorType) {
              // このメッセージであれば排他制御エラー
              case "DynamoDB:ConditionalCheckFailedException":
                this.showBottomToast(
                  `先に他のユーザーが更新しているため、新しい内容を確認してください。`,
                  `warning`
                );
                this.errorLog(`updateDrive`, `${this.parseErrorObject(ex)}`);
                // this.errorLog(
                //   `【confrict】 : pk[${pk}] sk[${sk}] error[${JSON.stringify(
                //     ex
                //   )}]`
                // );
                // 運行情報呼び出し側でエラー時も一覧を更新している
                // 必要に応じて競合時だけ更新する場合はここに処理を追加
                break;

              default:
                this.showBottomToast(`運行情報の更新に失敗しました`, `error`);
                this.errorLog(`updateDrive`, `${this.parseErrorObject(ex)}`);
                // this.errorLog(`pk[${pk}] sk[${sk}] error[${JSON.stringify(ex)}]`);
                break;
            }
          } else {
            this.showBottomToast(`運行情報の更新に失敗しました`, `error`);
            this.errorLog(`updateDrive`, `${this.parseErrorObject(ex)}`);
            // this.errorLog(`pk[${pk}] sk[${sk}] error[${JSON.stringify(ex)}]`);
          }
        }

        throw ex;
      }
    },

    // /**
    //  * 運行の更新情報文字列を取得します（ログ出力用）
    //  * @param {Object} driveRow 更新する運行情報
    //  * @returns 運行情報文字列
    //  */
    // async getUpdateDriveInfo(driveRow) {
    //   //console.log(`【Edit】${JSON.stringify(driveRow, null, "\t")}`)
    //   return `【Edit】${JSON.stringify(driveRow, null, "\t")}`;
    // },

    onFocusTimePickSiteEndTime(focusedPicker) {
      // console.log(focusedPicker)
      // test
      // this.$refs.timePickSiteEndTime.select();
      // focusedPicker.select();
    },

    /**
     * 運行サマリ情報更新時の処理です。
     */
    async updateSummarys(summarys) {
      console.log("updateSummarys");
      this.roundSummarys = summarys;

      // if (this.$refs.subHeader) {
      //   if (this.$refs.subHeader.updateSummarys) {
      //     // await this.$refs.subHeader.updateSummarys(summarys);
      //     this.roundSummarys = summarys;
      //   }
      // }
    },

    // /**
    //  * 運行情報リストから、車両IDと地図アイコン注記用表示順のリストを取得します。
    //  * @param {String} sk プロジェクトID#yyyyMMDD#到着拠点ID
    //  * @returns 車両IDと地図アイコン注記用表示順のリスト
    //  */
    // async getVehicleMapTextOrders(sk) {
    //   console.log(`[START] getVehicleMapTextOrders: ${sk}`);

    //   try {
    //     // 運行情報リスト(以降の処理用に保持)
    //     this.driveList = [];
    //     this.driveList = await DriveModel.getDriveList(sk);

    //     // 運行ID(タイムスタンプ)で昇順ソート
    //     let numberingList = this.driveList.sort(function(a, b) {
    //       return a.drive.id > b.drive.id ? 1 : -1;
    //     });

    //     // 車両IDで重複削除
    //     numberingList = numberingList.filter(
    //       (element, index, self) =>
    //         self.findIndex(
    //           (e) => e.drive.vehicleId === element.drive.vehicleId
    //         ) === index
    //     );

    //     // 車両ID毎に採番のうえリスト化
    //     let vehicleNumberList = [];
    //     for (let j = 0; j < numberingList.length; j++) {
    //       const drive = numberingList[j].drive;
    //       const order = j + 1;
    //       const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
    //       vehicleNumberList.push(item);
    //     }

    //     // 運行ID(タイムスタンプ)で降順でソート
    //     let driveList = this.driveList.sort(function(a, b) {
    //       return a.drive.id < b.drive.id ? 1 : -1;
    //     });

    //     // 車両IDで重複を削除
    //     driveList = driveList.filter(
    //       (element, index, self) =>
    //         self.findIndex(
    //           (e) => e.drive.vehicleId === element.drive.vehicleId
    //         ) === index
    //     );

    //     let vehicleMapTextOrders = [];

    //     for (let i = 0; i < driveList.length; i++) {
    //       const drive = driveList[i].drive;
    //       const order = vehicleNumberList.find(
    //         (vehicle) => vehicle.vehicleId === drive.vehicleId
    //       ).iconTextOrder;
    //       const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
    //       vehicleMapTextOrders.push(item);
    //     }

    //     // console.log(`vehicleMapTextOrders:${JSON.stringify(vehicleMapTextOrders, null, "\t")}`);
    //     return vehicleMapTextOrders;
    //   } finally {
    //     console.log(`[END] getVehicleMapTextOrders`);
    //   }
    // },

    // /**
    //  * 地図更新間隔設定モーダル表示
    //  * @returns 地図更新間隔（秒）
    //  */
    // getMapInterval() {
    //   // ローカルストレージから取得
    //   let value = localStorage.getItem(this.$store.state.user.cognitoSub);

    //   if (!value) {
    //     value = 60;
    //   }

    //   return value;
    // },

    /**
     * 運行情報用（カレンダーで日付変更時の処理）工区、打設箇所を再設定
     */
    async getAreaAndPouringListforDrive(targetDate) {
      if (!this.planId) {
        this.listDriveAreaAndPouring = [];
        this.listDriveArea = [];
        return
      }

      // 予定数量のテーブルに問合せ
      const searchKey = `${this.projectId}#${this.planId}`;
      // let searchKey = String(planList[0].sk).replace("plan#", "");
      let volumeList = await VolumeModel.getVolumeList(searchKey);

      // console.log(`☆34：${JSON.stringify(volumeList)}`)
      // 工区、打設箇所のリストセット
      this.listDriveAreaAndPouring = [];
      volumeList.forEach((element) => {
        let item = {
          areaId: element.volume.endAreaId,
          areaName: this.getAreaName(element.volume.endAreaId),
          pouringPosition: element.volume.pouringPosition,
        };

        this.listDriveAreaAndPouring.push(item);
      });

      // 工区のセレクトボックスにセット
      this.listDriveArea = [];
      this.listDriveAreaAndPouring.forEach((element) => {
        let item = {
          id: element.areaId,
          name: element.areaName,
        };

        // 重複チェック
        let chkValue = this.listDriveArea.filter((list) => list.id === item.id);
        if (chkValue.length == 0) {
          this.listDriveArea.push(item);
        }
      });

      // 打設箇所のセレクトボックス初期化
      this.listDrivePouringPosition = [];
    },

    /**
     * 運行情報用（工区のセレクトボックス変更イベント）
     */
    onChangeAreaDrive(selectData) {
      // 工区に紐づく打設箇所をセレクトボックスにセット
      let targetList = this.listDriveAreaAndPouring.filter(
        (list) => list.areaId === selectData
      );
      this.listDrivePouringPosition = [];
      this.editTimeLineData.drive.pouringPosition = null;
      this.modalpouringPosition = null;

      // セレクトボックスに追加
      targetList.forEach((element) => {
        let item = {
          id: element.pouringPosition,
          name: element.pouringPosition,
        };
        this.listDrivePouringPosition.push(item);
      });
    },

    /**
    * 打設開始ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickPouringStart(row){

      // 確認ダイアログ表示
      this.confirmTitle = "打設開始時刻設定確認";
      this.confirmMessage = "打設開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 1;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },

    /**
    * 打設終了ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickPouringEnd(row){

      // 確認ダイアログ表示
      this.confirmTitle = "打設終了時刻設定確認";
      this.confirmMessage = "打設終了時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 2;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },
    
    /**
     * 確認ダイアログでOKボタンクリック時の処理
     */
    async onClickConfirmCancel() {
      this.modals.confirmDlg = false;
    },
    
    /**
     * 確認ダイアログでOKボタンクリック時の処理
     */
    async onClickConfirmOK() {

      // 確認ダイアログ非表示  
      this.modals.confirmDlg = false;

      // 確認ダイアログモード毎で処理分岐
      if (this.confirmDlgMode == 0) {
        // 運航先一括設定
        this.setDriveBatch();
      } else if (this.confirmDlgMode == 1){
        // 打設開始時刻セット
        this.setPouringTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode == 2) {
        // 打設終了時刻セット
        this.setPouringTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==3){
        //積込開始時刻セット
        this.setCargoTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==4){
        //積込終了時刻セット
        this.setCargoTime(this.confirmDlgMode);
      } else if (this.confirmDlgMode ==5){
        //荷降時刻セット
        this.setCargoTime(this.confirmDlgMode);
      }

    },

    /**
     * 打設開始、終了時刻の更新処理です。
     */
    async setPouringTime(mode) {

      let loader = null;

      try {

        // 処理中
        loader = this.showLoader();

        // 運行情報取得
        let driveInfo = await DriveModel.getDrive({sk: this.skDrive});

        // 現在の日時取得
        let nowDateTime = DateUtil.dateStringDateTime();

        // 更新フラグ
        let isUpdate = false;

        // 取得した運行情報の打設時間を現在の時刻にセット
        if (mode == 1) {
          // 打設開始時間
          if (driveInfo.drive.pouringStartTime){
            this.showBottomToast(`既に打設開始時間が登録されています。運行一覧を更新します。`, `info`);
          } else {
            driveInfo.drive.pouringStartTime = nowDateTime;
            isUpdate = true;
          }
        } else {
          // 打設終了時間
          if (driveInfo.drive.pouringEndTime){
            this.showBottomToast(`既に打設終了時間が登録されています。運行一覧を更新します。`, `info`);
          } else {
            driveInfo.drive.pouringEndTime = nowDateTime;
            isUpdate = true;
          }
        }

        // 打設時間更新処理
        if (isUpdate == true) {
          // DB更新
          await DriveModel.updateDrive(driveInfo);

          // 完了メッセージ表示
          if (mode == 1) {
            this.showBottomToast(`打設開始時間を登録しました。`, `info`);
          } else {
            this.showBottomToast(`打設終了時間を登録しました。`, `info`);
          }
        }

        // 運行表示を更新
        this.driveList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);

      } finally {
        if (loader) {
          this.hideLoader(loader);
        }
      }        
    },
    /**
    * 積込開始ボタンクリック時の処理 処理確認モーダル表示
    */
    async onClickCargoStart(row){
      // 確認ダイアログ表示
      this.confirmTitle = "積込開始時刻設定確認";
      this.confirmMessage = "積込開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 3;
      this.skDrive = row.skDrive; // driveレコードのsk保持
      this.modals.confirmDlg = true;
    },

    /**
     * 積込終了ボタンクリック時の処理 処理確認モーダル表示
     */
    async onClickCargoEnd(row){
      //確認ダイアログ表示
      this.confirmTitle = "積込終了時刻設定確認";
      this.confirmMessage = "積込終了時刻を現在の時間で設定しますか？";

      this.confirmDlgMode = 4;
      this.skDrive = row.skDrive;
      this.modals.confirmDlg = true;
    },

    /**
     * 荷降開始ボタンクリック時の処理 処理確認モーダル表示
     */
    async onClickCargoUnloadingTime(row){
      //確認ダイアログ表示
      this.confirmTitle = "荷降開始時刻設定確認";
      this.confirmMessage = "荷降開始時刻を現在の時間で設定しますか？";
      
      this.confirmDlgMode = 5;
      this.skDrive = row.skDrive;
      this.modals.confirmDlg = true;
    },

    /**
     * 積込開始/終了,荷降時間の更新処理です。
     */
    async setCargoTime(mode){
      let loader = null;

      try {
        //処理中
        loader = this.showLoader();
        //運行情報取得
        let driveInfo = await DriveModel.getDrive({sk: this.skDrive});
        //現在の日時取得
        let nowDateTime = DateUtil.dateStringDateTime();
        //更新フラグ
        let isUpdate = false;        
        //取得した運行情報の打設時間を現在の時刻にセット
        if (mode == 3){
          //積込開始時間
          if (driveInfo.drive.pouringStartTime){
            this.showBottomToast(`既に積込開始時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.pouringStartTime = nowDateTime;
            isUpdate = true;
          }
        } else if (mode == 4){
          //積込終了時間
          if (driveInfo.drive.pouringEndTime){
            this.showBottomToast(`既に積込終了時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.pouringEndTime = nowDateTime;
            isUpdate = true;
          }
        } else if (mode == 5){
          //荷降時間
          if (driveInfo.drive.unloadingTime){
            this.showBottomToast(`既に荷降時間が登録されています。運行一覧を更新します`, `info`);
          } else {
            driveInfo.drive.unloadingTime = nowDateTime;
            isUpdate = true;
          }
        }
        //積込時間更新処理
        if (isUpdate == true) {
          //DB更新
          await DriveModel.updateDrive(driveInfo);
          //完了メッセージ表示
          if (mode == 3){
            this.showBottomToast(`積込開始時間を登録しました`, `info`);
          } else if (mode == 4){
            this.showBottomToast(`積込終了時間を登録しました`, `info`);
          } else if (mode ==5){
            this.showBottomToast(`荷降時間を登録しました`, `info`);
          }
        }

        //運行表示を更新
        this.driveList = [];
        const sk = `${this.projectId}#${DateUtil.getFormatString(this.dateString, "YYYYMMDD")}`;
        this.driveList = await DriveModel.getDriveList(sk);
        this.deliveryList = await DriveModel.getDelivaryList(sk);
        await this.refreshTimeLine(sk);
      } finally {
        if (loader){
          this.hideLoader(loader);
        }
      }
    },
    
    /**
    * 運搬時間取得
    */
    async getDeliveryTime(){

      // 戻り値初期化
      let retDeliveryTime = 0;

      // 予定数量登録日の温度取得
      let targetDate = DateUtil.getFormatString(this.dateString, "YYYYMMDD");
      let planList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetDate
      );

      let temperature = 0;
      if (planList.length > 0){
        temperature = planList[0].plan.temperature;

        // 取得した温度から
        for (let i=0; i<this.deliveryLimitList.length; i++){
          if (this.deliveryLimitList[i].lowTemperature <= temperature && 
              this.deliveryLimitList[i].HighTemperature > temperature) {
            retDeliveryTime = this.deliveryLimitList[i].transitTime;
            break;
          }
        }
      }
      return retDeliveryTime;
    },

    /**
    * 残時間（閾値）運搬時間の残り時間を表示する閾値（プロジェック設定）
    */
    async getProjectRemainingTime(){

      const user = await UserInfo.getUserInfo();
      let data = {
        pk: user.group,
        sk: `project#${this.projectId}`
      }
      const project = await PorjectModel.getProject(data);

      return project.project.remainingTime;

    },    
    
  },
};
</script>
