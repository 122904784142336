const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * 予定数量用のデータ取得モデルクラス
 */
class VolumeModel extends baseModel {
  /**
   * テナント内の予定数量リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getVolumeList(projectId) {
    return await super.getList({ beginsWith: `volume#${projectId}` })
  }

  /**
   * 予定数量を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteVolume(data) {
    await super.deleteData(data)
  }

  /**
   * 予定数量レコードを追加する
   * @param {*} data
   */
  static async addVolume(data) {
    await super.addData(data)
  }

  /**
   * 予定数量レコードを更新する
   * @param {*} data
   */
  static async updateVolume(data) {
    await super.updateData(data)
  }

  /**
   * 予定数量レコードを取得する
   * @param {*} data
   */
  static async getVolume(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, appointId, endAreaId, id) {
    // volume#プロジェクトID#予定ID#到着拠点Id#予定数量ID(uuidで採番)
    return `volume#${projectId}#${appointId}#${endAreaId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, appointId, endAreaId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, appointId, endAreaId, id)
    
    newRowData.volume = {
      id: id,
      endAreaId: "",
      pouringPosition: "",
      totalVolume: 0,
      grade: "",
      settingAlert: false,
      numberAlert: 0,
      rockTypeId: "",
      loadCapacity: 0
    }

    return newRowData
 
  }
}

module.exports = VolumeModel;
