import { createStore } from 'vuex'
import timeline from './timeline'
import drivinghistory from './drivinghistory'
import vehicle from './vehicle'
import plan from './plan'
import basearea from './basearea'
import user from './user'
import project from './project'
import signage from './signage'
import mainte from './mainte'
import drivespot from './drivespot'
import acceptance from './acceptance'
// テスト用（最後は削除する）
import test from './test'
import createPersistedState from 'vuex-persistedstate'

/**
 * Vuexストア
 */
export default createStore({
  state: {
    tenant: {
      id: "",
    },
    timeline: timeline,
    vehicle: vehicle,
    drivinghistory: drivinghistory,
    plan: plan,
    basearea: basearea,
    test: test,
    user: user,
    project: project,
    signage: signage,
    drivespot: drivespot,
    acceptance: acceptance,    
    navInfo: {
      title: "",
      desctiption: ""
    },
    mainte: mainte
  },
  mutations: {
    setTenant(state, tenant) {
      console.log(`Vuex setTenant ${JSON.stringify(tenant, null, "\t")}`)
      state.tenant = tenant;
    },
    setPlan(state, plan){
      console.log(`Vuex setPlan ${JSON.stringify(plan, null, "\t")}`)
      state.plan = plan;
    },
    setTimeline(state, timeline){
      console.log(`Vuex setTimeline ${JSON.stringify(timeline, null, "\t")}`)
      state.timeline = timeline;
    },
    setVehicle(state, vehicle){
      console.log(`Vuex setVehicle ${JSON.stringify(vehicle, null, "\t")}`)
      state.vehicle = vehicle;
    },
    setBaseArea(state, basearea){
      console.log(`Vuex setBaseArea ${JSON.stringify(basearea, null, "\t")}`)
      state.basearea = basearea;
    },
    setNavInfo(state, navInfo){
      console.log(`Vuex setNavInfo ${JSON.stringify(navInfo, null, "\t")}`)
      state.navInfo = navInfo;
    },
    setUser(state, user){
      console.log(`Vuex setUser ${JSON.stringify(user, null, "\t")}`)
      state.user = user;
    },
    setProject(state, project){
      console.log(`Vuex setProject ${JSON.stringify(project, null, "\t")}`)
      state.project = project;
    },
    setSignage(state, signage){
      console.log(`Vuex setSignage ${JSON.stringify(signage, null, "\t")}`)
      state.signage = signage;
    },
    setMainte(state, mainte){
      console.log(`Vuex setMainte ${JSON.stringify(mainte, null, "\t")}`)
      state.mainte = mainte;
    },
    setDriveSpot(state, drivespot){
      console.log(`Vuex setDriveSpot ${JSON.stringify(drivespot, null, "\t")}`)
      state.drivespot = drivespot;
    },
    setAcceptance(state, acceptance){
      console.log(`Vuex setAcceptance ${JSON.stringify(acceptance, null, "\t")}`)
      state.acceptance = acceptance;
    },

  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定。デフォルトではvuex
      key: `conoikeConcreate-${process.env.NODE_ENV}`,

      // ストレージの種類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage
    }
  )]
})
