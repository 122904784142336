const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * 予定数量用のデータ取得モデルクラス
 */
class SpotModel extends baseModel {
  /**
   * テナント内の予定数量リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getSpotList(projectId) {
    //return await super.getList({ beginsWith: `spot#${projectId}` })
    let list = await super.getList({ beginsWith: `spot#${projectId}` })
    // 運行スポットはspottypeidがあるレコードのみ有効とする(3次開発以前に作成したスポットは無効)
    list = list.filter(item => {
      return item.spot.spottypeid
    })
    return list
  }

  /**
   * 予定数量を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteSpot(data) {
    await super.deleteData(data)
  }

  /**
   * 予定数量レコードを追加する
   * @param {*} data
   */
  static async addSpot(data) {
    await super.addData(data)
  }

  /**
   * 予定数量レコードを更新する
   * @param {*} data
   */
  static async updateSpot(data) {
    await super.updateData(data)
  }

  /**
   * 予定数量レコードを取得する
   * @param {*} data
   */
  static async getSpot(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    // spot#プロジェクトID#スポットID(uuidで採番)
    return `spot#${projectId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, id)
    
    newRowData.spot = {
      id: id,
      spottypeid: "",
      name: "",
      value: "",
      voicetext: "",
      iconpath: "",
      range: 1,
      spotType: null,
      x: 0.0,
      y: 0.0,
      geofence: null,
      shapeType: null,
      congestionFlag: 0,
    }

    return newRowData
 
  }
}

module.exports = SpotModel;
