/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "651d65b265254c56b413086544579fa7",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "651d65b265254c56b413086544579fa7",
            "region": "us-west-2"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "651d65b265254c56b413086544579fa7",
                "region": "us-west-2"
            }
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pj5uv5xejh.execute-api.ap-northeast-1.amazonaws.com/appprod",
            "region": "ap-northeast-1"
        },
        {
            "name": "konoikeconcreteapi",
            "endpoint": "https://blw1hsvxid.execute-api.ap-northeast-1.amazonaws.com/appprod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://y2er5tqvoncb3bkekns6svocgi.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:04f2b290-a547-482e-ae8d-7b7324e48073",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_bv0GOss0w",
    "aws_user_pools_web_client_id": "6cndmogtvj433u5211hnc7sqcu",
    "oauth": {
        "domain": "localhost-appprod.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost/",
        "redirectSignOut": "http://localhost/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "kikstorage-userdatastorage105212-appprod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
