const short = require("short-uuid");
// const UserInfo = require("../../appUtils/UserInfo");
const baseModel = require("../baseModel");

/**
 * 手書き作図図形用のデータ取得モデルクラス
 */
class ShapeModel extends baseModel {

  /**
   * テナント-プロジェクト-ノード内の手書き作図図形リストを取得する
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {テナント-プロジェクト-ノード内の手書き作図図形リスト}
   */
  static async getShapeList(projectId, nodeId) {
    return await super.getList({ beginsWith: this.createGetSk(projectId, nodeId) });
  }

  /**
   * 手書き作図図形レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteShape(data) {
    await super.deleteData(data)
  }

  /**
   * 手書き作図図形レコードを追加する
   * @param {*} data
   */
  static async addShape(data) {
    await super.addData(data)
  }

  /**
   * 手書き作図図形レコードを更新する
   * @param {*} data
   */
  static async updateShape(data) {
    await super.updateData(data)
  }

  /**
   * 手書き作図図形レコードを取得する
   * @param {*} data
   */
  static async getShape(data) {
    return await super.getSingleData(data);
  }

  /**
   * 取得用skの文字列を生成
   * @param {プロジェクトID} projectId
   * @param {ノードID} nodeId
   * @returns {取得用skの文字列}
   */
  static createGetSk(projectId, nodeId) {
    const prefix = `shape`;
    const getSk = `${prefix}#${projectId}#${nodeId}`;
    console.log('getSk:' + getSk);
    return getSk;
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, nodeId, id) {
    // shape#プロジェクトID#ノードID#箇所ID(uuidで採番)
    return `${this.createGetSk(projectId, nodeId)}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, nodeId) {
    let newRowData = await super.getNewData()

    const id = short.generate()
    newRowData.sk = this.createSk(projectId, nodeId, id)

    newRowData.shape = {
      shapeId: "",
      shapeType: 0,
      coordinates: "",
      lineColor: "",
      lineWidth: 0,
      paintColor: "",
      paintAlpha: "",
      radius: 0,
      shapeText: "",
      shapeTextSize: 0,
      shapeTextColor: "",
    }

    return newRowData

  }
}

module.exports = ShapeModel;
