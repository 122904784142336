<template>
  <div>
    <div class="card">
      <!-- ヘッダー -->
      <div class="row ml-0">
        <application-header
          :title="appTitle"
          :isDatePicker="false"
          :isNowTime="false"
          :isEditBase="false"
          :isEditVehicle="false"
          :isCreatePlan="false"
          :isDriveLog="false"
          :isOutputCsv="false"
          :isDriveVue="true"
          @driveVue="onClickDriveVue"
        >
        </application-header>
      </div>

      <el-tabs
        v-model="baseAreaMode"
        type="card"
        class="demo-tabs"
        :before-leave="checkLeaveTab"
      >
        <el-tab-pane label="拠点編集" name="0">
          <div class="card-header border-0">
            <div class="row d-flex justify-content-between">
              <div class="col-auto">
                <el-button
                  class=""
                  plain
                  @click="onClickCancel"
                  v-bind:disabled="!editableFlag"
                  >キャンセル</el-button
                >
              </div>
              <div class="col-auto">
                <el-button
                  class=""
                  type="primary"
                  @click="onClickSave"
                  v-bind:disabled="!editableFlag"
                >
                  保 存
                </el-button>
              </div>
            </div>
          </div>

          <!-- 出発地点、到着地点を左右均等に配置 -->
          <div class="row card-header h-100">
            <!-- ##### 出発地点 ##### -->
            <div class="col-md card-header my-0 mr-1 h-100">
              <!-- イメージ、タイトル、ツールメニュー（追加、削除、編集） -->
              <div class="row d-flex justify-content-left align-items-center mb-2">
                <!-- <div id="horizonal-toolbar" class="row  horizonal-toolbar align-items-center" role="toolbar"> -->
                <img v-if="isZando"
                  src="/img/concrete/icon_1r_64.png"
                  style="width: 48px; height: 48px;"
                />
                <img v-else
                  src="/img/concrete/icon_2y_64.png"
                  style="width: 48px; height: 48px;"
                />
                <div class="mt-0 ml-4">
                  <div class="row">
                    <h4 class="mt-1 mb-0 mr-2">{{ isZando ? soilTitleStart : titleStart }}</h4>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilAddStartTitle : addStartTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        @click="onClickAddStartArea"
                        v-show="editableFlag"
                      >
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="!noStartArea"
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilDeleteStartTitle : deleteStartTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        style=""
                        @click="onDeleteStartArea(selectedStartArea)"
                        v-show="editableFlag"
                      >
                        <i class="far fa-trash-alt"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="!noStartArea"
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilEditStartTitle : editStartTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        @click.prevent="onEditStartArea(selectedStartArea)"
                        v-show="editableFlag"
                      >
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>            
              </div>

              <!-- 出発拠点なし警告表示 -->
              <div v-if="noStartArea" class="baseArea h-100">
                <card class="text-center no-area">
                  <h3 class="card-title text-warning mt-4">出発地点未登録</h3>
                  <p class="card-text">
                    [＋] ボタンをクリックし、出発地点を登録してください
                  </p>
                </card>
              </div>
              <!-- 拠点選択 -->
              <div v-if="!noStartArea" class="baseArea h-100">
                <div class="row d-flex align-items-center mb-2">
                  <el-select
                    class="flex-fill"
                    placeholder=""
                    v-model="startAreaId"
                    readonly="true"
                    @change="onChangeStartArea"
                    >
                    <el-option
                      v-for="item in startAreaList"
                      class="select-primary"
                      :value="item.baseArea.id"
                      :label="item.baseArea.areaName"
                      :key="item.baseArea.areaName"
                    >
                    </el-option>
                  </el-select>
                </div>

                <!-- 形状 -->
                <div class="row d-flex justify-content-between">
                  <!-- ############################ -->
                  <!-- 形状選択 -->
                  <div class="d-flex align-items-center my-2">
                    <div class="ml-0 mr-2 mt-2">
                      <h5 class="">形状</h5>
                    </div>
                    <el-select
                      placeholder=""
                      @change="onChangeStartShapeType"
                      v-model="selectStartShapeTypeValue"
                      v-bind:disabled="!editableFlag"
                    >
                      <el-option
                        v-for="item in selectShapeTypeList"
                        class="select-primary"
                        :value="item.name"
                        :label="item.name"
                        :key="item.id"
                      >
                        <span
                          :class="item.icon"
                          style="width: 24px; height: 24px;"
                        ></span>
                        <span class="ml-1">{{ item.name }}</span>
                      </el-option>
                      <template #prefix>
                        <span
                          v-if="selectStartShapeTypeIcon"
                          :class="selectStartShapeTypeIcon"
                          style="display: inline-flex; justify-content: center; align-items: center; width: 24px; height: 24px; color: #606266"
                        ></span>
                      </template>
                    </el-select>
                  </div>

                  <!-- ポリゴン形状パネル -->
                  <div
                    class="d-flex align-items-center my-2"
                    v-if="isShowStartPolygonPanel"
                  >
                    <el-button
                      class="button-menu"
                      size="medium"
                      round
                      @click="onClickCancelStartShapeMode"
                      v-bind:disabled="!editableFlag"
                    >
                      <span class="fas fa-eraser mr-2"></span
                      ><span>作図キャンセル</span>
                    </el-button>
                  </div>

                  <!-- ############################ -->

                  <!-- 円形状パネル -->
                  <div
                    class="d-flex align-items-center my-2"
                    v-if="isShowStartCirclePanel"
                  >
                    <div class="ml-0 mr-2 mt-2">
                      <h5 class="">出発判断半径</h5>
                    </div>
                    <el-input-number
                      ref="startRadius"
                      class="areaRadius"
                      v-model="startRadius"
                      :controls="false"
                      :min="10"
                      :max="999"
                      step-strictly
                      @blur="onBlurStartRadius"
                      @change="onChangeStartRadius"
                      v-bind:disabled="!editableFlag"
                    ></el-input-number>
                    <h5 class="ml-2 mt-1">m</h5>
                  </div>
                </div>

                <!-- 地図(BaseAreaMap.vue) -->
                <div class="row d-flex align-items-stretch">
                  <div
                    class="my-box baseArea-map d-flex flex-fill align-items-stretch"
                  >
                    <base-area-map
                      id="map1"
                      :mapId="mapIdStart"
                      ref="map1"
                      @areaClick="onStartAreaClick"
                      @drawFinish="onStartAreaDrawFinish"
                    ></base-area-map>
                  </div>
                </div>
              </div>
            </div>

            <!-- ##### 到着地点 ##### -->
            <div class="col-md card-header my-0 ml-1 h-100">
              <!-- イメージ、タイトル、ツールメニュー（追加、削除、編集） -->
              <div class="row d-flex justify-content-left align-items-center mb-2">
                <!-- <div class="row d-flex align-items-center mb-4"> -->
                <img v-if="isZando"
                  src="/img/concrete/soil_goal.png"
                  style="width: 48px; height: 48px;"
                />
                <img v-else
                  src="/img/concrete/icon_1r_64.png"
                  style="width: 48px; height: 48px;"
                />

                <div class="mt-0 ml-4">
                  <div class="row">
                    <h4 class="mt-1 mb-0 mr-2">{{ isZando ? soilTitleEnd : titleEnd }}</h4>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilAddEndTitle : addEndTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        @click="onClickAddEndArea"
                        v-show="editableFlag"
                      >
                        <i class="fas fa-plus"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="!noEndArea"
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilDeleteEndTitle :  deleteEndTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        style=""
                        @click="onDeleteEndArea(selectedEndArea)"
                        v-show="editableFlag"
                      >
                        <i class="far fa-trash-alt"></i>
                      </el-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="!noEndArea"
                      class="item"
                      effect="dark"
                      v-bind:content="isZando ? soilEditEndTitle : editEndTitle"
                      placement="bottom"
                    >
                      <el-button
                        class="button-menu"
                        size="medium"
                        circle
                        @click.prevent="onEditEndArea(selectedEndArea)"
                        v-show="editableFlag"
                      >
                        <i class="fas fa-pen"></i>
                      </el-button>
                    </el-tooltip>
                    
                  </div>
                </div>

              </div>
              <!-- </div> -->

              <!-- 到着拠点なし警告表示 -->
              <div v-if="noEndArea" class="baseArea h-100">
                <card class="text-center no-area">
                  <h3 class="card-title text-warning mt-4">到着地点未登録</h3>
                  <p class="card-text">
                    [＋] ボタンをクリックし、到着地点を登録してください
                  </p>
                </card>
              </div>

              <!-- 拠点選択 -->
              <div v-if="!noEndArea" class="baseArea h-100">
                <div class="row d-flex align-items-center mb-2">
                  <el-select
                    class="flex-fill"
                    placeholder=""
                    v-model="endAreaId"
                    readonly="true"
                    @change="onChangeEndArea"
                    >
                    <el-option
                      v-for="item in endAreaList"
                      class="select-primary"
                      :value="item.baseArea.id"
                      :label="item.baseArea.areaName"
                      :key="item.baseArea.areaName"
                    >
                    </el-option>
                  </el-select>
                </div>

                <!-- 形状 -->
                <div class="row d-flex justify-content-between">
                  <!-- ############################ -->
                  <!-- 形状選択 -->
                  <div class="d-flex align-items-center my-2">
                    <div class="ml-0 mr-2 mt-2">
                      <h5 class="">形状</h5>
                    </div>
                    <el-select
                      placeholder=""
                      @change="onChangeEndShapeType"
                      v-model="selectEndShapeTypeValue"
                      v-bind:disabled="!editableFlag"
                    >
                      <el-option
                        v-for="item in selectShapeTypeList"
                        class="select-primary"
                        :value="item.name"
                        :label="item.name"
                        :key="item.id"
                      >
                        <span
                          :class="item.icon"
                          style="width: 24px; height: 24px;"
                        ></span>
                        <span class="ml-1">{{ item.name }}</span>
                      </el-option>
                      <template #prefix>
                        <span
                          v-if="selectEndShapeTypeIcon"
                          :class="selectEndShapeTypeIcon"
                          style="display: inline-flex; justify-content: center; align-items: center; width: 24px; height: 24px; color: #606266"
                        ></span>
                      </template>
                    </el-select>
                  </div>

                  <!-- ポリゴン形状パネル -->
                  <div
                    class="d-flex align-items-center my-2"
                    v-if="isShowEndPolygonPanel"
                  >
                    <el-button
                      class="button-menu"
                      size="medium"
                      round
                      @click="onClickCancelEndShapeMode"
                      v-bind:disabled="!editableFlag"
                    >
                      <span class="fas fa-eraser mr-2"></span
                      ><span>作図キャンセル</span>
                    </el-button>
                  </div>
                  <!-- ############################ -->

                  <!-- 円形状パネル -->
                  <div
                    class="d-flex align-items-center my-2"
                    v-if="isShowEndCirclePanel"
                  >
                    <div class="ml-0 mr-2 mt-2 w-auto">
                      <h5 class="">到着判断半径</h5>
                    </div>
                    <el-input-number
                      ref="endRadius"
                      class="areaRadius"
                      v-model="endRadius"
                      :controls="false"
                      :min="10"
                      :max="999"
                      step-strictly
                      @blur="onBlurEndtRadius"
                      @change="onChangeEndRadius"
                      v-bind:disabled="!editableFlag"
                    ></el-input-number>
                    <h5 class="ml-2 mt-1">m</h5>
                  </div>
                </div>

                <!-- 地図(BaseAreaMap.vue) -->
                <div class="row d-flex align-items-stretch">
                  <div
                    class="my-box baseArea-map d-flex flex-fill align-items-stretch"
                  >
                    <base-area-map
                      id="map2"
                      :mapId="mapIdEnd"
                      class=""
                      ref="map2"
                      @areaClick="onEndAreaClick"
                      @drawFinish="onEndAreaDrawFinish"
                    ></base-area-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- ルート作成 -->
        <el-tab-pane label="ルート編集" name="1">
          <div class="d-flex justify-content-between ml-2 mr-2 mb-2">
            <el-button
              class=""
              plain
              v-if="allowMakeRoute"
              @click="onClickRouteCancel"
              v-bind:disabled="!editableRouteFlag"
              >キャンセル</el-button
            >
            <el-button
              class=""
              v-if="allowMakeRoute"
              type="primary"
              @click="onClickRouteSave"
              v-bind:disabled="!editableFlag"
            >
              保 存
            </el-button>
          </div>
          <div class="d-flex ">
            <div class="d-flex flex-column ml-4 mr-3">
              <div class="d-flex align-items-center">
                <img v-if="isZando"
                  src="/img/concrete/icon_1r_64.png"
                  style="width: 48px; height: 48px;"
                />
                <img v-else 
                  src="/img/concrete/icon_2y_64.png"
                  style="width: 48px; height: 48px;"
                />
                <h4 class="mt-1 mb-0 mr-2">{{ isZando ? soilTitleStart :  titleStart }}</h4>
              </div>
              <div>
                <el-select
                  class="flex-fill mr-2 mb-2"
                  placeholder=""
                  v-model="routeStartAreaId"
                  readonly="true"
                  @change="onChangeRouteStartArea"
                  >
                  <el-option
                    v-for="item in startAreaList"
                    class="select-primary"
                    :value="item.baseArea.id"
                    :label="item.baseArea.areaName"
                    :key="item.baseArea.areaName"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex align-items-end ">
              <div class="d-flex flex-column" style="margin-bottom:12px;">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="往路"
                  placement="right"
                >
                <div class="rounded-pill ouro-info pl-2 pr-2 mb-1 d-flex align-items-center">
                  <i class="fas fa-arrow-right mr-1"></i>
                  <div class="flex-fill ml-1 mr-1 text-right">{{ ouroDistance}}km</div>
                  <i class="fas fa-arrow-right ml-1"></i>
                </div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="復路"
                  placement="left"
                >
                <div class="rounded-pill fukuro-info pl-2 pr-2 d-flex align-items-center">
                  <i class="fas fa-arrow-left mr-1"></i>
                  <div class="flex-fill ml-1 mr-1 text-right">{{ fukuroDistance }}km</div>
                  <i class="fas fa-arrow-left ml-1"></i>
                </div>
                </el-tooltip>
              </div>
            </div>
            <div class="d-flex flex-fill flex-column ml-4">
              <div class="d-flex align-items-center">
                <img v-if="isZando"
                  src="/img/concrete/soil_goal.png"
                  style="width: 48px; height: 48px;"
                />
                <img v-else
                  src="/img/concrete/icon_1r_64.png"
                  style="width: 48px; height: 48px;"
                />
                <h4 class="mt-1 mb-0 mr-2">{{ isZando ? soilTitleEnd : titleEnd }}</h4>  
              </div>
              <div>
                <el-select
                  class="flex-fill mr-2 mb-2"
                  placeholder=""
                  v-model="routeEndAreaId"
                  readonly="true"
                  @change="onChangeRouteEndArea"
                  >
                  <el-option
                    v-for="item in endAreaList"
                    class="select-primary"
                    :value="item.baseArea.id"
                    :label="item.baseArea.areaName"
                    :key="item.baseArea.areaName"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="align-self-end mr-4" style="margin-bottom:16px;">
              <el-button v-if="allowMakeRoute"
                class=""
                plain
                @click="onClickRouteClear"
                v-bind:disabled="!editableFlag"
                >クリア</el-button
              >
            </div>
            <div>
              <div class="d-flex flex-fill flex-column mr-2" v-if="allowMakeRoute">
                <div class="d-flex align-items-center" style="height:48px;">
                  
                  <h4 class="mt-1 mb-0 mr-2" style="">編集するルート</h4>  
                </div>
                <el-radio-group
                  class="mb-2"
                  v-model="roadType"
                  size="large"
                  :fill="'#154CA3'"
                  @change="changeRoadType"
                  v-bind:disabled="!editableFlag"
                >
                  <el-radio-button label="0">往路</el-radio-button>
                  <el-radio-button label="1">復路</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="baseArea-route-map d-flex flex-fill align-items-stretch" style="position:relative;">
              <base-area-route-map
                id="map3"
                :lock=!allowMakeRoute
                :mapId="mapIdRoute"
                class=""
                ref="map3"
                @areaClick="onRouteClick"
                @drawFinish="onRouteDrawFinish"
              ></base-area-route-map>
              
            </div>
          </div>
          
          
        </el-tab-pane>
      </el-tabs>

      

      
    </div>

    <!-- 編集用のモーダル(拠点情報) -->
    <modal v-model:show="modals.editAreaDlg" size="sm" body-classes="p-0" @close="clickCloseModal">
      <template v-slot:header>
        <h6 class="modal-title">{{ modals.dlgTitle }}</h6>
      </template>
      <card
        type="secondary"
        header-classes="bg-transparent pb-5"
        body-classes="px-lg-2 py-lg-2"
        class="border-0 mb-0"
      >
        <div class="col-md-12">
          <base-input type="text" label="名前">
            <template></template>
            <el-input
              v-show="modals.dlgshowAddNew"
              v-model="editAreaName"
              placeholder="出発地点の名前を入力してください"
            ></el-input>
            <el-input
              v-show="!modals.dlgshowAddNew"
              v-model="editAreaName"
              placeholder="到着地点の名前を入力してください"
            ></el-input>
          </base-input>
        </div>
      </card>

      <!-- キャンセル、更新ボタン -->
      <div class="row mt-2 mb-2 ml-2 mr-2">
        <div class="col-sm-5">
          <base-button type="secondary" block @click="onClickCancelModal"
            >キャンセル</base-button
          >
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-5">
          <base-button type="primary" block @click="onClickSubmitModal">{{
            modals.dlgButtonCaption
          }}</base-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */

.el-button--primary {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
}

.no-area {
  color: #000;
  background-color: #ffeab5;
  border-color: #ed6307;
  border-width: 2px;
  height: calc(100% - 30px);
}

.baseArea {
  /* height: calc(100% - 30px); */
  height: 100%;
}

.baseArea-route-map {
  height: calc(100vh - 380px);
  /* height:100%; */
}
.baseArea-map {
  height: calc(460px);
  /* height:100%; */
}

.box {
  position: relative;
  height: 24px;
  width: 24px;
  margin-right: 32px;
}
.box p {
  position: absolute;
  top: -10%;
  left: -10%;
  transform: translate(-10%, -10%);
  -webkit-transform: translate(-10%, -10%);
  -ms-transform: translate(-10%, -10%);
  /* transform: translateY(-35%); */
  /* -webkit-transform: translateY(-35%); */
  /* -ms-transform: translateY(-35%); */
}

.card {
  /* 横スクロールバー非表示対策 */
  overflow-x: hidden;
  border-radius: 0;
}

.horizonal-toolbar {
  left: 0;
  height: 24px;
  width: 100%;
  text-align: left;
  font-size: 0;
}

.button-menu {
  border-style: none;
}

.card-header {
  padding-top: 12px;
  padding-bottom: 12px;
}

.areaRadius {
  width: 80px;
}

.ouro-info {
  font-size: 0.9rem;
  color:white;
  background-color: #FF6F00;
}
.fukuro-info {
  font-size: 0.9rem;
  color: white;
  background-color: #1565C0;
}
</style>

<script>
import awsconfig from "../../aws-exports";
import location from "aws-sdk/clients/location";
import { onBeforeRouteLeave } from "vue-router";
import { Auth } from "aws-amplify";
import { useToast } from "vue-toastification";
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";
import TableToolMenu from "../components/Menu/TableToolMenu";
import appLog from "@/appUtils/AppLog";
import Modal from "@/components/Modal";
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import TenantModel from "@/appModel/Tenant/TenantModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import RouteModel from "@/appModel/Route/RouteModel";
import BaseAreaMap from "@/appViews/BaseArea/BaseAreaMap";
import BaseAreaRouteMap from "@/appViews/BaseArea/BaseAreaRouteMap";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import UserInfo from "@/appUtils/UserInfo";
import { ConsoleLogger } from "@aws-amplify/core";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import settingModel from "@/appModel/settingModel"
import Logger from "@/appViews/common/Logger.js";

// ポリゴン形状ジオフェンスの最大頂点数
const MAX_POLYGON_VERTEX_COUNT = 100;

// 図形種別: 円
const ID_SHAPE_TYPE_CIRCLE = "0";

// 図形種別: ポリゴン
const ID_SHAPE_TYPE_POLYGON = "1";

// 図形種別: 円リストインデックス
const INDEX_SHAPE_TYPE_CIRCLE = 1;

// 図形種別: ポリゴンリストインデックス
const INDEX_SHAPE_TYPE_POLYGON = 0;

export default {
  components: {
    BaseAreaMap,
    BaseAreaRouteMap,
    ApplicationHeader,
    //TableToolMenu,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      projectType: "0", // 0:生コン、1：残土
      mapIdStart: "map1", // 出発地地図用
      mapIdEnd: "map2", // 到着地地図用
      mapIdRoute: "map3",
      credentials: null,
      projectId: null,
      projectName: null,
      datestring: null,
      noStartArea: false, // 出発地データ有無により画面表示切り替え用
      noEndArea: false, // 到着地データ有無により画面表示切り替え用
      startAreaId: null,
      startRadius: 200,
      endAreaId: null,
      endRadius: 200,
      routeStartAreaId: null,
      routeEndAreaId: null,
      editAreaName: null,
      editBeforeStartAreaList: [],
      editBeforeEndAreaList: [],
      startAreaList: [],
      endAreaList: [],
      selectedStartArea: null,
      selectedEndArea: null,
      deleteStartAreaList: [],
      deleteEndAreaList: [],
      currentRouteData: null,
      routeDatas: {},
      routeModified: false,
      appTitle: "拠点編集",
      titleStart: "出発地点（生コン工場）",
      titleEnd: "到着地点（現場）",
      soilTitleStart: "出発地点（現場）",
      soilTitleEnd: "到着地点（土捨場）",
      addStartTitle: `出発地点（生コン工場）を追加`,
      editStartTitle: `出発地点（生コン工場）を編集`,
      deleteStartTitle: `出発地点（生コン工場）を削除`,
      addEndTitle: `到着地点（現場）を追加`,
      editEndTitle: `到着地点（現場）を編集`,
      deleteEndTitle: `到着地点（現場）を削除`,
      soilAddStartTitle: `出発地点（現場）を追加`,
      soilEditStartTitle: `出発地点（現場）を編集`,
      soilDeleteStartTitle: `出発地点（現場）を削除`,
      soilAddEndTitle: `到着地点（土捨場）を追加`,
      soilEditEndTitle: `到着地点（土捨場）を編集`,
      soilDeleteEndTitle: `到着地点（土捨場）を削除`,
      modals: {
        editAreaDlg: false,
        dlgEditMode: 0, // 0:出発地、登録 1:到着地、登録 2:出発地、更新 3:到着地、更新
        dlgTitle: null,
        dlgshowAddNew: true,
        dlgButtonCaption: "",
      },
      userStore: null,
      baseAreaNameLengh: 50,
      userAuthority: 1,
      editableFlag: true,
      editableRouteFlag: true,
      repeatedHitsFlg: false, // 連打防止フラグ （拠点編集画面等へ遷移する際に使用）
      tenantId: "",
      geofenceCollectionId: "",
      // 形状選択項目リスト
      selectShapeTypeList: [
        {
          id: ID_SHAPE_TYPE_POLYGON,
          name: "ポリゴン（複数点で構成）",
          icon: "fas fa-draw-polygon",
        },
        {
          id: ID_SHAPE_TYPE_CIRCLE,
          name: "円（中心指定）",
          icon: "far fa-circle",
        },
      ],
      // 出発地点: 選択されている形状値
      selectStartShapeTypeValue: null,
      // 出発地点: 選択されている形状アイコン
      selectStartShapeTypeIcon: null,
      // 出発地点: 円パネルを表示するかどうか
      isShowStartCirclePanel: false,
      // 出発地点: ポリゴンパネルを表示するかどうか
      isShowStartPolygonPanel: false,
      // 到着地点: 選択されている形状値
      selectEndShapeTypeValue: null,
      // 到着地点: 選択されている形状アイコン
      selectEndShapeTypeIcon: null,
      // 到着地点: 円パネルを表示するかどうか
      isShowEndCirclePanel: false,
      // 到着地点: ポリゴンパネルを表示するかどうか
      isShowEndPolygonPanel: false,
      baseAreaMode: "0",
      roadType: "0",
      ouroDistance: 0.00,
      fukuroDistance: 0.00,
    };
  },

  // コンピュートプロパティ
  computed: {
    allowMakeRoute() {
      return (this.routeStartAreaId && this.routeEndAreaId)
    },
    vuename() {
      return "BaseArea.vue"
    }
  },
  watch: {
    routeDatas: {
      handler: function () {
        console.log(`routeModified watch routeDatas`)
        this.routeModified = true
      },
      deep: true
    }
  },
  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  async created() {
    // インスタンスが生成され､且つデータが初期化された後
    this.infoLog(
      `Start Vuex：projectid(${this.$store.state.basearea.projectid})、projectname(${this.$store.state.basearea.projectname})、datestring(${this.$store.state.basearea.datestring})`
    );

    await this.init();
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    // const targetElement = this.$refs.endRadius;
    // console.log(targetElement)
    // const fullHeight = this.$el.clientHeight + 'px';
    // console.log(`fullHeight:${fullHeight}`)
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
    this.dbData = null;
  },

  setup() {
    // same as beforeRouteLeave option with no access to `this`
    // onBeforeRouteLeave((to, from) => {
    //   const answer = window.confirm(
    //     'Do you really want to leave? you have unsaved changes!'
    //   )
    //   // cancel the navigation and stay on the same page
    //   if (!answer) return false
    // })
  },

  // vue3以前の記法
  beforeRouteLeave(to, from, next) {
    // Vue Rtouterでのページ離脱時
    if (this.confirm() === false) return;

    // モーダルを非表示
    this.modals.editAreaDlg = false;

    this.infoLog(`End`);

    next();
  },

  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * ページ離脱時の、処理
     */
    confirm() {
      // 変更の有無をチェック
      if (this.isChange() == true) {
        this.repeatedHitsFlg = false;

        return window.confirm(
          "編集中の拠点が保存されていません。ページを離れてもよろしいですか？"
        );
      } else {
        return true;
      }
    },

    /**
     * 初期化処理
     */
    async init() {
      let loader = this.showLoader();

      try {
        
        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        } 
        
        // store(vuex)から値取得
        this.projectId = this.$store.state.basearea.projectid;
        this.datestring = this.$store.state.basearea.datestring;
        this.projectName = this.$store.state.basearea.projectname;

        // ProjectIDがセットされているかチェック
        if (this.projectId == null || this.projectId == "") {
          this.showBottomToast(
            `拠点編集画面表示処理に失敗しました。（プロジェクトIDが不正）`,
            "error"
          ).then(() => {});

          this.$router.push({
            path: "/projects",
          });
        }

        // ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        this.userAuthority = this.$store.state.user.userType;

        if (this.userAuthority == 0) {
          this.editableFlag = false;
        }

        // aws location関連初期化
        this.credentials = await Auth.currentCredentials();
        // console.log(this.credentials);
        // console.log(awsconfig.aws_project_region);

        this.locationService = new location({
          credentials: this.credentials,
          region: awsconfig.aws_project_region,
        });

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        // 出発地、到着地取得
        await this.initBaseArea();

        // 地図設定（0件の場合は設定しない）
        this.$nextTick(function() {
          if (!this.noStartArea) {
            this.$refs.map1.mapCreate();
          }

          if (!this.noEndArea) {
            this.$refs.map2.mapCreate();
          }

          // 出発地、到着地アイコン、ジオフェンス描画
          this.drawBaseArea();

        }.bind(this))
        
        // ジオフェンスコレクションIDを取得
        const loginInfo = await UserInfo.getUserInfo();

        if (!loginInfo.group) {
          this.showBottomToast(
            `ユーザー情報の取得に失敗しました。`,
            "error"
          ).then(() => {
            this.$router.push({
              path: "/projects",
            });
          });
        }

        // console.log(`getList:${loginInfo.group}`)
        const tenantId = loginInfo.group;
        let tenant = await TenantModel.getTenant(tenantId);
        this.geofenceCollectionId = tenant.tenant.geofenceCollectionId;

        if (!this.geofenceCollectionId) {
          this.geofenceCollectionId =
            process.env.VUE_APP_GEOFENCE_COLLECTION_NAME;
        }

        console.log(`${this.geofenceCollectionId}`);

        // 連打防止
        this.repeatedHitsFlg = false;

        // // Test
        // await this.sampleGetGeofance();
      } catch (e) {
        this.showBottomToast(`初期表示処理でエラーが発生しました。`, "error");
        this.errorLog("init", this.parseErrorObject(e))
        throw e
      } finally {
        this.hideLoader(loader);
      }
    },
    removeAllGeofence() {
      // 描画済みのジオフェンスをすべて削除(2重描画にならないよう)
      let geofenceIdlist = []
      for (let area of this.startAreaList) {
        geofenceIdlist.push(area.baseArea.id)
      }
      for (let area of this.endAreaList) {
        geofenceIdlist.push(area.baseArea.id)
      }
      this.$refs[this.mapIdRoute].deleteShape(geofenceIdlist)
      // アイコン削除
      this.$refs[this.mapIdRoute].deletePoi(geofenceIdlist)      
    },
    async initRoute() {
      
      try {
        console.log(`initRoute`)
        this.$refs[this.mapIdRoute].mapCreate();
        // 描画しているジオフェンス、アイコンをすべてクリア
        this.removeAllGeofence()

        // 拠点を描画
        this.drawBaseAreaForRoute()
        // ルート情報を取得
        this.routeDatas = await this.getRouteDatas()
        
        console.log(`routeModified: ${this.routeModified }`)
        setTimeout(function() {
          this.$refs[this.mapIdRoute].fitBoundsBasePointList()
          // 選択中の拠点間のルートを描画
          this.drawRouteOfCurentArea()
          this.routeModified = false
        }.bind(this), 10);
      } catch (e) {
        console.log(e)
        
      }
    },
    async getRouteDatas() {
      this.currentRouteData = null
      let routeDatas = {}
      // プロジェクトに紐づくデータをすべて取得
      let routeList = await RouteModel.getRouteList(this.projectId)
      if (routeList.length > 0) {
        console.log(`routeList: ${routeList.length}`)
        for (let i = 0; i < routeList.length;i++) {
          let item = routeList[i]
          let skList = item.sk.split("#")
          let startAreaId = skList[2]
          let endAreaId = skList[3]
          let roadType = item.route.roadType
          // 変更フラグとセットで取得する
          routeDatas[`${startAreaId}#${endAreaId}#${roadType}`] = {
            modified: false,
            isNew: false,
            data: item
          }
        } 
        return routeDatas
      }
      return {}
    },

    /**
     * 拠点情報データ取得後、出発地と到着地に振り分けます
     */
    async initBaseArea() {
      try {
        // console.log('projectId:' + this.projectId);

        // DBから拠点情報のレコード取得
        const baseAreaList = await BaseAreaModel.getBaseAreaList(
          this.projectId
        );

        // Polygon描画機能追加前の既存円は
        // shapeTypeとgeofenceがない場合があるのでここで設定しておく
        baseAreaList.forEach((item) => {
          // 図形種別
          item.baseArea.shapeType = this.normalizeShapeType(
            item.baseArea.shapeType
          );

          // ジオフェンス座標圧縮文字列
          if (!item.baseArea.geofence) {
            item.baseArea.geofence = this.getCompressCoordsText(
              item.baseArea,
              null
            );
          }
        });

        const baseAreas = [];
        for (let i = 0; i < baseAreaList.length; i++) {
          baseAreas.push(baseAreaList[i]);
        }

        // 出発地
        this.startAreaList = baseAreas
          .filter((list) => list.baseArea.areaType === 1)
          .slice()
          .sort(function(a, b) {
            if (a.baseArea.id < b.baseArea.id) return -1;
            if (a.baseArea.id > b.baseArea.id) return 1;
          });

        // 到着地
        this.endAreaList = baseAreas
          .filter((list) => list.baseArea.areaType === 2)
          .slice()
          .sort(function(a, b) {
            if (a.baseArea.id < b.baseArea.id) return -1;
            if (a.baseArea.id > b.baseArea.id) return 1;
          });

        // 画面の制御
        if (this.startAreaList.length < 1) {
          this.noStartArea = true;
        }

        if (this.endAreaList.length < 1) {
          this.noEndArea = true;
        }

        // 変更前の情報を保持
        this.editBeforeStartAreaList = JSON.parse(
          JSON.stringify(this.startAreaList)
        );

        this.editBeforeEndAreaList = JSON.parse(
          JSON.stringify(this.endAreaList)
        );

        // console.log(`出発地点数：${this.startAreaList.length} 到着地点数：${this.endAreaList.length}`);
      } catch (e) {
        this.showBottomToast(`拠点情報取得でエラーが発生しました。`, "error");
        this.errorLog("initBaseArea", this.parseErrorObject(e))


        throw e;
      }
    },

    /**
     * 正常なジオフェンス図形種別を取得します。
     * 図形種別が設定されていない場合は円の図形種別を返します。
     * @param {string} shapeType 図形種別
     * @returns {string} 図形種別
     */
    normalizeShapeType(shapeType) {
      // 設定されていない場合は円をデフォルト
      if (!shapeType) {
        return ID_SHAPE_TYPE_CIRCLE;
      }

      return shapeType;
    },

    /**
     * 拠点ジオフェンスの座標圧縮文字列を取得します。
     * @param {Object} baseArea 拠点情報
     * @param {Object} polygon Leaflet Polygon Layer(拠点形状がポリゴンの場合のみ)
     */
    getCompressCoordsText(baseArea, polygon) {
      // 設定されていない場合は円をデフォルト
      let shapeType = this.normalizeShapeType(baseArea.shapeType);

      let latlngs;

      if (shapeType == ID_SHAPE_TYPE_CIRCLE) {
        // 中心座標、半径から、ポリゴン座標を取得
        latlngs = CoordinateUtil.getCirclePolygonPoints(
          new L.LatLng(baseArea.y, baseArea.x),
          baseArea.radius
        );
      } else {
        // 外形の座標のみ
        latlngs = polygon.getLatLngs()[0];
      }

      // ポリゴン座標を座標圧縮文字列にする
      return MLCompressUtil.latLngArrayToBase64(latlngs);
    },
    getCompressRoute(polygon) {
      // 外形の座標のみ
      let latlngs = polygon.getLatLngs();
      return MLCompressUtil.latLngArrayToBase64(latlngs);
    },

    /**
     * 起動時の地点描画処理をします
     */
    drawBaseArea() {
      // 出発地
      if (this.startAreaList.length > 0) {
        let startArea = this.startAreaList[0];

        // 出発地セレクトボックスの1つめをカレントにする
        this.startAreaId = startArea.baseArea.id;
        this.startRadius = startArea.baseArea.radius;
        this.selectedStartArea = startArea;

        // 描画形状UI
        let isStartBaseArea = true;
        this.setupShapeTypeUI(startArea.baseArea.shapeType, isStartBaseArea);

        // 描画用パラメータセット
        let parameters = this.getDrawParameters(startArea.baseArea);

        // 出発地描画、地図移動
        this.$refs.map1.moveMapAndDrawFigure(parameters);
      }

      // 到達地
      if (this.endAreaList.length > 0) {
        let endArea = this.endAreaList[0];

        // 到着地セレクトボックスの1つめをカレントにする
        this.endAreaId = endArea.baseArea.id;
        this.endRadius = endArea.baseArea.radius;
        this.selectedEndArea = endArea;

        // 描画形状UI
        let isStartBaseArea = false;
        this.setupShapeTypeUI(endArea.baseArea.shapeType, isStartBaseArea);

        // 描画用パラメータセット
        let parameters = this.getDrawParameters(endArea.baseArea);

        // 到着地描画、地図移動
        this.$refs.map2.moveMapAndDrawFigure(parameters);
      }
    },

    drawBaseAreaForRoute() {
      // 出発地
      if (this.startAreaList.length > 0) {
        let startArea = this.startAreaList[0];

        // 出発地セレクトボックスの1つめをカレントにする
        this.routeStartAreaId = startArea.baseArea.id;
        this.startRadius = startArea.baseArea.radius;
        this.selectedStartArea = startArea;

        // 描画形状UI
        let isStartBaseArea = true;
        this.setupShapeTypeUI(startArea.baseArea.shapeType, isStartBaseArea);

        // 描画用パラメータセット
        let parameters = this.getDrawParameters(startArea.baseArea);

        // 出発地描画、地図移動
        this.$refs[this.mapIdRoute].moveMapAndDrawFigure(parameters);

        
      }

      // 到達地
      if (this.endAreaList.length > 0) {
        let endArea = this.endAreaList[0];

        // 到着地セレクトボックスの1つめをカレントにする
        this.routeEndAreaId = endArea.baseArea.id;
        this.endRadius = endArea.baseArea.radius;
        this.selectedEndArea = endArea;

        // 描画形状UI
        let isStartBaseArea = false;
        this.setupShapeTypeUI(endArea.baseArea.shapeType, isStartBaseArea);

        // 描画用パラメータセット
        let parameters = this.getDrawParameters(endArea.baseArea);

        // 到着地描画、地図移動
        this.$refs[this.mapIdRoute].moveMapAndDrawFigure(parameters);
      }
    },

    /**
     * ジオフェンス形状選択UIをセットアップします。
     * @param {string} shapeType ジオフェンス図形種別
     * @param {boolean} isStartBaseArea 開始地点かどうか
     */
    setupShapeTypeUI(shapeType, isStartBaseArea) {
      if (shapeType == ID_SHAPE_TYPE_CIRCLE) {
        // 円
        let circleType = this.selectShapeTypeList[INDEX_SHAPE_TYPE_CIRCLE];
        let map;

        if (isStartBaseArea) {
          // 出発地点
          this.isShowStartCirclePanel = true;
          this.isShowStartPolygonPanel = false;
          this.selectStartShapeTypeValue = circleType.name;
          this.selectStartShapeTypeIcon = circleType.icon;

          map = this.$refs.map1;
        } else {
          // 到着地点
          this.isShowEndCirclePanel = true;
          this.isShowEndPolygonPanel = false;
          this.selectEndShapeTypeValue = circleType.name;
          this.selectEndShapeTypeIcon = circleType.icon;

          map = this.$refs.map2;
        }

        map.stopDrawShapeMode();
      } else {
        // ポリゴン
        let polygonType = this.selectShapeTypeList[INDEX_SHAPE_TYPE_POLYGON];
        let map;

        if (isStartBaseArea) {
          // 出発地点
          this.isShowStartCirclePanel = false;
          this.isShowStartPolygonPanel = true;
          this.selectStartShapeTypeValue = polygonType.name;
          this.selectStartShapeTypeIcon = polygonType.icon;

          map = this.$refs.map1;
        } else {
          // 到着地点
          this.isShowEndCirclePanel = false;
          this.isShowEndPolygonPanel = true;
          this.selectEndShapeTypeValue = polygonType.name;
          this.selectEndShapeTypeIcon = polygonType.icon;

          map = this.$refs.map2;
        }

        this.startDrawPolygonMode(map);
      }
    },

    /**
     * ポリゴン作図モードを開始します。
     * @param {Object} map 対象拠点のBaseAreaMap
     */
    startDrawPolygonMode(map) {
      // 一般ユーザーの時は処理を抜ける
      if (!this.editableFlag) {
        return;
      }

      map.startDrawPolygonMode();
    },

    /**
     * 作図をキャンセルします。
     * (ポリゴン描画中の状態をポリゴン描画開始状態へ)
     * @param {Object} map 対象拠点のBaseAreaMap
     */
    cancelEndShapeMode(map) {
      map.stopDrawShapeMode();
      this.startDrawPolygonMode(map);
    },

    /**
     * ジオフェンス形状名称からジオフェンス形状選択項目を取得します。
     * @param {string} name ジオフェンス形状名称
     * @returns {Object} ジオフェンス形状選択項目
     */
    getShapeTypeItemByName(name) {
      return this.selectShapeTypeList.filter((list) => list.name == name);
    },

    /**
     * 現在選択されているジオフェンス図形種別を取得します。
     * @param {boolean} isStartBaseArea 開始地点かどうか
     * @returns {string} ジオフェンス図形種別
     */
    currentShapeType(isStartBaseArea) {
      let typeValue = isStartBaseArea
        ? this.selectStartShapeTypeValue
        : this.selectEndShapeTypeValue;

      // 選択名称から形状選択項目を取得
      let list = this.getShapeTypeItemByName(typeValue);

      if (list.length < 1) {
        return ID_SHAPE_TYPE_CIRCLE;
      }

      return list[0].id;
    },

    /**
     * 拠点ジオフェンス描画パラメータを取得します。
     * @param {Objecct} baseArea 描画する拠点情報
     */
    getDrawParameters(baseArea) {
      // 描画用パラメータセット
      let parameters = {
        id: baseArea.id,
        shapeType: baseArea.shapeType,
        x: baseArea.x,
        y: baseArea.y,
        radius: baseArea.radius,
        geofence: baseArea.geofence,
      };

      return parameters;
    },
    getRouteDrawParameters(route) {
      // 描画用パラメータセット
      let parameters = {
        routeId: route.routeId,
        routeGeometry: route.routeGeometry,
        roadType: route.roadType
      };

      return parameters;
    },
    /**
     * 出発地点
     * 拠点セレクトボックス変更イベント
     */
    onChangeStartArea(value) {
      // カレント行セット
      for (let i = 0; i < this.startAreaList.length; i++) {
        if (this.startAreaList[i].baseArea.id == value) {
          this.selectedStartArea = this.startAreaList[i];
          break;
        }
      }

      // 半径セット
      this.startRadius = this.selectedStartArea.baseArea.radius;

      // 描画形状UI
      let isStartBaseArea = true;
      this.setupShapeTypeUI(
        this.selectedStartArea.baseArea.shapeType,
        isStartBaseArea
      );

      // 出発地描画用パラメータ生成
      let parameters = this.getDrawParameters(this.selectedStartArea.baseArea);

      // 出発地描画
      this.$refs.map1.moveMapAndDrawFigure(parameters);
    },

    /**
     * 到着地点
     * 拠点セレクトボックス変更イベント
     */
    onChangeEndArea(value) {
      // カレント行セット
      for (let i = 0; i < this.endAreaList.length; i++) {
        if (this.endAreaList[i].baseArea.id == value) {
          this.selectedEndArea = this.endAreaList[i];
          break;
        }
      }

      // 半径セット
      this.endRadius = this.selectedEndArea.baseArea.radius;

      // 描画形状UI
      let isStartBaseArea = false;
      this.setupShapeTypeUI(
        this.selectedEndArea.baseArea.shapeType,
        isStartBaseArea
      );

      // 出発地描画用パラメータ生成
      let parameters = this.getDrawParameters(this.selectedEndArea.baseArea);

      // 出発地描画
      this.$refs.map2.moveMapAndDrawFigure(parameters);
    },
    /**
     * 選択中の拠点のジオフェンスを描画する。ルート編集時用野処理
     */
    drawAreaGeofenceForRoute() {
      let startArea = null
      let endArea = null
      let deleteAreaIdList = []

      // 工場側の拠点情報取得
      if (this.startAreaList.length > 0) {
        for (let i = 0; i < this.startAreaList.length; i++) {
          if (this.startAreaList[i].baseArea.id == this.routeStartAreaId) {
            startArea = this.startAreaList[i].baseArea;
          }
          // 全削除後に描画するためIDを確保
          deleteAreaIdList.push(this.startAreaList[i].baseArea.id)
        }
      }
      
      // 現場側の拠点情報取得
      if (this.endAreaList.length) {
        for (let i = 0; i < this.endAreaList.length; i++) {
          if (this.endAreaList[i].baseArea.id == this.routeEndAreaId) {
            endArea = this.endAreaList[i].baseArea;
          }
          // 全削除後に描画するためIDを確保
          deleteAreaIdList.push(this.endAreaList[i].baseArea.id)
        }
      }
      // ジオフェンス削除
      if (deleteAreaIdList.length > 0) {
        this.$refs[this.mapIdRoute].deleteShape(deleteAreaIdList)
        this.$refs[this.mapIdRoute].deletePoi(deleteAreaIdList)
      }
      if (startArea) {
        // 出発地描画
        this.$refs[this.mapIdRoute].moveMapAndDrawFigure(this.getDrawParameters(startArea));
      }
      if (endArea) {
        // 到着地描画
        this.$refs[this.mapIdRoute].moveMapAndDrawFigure(this.getDrawParameters(endArea));
      }

    },
    /**
     * 選択中の工場、現場でルートを描画
     */
    drawRouteOfCurentArea() {
      
      // 往路
      let key1 = `${this.routeStartAreaId}#${this.routeEndAreaId}#0`
      // 復路
      let key2 = `${this.routeStartAreaId}#${this.routeEndAreaId}#1`
      let routeData1 = this.routeDatas[key1]
      let routeData2 = this.routeDatas[key2]

      // 描画中のルート削除
      let idList = []
      let routeDataKeys = Object.keys(this.routeDatas)
      for (let key of routeDataKeys) {
        idList.push(this.routeDatas[key].data.route.routeId)
      }
      console.log(`onChangeRouteStartArea idList: ${JSON.stringify(idList)}`)
      // IDリスト分一旦削除
      this.$refs[this.mapIdRoute].deleteShape(idList)
      // 距離削除
      this.ouroDistance = 0.00
      this.fukuroDistance = 0.00
      if (routeData1) {
        // 往路ルート描画用パラメータ生成
        let parameters = this.getRouteDrawParameters(routeData1.data.route); 
        // 往路描画
        this.$refs[this.mapIdRoute].drawRoute(parameters);
        // 距離
        this.ouroDistance = Number(routeData1.data.route.routeDistance/1000).toFixed(2)
      }
      if (routeData2) {
        // 復路ルート描画用パラメータ生成
        let parameters = this.getRouteDrawParameters(routeData2.data.route); 
        // 復路描画
        this.$refs[this.mapIdRoute].drawRoute(parameters);
        // 距離
        this.fukuroDistance = Number(routeData2.data.route.routeDistance/1000).toFixed(2)
      }
    },
    /**
     * 出発地点
     * 拠点セレクトボックス変更イベント
     */
    onChangeRouteStartArea(value) {
      // 選択した拠点のジオフェンス描画
      this.drawAreaGeofenceForRoute()
      // 対象のルートを描画
      this.drawRouteOfCurentArea()
      // 表示範囲調整
      this.$nextTick(function() {
        this.$refs[this.mapIdRoute].fitBoundsBasePointList()
      }.bind(this))
      
    },

    /**
     * 到着地点
     * 拠点セレクトボックス変更イベント
     */
    onChangeRouteEndArea(value) {
      // 選択した拠点のジオフェンス描画
      this.drawAreaGeofenceForRoute()
      // 対象のルートを描画
      this.drawRouteOfCurentArea()
      // 表示範囲調整
      this.$nextTick(function() {
        this.$refs[this.mapIdRoute].fitBoundsBasePointList()
      }.bind(this))
    },

    /**
     * 出発地点
     * 形状セレクトボックス変更
     */
    onChangeStartShapeType() {
      console.log(this.selectStartShapeTypeValue);

      // 選択名称から形状選択項目を取得
      let list = this.getShapeTypeItemByName(this.selectStartShapeTypeValue);

      if (list.length < 1) {
        return;
      }

      // 選択リストアイコン
      this.selectStartShapeTypeIcon = list[0].icon;

      let map = this.$refs.map1;

      if (list[0].id == ID_SHAPE_TYPE_CIRCLE) {
        // 円
        this.isShowStartCirclePanel = true;
        this.isShowStartPolygonPanel = false;

        map.stopDrawShapeMode();
      } else {
        // ポリゴン
        this.isShowStartCirclePanel = false;
        this.isShowStartPolygonPanel = true;

        this.startDrawPolygonMode(map);
      }
    },

    /**
     * 到着地点
     * 形状セレクトボックス変更
     */
    onChangeEndShapeType() {
      console.log(this.selectEndShapeTypeValue);

      // 選択名称から形状選択項目を取得
      let list = this.getShapeTypeItemByName(this.selectEndShapeTypeValue);

      if (list.length < 1) {
        return;
      }

      // 選択リストアイコン
      this.selectEndShapeTypeIcon = list[0].icon;

      let map = this.$refs.map2;

      if (list[0].id == ID_SHAPE_TYPE_CIRCLE) {
        // 円
        this.isShowEndCirclePanel = true;
        this.isShowEndPolygonPanel = false;

        map.stopDrawShapeMode();
      } else {
        // ポリゴン
        this.isShowEndCirclePanel = false;
        this.isShowEndPolygonPanel = true;

        this.startDrawPolygonMode(map);
      }
    },

    /**
     * 出発地点
     * 半径設定テキストボックスblurイベント
     */
    onBlurStartRadius() {
      console.log(`onBlurStartRadius: ${this.startRadius}`);

      // 空の場合は初期値200をセット
      if (this.startRadius == "" || this.startRadius == null) {
        this.startRadius = 200;
      }

      // 半径
      this.selectedStartArea.baseArea.radius = this.startRadius;

      // ジオフェンス座標圧縮文字列
      this.selectedStartArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedStartArea.baseArea,
        null
      );

      // 出発値を再描画するためのパラメータセット
      let parameters = this.getDrawParameters(this.selectedStartArea.baseArea);

      // 地図再描画
      this.$refs.map1.moveMapAndDrawFigure(parameters);

      // // 半径をセット
      // this.selectedStartArea.baseArea.radius = this.startRadius;
    },

    /**
     * 到着地点
     * 半径設定テキストボックスblurイベント
     */
    onBlurEndtRadius() {
      // 空の場合は初期値200をセット
      if (this.endRadius == "" || this.endRadius == null) {
        this.endRadius = 200;
      }

      // 半径
      this.selectedEndArea.baseArea.radius = this.endRadius;

      // ジオフェンス座標圧縮文字列
      this.selectedEndArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedEndArea.baseArea,
        null
      );

      // 到着値を再描画するためのパラメータセット
      let parameters = this.getDrawParameters(this.selectedEndArea.baseArea);

      // 地図再描画
      this.$refs.map2.moveMapAndDrawFigure(parameters);

      // // 半径をセット
      // this.selectedEndArea.baseArea.radius = this.endRadius;
    },

    /**
     * 出発地点
     * 半径設定テキストボックスchangeイベント
     */
    onChangeStartRadius() {
      console.log(`onChangeStartRadius: ${this.startRadius}`);

      // 空の場合は初期値200をセット
      if (this.startRadius == "" || this.startRadius == null) {
        this.startRadius = 200;
      }
    },

    /**
     * 到着地点
     * 半径設定テキストボックスchangeイベント
     */
    onChangeEndRadius() {
      // 空の場合は初期値200をセット
      if (this.endRadius == "" || this.endRadius == null) {
        this.endRadius = 200;
      }
    },

    /**
     * 出発地点
     * 作図キャンセルクリック
     */
    onClickCancelStartShapeMode() {
      this.cancelEndShapeMode(this.$refs.map1);
    },

    /**
     * 到着地点
     * 作図キャンセルクリック
     */
    onClickCancelEndShapeMode() {
      this.cancelEndShapeMode(this.$refs.map2);
    },

    /**
     * 出発地点
     * BaseAreaMap.vueの地図クリックイベント
     */
    onStartAreaClick(params) {
      console.log(`onStartAreaClick: ${params.id}, ${params.x}, ${params.y}`);

      // 一般ユーザーの時は処理を抜ける
      if (this.editableFlag == false) {
        return;
      }

      // 円作図でない場合は何もしない
      let isStartBaseArea = true;
      if (this.currentShapeType(isStartBaseArea) != ID_SHAPE_TYPE_CIRCLE) {
        return;
      }

      // 更新
      this.selectedStartArea.baseArea.shapeType = ID_SHAPE_TYPE_CIRCLE;
      this.selectedStartArea.baseArea.x = params.x;
      this.selectedStartArea.baseArea.y = params.y;
      this.selectedStartArea.baseArea.radius = this.startRadius;
      this.selectedStartArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedStartArea.baseArea,
        null
      );

      // パラメータ生成
      let parameters = this.getDrawParameters(this.selectedStartArea.baseArea);

      // 地図描画
      this.$refs.map1.moveMapAndDrawFigure(parameters);
    },

    /**
     * 到着地点
     * BaseAreaMap.vueの地図クリックイベント
     */
    onEndAreaClick(params) {
      console.log(`onEndAreaClick: ${params.id}, ${params.x}, ${params.y}`);

      // 一般ユーザーの時は処理を抜ける
      if (this.editableFlag == false) {
        return;
      }

      // 円作図でない場合は何もしない
      let isStartBaseArea = false;
      if (this.currentShapeType(isStartBaseArea) != ID_SHAPE_TYPE_CIRCLE) {
        return;
      }

      // 更新
      this.selectedEndArea.baseArea.shapeType = ID_SHAPE_TYPE_CIRCLE;
      this.selectedEndArea.baseArea.x = params.x;
      this.selectedEndArea.baseArea.y = params.y;
      this.selectedEndArea.baseArea.radius = this.endRadius;
      this.selectedEndArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedEndArea.baseArea,
        null
      );

      // パラメータ生成
      let parameters = this.getDrawParameters(this.selectedEndArea.baseArea);

      // 地図描画
      this.$refs.map2.moveMapAndDrawFigure(parameters);
    },

    /**
     * 出発地点
     * BaseAreaMap.vueの作図完了イベント
     * @param {*} params イベントデータ
     */
    onStartAreaDrawFinish(params) {
      console.log(`onStartAreaDrawFinish: ${params.id}`);

      // 一般ユーザーの時は処理を抜ける
      if (!this.editableFlag) {
        return;
      }

      // 頂点数チェック
      let vertexCount = params.layer.getLatLngs()[0].length;

      if (MAX_POLYGON_VERTEX_COUNT < vertexCount) {
        this.showBottomToast(
          `ポリゴンの頂点数は ${MAX_POLYGON_VERTEX_COUNT} までです。`,
          "warning"
        );
        return;
      }

      // 面上にある点を取得
      let p = CoordinateUtil.pointOnSurface(params.layer);

      // 更新
      this.selectedStartArea.baseArea.shapeType = ID_SHAPE_TYPE_POLYGON;
      this.selectedStartArea.baseArea.x = p[0];
      this.selectedStartArea.baseArea.y = p[1];
      this.selectedStartArea.baseArea.radius = 0;
      this.selectedStartArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedStartArea.baseArea,
        params.layer
      );

      // パラメータ生成
      let parameters = this.getDrawParameters(this.selectedStartArea.baseArea);

      // 地図描画
      this.$refs.map1.moveMapAndDrawFigure(parameters);
    },

    /**
     * 到着地点
     * BaseAreaMap.vueの作図完了イベント
     * @param {*} params イベントデータ
     */
    onEndAreaDrawFinish(params) {
      console.log(`onEndAreaDrawFinish: ${params.id}`);

      // 一般ユーザーの時は処理を抜ける
      if (!this.editableFlag) {
        return;
      }

      // 頂点数チェック
      let vertexCount = params.layer.getLatLngs()[0].length;

      if (MAX_POLYGON_VERTEX_COUNT < vertexCount) {
        this.showBottomToast(
          `ポリゴンの頂点数は ${MAX_POLYGON_VERTEX_COUNT} までです。`,
          "warning"
        );
        return;
      }

      // 面上にある点を取得
      let p = CoordinateUtil.pointOnSurface(params.layer);

      // 更新
      this.selectedEndArea.baseArea.shapeType = ID_SHAPE_TYPE_POLYGON;
      this.selectedEndArea.baseArea.x = p[0];
      this.selectedEndArea.baseArea.y = p[1];
      this.selectedEndArea.baseArea.radius = 0;
      this.selectedEndArea.baseArea.geofence = this.getCompressCoordsText(
        this.selectedEndArea.baseArea,
        params.layer
      );

      // パラメータ生成
      let parameters = this.getDrawParameters(this.selectedEndArea.baseArea);

      // 地図描画
      this.$refs.map2.moveMapAndDrawFigure(parameters);
    },
    async onClickRouteClear() {
      // 描画ストップ
      this.$refs[this.mapIdRoute].stopDrawShapeMode()
      // 一般ユーザーの時は処理を抜ける
      if (!this.editableFlag) {
        return;
      }
      // 変更フラグセット
      let routeData = this.routeDatas[`${this.routeStartAreaId}#${this.routeEndAreaId}#${this.roadType}`]
      if (!routeData) {
        // 対象がない場合は描画されていないので終了
        return
      }
      // 距離
      if (this.roadType == "0") {
        this.ouroDistance = 0.00
      } else {
        this.fukuroDistance = 0.00
      }

      routeData.modified = true

      routeData.data.route.routeGeometry = null
      routeData.data.route.routeDistance = null

      // ルート描画用パラメータ作成
      let parameters = this.getRouteDrawParameters(routeData.data.route); 
      // 以前のルートを削除
      this.$refs[this.mapIdRoute].deleteShape([routeData.data.route.routeId])

      // 描画再開
      this.$refs[this.mapIdRoute].startDrawLineMode()
    },
    /**
     * ルート作成完了時
     * @param {*} params 
     */
    async onRouteDrawFinish(params) {
      console.log(`onEndAreaDrawFinish: ${params.id}`);
      // 描画ストップ
      this.$refs[this.mapIdRoute].stopDrawShapeMode()

      // 一般ユーザーの時は処理を抜ける
      if (!this.editableFlag) {
        return;
      }
      // 頂点数チェック
      let vertexCount = params.layer.getLatLngs()[0].length;

      if (MAX_POLYGON_VERTEX_COUNT < vertexCount) {
        this.showBottomToast(
          `ポリゴンの頂点数は ${MAX_POLYGON_VERTEX_COUNT} までです。`,
          "warning"
        );
        return;
      }
      // 距離計測
      console.log(`POLYLINE`)
      let latLngs = params.layer.getLatLngs()
      let totalDistance = 0
      for (let i = 1;i < latLngs.length;i++) {
        let distance = latLngs[i].distanceTo(latLngs[i-1])
        console.log(`${distance}`)
        totalDistance += distance
      }
      totalDistance = Number(totalDistance).toFixed(0) 
      console.log(`${Number(totalDistance).toFixed(0)}`)

      
      // 変更フラグセット
      let routeData = this.routeDatas[`${this.routeStartAreaId}#${this.routeEndAreaId}#${this.roadType}`]
      if (!routeData) {
        // 対象がない場合は新規レコード
        routeData = {
          modified: true,
          isNew: true,
          data: await RouteModel.getNewData(this.projectId, this.routeStartAreaId, this.routeEndAreaId, this.roadType)
        }
      }

      routeData.modified = true
      // let routeData = this.routeDatas[`${this.routeStartAreaId}#${this.routeEndAreaId}`].data
      routeData.data.route.routeGeometry = this.getCompressRoute(params.layer);
      routeData.data.route.routeDistance = totalDistance
      routeData.data.route.roadType = this.roadType
      this.routeDatas[`${this.routeStartAreaId}#${this.routeEndAreaId}#${this.roadType}`] = routeData
      
      // ルート描画用パラメータ作成
      let parameters = this.getRouteDrawParameters(routeData.data.route); 
      // 以前のルートを削除
      this.$refs[this.mapIdRoute].deleteShape([routeData.data.route.routeId])

      // ルート描画
      this.$refs[this.mapIdRoute].drawRoute(parameters);

      // 距離
      if (this.roadType == "0") {
        this.ouroDistance = Number(totalDistance/1000).toFixed(2)
      } else {
        this.fukuroDistance = Number(totalDistance/1000).toFixed(2)
      }
      

      this.$refs[this.mapIdRoute].startDrawLineMode()

    },
    /**
     * 出発地点
     * 追加ボタン押下イベント
     */
    async onClickAddStartArea() {
      // 上限数チェック
      const baseAreaLimit = await settingModel.getBaseAreaLimit()
      let limitNum = 100
      if (baseAreaLimit) {
        if (baseAreaLimit.value) {
          limitNum = baseAreaLimit.value - 0
          console.log(`baseAreaLimit.value: ${baseAreaLimit.value}`)
        }
      }

      // 現在の件数
      const baseAreaList = await BaseAreaModel.getBaseAreaList(this.projectId);
      if (baseAreaList.length >= limitNum) {
        this.showBottomToast(
          `拠点数の上限に達しました`,
          "warning"
        );
        return
      }

      // 0件から追加の場合は地図フレーム表示
      if (this.startAreaList.length == 0) {
        this.noStartArea = false;
      }
      

      // モーダル画面の表示設定
      this.modals.dlgEditMode = 0;
      if (this.projectType == "0"){
        this.modals.dlgTitle = this.addStartTitle;
      } else if (this.projectType == "1"){
        this.modals.dlgTitle = this.soilAddStartTitle;
      }
      this.modals.dlgButtonCaption = "登録";
      this.modals.dlgshowAddNew = true;
      this.editAreaName = null;
      this.modals.editAreaDlg = true;
    },

    /**
     * 到着地点
     * 追加ボタン押下イベント
     */
    async onClickAddEndArea() {
      // 上限数チェック
      const baseAreaLimit = await settingModel.getBaseAreaLimit()
      let limitNum = 100
      if (baseAreaLimit) {
        if (baseAreaLimit.value) {
          limitNum = baseAreaLimit.value - 0
          console.log(`baseAreaLimit.value: ${baseAreaLimit.value}`)
        }
      }

      // 現在の件数
      const baseAreaList = await BaseAreaModel.getBaseAreaList(this.projectId);
      if (baseAreaList.length >= limitNum) {
        this.showBottomToast(
          `拠点数の上限に達しました`,
          "warning"
        );
        return
      }

      // 0件から追加の場合は地図フレーム表示
      if (this.endAreaList.length == 0) {
        this.noEndArea = false;
      }

      // モーダル画面の表示設定
      this.modals.dlgEditMode = 1;
      if (this.projectType == "0"){
        this.modals.dlgTitle = this.addEndTitle;
      } else if (this.projectType == "1"){
        this.modals.dlgTitle = this.soilAddEndTitle
      }
      this.modals.dlgButtonCaption = "登録";
      this.modals.dlgshowAddNew = false;
      this.editAreaName = null;
      this.modals.editAreaDlg = true;
    },

    /**
     * 出発地点
     * 編集ボタン押下イベント
     */
    onEditStartArea(selectedData) {
      this.modals.dlgEditMode = 2;
      if (this.projectType == "0"){
        this.modals.dlgTitle = this.editStartTitle;
      } else if (this.projectType == "1"){
        this.modals.dlgTitle = this.soilEditStartTitle
      }
      this.modals.dlgButtonCaption = "更新";
      this.modals.dlgshowAddNew = true;
      this.editAreaName = selectedData.baseArea.areaName;
      this.modals.editAreaDlg = true;
    },

    /**
     * 到着地点
     * 編集ボタン押下イベント
     */
    onEditEndArea(selectedData) {
      this.modals.dlgEditMode = 3;
      if (this.projectType == "0"){
        this.modals.dlgTitle = this.editEndTitle;
      } else if (this.projectType == "1"){
        this.modals.dlgTitle = this.soilEditEndTitle
      }
      this.modals.dlgButtonCaption = "更新";
      this.modals.dlgshowAddNew = true;
      this.editAreaName = selectedData.baseArea.areaName;
      this.modals.editAreaDlg = true;
    },

    /**
     * 出発地点
     * 削除ボタン押下イベント
     */
    onDeleteStartArea(selectData) {
      // 最後の1件は削除不可
      if (this.startAreaList.length == 1) {
        this.showBottomToast(
          `出発地を全て削除することはできません。`,
          "warning"
        );

        return;
      }

      // 確認メッセージ
      if (!confirm("削除します。よろしいでしょうか？")) {
        return;
      }

      for (let i = 0; i < this.startAreaList.length; i++) {
        if (selectData.baseArea.id == this.startAreaList[i].baseArea.id) {
          // 削除リストに追加
          this.deleteStartAreaList.push(this.startAreaList[i]);

          // リストから削除
          this.startAreaList.splice(i, 1);

          // 0件になる場合は、画面差し替え
          if (this.startAreaList.length == 0) {
            this.noStartArea = true;
            this.startAreaList = [];
          } else {
            // 削除後のカレント変更
            let startArea =
              i == 0 ? this.startAreaList[0] : this.startAreaList[i - 1];

            this.startAreaId = startArea.baseArea.id;
            this.startRadius = startArea.baseArea.radius;
            this.selectedStartArea = startArea;

            let isStartBaseArea = true;
            this.setupShapeTypeUI(
              startArea.baseArea.shapeType,
              isStartBaseArea
            );

            // 変更後のカレント行の出発地描画パラメータ作成
            let parameters = this.getDrawParameters(startArea.baseArea);

            // 地図描画
            this.$refs.map1.moveMapAndDrawFigure(parameters);
          }
        }
      }
    },

    /**
     * 到着地点
     * 削除ボタン押下イベント
     */
    async onDeleteEndArea(selectData) {
      // 最後の1件は削除不可
      if (this.endAreaList.length == 1) {
        this.showBottomToast(
          `到着地を全て削除することはできません。`,
          "warning"
        );

        return;
      }

      // 予定数量に登録されている拠点IDは削除不可
      if (await this.isRegisteredBaseAreaId(selectData.baseArea.id)) {
        this.showBottomToast(
          "予定数量に登録されている拠点は削除することはできません。",
          "warning"
        );

        return;
      }

      // 確認メッセージ
      if (!confirm("削除します。よろしいでしょうか？")) {
        return;
      }

      for (let i = 0; i < this.endAreaList.length; i++) {
        if (selectData.baseArea.id == this.endAreaList[i].baseArea.id) {
          // 削除リストに追加
          this.deleteEndAreaList.push(this.endAreaList[i]);

          // リストから削除
          this.endAreaList.splice(i, 1);

          // 0件になる場合は、画面差し替え
          if (this.endAreaList.length == 0) {
            this.noEndArea = true;
            this.endAreaList = [];
          } else {
            // 削除後のカレント変更
            let endArea =
              i == 0 ? this.endAreaList[0] : this.endAreaList[i - 1];

            this.endAreaId = endArea.baseArea.id;
            this.endRadius = endArea.baseArea.radius;
            this.selectedEndArea = endArea;

            let isStartBaseArea = false;
            this.setupShapeTypeUI(endArea.baseArea.shapeType, isStartBaseArea);

            // 変更後のカレント行の到着地描画パラメータ作成
            let parameters = this.getDrawParameters(endArea.baseArea);

            // 地図描画
            this.$refs.map2.moveMapAndDrawFigure(parameters);
          }
        }
      }
    },

    /**
     * 拠点が予定数量に登録されているか
     * @returns 拠点が予定数量に登録されている場合true、さもなくばfalse
     */
    async isRegisteredBaseAreaId(baseAreaId) {
      const searchKey = `${this.projectId}#`;
      let listVolume = await VolumeModel.getVolumeList(searchKey);

      for (const item of listVolume) {
        if (baseAreaId == item.volume.endAreaId) return true;
      }

      return false;
    },

    /**
     * モーダルキャンセルボタン押下イベント
     */
    onClickCancelModal() {
      // 0件から追加の場合でキャンセルしたときは、地図フレームを非表示にする
      if (this.startAreaList == 0) {
        if (this.modals.dlgEditMode == 0) {
          this.noStartArea = true;
        }
      }

      if (this.endAreaList == 0) {
        if (this.modals.dlgEditMode == 1) {
          this.noEndArea = true;
        }
      }

      // モーダル画面を非表示
      this.modals.editAreaDlg = false;
    },

    /**
     * モーダル登録・更新ボタン押下イベント
     */
    async onClickSubmitModal() {
      // 名前が入力されているかチェック
      if (this.editAreaName == "" || this.editAreaName == null) {
        this.showBottomToast(
          `名前が入力されていません。\n入力してください。`,
          "warning"
        );

        return;
      }

      // 文字長さチェック
      if (String(this.editAreaName).length > this.baseAreaNameLengh) {
        this.showBottomToast(
          `名前の文字数は ${this.baseAreaNameLengh} 文字以内で入力してください。`,
          "warning"
        );

        return;
      }

      // 禁則文字チェック
      let kinsokuflg = false;
      const kinsoku_str = process.env.VUE_APP_KINSOKU_CHARACTERS;

      for (let i = 0; i < String(kinsoku_str).length; i++) {
        let check_str = String(kinsoku_str).substr(i + 1, 1);
        let ret = String("dummy" + this.editAreaName).indexOf(check_str);

        if (ret > 0) {
          kinsokuflg = true;
          break;
        }
      }

      if (kinsokuflg) {
        this.showBottomToast(
          `禁則文字が指定されてます。\n禁則文字（${kinsoku_str.substr(
            1,
            kinsoku_str.length - 1
          )}）`,
          "warning"
        );

        return;
      }

      // 重複チェック
      if (this.modals.dlgEditMode == 0 || this.modals.dlgEditMode == 2) {
        for (let i = 0; i < this.startAreaList.length; i++) {
          if (this.startAreaList[i].baseArea.areaName == this.editAreaName) {
            this.showBottomToast(
              `既に使用されている名称です。\n別の名称を指定してください。`,
              "warning"
            );

            return;
          }
        }
      } else {
        for (let i = 0; i < this.endAreaList.length; i++) {
          if (this.endAreaList[i].baseArea.areaName == this.editAreaName) {
            this.showBottomToast(
              `既に使用されている名称です。\n別の名称を指定してください。`,
              "warning"
            );

            return;
          }
        }
      }

      if (this.modals.dlgEditMode == 0) {
        // ### 出発地 新規追加 ###

        // リストが0件の時は地図初期化
        if (this.startAreaList.length == 0) {
          this.$refs.map1.mapCreate();
        }

        // 追加する項目を設定
        let item = await BaseAreaModel.getNewData(this.projectId);
        item.baseArea.areaName = this.editAreaName;
        item.baseArea.shapeType = ID_SHAPE_TYPE_POLYGON;
        item.baseArea.x = 0;
        item.baseArea.y = 0;
        item.baseArea.areaType = 1;
        item.baseArea.radius = 0;
        item.baseArea.geofence = "";

        this.startAreaList.push(item);

        // カレントにする
        this.startAreaId = item.baseArea.id;
        this.selectedStartArea = item;

        let isStartBaseArea = true;
        this.setupShapeTypeUI(item.baseArea.shapeType, isStartBaseArea);

        // 出発地を削除
        this.$refs.map1.deleteBeforeDrawFigure();
      } else if (this.modals.dlgEditMode == 1) {
        // ### 到着地 新規追加 ###

        // リストが0件の時は地図初期化
        if (this.endAreaList.length == 0) {
          this.$refs.map2.mapCreate();
        }

        // 追加する項目を設定
        let item = await BaseAreaModel.getNewData(this.projectId);
        item.baseArea.areaName = this.editAreaName;
        item.baseArea.shapeType = ID_SHAPE_TYPE_POLYGON;
        item.baseArea.x = 0;
        item.baseArea.y = 0;
        item.baseArea.areaType = 2;
        item.baseArea.radius = 0;
        item.baseArea.geofence = "";

        this.endAreaList.push(item);

        // カレントにする
        this.endAreaId = item.baseArea.id;
        this.selectedEndArea = item;

        let isStartBaseArea = false;
        this.setupShapeTypeUI(item.baseArea.shapeType, isStartBaseArea);

        // 出発地を削除
        this.$refs.map2.deleteBeforeDrawFigure();
      } else if (this.modals.dlgEditMode == 2) {
        // ### 出発地 変更 ###
        for (let i = 0; i < this.startAreaList.length; i++) {
          if (this.startAreaList[i].baseArea.id == this.startAreaId) {
            this.startAreaList[i].baseArea.areaName = this.editAreaName;
            break;
          }
        }
      } else if (this.modals.dlgEditMode == 3) {
        // ### 到着地 変更 ###
        for (let i = 0; i < this.endAreaList.length; i++) {
          if (this.endAreaList[i].baseArea.id == this.endAreaId) {
            this.endAreaList[i].baseArea.areaName = this.editAreaName;

            break;
          }
        }
      }

      // ダイアログ画面を閉じる
      this.modals.editAreaDlg = false;
    },

    /**
     * 編集の有無を確認（編集があった場合はtrueを返却）
     */
    isChange() {
      let isChangeStart = false;
      let isChangeEnd = false;

      // 出発値の変更有無チェック
      if (
        JSON.stringify(this.editBeforeStartAreaList) !=
        JSON.stringify(this.startAreaList)
      ) {
        isChangeStart = true;
      }

      // 到着値の変更有無チェック
      if (
        JSON.stringify(this.editBeforeEndAreaList) !=
        JSON.stringify(this.endAreaList)
      ) {
        isChangeEnd = true;
      }

      // 変更なしの場合は処理終了
      if (!isChangeStart && !isChangeEnd) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * キャンセルボタンクリック時の処理です。
     */
    onClickCancel() {
      if (!this.isChange()) {
        document.activeElement.blur();
        return;
      }

      // 変更の有無を確認し、変更があれば確認メッセージ
      if (!confirm("拠点の変更をしていますが、処理をキャンセルしますか？")) {
        document.activeElement.blur();
        return;
      }

      // // リストを初期化
      // this.startAreaList = [];
      // this.endAreaList = [];

      // // リストを変更前の状態に戻す
      // this.startAreaList = JSON.parse(
      //   JSON.stringify(this.editBeforeStartAreaList)
      // );
      // this.endAreaList = JSON.parse(JSON.stringify(this.editBeforeEndAreaList));

      // // 画面の制御
      // if (this.startAreaList.length < 1) {
      //   this.noStartArea = true;
      // }

      // if (this.endAreaList.length < 1) {
      //   this.noEndArea = true;
      // }

      // // 描画
      // this.drawBaseArea();

      // キャンセル
      this.doCancel()
    },
    /**
     * 拠点編集キャンセル処理
     */
    doCancel() {
      // リストを初期化
      this.startAreaList = [];
      this.endAreaList = [];

      // リストを変更前の状態に戻す
      this.startAreaList = JSON.parse(
        JSON.stringify(this.editBeforeStartAreaList)
      );
      this.endAreaList = JSON.parse(JSON.stringify(this.editBeforeEndAreaList));

      // 画面の制御
      if (this.startAreaList.length < 1) {
        this.noStartArea = true;
      }

      if (this.endAreaList.length < 1) {
        this.noEndArea = true;
      }

      // 描画
      this.drawBaseArea();
    },
    /**
     * 保存ボタンクリック時の処理です。
     */
    async onClickSave() {
      if (this.isChange() == false) {
        this.showBottomToast(
          `編集されてませんので、保存処理は実施されません。`,
          "warning"
        );
        document.activeElement.blur();
        return;
      }

      let loader = this.showLoader();

      try {
        // バリデーションチェック（出発値）
        let retVal = this.validation(1, this.startAreaList);
        if (retVal) {
          return;
        }

        // バリデーションチェック（到着値）
        retVal = this.validation(2, this.endAreaList);
        if (retVal) {
          return;
        }

        // 更新処理（出発値）
        await this.updateBaseAreaInfo(
          this.startAreaList,
          this.editBeforeStartAreaList,
          this.deleteStartAreaList
        );

        // 更新処理（到着地）
        await this.updateBaseAreaInfo(
          this.endAreaList,
          this.editBeforeEndAreaList,
          this.deleteEndAreaList
        );

        // 変更前のリストを、今回保存したリストに更新する
        this.editBeforeStartAreaList = [];
        this.editBeforeEndAreaList = [];

        this.editBeforeStartAreaList = JSON.parse(
          JSON.stringify(this.startAreaList)
        );

        this.editBeforeEndAreaList = JSON.parse(
          JSON.stringify(this.endAreaList)
        );

        // 完了メッセージ
        this.showBottomToast(`拠点情報更新処理が完了しました。`, "info");
      } catch (e) {
        this.showBottomToast(`拠点情報更新処理で例外が発生しました。`, "error");
        this.errorLog("onClickSave", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
      }
    },
    async onClickRouteCancel() {
      console.log(`onClickRouteCancel`)
      // 描画ストップ
      console.log(`描画ストップ`)
      this.$refs[this.mapIdRoute].stopDrawShapeMode()
      // ルート情報を再取得
      console.log(`ルート再取得`)
      this.routeDatas = await this.getRouteDatas()
      // 対象のルートを描画
      console.log(`ルート再描画`)
      this.drawRouteOfCurentArea()
      
      this.$nextTick(function() {
        // 更新フラグを戻す
        console.log(`ルートフラグ戻す`)
        this.routeModified = false  
      }.bind(this));
      
      // 描画モード再開
      console.log(`ルート描画モード再開`)
      this.$refs[this.mapIdRoute].startDrawLineMode()
    },
    async onClickRouteSave() {
      let loader = this.showLoader();
      try {
        if (this.routeModified == false) {
          this.showBottomToast(
            `編集されてませんので、保存処理は実施されません。`,
            "warning"
          );
          document.activeElement.blur();
          return;
        }

        let routeDataKeys = Object.keys(this.routeDatas)
        for (let key of routeDataKeys) {
          let routeData = this.routeDatas[key]
          if (routeData.modified) {
            if (routeData.isNew) {
              console.log(`addRoute`)
              await RouteModel.addRoute(routeData.data)
            } else {
              console.log(`updateRoute`)
              await RouteModel.updateRoute(routeData.data)
            }
          }
        }

        // for (let routeData of this.routeDatas) {
        //   if (routeData.modified) {
        //     if (routeData.isNew) {
        //       console.log(`addRoute`)
        //       await RouteModel.addRoute(routeData.data)
        //     } else {
        //       console.log(`updateRoute`)
        //       await RouteModel.updateRoute(routeData.data)
        //     }
        //   }
        // }
      } catch (e) {
        console.log(e)
        this.showBottomToast(`ルート報更新処理で例外が発生しました。`, "error");
        
        this.errorLog("onClickRouteSave", this.parseErrorObject(e))
      } finally {
        await this.initRoute()
        this.hideLoader(loader);
      }

    },
    /**
     * 保存ボタンクリック時の処理です。
     * @parms モード 1:出発地、2：到着地
     * @parms 拠点リスト（出発地 or 到着地）
     * @return true：異常値あり、false
     */
    validation(mode, areaList) {
      let retVal = false;

      // 出発、到着フラグ
      let modeValue = mode == 1 ? "出発地点" : "到着地点";

      // バリデーションチェック
      for (let i = 0; i < areaList.length; i++) {
        let area = areaList[i];

        console.log(area.baseArea);

        // 座標がセットされているかチェック
        if (area.baseArea.x == 0 && area.baseArea.y == 0) {
          this.showBottomToast(
            `${modeValue}（${area.baseArea.areaName}）の位置が指定されていません。\n地図上で位置を指定してください。`,
            "warning"
          );

          retVal = true;
          break;
        }

        if (!area.baseArea.geofence) {
          this.showBottomToast(
            `${modeValue}（${area.baseArea.areaName}）の形状がみつかりません。\nもう一度、地図上で形状を描画してください。`,
            "warning"
          );

          retVal = true;
          break;
        }
        // // 半径がセットされているかチェック
        // if (area.baseArea.radius == "" || area.baseArea.radius == null) {
        //   this.showBottomToast(
        //     `${modeValue}（${area.baseArea.areaName}）の、半径が指定されていません。\n半径を指定してください。`,
        //     "warning"
        //   );

        //   retVal = true;
        //   break;
        // }
      }

      return retVal;
    },

    /**
     * 保存ボタンクリック時の処理です。
     * @parms 拠点リスト（出発地 or 到着地）
     * @parms 変更前拠点リスト（出発地 or 到着地）
     * @parms 削除拠点リスト（出発地 or 到着地）
     */
    async updateBaseAreaInfo(
      baseAreaList,
      beforeBaseAreaList,
      deleteBaseAreaList
    ) {
      // -------------------------------------------------------------------------------------
      // 編集後の拠点情報リストと変更前の拠点情報リストを比較し、新規登録、更新をします
      // -------------------------------------------------------------------------------------
      let updateItems = null;

      // (0:変更なし、1：編集あり（拠点名称だけ）、2:編集あり（位置の変更）、3:追加)
      let mode = 3;

      for (let i = 0; i < baseAreaList.length; i++) {
        let baseArea = baseAreaList[i];

        // 変更前の拠点リストとbaseArea.idが一致するレコードがあるかチェック
        for (let j = 0; j < beforeBaseAreaList.length; j++) {
          let beforeBaseArea = beforeBaseAreaList[j];

          if (baseArea.baseArea.id == beforeBaseArea.baseArea.id) {
            // 一致するレコードがあった場合は、編集の有無を確認
            if (
              baseArea.baseArea.areaName == beforeBaseArea.baseArea.areaName &&
              baseArea.baseArea.shapeType ==
                beforeBaseArea.baseArea.shapeType &&
              baseArea.baseArea.x == beforeBaseArea.baseArea.x &&
              baseArea.baseArea.y == beforeBaseArea.baseArea.y &&
              baseArea.baseArea.radius == beforeBaseArea.baseArea.radius &&
              baseArea.baseArea.geofence == beforeBaseArea.baseArea.geofence
            ) {
              // すべて一致の場合は変更なし：0
              mode = 0;
            } else {
              if (
                baseArea.baseArea.shapeType ==
                  beforeBaseArea.baseArea.shapeType &&
                baseArea.baseArea.x == beforeBaseArea.baseArea.x &&
                baseArea.baseArea.y == beforeBaseArea.baseArea.y &&
                baseArea.baseArea.radius == beforeBaseArea.baseArea.radius &&
                baseArea.baseArea.geofence == beforeBaseArea.baseArea.geofence
              ) {
                // 拠点名称だけ変更されていた場合：1
                mode = 1;
                updateItems = baseArea;
              } else {
                // 位置、半径が変更されていたい場合：2
                mode = 2;
                updateItems = baseArea;
              }
            }
          }
        }

        // console.log("Reslt :" + mode + ":" + baseArea.baseArea.areaName + ":" + baseArea.baseArea.id)

        if (mode == 1) {
          // 拠点情報更新（DnyamoDB）、Geofenceは更新しない
          await BaseAreaModel.updateBaseArea(updateItems);

          // ログ出力
          this.infoLog(
            `【Update】 BaseArea：  pk[${updateItems.pk}]、sk[${updateItems.sk}]、種別 [${updateItems.baseArea.areaType}]、拠点名 [${updateItems.baseArea.areaName}]、x [${updateItems.baseArea.x}]、y [${updateItems.baseArea.y}]、半径 [${updateItems.baseArea.radius}]`
          );
        } else if (mode == 2) {
          // Geofence更新
          await this.updateGeofence(updateItems.baseArea);

          // 拠点情報更新（DnyamoDB）
          await BaseAreaModel.updateBaseArea(updateItems);

          // ログ出力
          const geofenceId = `${this.projectId}_${updateItems.baseArea.areaType}_${updateItems.baseArea.id}`;
          this.infoLog(
            `【Update Geofence】 BaseArea： GeofeceId [${geofenceId}]`
          );
          this.infoLog(
            `【Update】 BaseArea： pk[${updateItems.pk}]、sk[${updateItems.sk}]、種別 [${updateItems.baseArea.areaType}]、拠点名 [${updateItems.baseArea.areaName}]、x [${updateItems.baseArea.x}]、y [${updateItems.baseArea.y}]、半径 [${updateItems.baseArea.radius}]`
          );
        } else if (mode == 3) {
          // Geofence追加
          await this.updateGeofence(baseArea.baseArea);

          // 拠点情報更新（DnyamoDB）
          await BaseAreaModel.addBaseArea(baseArea);

          // ログ出力
          const geofenceId = `${this.projectId}_${baseArea.baseArea.areaType}_${baseArea.baseArea.id}`;
          this.infoLog(
            `【Insert Geofence】 BaseArea： GeofeceId [${geofenceId}]`
          );
          this.infoLog(
            `【Insert】 BaseArea： pk[${baseArea.pk}]、sk[${baseArea.sk}]、種別 [${baseArea.baseArea.areaType}]、拠点名 [${baseAreaList[i].baseArea.areaName}]、x [${baseAreaList[i].baseArea.x}]、y [${baseAreaList[i].baseArea.y}]、半径 [${baseAreaList[i].baseArea.radius}]`
          );
        }

        // 変数初期化
        mode = 3;
        updateItems = null;
      }

      // -------------------------------------------------------------------------------------
      // 削除処理のリストからGeofenceと拠点情報を(DynamoDB)削除
      // -------------------------------------------------------------------------------------
      for (let i = 0; i < deleteBaseAreaList.length; i++) {
        let deleteBaseArea = deleteBaseAreaList[i];

        // Geofenceを削除 (削除するGeofenceのキーを作成)
        let geofenceId = `${this.projectId}_${deleteBaseArea.baseArea.areaType}_${deleteBaseArea.baseArea.id}`;
        await this.deleteGeofence(geofenceId);

        // 拠点情報削除
        await BaseAreaModel.deleteBaseArea(deleteBaseArea);

        // ログ出力
        this.infoLog(
          `【Delete Geofence】 BaseArea： GeofeceId [${geofenceId}]`
        );
        this.infoLog(
          `【Delete】 BaseArea： pk[${deleteBaseArea.pk}]、sk[${deleteBaseArea.sk}]、種別 [${deleteBaseArea.baseArea.radius}]`
        );
      }

      // // 削除
      // deleteBaseAreaList.forEach(item => {
      //   const geofenceId = `${this.projectId}_${item.baseArea.areaType}_${item.baseArea.id}`
      //   this.deleteGeofence(geofenceId).then(() => {
      //     BaseAreaModel.deleteBaseArea(item).then(() => {
      //       console.log("delete success")
      //     })
      //   })
      // });
    },

    /**
     * Geofenceを登録します
     * @parms 拠点情報
     */
    async updateGeofence(baseArea) {
      // 座標圧縮文字列から座標群に復元
      let latlngs = MLCompressUtil.base64ToLatLngArray(baseArea.geofence);

      // // 座標をセット
      // const x = baseArea.x;
      // const y = baseArea.y;

      // // 中心点と半径を渡して、円形のポリゴン座標取得
      // let latlngs = this.getCirclePolygonPoints(
      //   new L.LatLng(y, x),
      //   baseArea.radius
      // );

      // ポリゴン→GeoJson作成 ※この時にLeafletが自動で閉じポリゴンにしてくれる
      const geoJson = L.polygon(latlngs).toGeoJSON(6);
      // console.log(geoJson);

      // ID(プロジェクトID_種別_拠点ID）
      const geofenceId = `${this.projectId}_${baseArea.areaType}_${baseArea.id}`;

      // 成功(GeoJsonの座標部分を渡すのが正解 ※複数ある場合は1つづつ渡す(本システムでは1拠点に複数ポリゴンは無し))
      await this.saveGeofence(geofenceId, geoJson.geometry.coordinates[0]);
    },

    /**
     * 中心点経緯度と半径（m単位）から円形ポリゴン座標を取得します。
     * @parms 中心座標（X、Y）
     * @parms 半径
     */
    getCirclePolygonPoints(latlng, radius) {
      return CoordinateUtil.getCirclePolygonPoints(latlng, radius);
    },

    /**
     * ジオフェンスを保存します。（新規追加、または更新）
     * @param {String} geofenceId ID(プロジェクトD#拠点ID(タイムスタンプ）)
     * @param {Object} geofencePolygon ジオフェンス頂点情報（GeoJson座標部分の1要素)
     */
    async saveGeofence(geofenceId, geofencePolygon) {
      try {
        if (!this.locationService) {
          // console.log(this.locationService);
          return;
        }

        const geoParams = {
          CollectionName: this.geofenceCollectionId,
          GeofenceId: geofenceId,
          Geometry: {
            Polygon: [geofencePolygon],
          },
        };
        // console.log(geoParams);

        const result = await this.locationService
          .putGeofence(geoParams)
          .promise();

        // console.log(
        //   `putGeofence Success(Saved on Amazon Location Service:${JSON.stringify(
        //     result
        //   )}).`
        // );
      } catch (e) {
        
        this.errorLog("saveGeofence", this.parseErrorObject(e))
        this.showBottomToast(`拠点情報保存処理に失敗しました。`, "error");
        throw e;
      }
    },

    /**
     * ジオフェンスを削除します。
     * @param {String} geofenceId ID(プロジェクトD_種別_拠点ID(タイムスタンプ）)
     */
    async deleteGeofence(geofenceId) {
      try {
        if (!this.locationService) {
          // console.log(this.locationService);
          return;
        }

        const geoParams = {
          CollectionName: this.geofenceCollectionId,
          GeofenceIds: [geofenceId],
        };
        // console.log(geoParams);

        const result = await this.locationService
          .batchDeleteGeofence(geoParams)
          .promise();

        // console.log(`batchDeleteGeofence Success(Deleted on Amazon Location Service:${JSON.stringify(result)}).`);
      } catch (e) {
        
        this.errorLog("deleteGeofence", this.parseErrorObject(e))
        this.showBottomToast(`拠点情報削除処理に失敗しました。`, "error");
        throw e;
      }
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();

      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },

    /**
     * コンソール出力のみ。
     */
     debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },

    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // if (this.isChange()){
      //   // 変更の有無を確認し、変更があれば確認メッセージ
      //   let ret = confirm('拠点の変更をしていますが、本ページから運行情報画面へ遷移しますか？');
      //   if (!ret) return
      // }

      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      // 運行情報画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },

    async clickSample() {
      // ABC工場
      await this.saveSampleGeofenceParams(
        135.496671,
        34.678965,
        150,
        "20211115155809146",
        1
      );

      // A工区
      await this.saveSampleGeofenceParams(
        135.500967,
        34.679598,
        100,
        "20211115155809147",
        2
      );
    },

    async saveSampleGeofenceParams(x, y, radius, baseAreaId, areaType) {
      let latlngs = CoordinateUtil.getCirclePolygonPoints(
        new L.LatLng(y, x),
        radius
      );

      // // 中心点をメルカトル座標（メートル単位）に変換
      // const prj = L.CRS.EPSG3857;
      // const mPoint = prj.project(new L.LatLng(y, x));
      // const mX = mPoint.x;
      // const mY = mPoint.y;
      // const r = radius;

      // // 円をポリゴン化する
      // const n = 36;
      // let latlngs = [];

      // for (let i = 0 ; i < n ; i++) {
      //   const px = mX + r * Math.cos(i * Math.PI / (n / 2));
      //   const py = mY + r * Math.sin(i * Math.PI / (n / 2));

      //   // 経緯度に変換
      //   latlngs.push(prj.unproject(new L.Point(px, py)));
      // }

      // ポリゴン→GeoJson作成 ※この時にLeafletが自動で閉じポリゴンにしてくれる
      const geoJson = L.polygon(latlngs).toGeoJSON(6);
      // console.log(geoJson);

      // ID(プロジェクトID_種別_拠点ID(※本当はタイムスタンプ))
      // ※#はエラーになるので_区切り
      const geofenceId = `hY8vvhKMfGcf2c56tuYPDW_${areaType}_${baseAreaId}`;
      // const geofenceId = `${this.projectId}_${areaType}_${baseAreaId}`;
      // console.log(`geofenceId:${geofenceId}`);

      // 成功(GeoJsonの座標部分を渡すのが正解 ※複数ある場合は1つづつ渡す(本システムでは1拠点に複数ポリゴンは無し))
      await this.saveGeofence(geofenceId, geoJson.geometry.coordinates[0]);
    },

    async sampleGetGeofance() {
      const geoParams = {
        CollectionName: this.geofenceCollectionId,
        GeofenceId: "9LoaWn35vYqqDULVwytVf8_1_20221111133606723",
      };

      const result = await this.locationService
        .getGeofence(geoParams)
        .promise();

      console.log("#############");
      console.log(result);
    },

    /**
     * モーダル閉じる（×）ボタンクリック
     */
    clickCloseModal() {
      // 0件から追加の場合でキャンセルしたときは、地図フレームを非表示にする
      if (this.startAreaList == 0) {
        if (this.modals.dlgEditMode == 0) {
          this.noStartArea = true;
        }
      }

      if (this.endAreaList == 0) {
        if (this.modals.dlgEditMode == 1) {
          this.noEndArea = true;
        }
      }
    },
    /**
     * タブ離脱時の可否制御
     * @param {*} activeName 
     * @param {*} oldName 
     */
    checkLeaveTab(activeName, oldName) {

      console.log(`activeName: ${activeName} oldName: ${oldName}`)
      if (oldName == activeName) {
        // タブの前後名称が同じなら何もしない
        return true
      }
      
      switch (activeName) {
        case "0":
          // ルート編集荷変更時→拠点編集
          if (this.routeModified) {
            this.repeatedHitsFlg = false;            
            let ret = window.confirm(
              "編集中のルートが保存されていません。ページを離れてもよろしいですか？"
            );
            if (!ret) {
              this.$nextTick(function() {
                this.baseAreaMode = oldName
              }.bind(this))
              return false
            }
            // ルート初期化する
            this.initRoute().then(() => {

            })

          }
          break;      
        case "1":
          // 拠点編集→ルート編集荷変更時
          if (this.isChange() == true) {
            this.repeatedHitsFlg = false;
            
            let ret = window.confirm(
              "編集中の拠点が保存されていません。ページを離れてもよろしいですか？"
            );
            if (!ret) {
              this.$nextTick(function() {
                this.baseAreaMode = oldName
              }.bind(this))
              return false
            }
            // 拠点編集キャンセル
            this.doCancel()
          }

          this.$nextTick(async function() {
            this.initRoute().then(() => {

            })
          }.bind(this))
          
          break;
      }
      return true
    },
    changeRoadType() {
      this.$refs[this.mapIdRoute].stopDrawShapeMode();
      this.$refs[this.mapIdRoute].changeDrawShapeStype(this.roadType)
      this.$refs[this.mapIdRoute].startDrawLineMode()
    },
    

    // 以下最後に削除

    // async deleteSampleGeofence() {

    //   // ID(プロジェクトID_種別_拠点ID(※本当はタイムスタンプ))
    //   // ※#はエラーになるので_区切り
    //   const geofenceId = '4Mj7SLRFcHSp85D8c57hzR_1_bAXN2e7s6gRmX4WoR9ZnNA';
    //   // const geofenceId = 'uTNYvzZ7r8STNvFyPVT28S_1_2021111110451234'

    //   await this.deleteGeofence(geofenceId);
    // },

    // /**
    //  * 拠点情報を保存します。（新規追加、または更新）
    //  */
    // async saveBaseArea() {
    //   try {
    //     // 1拠点づつ処理するのでループ（出発地点リストのループ→到着地地点りストのループ

    //     // ToDo 出発、到着ともこのvueへの遷移直後の内容と比較し変更なければ更新しない

    //     // ToDo 更新対象拠点の座標、半径からポリゴンを作成(TimeLineMap.vueのサンプル参照)

    //     // ToDo ポリゴンをGeoJson化する

    //     // ToDo Geofenceの登録

    //     // ToDo DBの登録

    //   } catch (e) {
    //     this.showBottomToast(`車両一覧のソート順番保存処理に失敗しました。`, 'error')
    //     throw e
    //   }
    // },

    // /**
    //  * 拠点情報を削除する（1拠点づつの削除）
    //  * @param {Object} deleteArea 削除する1つの拠点データ
    //  */
    // async deleteBaseArea(deleteArea) {
    //   try {
    //     // ToDo 事前に確認モーダルでOKだった場合に呼ばれる

    //     // ToDo BatchDeleteGeofence

    //     // ToDo DBの削除

    //   } catch (e) {
    //     this.showBottomToast(`拠点情報の削除に失敗しました。`, 'error')
    //     throw e
    //   }
    // },
  },
};
</script>
