<template>
  <div>
    <!-- <base-header class="pb-1" type="">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h3 class="d-inline-block mb-0">{{salerTenant.name}} 契約テナント一覧</h3>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
              <route-bread-crumb></route-bread-crumb>
            </nav>
          </div>
        </div>
    </base-header> -->
    <div class="container-fluid mt-1 ">
      <div class="row">
        <div class="col-4">
          <div class="card chart-area p-3 mb-3 bg-white rounded shadow">
            <h4>有効契約数の推移</h4>
            <Bar
              :chart-options="chartOptions"
              :chart-data="tenantChartData"
              chart-id="bar-chart"
              dataset-id-key="label"
              :width="chartWidth"
              :height="chartHeight"
            />
          </div>
          <div class="card p-3 mb-3 shadow">
            <h4>今月終了の契約</h4>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="willEndTenants"
            >
              <template #empty>
                <div
                  class="border rounded-pill border-primary text-primary"
                  style="line-height:30px;"
                >
                  対象はありません
                </div>
              </template>
              <el-table-column label="契約者名" prop="name">
                <template v-slot="{ row }">
                  <span style="font-size:0.8rem;">
                    <a
                      href="#!"
                      class="mt-2 mb-3 ml-2"
                      @click.prevent="onClickTenant(row)"
                      ><strong>{{ row.tenant.name }}</strong></a
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label-class-name="header-cell-right"
                align="right"
                label="契約終了日"
                min-width="140px"
              >
                <template v-slot="{ row }">
                  <span style="font-size:0.8rem;">{{
                    toWawrekiDate(row.tenant.contractEnd)
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card p-3 mb-3 shadow">
            <h4>契約終了後6ヶ月経過</h4>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="endTenants"
            >
              <template #empty>
                <div
                  class="border rounded-pill border-primary text-primary"
                  style="line-height:30px;"
                >
                  対象はありません
                </div>
              </template>
              <el-table-column label="契約者名" prop="name">
                <template v-slot="{ row }">
                  <span style="font-size:0.8rem;">
                    <a
                      href="#!"
                      class="mt-2 mb-3 ml-2"
                      @click.prevent="onClickTenant(row)"
                      ><strong>{{ row.tenant.name }}</strong></a
                    >
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="契約終了日"
                min-width="140px"
                align="right"
                label-class-name="header-cell-right"
              >
                <template v-slot="{ row }">
                  <span style="font-size:0.8rem;">{{
                    toWawrekiDate(row.tenant.contractEnd)
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="col-8">
          <h3 class="flex-grow-1 mb-2 mr-3 inline-block align-self-center">
            契約一覧
          </h3>
          <div class="card bg-transparent">
            <div class="d-flex">
              <div class="card-header border-0 mb-2 pb-2 flex-grow-1">
                <!-- <div class="d-flex ml-4"> -->

                <!-- <div class="d-flex flex-column"> -->
                <div class="d-flex">
                  <div class="d-flex flex-fill flex-column mr-2">
                    <div class="d-flex mb-2">
                      <div
                        style="width:100px;"
                        class="text-nowrap mr-2 font-weight-bold align-self-center"
                      >
                        契約者
                      </div>
                      <div class="flex-fill">
                        <el-input
                          name="tenantName"
                          class=""
                          autocomplete="off"
                          placeholder="契約者名を入力"
                          v-model="search.tenantName"
                          clearable
                        ></el-input>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div
                        style="width:100px;"
                        class="text-nowrap mr-2 font-weight-bold align-self-center"
                      >
                        契約終了月
                      </div>
                      <div class="flex-fill">
                        <el-date-picker
                          class="pr-2"
                          format="YYYY年MM月"
                          value-format="YYYYMM"
                          v-model="search.endMonth"
                          type="month"
                          placeholder="契約終了月"
                        />
                      </div>
                    </div>
                  </div>

                  <base-button
                    icon
                    outline
                    class="align-self-stretch text-nowrap"
                    type="primary"
                    @click="onClickSearch"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fas fa-search"></i
                    ></span>
                    <span class="btn-inner--text text-nowrap">検索</span>
                  </base-button>
                </div>
                <!-- </div> -->
              </div>
              <div class="card-header border-0 mb-2 ml-2 d-flex flex-column">
                <div class="d-flex mb-2">
                  <div
                    style="width:80px;"
                    class="inline-block text-nowrap align-self-center mr-2 font-weight-bold"
                  >
                    並び順：
                  </div>
                  <el-select
                    size="small"
                    class=""
                    placeholder=""
                    @change="changeSort(item, $event)"
                    v-model="selectSort"
                  >
                    <el-option
                      v-for="item in selectList"
                      class="select-primary"
                      :value="item.value"
                      :label="item.label"
                      :key="item.value"
                    >
                      <span class="ml-1">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="d-flex">
                  <div
                    style="width:80px;"
                    class="inline-block text-nowrap align-self-center mr-2 font-weight-bold"
                  >
                    方向：
                  </div>
                  <el-select
                    size="small"
                    class=""
                    placeholder=""
                    @change="changeDirection(item, $event)"
                    v-model="selectDirection"
                  >
                    <el-option
                      v-for="item in directionList"
                      class="select-primary"
                      :value="item.value"
                      :label="item.label"
                      :key="item.value"
                    >
                      <span class="ml-1">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="card-header border-0 mb-2 ml-2 d-flex">
                <div class="text-right mr-1 align-self-center">
                  <base-button
                    icon
                    outline
                    type="primary"
                    class="text-nowrap"
                    @click="onClickNewTenant"
                  >
                    <span class="btn-inner--icon"
                      ><i class="fa fa-plus-square"></i
                    ></span>
                    <span class="btn-inner--text text-nowrap">新規契約</span>
                  </base-button>
                </div>
              </div>
            </div>
            <!-- <div  v-for="row in pagedData"> -->
            <div
              v-for="row in pagedData"
              :key="row"
              class="card shadow mb-2 p-2 rounded"
            >
              <div class="d-flex ">
                <div class="d-flex flex-column flex-grow-1">
                  <a
                    href="#!"
                    class="mt-2 mb-3 ml-2"
                    @click.prevent="onClickTenant(row)"
                    ><strong>{{ row.tenant.name }}</strong></a
                  >
                  <div class="d-flex">
                    <span class="mr-3 ml-3 font-sm"
                      >契約開始日:{{
                        toWawrekiDate(row.tenant.contractStart)
                      }}</span
                    >
                    <span class="font-sm"
                      >契約終了日:{{
                        toWawrekiDate(row.tenant.contractEnd)
                      }}</span
                    >
                  </div>
                </div>

                <div
                  v-if="row.status == 0"
                  class="d-flex align-self-center contract-invalid"
                >
                  <div class="align-self-center">
                    期間外
                  </div>
                </div>
                <div
                  v-if="row.status == 1"
                  class="d-flex align-self-center contract-valid"
                >
                  <div class="align-self-center">
                    契約中
                  </div>
                </div>
                <div
                  v-if="row.status == 2"
                  class="d-flex align-self-center contract-end"
                >
                  <div class="align-self-center">
                    当月終了
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="pagedData.length == 0"
              class="card shadow mb-2 p-2 rounded"
            >
              <div
                class="text-center border rounded-pill border-primary text-primary m-3"
                style="line-height:30px;"
              >
                対象はありません
              </div>
            </div>
            <!-- </div> -->
            <el-table
              v-if="false"
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="pagedData"
            >
              <el-table-column
                label="契約者名"
                min-width="410px"
                prop="name"
                sortable
              >
                <template v-slot="{ row }">
                  <!-- <img :src="row.project.image" class="avatar rounded-circle mr-3" /> -->
                  <b>
                    <a href="#!" @click.prevent="onClickTenant(row)"
                      >{{ row.tenant.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <el-table-column label="契約開始日" min-width="140px" sortable>
                <template v-slot="{ row }">
                  <span>{{ row.tenant.contractStart }}</span>
                </template>
              </el-table-column>
              <el-table-column label="契約終了日" min-width="140px" sortable>
                <template v-slot="{ row }">
                  <span>{{ row.tenant.contractEnd }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="d-flex  justify-content-center mt-3 mb-3">
              <el-pagination
                class="bg-transparent"
                background
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-1"></div>
  </div>
</template>
<style>
.header-cell-right {
  justify-content: flex-end;
}
</style>
<style scoped>
.gradient {
  border-bottom: 3px solid #003c9c;
  border-image: linear-gradient(to right, #003c9c 0%, #ffffff 100%);
  border-image-slice: 1;
}
.w-100 {
  width: 100px !important;
}

.contract-invalid {
  border-radius: 50px;
  border: 0px solid #666666;
  background-color: #666666;
  color: white;
  padding: 5px 10px 5px 10px;

  /* aspect-ratio: 1; */
  /* height: 100%; */
  /* margin:10px; */
}
.contract-valid {
  border-radius: 50px;
  border: 0px solid #00b94d;
  background-color: #00b94d;
  color: white;
  padding: 5px 10px 5px 10px;
  /* aspect-ratio: 1; */
  /* height: 100%; */
  /* margin:10px; */
}
.contract-end {
  border-radius: 50px;
  border: 2px solid #c40303;
  background-color: #c40303;
  color: white;
  padding: 5px 10px 5px 10px;
  /* aspect-ratio:1; */
  /* height: 100%; */
  /* margin:10px; */
}
</style>

<script>
import TenantModel from "@/appModel/Tenant/TenantModel";
import appLog from "@/appUtils/AppLog";
import UserInfo from "@/appUtils/UserInfo";
import SortUtil from "@/appUtils/SortUtil";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
import "flatpickr/dist/flatpickr.css";
import DateUtil from "../../appUtils/DateUtil";
import { Storage } from "aws-amplify";

export default {
  components: {
    Bar,
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init();
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  data() {
    return {
      salesChartID: "salesChart",
      tenants: [],
      willEndTenants: [],
      endTenants: [],
      salerTenant: {
        name: "",
        tenantId: "",
      },
      chartWidth: 350,
      chartHeight: 200,
      tenantChartData: {
        labels: ["", "", "", "", "", ""],
        datasets: [
          {
            label: "有効契約数",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      chartOptions: {
        scales: {
          x: {
            categoryPercentage: 0.5,
          },
          y: {
            ticks: {
              callback: function(value, index, ticks) {
                console.log(`callback: ${value}`);
                if (Math.floor(value) === value) {
                  return value;
                }
              },
            },
          },
        },
      },
      tenantChartConfig: {
        type: "line",
        data: this.tenantChartData,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0,
      },
      selectSort: 0,
      selectDirection: 0,
      selectList: [
        { label: "名前", value: 0 },
        { label: "契約開始日", value: 1 },
        { label: "契約終了日", value: 2 },
      ],
      directionList: [
        { label: "昇順", value: 0 },
        { label: "降順", value: 1 },
      ],
      loader: null,
      search: {
        tenantName: "",
        endMonth: "",
      },
      removeTenantId: [],
    };
  },
  watch: {},
  /**
   * コンピュートプロパティ
   */
  computed: {
    vuename() {
      return this.$route.name;
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.tenants.slice(this.from, this.to);
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.tenants.length;
    },
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      try {
        this.showLoader();
        // ログインユーザーの情報
        const user = await UserInfo.getUserInfo();
        // 販売店の情報
        const tenant = await TenantModel.getTenant(user.group);
        this.salerTenant.name = tenant.tenant.name;
        this.salerTenant.tenantId = user.group;
        let mainteStore = this.$store.state.mainte;
        mainteStore.title = `${this.salerTenant.name} 契約テナント管理`;
        this.$store.commit("setTenant", mainteStore);
        // 削除予定テナント一覧
        await this.removeTenantList();
        // 全件取得
        await this.getAllTenantList();
        // 今月終了予定
        await this.getWillEndList();
        // 終了後6ヶ月経過のテナント
        await this.getEndList();
        // 契約状況推移
        await this.getGraphList();
      } catch (e) {
        this.errorLog(`error ${e.message}`);
        throw e;
      } finally {
        this.hideLoader();
      }
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage;
    },
    /**
     * 契約テナント取得
     */
    async getAllTenantList() {
      // 販売店と契約しているテナントリスト

      let list = await TenantModel.getTenantList();
      let sortField = "";
      let sortDirection = "";
      switch (this.selectSort) {
        case 0:
          sortField = "tenant.name";
          break;
        case 1:
          sortField = "tenant.contractStart";
          break;
        case 2:
          sortField = "tenant.contractEnd";
          break;
        default:
          sortField = "tenant.name";
          break;
      }
      switch (this.selectDirection) {
        case 0:
          sortDirection = "ascending";
          break;
        case 1:
          sortDirection = "descending";
          break;
        default:
          sortDirection = "ascending";
          break;
      }
      list = list.filter(
        function(item) {
          console.log(
            `all data:${item.tenant.tenantId} ${this.salerTenant.tenantId}`
          );
          let flg = item.tenant.tenantId != this.salerTenant.tenantId;
          // 検索条件がある場合
          if (this.search.tenantName) {
            flg = item.tenant.name.includes(this.search.tenantName);
          }
          if (this.search.endMonth) {
            console.log(`${this.search.endMonth}`);
            flg = item.lsiStr2.includes(this.search.endMonth);
          }

          // 削除予定である場合は表示させない
          if (this.removeTenantId.includes(item.tenant.tenantId)) {
            flg = false;
          }
          return flg;
        }.bind(this)
      );

      // 契約期間判定
      for (let i = 0; i < list.length; i++) {
        let from = list[i].tenant.contractStart;
        let to = list[i].tenant.contractEnd;
        let target = DateUtil.dateStringBase(new Date(), "YYYY-MM-DD");
        if (DateUtil.isWithinPeriodTarget(from, to, target)) {
          let nowYm = DateUtil.dateStringBase(new Date(), "YYYYMM");
          let endYm = DateUtil.dateStringBase(to, "YYYYMM");
          if (nowYm == endYm) {
            // 当月で終了
            list[i].status = 2;
          } else {
            // 契約中
            list[i].status = 1;
          }
        } else {
          // 契約期間外
          list[i].status = 0;
        }
      }

      this.tenants = SortUtil.sort(list, sortField, sortDirection, false);
    },
    /**
     * 今月終了予定のリストを取得
     */
    async getWillEndList() {
      // 今月終了予定のテナント
      let todayYmd = DateUtil.dateStringBase(new Date(), "YYYYMM");
      this.willEndTenants = await TenantModel.getEndTenantList(
        this.salerTenant.tenantId,
        todayYmd
      );
      this.willEndTenants = this.willEndTenants.filter(
        function(item) {
          return item.tenant.tenantId != this.salerTenant.tenantId;
        }.bind(this)
      );
    },
    /**
     * 終了後6ヶ月経過のリスト
     */
    async getEndList() {
      let after6monthYm = DateUtil.dateStringBase(
        DateUtil.addMonth(new Date(), -6),
        "YYYYMM"
      );
      this.endTenants = await TenantModel.getEndTenantListBefore(
        this.salerTenant.tenantId,
        after6monthYm
      );
      this.endTenants = this.endTenants.filter(
        function(item) {
          return item.tenant.tenantId != this.salerTenant.tenantId;
        }.bind(this)
      );
    },
    /**
     * 6ヶ月間の契約状況取得
     */
    async getGraphList() {
      //
      let after2monthYm = DateUtil.dateStringBase(
        DateUtil.addMonth(new Date(), -2),
        "YYYYMM"
      );
      let list = await TenantModel.getActiveContractList(
        this.salerTenant.tenantId,
        after2monthYm
      );
      list = list.filter(
        function(item) {
          return item.tenant.tenantId != this.salerTenant.tenantId;
        }.bind(this)
      );

      // グラフデータをクリア
      this.tenantChartData = {
        labels: [],
        datasets: [
          {
            label: "有効契約数",
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      };

      // ラベルを設定
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(DateUtil.addMonth(new Date(), -2), "YYYY/MM")
      );
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(DateUtil.addMonth(new Date(), -1), "YYYY/MM")
      );
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(new Date(), "YYYY/MM")
      );
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(DateUtil.addMonth(new Date(), 1), "YYYY/MM")
      );
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(DateUtil.addMonth(new Date(), 2), "YYYY/MM")
      );
      this.tenantChartData.labels.push(
        DateUtil.dateStringBase(DateUtil.addMonth(new Date(), 3), "YYYY/MM")
      );

      // 契約件数をカウント
      list.forEach(
        function(item) {
          // 2ヶ月前に有効な件数
          const past2MonthYmd = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), -2),
            "YYYY-MM-DD"
          );
          const past2MonthYm = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), -2),
            "YYYYMM"
          );
          // 2ヶ月前の1日に契約開始または契約終了月ではない
          if (
            DateUtil.dateStringBase(item.tenant.contractStart, "YYYYMM") <=
              past2MonthYm &&
            (item.tenant.contractStart == past2MonthYmd ||
              DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
                past2MonthYm)
          ) {
            this.tenantChartData.datasets[0].data[0] += 1;
          }
          // 1ヶ月前に有効な件数
          const past1MonthYmd = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), -1),
            "YYYY-MM-DD"
          );
          const past1MonthYm = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), -1),
            "YYYYMM"
          );
          // 1ヶ月前の1日に契約開始または契約終了月ではない
          if (
            DateUtil.dateStringBase(item.tenant.contractStart, "YYYYMM") <=
              past1MonthYm &&
            (item.tenant.contractStart == past1MonthYmd ||
              DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
                past1MonthYm)
          ) {
            this.tenantChartData.datasets[0].data[1] += 1;
          }
          // 当月に有効な件数
          const thisMonthYmd = DateUtil.dateStringBase(
            new Date(),
            "YYYY-MM-DD"
          );
          const thisMonthYm = DateUtil.dateStringBase(new Date(), "YYYYMM");
          // 当月の1日に契約開始または契約終了月ではない
          if (
            DateUtil.dateStringBase(item.tenant.contractStart, "YYYYMM") <=
              thisMonthYm &&
            (item.tenant.contractStart == thisMonthYmd ||
              DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
                thisMonthYm)
          ) {
            this.tenantChartData.datasets[0].data[2] += 1;
          }
          // 1ヶ月後に有効な件数
          const future1MonthYmd = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 1),
            "YYYY-MM-DD"
          );
          const future1MonthYm = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 1),
            "YYYYMM"
          );
          // 1ヶ月後の1日に契約開始または契約終了月ではない
          if (
            DateUtil.dateStringBase(item.tenant.contractStart, "YYYYMM") <=
              future1MonthYm &&
            (item.tenant.contractStart == future1MonthYmd ||
              DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
                future1MonthYm)
          ) {
            this.tenantChartData.datasets[0].data[3] += 1;
          }
          // 2ヶ月後に有効な件数
          const future2MonthYmd = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 2),
            "YYYY-MM-DD"
          );
          const future2MonthYm = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 2),
            "YYYYMM"
          );
          // 2ヶ月後の1日に契約開始または契約終了月ではない
          if (
            DateUtil.dateStringBase(item.tenant.contractStart, "YYYYMM") <=
              future2MonthYm &&
            (item.tenant.contractStart == future2MonthYmd ||
              DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
                future2MonthYm)
          ) {
            this.tenantChartData.datasets[0].data[4] += 1;
          }
          // 3ヶ月後に有効な件数
          const future3MonthYmd = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 3),
            "YYYY-MM-DD"
          );
          const future3MonthYm = DateUtil.dateStringBase(
            DateUtil.addMonth(new Date(), 3),
            "YYYYMM"
          );
          // 2ヶ月前の1日に契約開始または契約終了月ではない
          if (
            item.tenant.contractStart == future3MonthYmd ||
            DateUtil.dateStringBase(item.tenant.contractEnd, "YYYYMM") >=
              future3MonthYm
          ) {
            this.tenantChartData.datasets[0].data[5] += 1;
          }
        }.bind(this)
      );
    },
    async removeTenantList() {
      console.log(`removeTenantList`);
      try {
        let list = await Storage.list(`endcontract`);
        list.forEach(
          function(item) {
            // endcontract/ncsmanager20221129114747

            let keyItems = item.key.split("/");
            if (keyItems.length >= 2) {
              this.removeTenantId.push(keyItems[1]);
            }
          }.bind(this)
        );
      } catch (e) {
        console.log(e);
      }
    },
    toWawrekiDate(dateString) {
      return DateUtil.dateStringBase(dateString, "YYYY年MM月DD日");
    },
    onClickNewTenant() {
      // Storeの情報をクリア
      let tenant = this.$store.state.tenant;
      tenant.id = "";
      this.$store.commit("setTenant", tenant);

      this.$router.push({
        name: "TenantEdit",
      });
    },
    onClickTenant(row) {
      let tenant = this.$store.state.tenant;
      tenant.id = row.tenant.tenantId;
      this.$store.commit("setTenant", tenant);
      this.$router.push({
        name: "TenantEdit",
      });
    },
    async onClickSearch() {
      await this.getAllTenantList();
    },
    /**
     * 一覧のソート変更
     */
    async changeSort(item, $event) {
      try {
        this.showLoader();
        // 全件取得
        await this.getAllTenantList();
      } finally {
        this.hideLoader();
      }
    },
    async changeDirection(item, $event) {
      try {
        this.showLoader();
        // 全件取得
        await this.getAllTenantList();
      } finally {
        this.hideLoader();
      }
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      this.loader = this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader() {
      this.loader.hide();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(message) {
      appLog.debugLog(this.vuename, this.$store.state.user.userId, message);
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(message) {
      appLog.infoLog(this.vuename, this.$store.state.user.userId, message);
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(message) {
      appLog.errLog(this.vuename, this.$store.state.user.userId, message);
    },
  },
};
</script>
