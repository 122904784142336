const short = require("short-uuid");
const baseModel = require("../baseModel");

/**
 * 累積走行距離データ取得モデルクラス
 */
class MileageModel extends baseModel {

  /**
   * lsiStr1指定で取得(mileage#プロジェクトID#yyyyMMdd)
   * @param {*} projectId 
   * @param {*} targetDateString yyyyMMdd
   */
  static async getMileageListFromlsiStr1(projectId, targetDateString) {
    return await super.getLsistr1List({ beginsWith: `mileage#${projectId}#${targetDateString}` })
  }

  /**
   * lsiStr3指定で取得(mileage#プロジェクトID#yyyyMMdd)
   * @param {*} projectId 
   * @param {*} targetDateString yyyyMMdd
   * @param {*} volumeId 予定数量ID
   */
  static async getMileageListFromlsiStr3(projectId, targetDateString, volumeId){
    return await super.getLsistr3List({ beginsWith: `mileage#${projectId}#${targetDateString}#${volumeId}` })
  }

}

module.exports = MileageModel;
