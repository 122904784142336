<template>
    <div class="projectmain-content" v-if="dbData">
      
        <!-- <base-header class="pb-1" type="">
            <div class="row align-items-center py-4">   
                <div class="col-lg-6 col-7">
                <h6 class="h2 d-inline-block mb-0">{{dbData.project.name}}</h6>
                <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
                    <route-bread-crumb></route-bread-crumb>
                </nav>
                </div>
            </div>
        </base-header> -->
        
        <!-- SplitPaneのUI -->

        <div class="project-view-container">
            <!-- <card header-classes="bg-transparent" style="height:100%;"> -->
            <!-- <split-file-view :defaultProps="defaultProps" :projectId="projectId" :project="dbData"></split-file-view> -->
          <project-view :defaultProps="defaultProps" :projectId="projectId" :project="dbData"></project-view>
            <!-- </card> -->
        </div>

    </div>
</template>

<style>
  .main-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .router-content {
    flex: 1 1 auto;
    height:100%;
  }
  .projectmain-content{
    height: 100%;
  }
  .project-view-container {
      width: 100%;
      height: 100%;
      position: relative;
      border:1px solid white;
  }
</style>

<script>


import ProjectView from '@/appViews/Projects/ProjectView'
import ProjectModel from '@/appModel/project/ProjectModel'
import appLog from "@/appUtils/AppLog"
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
export default {
  components: {
    
    ProjectView
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      query: {
        id: ""
      },
      isEditName: true,
      //dbdata:{},
      fileList: [],
      dirList: [],
      
      treeData: [
        {
          label: 'プロジェクトルート',
          id: '1',
          type: 'project',
          dirList: [],
          children: []
        }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      dbData: null
    };
  },
  watch: {
    dbData() {
      if (this.dbData) {
        let navInfo = this.$store.state.navInfo
        navInfo.title = this.dbData.project.name
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
      }
      
    }
  },
  beforeCreate(){
    //インスタンスは生成されたがデータが初期化される前
    //alert(JSON.stringify(this.$store.state.project,null,"\t"))
    //this.dbdata = this.$store.state.project
  },
  created(){    
    //インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog("ProjectMain.vue", this.$store.state.user.userId, `Start Vuex：projectId(${this.$store.state.project.projectId})`)

    this.query.id = this.$route.query.id
    this.getDBData()

  },
  beforeMount(){
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted(){
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate(){
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount(){
    //Vue インスタンスが破壊される前
  },
  unmounted(){
    //Vue インスタンスが破壊された後

  },
  beforeRouteLeave() {
    appLog.infoLog("Projects.vue", this.$store.state.user.userId, `End`)
  },
  computed: {
    // dbdata(){
    //   //alert(`${JSON.stringify(this.$store.state.project)}`)
    //   return this.$store.state.project
    // }
    projectId() {
      return this.query.id
    },
    vuename() {
      return "ProjectMain.vue"
    }
  },
  methods: {
    async getDBData() {
      const data = await ProjectModel.getProject({sk: ProjectModel.createSk(this.query.id)})
      console.log(`data:${JSON.stringify(data, null, "\t")}`)
      this.dbData = data
    },
    renderContent(h, { node, data }) {
      let retObj = null
      switch (data.type) {
      case "project":
        retObj = 
          <span>
            <span class="fa fa-tasks mr-1"></span>{node.data.label}
          </span>
        ;
        break
      case "folder":
        retObj = 
          <span>
            <span class="fa fa-folder mr-1"></span>{node.data.label}
          </span>
        ;
        break
      default:
        retObj = 
          <span>
            <span class="fa fa-folder mr-1"></span>{node.data.label}
          </span>
        ;
      }
      return retObj
    },
    handleNodeClick(data) {
      //alert(JSON.stringify(data));
      
      switch (data.type) {
      case "folder":
        this.fileList = data.fileList
        this.dirList = data.dirList
        break
      default:
        break
      }
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
    try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  }
};
</script>

<style></style>
