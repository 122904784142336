const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * 予定用のデータ取得モデルクラス
 */
class PlanModel extends baseModel {
  /**
   * テナント内の予定リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getPlanList(projectId) {
    return await super.getList({ beginsWith: `plan#${projectId}` })
  }
  /**
   * 日付指定で予定を取得する
   * @param {*} projectId 
   * @param {*} targetDateString YYYYMMDD
   */
  static async getPlanListFromYMD(projectId, targetDateString) {
    // alert(`plan#${projectId}#${targetDateString}`)
    return await super.getLsistr1List({ beginsWith: `plan#${projectId}#${targetDateString}` })
  }

  /**
   * 予定を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deletePlan(data) {
    await super.deleteData(data)
  }

  /**
   * 予定管理レコードを追加する
   * @param {*} data
   */
  static async addPlan(data) {
    await super.addData(data)
  }

  /**
   * 予定管理レコードを更新する
   * @param {*} data
   */
  static async updatePlan(data) {
    await super.updateData(data)
  }

  /**
   * 予定管理レコードを取得する
   * @param {*} data
   */
  static async getPlan(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    // plan#プロジェクトID#予定ID(uuidで採番)    
    return `plan#${projectId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, id)
    
    newRowData.plan = {
      id: id,
      createDate: "",
      temperature: 0
    }

    return newRowData
 
  }
}

module.exports = PlanModel;
