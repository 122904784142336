<template>
  <!-- <div class="card"> -->

  <application-header
    :title="title"
    :isDatePicker="false"
    :isNowTime="false"
    :isEditBase="false"
    :isEditVehicle="false"
    :isCreatePlan="false"
    :isDriveLog="false"
    :isOutputCsv="false"
    :isDriveVue="true"
    @driveVue="onClickDriveVue"
  >
  </application-header>

  <div class="d-flex drivinghistory-main">
    <div v-if="isShowTimeline" class="drivinghistory-list">
      <el-table
        class="table-responsive"
        :data="drivehistory"
        :cell-style="{
          padding: '4px',
          height: '24px',
          background: '#FFF',
          color: '#000',
        }"
        :header-cell-style="headerCellStyle"
        :header-cell-class-name="headerCellClassName"
        border
        size="mini"
        height="100%"
        style="width: 100%"
        empty-text="運行情報がありません"
      >
        <el-table-column
          prop="col1"
          label=""
          width="60px"
          align="center"
          :resizable="false"
        >
          <template v-slot="{ row }">
            <div class="driveIconBox">
              <img :src="row.img" class="bg-white avatar-sm" />
              <p>{{ row.order }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="col2"
          label="走行履歴"
          width="180px"
          align="center"
          :resizable="false"
        />
      </el-table>
    </div>

    <div class="drivinghistory-map flex-fill">
      <div class="d-flex map-header" style="margin-top:10px;margin-left:10px">
        <base-input>
          <el-select
            v-bind:placeholder="vehiclePlaceholder"
            v-model="headerVehicleId"
            readonly="true"
            @change="onChangeVehicle"
            >>
            <el-option
              v-for="item in listVehicle"
              class="select-primary"
              :value="item.id"
              :label="item.name"
              :key="item.name"
            >
            </el-option>
          </el-select>
        </base-input>

        <base-input style="margin-left:30px">
          <el-date-picker
            v-model="headerDate"
            type="date"
            class="w-100"
            widht="375px"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="日付を選択"
            :disabled-date="disabledDate"
            :picker-options="datePickerOptions"
            :shortcuts="shortcuts"
            @change="onChangeDate"
            >>
          </el-date-picker>
        </base-input>

        <base-input style="margin-left:30px;width:80px">
          <el-select placeholder="" v-model="headerHour" @change="onChangeHour">
            <el-option
              v-for="item in listHour"
              class="select-primary"
              :value="item.id"
              :label="item.name"
              :key="item.name"
            >
            </el-option>
          </el-select>
        </base-input>

        <p style="margin-left:5px;margin-top:10px">時</p>
      </div>

      <!-- 地図表示 -->
      <!-- <div class="map-area"> -->
      <driving-history-map ref="map"></driving-history-map>
      <!-- </div> -->
    </div>
  </div>
  <!-- </div> -->
</template>

<style scoped>
/** このVueだけのスタイル */
.card {
  /* 横スクロールバー非表示対策 */
  overflow-x: hidden;
  border-radius: 0;
}

.drivinghistory-main {
  /* height: calc(100% - 80px); */
  overflow-x: hidden;
  border-radius: 0;
  height: calc(100% - 65px);
  /* height: 100% */
}

.drivinghistory-list {
  width: 270px;
  height: 100%;
  /* flex: 0 0 auto; */
}

.drivinghistory-map {
  /* height: 20px; */
  height: calc(100% - 80px);
}

/* .map-area{
    height: calc(100%);
  } */

:deep .el-table th {
  background-color: #b9e3f9;
  color: #5676d9;
  font-weight: 600;
  text-align: center;
}

:deep .el-table__row {
  background-color: #fff;
}

:deep .el-table .cell .vehicle-cell td {
  background-color: #6d97f7 !important ;
  color: #fff !important;
}

:deep .el-table .vehicle-row td {
  background-color: #6d97f7 !important ;
  color: #fff !important;
}

:deep .el-table .warning-cell {
  --el-table-tr-background-color: var(--el-color-warning-lighter) !important;
  /* --el-table-tr-background-color: yellow;
    background-color: yellow !important ; */
  color: red !important;
  font-weight: bold !important;
}

.driveIconBox {
  position: relative;
}

.driveIconBox p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /* top: 4px;
    left: 16px; */
  text-align: center;
  font-size: 16 !important;
  font-weight: 1000;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: grey;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: '#263238'; */
}
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";
// import UserInfo from "@/appUtils/UserInfo"
import DrivingHistoryMap from "@/appViews/Projects/DrivingHistoryMap.vue";
import ApplicationHeader from "../components/Menu/ApplicationHeader.vue";

import VehicleModel from "@/appModel/Vehicle/VehicleModel";
import LocationModel from "@/appModel/Location/LocationModel";
const stringify = require("csv-stringify/lib/sync");
import { useToast } from "vue-toastification";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {
    DrivingHistoryMap,
    ApplicationHeader,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      projectId: null,
      projectName: null,
      datestring: null,
      listVehicle: [],
      isShowTimeline: true,
      // sample
      title: "",
      vehiclePlaceholder: null,
      headerVehicleId: null,
      headerDate: null,
      headerHour: null,
      listHour: [],
      drivehistory: [],
      datePickerOptions: {
        disabledDate: this.disabledDate,
      },
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）
      /**
       * 日付選択ショートカット
       */
      shortcuts: [
        {
          text: "今日",
          value: new Date(),
        },
        {
          text: "昨日",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            return date;
          })(),
        },
        {
          text: "1週間前",
          value: (() => {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            return date;
          })(),
        },
      ],
    };
  },

  computed: {
    vuename() {
      return "DrivingHistory.vue";
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  created() {
    // インスタンスが生成され､且つデータが初期化された後
  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
    appLog.infoLog(
      "DrivingHistory.vue",
      this.$store.state.user.userId,
      `Start Vuex：projectid(${this.$store.state.drivinghistory.projectid})、projectname(${this.$store.state.drivinghistory.projectname})、datestring(${this.$store.state.drivinghistory.datestring})`
    );
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後

    // appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    this.init();
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },
  updated() {
    // データが更新され､且つ DOM に適用された後
    // console.log(`updated`);
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前
  },

  unmounted() {
    // Vue インスタンスが破壊された後
  },

  beforeRouteLeave() {
    appLog.infoLog("DrivingHistory.vue", this.$store.state.user.userId, `End`);
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    init() {
      let loader = this.showLoader();

      try {
        // vuexの値取得
        this.projectId = this.$store.state.drivinghistory.projectid;
        this.datestring = this.$store.state.drivinghistory.datestring;
        this.projectName = this.$store.state.drivinghistory.projectname;

        if (this.projectId == "" || this.projectId == null) {
          this.showBottomToast(
            `車両一覧画面表示処理に失敗しました。（プロジェクトIDが不正）`,
            "error"
          ).then(() => {});
          this.$router.push({
            path: "/projects",
          });
        }

        // タイトルをセット
        let navInfo = this.$store.state.navInfo;
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
        navInfo.description = "";
        this.$store.commit("setNavInfo", navInfo);

        // 時間のセレクトボックス作成
        this.setHourSelect();
        this.headerHour = 9;

        // 車両一覧取得、車両セレクトボックス作成
        this.getVehicleList(this.projectId).then(() => {
          // タイトル表示（[車両名] yyyy/mm/dd XX時 の走行履歴
          let vehicleName = this.getVehicleName(this.headerVehicleId);
          if (vehicleName == null) {
            vehicleName = " 車両未登録";
          }

          this.title = `[ ${vehicleName} ] ${this.datestring} ${this.headerHour}時の走行履歴`;
          this.headerDate = this.datestring;

          // 運行情報取得
          let targetDate = DateUtil.dateStringBase(this.datestring, "YYYYMMDD");

          // 走行履歴プロット
          this.getLocationList(
            this.projectId,
            targetDate,
            this.headerVehicleId,
            this.headerHour
          );
        });

        // 連打防止
        this.repeatedHitsFlg = false;
      } catch (e) {
        this.errorLog("init", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DrivingHistory.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      return "table-header-latest";
    },

    headerCellStyle() {
      return "text-align:center";
    },

    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDate(time) {
      // 当日以降の日付は選択不可とする
      return time.getTime() > Date.now();
    },

    /**
     * 車両の一覧取得し、セレクトボックスに値セット
     */
    setHourSelect() {
      for (let i = 0; i < 24; i++) {
        let item = { id: i.toString(), name: i.toString() };
        this.listHour.push(item);
      }
    },

    /**
     * 車両の一覧取得し、セレクトボックスに値セット
     */
    async getVehicleList(projectId) {
      //車両一覧取得
      const lists = await VehicleModel.getVehicleList(projectId);

      // ソートして一覧に表示（予定登録日の降順でソート）
      let sortlists = lists.sort(function(a, b) {
        return a.vehicle.order < b.vehicle.order ? -1 : 1;
      });

      sortlists.forEach((list) => {
        let item = {
          id: list.vehicle.id,
          name: list.vehicle.name,
        };

        if (!list.invalid) {
          this.listVehicle.push(item);
        }
      });

      // 車両がないときは 車両未登録 と表示
      if (this.listVehicle.length == 0) {
        this.vehiclePlaceholder = "車両未登録";
      }

      // 最初の車両IDをセット
      this.headerVehicleId = this.getFirstVehicle();
    },

    /**
     * 最初の車両のIDを返す
     */
    getFirstVehicle() {
      let retVal = null;

      if (this.listVehicle.length > 0) {
        retVal = this.listVehicle[0].id;
      }

      return retVal;
    },

    /**
     * 車両のIDから車両名取得
     * @param vehicleId 車両ID
     */
    getVehicleName(vehicleId) {
      let retVal = null;

      for (let i = 0; i < this.listVehicle.length; i++) {
        if (this.listVehicle[i].id == vehicleId) {
          retVal = this.listVehicle[i].name;
          break;
        }
      }

      return retVal;
    },

    /**
     * 該当の運行情報を取得し、一覧に表示・地図上にプロットする
     * @param projectId プロジェクトID
     * @param targetDate プロット対象年月日
     * @param targetVehicleId プロット対象車両
     * @param targetHour プロット年月日
     */
    async getLocationList(projectId, targetDate, targetVehicleId, targetHour) {
      let loader = this.showLoader();

      try {
        // 該当の運行情報を取得（プロジェクトID、年月日、車両IDを指定）
        const searchKey = `${projectId}#${targetDate}#${targetVehicleId}`;
        let lists = await LocationModel.getLocationList(searchKey);

        // 作成日時の昇順でソート
        lists = lists.sort(function(a, b) {
          return a.location.sendDateTime < b.location.sendDateTime ? -1 : 1;
        });

        // 運行情報1日分取得するので、該当時間の運行情報を抽出
        let index = 1;
        this.drivehistory = [];

        lists.forEach((list) => {
          // 時間の範囲内かチェック OKならリストに追加
          let chkHour = String(list.location.sendDateTime).substr(11, 2);
          if (chkHour.substr(0, 1) == "0") {
            chkHour = chkHour.substr(1, 1);
          }

          // 時間チェック
          if (chkHour == targetHour) {
            let item = {
              col1: index,
              col2: DateUtil.dateStringBase(
                list.location.sendDateTime,
                "YYYY/MM/DD HH:mm"
              ),
              img: "./img/concrete/ic_circle_red.png",
              order: index,
              x: list.location.x,
              y: list.location.y,
              id: index,
            };

            this.drivehistory.push(item);
            index++;
          }
        });

        // 地図上にプロットする
        this.$refs.map.plotDrivingHistory(this.drivehistory);
      } catch (e) {
        this.errorLog("getLocationList", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DrivingHistory.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * 運行情報取得条件を取得し、運行情報プロットを実行
     */
    execPlot() {
      let loader = this.showLoader();

      try {
        // ヘッダーの文言修正
        let vehicleName = this.getVehicleName(this.headerVehicleId);
        if (vehicleName == null) {
          vehicleName = " 車両未登録";
        }
        let titleDate = DateUtil.dateStringBase(
          this.headerDate.toString(),
          "YYYY/MM/DD"
        );
        this.title = `[ ${vehicleName} ] ${titleDate} ${this.headerHour}時の走行履歴`;

        // プロット条件をセット
        let projectid = this.projectId;
        let targetDate = DateUtil.dateStringBase(
          this.headerDate.toString(),
          "YYYYMMDD"
        );

        let targetVehicleid = this.headerVehicleId; //'7CTYDxhtqzw312Tyui41RW'
        let targetHour = this.headerHour;

        // プロット実行
        this.getLocationList(
          projectid,
          targetDate,
          targetVehicleid,
          targetHour,
          false
        );
      } catch (e) {
        this.errorLog("execPlot", this.parseErrorObject(e))
        // appLog.errLog(
        //   "DrivingHistory.vue",
        //   this.$store.state.user.userId,
        //   `${e.message}`
        // );
      } finally {
        this.hideLoader(loader);
      }
    },

    /**
     * 車両一覧セレクトボックス変更時
     */
    onChangeVehicle() {
      this.execPlot();
    },

    /**
     * 年月日変更時
     */
    onChangeDate() {
      this.execPlot();
    },

    /**
     * 時間セレクトボックス変更時
     */
    onChangeHour() {
      this.execPlot();
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    hideLoader(loader) {
      loader.hide();
    },

    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue() {
      // 連打防止
      if (this.repeatedHitsFlg) return;
      this.repeatedHitsFlg = true;

      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.datestring = this.datestring;

      await this.$store.commit("setTimeline", store);

      this.$router.push({
        path: this.getRoutePath('timeline'),
      });
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
