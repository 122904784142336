const { API, graphqlOperation } = require("aws-amplify");

const {
  createKonoikeConcreteData,
  deleteKonoikeConcreteData,
  updateKonoikeConcreteData,
} = require("@/graphql/mutations");

const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
  listDataFromLsiStr1,
  listDataFromLsiStr3,
} = require("@/graphql/queries");

const UserInfo = require("../appUtils/UserInfo");
const dayjs = require("dayjs");

const pkSkOnlyList = /* GraphQL */ `
  query ListKonoikeConcreteData(
    $pk: String
    $sk: ModelStringKeyConditionInput
    $filter: ModelKonoikeConcreteDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKonoikeConcreteData(
      pk: $pk
      sk: $sk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pk
        sk
      }
      nextToken
    }
  }
`;
//require('dayjs/locale/ja');

/**
 * ミーティング用のデータ取得モデルクラス
 */
class baseModel {
  /** 1回で取得する件数 */
  static limit = 100;

  static getNowDate() {
    return dayjs().format("YYYY-MM-DD HH:mm:ss.SSS");
  }

  /**
   * リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getList(skCondition, groupId = null) {
    let pk = groupId;

    if (!pk) {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }

      pk = loginInfo.group;
    }

    if (!pk) {
      return;
    }

    let condition = {
      pk: pk,
      sk: skCondition,
    };

    return await this.getListBase(condition);
  }
  /**
   * リストを取得する(PKSKのみ)
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getListPkSk(skCondition, groupId = null) {
    let pk = groupId;

    if (!pk) {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        return null;
      }

      pk = loginInfo.group;
    }

    if (!pk) {
      return;
    }

    let condition = {
      pk: pk,
      sk: skCondition,
    };

    return await this.getPkSkOnlyListBase(condition);
  }

  static async getListBase(condition) {
    // 1回で取得する件数
    // const limit = 10;
    let nextToken = "";
    let dataList = [];

    while (nextToken !== null) {
      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(listKonoikeConcreteData, {
            pk: condition.pk,
            sk: condition.sk, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
            nextToken,
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(listKonoikeConcreteData, {
            pk: condition.pk,
            sk: condition.sk, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
          })
        );
      }

      if (result) {
        if (result.data) {
          // console.log(`getList : ${result.data.listKonoikeConcreteData.items.length}`)

          dataList = dataList.concat(result.data.listKonoikeConcreteData.items);

          // console.log(`nextToken : ${result.data.listKonoikeConcreteDatas.nextToken}`)
          nextToken = result.data.listKonoikeConcreteData.nextToken;
        }
      }
    }

    console.log(`getListBase ${JSON.stringify(dataList)}`);

    return dataList;
  }
  static async getPkSkOnlyListBase(condition) {
    let pk = condition.pk
    let sk = condition.sk

    // 1回で取得する件数
    // const limit = 10;
    let nextToken = "";
    let dataList = [];

    while (nextToken !== null) {
      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(pkSkOnlyList, {
            pk: pk,
            sk: sk, 
            limit: baseModel.limit,
            nextToken,
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(pkSkOnlyList, {
            pk: pk,
            sk: sk, 
            limit: baseModel.limit,
          })
        );
      }

      if (result) {
        if (result.data) {
          dataList = dataList.concat(result.data.listKonoikeConcreteData.items);

          nextToken = result.data.listKonoikeConcreteData.nextToken;
        }
      }
    }

    console.log(`getPkSkOnlyListBase ${JSON.stringify(dataList)}`);

    return dataList;
  }
  static async getLsistr1List(lsiStr1Condition) {
    /*
    const listDataFromLsiStr1 = 
    query ListDataFromLsiStr1(
      $pk: String
      $lsiStr1: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelKonoikeConcreteDataFilterInput
      $limit: Int
      $nextToken: String
    )
    */

    const loginInfo = await UserInfo.getUserInfo();
    if (!loginInfo.group) {
      // console.log(`グループなし`)
      return null;
    }

    // console.log(`getList:${loginInfo.group}`)

    const tenantId = loginInfo.group;
    if (!tenantId) {
      return;
    }

    // 1回で取得する件数
    // const limit = 10;
    let nextToken = "";
    let dataList = [];

    while (nextToken !== null) {
      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(listDataFromLsiStr1, {
            pk: tenantId,
            lsiStr1: lsiStr1Condition, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
            nextToken,
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(listDataFromLsiStr1, {
            pk: tenantId,
            lsiStr1: lsiStr1Condition, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
          })
        );
      }

      //alert(JSON.stringify(result))

      if (result) {
        if (result.data) {
          dataList = dataList.concat(result.data.listDataFromLsiStr1.items);

          nextToken = result.data.listDataFromLsiStr1.nextToken;
        }
      }
    }

    return dataList;
  }

  static async getLsistr3List(lsiStr1Condition) {
    /*
    const listDataFromLsiStr3 = 
    query ListDataFromLsiStr3(
      $pk: String
      $lsiStr1: ModelStringKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelKonoikeConcreteDataFilterInput
      $limit: Int
      $nextToken: String
    )
    */

    const loginInfo = await UserInfo.getUserInfo();
    if (!loginInfo.group) {
      // console.log(`グループなし`)
      return null;
    }

    // console.log(`getList:${loginInfo.group}`)

    const tenantId = loginInfo.group;
    if (!tenantId) {
      return;
    }

    // 1回で取得する件数
    // const limit = 10;
    let nextToken = "";
    let dataList = [];

    while (nextToken !== null) {
      let result;

      if (nextToken.length > 0) {
        result = await API.graphql(
          graphqlOperation(listDataFromLsiStr3, {
            pk: tenantId,
            lsiStr3: lsiStr1Condition, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
            nextToken,
          })
        );
      } else {
        // 初回取得時はnextTokenを指定するとパラメータエラーになる
        result = await API.graphql(
          graphqlOperation(listDataFromLsiStr3, {
            pk: tenantId,
            lsiStr3: lsiStr1Condition, //{ beginsWith: "meeting" },
            limit: baseModel.limit,
          })
        );
      }

      //alert(JSON.stringify(result))

      if (result) {
        if (result.data) {
          dataList = dataList.concat(result.data.listDataFromLsiStr3.items);

          nextToken = result.data.listDataFromLsiStr3.nextToken;
        }
      }
    }

    return dataList;
  }

  /**
   * レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteData(data) {
    if (!data) {
      return;
    }

    return await API.graphql(
      graphqlOperation(deleteKonoikeConcreteData, {
        input: { pk: data.pk, sk: data.sk },
      })
    );
    //alert(`ok`)
  }

  /**
   * レコードを更新する
   * @param {*} data
   */
  static async updateData(data, condition = null) {
    //TODO:更新されているかをチェックすること
    delete data.createdAt;
    delete data.updatedAt;
    // alert(`updateData`)

    if (condition) {
      return await API.graphql(
        graphqlOperation(updateKonoikeConcreteData, {
          input: data,
          condition: condition,
        })
      );
    } else {
      return await API.graphql(
        graphqlOperation(updateKonoikeConcreteData, { input: data })
      );
    }
  }

  /**
   * レコードを追加する
   * @param {*} data
   */
  static async addData(data) {
    return await API.graphql(
      graphqlOperation(createKonoikeConcreteData, { input: data })
    );
  }

  /**
   * ミーティング管理レコードを取得する
   * @param {*} data
   */
  static async getSingleData(data) {
    let pk = "";

    if (!data.pk) {
      const loginInfo = await UserInfo.getUserInfo();
      if (!loginInfo.group) {
        // console.log(`グループなし`)
        return null;
      }

      pk = loginInfo.group;
    } else {
      pk = data.pk;
    }

    if (!pk) {
      // console.log(`テナント無し`)
      return;
    }

    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, { pk: pk, sk: data.sk })
    );

    return result.data.getKonoikeConcreteData;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    const loginInfo = await UserInfo.getUserInfo();
    if (!loginInfo.group) {
      return null;
    }

    const tenantId = loginInfo.group;

    return {
      pk: `${tenantId}`,
      sk: "",
      lsiStr1: "-",
      lsiStr2: "-",
      lsiStr3: "-",
      lsiStr4: "-",
      lsiInt1: 0,
      groups: [tenantId],
      invalid: false,
      ttl: 0,
    };
  }
}

module.exports = baseModel;
