const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 通知情報のデータ取得モデルクラス
 */
class NoticeModel extends baseModel {
  /**
   * プロジェクト内の一括運行設定リストを取得する
   * @param {プロジェクト}} projectId
   * @returns
   */
  static async getNoticeList(projectId) {
    return await super.getList({ beginsWith: `notice#${projectId}`})
  }

  /**
   * 通知情報レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteNotice(data) {
    await super.deleteData(data)
  }
  
  /**
   * 通知情報レコードを追加する
   * @param {*} data
   */
  static async addNotice(data) {
    await super.addData(data)
  }
  
  /**
   * 通知情報レコードを更新する
   * @param {*} data
   */
  static async updateNotice(data) {
    await super.updateData(data)
  }
  
  /**
   * 通知情報レコードを取得する
   * @param {*} data
   */
  static async getNotice(data) {
    return await super.getSingleData(data);
  }
  
  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, dateString, id) {
    return `notice#${projectId}#${dateString}#${id}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, dateString) {
    let newRowData = await super.getNewData()
      
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, dateString, id);
      
    newRowData.notice = {
      id: id,
      noticeType: 1,
      title: "",
      content: "",
      argment: "",      
      completedPush: false,
    }
    return newRowData  
  }
}
  
module.exports = NoticeModel;
  

// const {API, graphqlOperation} = require('aws-amplify')

// const {getKonoikeConcreteData} = require('@/graphql/queries')
// const short = require('short-uuid');
// const UserModel = require("../../appModel/users/UserModel");
// const UserInfo = require("../../appUtils/UserInfo");
// const baseModel = require("../baseModel")
// const DateUtil = require("../../appUtils/DateUtil");

// /**
//  * 通知用のデータ取得モデルクラス
//  */
// class NoticeModel extends baseModel {
//   /**
//    * テナント内の通知リストを取得する
//    *
//    * @returns 通知リスト
//    */
//   static async getNoticeList() {
//     const loginInfo = await UserInfo.getUserInfo();
//     return await this.convertNoticeListData(
//       await super.getList({beginsWith: this.createBaseSk(loginInfo.sub)}), loginInfo);
//   }

//   /**
//    * 通知データリストにアイコン定義を追加します。
//    *
//    * @param {*} results 通知データリスト
//    * @param {Object} userInfo ユーザー情報
//    * @returns アイコン定義を追加した通知データリスト
//    */
//   static async convertNoticeListData(results, userInfo) {

//     let noticeList = [];

//     await Promise.all(results.map(async row => {
//       // 通知登録者のユーザー名称
//       const createUserName = await this.getUserName(userInfo, row.notice.createUser);
//       let iconClass = "";
//       let iconType = "";
//       switch (Number(row.notice.type)) {
//       case 0:
//         // ミーティング
//         iconClass = "fas fa-video";
//         iconType = "primary";
//         break;
//       case 1:
//         // ToDo通知
//         iconClass = "ni ni-check-bold";
//         iconType = "warning";
//         break;
//       case 2:
//         // 検査箇所
//         iconClass = "ni ni-pin-3";
//         iconType = "success";
//         break;
//       case 3:
//         // ユーザー間の任意内容
//         iconClass = "ni ni-circle-08";
//         iconType = "default";
//         break;
//       default:
//         iconClass = "fas fa-question"
//         iconType = "danger";
//       }
//       noticeList.push({
//         title: row.notice.id,
//         type: row.notice.type,
//         iconClass: iconClass,
//         iconType: iconType,
//         content: row.notice.content,
//         createdAt: DateUtil.dateStringDateTime(row.createdAt),
//         createUser: row.notice.createUser,
//         createUserName: createUserName,
//         Readed: row.notice.Readed,
//         needPushNotice: row.notice.needPushNotice,
//         completedPush: row.notice.completedPush,
//         linkedId: row.notice.linkedId,
//         rawData: Object.assign({}, row)
//       })
//     }))

//     // results.forEach((row) => {
//     // // results.forEach((row, index) => {
//     //   const createUserName = await this.getUserName(userInfo, row.notice.createUser);
//     //   let iconClass = "";
//     //   let iconType = "";
//     //   switch (Number(row.notice.type)) {
//     //     case 0:
//     //       // ミーティング
//     //       iconClass = "fas fa-video";
//     //       iconType = "primary";
//     //       break;
//     //     case 1:
//     //       // ToDo通知
//     //       iconClass = "ni ni-check-bold";
//     //       iconType = "warning";
//     //       break;
//     //     case 2:
//     //       // 検査箇所
//     //       iconClass = "ni ni-pin-3";
//     //       iconType = "success";
//     //       break;
//     //     case 3:
//     //       // ユーザー間の任意内容
//     //       iconClass = "ni ni-circle-08";
//     //       iconType = "default";
//     //       break;
//     //     default:
//     //       iconClass = "fas fa-question"
//     //       iconType = "neutral";
//     //   }
//     //   noticeList.push({
//     //     title: row.notice.id,
//     //     type: row.notice.type,
//     //     iconClass: iconClass,
//     //     iconType: iconType,
//     //     content: row.notice.content,
//     //     createdAt: DateUtil.dateStringDateTime(row.createdAt),
//     //     createUser: row.notice.createUser,
//     //     createUserName: createUserName,
//     //     Readed: row.notice.Readed,
//     //     needPushNotice: row.notice.needPushNotice,
//     //     completedPush: row.notice.completedPush,
//     //     linkedId: row.notice.linkedId,
//     //     rawData: Object.assign({}, row)
//     //   })
//     // })
//     return noticeList
//   }

//   /**
//    * 通知データリストの未読件数を取得します。
//    *
//    * @returns 未読件数
//    */
//   static async getNotReadNoticeCount() {
//     const notices = await this.getNoticeList();
//     let count = 0;

//     for (let i = 0; i < notices.length; i++) {
//       if (!notices[i].Readed) {
//         count++;
//       }
//     }

//     console.log('未読通知件数：' + count);
//     return count;
//   }

//   /**
//    * ログインユーザー名を取得します。
//    *
//    * @param {Object} userInfo ユーザー情報
//    * @returns ログインユーザー名
//    */
//   static async getUserName(userInfo, userSub) {

//     console.log(userInfo);
//     console.log(userSub);

//     const pk = userInfo.group;
//     const sk = UserModel.createSk(userSub);
//     // const sk = UserModel.createSk(userInfo.userName);
//     const userData = await UserModel.getUser({pk: pk, sk: sk})
//     console.log(userData);

//     return userData.user.name;
//   }

//   /**
//    * 通知を削除する
//    * @param {pk:テナントID, sk:sk} data
//    * @returns
//    */
//   static async deleteNotice(data) {
//     await super.deleteData(data)
//   }

//   /**
//    * 通知レコードを追加する
//    * @param {*} data
//    */
//   static async addNotice(data) {
//     await super.addData(data)
//   }

//   /**
//    * 通知レコードを更新する
//    * @param {*} data
//    */
//   static async updateNotice(data) {
//     await super.updateData(data)
//   }

//   /**
//    * 通知レコードを取得する
//    * @param {*} data
//    */
//   static async getNotice(data) {
//     let result = await API.graphql(graphqlOperation(getKonoikeConcreteData, {pk: data.pk, sk: data.sk}))
//     return result.data.getKonoikeConcreteData

//   }

//   /**
//    * skの文字列を生成
//    * @param {*} userId ユーザーのcognitoId
//    * @param {*} noticeId 通知ID
//    * @returns
//    */
//   static createSk(userId, noticeId){
//     return `${this.createBaseSk(userId)}#${noticeId}`;
//   }

//   /**
//    * skの基盤文字列を生成
//    * @param {*} userId
//    * @returns skの文字列
//    */
//   static createBaseSk(userid) {
//     return `notice#${userid}`;
//   }

//   /**
//    * 新規登録用のスキーマ
//    */
//   static async getNewData() {
//     let newRowData = await super.getNewData()
//     const userInfo = await UserInfo.getUserInfo()
//     console.log(userInfo);
//     const id = short.generate()
//     newRowData.sk = this.createSk(userInfo.sub, id);

//     newRowData.notice = {
//       id: id,
//       targetUsers: [],
//       type: "",
//       content: "",
//       createUser: userInfo.sub,
//       Readed: false,
//       needPushNotice: false,
//       completedPush: false,
//       linkedId: ""
//     }

//     return newRowData
//   }

// }

// module.exports = NoticeModel
