const { API, graphqlOperation } = require("aws-amplify");
const {
  getKonoikeConcreteLog,
} = require("@/graphql/queries");

const lodash = require("lodash");
const { group } = require("d3");

class LogModel {
  /**
   * 最終ログイン日を取得する
   * @param {*} data
   */
  static async getLastLogin(group) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteLog, { pk: group, sk: "lastLogin" })
    );
    console.log(`getLastLogin ${JSON.stringify(result)}`)
    return result.data.getKonoikeConcreteLog;
  }
}
module.exports = LogModel;