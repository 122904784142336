<template>
  <div>
    <!-- Page content -->
    <div class="container mt-5" >
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card bg-white border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="row align-items-center">
                <div class="col-sm-12">
                  <!-- <h2 class="mb-0">{{$t("ログイン")}}</h2> -->
                  <!-- <img src="/siteid_01.png" class="login-cplogo" /> -->
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12">
                  <h2 class="mb-0 text-center login-title pb-4">{{appTitle}}</h2>
                </div>
              </div>
              <div class="row mt-3 justify-content-center">
                <div class=" col-md-8">
                  <base-input
                    alternative
                    
                    label="ユーザーID"
                    >
                    <el-input
                      addon-left-icon="fas fa-user"
                      name="userid"
                      placeholder="ユーザーID"
                      v-model="userId"
                      v-on:keydown.enter="signin"
                    ></el-input>
                  </base-input>

                  <base-input alternative label="パス
                  ワード">
                    <el-input
                      type="password"
                      placeholder="パスワード"
                      v-model="password"
                      autocomplete="off"
                      v-on:keydown.enter="signin"
                    ></el-input>
                  </base-input>

                  <div class="text-center mt-5">
                    <!-- <base-button icon type="primary" @click="signin" class="login-button">
                      <span class="btn-inner--icon"><i class="fa fa-sign-in-alt"></i></span>
                      <span class="btn-inner--text">ログイン</span>
                    </base-button> -->
                    
                    <button type="button" class="btn btn-primary w-100" @click="signin">ログイン</button>
                    
                  </div>
                  
                  <div class="text-left mt-5">
                    <base-button type="link" @click="clickForgotPassword" v-if="enableForgotPassword">
                      パスワードを忘れた場合
                    </base-button>
                  </div>
                  <div class="text-center mt-5 text-default" style="font-size:0.8em;">
                    ver {{appver}}
                  </div>
                </div>
                <div class="col-md-12" v-if="notices.length">
                  <div class="card">
                    <!-- Card header -->
                    <div class="card-header">
                      <!-- Title -->
                      <h5 class="h3 mb-0">お知らせ</h5>
                    </div>
                    <!-- Card body -->
                    <div class="card-body">
                      <div
                        v-if="notices.length"
                        class="timeline timeline-one-side"
                        data-timeline-content="axis"
                        data-timeline-axis-style="dashed">

                        <div v-for="notice in notices" :key="notice.title" class="timeline-block">
                          <span class="timeline-step" :class="`badge-${notice.type}`">
                            <i :class="notice.icon"></i>
                          </span>
                          <div class="timeline-content">
                            <div>
                              <small>{{ notice.createDate }}</small>
                            </div>
                            <div class="d-flex justify-content-between pt-1">
                              <div>
                                <span class="text-sm font-weight-bold">{{
                                  notice.title
                                }}</span>
                              </div>

                            </div>
                            <h6 class="text-sm mt-1 mb-0">
                              <small style="white-space:pre-wrap; word-wrap:break-word;">{{ notice.description }}</small>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <small>お知らせはありません</small>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.login-button{
  width:100%;
  border-radius: 50px 50px 50px 50px !important;
}
.login-cplogo{
  object-fit: contain;
  width: 300px;
  height:60px;
}

.card {
  box-shadow:none !important;

}
h2.login-title:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  content: '';
  border-radius: 3px;
  /* background-color: #FADCE9; */
  /* background-image: -webkit-gradient(linear, right top, left top, from(#FADCE9), to(#E4007F)); */
  background-image: -webkit-linear-gradient(right, #ffffff 0%, #003C9C 500%, #ffffff 100%);
  background-image: linear-gradient(to left, #ffffff 0%, #003C9C 50%, #ffffff 100%);
}
</style>
<script>
import { Storage } from "aws-amplify";
// import { Form } from "vee-validate";
import { Auth } from 'aws-amplify'
// import path from 'path'
import UserInfo from "@/appUtils/UserInfo"
import TenantModel from "@/appModel/Tenant/TenantModel"
import UserModel from "@/appModel/users/UserModel"

import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"
import { useToast } from "vue-toastification";

import pkg from "../../../package"

export default {
  components: {
  },
  beforeCreate() {
    console.log(`beforeCreate`)
    //インスタンスは生成されたがデータが初期化される前
    
  },
  created() {
    console.log(`created`)
    //インスタンスが生成され､且つデータが初期化された後
    appLog.infoLog("Signin.vue", this.$store.state.user.userId, `Start`)
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    console.log(`mounted`)
    //インスタンスが DOM 要素にマウントされた後
    // appLog.infoLog("Signin.vue", '', `start signIn.`)
    // appLog.infoLog({view: "Signin.vue", message: `start signIn.`})
    this.init().then(() => {
    })
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    // appLog.infoLog("Signin.vue", '', `end signIn.`)
    // appLog.infoLog({view: "Signin.vue", message: `end signIn.`})
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    appLog.infoLog("Signin.vue", this.$store.state.user.userId, `End`)
  },
  data() {
    return {
      test: "",
      userId: "",
      password: "",
      rememberMe: false,
      appver: "",
      appTitle: "",
      enableForgotPassword: true,
      notices: [
      ],
      systemJsonLoadedFlg: false
      
    };
  },
  methods: {
    async init() {
      
      this.appver = pkg.version
      this.appTitle = process.env.VUE_APP_TITLE
      if (process.env.VUE_APP_ENABLE_FORGET_PASS == '0') {
        this.enableForgotPassword = false
      } else {
        this.enableForgotPassword = true
      }

    },
    
    /**
     * ログイン
     */
    async signin() {

      let loader = this.showLoader();

      try {

        if (!this.userId || !this.password) {
          this.showBottomToast(`ユーザーIDまたはパスワードを入力してください。`, 'error')
          return
        }
        // Cognitoサインイン
        await Auth.signIn(this.userId, this.password)
        appLog.infoLog("Signin.vue", this.userId, `signIn success userId:${this.userId}`)
        
        // ユーザ情報取得
        const user = await UserInfo.getUserInfo()

        

        
        // 削除ユーザーかどうかの確認
        const pk = user.group
        const sk = UserModel.createSk(user.sub)
        const userData = await UserModel.getUser({pk: pk, sk: sk})
        if (userData.user.deleted != null && userData.user.deleted == true) {
          appLog.warnLog("MainteSignin.vue", this.userId, `deletedUser userId:${this.userId}`)
          this.showBottomToast(`このユーザーは削除されています。`, 'error')
          return;
        }
        console.log(JSON.stringify(userData,null, "\t"))

        // 代理店ユーザーのみログイン可能
        if (userData.user.usertype != "10") {
          this.showBottomToast(`利用できないユーザーです`, 'error')
          return
        }
        // Vuexにユーザー情報をセット
        let userStore = this.$store.state.user
        userStore.cognitoSub = user.sub //Cognitoで管理する一意なUserId
        userStore.userId = userData.user.userId //ログイン時に使用するユーザーID
        userStore.userType = userData.user.usertype // 0:一般 1:管理者 2:スーパーユーザ― 3:iPhone(ドライバー）ユーザー 99:NCSメンテナンスユーザー
        userStore.userName = userData.user.name // ユーザー名
        userStore.loginDateTIme = DateUtil.dateStringDateTime() // ログイン日時（ログインからn日後にログアウトさせるような場合に使用する）
        userStore.group = user.group // 所属するユーザーグループ
        this.$store.commit("setUser", userStore)

        // 閲覧可能ユーザーはダッシュボードへ
        appLog.infoLog("MainteSignin.vue", this.userId, `start.`)
        // appLog.infoLog({view: "Signin.vue", message: `start mcz.`})
        this.$router.push({
          name: 'TenantManage'
        })
        return
        
      } catch (e) {
        appLog.errLog("MainteSignin.vue", this.$store.state.user.userId, `${e.message}`)
        switch (e.code) {
        case "UserNotFoundException":
          this.showBottomToast(`ログインできません。ユーザー名、パスワードをご確認ください`, 'error')
          // alert(`認証エラー`)

          break
        case "NotAuthorizedException":
          this.showBottomToast(`ログインできません。ユーザー名、パスワードをご確認ください`, 'error')
          // alert(`認証エラー`)
          break
        case "UserLambdaValidationException":
          this.showFailedMessage(e.message);
          break
        case "InvalidParameterException":
          this.showBottomToast(`ユーザー名またはパスワードが未入力`, 'warning')
          // alert(`ユーザー名またはパスワードが未入力`)
          break
        default:
          throw e
        }
      } finally {
        this.hideLoader(loader);
      }
    },
    clickForgotPassword() {

      // 認証コードを送信へ
      this.$router.push({
        name: 'ForgotPassword'
      })
      return;
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },

    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
    showFailedMessage(exceptionMessage) {

      if (exceptionMessage.includes(`PreAuthentication`)) {
        this.showBottomToast(`ログインできません。ユーザー名、パスワード、ご契約状況をご確認ください`, `error`)
      }
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(message) {
      appLog.debugLog(this.vuename, this.$store.state.user.userId, message)
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(message) {
      appLog.infoLog(this.vuename, this.$store.state.user.userId, message)
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(message) {
      appLog.errLog(this.vuename, this.$store.state.user.userId, message)
    }
  },
};
</script>
