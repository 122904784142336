<template>

  <div class="wrapper">
    <side-bar >
      <template v-slot:links>
        
        <el-tooltip class="item" effect="dark" content="ユーザー管理を表示" placement="right">
        <sidebar-item
          :link="{
            name: 'ユーザー管理',
            path: '/userlist',
            icon: 'ni ni-single-02 text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="生コン運行管理プロジェクト一覧を表示" placement="right" v-if="allowNamacon">
        <sidebar-item
          :link="{
            name: '生コン運行管理',
            path: '/projects',
            icon: 'mixer-icon text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="ダンプカー運行管理プロジェクト一覧を表示" placement="right" v-if="allowZando">
        <sidebar-item
          :link="{
            name: 'ダンプカー運行管理',
            path: '/zandoprojects',
            icon: 'dump-icon text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="システム設定画面を表示" placement="right">
        <sidebar-item
          :link="{
            name: 'システム設定',
            path: '/system',
            icon: 'fa fa-tools text-primary', 
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="利用端末管理一覧を表示" placement="right" v-if="this.showOnlyForAdmin">
        <sidebar-item
          :link="{
            name: '利用端末管理',
            path: '/devicelist',
            icon: 'fa fa-mobile text-primary',
          }"
        >
        </sidebar-item>
        </el-tooltip>
        <!--1行分改行-->
        <div><p><br></p></div>

        <el-tooltip class="item no-bold" effect="dark" content="生コン運行管理マニュアル" placement="right" v-if="allowNamacon">
        <sidebar-item
          :link="manualLink"
        >
        </sidebar-item>
        </el-tooltip>

        <el-tooltip class="item no-bold" effect="dark" content="ダンプカー運行管理マニュアル" placement="right" v-if="allowZando">
        <sidebar-item
          :link="zandomanualLink"
        >
        </sidebar-item>
        </el-tooltip>
        

        <sidebar-item
            v-if="false"
            :link="{
                name: '開発',
                icon: 'fa fa-tools text-primary',
            }"
        >
            <sidebar-item :link="{name: 'DB登録サンプル', path: '/dbresistsample', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'DB登録サンプル（予定情報）', path: '/dBRegistSamplePlan', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'DB登録サンプル（拠点情報）', path: '/dBRegistSampleBaseArea', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'DB登録サンプル（運行情報）', path: '/dBRegistSampleDrive', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'DB登録サンプル（iOS送信座標情報）', path: '/dBRegistSampleLocation', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: 'UIサンプル', path: '/uisample', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
            <sidebar-item :link="{name: '地図表示', path: '/timeline', icon: 'ni ni-map-big text-primary',}"></sidebar-item>
        </sidebar-item>

      </template>

    </side-bar>
    <div class="main-content" :class="{'zando': isZando, 'namacon': isNamacon}">
      
      <dashboard-navbar :type="$route.meta.navbarType" :appType="appType" ></dashboard-navbar>
      
      <div class="router-content" @click="$sidebar.displaySidebar(false)">
          <router-view :key="$route.fullPath"></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>

      
    </div>

  </div>
</template>
<style>
  .main-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .nav-link-text {
    font-size:1.0rem !important;
  }
  .active > .nav-link-text{
    font-weight: bold;
  }
  .no-bold .nav-link-text{
    font-weight: normal !important;
  }
  .mixer-icon {
    margin-right:16px;
    background-image: url(/img/concrete/mixer_black.png);
    background-size:cover;
    height:18px;
    width:18px;
    min-width: 18px !important;
    color: #003C9C !important;
    filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(247deg) brightness(70%) contrast(145%);
  }
  .dump-icon {
    margin-right:16px;
    background-image: url(/img/concrete/dump_black.png);
    background-size:cover;
    height:18px;
    width:18px;
    min-width: 18px !important;
    color: #003C9C !important;
    filter: invert(8%) sepia(99%) saturate(7044%) hue-rotate(247deg) brightness(70%) contrast(145%);
  }
  
</style>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import appLog from "@/appUtils/AppLog"
import "perfect-scrollbar/css/perfect-scrollbar.css";
// import NoticeEdit from '@/appViews/Notice/NoticeEdit.vue'
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import SideBar from '../../components/MCZSidebarPlugin/SideBar.vue';
import SidebarItem from '../../components/MCZSidebarPlugin/SidebarItem.vue';
// import SideBar from '../../components/SidebarPlugin/SideBar.vue';
// import SidebarItem from '../../components/SidebarPlugin/SidebarItem.vue';
import UserInfo from '../../appUtils/UserInfo'
// import NoticeModel from "@/appModel/Notice/NoticeModel"
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SideBar,
    // NoticeEdit,
    SidebarItem,
  },
  mixins: [UseApps, Logger],
  data() {
    return {
      isTooltip: false,
      env: "dev",
      appType: "mcz",
      ncsManager: false,
      userType: "0",
      noticeEditShow: false,
      notReadNoticeCount: "",
      manualLink: {
        name: '生コン運行管理マニュアル',
        path: '#',
        target: "_blank",
        
        event: this.toManual, 
        icon: 'fa fa-question-circle text-primary',
      },
      zandomanualLink: {
        name: 'ダンプカー運行管理マニュアル',
        path: '#',
        target: "_blank",
        event: this.toZandoManual, 
        icon: 'fa fa-question-circle text-primary',
      }
    }
  },
  computed: {    
    debug() {
      return process.env.NODE_ENV == 'dev' || process.env.NODE_ENV == 'local'
    },
    vuename() {
      return "DashboardLayout.vue"
    },
    showOnlyForAdmin() {
      // ログインユーザーが管理者、スーパー管理者の場合のみTrue
      return this.$store.state.user.userType == 1 || this.$store.state.user.userType == 2
    }
  },
  watch: {

  },
  methods: {
    
    isExpandSidebar() {
      const bodyClass = document.body.getAttribute("class")
      if (!bodyClass) {
        return false
      }
      if (bodyClass.indexOf("g-sidenav-show") > 0) {
        // サイドバー表示中
        return true
      } else {
        // サイドバー非表示
        return false
      }
    },
    async init() {
      const loginUser = await UserInfo.getUserInfo()
      this.ncsManager = loginUser.group == "ncsmanager"

      // console.log(`this.ncsManager:${this.ncsManager}`)

      this.env = process.env.NODE_ENV
    },
    
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    
    toManual() {
      window.open(`./manual/index.html`, '_blank')
    },
    toZandoManual() {
      window.open(`./zandomanual/index.html`, '_blank')
    },
    
    /**
     * コンソール出力のみ。
     */
    debugLog(message) {
      appLog.debugLog(this.vuename, this.$store.state.user.userId, message)
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(message) {
      appLog.infoLog(this.vuename, this.$store.state.user.userId, message)
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(message) {
      appLog.errLog(this.vuename, this.$store.state.user.userId, message)
    }
  },
  async mounted() {
    await this.getUseApp()
    this.init()
    this.initScrollbar();
    
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
    // console.log('beforeCreate');
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    // console.log('created');
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    // console.log('beforeMount');
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
    // console.log('beforeUpdate');
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
    // console.log('updated');

    // 現在のパスを取得
    const path = this.$route.path;
    // console.log(path)

    if (path == '/noticeList') {
      // 通知を表示した際は未読がすべて既読になるのでクリア
      this.notReadNoticeCount = '';
    }
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    // console.log('beforeUnmount');
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    // console.log('unmounted');
  }
};
</script>
<style lang="scss"></style>
