<template>
  <div id="nav"></div>
  <router-view />
</template>

<style lang="scss">
// @import "./assets/sass/custom/_variables.scss";
// テーブルヘッダー行カラー設定
.app-th-row > th {
  overflow: hidden !important;
  user-select: none !important;
  background-color: #f8f9fe !important;
}

// システム全体のテーブルヘッダーが書き変わってしまうので却下
// .el-table th.el-table__cell {
//   overflow: hidden !important;
//   user-select: none !important;
//   background-color: #F8F9Fe;
// }

// ソートキャレット定義
.caret-wrapper {
  display: inline-flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 18px !important;
  // height: 14px;
  width: 24px !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  overflow: initial !important;
  position: relative !important;
}

.sort-caret {
  width: 0 !important;
  height: 0 !important;
  border: solid 5px transparent !important;
  position: absolute !important;
  left: 7px !important;
}

.sort-caret.ascending {
  border-bottom-color: var(--el-text-color-placeholder) !important;
  top: -5px !important;
}
.ascending .sort-caret.ascending {
  border-bottom-color: var(--el-color-primary) !important;
}

.sort-caret.descending {
  border-top-color: var(--el-text-color-placeholder) !important;
  bottom: -3px !important;
}

.descending .sort-caret.descending {
  border-top-color: var(--el-color-primary) !important;
}

</style>

<script>
import UserInfo from "@/appUtils/UserInfo";
import AppLog from "@/appUtils/AppLog";

export default {
  data() {
    return {
      currentUserInfo: {
        cognitoId: "",
        userName: "",
        sub: "",
        group: "",
      },
      appLogger: null,
    };
  },
  computed: {
    userInfo() {
      return this.currentUserInfo;
    },
    logger() {
      return this.appLogger
    },
  },

  async mounted() {
    //インスタンスが DOM 要素にマウントされた後
    
    // リリース時にconsole無効化
    const loglevelString = process.env.VUE_APP_LOG_LEVEL;
    const logLevelList = { DEBUG: 0, INFO: 1, WARN: 2, ERROR: 3 };
    if (!logLevelList[loglevelString] == 0) {
      if (!window.console) {
        window.console = {};
      }
      let methods = ["log", "debug", "warn", "info"];

      for (let i = 0; i < methods.length; i++) {
        console[methods[i]] = function() {};
      }
    }
  },
  methods: {
    /**
     * ログイン情報をセット（ログイン画面から呼び出される）
     */
    setLoginInfo(userInfo) {
      this.currentUserInfo = userInfo;
      this.appLogger = new AppLog(this.currentUserInfo.group, this.currentUserInfo.userName)
    },
    /**
     * 現在のログインユーザー情報を取得
     */
    async getUserInfo() {
      if (!this.currentUserInfo.sub) {
        console.log(`Cognitoから取得`);
        const userInfo = await UserInfo.getUserInfo();
        this.currentUserInfo = userInfo;
      }
      return this.currentUserInfo;
    },
  },
};
</script>
