<template>
  <make-user />
</template>

<style scoped>
  /** このVueだけのスタイル */
</style>
<style >
  /** 全体に影響するスタイル */
</style>

<script>
import appLog from "@/appUtils/AppLog"
import MakeUser from "@/appViews/User/MakeUser" 


export default {
  components: {
    MakeUser
  },
  data() {
    return {      
    };
  },
  computed: {
    vuename() {
      return this.$route.name
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
  },
};
</script>
