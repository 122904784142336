const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const settingModel = require("../settingModel")

/**
 * スポットタイプ設定用のデータ取得モデルクラス
 */
class SpotTypeBaseModel extends settingModel {  
  /**
   * スポットタイプ設定リストを取得する
   * @returns
   */
  static async getSpotTypeBaseList() {
    return await super.getList(`spottypebase`, { beginsWith: `spottypebase#`})
  }
}

module.exports = SpotTypeBaseModel;
