<template>

  <el-tabs
    v-model="CO2SettingModel"
    type="card"
    class="demo-tabs"
    :before-leave="checkLeaveTab"
  >
  <el-tab-pane label="CO2排出量算出設定" name="0">
  <div class="bg-white">
    <div class="container-fluid mt-1 mb-4" style="border:0px solid red;">
      <h3>CO2排出量算出設定</h3>
      <el-form :model="formData" :rules="rules" ref="form" class="ml-2">
        <div>
          <div class="d-flex align-items-center ml-2">
            <h4>5秒間速度超過<require-tag /></h4>
            <el-tooltip
              class="box-item"
              effect="dark"
              content="5秒間の間、速度が値以上となった場合に速度超過と判定を行います。"
              placement="right"
            >
              <i
                class="far fa-question-circle"
                style="margin-bottom:8px; margin-left:5px;"
              ></i>
            </el-tooltip>
          </div>
          <el-form-item prop="speedThreshold" class="ml-4">
            <el-input
              name="speedThreshold"
              style="width:60px"
              :disabled="editMode == 1"
              autocomplete="off"
              placeholder="数値でkm/h値を入力"
              v-model="formData.speedThreshold"
            ></el-input>
          <span>&nbsp;km/h</span>
          </el-form-item>
        </div>
        <div class="d-flex align-items-center ml-2">
          <h4>急発進判定<require-tag /></h4>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="5秒間の間の速度変化量がこの値を超えていた場合に急発進と判定します。"
            placement="right"
          >
            <i
              class="far fa-question-circle"
              style="margin-bottom:8px; margin-left:5px;"
            ></i>
          </el-tooltip>
        </div>
        <el-form-item prop="suddenStartValue" class="ml-4 d-flex">
          <span class="mr-2">5秒間で</span>
          <el-input
            name="suddenStartValue"
            class="mr-2"
            style="width:60px"
            :disabled="editMode == 1"
            autocomplete="off"
            placeholder="km/hで入力"
            v-model="formData.suddenStartValue"
          ></el-input>
          <span>km/h 以上の変化時</span>
        </el-form-item>
        <div class="d-flex align-items-center ml-2">
          <h4>急停止判定<require-tag /></h4>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="五秒間の間の速度変化量がこの値を超えていた場合に急停車と判定します。"
            placement="right"
          >
            <i
              class="far fa-question-circle"
              style="margin-bottom:8px; margin-left:5px;"
            ></i>
          </el-tooltip>
        </div>
        <el-form-item prop="suddenStopValue" class="ml-4 d-flex">
          <span class="mr-2">5秒間で</span>
          <el-input
            name="suddenStopValue"
            class="mr-2"
            style="width:60px"
            :disabled="editMode == 1"
            autocomplete="off"
            placeholder="km/hで入力"
            v-model="formData.suddenStopValue"
          ></el-input>
          <span>km/h 以上の変化時</span>
        </el-form-item>
        <div class="d-flex align-items-center ml-2">
          <h4>エコ運転判定<require-tag /></h4>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="急発進、急停車、速度超過をカウントします。合計回数にてエコ運転の達成度合いを判定します。"
            placement="right"
          >
            <i
              class="far fa-question-circle"
              style="margin-bottom:8px; margin-left:5px;"
            ></i>
          </el-tooltip>
        </div>
        

        <div>
          <div class="d-flex pb-2 mt-3 ml-4" style="border-bottom: 1px solid #666666;">
            <div class="w150 text-center font-weight-bold" style="border:0px solid red;">
              ランク
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              判定回数
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              燃費係数
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              予想燃費(10t車)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              備考
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              A判定
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="AClassCount" class="ml-4 d-flex">
                <el-input
                  name="AClassCount"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  ref="AClassCount"
                  v-model="formData.AClassCount"
                ></el-input>
                <span>回以下</span>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="AClassFuelParam" class="ml-4 d-flex">
                <el-input
                  name="AClassFuelParam"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  ref="AClassFuelParam"
                  v-model="formData.AClassFuelParam"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              {{ArankFuel}}
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage1" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage1"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.ArankFuelMemo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              B判定
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="BClassCount" class="ml-4 d-flex">
                <el-input
                  name="BClassCount"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.BClassCount"
                ></el-input>
                <span>回以下</span>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="BClassFuelParam" class="ml-4 d-flex">
                <el-input
                  name="BClassFuelParam"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  ref="BClassFuelParam"
                  v-model="formData.BClassFuelParam"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              {{BrankFuel}}
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage1" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage1"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.BrankFuelMemo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w150 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              C判定
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="CClassCount" class="ml-4 d-flex">
                <!-- <el-input
                  name="CClassCount"
                  class="w100 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.CClassCount"
                ></el-input>
                <span>回以下</span> -->
                <span>B判定を超過</span>
              </el-form-item>

            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="CClassFuelParam" class="ml-4 d-flex">
                <el-input
                  name="CClassFuelParam"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.CClassFuelParam"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              {{CrankFuel}}
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage1" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage1"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.CrankFuelMemo"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center ml-2">
          <h4>基準燃費<require-tag /></h4>
          <el-tooltip
            class="box-item"
            effect="dark"
            content="車両サイズ別の燃費既定値を定義します。CO2排出量の計算で使用します。"
            placement="right"
          >
            <i
              class="far fa-question-circle"
              style="margin-bottom:8px; margin-left:5px;"
            ></i>
          </el-tooltip>
        </div>
        <div class="ml-4">
          <span>各最大積載量に応じた経由での燃費を入力してください。</span>
        </div>

        <div>
          <div class="d-flex pb-2 mt-3 ml-4" style="border-bottom: 1px solid #666666;">
            <div class="w300 text-center font-weight-bold" style="border:0px solid red;">
              最大積載量
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              燃費(km/l)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              備考
            </div>
            <div class="col-md-2" style="margin-left:auto">
              <base-button
                type="primary"              
                size="sm"
                block
                @click="onClickDefault"
                >初期値にリセット</base-button
              >
            </div>
          </div>


          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              2000～3999kg (約0.8～1.3㎥)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="fuelMileage1" class="ml-4 d-flex">
                <el-input
                  name="fuelMileage1"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.fuelMileage1"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage1Memo" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage1Memo"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.fuelMileage1Memo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              4000～5999kg (約1.4～2.3㎥)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="fuelMileage2" class="ml-4 d-flex">
                <el-input
                  name="fuelMileage2"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.fuelMileage2"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage2Memo" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage2Memo"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.fuelMileage2Memo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              6000～7999kg (約2.4～3.1㎥)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="fuelMileage3" class="ml-4 d-flex">
                <el-input
                  name="fuelMileage3"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.fuelMileage3"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage3Memo" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage3Memo"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.fuelMileage3Memo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              8000～9999kg (約3.2～3.9㎥)
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="fuelMileage4" class="ml-4 d-flex">
                <el-input
                  name="fuelMileage4"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.fuelMileage4"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage4Memo" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage4Memo"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.fuelMileage4Memo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="d-flex pb-2 mt-2 ml-4">
            <div class="w300 text-center font-weight-bold" style="line-height:40px;border:0px solid red;height:40px;">
              10000～11999kg （約4.0㎥～）
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red;">
              <el-form-item prop="fuelMileage5" class="ml-4 d-flex">
                <el-input
                  name="fuelMileage5"
                  class="mr-2"
                  style="width:60px"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="数値で入力"
                  v-model="formData.fuelMileage5"
                ></el-input>
              </el-form-item>
            </div>
            <div class="w200 text-center font-weight-bold" style="border:0px solid red">
              <el-form-item prop="fuelMileage5Memo" class="ml-6 d-flex">
                <el-input
                  name="fuelMileage5Memo"
                  class="w400 mr-2"
                  :disabled="editMode == 1"
                  autocomplete="off"
                  placeholder="備考を入力"
                  v-model="formData.fuelMileage5Memo"
                ></el-input>
              </el-form-item>
            </div>
          </div>

          <div class="col-md-3" style="margin-left:auto;padding:10px">
            <base-button
              type="primary"              
              block
              @click="onClickSave"
              >保存する</base-button
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
  </el-tab-pane>


    <el-tab-pane label="岩種マスタ" name="1" v-if="allowZando">
      <div>
        <div class="card">
          <div class="card-header border-0">
            
            <!-- メニュー 削除・編集・上へ・下へを有効 -->
            <div class="row m1-1">
              <table-tool-menu
                      class="RockMenu"
                      :title="title"
                      :addTitle="addTitle"
                      :editTitle="editTitle"
                      :deleteTitle="deleteTitle"
                      :copyTitle="copyTitle"
                      :isAdd="isAdd"
                      :isDelete="false"                    
                      :isEdit="isEdit"
                      :isCopy="isCopy"
                      :isSingleSelect="selectRow"
                      :isMultiSelect="false"
                      :processing="false"
                      :isOrderMove="isOrderMove" 
                      :disableMoveDown="disableMoveDown"
                      :disableMoveUp="disableMoveUp"
                      @addNew="onAddRockName()"
                      @edit="onEditRockName(selectedData)"
                      @delete="onDeleteRockName()"
                      @moveRow="onClickRowMove"
                      
                  ></table-tool-menu>
            </div>
          </div>

          <!-- 岩種一覧テーブル -->
          <div class="rock-list">
            <el-table
              ref="RockTable"
              class="table-responsive align-items-center table-flush"
              header-row-class-name="app-th-row"
              select-on-indeterminate="true"
              :default-sort="{ prop: 'rocktype.name', order: 'descending' }"
              @sort-change="runSort"
              @select="handleSelectRow"
              @select-all="handleSelectAllRow"
              :data="pagedData"
              >
              <el-table-column v-if="isAdd" type="selection" align="left" min-width="120px"></el-table-column>
                <el-table-column
                  label="岩種名"
                  min-width="200px"
                  width="200px"
                  prop="rocktype.name"
                  >
                </el-table-column>
                <el-table-column 
                  label="積載量" 
                  min-width="100px" 
                  prop="rocktype.loadCapacity" 
                  >
                  <template v-slot="{ row }">
                    {{ row.rocktype.loadCapacity }} ㎥/台
                  </template>
                </el-table-column>
              </el-table>

              <!-- ページング -->
               <div class="row mt-1">
                <div class="col">
                  <div class="mt-1 d-flex justify-content-center">
                    <el-pagination
                      @current-change="setCurrent"
                      :page-size="pagination.perPage"
                      :current-page="pagination.currentPage"
                      layout="prev, pager, next"
                      :total="total"
                    ></el-pagination>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>


  
      <!-- 岩種の追加・編集（モーダル）-->
      <modal v-model:show="modals.editRockDlg" size="sm" body-classes="p-0">
          <template v-slot:header>
            <h6 class="modal-title">{{confirmTitle}}</h6>
          </template>
          <card
            type="secondary"
            header-classes="bg-transparent pb-5"
            body-classes="px-lg-2 py-lg-2"
            class="border-0 mb-0">

          <el-form :model="rockTypeFormData" :rules="rockrules" ref="form1">
                <h4>岩種名<require-tag/></h4>
                <el-form-item prop="rockTypeName">
                   <div class="d-flex align-items-center mb-3">
                      <el-input
                        name="rockTypeName"
                        autocomplete="off"
                        style="width:320px"
                        placeholder="岩種名を入力"
                        v-model="rockTypeFormData.rockTypeName"
                      ></el-input>
                    </div>
                </el-form-item>

                <h4>積載量<require-tag/></h4>
                <el-form-item prop="loadCapacity">
                  <div class="d-flex align-items-center mb-3">
                    <el-input
                      name="loadCapacity"
                      autocomplete="off"
                      style="width:auto"
                      placeholder="数値で入力"
                      v-model="rockTypeFormData.loadCapacity"
                    ></el-input> 
                    <h4 style="margin-top:10px"> ㎥/台</h4> 
                  </div>
                </el-form-item>
          </el-form>

          </card>

          <!-- キャンセル、更新ボタン -->
          <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button type="secondary" block @click="modals.editRockDlg = false" >キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
              <base-button type="primary" block @click="onClickSubmit">{{execBtnCaption}}</base-button>
            </div>
          </div>
        </modal>

          <!-- 処理確認モーダル -->
        <modal v-model:show="modals.confirmDlg">
            <template v-slot:header>
                <h5 class="modal-title" id="modalLabel">{{confirmTitle}}</h5>
            </template>
            <div class="mb-5">
              {{confirmMessage}}
            </div>
            <div class="row mt-2 mb-2 ml-2 mr-2">
            <div class="col-sm-5">
              <base-button type="secondary" block @click="onClickConfirmCancel">キャンセル</base-button>
            </div>
            <div class="col-sm-2">
            </div>
            <div class="col-sm-5">
             <base-button type="primary" block @click="onClickConfirmOK">{{execBtnCaption}}</base-button>
            </div>
          </div>
        </modal> 

</template>

<style scoped>
  /** このVueだけのスタイル */
   .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .plan-list {
    width: 100%;
    height: 100%;
  }

  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>
<style >
  /** 全体に影響するスタイル */
</style>
<script>
import { useToast } from "vue-toastification";
import SortUtil from '@/appUtils/SortUtil';
import appLog from "@/appUtils/AppLog";
import Modal from "@/components/Modal";
import CO2SettingModel from "@/appModel/CO2Setting/CO2SettingModel";
import ValidateUtil from "@/appUtils/ValidateUtil";
import ValidSession from "../common/ValidSession.js";
import TableToolMenu from "../components/Menu/TableToolMenu";
import rockModel from "@/appModel/Rock/RockModel";
import MonthTemperatureModel from "@/appModel/Temperature/MonthTemperatureModel";
import DeliveryLimitModel from "@/appModel/Temperature/DeliveryLimitModel";
import DateUtil from '@/appUtils/DateUtil';
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import clone from 'clone';
// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"

export default {
  components: {
    TableToolMenu,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      isAddNew: false,
      co2Setting: null,
      formData: {
        speedThreshold: null,
        suddenStartValue: null,
        suddenStopValue: null,
        ecoDriveParam: null,
        AClassCount: 1,
        BClassCount: 2,
        CClassCount: 3,
        AClassFuelParam: 1.2,
        BClassFuelParam: 1.0,
        CClassFuelParam: 0.8,
        fuelMileage1: 0,
        fuelMileage2: 0,
        fuelMileage3: 0,
        fuelMileage4: 0,
        fuelMileage5: 0,
        fuelMileage1Memo: null,
        fuelMileage2Memo: null,
        fuelMileage3Memo: null,
        fuelMileage4Memo: null,
        fuelMileage5Memo: null,
        ArankFuelMemo: null,
        BrankFuelMemo: null,
        CrankFuelMemo: null,
        CO2SettingModel: "0",
        repeatedHitsFlg: false, // 連打防止フラグ 
      },
      modals: {
        editRockDlg: false,
        confirmDlg: false,
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      confirmTitle: '',
      confirmMessage: '', 
      execBtnCaption: '', 
      confiremMode: 0, // 0：燃料初期化、1：CO2設定、2：岩種
      title: "岩種マスタ",
      addTitle: "岩種の追加",
      editTitle: "岩種の編集",
      deleteTitle: "岩種の削除",
      copyTitle: "岩種のコピー",
      rockList: [],
      rowId: null,
      rockTypeFormData: {
        rockTypeName: null,
        loadCapacity: 0,
        beforeRockTypeName: null,
        repeatedHitsFlg: false, // 連打防止フラグ 
      },
      rockTypeId: null,
      CopyFlag: false,      
      isAdd: true,
      isEdit: true,
      isCopy: true,
      userAuthority: 1,
      confirmOK: '',
      actionMode: 0, // 0: 追加, 1: 削除, 2: 編集, 3: コピー
      editMode: '',
      editButtonKind: '',
      rockTypeNameLenght: 20,
      selectedData: null,
      selectedDatas: null,
      selectedDataCnt: 0,
      isCopyCommnet: false,
      repeatedHitsFlg: false, // 連打防止フラグ
      rules: {
        speedThreshold: [ // 速度超過判定閾値
          { required: false, validator: this.checkSpeedThreshold, trigger: "blur" },
        ],
        suddenStartValue: [ // 急発進判定
          { required: false, validator: this.checkSuddenStartValue, trigger: "blur" },
        ],
        suddenStopValue: [ // 急停止判定
          { required: false, validator: this.checkSuddenStopValue, trigger: "blur" },
        ],
        ecoDriveParam: [ // エコ運転判定
          { required: false, validator: this.checkEcoDriveParam, trigger: "blur" },
        ],
        AClassCount: [ // A判定 判定回数
          { required: false, validator: this.checkAClassCount, trigger: "blur" },
        ],
        BClassCount: [ // B判定 判定回数
          { required: false, validator: this.checkBClassCount, trigger: "blur" },
        ],
        CClassCount: [ // C判定 判定回数
          { required: false, validator: this.checkCClassCount, trigger: "blur" },
        ],
        AClassFuelParam: [ // A判定 燃費係数
          { required: false, validator: this.checkAClassFuelParam, trigger: "blur" },
        ],
        BClassFuelParam: [ // B判定 燃費係数
          { required: false, validator: this.checkBClassFuelParam, trigger: "blur" },
        ],
        CClassFuelParam: [ // C判定 燃費係数
          { required: false, validator: this.checkCClassFuelParam, trigger: "blur" },
        ],
        fuelMileage1: [ // 燃費1
          { required: false, validator: this.checkFuelMileage1, trigger: "blur" },
        ],
        fuelMileage2: [ // 燃費2
          { required: false, validator: this.checkFuelMileage2, trigger: "blur" },
        ],
        fuelMileage3: [ // 燃費3
          { required: false, validator: this.checkFuelMileage3, trigger: "blur" },
        ],
        fuelMileage4: [ // 燃費4
          { required: false, validator: this.checkFuelMileage4, trigger: "blur" },
        ],
        fuelMileage5: [ // 燃費5
          { required: false, validator: this.checkFuelMileage5, trigger: "blur" },
        ],
      },
      rockrules: {
        rockTypeName: [ //岩種名
          {required: false, validator: this.checkRockName, trigger: "blur" },
        ],
        loadCapacity: [ //積載量
          {required: false, validator: this.checkLoadCapacity, trigger: "blur" },
        ],
      },
      isOrderMove: false,
      disableMoveDown: false,
      disableMoveUp: false,
    };
  },
  computed: {
    vuename() {
      return "System.vue"
    },
    ArankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.AClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },
    BrankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.BClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },
    CrankFuel() {
      let fuel = 0
      try {
        let ret = Number((Number(this.formData.fuelMileage5) * Number(this.formData.CClassFuelParam)).toFixed(2))
        if (!isNaN(ret)) {
          fuel = ret
        } 
      } catch (e) {
        // 例外はキャッチして無視する
        console.log(e)
      }
      return fuel
    },
    selectRow() {
      if (this.userAuthority == 0) {
        return false;
      }
      if (this.selectedDataCnt > 0) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * ページング用のデータを返す
     */
    pagedData() {
      return this.rockList.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound){
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.rockList.length;
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.getUseApp().then()
    let timelineData = {
      projectType: `0`,
      projectid: ``,
      projectname: ``,
      datestring: DateUtil.getDateString('YYYY/MM/DD')
    }
    this.$store.commit("setTimeline", timelineData);
  },
  async beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    
  },
  async mounted() {
    //インスタンスが DOM 要素にマウントされた後
    
    appLog.infoLog(
      `${this.vuename}`, `Start ${this.vuename}`)
    await this.init()
    await this.initRock()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.editDateDlg = false;
    this.modals.confirmDlg = false;
    
    appLog.infoLog("PlanList.vue", this.$store.state.user.userId, `End`)
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {

      let loader = this.showLoader();

      console.log("init")
      // タイトルをセット
      let navInfo = this.$store.state.navInfo
      navInfo.title = "システム設定"
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)

      try {
        // 設定情報取得し画面表示
        await this.getCO2SettingInfo();
        
      } catch (e) {
        this.errorLog(`システム設定画面起動処理で例外が発生しました。：${e.message}`)
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * CO2排出関係設定値取得
     */
    async getCO2SettingInfo(){

      // CO2排出関係設定値取得
      this.co2Setting = await CO2SettingModel.getCO2Setting();

      if (this.co2Setting){
        // 設定値が取得できた場合は画面に反映
        this.formData = this.co2Setting.co2setting;
      } else {
        // 設定値が取得出来なかった場合は新規レコード作成
        let regData = await CO2SettingModel.getNewData();
        await CO2SettingModel.addCO2Setting(regData);

        // 作成したレコード取得し、画面に反映
        this.co2Setting = await CO2SettingModel.getCO2Setting();
        this.formData = this.co2Setting.co2setting;
      }
    },
    /**
     * 初期値にリセットボタンクリック時の処理
     */
    async onClickDefault(){
      // 確認ダイアログ表示
      this.confirmTitle = '基本燃料初期化';
      this.confirmMessage = '基本燃料の燃費の値を初期化しますか？';
      this.execBtnCaption = '実行';
      this.confiremMode = 0;
      this.modals.confirmDlg = true;
    },
    /**
     * 保存ボタンクリック時の処理
     */
    async onClickSave(){

      await this.$refs.form.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          // 確認ダイアログ表示
          this.confirmTitle = 'CO2排出関係設定値';
          this.confirmMessage = 'CO2排出関係設定値を保存しますか？';
          this.execBtnCaption = '保存';
          this.confiremMode = 1;
          this.modals.confirmDlg = true;

        }.bind(this));
    },
    /**
     * 確認ダイアログ キャンセルボタンクリック時の処理
     */
    async onClickConfirmCancel(){
      // 保存確認ダイアログ非表示
      this.modals.confirmDlg = false;
    },
    /**
     * 確認ダイアログ OKボタンクリック時の処理
     */
    async onClickConfirmOK(){
      // 保存確認ダイアログ非表示
      this.modals.confirmDlg = false;
      
      // モードで処理分岐
      if (this.confiremMode == 0) {
        this.formData.fuelMileage1 = 4.58;
        this.formData.fuelMileage2 = 3.79;
        this.formData.fuelMileage3 = 3.38;
        this.formData.fuelMileage4 = 3.09;
        this.formData.fuelMileage5 = 2.89;
        
        await this.$refs.form.validate()
        
      } else if (this.confiremMode == 1){
        this.saveCO2Setting();
      } else if (this.confiremMode == 2) {
        this.registRockType();
      }
    },
    /**
     * 確認ダイアログ OKボタンクリック時の処理
     */
    async saveCO2Setting() {
      let loader = this.showLoader();
      try {
        // 論理チェック (判定数)
        if (this.formData.AClassCount >= this.formData.BClassCount) {
          this.showBottomToast(`A判定の判定回数は、B判定の判定回数より小さい値を指定して下さい。`, "warning");
          this.$refs.AClassCount.focus();
          return;
        } 
        // else if (this.formData.BClassCount >= this.formData.CClassCount){
        //   this.showBottomToast(`B判定の判定回数は、C判定の判定回数より小さい値を指定して下さい。`, "warning");
        //   return;
        // }
        
        // 論理チェック (燃費係数)
        if (this.formData.AClassFuelParam <= this.formData.BClassFuelParam){
          this.showBottomToast('A判定の燃費係数は、B判定の燃費係数より大きい値を指定してください', 'warning')
          this.$refs.AClassFuelParam.focus();
          return;
        } else if (this.formData.BClassFuelParam <= this.formData.CClassFuelParam){
          this.showBottomToast('B判定の燃費係数は、C判定の燃費係数より大きい値を指定してください', 'warning')
          this.$refs.BClassFuelParam.focus();
          return;
        }

        // 画面設定内容を登録用の変数に設定
        this.co2Setting.co2setting = this.formData;

        // システム設定更新処理
        await CO2SettingModel.updateCO2Setting(this.co2Setting);

        this.showBottomToast(`システム設定保存処理が完了しました。`, "info");

        // 再取得し画面に反映
        this.co2Setting = await CO2SettingModel.getCO2Setting();

      } catch (e) {
        this.showBottomToast(`システム設定保存処理で例外が発生しました。：${e.message}`, "error");
        this.errorLog(`システム設定保存処理で例外が発生しました。：${e.message}`)
      } finally {
        this.hideLoader(loader);
      }
    },
    /**
     * 初期化処理
     */
    async initRock(){
      let loader = this.showLoader();
      try {
        this.userAuthority = this.$store.state.user.userType
      
        //ユーザー権限設定（0:一般 1:管理者 2：スーパーユーザ―）
        if (this.userAuthority == 0){
          this.isAdd = false;
        }

        // 登録項目変数初期化
        this.initEditListItem();

        
        //岩種一覧取得
        await this.getRockList();
       
        // 積載量取得
        await this.getloadCapacity();
       
        //連打防止
        this.repeatedHitsFlg = false
      } catch (e) {
        appLog.errLog("System.vue", this.$store.state.user.userId, `${e.message}`)
      } finally {
        this.hideLoader(loader)
      }
    },

    /**
     * 項目初期化
     */
    // 登録項目変数初期化
    initEditListItem(){
      let item= {
        rowId: "",
        rockTypeName: "",
        loadCapacity: 0,
        beforeRockTypeName: "",
      }
    },


    /**
     * 岩種一覧取得
     */
    async getRockList(){
     
      // テーブル関連編集初期化
      this.selectedData = null;
      this.selectedDatas = null;
      this.selectedDataCnt = 0;
      
      //岩種IDから岩種情報取得
      let listdata = await rockModel.getRockList();

      // //無効フラグが無効(=削除されていない)レコードのみ取得。
      // this.rockList = listdata.filter((list) => list.invalid === false)
      //   .slice()
      //   .sort(function(a, b){
      //     return (a.rocktype.name < b.rocktype.name) ? 1: -1;
      //   })
    
      //ソートして一覧に表示
      // this.rockList = listdata.sort(function(a, b){
      //   return (a.rocktype.name < b.rocktype.name) ? 1: -1;
      // })

      this.rockList = listdata.sort(function(a, b) {
        if (a.rocktype.order < b.rocktype.order) return -1;
        if (a.rocktype.order > b.rocktype.order) return 1;
        return 0;
      }) 
      
    
    },
    /**
     * 岩種の追加（モーダル画面の表示）
     */
    async onAddRockName(){
      // モーダル画面のタイトルとボタンのキャプション設定
      this.confirmTitle = this.addTitle
      this.execBtnCaption = "登録";
    

      // 新規登録のため、IDはNULLを設定（登録モード）
      this.rowId = null;
      this.rockTypeFormData.rockTypeName = null;
      this.rockTypeFormData.loadCapacity = null;
      this.rockTypeFormData.beforeRockTypeName = null;
      this.CopyFlag = false

      //モーダル画面表示
      this.modals.editRockDlg = true;
    },

    /**
     * 岩種の編集（モーダル画面表示）
     */
    onEditRockName(row){

      // // 運行情報に紐づく予定数量を削除しようとしていないかチェック
      // let ret = await this.checkDriveInfo(row)
      // if (ret == true){
      //   this.showBottomToast(`既に運行情報が登録されているため、岩種は編集できません。`, 'warning')
      //   return 
      // }

      // モーダル画面のタイトルとボタンのキャプション設定
      this.confirmTitle = this.editTitle
      this.execBtnCaption = "更新"

      //選択行の岩種名、積載量をセット
      this.rowId = row.id
      this.rockTypeFormData.rockTypeName = row.rocktype.name;
      this.rockTypeFormData.loadCapacity = row.rocktype.loadCapacity;
      this.rockTypeFormData.beforeRockTypeName = row.rocktype.name;
      this.CopyFlag = false

      //モーダル画面表示
      this.modals.editRockDlg = true;
    },


    /**
     * 岩種追加、編集、（モーダル画面の実行ボタン押下時）
     */
    async onClickSubmit(){
      await this.$refs.form1.validate(
        async function(result) {

          // フォーム入力基本チェック
          if (!result) {
            this.showBottomToast("入力内容にエラーがあります。", "error");
            return;
          }

          //登録確認メッセージ
          let operation = null;
          let buttonLabel = "実行"
          if (this.rowId == null && this.CopyFlag == false) {
            operation = this.addTitle;
            this.actionMode = 0;
            buttonLabel ='追加';
          } else if (this.rowId != null){
            operation = this.editTitle;
            this.actionMode = 2;
            buttonLabel = '編集';
          } 

          this.modals.editRockDlg = true;

          //処理確認モーダル表示
          this.confirmTitle = operation;
          this.confirmMessage = `${operation}します。よろしいですか？`;
          this.confirmOK = buttonLabel;
          this.confiremMode = 2;
          this.modals.confirmDlg = true;
        }.bind(this))},

    /**
     * 岩石登録確認モーダル実行ボタンクリックイベント
     */
    async registRockType() {
      //岩石処理確認モーダル非表示
      this.modals.confirmDlg = false;
      
      //削除モードの場合は削除処理を呼び出す
      if (this.actionMode == 1){
        this.deleteRock();
        return;
      }

      //登録確認メッセージ
      let operation = null
      if (this.rowId == null && this.CopyFlag == false)
        operation = this.addTitle
      else if (this.rowId != null)
        operation = this.editTitle
      // else
      //   operation = this.copyTitle
      
      try {
        
        //岩種名がすでに登録されている場合は登録不可能
        if (this.rockTypeFormData.beforeRockTypeName != this.rockTypeFormData.rockTypeName){
          let ret = await this.validationCheck(this.rockTypeFormData.rockTypeName)
          if (ret){
            this.showBottomToast(`既に登録されている岩種名です`, `warning`)
            return
          }
        }

        //新規登録時、コピー
        if (this.rowId == null)
        {
          //登録用レコード作成
          let regData = await rockModel.getNewData()
  
          //岩種設定
          regData.rocktype.name = this.rockTypeFormData.rockTypeName
          regData.rocktype.loadCapacity = this.rockTypeFormData.loadCapacity
          
          //DB更新
          await rockModel.addRock(regData);
       
          //編集時
        } else {
     
          // 更新対象の行情報をセット
          let editData = this.selectedData;

          editData.rocktype.name = this.rockTypeFormData.rockTypeName;
          editData.rocktype.loadCapacity = this.rockTypeFormData.loadCapacity;
          
          //DB更新
          await rockModel.updateRock(editData)
          
          
        } 

        //モーダル画面を閉じる
        this.modals.editRockDlg = false

        //一覧情報大取得
        this.getRockList();

        //完了メッセージ
        this.showBottomToast(`${operation}処理が完了しました`, 'info')
      
      } catch (e){
        //エラー外メッセージ表示
        this.showBottomToast(`${operation}処理に失敗しました`, 'error')
      }
    },

    /***
     * 岩種名、積載量重複チェック
     * @param name 岩種名
     * @return  true:重複チェックエラー、false：重複なし
     */
    async validationCheck(name){

      //戻り値初期化
      let retValue = true

      //岩種リストを取得
      let listRock = await rockModel.getRockList();

      //岩種名のデータ重複がないかチェック
      let retList = await listRock.filter((list) => list.rocktype.name === name);
      if (retList.length == 0){
        retValue = false
      }

      return retValue
    },
    async onClickRowMove(direction) {
      // 連続押下防止
      if (this.processing) return;

      this.processing = true;

      await this.updateOrder(direction)
      setTimeout(function() {
        this.processing = false;
      }.bind(this), 100)      
    },
    /**
     * 上へ、下への矢印ボタン押下時の処理
     */       
    async updateOrder(direction) {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      // 対象のテーブル
      let dataList = this.rockList;
      // 対象の行
      let currentRow =this.selectedData;

      // 現在の行インデックス
      const nowIndex = this.getRowIndex(currentRow.rocktype.id);
      
      // 移動後のインデックス
      let afterIndex = nowIndex + 1;
      if (direction == 'up') {
        afterIndex = nowIndex - 1;
      }

      // 連打された時の対応
      if (afterIndex < 0) afterIndex = 0
      if (afterIndex > this.rockList.length - 1) afterIndex = this.rockList.length - 1

      // リストの中から2つのインデックスに該当する行を抽出し、
      // インデックスを入れ替えた値でorderを更新
      // ※order順で並んでいる想定
      // ※先頭、末尾行への考慮はボタンのdisableで制御済みの想定
      dataList[nowIndex].rocktype.order = afterIndex;
      dataList[afterIndex].rocktype.order = nowIndex;

      // 明示的に並び替える
      dataList.sort(function(a, b) {
        if (a.rocktype.order < b.rocktype.order) return -1;
        if (a.rocktype.order > b.rocktype.order) return 1;
        return 0;
      })        

      // DBを更新
      // チェックボックスの再チェック処理がもっさりするのでDB更新は非同期で実行する
      this.saveRockType(nowIndex, afterIndex).then(() => {
      })
        
      // 上へ下へボタン活性、非活性にする
      this.controlMoveButton(afterIndex);

      // チェックが外れてしまうので、移動後のデータを再チェック
      this.$nextTick(function() {
        this.$refs.RockTable.toggleRowSelection(currentRow, true);
      }.bind(this))
      
    },
    /**
     * 車両情報を保存する（変更行のソート番号更新）
     */
    async saveRockType(nowIndex, afterIndex) {
      try {
        
        // レコード移動前のソート番号を更新
        let updateItem = clone(this.rockList[nowIndex])
        
        await rockModel.updateRock(updateItem)

        // レコード移動後のソート番号を更新
        updateItem = clone(this.rockList[afterIndex])

        await rockModel.updateRock(updateItem)

      } catch (e) {
        this.showBottomToast(`岩種一覧のソート順番保存処理に失敗しました。`, 'error')
        appLog.errLog("System.vue", this.$store.state.user.userId, `${e.message}`)
        throw e
      }
    },
    // /**
    //  * 削除ボタンクリックイベント
    //  */

    // async onDeleteRockName(){
    //   try {
       
    //     //確認メッセージ設定
    //     let msgStr = null
    //     msgStr = '選択した岩種を削除します。よろしいですか？'
        
    //     //処理確認モーダル表示
    //     let operation = this.deleteTitle;
    //     this.confirmTitle = operation;
    //     this.confirmMessage = msgStr;
    //     this.confiremMode = 2;
    //     this.execBtnCaption = '削除';
    //     this.actionMode = 1;
    //     this.modals.confirmDlg = true;

    //   } catch (e) {
    //     //エラーがいメッセージ表示
    //     this.showBottomToast(`岩種の削除処理に失敗しました。`, 'error')
    //   }
    // },
    
    // /**
    //  * 削除処理
    //  */
    // async deleteRock(){

    //   try {
    //     for (let i=0; i<this.selectedDatas.length; i++){
          
    //       // //削除処理
    //       // await rockModel.deleteRock(this.selectedDatas[i]);

    //       // 削除対象の行情報をセット
    //       let editData = this.selectedDatas[i];

    //       editData.invalid = true;

    //       //DB更新
    //       await rockModel.updateRock(editData)



    //     }
    //     //一覧情報再取得
    //     this.getRockList();
    //     //完了メッセージ
    //     this.showBottomToast(`岩種の削除処理が完了しました`, 'info')
    //   } catch (e) {
    //     this.showBottomToast(`岩種の削除処理に失敗しました。`, 'error')
    //   } 
    // },



    /**
     * テーブルのチェックボックス列選択時（全て）
     */
    handleSelectAllRow(selection) {
      
      //権限が閲覧ユーザーの場合はチェックオンさせない
      if (this.userAuthority == 0){
        this.$refs.RockTable.clearSelection()
        this.selectedData = null
        return
      }

      //選択行数をセット
      this.selectedDataCnt = selection.length

      //選択行数が1件の場合は、編集ボタン表示
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = false; this.isDelete = false;
        this.isOrderMove = true;
      } else {
        this.isEdit = false;
        this.isOrderMove = false;
      }
      this.selectedDatas = selection
    },
    
    /**
     * テーブルの行選択時の処理
     */
    handleSelectRow(selection, row){
      
      //権限が閲覧ユーザーの場合はチェックオンさせない
      if (this.userAuthority == 0){
        this.$refs.RockTable.clearSelection()
        this.selectedData = null
        return
      }

      //選択行数をセット
      this.selectedDataCnt = selection.length
      console.log(`this.selectedDataCnt ${this.selectedDataCnt}`)
      //選択行数が1件の場合は、編集ボタン表示
      if (this.selectedDataCnt == 1){
        this.isEdit = true; this.isCopy = false; this.isDelete = false;
        this.isOrderMove = true;
      } 
      //チェックON,OFF

      // 今回（削除が無いので）選択した行以外は選択を外し、常に単一選択とする
      for (let i = 0; i < selection.length; i++) {
        if (selection[i] != row) {
          this.$refs.RockTable.toggleRowSelection(selection[i], false)
        }
      }
      if (this.selectedData == row){
        //同一行の場合チェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      //1件のときは選択行にセット（チェックOFFした結果別の行の一件がカレント行になるように
      if (selection.length == 1){
        this.selectedData = selection[0]
      }
      
      //選択行保持
      this.selectedDatas = selection
      if (this.selectedData) {
        console.log(`選択あり${this.selectedData.rocktype.id}`)
        this.infoLog(`handleSelectRow`, `選択あり${this.selectedData.rocktype.id}`)
        this.controlMoveButton(this.getRowIndex(this.selectedData.rocktype.id))
      } else {
        console.log(`選択なし`)
        this.infoLog(`handleSelectRow`, `選択無し`)
        this.controlMoveButton(this.rockList.length);
      }
    },
    /**
     * テーブル行から指定したidのインデックスを取得します。
     * @params {string} id ID
     * @returns {number} インデックス
     */
    getRowIndex(id) {
      // 対象のテーブル
      let dataList = this.rockList;

      let currentIndex = dataList.findIndex(item => {
        return item.rocktype.id == id
      })
    
      return currentIndex;
    },
    /**
     * 上へ下へボタンの活性、非活性の設定
     */ 
    controlMoveButton(index)
    {
      console.log(`index:${index}`)
      if (index == 0)
      {
        this.disableMoveDown = false;
        this.disableMoveUp = true;
      } else if (index == this.rockList.length - 1)
      {
        this.disableMoveDown = true;
        this.disableMoveUp = false;
      } else 
      {
        this.disableMoveDown = false;
        this.disableMoveUp = false;
      }
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },
    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      if (!fieldName) {
        return;
      }
      
      // 日本語ソート
      let isKana = false;
      if (fieldName == "rocktype.name") {
        isKana = true;
      }
      // ソート実行
      this.rockList = SortUtil.sort(this.rockList, fieldName, sortingType, isKana);
    },        

    clickCancel() {
      this.$router.push({
        path: "/Projects",
        name: "Projects",        
      });  
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },

    /**
     * フォームのバリデーション(速度超過判定閾値)
     */
    checkSpeedThreshold(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(急発進判定)
     */
    checkSuddenStartValue(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(急停止判定)
     */
    checkSuddenStopValue(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },    
    /**
     * フォームのバリデーション(エコ運転判定)
     */
    checkEcoDriveParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(A判定 判定回数)
     */
    checkAClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(B判定 判定回数)
     */
    checkBClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(C判定 判定回数)
     */
    checkCClassCount(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(A判定 燃費係数)
     */
    checkAClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(B判定 燃費係数)
     */
    checkBClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(C判定 燃費係数)
     */
    checkCClassFuelParam(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費1)
     */
    checkFuelMileage1(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費2)
     */
    checkFuelMileage2(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費3)
     */
    checkFuelMileage3(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費4)
     */
    checkFuelMileage4(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(燃費5)
     */
    checkFuelMileage5(rule, value, callback) {
      if (
        ValidateUtil.checkCO2Setting(value, callback, true, true)
      ) {
        callback();
      }
    },
    /**
     * フォームのバリデーション(岩種名)
     */
    checkRockName(rule, value, callback){
      if (
        ValidateUtil.checkBase(value, callback, this.rockTypeNameLenght, true, false, false, true)
      ){
        callback();
      }
    },
    /**
     * フォームのバリデーション(積載量)
     */
    checkLoadCapacity(rule, value, callback){
      if (
        ValidateUtil.checkBase(value, callback, 0, true, false, true, false)
      ){
        callback();
      }
    },
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },          
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */    
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },

};
</script>
