<template>
  <div class="app-subheader d-flex" v-if="isSubheader">
    <div class="d-flex align-items-center my-0" v-if="isTimeline">
      <div class="ml-2 mt-0">
        <el-tooltip
          class="item"
          effect="dark"
          content="運行情報の表示・非表示"
          placement="bottom"
        >
          <el-button
            size="small"
            class="mr-3"
            style="border:0;"
            circle
            @click.prevent="onCloseClick(isTimelineShow)"
          >
            <i
              class="fas fa-lg"
              :class="[
                isTimelineShow
                  ? 'fa-angle-double-left'
                  : 'fa-angle-double-right',
              ]"
              style="background-color:white;border:0;"
            ></i>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="d-flex flex-fill justify-content-center align-items-center mt-0"
      v-if="isTimeline"
    >
      <div
        v-for="summary in roundSummarys"
        :key="fixSummaryLabel(summary.label)"
        class="d-flex bg-white rounded-pill mt-1 mb-1 mr-1"
        v-show="summary.isShow"
      >
        <img
          :src="summary.img"
          class="avatar-sm small mr-0"
          v-show="summary.isShow"
          style="width:30px; height:30px;"
        />
        <span
          class="mr-2 align-self-center summary-label"
          v-show="summary.isShow"
        >
          {{ fixSummaryLabel(summary.label) }}:{{ summary.count }}台
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <!-- 往路凡例 -->
      <div class="d-flex flex-column justify-content-center mr-2">
        <div class="d-flex justify-content-center">
          <div class="font-sm">往路</div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="rounded-pill ouro-bar"></div>
        </div>
      </div>
      <!-- 復路凡例 -->
      <div class="d-flex flex-column justify-content-center mr-2">
        <div class="d-flex justify-content-center">
          <div class="font-sm">復路</div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="rounded-pill fukuro-bar"></div>
        </div>
      </div>

    </div>
    <div class="d-flex align-items-center">
      <div class="my-box pr-1">
        <!-- <el-checkbox v-model="chkSpot" label="運行スポット" @change="changeChkSpot" /> -->
        <el-button type="primary" icon="" size="small" @click="showOption"
          >表示オプション</el-button
        >
      </div>
    </div>
    <el-dialog
      v-model="dialogTableVisible"
      :modal="true"
      width="500px"
      title="表示オプション"
    >
      <div class="d-flex border-bottom mb-2 pl-2">
        <div class="my-box" style="width:150px;">
          <el-checkbox
            v-model="checkStatus.chkSpot"
            label="運行スポット"
            @change="changeChkSpot"
          />
        </div>
        <div class="my-box flex-fill">
          地図上に運行スポットを表示させます。<br />初期値はオフです。
        </div>
      </div>
      <div class="d-flex border-bottom mb-2 pl-2">
        <div class="my-box" style="width:150px;">
          <el-checkbox
            v-model="checkStatus.chkOuro"
            label="往路車両"
            @change="changeChkOuro"
          />
        </div>
        <div class="my-box flex-fill">
          地図上に往路車両を表示させます。<br />初期値はオンです。
        </div>
      </div>
      <div class="d-flex border-bottom mb-2 pl-2">
        <div class="my-box" style="width:150px;">
          <el-checkbox
            v-model="checkStatus.chkFukuro"
            label="復路車両"
            @change="changeChkFukuro"
          />
        </div>
        <div class="my-box flex-fill">
          地図上に復路車両を表示させます。<br />初期値はオンです。
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" src="./css/ApplicationSubHeader-scoped.scss" scoped />
<style v-if="isZando" lang="scss" src="./css/ApplicationSubHeaderZando-scoped.scss" scoped />

<style scoped>
.ouro-bar {
  width:50px;
  height:5px;
  background-color: #FF6F00;
}
.fukuro-bar {
  width:50px;
  height:5px;
  background-color: #1565C0;
}


/** このVueだけのスタイル */
/* .app-subheader {
  height: auto;
  width: 100%;
  background-color: #fff;
}
.el-button--primary {
  color: #fff;
  background-color: #003c9c;
  border-color: #154ca3;
  padding-left: 4px;
  padding-right: 4px;
} */
/* .el-button--primary {
    color: #003C9C;
    background-color: #FFF;
    border-color: #FFF;
  } */
</style>

<style>
/** 全体に影響するスタイル */
</style>

<script>
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {},
  mixins: [UseApps, Logger],
  props: {
    /**
     * サブヘッダー表示フラグ
     */
    isSubheader: {
      type: Boolean,
      default: false,
    },

    /**
     * 運行表示フラグ
     */
    isTimeline: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    optionSettingKey: {
      get() {
        return "optionSetting";
      },
      vuename() {
        return "ApplicationSubHeader.vue"
      }
    },
  },

  data() {
    return {
      /**
       * 運行表示フラグ
       */
      isTimelineShow: true,
      /**
       * 往復サマリ
       */
      roundSummarys: [
        {
          label: `往路`,
          img: "./img/concrete/ic_circle_yellow.png",
          count: 0,
          isShow: true,
        },
        {
          label: `現場`,
          img: "./img/concrete/ic_circle_red.png",
          count: 0,
          isShow: true,
        },
        {
          label: `復路`,
          img: "./img/concrete/ic_circle_blue.png",
          count: 0,
          isShow: true,
        },
      ],
      
      dialogTableVisible: false,
      checkStatus: {
        /** 運行スポット表示フラグ (初期値：false) */
        chkSpot: false,
        /** 往路表示フラグ (初期値：false) */
        chkOuro: true,
        /** 復路表示フラグ (初期値：false) */
        chkFukuro: true,
      }
    };
  },

  /** ライフサクルメソッド */
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
    
  },

  created() {
    //インスタンスが生成され､且つデータが初期化された後
    
  },

  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },

  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },

  updated() {
    //データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },

  unmounted() {
    //Vue インスタンスが破壊された後
  },

  /**
   * watchはvueインスタンスのプロパティ(dataで定義している値など)の変更を監視する
   */
  watch: {},

  methods: {
    init() {
      this.loadOption();

      this.changeChkSpot();
      this.changeChkOuro();
      this.changeChkFukuro();
    },

    /**
     * 開閉ボタンクリック時の処理です。
     */
    onCloseClick(value) {
      //console.log(value);
      this.isTimelineShow = !value;

      this.$emit("closeTimeline", value);
    },

    updateSummarys(summarys) {
      // 台数のみ反映
      if (!summarys) {
        return;
      }

      // roundSummarys内のラベル名とsummarysのラベル名の一致データのカウントをアップさせる。
      // 残土でも生コンでも到着地点のラベル名は「現場」のままとする。
      // 画面表示時のラベル名のみ読み替えて表示させる。
      summarys.forEach(
        function(item) {
          for (let i = 0; i < this.roundSummarys.length; i++) {
            if (this.roundSummarys[i].label == item.label) {
              this.roundSummarys[i].count = item.count;
              break;
            }
          }
        }.bind(this)
      );

      // this.roundSummarys = summarys;
    },

    /**
     * 表示オプションを表示します
     */
    showOption() {
      this.dialogTableVisible = true;
    },

    /**
     * 運行スポットチェックボックス変更時の処理です。
     */
    changeChkSpot() {
      this.$emit("changeChkSpot", this.checkStatus.chkSpot);
      this.setOption();
    },

    /**
     * 復路チェックボックス変更時の処理です。
     */
    changeChkOuro() {
      this.$emit("changeChkOuro", this.checkStatus.chkOuro);
      this.setOption();
    },

    /**
     * 復路チェックボックス変更時の処理です。
     */
    changeChkFukuro() {
      this.$emit("changeChkFukuro", this.checkStatus.chkFukuro);
      this.setOption();
    },

    /**
     * アイコン凡例表示切り替え
     */
    setSummaryVisible(label, isShow) {
      console.log(`setSummaryVisible ${label}, ${isShow}`);
      for (let i = 0; i < this.roundSummarys.length; i++) {
        if (this.fixSummaryLabel(this.roundSummarys[i].label) == label) {
          console.log(JSON.stringify(this.roundSummarys[i]));
          this.roundSummarys[i].isShow = isShow;
          break;
        }
      }

      switch (label) {
        case "往路":
          this.checkStatus.chkOuro = isShow;
          break;
        case "復路":
          this.checkStatus.chkFukuro = isShow;
          break;
        default:
          break;
      }

      this.setOption();
    },

    /**
     * ローカルストレージからオプション読み込み
     */
    loadOption() {
      try {
        // keyは固定値
        const jsonString = localStorage.getItem(this.optionSettingKey);
        console.log(`loadOption ${jsonString}`);

        if (jsonString) {
          let data = JSON.parse(jsonString);
          this.checkStatus.chkSpot = data.chkSpot;
          this.checkStatus.chkOuro = data.chkOuro;
          this.checkStatus.chkFukuro = data.chkFukuro;
        } else {
          // 設定がない場合は初期表示設定
          this.checkStatus.chkSpot = false;
          this.checkStatus.chkOuro = true;
          this.checkStatus.chkFukuro = true;
        }
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * ローカルストレージにオプション書き込み
     */
    setOption() {
      console.log(`setOption ${JSON.stringify(this.checkStatus)}`);
      localStorage.setItem(
        this.optionSettingKey,
        JSON.stringify(this.checkStatus)
      );
    },
    /**
     * ラベルをプロジェクトタイプに応じて変更
     * @param {*} label 
     */
    fixSummaryLabel(label) {
      console.log(`fixSummaryLabel: ${label}`)
      if (label == "現場") {
        if (this.isZando) {
          return "土捨場"
        } 
      }
      return label
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
  
};
</script>
