<template>
  <div class="preoject-wrapper">
    <transition name="left">
      <div v-if="drawer.tree" class="drawer-tree-wrapper p-2">
        <div class="drawer-pulltab">
          <el-tooltip class="item" effect="dark" content="ツリーを隠す" placement="right">
            <el-button size="medium" type="text" @click="toggleTree" class="w-100">
              <i class="fas pt-3" :class="[drawer.tree ? 'fa-caret-left' : 'fa-caret-right']"></i><br />
              <div class="fas fa-folder-open w-100 text-center pt-3"></div>
            </el-button>
          </el-tooltip>
        </div>
        <tree-list :defaultProps="defaultProps" @clicked-node="onNodeSelect" @transfer="transferProc" :project="project" :projectId="projectId"></tree-list>
      </div>
    </transition>
    <div v-if="!drawer.tree" class="drawer-tree-wrapper-hide">
      <div class="drawer-pulltab">
        <el-tooltip class="item" effect="dark" content="ツリーを表示する" placement="right">
          <el-button size="medium" type="text" @click="toggleTree" class="w-100">
            <i class="fas pt-3" :class="[drawer.tree ? 'fa-caret-left' : 'fa-caret-right']"></i><br />
            <div class="fas fa-folder w-100 text-center pt-3"></div>
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <!-- 検査箇所表示(ドロワースタイルで表示させる) -->
    <transition name="right">
      <div v-if="drawer.place" style="overflow-y:scroll;overflow-x:hidden;" class="drawer-place-wrapper px-2 pb-2">
      </div>
    </transition>
    <div class="main-view" v-if="true" style="overflow:hidden;position:relative;">
      
      <!-- 図面表示 -->
      <Map v-if="activeView.map" :projectId="projectId" ref="map" :nodeId="nodeId" :fileType="fileType" @transfer="transferProc" :transferArgs="mapArgs"></Map>    
    </div>
  </div>
</template>

<style scoped>
  /** このVueだけのスタイル */
  .preoject-wrapper {
    border: 0px solid red;
    width:100%;
    height:100%;
    position: relative;
  }

  .main-view {
    border:0x solid gray;
    width:100%;
    height:100%;
    /* position: relative; */
  }
  .drawer-pulltab{
    position:absolute;
    border: 0px solid green;
    z-index: 1041;
    right:-30px;
    width:30px;
    top:400px;
    height:100px;
    border-radius: 0px 5px 5px 0px;
    background-color: white;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .25);
  }

</style>
<style>
  /** 全体に影響するスタイル */
.right-enter-active,
.right-leave-active {
  transform: translate(0px, 0px);
  transition: transform 200ms cubic-bezier(.29,.7,.63,1.02) 0ms;

}
.right-enter,
.right-leave-to {
  transform: translateX(100%) translateX(0px);
  transition: transform 400ms cubic-bezier(.29,.7,.63,1.02) 0ms;
}
.left-enter-active, .left-leave-active {
  transform: translate(0, 0);
  transition: transform 250ms ;/*liner cubic-bezier(0, 0, 0.2, 1) 0ms;*/
}
.left-enter, .left-leave-to {
  transform: translateX(-100%) translateX(0);
  /* transform: translate(calc(-100% + 30px), 0); */
}
.left-enter-to, .left-leave {
  transform: translateX(0) translateX(0);
  /* transform: translate(calc(-100% + 30px), 0); */
}
.drawer-tree-wrapper {
  position: absolute;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  background-color: rgba(250,250,250,1);
  border-left:0px solid rgba(150,150,150,0.9);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .25);

}
.drawer-tree-wrapper-hide{
  position: absolute;
  z-index: 1039;
  top: 0;
  left: 0;
  width: 0px;
  height: 100%;
  background-color: rgba(250,250,250,1);
  border-left:0px solid rgba(150,150,150,0.9);
  /* box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .25); */
}
.drawer-place-wrapper{
  position: absolute;
  z-index: 1040;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: rgba(250,250,250,1);
  border-left:1px solid rgba(150,150,150,0.9);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .25);
}
.drawer-menu {
  padding: 24px;
}

</style>
<script>
import appLog from "@/appUtils/AppLog"
import ProjectModel from "@/appModel/project/ProjectModel"
import Map from "@/appViews/Map/Map.vue";

// import DateUtil from "@/appUtils/DateUtil"
// import UserInfo from "@/appUtils/UserInfo"

export default {
  components: {
    Map,
  },
  data() {
    return {
      drawer: {
        tree: false,
        place: false,
      },
      activeView: {
        map: false,
        masterList: true,
        masterEdit: false,
        file: false
      },
      stab: {
        // 仮データ
        projectId: "3fw34B7dLk5idTmdtoYxyj",
        project: {},
        nodeId: "8M4VLRJ5qHnK4CmpP4ksE5",
        fileType: 1
      },
      // カレントディレクトリのパンくず表示用不要か？
      dirList: [],
      fileInfo: null,
      fileAttributes: [],
      fileType: 3,
      mapTitle: "",
      nodeData: {},
      // projectId: "",
      nodeId: "",
      nodeType: "",
      fileListArgs: null,
      mapArgs: null,
      mapTileArgs: null,
      inspectMasterEditArgs: null,
      inspectMasterListArgs: {},
      inspectPlaceArgs: null,
      placeId: null,
      addPlaceData: null,
      editPlaceId: null
    };
  },
  props: {
    /**
     * TODO:不要なら消す
     */
    defaultProps: Object,
    /**
     * プロジェクトID
     */
    projectId: String,
    /**
     * プロジェクト
     */
    project: Object
  },
  computed: {
    vuename() {
      return this.$route.name
    }
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    // appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`)
    this.init().then(() =>{

    })
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    async init() {
      
      this.infoLog(`init`, `${JSON.stringify(this.project)}`)
      
    },
    toggleTree() {
      this.drawer.tree = !this.drawer.tree
    },
    transferProc(params) {
      if (params.sendTo) {
        // ルーティング先が指定されている場合
        this.clearSelectInfo()
        this.$router.push(params.sendTo)
        return
      } else {
        //this.clearSelectInfo()
        if (params) {

          switch (params.key) {
          case "inspectPlaceArgs":
            this.editPlaceId = null
            this.addPlaceData = null
            if (params.newPlaceData){
              this.mapArgs = {}
              //this.placeId = params.newPlaceData.id
              this.addPlaceData = params.newPlaceData.dbData
              this.drawer.place = !this.drawer.place;
              // this.debugLog(`inspectPlaceArgs open ${JSON.stringify(this.addPlaceData)}`)
            } else if (params.selectPlaceData) {
              this.mapArgs = {}
              this.editPlaceId = params.selectPlaceData.id
              this.drawer.place = !this.drawer.place;
              // this.debugLog(`inspectPlaceArgs open ${params.selectPlaceData.id}`)
            } else if (params.values) {
              this.mapArgs = {}
              this.editPlaceId = params.values.id
              this.drawer.place = !this.drawer.place;
              // this.debugLog(`inspectPlaceArgs open ${params.values.id}`)
            }

            break
          case "inspectPlaceAddComplete":
            this.mapArgs = {}
            this.drawer.place = false
            this.addPlaceData = null

            this.$refs.map.addPoi(params.values)
            break
          case "inspectPlaceEditComplete":
            this.mapArgs = {}
            this.drawer.place = false
            this.addPlaceData = null

            this.$refs.map.addPoi(params.values)
            break
          case "inspectPlaceCancel":
            this.mapArgs = {}
            this.drawer.place = false
            this.addPlaceData = null
            break
          case "inspectPlaceListArgs":
            // ノードID
            this.clearSelectInfo()
            // UI更新処理が走ってから検査箇所一覧を表示
            this.$nextTick(function() {
              this.nodeData = params.values
              // this.debugLog(JSON.stringify(this.nodeData, null, "\t"))
              this.nodeId = this.nodeData.id
              this.nodeType = this.nodeData.type
              this.mapArgs = {}
              this.drawer.place = false
              this.addPlaceData = null
              this.fileType = 4;
            }.bind(this))

            break
          case "mapArgs":
            // 検査箇所一覧⇒Map
            this.clearSelectInfo()
            // UI更新処理が走ってからMapを表示
            this.$nextTick(function() {
              this.dirList = null

              this.mapArgs = params.values;

              // ノードID
              this.nodeId = params.values.nodeId;
              const nodeType = params.values.nodeType;
              switch (nodeType) {
              case "backimage":
                this.inspectPlaceArgs = {}
                this.fileType=1
                break
              case "map":
                this.fileType=2
                break
              }
            }.bind(this))
            break
          default:
            this.clearSelectInfo()
            this.activeView = {
              map: false,
              masterList: params.key == "inspectMasterListArgs",
              masterEdit: params.key == "inspectMasterEditArgs",
              file: false
            }

            this[params.key] = params.values
            // this.debugLog(`${params.key}`)
            break

          }
        }
      }
    },
    clearSelectInfo() {
      this.fileType = -1
      this.fileListArgs = null
      this.mapArgs = null
      this.mapTileArgs = null
      this.inspectMasterEditArgs = null
      this.inspectMasterListArgs = null
      this.inspectPlaceArgs = null
      this.drawer.place = false
      this.placeId = null

      this.activeView = {
        map: false,
        masterList: false,
        masterEdit: false,
        file: false
      }
    },
    onNodeSelect(data) {
      // プロジェクトID

      // const currentProject = this.$store.state.project;
      // this.projectId = currentProject.project.id
      // ノードID
      this.nodeData = data
      // this.debugLog(JSON.stringify(this.nodeData, null, "\t"))
      this.nodeId = this.nodeData.id

      this.clearSelectInfo()

      // const _this = this
      // this.debugLog(data.type)
      this.$nextTick(function() {
        switch (data.type) {
        case "folder":
          this.drawer.tree = false
          this.fileType=0
          // this.fileList = data.fileList
          this.dirList = data.dirList
          this.activeView = {
            map: false,
            masterList: false,
            masterEdit: false,
            file: true
          }
          break
        case "backimage":
          this.drawer.tree = false
          this.fileType=1
          //this.mapTitle = data.label
          // this.fileList = null
          this.dirList = null

          this.inspectPlaceArgs = {}
          this.activeView = {
            map: true,
            masterList: false,
            masterEdit: false,
            file: false
          }
          // TODO: 動作確認用で固定値
          // this.placeId = "kruaXG7bbCGVWowrDviKWW"
          break
        case "map":
          this.drawer.tree = false
          // TODO:地図表示する
          this.activeView = {
            map: true,
            masterList: false,
            masterEdit: false,
            file: false
          }
          this.fileType=2
          this.dirList = null
          break
        case "nobackimage":
          this.nodeId = this.nodeData.id
          this.nodeType = this.nodeData.type
          this.mapArgs = {}
          this.drawer.place = false
          this.addPlaceData = null
          this.fileType = 4;
          break;
        case "project":
          // this.fileType = 3
          // this.dirList = null
          // this.inspectMasterListArgs = {}
          break
        case "inspectMaster":
          this.drawer.tree = false
          this.fileType = 3
          this.dirList = null
          this.inspectMasterListArgs = {}
          this.activeView = {
            map: false,
            masterList: true,
            masterEdit: false,
            file: false
          }
          break
        default:
          this.fileType=-1
          break
        }
      }.bind(this))
    },
    onFileSelect(data) {

      this.fileInfo = {
        title: data.title,
      }
      this.fileAttributes = [
        {name: "type", value: data.type},
        {name: "size", value: data.size},
        {name: "createDate", value: data.createDate}
      ]

    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
