<template>
  <div ref="mapContainer" class="map-container">
    <div id="map" class="map">
      <base-button
        class="btn-white btn-icon-only overmap-bottom-right leaflet-bar leaflet-touch"
        size="sm"
        @click="moveCurrentLocation"
      >
        <img src="img\concrete\gpsicon.png" style="width: 100%;" />
      </base-button>
    </div>
  </div>
</template>

<style scoped>
/** このVueだけのスタイル */
.map-container {
  width: 100%;
  height: 100%;
  /* border:1px solid green; */
}

.map {
  /* width: 500px;
    height: 500px; */

  width: 100%;
  height: 100%;
  /* border:1px solid blue; */
}

.leaflet-grab {
  cursor: crosshair;
}

.leaflet-dragging .leaflet-grab {
  cursor: grabbing;
}

.overmap-bottom-right {
  position: absolute;
  right: 32px;
  bottom: 24px;
  width: 32px;
  height: 32px;
  padding: 0px 0px 0px 0px;
  z-index: 1001;
}

/* :deep .leaflet-interactive {
  cursor: grab !important;
} */
</style>
<style>
/** 全体に影響するスタイル */
</style>
<script>
import appLog from "@/appUtils/AppLog";
import CoordinateUtil from "@/appUtils/CoordinateUtil";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import xss from 'xss';
// // data()内に保持するとLeafletの挙動がおかしくなります。
// let mlMap = null;

export default {
  components: {},
  data() {
    return {
      drivehistoryList: [],
      initdrivehistoryList: [],
      spotTypeList: [],
      spotTypeLayerName: [],
      operationMode: 0, // 操作モード - 0: 作成, 1: 編集, 2: 削除
      operationShape: 0, // 図形種別 -0: 円, 1:ポリゴン
      isSelected: false,
      latlngList: [],
      isMovingCurrentLocation: false,
    };
  },
  mixins: [ValidSession, UseApps, Logger],
  computed: {
    vuename() {
      return "DriveSpotMap.vue";
    },
  },

  beforeCreate() {
    // インスタンスは生成されたがデータが初期化される前
  },

  async created() {
    // インスタンスが生成され､且つデータが初期化された後

    // ※画面表示に関連しないメンバ変数を定義

    // MLMap
    // data()内に保持するとLeafletの挙動がおかしくなります。
    this.mlMap = null;

    // 地図コンテナリサイズ監視オブザーバー
    this.resizeObserver = null;

    // ジオフェンス描画スタイル
    this.geofenceStyle = {
      // color: "#B71C1C",
      color: "#E65100",
      weight: 2.5,
      fill: true,
      // fillColor: "#B71C1C",
      fillColor: "#E65100",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // 運行スポット描画スタイル
    this.spotStyle = {
      color: "#021aee",
      weight: 2.5,
      fill: true,
      fillColor: "#021aee",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

    // 運行間隔スポットポリゴンスタイル
    this.intervalSpotStyle = {
      color: "#008A00",
      weight: 2.5,
      fill: true,
      fillColor: "#008A00",
      fillOpacity: 0.35,
      opacity: 0.9,
      interactive: false,
    };

  },

  beforeMount() {
    // インスタンスが DOM 要素にマウントされる前
  },

  mounted() {
    // インスタンスが DOM 要素にマウントされた後
    appLog.infoLog(`${this.vuename}`, `Start ${this.vuename}`);

    // // 地図生成
    // this.init();

    // 地図コンテナのリサイズ監視開始
    this.addResizeObserver();
  },

  beforeUpdate() {
    // データは更新されたが DOM に適用される前
  },

  updated() {
    // データが更新され､且つ DOM に適用された後
  },

  beforeUnmount() {
    // Vue インスタンスが破壊される前

    // 地図コンテナのリサイズ監視終了
    this.removeResizeObserver();
  },

  unmounted() {
    // Vue インスタンスが破壊された後

    // マップリソース解放
    if (this.mlMap) {
      this.mlMap.off("click", this.onMapClick);
      this.mlMap.off(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);

      this.mlMap.remove();
    }
  },

  // ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 地図コンテナのリサイズ監視を開始します。
     */
    addResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナのリサイズ監視を終了します。
     */
    removeResizeObserver() {
      this.resizeObserver.unobserve(this.$refs.mapContainer);
    },

    /**
     * 地図コンテナリサイズイベント
     */
    onResize() {
      console.log(`onResize`);

      // Leafletの地図領域を更新
      if (this.mlMap) {
        this.mlMap.invalidateSize();
      }
    },

    init() {
      //console.log("init")
      // 地図生成
      // this.mapCreate();
    },

    /**
     * 地図の作成
     */
    mapCreate() {
      // MapletLeaflet関連出力ログレベル
      MLLog.level = MLLog.LOGLEVEL.INFO;

      // レイヤ追加
      this.addLayers();

      // コントロール
      this.mlMap.setLayerControlVisible(true);
      this.mlMap.setMapInfoControlVisible(true);

      // イベント
      this.initMapEvent();

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },

    /**
     * レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addLayers() {
      // 背景レイヤの追加
      this.addBaseLayers();

      // 拠点ジオフェンス範囲
      this.mlMap.addShapeLayer("拠点範囲");

      // アイコンレイヤ群追加
      this.addPoiLayers();
    },

    /**
     * 背景レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addBaseLayers() {
      // 国土地理院タイル地図レイヤを追加
      this.addGsiLayers();
    },

    /**
     * 国土地理院タイル地図レイヤを追加します。
     *
     * @returns 地図表示初期領域
     */
    addGsiLayers() {
      this.mlMap = null;
      this.mlMap = new MLMap("map", MLMap.VIEW_TYPE.MAP);

      this.mlMap.addGsiPaleLayer("地理院タイル 淡色地図", { opacity: 0.9 });
      this.mlMap.addGsiStdLayer("地理院タイル 標準地図", { opacity: 0.65 });
      this.mlMap.addGsiPhotoLayer("地理院タイル 写真", { opacity: 0.8 });

      this.mlMap.setView([35.68944, 139.69167], 12);

      const bounds = L.latLngBounds([25, 120], [45, 150]);
      this.mlMap.setMaxBounds(bounds.pad(0.25));
    },

    /**
     * アイコンレイヤ群を追加します。
     */
    addPoiLayers() {
      // ラベルスタイル
      const poiLabelStyle = {
        color: "#263238",
        weight: 2,
        fillColor: "#FFFFFF",
        fontSize: 24,
        textBaseline: "middle",
        fillOpacity: 0,
        opacity: 0,
      };

      let types = [
        {
          layerName: "出発地点",
          iconUrl: this.isZando ? "./img/concrete/icon_1r_64.png" : "./img/concrete/icon_2y_64.png",
          iconSize: [48, 48],
          iconOffset: [-24, -46],
          interactive: false,
        },
        {
          layerName: "到着地点",
          iconUrl: this.isZando ? "./img/concrete/soil_goal.png" : "./img/concrete/icon_1r_64.png",
          iconSize: [48, 48],
          iconOffset: [-24, -48],
          interactive: false,
        },
      ];

      this.spotTypeList.forEach((spotType) => {
        this.spotTypeLayerName.push(spotType.name);
        types.push({
          layerName: spotType.name,
          iconUrl: spotType.iconUrl,
          iconSize: [38, 38],
          iconOffset: [-19, -38],
          interactive: true,
        });
      });

      for (let i = 0; i < types.length; i++) {
        const type = types[i];

        // アイコン種別
        let poiStyle = {
          iconUrl: type.iconUrl,
          iconSize: type.iconSize,
          iconOffset: type.iconOffset,
          interactive: type.interactive,
        };

        let layerName = type.layerName;

        // 拠点アイコンレイヤ追加
        this.mlMap.addPoiLayer(xss(layerName), poiStyle, poiLabelStyle);
        // this.$nextTick(
        //   function() {
        //     // 拠点アイコンレイヤ追加
        //     this.mlMap.addPoiLayer(layerName, poiStyle, poiLabelStyle);
        //   }.bind(this)
        // );
      }
    },

    /**
     * 図形情報からBBX領域を取得します。
     *
     * @returns BBX領域
     */
    getPoiBounds() {
      // 基準領域
      const worldBounds = L.latLngBounds([25, 120], [45, 150]);

      if (this.latlngList.length > 0) {
        const polygon = L.polygon(this.latlngList);
        const bounds = polygon.getBounds();
        return bounds;
      } else {
        return worldBounds;
      }
    },

    /**
     * 地図イベントの初期化処理です。
     */
    initMapEvent() {
      // クリック
      this.mlMap.on("click", this.onMapClick);

      // 作図完了
      this.mlMap.on(MLMap.EVENT_SHAPE_DRAW_FINISH, this.onMapShapeDrawFinish);
    },

    /**
     * 地図クリックイベント
     * @param {Object} e イベントデータ
     */
    onMapClick(e) {
      if (this.isMovingCurrentLocation) {
        this.isMovingCurrentLocation = false;
        return;
      }
      
      if (this.operationMode == 0) {
        // 追加
        this.$emit("onAreaClickAdd", { x: e.latlng.lng, y: e.latlng.lat });
      } else if (this.operationMode == 1) {
        if (this.isSelected) {
          // 選択時: 移動処理
          this.$emit("onAreaClickMove", { x: e.latlng.lng, y: e.latlng.lat });
        } else {
          // 未選択時: 選択処理
          const items = this.getPoiItems(e);
          if (items.length < 1) return;
          this.$emit("onAreaClickSelect", items[0]);

          // スポット選択中の仮図形を作図する
          this.addTempPoi([items[0].y, items[0].x]);

          if (this.operationShape == 1){
            this.startDrawPolygonMode();
          }

        }
      } else {
        // 削除
        const items = this.getPoiItems(e);
        if (items.length < 1) return;
        this.$emit("onAreaClickRemove", items[0]);

        // ジオフェンス図形削除
        this.mlMap.removeShapeById(items[0].id);
        // アイコン削除
        this.mlMap.removePoiById(xss(items[0].layerName), items[0].id);
      }
    },

    /**
     * ジオフェンスを削除します
     */
    deleteShapeById(id) {
      // ジオフェンス図形削除
      this.mlMap.removeShapeById(id);
    },

    /**
     * アイコン図形を削除します
     */
    deletePoiById(id, layerName) {
      // アイコン削除
      this.mlMap.removePoiById(layerName, id);
    },

    /**
     * 一時POI図形を作図します。
     * ＊検査箇所の新規登録時にコールします。
     *
     * @param {latlng} latlng 座標YX
     */
    addTempPoi(latlng) {
      this.mlMap.clearTempPoi();

      this.mlMap.addTempPoi(latlng, {
        color: "#FF6D00",
        opacity: 0.8,
        weight: 4,
        fillColor: "#FF6D00",
        fillOpacity: 0.4,
        radius: 24,
      });
    },

    /**
     * 一時POI図形を削除します。
     */
    clearTempPoi() {
      this.mlMap.clearTempPoi();
    },

    /**
     * クリック時のイベント引数から運行スポットPOIを取得します。
     *
     * @param {eventArg} e Map上のクリックイベント引数
     * @returns 運行スポットPOI
     */
    getPoiItems(e) {
      let items = [];

      for (let i = 0; i < this.spotTypeLayerName.length; i++) {
        try {
          items = this.mlMap.getPoiByDomMouseEvent(
            this.spotTypeLayerName[i],
            e.originalEvent
          );
          
        } catch (e) {
          if (e.message != "Cannot read properties of null (reading 'mouseEventToLayerPoint')") {
            throw e;
          }
        }

        if (items.length > 0) {
          return items;
        }
      }

      return items;
    },

    /**
     * 拠点情報描画
     */
    drawBaseArea(parameters) {
      try {
        for (let baseArea of parameters) {
          // -------------------------
          // ジオフェンスの作図
          // -------------------------

          // ID
          let shapeItem = { id: baseArea.id };

          // addShape
          this.mlMap.addShape(
            MLMap.SHAPE_TYPE.POLYGON,
            baseArea.geofence,
            shapeItem,
            this.geofenceStyle
          );

          // -------------------------
          // アイコンの作図
          // -------------------------
          let layerName;
          if (baseArea.areaType == 1) {
            layerName = "出発地点";
          } else {
            layerName = "到着地点";
          }

          // アイコン図形をプロットを追加
          this.mlMap.addPoi(layerName, {
            id: baseArea.id,
            x: baseArea.x,
            y: baseArea.y,
            layerName: layerName,
          });

          const latlng = [baseArea.y, baseArea.x];
          this.latlngList.push(latlng);
        }
      } catch (e) {
        this.errorLog("drawBaseArea", this.parseErrorObject(e))
        // appLog.errLog("DriveSpotMap.vue", ``, `${e.message}`);
      }
    },

    /**
     * 運行スポット描画
     */
    drawSpot(parameters) {
      try {
        for (let spot of parameters) {
          const x = spot.x;
          const y = spot.y;

          // -------------------------
          // ジオフェンスの作図
          // -------------------------
          let compressCoordsText;

          // 図形タイプがポリゴンの場合は、DB値をそのまま使用
          if (spot.shapeType == "1") {
            compressCoordsText = spot.geofence;
          } else {
            // 中心座標、半径から、ポリゴン座標を取得
            const latlngs = CoordinateUtil.getCirclePolygonPoints(
              new L.LatLng(y, x),
              spot.range
            );
            // ポリゴン座標をmlMap圧縮文字列にする
            compressCoordsText = MLCompressUtil.latLngArrayToBase64(
              latlngs
            );
          }

          // ID
          let shapeItem = { id: spot.id };

          // スポット種別取得
          const spotInfo = this.spotTypeList.find(
            (spotType) => spotType.value === spot.spottypeid
          );

          // console.log("▲1▲" + JSON.stringify(this.spotTypeList));
          // console.log("▲2▲" + JSON.stringify(spot));
          // console.log("▲3▲" + JSON.stringify(spotInfo));

          // addShape
          if (spotInfo.spotType == "1") {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.POLYGON,
              compressCoordsText,
              shapeItem,
              this.intervalSpotStyle
            );
          } else {
            this.mlMap.addShape(
              MLMap.SHAPE_TYPE.POLYGON,
              compressCoordsText,
              shapeItem,
              this.spotStyle
            );
          }

          // -------------------------
          // アイコンの作図
          // -------------------------
          let layerName = spot.spotTypeName;

          // アイコン図形をプロットを追加
          this.mlMap.addPoi(layerName, {
            id: spot.id,
            x: spot.x,
            y: spot.y,
            layerName: xss(layerName),
          });

          const latlng = [y, x];
          this.latlngList.push(latlng);
        }
      } catch (e) {
        this.errorLog("drawSpot", this.parseErrorObject(e))
        // appLog.errLog("DriveSpotMap.vue", ``, `${e.message}`);
      }

      // 図形の上下(Z-Order)を正しく描画
      this.mlMap.refreshZOrder();
    },

    /**
     * 表示位置・範囲設定
     */
    async setDisplayPosition() {
      const bounds = await this.getPoiBounds();
      this.mlMap.fitBounds(bounds);
    },

    // 画面の中心を現在位置に移動する
    moveCurrentLocation() {
      this.isMovingCurrentLocation = true;
      this.mlMap.map.locate({ setView: true });
    },

    /**
     * ジオフェンスポリゴン作図モードを開始します。
     */
    startDrawPolygonMode() {
      this.mlMap.startDirectDrawShapeMode(MLMap.SHAPE_TYPE.POLYGON);
    },

    /**
     * ジオフェンス作図モードを終了します。
     */
    stopDrawShapeMode() {
      this.mlMap.stopDirectDrawShapeMode();
    },

    /**
     * 作図完了イベント
     * @param {Object} e イベントデータ
     */
    onMapShapeDrawFinish(e) {
      console.log(`${e.type}`);

      // AWSに登録するジオフェンスポリゴンは時計回りをサポートしていないため、
      // 時計回りポリゴンの場合は座標配列要素を反転させ反時計回りポリゴンにする
      if (e.shapeType === MLMap.SHAPE_TYPE.POLYGON) {
        let isClockWise = CoordinateUtil.isClockWise(e.layer);
        console.log(`isClockWise: ${isClockWise}`);

        if (isClockWise) {
          // 座標配列要素を反転
          e.layer.getLatLngs()[0].reverse();

          let isClockWise = CoordinateUtil.isClockWise(e.layer);
          console.log(`Reverse isClockWise: ${isClockWise}`);
        }
      }

      // 親のBaseArea.vueの処理を呼ぶ
      this.$emit("onDrawPolygonFinish", {
        id: "",
        layer: e.layer,
      });
    },    
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  },
};
</script>
