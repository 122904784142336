const dayjs = require('dayjs')
require('dayjs/locale/ja')
// const AppLog = require('./AppLog')

class DateUtil {
  static stringToDate(string, format = null) {
    
    if (format) {
      return dayjs(string, format)  
    } else {
      return dayjs(string)
    }
    
  }

  static dateStringDate(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD")
  }
  static dateStringDateTime(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD HH:mm:ss")
  }
  static dateStringDateTimeMilli(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD HH:mm:ss:SSS")
  }
  static dateStringBase(date, format) {
    return dayjs(date).format(format)
  }
  static getUnixTimeStampOfSecond() {
    return dayjs().unix()
  }
  static getUnixTimeStampOfMilliSecond() {
    return dayjs().valueOf()
  }
  static dateStringDateTimeAtSecond0(date) {
    return this.dateStringBase(dayjs(date), "YYYY-MM-DD HH:mm:00")
  }
  
  static utcDateStringToJST(dateString, format = 'YYYY-MM-DD HH:mm:ss', locale = 'ja') {
    
    let date = dayjs.utc(dateString)
    dayjs.locale(locale)
    let jstStr = dayjs(date).format(format)
    // AppLog.debugLog("DateUtil", "", `utcDateStringToJST ${dateString} -> ${jstStr}`)
    return jstStr
  }
  /**
   * 日本時間の現在日時文字列を取得します。
   * @returns {String} 日本時間の現在日時文字列
   */
  static getDateStringDateTimeMilli(format = 'YYYY-MM-DD HH:mm:ss:SSS') {
    dayjs.locale('ja')
    return dayjs().format(format)
  }
  /**
   * 日本時間の現在日付文字列を取得します。
   * @returns {String} 日本時間の現在日付文字列
   */
  static getDateString(format = 'YYYY-MM-DD') {
    dayjs.locale('ja')
    return dayjs().format(format)
  }
  /**
   * 日本時間の現在日時文字列を取得します。
   * @returns {String} 日本時間の現在日付文字列
   */
  static getDateTimeString(format = 'YYYY-MM-DD HH:mm:ss') {
    dayjs.locale('ja')
    return dayjs().format(format)
  }
  /**
   * 日付の文字列を別のフォーマットに変換 
   * */
  static getFormatString(dateString, format) {
    try {
      let date = dayjs(dateString);
      return date.format(format);
    } catch (e) {
      console.log(`${JSON.stringify(e)}`);
      return '';
    }
  }


  static isAfter(baseDate, targetDate) {
    let a = dayjs(baseDate)
    let b = dayjs(targetDate)
    // console.log(`isAfter ${a.format('YYYY-MM-DD HH:mm:ss')} ${b.format('YYYY-MM-DD HH:mm:ss')}`)

    return a.isAfter(b)
  }

  static isBefore(baseDate, targetDate) {
    return dayjs(baseDate).isBefore(targetDate)
  }

  /**
   * date1がdate2からどれくらい経過したかを計算する
   * @param {*} date1String 
   * @param {*} date2String 
   * @param {*} unit 単位 https://day.js.org/docs/en/display/difference
   * @param {*} isFloat true;小数点あり false:整数値
   */
  static diff(date1String, date2String, unit = "m", isFloat = false){
    dayjs.locale('ja')
    const date1 = dayjs(date1String)
    const date2 = dayjs(date2String)
    return date1.diff(date2, unit, isFloat)
    
  }

  /**
   * 現在日時が指定した期間範囲内かどうかを判定します。
   * @param {String} from 期間開始文字列
   * @param {String} end 期間終了文字列
   * @returns {Boolean} 期間範囲内の場合true、さもなくばfalse
   */
  static isWithinPeriod(from, to) {
    let result = false;

    // 現在日時
    const nowDateTime = this.getDateTimeString();
    const now = new Date(nowDateTime);

    // 開始/終了
    const format = 'YYYY/MM/DD HH:mm:ss';
    const formatFrom = this.getFormatString(from, format);
    const formatTo = this.getFormatString(to, format);

    // AppLog.debugLog("DateUtil", "", now)
    // AppLog.debugLog("DateUtil", "", formatFrom)

    const start = new Date(formatFrom);
    const end = new Date(formatTo);

    if (now < start || now > end) {
      // 有効期限範囲外
      return result;
    }

    result = true;
    // AppLog.debugLog("DateUtil", "", '有効期限範囲内')
    return result;
  }

  /**
   * 指定日が指定した期間範囲内かどうかを判定します。
   * @param {String} from 期間開始文字列
   * @param {String} end 期間終了文字列
   * @param {String} target チェック対象日
   * @returns {Boolean} 期間範囲内の場合true、さもなくばfalse
   */
  static isWithinPeriodTarget(from, to, target) {

    let result = false;
    let _from = from.length == 10 ? from + " 00:00:00" : from
    let _to = to.length == 10 ? to + " 23:59:59" : to
    let _target = target.length == 10 ? target + " 00:00:00" : target

    // チェック日時
    const now = new Date(_target);

    // 開始/終了  
    const format = 'YYYY/MM/DD HH:mm:ss';
    const formatFrom = this.getFormatString(_from, format);
    const formatTo = this.getFormatString(_to, format);

    // AppLog.debugLog("DateUtil", "", now)
    // AppLog.debugLog("DateUtil", "", formatFrom)

    const start = new Date(formatFrom);
    const end = new Date(formatTo);

    if (now < start || now > end) {
      // 有効期限範囲外
      return result;
    }

    result = true;
    return result;
  }

  /**
   * 2つの時間の差分時間を取得します。
   * @param {Object} time1 日時1
   * @param {Object} time2 日時2 
   * @returns 2つの時間の差分時間（HH:mm表記）
   */
  static getDifferentialTime(time1, time2) {
    let date1 = new Date(String(time1).replace('Z', ''));
    let date2 = new Date(String(time2).replace('Z', ''));
    let diffSec = Math.floor(date2.getTime() - date1.getTime()) / 1000;
    let diffHour = Math.floor(diffSec / 3600);
    let diffMin = Math.floor((diffSec % 3600) / 60);

    return diffHour.toString().padStart(2, '0') + ':' + diffMin.toString().padStart(2, '0');
  }

  /**
   * 2つの時間の差分時間を取得します。
   * @param {Object} time1 日時1
   * @param {Object} time2 日時2 
   * @returns 2つの時間の差分時間（HH:mm表記）
   */
  static getTimeDifference(time1, time2) {

    // 変数定義
    let retValue = ""
    let diffHour = null
    let diffMin = null

    // Safari対応 XXXX-XX-XX ⇒ XXXX/XX/XX
    time1 = String(time1).replace('-', '/').replace('-', '/')
    time2 = String(time2).replace('-', '/').replace('-', '/')

    // 日付型に変換
    let date1 = new Date(String(time1));
    let date2 = new Date(String(time2));

    // 時間と分を取得
    let date1str = this.getFormatString(date1, "HHmm")
    let date2str = this.getFormatString(date2, "HHmm")

    // 時間と分が取得できた場合は差を算出
    if (date1str.length == 4 && date2str.length == 4){
      // 時間取得
      let hour1 = String(date1str).substr(0, 2)
      let hour2 = String(date2str).substr(0, 2)
      // 分取得        
      let min1 = String(date1str).substr(2, 2)
      let min2 = String(date2str).substr(2, 2)

      // 取得した時間、分を分に変換
      let totalMin1 = Number(hour1 * 60) + Number(min1)
      let totalMin2 = Number(hour2 * 60) + Number(min2)

      // 分での差を算出
      if (totalMin2 <= totalMin1){
        // 出発時間が到着時間より遅い場合
        retValue = '00:00'
      } else {
        // 差を算出
        let diffTime = totalMin2 - totalMin1
        if (diffTime >= 60){
          // 60以上大きい場合は、割り算と余りを求める
          diffHour = Math.floor(diffTime / 60)
          diffMin = diffTime % 60
          retValue = diffHour.toString().padStart(2, '0') + ':' + diffMin.toString().padStart(2, '0');
        } else {
          // 60より小さい場合は、分のみ
          retValue = `00:${diffTime.toString().padStart(2, '0')}`
        }
      }
    }

    return retValue;
  }
  /**
   * 日を加算
   * @param {*} date 基準の日付
   * @param {*} month 加算する月数
   * @returns 
   */
  static addDay(date, day) {
    return dayjs(date).add(day, 'd')
  }
  /**
   * 月を加算
   * @param {*} date 基準の日付
   * @param {*} month 加算する月数
   * @returns 
   */
  static addMonth(date, month) {
    return dayjs(date).add(month, 'M')
  }
  /**
   * 分を加算
   * @param {*} date 基準の日付
   * @param {*} month 加算する分数
   * @returns 
   */
  static addMinute(date, minute) {
    return dayjs(date).add(minute, 'm')
  }

  static dayArray(date) {
    let list = []
    let startDate = dayjs(date).startOf('month');
    let endDate = dayjs(date).endOf('month');
    for (let date=startDate; date<=endDate; date=date.add(1, 'day')){
      list.push(date)
    }
    return list
  }

}

module.exports = DateUtil
