const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil");
const baseModel = require("../baseModel");
const vehicleModel = require("../Vehicle/VehicleModel");
const driveModel = require("../Drive/DriveModel");

/**
 * 最終座標情報用のデータ取得モデルクラス
 */
class LastLocationModel extends baseModel {
  /**
   * テナント内の最終座標情報リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getLastLocationList(projectId) {
    return await super.getList({ beginsWith: `lastLocation#${projectId}` });
  }

  /**
   * 最終座標情報を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deletelastLocation(data) {
    await super.deleteData(data);
  }

  /**
   * 最終座標情報管理レコードを追加する
   * @param {*} data
   */
  static async addLastLocation(data) {
    await super.addData(data);
  }

  /**
   * 最終座標情報管理レコードを更新する
   * @param {*} data
   */
  static async updateLastLocation(data) {
    await super.updateData(data);
  }

  /**
   * 最終座標情報管理レコードを取得する
   * @param {*} data
   */
  static async getLastLocation(data) {
    return await super.getSingleData(data);
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, targetDate, vehicleId, endAreaId) {
    // lastLocation#プロジェクトID#yyyyMMDD#車両ID#到着拠点ID
    return `lastLocation#${projectId}#${targetDate}#${vehicleId}#${endAreaId}`;
  }

  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, targetDate, vehicleId, endAreaId) {
    let newRowData = await super.getNewData();

    const id = short.generate();
    newRowData.sk = this.createSk(projectId, targetDate, vehicleId, endAreaId);

    newRowData.location = {
      id: id,
      x: 0,
      y: 0,
      driveId: "",
      vehicleId: "",
      endAreaId: "",
      pouringPosition: "",
      carryType: 1,
    };

    return newRowData;
  }

  /**
   * 最終位置情報リストの変換リストを取得します。（当日/過去用）
   * @param {String} projectId プロジェクトID
   * @param {String} targetDate 対象日付(YYYYMMDD)
   * @param {Array}} results 最終位置情報リスト
   * @returns 変換後リスト
   */
  static async convertLocationListData(projectId, targetDate, results) {
    // console.log(results);

    let list = [];

    const sk = `${projectId}#${targetDate}`;

    ///////////////////////////////////
    // 高速化のため運行情報、車両情報を並列で取得
    const [driveList, vehicleList] = await Promise.all([
      driveModel.getDriveList(sk),
      vehicleModel.getVehicleList(projectId),
    ]);

    // 運行情報
    // const driveList = await driveModel.getDriveList(sk);
    // console.log(`driveList: ${JSON.stringify(driveList, null, "\t")}`);

    // 車両情報
    // const vehicleList = await vehicleModel.getVehicleList(projectId);
    // console.log(`vehicleList: ${JSON.stringify(vehicleList, null, "\t")}`);
    ///////////////////////////////////

    for (let i = 0; i < results.length; i++) {
      const item = results[i].lastLocation;

      // 運行情報
      // console.log(`endAreaId: ${item.endAreaId} vehicleId:${item.vehicleId} driveId:${item.driveId}`);
      const driveData = driveModel.getDriveData(
        item.endAreaId,
        item.pouringPosition,
        item.vehicleId,
        item.driveId,
        driveList
      );
      // console.log(`driveData: ${JSON.stringify(driveData, null, "\t")}`);

      if (!driveData) {
        // 運行情報が見つからない
        console.log(
          `運行情報なし 到着拠点ID: ${item.endAreaId} 車両ID: ${item.vehicleId} 運行情報id:${item.driveId}`
        );
        continue;
      }

      // 車両情報(名称、表示順）
      const vehicleData = vehicleModel.getVehicleData(
        item.vehicleId,
        vehicleList
      );
      // console.log(`vehicleData: ${JSON.stringify(vehicleData, null, "\t")}`);

      if (!vehicleData) {
        // 車両情報が見つからない
        console.log(`車両情報未登録: ${item.vehicleId}`);
        continue;
      }

      list.push({
        id: item.id,
        x: item.x,
        y: item.y,
        driveId: item.driveId,
        vehicleId: item.vehicleId,
        endAreaId: item.endAreaId,
        pouringPosition: item.pouringPosition,
        carryType: item.carryType,
        // 運行情報
        roadType: driveData.drive.roadType,
        // factoryStartTime: driveData.drive.driveData,
        // 車両情報(名前は将来ツールチップ用)
        vehicleName: vehicleData.name,
        vehicleOrder: vehicleData.order,
        // 車両ナンバープレート
        numberPlate: vehicleData.numberPlate,
        // 仮置き（後でソート後に付番）
        label: 0,
        // 工場出発時刻
        factoryStartTime: driveData.drive.factoryStartTime,
        // 現場到着時間
        siteEndTime: driveData.drive.siteEndTime,
        // 現場出発時間
        siteStartTime: driveData.drive.siteStartTime,
        // 工場到着時間
        factoryEndTime: driveData.drive.factoryEndTime,
        // 運行回数
        driveNumber: driveData.drive.driveNumber,
        // 最大積載量（㎥）
        maxCapacity: driveData.drive.maxCapacity,
        // 渋滞フラグ（null, 0:渋滞なし 1:渋滞中）
        congestionFlag: driveData.drive.congestionFlag,
        // 積載量
        loadCapacity: driveData.drive.loadCapacity,
      });
    }

    console.log(list);

    return list;
  }

  /**
   * 最終座標リストを取得する(運行情報地図表示用)
   * @param {String} sk 取得キー(projectId#yyyyMMDD)
   * @param {Number} orderType 表示順序(1:工場出発順、2:車両番号順)
   * @param {Array} vehicleMapTextOrders 車両名と地図アイコン表示順のリスト
   * @returns 地図表示用最終座標データリスト
   */
  static async getLastLocationMapList(sk, orderType, vehicleMapTextOrders) {
    // console.log(`sk: ${sk}`);

    const sks = sk.split("#");
    const projectId = sks[0];
    const targetData = sks[1];

    // 最終座標情報
    let locationList = await this.convertLocationListData(
      projectId,
      targetData,
      await this.getLastLocationList(sk)
    );
    // console.log(`locationList: ${JSON.stringify(locationList, null, "\t")}`);

    // 先に並び替え 不要かも
    if (orderType == 1) {
      // 工場出発順
      locationList = locationList.sort(function(a, b) {
        return a.factoryStartTime < b.factoryStartTime ? -1 : 1;
      });
    } else {
      // 車両番号順
      locationList = locationList.sort(function(a, b) {
        return a.vehicleOrder < b.vehicleOrder ? -1 : 1;
      });
    }
   

    let mapIcons = [];

    for (let i = 0; i < locationList.length; i++) {
      const item = locationList[i];

      // 地図アイコン表示用の順番を決定
      let order = 0;
      if (vehicleMapTextOrders && vehicleMapTextOrders.length > 0) {
        const vehicleMapTextOrder = vehicleMapTextOrders.find(
          (vehicle) => vehicle.vehicleId === item.vehicleId
        );
        // lastLocationは画面で選択されている打設箇所で絞り込みができないため、選択日のレコード全て取得している。
        // driveから作成したvehicleMapTextOrderに該当しない場合がある。
        console.log(
          `getLastLocationMapList ${JSON.stringify(vehicleMapTextOrder)}`
        );

        if (vehicleMapTextOrder) {
          order = vehicleMapTextOrder.iconTextOrder;
        } else {
          order = null;
        }
      }

      // 並び順取得できない場合は次へ
      if (order == null) {
        continue;
      }

      // 見つからなかった場合（※表示対象外とするか）
      if (!order || order == 0) {
        order = i + 1;
      }

      // let order = i + 1;

      // アイコン注記ラベル用の表示順を設定
      item.label = order;

      // 平均到着、帰着時間を設定
      let averageArrive = 0;
      let averageReturn = 0;
      let arriveDiffList = []
      let returnDiffList = []
  
      if (locationList.length > 0){
        // 運行情報リスト取得
        let driveList = await driveModel.getDriveListbyAreaId(projectId, targetData, item.endAreaId);  
        
        driveList = driveList.filter(function(driveItem){
          return driveItem.drive.pouringPosition == item.pouringPosition
        }.bind(this))
  
        driveList.forEach(function(driveItem){
          if ((driveItem.drive.factoryStartTime != "" && driveItem.drive.factoryStartTime != null) &&
           (driveItem.drive.siteEndTime != "" && driveItem.drive.siteEndTime != null)) {
            // 工場出発時刻と現場到着時刻の差分を取得する
            arriveDiffList.push(DateUtil.diff(driveItem.drive.siteEndTime, driveItem.drive.factoryStartTime, "m", false))
          }
  
          if ((driveItem.drive.siteStartTime != "" && driveItem.drive.siteStartTime != null) &&
           (driveItem.drive.factoryEndTime != "" && driveItem.drive.factoryEndTime != null)) {
            // 工場出発時刻と現場到着時刻の差分を取得する
            returnDiffList.push(DateUtil.diff(driveItem.drive.factoryEndTime, driveItem.drive.siteStartTime, "m", false))
          }
        })
  
        // 平均到着時間を算出
        if (arriveDiffList.length > 0) {
          averageArrive = Math.round((arriveDiffList.reduce((sum, value) => { return sum + value })) / arriveDiffList.length)
        } 
      
        // 平均帰着時間を算出
        if (returnDiffList.length > 0) {
          averageReturn = Math.round((returnDiffList.reduce((sum, value) => { return sum + value })) / returnDiffList.length)
        }
      }

      item.averageArrive = averageArrive;
      item.averageReturn = averageReturn;

      mapIcons.push(item);
    }

    // console.log(`mapIcons: ${JSON.stringify(mapIcons, null, "\t")}`);
    return mapIcons;
  }
}

module.exports = LastLocationModel;
