const { API, graphqlOperation } = require("aws-amplify");
// const {
//   createKonoikeConcreteData,
//   deleteKonoikeConcreteData,
//   updateKonoikeConcreteData,
// } = require("@/graphql/mutations");
const {
  listKonoikeConcreteData,
  getKonoikeConcreteData,
  listDataFromLsiStr1,
  listDataFromLsiStr2,
} = require("@/graphql/queries");
const baseModel = require("../baseModel")

/**
 * テナント用のデータ取得モデルクラス
 */
class TenantModel extends baseModel {
  /**
   * テナント内のテナントリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getTenantList() {
    
    const result = await API.graphql(
      graphqlOperation(listKonoikeConcreteData, {
        pk: "tenant",
        sk: { beginsWith: "tenant#" }
      })
    );
    //alert(JSON.stringify(result))
    if (result) {
      if (result.data) {
        //console.log(`getList : ${result.data.listKonoikeConcreteData.items.length}`)
        return result.data.listKonoikeConcreteData.items;
      }
    }
    return [];
  }
  /**
   * 指定月に契約終了しているリストを取得
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getEndTenantList(salerId, dateYmString) {
    const result = await API.graphql(
      graphqlOperation(listDataFromLsiStr2, {
        pk: "tenant",
        lsiStr2: { beginsWith: `tenant#${salerId}#${dateYmString}` }
      })
    );
    
    if (result) {
      if (result.data) {
        return result.data.listDataFromLsiStr2.items;
      }
    }
    return [];
  }
  /**
   * 指定月以前に契約終了しているリストを取得
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getEndTenantListBefore(salerId, dateYmString) {
    const result = await API.graphql(
      graphqlOperation(listDataFromLsiStr2, {
        pk: "tenant",
        lsiStr2: { le: `tenant#${salerId}#${dateYmString}` }
      })
    );
    
    if (result) {
      if (result.data) {
        return result.data.listDataFromLsiStr2.items;
      }
    }
    return [];
  }
  /**
   * テナント内のテナントリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getActiveContractList(salerId, dateYmdString) {
    
    const result = await API.graphql(
      graphqlOperation(listDataFromLsiStr2, {
        pk: "tenant",
        lsiStr2: { ge: `tenant#${salerId}#${dateYmdString}` }
      })
    );
    
    if (result) {
      if (result.data) {
        return result.data.listDataFromLsiStr2.items;
      }
    }
    return [];
  }
  

  /**
   * テナントを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteTenant(data) {
    await super.deleteData(data)
  }

  /**
   * テナント管理レコードを追加する
   * @param {*} data
   */
  static async addTenant(data) {
    await super.addData(data)
  }

  /**
   * テナント管理レコードを更新する
   * @param {*} data
   */
  static async updateTenant(data) {
    delete data.__typename;
    delete data.tenant.__typename;
    await super.updateData(data)
  }

  /**
   * テナント管理レコードを取得する
   * @param {*} data
   */
  static async getTenant(tenantId) {
    let result = await API.graphql(
      graphqlOperation(getKonoikeConcreteData, { pk: "tenant", sk: `tenant#${tenantId}` })
    );
    return result.data.getKonoikeConcreteData;
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(tenantId) {
    return `tenant#${tenantId}`;
  }
  /**
   * lsiStr1の文字列を生成
   * @param {*}
   * @returns
   */
  static createLsiStr1(salerId, constractStart) {
    return `tenant#${salerId}#${constractStart}`;
  }
  /**
   * lsiStr2の文字列を生成
   * @param {*}
   * @returns
   */
  static createLsiStr2(salerId, constractEnd) {
    return `tenant#${salerId}#${constractEnd}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {

    let newRowData = {
      pk: `tenant`,
      sk: ``,
      lsiStr1: "-",
      lsiStr2: "-",
      lsiStr3: "-",
      lsiStr4: "-",
      lsiInt1: 0,
      groups: [],
      
    }
    
    newRowData.tenant = {
      tenantId: ``,
      name: "",
      contractStart: "",
      contractEnd: "",
      status: "",
      availableOptions: [],
      email: "",
      initUserId: ""
    }

    return newRowData
  }
}

module.exports = TenantModel;
