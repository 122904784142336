const settingModel = require("../settingModel")

/**
 * 月別温度設定のデータ取得モデルクラス
 */
class MonthTemperatureModel extends settingModel {
  /**
   * 月別温度設定リストを取得する
   * @returns
   */
  static async getMonthTemperatureList() {
    return await super.getList("monthTemperature", { beginsWith: `monthTemperature#` })
  }
}

module.exports = MonthTemperatureModel;
