const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * 車両用のデータ取得モデルクラス
 */
class VehicleModel extends baseModel {
  /**
   * テナント内の車両リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getVehicleList(projectId) {
    return await super.getList({ beginsWith: `vehicle#${projectId}` })
  }

  /**
   * 車両を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteVehicle(data) {
    await super.deleteData(data)
  }

  /**
   * 車両管理レコードを追加する
   * @param {*} data
   */
  static async addVehicle(data) {
    await super.addData(data)
  }

  /**
   * 車両管理レコードを更新する
   * @param {*} data
   */
  static async updateVehicle(data) {
    await super.updateData(data)
  }

  /**
   * 車両管理レコードを取得する
   * @param {*} data
   */
  static async getVehicle(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    return `vehicle#${projectId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, id)
    
    newRowData.vehicle = {
      id: id,
      name: "",
      maxCapacity: 0,
      memo: "",
      order: DateUtil.getUnixTimeStampOfSecond()
    }

    return newRowData
 
  }

  /**
   * 車両情報を取得します。
   * @param {*} vehicleId 車両ID
   * @param {*} vehicleList 車両データリスト
   * @returns 
   */
  static getVehicleData(vehicleId, vehicleList) {

    let item = null;

    for (let i = 0; i < vehicleList.length; i++) {
      const vehicle = vehicleList[i];

      if (vehicle.vehicle.id == vehicleId) {
        item = vehicle.vehicle;
        break
      }
    }
    
    let vehicle = {
      name: ``,
      order: ``,
    };
    
    // console.log(item);

    if (item) {
      vehicle.name = item.name;
      vehicle.order = item.order;
      vehicle.numberPlate = item.numberPlate;
      // console.log(vehicle);
    }
    
    return vehicle;
  }
}

module.exports = VehicleModel;
