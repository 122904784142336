const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")
const { API, graphqlOperation } = require("aws-amplify");



/**
 * 受入検査用のデータ取得モデルクラス
 */
class AcceptanceModel extends baseModel {
  /**
   * テナント内の受入検査リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getAcceptanceList(projectId, targetDate) {
    return await super.getList({ beginsWith: `acceptance#${projectId}#${targetDate}`})
  }

  static async getPkSkOnlyList(projectId, targetDate) {
    return await super.getListPkSk({ beginsWith: `acceptance#${projectId}#${targetDate}`})
  }

  /**
   * 予定数量IDで受入検査リストを取得する
   * @param {*} projectId 
   * @param {*} volumeId 予定数量ID
   */
  static async getAcceptanceListFromVolumeId(projectId, volumeId) {
    return await super.getLsistr1List({ beginsWith: `acceptance#${projectId}#${volumeId}`})
  }

  /**
   * 受入検査を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteAcceptance(data) {
    await super.deleteData(data)
  }

  /**
   * 受入検査管理レコードを追加する
   * @param {*} data
   */
  static async addAcceptance(data) {
    await super.addData(data)
  }

  /**
   * 受入検査管理レコードを更新する
   * @param {*} data
   */
  static async updateAcceptance(data) {
    await super.updateData(data)
  }

  /**
   * 受入検査管理レコードを取得する
   * @param {*} data
   */
  static async getAcceptance(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, inspectionDate, id) {
    return `acceptance#${projectId}#${inspectionDate}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, inspectionDate) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, inspectionDate, id)
    
    newRowData.acceptance = {
      acceptanceId: id,
      inspectionDateTime: "",
      slump: 0,
      chlorideQuantity: 0,
      airVolume: 0,
      ct: 0,
      at: 0,
      photos: [],
      memo: "",
      weather: "0",
      updateEditor: 1,
      updateUserID: "",
      updateDate: ""
    }

    return newRowData
 
  }

  /**
   * 受入検査情報を取得します。
   * @param {*} acceptanceId 受入検査ID
   * @param {*} acceptanceList 受入検査データリスト
   * @returns 
   */
  /*
  static getAcceptanceData(acceptanceId, acceptanceList) {

    let item = null;

    for (let i = 0; i < AcceptanceList.length; i++) {
      const acceptance = AcceptanceList[i];

      if (vehicle.vehicle.id == vehicleId) {
        item = vehicle.vehicle;
        break
      }
    }
    
    let vehicle = {
      name: ``,
      order: ``,
    };
    
    // console.log(item);

    if (item) {
      vehicle.name = item.name;
      vehicle.order = item.order;
      // console.log(vehicle);
    }
    
    return vehicle;
  }
  */
}

module.exports = AcceptanceModel;
