<template>
  <div>
    <!-- <div class="container-fluid mt-1"> -->
      <div class="card">
          <div class="card-header border-0">
            <div class="row ml-0">
              <table-tool-menu
                class="col-12"
                :title="title"
                :addTitle="addTitle"
                :isAdd="userAuthority"
                :isSingleSelect="false"
                :isMultiSelect="false"
                :processing="false"
                :disableMoveDown="false"
                :disableMoveUp="false"
                @addNew="onClickNewUser"
              >
              </table-tool-menu>
              <!--
              <div class="col-6 text-right">
                <base-button
                  icon
                  outline
                  type="primary"
                  @click="onClickNewUser"
                >
                  <span class="btn-inner--icon"
                    ><i class="fa fa-plus-square"></i
                  ></span>
                  <span class="btn-inner--text">新しいユーザーを追加</span>
                </base-button>
              </div>
               -->
            </div>
          </div>
        <div class="user-list">
          <el-table
              class="table-responsive table-flush"
              header-row-class-name="app-th-row"
              row-key="id"
              @sort-change="runSort"
              :default-sort="{ prop: 'user.userId', order: 'ascending' }"
              :data="pagedData"
            >
              <el-table-column
                label="ユーザー名"
                min-width="220px"
                prop="user.name"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  <b>
                    <a href="#!" @click.prevent="onClickEditUser(row)"
                      >{{ row.user.name }}
                    </a>
                  </b>
                </template>
              </el-table-column>
              <el-table-column
                label="ユーザーID"
                min-width="220px"
                prop="user.userId"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  {{ row.user.userId }}
                </template>
              </el-table-column>

              <el-table-column label="権限" prop="user.usertype" min-width="120px" sortable="'custom'">
                <template v-slot="{ row }">
                  <span v-if="row.user.usertype == '0'">一般</span>
                  <span v-if="row.user.usertype == '1'">管理者</span>
                  <span v-if="row.user.usertype == '2'">スーパー管理者</span>
                  <span v-if="row.user.usertype == '3'">iOSアプリ</span>
                  <span v-if="row.user.usertype == '99'">メンテナンスユーザー</span>
                  <span></span>
                </template>
              </el-table-column>

            </el-table>

            <div class="row mt-1">
              <div class="col">
                <div class="mt-1 d-flex justify-content-center">
                  <el-pagination
                    @current-change="setCurrent"
                    :page-size="pagination.perPage"
                    :current-page="pagination.currentPage"
                    layout="prev, pager, next"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>

        </div>
      <!-- </div> -->
  </div>
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .user-list {
    width: 100%;
    height: 100%;
  }

  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>

<script>
import UserModel from "../../appModel/users/UserModel"
import TableToolMenu from "../components/Menu/TableToolMenu"
import SortUtil from '../../appUtils/SortUtil';
import appLog from "@/appUtils/AppLog"
import ValidSession from "../common/ValidSession.js";
import DateUtil from "@/appUtils/DateUtil"
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";

export default {
  components: {
    TableToolMenu
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      users: {},
      userList: [],
      items: [
      ],
      userAuthority: true,
      title: "ユーザー一覧",
      addTitle: "ユーザーを追加",
      isAdd: true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）
      /**
       * 初期ソート（ユーザーID=>昇順)
       */
      sortColumn: {
        prop: 'user.userId',
        order: 'ascending',
      }  
    };
  },

  // コンピュートプロパティ
  computed: {
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.userList.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.userList.length;
    },
    vuename() {
      return "UserList.vue"
    },   
  },

  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    let timelineData = {
      projectType: `0`,
      projectid: ``,
      projectname: ``,
      datestring: DateUtil.getDateString('YYYY/MM/DD')
    }
    this.$store.commit("setTimeline", timelineData);

    appLog.infoLog("UserList.vue", this.$store.state.user.userId, `Start Vuex：editUserId(${this.$store.state.user.editUserId})、createFlg(${this.$store.state.user.createFlg})、fromNavbar(${this.$store.state.user.fromNavbar})、cognitoSub(${this.$store.state.user.cognitoSub})、userId(${this.$store.state.user.userId})、userType(${this.$store.state.user.userType})、userName(${this.$store.state.user.userName})、loginDateTIme(${this.$store.state.user.loginDateTIme})、group(${this.$store.state.user.group})`)
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    appLog.infoLog("UserList.vue", this.$store.state.user.userId, `End`)
  },
  methods: {

    async init() {
      this.infoLog("init", "start")
      let loader = this.showLoader();

      try {

        if (this.$store.state.user.userType == 0) {
          // 一般ユーザーは追加不可
          this.userAuthority = false
          
        } else {
          // 管理者、スーパーユーザーのみ
          this.userAuthority = true
        }

        await this.getUserList()
        await this.runSort(this.sortColumn);
        
        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = "ユーザー一覧"
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)

        // 連打防止
        this.repeatedHitsFlg = false
        
      } catch (e) {
        appLog.errLog("UserList.vue", this.$store.state.user.userId, `${e.message}`)
        this.errorLog("init", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
        this.infoLog("init", "end")
      }
    },
    async getUserList() {
      this.userList = await UserModel.getEditableUserList();
    },
    onClickEditUser(row) {

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true

      // console.log(JSON.stringify(row, null, "\t"));
      let id = row.sk.split("#")[1];
      let params = this.$store.state.user
      params.editUserId = id
      params.createFlg = false
      params.fromNavbar = false
      this.$store.commit("setUser", params)
      this.infoLog("onClickEditUser", JSON.stringify(params))
      this.$router.push({
        path: `/edituser`
      });

    },
    onClickNewUser() {

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true

      let params = this.$store.state.user
      params.editUserId = null
      params.createFlg = true
      params.fromNavbar = false
      this.$store.commit("setUser", params)
      this.infoLog("onClickNewUser", JSON.stringify(params))
      this.$router.push({
        name: "MakeUser",
      });
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      // this.$nextTick(async function() {
      await this.changeTableSort(column);
      // }.bind(this))

    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      // console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      // console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = false;
      if (fieldName == "user.name") {
        isKana = true;
      }

      // let list = JSON.parse(JSON.stringify(this.userList))
      // this.userList = null;
      // this.userList = SortUtil.sort(list, fieldName, sortingType, isKana);

      this.userList = SortUtil.sort(this.userList, fieldName, sortingType, isKana);
      // console.log(this.userList);

    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },
    /**
     * コンソール出力のみ。
     */
     debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  }
};
</script>
