const { Auth } = require("aws-amplify");
const TenantModel = require("../appModel/Tenant/TenantModel")
const DateUtil = require('./DateUtil');
const UserInfo = require("./UserInfo");
const AppLog = require("./AppLog")

const alertMessage = `ログインセッションが切れました。\rお手数ですが再度ログインをお願いします。`

class AuthUtil {

  /**
   * ユーザーセッション切れかどうかを判定します。
   * @param {string} tenantId テナントID
   * @returns 有効の場合true、さもなくばfalse。
   */
  static async isValidSession(tenantId) {

    let data = null;

    // test
    // await Auth.signOut();

    try {
      // 認証済みであるか
      data = await Auth.currentAuthenticatedUser();

    } catch (e) {
      AppLog.errLog("AuthUtil", "", `ユーザーセッション有効期限切れ ${JSON.stringify(e)}`)
      // セッションなし
      return false;
    }

    if (!data || !data.signInUserSession) {
      // console.log(`ユーザーセッション有効期限切れ`)
      AppLog.errLog("AuthUtil", "", `ユーザーセッション有効期限切れ`)
      return false;
    }
    // テナンtのIDチェック
    if (!tenantId) {
      // テナントID指定なしはfalseで返す
      return false;
    }
    // 契約期間有効期限確認
    const isValidPeriod = await this.isValidPeriod(tenantId);
    if (!isValidPeriod) {
      // 有効期限切れ
      // console.log(`契約期間有効期限切れ`)
      AppLog.debugLog("AuthUtil", "", `契約期間有効期限切れ`)

      // サインアウト
      await Auth.signOut();

      return false;
    }

    return true;
  }

  static alert() {
    alert(alertMessage);
  }

  static async refreshToken(cognitoUser) {
    // console.log(`refreshToken`);
    AppLog.debugLog("AuthUtil", "", `refreshToken`)

    try {
      const currentSession = await Auth.currentSession();
      //console.log(JSON.stringify(currentSession.refreshToken));
      AppLog.debugLog("AuthUtil", "", JSON.stringify(currentSession.refreshToken))

      await cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (err) {
          //console.log(err)
          AppLog.errLog("AuthUtil", "", err)
        } else {
          // session.refreshTokenの内容が新しくなっていればOKだが
          // console.log(JSON.stringify(session));
          //console.log(JSON.stringify(session.refreshToken))
          AppLog.debugLog("AuthUtil", "", JSON.stringify(session.refreshToken))
        }
      });

    } catch (e) {
      // console.log(`refreshTokenエラー:${e}`)
      AppLog.errLog("AuthUtil", "", `refreshTokenエラー:${e}`)
    }
  }

  /**
   * 有効期限切れかどうかを判定します。
   * @returns 有効期限が有効の場合true、さもなくばfalse。
   */
  static async isValidPeriod(tenantId) {
    
    try {
      if (!tenantId) {
        // ToDo テナント情報なし
        // console.log('テナント情報なし');
        AppLog.debugLog("AuthUtil", "", 'テナント情報なし')
        return false;
      }
      let tenant = await TenantModel.getTenant(tenantId);
      // console.log(tenant);
      tenant = tenant.tenant;
  
      // 現在日時
      const now = DateUtil.getDateString('YYYYMMDD');
      // const now = new Date(nowDateTime);
  
      // 開始/終了
      const format = 'YYYYMMDD';
      const start = DateUtil.getFormatString(tenant.contractStart, format);
      const end = DateUtil.getFormatString(tenant.contractEnd, format);
  
      // const start = new Date(contractStart);
      // const end = new Date(contractEnd);
  
      // return DateUtil.isWithinDate(nowDateTime, contractStart, contractEnd)
  
      console.log(`${now} ${start} ${end}`)
  
      if (now < start || now > end) {
        // 有効期限範囲外
        return false;
      }
  
      return true;
    } catch (e) {
      try {
        AppLog.errorLog("AuthUtil", `${e.stack}`, 'テナント情報なし')
      } finally {
        console.log(`log error`)
      }
      // 期限判定の例外発生時はtrueを返しておく
      return true
    }
  }
}

module.exports = AuthUtil;
