const { group } = require("d3");
const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * プロジェクト用のデータ取得モデルクラス
 */
class GeoLogModel extends baseModel {
  /**
   * テナント内のプロジェクトリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getGeoLogList(flg, groupId, yyyymm) {
    return await super.getList({ beginsWith: `geolog#${flg}#${yyyymm}` }, groupId)
  }

  /**
   * プロジェクト管理レコードを取得する
   * @param {*} data
   */
  static async getGeoLog(pk, sk) {
    return await super.getSingleData({pk: pk, sk: sk});
  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    return `inspectMaster#${projectId}#${id}`;
  }
  
}

module.exports = GeoLogModel;
