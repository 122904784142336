const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")
const vehicleModel = require("../Vehicle/VehicleModel")
const baseAreaModel = require("../BaseArea/BaseAreaModel");
const driveModel = require("../Drive/DriveModel")

/**
 * iOS送信座標情報用のデータ取得モデルクラス
 */
class LocationModel extends baseModel {
  /**
   * テナント内のiOS送信座標情報リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getLocationList(projectId) {
    return await super.getList({ beginsWith: `location#${projectId}` })
  }

  /**
   * iOS送信座標情報を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteLocation(data) {
    await super.deleteData(data)
  }

  /**
   * iOS送信座標情報管理レコードを追加する
   * @param {*} data
   */
  static async addLocation(data) {
    await super.addData(data)
  }

  /**
   * iOS送信座標情報管理レコードを更新する
   * @param {*} data
   */
  static async updateLocation(data) {
    await super.updateData(data)
  }

  /**
   * iOS送信座標情報管理レコードを取得する
   * @param {*} data
   */
  static async getLocation(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, targetDate, endAreaId, vehicleId, id) {
    // location#プロジェクトID#yyyyMMDD#到着拠点ID#車両ID#送信座標Id(uuidで採番)    
    return `location#${projectId}#${targetDate}#${endAreaId}#${vehicleId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, targetDate, endAreaId, vehicleId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, targetDate, endAreaId, vehicleId, id)
    
    newRowData.location = {
      id: id,
      x: 0,
      y: 0,
      driveId: "",
      vehicleId: "",
      endAreaId: "",
      pouringPosition: "",
      carryType: 1
    }

    return newRowData
 
  }

  /**
   * 位置情報リストの変換リストを取得します。（当日/過去用）
   * @param {String} projectId プロジェクトID
   * @param {Array}} results 位置情報リスト
   * @returns 変換後リスト
   */
  static async convertLocationListData(projectId, results) {

    let list = []

    // 運行情報
    const driveList = await driveModel.getDriveList(projectId);

    // 車両情報
    const vehicleList = await vehicleModel.getVehicleList(projectId);
    // console.log(`vehicleList: ${JSON.stringify(vehicleList, null, "\t")}`);

    // 工区情報
    const baseAreaList = await baseAreaModel.getBaseAreaList(projectId);
    // console.log(`baseAreaList: ${JSON.stringify(baseAreaList, null, "\t")}`);

    for (let i = 0; i < results.length; i++) {
      const row = results[i];

      // 車両情報(名称、表示順） 
      const vehicleData = this.getVehicleData(row, vehicleList);
      // console.log(`vehicleData: ${JSON.stringify(vehicleData, null, "\t")}`);
      
      // 工区名称
      const endAreaName = await this.getEndAreaName(row, baseAreaList);
      // console.log(`endAreaName: ${JSON.stringify(endAreaName, null, "\t")}`);

      const item = row.drive;

      list.push({
        id: item.id,
        vehicleId: item.vehicleId,
        driveNumber: item.driveNumber,
        factoryStartTime: item.factoryStartTime,
        siteEndTime: item.siteEndTime,
        pouringStartTime: item.pouringStartTime,
        pouringEndTime: item.pouringEndTime,
        siteStartTime: item.siteStartTime,
        factoryEndTime: item.factoryEndTime,
        roadType: item.roadType,
        endAreaId: item.endAreaId,
        pouringPosition: item.pouringPosition,
        carryType: item.carryType,
        maxCapacity: item.maxCapacity,
        pouringStartUser: item.pouringStartUser,
        pouringEndUser: item.pouringEndUser,
        // 車両情報
        vehicleName: vehicleData.name,
        vehicleOrder: vehicleData.order,
        // 工区名
        endAreaName: endAreaName,
        // DBデータ(更新用)
        rawData: Object.assign({}, row)
      })
    }    

    return list;

  }
}

module.exports = LocationModel;
