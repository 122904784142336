const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * ルート用のデータ取得モデルクラス
 */
class RouteModel extends baseModel {
  /**
   * テナント内のルートリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getRouteList(project, startAreaId = "", endAreaId = "") {
    let skCondition = `route#${project}`
    skCondition = startAreaId ? `${skCondition}#${startAreaId}` : skCondition
    skCondition = endAreaId ? `${skCondition}#${endAreaId}` : skCondition
    console.log(`RouteModel.getRouteList skCondition: ${skCondition}`)
    return await super.getList({ beginsWith: `${skCondition}` })
  }

  /**
   * ルートを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteRoute(data) {
    await super.deleteData(data)
  }

  /**
   * ルート管理レコードを追加する
   * @param {*} data
   */
  static async addRoute(data) {
    await super.addData(data)
  }

  /**
   * ルート管理レコードを更新する
   * @param {*} data
   */
  static async updateRoute(data) {
    await super.updateData(data)
  }

  /**
   * ルート管理レコードを取得する
   * @param {*} data
   */
  static async getRoute(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, startAreaId, endAreaId, roadType, id) {
    // Route#プロジェクトID#ルートID(uuidで採番)

    return `route#${projectId}#${startAreaId}#${endAreaId}#${roadType}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId, startAreaId, endAreaId, roadType) {
    let newRowData = await super.getNewData()
    
    //const id = short.generate()
    let date = new Date()
    
    const id = short.generate();

    newRowData.sk = this.createSk(projectId, startAreaId, endAreaId, roadType, id)
    
    newRowData.lsiStr1 = `route#${projectId}#${startAreaId}#${endAreaId}#${roadType}#${id}`

    newRowData.route = {
      routeId: id,
      routeGeometry: "",
      routeDistance: 0.0,
      roadType: roadType
    }

    return newRowData
 
  }

  /**
   * ルート名称を取得します。
   * @param {*} id ルートID
   * @param {*} RouteList ルート情報リスト
   * @returns ルート名称
   */
  static getRouteName(id, RouteList) {
    let name = ``;

    for (let i=0; i<RouteList.length; i++) {
      const area = RouteList[i];

      if (area.Route.id == id)
      {
        name = area.Route.areaName;
        break
      }
    }

    return name;
  }

}

module.exports = RouteModel;
