class SortUtil {
  /**
   * テーブルのソート順を変更します。
   */
  static sort(tableList, fieldName, sortingType, isKana) {
    // console.log(tableList);

    if (fieldName === false) {
      return;
    }

    if (!fieldName) {
      return;
    }

    if (fieldName.indexOf(".") !== -1) {
      const items = fieldName.split(".");
      const chiled = items[0];
      const field = items[1];
      tableList = this.sortNest(tableList, chiled, field, sortingType, isKana);
      // console.log(tableList);

      return tableList;
    }

    if (sortingType == "descending") {
      if (!isKana) {
        tableList = tableList.sort((a, b) => {
          // console.log(a[fieldName]);
          if (b[fieldName] < a[fieldName]) return -1;
          if (b[fieldName] > a[fieldName]) return 1;
        });
      } else {
        // 日本語ソート
        tableList = tableList.sort((a, b) => {
          // console.log(a[`project`][`name`]);
          // nullは空文字に置換
          if (!a[fieldName]) a[fieldName] = "";
          if (!b[fieldName]) b[fieldName] = "";
          a = this.convertKana(a[fieldName].toString());
          b = this.convertKana(b[fieldName].toString());
          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
        });
      }
    } else {
      if (!isKana) {
        tableList = tableList.sort((a, b) => {
          console.log(`a:${a[fieldName]} b:${b[fieldName]}`);
          if (b[fieldName] < a[fieldName]) return 1;
          if (b[fieldName] > a[fieldName]) return -1;
        });
      } else {
        // 日本語ソート
        tableList = tableList.sort((a, b) => {
          // nullは空文字に置換
          if (!a[fieldName]) a[fieldName] = "";
          if (!b[fieldName]) b[fieldName] = "";
          a = this.convertKana(a[fieldName].toString());
          b = this.convertKana(b[fieldName].toString());
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      }
    }

    return tableList;
  }

  /**
   * テーブルのソート順を変更します。
   * ※1階層下のオブジェクト要素を指定する場合
   */
  static sortNest(tableList, chiled, fieldName, sortingType, isKana) {
    // console.log(tableList);

    if (fieldName === false) {
      return;
    }

    if (!fieldName) {
      return;
    }

    if (sortingType == "descending") {
      if (!isKana) {
        tableList = tableList.sort((a, b) => {
          // console.log(a[chiled][fieldName]);
          if (b[chiled][fieldName] < a[chiled][fieldName]) return -1;
          if (b[chiled][fieldName] > a[chiled][fieldName]) return 1;
        });
        // console.log(tableList)
      } else {
        // 日本語ソート
        tableList = tableList.sort((a, b) => {
          // console.log(a[chiled][fieldName]);
          // nullは空文字に置換
          if (!a[chiled][fieldName]) a[chiled][fieldName] = "";
          if (!b[chiled][fieldName]) b[chiled][fieldName] = "";
          a = this.convertKana(a[chiled][fieldName].toString());
          b = this.convertKana(b[chiled][fieldName].toString());
          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
        });
        // console.log(tableList)
      }
    } else {
      if (!isKana) {
        tableList = tableList.sort((a, b) => {
          if (b[chiled][fieldName] < a[chiled][fieldName]) return 1;
          if (b[chiled][fieldName] > a[chiled][fieldName]) return -1;
        });
      } else {
        // 日本語ソート
        tableList = tableList.sort((a, b) => {
          // nullは空文字に置換
          if (!a[chiled][fieldName]) a[chiled][fieldName] = "";
          if (!b[chiled][fieldName]) b[chiled][fieldName] = "";
          a = this.convertKana(a[chiled][fieldName].toString());
          b = this.convertKana(b[chiled][fieldName].toString());
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      }
    }

    return tableList;
  }

  // https://gist.github.com/kawanet/5553478
  /** カタカナをひらがなに変換する
   * @param {String} src - カタカナ
   * @returns {String} - ひらがな
   */
  static convertKana(src) {
    return src.replace(/[\u30a1-\u30f6]/g, function(match) {
      const chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }
}

module.exports = SortUtil;
