/**
 * TimeLine.vue, MobileTimeLine.vueの共通処理
 */

import appLog from "@/appUtils/AppLog";
import DateUtil from "@/appUtils/DateUtil";

import PlanModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import DriveModel from "@/appModel/Drive/DriveModel";

import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      // 到着地点（現場・工区）のリスト
      listArea: [],
      // 出発地点（工場）＋ 到着地点（現場・工区）のリスト
      listBaseArea: [],
      planId: "",
      planList: [],
      volumList: [],
      LoadingFlag: false,
      projectId: "",
      projectName: "",
      driveList: [],
      selectedVolumeId: '',
    };
  },

  created() {
    this.hello();
  },

  methods: {
    hello() {
      console.log(`MixinTimeLine!: ${this.vuename}`);
    },

    /**
     * タイトルをセット
     */
    setNavInfo() {
      let navInfo = this.$store.state.navInfo;
      navInfo.title = `${this.projectName} - ${this.$route.meta.title}`;
      navInfo.description = "";
      this.$store.commit("setNavInfo", navInfo);
    },

    /**
     * 工区の一覧取得し、セレクトボックスに値セット
     */
    getAreaList() {
      // 拠点の種別到着地のみ取得（IDでソート）
      let lists = this.listBaseArea
        .filter((list) => list.baseArea.areaType === 2)
        .slice()
        .sort(function(a, b) {
          if (a.baseArea.id < b.baseArea.id) return -1;
          if (a.baseArea.id > b.baseArea.id) return 1;
        });

      // セレクトボックスの項目に追加
      lists.forEach(
        function(list) {
          let item = {
            id: list.baseArea.id,
            name: list.baseArea.areaName,
          };

          this.listArea.push(item);
        }.bind(this)
      );
    },

    /**
     * 到着地点IDから名称取得
     * @paramas baseAreaID 到着拠点Id
     */
    getAreaName(baseAreaID) {
      let retVal = null;

      for (let i = 0; i < this.listArea.length; i++) {
        if (this.listArea[i].id == baseAreaID) {
          retVal = this.listArea[i].name;
          break;
        }
      }

      return retVal;
    },

    /**
     * 予定を取得します。
     */
    async getPlan() {
      let targetYmd = this.dateString.replaceAll("/", "");
      this.planList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetYmd
      );

      if (this.planList.length == 0) {
        this.planId = "";
        return;
      }

      // 指定日でplanレコードは1件だけ
      this.planId = this.planList[0].plan.id;
      // console.log(`getPlan ${this.planId}`)
    },

    /**
     * 予定数量リストを取得します。
     */
    async getVolumeList() {
      if (!this.planId) {
        // 対象日の予定が無ければプロジェクトすべての数量になるので取得しない
        this.volumList = [];
        return;
      }

      // プロジェクトID、予定IDを指定して取得
      const searchKey = `${this.projectId}#${this.planId}`;
      let listVolum = await VolumeModel.getVolumeList(searchKey);
      // console.log(`getVolumeList listVolum ${JSON.stringify(listVolum)}`)

      // 到着拠点IDを名称に変更
      listVolum.forEach((Item) => {
        for (let i = 0; i < this.listArea.length; i++) {
          // console.log(`getVolumeList find ${this.listArea[i].id} ${Item.volume.endAreaId}`)
          if (this.listArea[i].id == Item.volume.endAreaId) {
            Item.volume.endAreaName = this.listArea[i].name;
            break;
          }
        }
      });

      // ソートして一覧に表示（工区名称でソート）
      let volumSortedList = listVolum.sort(function(a, b) {
        return a.volume.endAreaName < b.volume.endAreaName ? -1 : 1;
      });

      // 重複を削除（工区IDと打設箇所（名称）)
      this.volumList = volumSortedList.filter(
        (element, index, self) =>
          self.findIndex(
            (e) =>
              e.volume.endAreaId === element.volume.endAreaId &&
              e.volume.pouringPosition === element.volume.pouringPosition
          ) === index
      );
    },

    /**
     * this.selectedVolumeIdからthis.pouringListのインデックスを取得します。
     */
    getPouringListIndex() {
      let pouringListIndex = -1;

      for (let i = 0; i < this.volumList.length; i++) {
        if (this.volumList[i].volume.id == this.selectedVolumeId) {
          pouringListIndex = i;
        }
      }

      return pouringListIndex;
    },

    /**
     * 運行情報リストから、車両IDと地図アイコン注記用表示順のリストを取得します。
     * @param {String} sk プロジェクトID#yyyyMMDD#到着拠点ID
     * @returns 車両IDと地図アイコン注記用表示順のリスト
     */
    async getVehicleMapTextOrders(sk, volumeId = null) {
      console.log(`[START] getVehicleMapTextOrders: ${sk}: ${volumeId}`);

      try {
        // 運行情報リスト(以降の処理用に保持)
        this.driveList = [];
        this.driveList = await DriveModel.getDriveList(sk);

        // 打設箇所で絞り込み
        if (volumeId) {
          this.driveList = this.driveList.filter(item => {
            return item.drive.volumeId == volumeId
          })
        }
        

        // 納入伝票情報リスト(以降の処理用に保持)
        this.deliveryList = [];
        let skList = sk.split('#');
        this.deliveryList = await DriveModel.getDelivaryList(`${skList[0]}#${skList[1]}`);

        // 運行ID(タイムスタンプ)で昇順ソート
        let numberingList = this.driveList.sort(function(a, b) {
          return a.drive.id > b.drive.id ? 1 : -1;
        });

        // 車両IDで重複削除
        // numberingList = numberingList.filter(
        //   (element, index, self) =>
        //     self.findIndex(
        //       (e) => e.drive.vehicleId === element.drive.vehicleId
        //     ) === index
        // );

        // 車両ID毎に採番のうえリスト化
        let vehicleNumberList = [];
        for (let j = 0; j < numberingList.length; j++) {
          const drive = numberingList[j].drive;
          const order = j + 1;
          const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
          vehicleNumberList.push(item);
        }

        // 運行ID(タイムスタンプ)で降順でソート
        let driveList = this.driveList.sort(function(a, b) {
          return a.drive.id < b.drive.id ? 1 : -1;
        });

        // 車両IDで重複を削除
        driveList = driveList.filter(
          (element, index, self) =>
            self.findIndex(
              (e) => e.drive.vehicleId === element.drive.vehicleId
            ) === index
        );

        let vehicleMapTextOrders = [];

        for (let i = 0; i < driveList.length; i++) {
          const drive = driveList[i].drive;
          // vehicleNumberListは運行IDの昇順で、車両IDの運行回数分の番号を持つリスト。
          // findLastにより最後に該当する要素を取得して画面表示される
          let vehicleNum = vehicleNumberList.length
          let order = 0;
          for (let j = vehicleNum-1; j >= 0; j--) {
            let vehicle = vehicleNumberList[j]
            if (vehicle.vehicleId === drive.vehicleId) {
              order = vehicle.iconTextOrder;
              break;
            }
          }
          // const order = vehicleNumberList.findLast(
          //   (vehicle) => vehicle.vehicleId === drive.vehicleId
          // ).iconTextOrder;

          const item = { vehicleId: drive.vehicleId, iconTextOrder: order };
          vehicleMapTextOrders.push(item);
        }

        // console.log(`vehicleMapTextOrders:${JSON.stringify(vehicleMapTextOrders, null, "\t")}`);
        return vehicleMapTextOrders;
      } finally {
        console.log(`[END] getVehicleMapTextOrders`);
      }
    },

    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },

    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      // 既に処理中の場合は処理を抜ける
      if (this.LoadingFlag) return null;

      // 処理中にする
      this.LoadingFlag = true;

      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    hideLoader(loader) {
      if (loader != null) {
        loader.hide();
      }

      // 処理中終了
      this.LoadingFlag = false;
    },

    /**
     * 地図更新間隔設定モーダル表示
     * @returns 地図更新間隔（秒）
     */
    getMapInterval() {
      // ローカルストレージから取得
      let value = localStorage.getItem(this.$store.state.user.cognitoSub);

      if (!value) {
        value = 60;
      }

      return value;
    },

    /**
     * 日付選択で選択不可の日を指定します。
     */
    disabledDate(time) {
      // 当日以降の日付は選択不可とする
      return time.getTime() > Date.now();
    },

    /**
     * 運行の更新情報文字列を取得します（ログ出力用）
     * @param {Object} driveRow 更新する運行情報
     * @returns 運行情報文字列
     */
    async getUpdateDriveInfo(driveRow) {
      //console.log(`【Edit】${JSON.stringify(driveRow, null, "\t")}`)
      return `【Edit】${JSON.stringify(driveRow, null, "\t")}`;
    },

    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },

    /**
     * 指定された日付("YYYY/MM/DD")が本日かどうかを返します。
     * @param {String} dateString 日付("YYYY/MM/DD")
     * @returns 本日の場合はtrue、さもなくばfalse
     */
    isToday(dateString) {
      return dateString == DateUtil.dateStringBase(new Date(), "YYYY/MM/DD");
    },
  },
};
