/**
 * 全ビューで使用する共通処理
 */
import AuthUtil from "@/appUtils/AuthUtil"
import UserInfo from "@/appUtils/UserInfo";

export default {
  data() {
    return {

    }
  },
  created() {
    console.log(`mixin created`)
    // テナント有効期限、ユーザーのログインチェック
    this.validateSession().then(() => {

    })
  },
  methods: {
    /**
     * 各画面から呼び出されるメソッド。
     */
    async validateSession() {
      try {
        console.log(`セッションチェック`)
        // ログインセッションチェック
        let userInfo = await this.getUserInfo()

        if (!await AuthUtil.isValidSession(userInfo.group)) {
          // メッセージ表示
          AuthUtil.alert()

          // ログイン画面に戻る
          this.$router.push({
            name: 'Signin'
          })
          return false
        }
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async getUserInfo() {
      if (!this.currentUserInfo.sub) {
        console.log(`Cognitoから取得`);
        const userInfo = await UserInfo.getUserInfo();
        this.currentUserInfo = userInfo;
      }
      return this.currentUserInfo;
    },
  }
};
