const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * 運行間隔設定のデータ取得モデルクラス
 */
class IntervalSettingModel extends baseModel {
  /**
   * テナント内の運行間隔設定リストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getIntervalSettingList(projectId) {
    return await super.getList({ beginsWith: `intervalsetting#${projectId}` })
  }
  /**
   * 運行間隔設定を削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteIntervalSetting(data) {
    await super.deleteData(data)
  }
  /**
   * 運行間隔設定レコードを追加する
   * @param {*} data
   */
  static async addIntervalSetting(data) {
    await super.addData(data)
  }
  /**
   * 運行間隔設定レコードを更新する
   * @param {*} data
   */
  static async updateIntervalSetting(data) {
    await super.updateData(data)
  }
  /**
   * 運行間隔設定レコードを取得する
   * @param {*} data
   */
  static async getIntervalSetting(data) {
    return await super.getSingleData(data);
  }
  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId) {
    // IntervalSetting#プロジェクトID
    return `intervalsetting#${projectId}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    newRowData.sk = this.createSk(projectId)
    
    newRowData.intervalsetting = {
      delayTime: 0,
      dwellTime: 0,
    }
    return newRowData
  }
}

module.exports = IntervalSettingModel;