/**
 * SignageInfo.vue, MobileSignageInfo.vueの共通処理
 */
import appLog from "@/appUtils/AppLog"
import DateUtil from "@/appUtils/DateUtil"
import BaseAreaModel from "@/appModel/BaseArea/BaseAreaModel";
import PlanModel from "@/appModel/Plan/PlanModel";
import VolumeModel from "@/appModel/Volume/VolumeModel";
import DriveModel from "@/appModel/Drive/DriveModel";
import { useToast } from "vue-toastification";
import Decimal from 'decimal.js';

export default {
  data() {
    return { 
      allDumpSiteList: [],
      signageAllVolumlist: [],
    };
  },

  created() {
    this.hello();
  },

  methods: {
    hello() {
      console.log(`MixinSignage!: ${this.vuename}`);
    },

    /**
     * 予定取得
     */
    async getSignagePlan() {
      let targetYmd = this.targetDate.replaceAll("/", "");
      this.signagePlanList = await PlanModel.getPlanListFromYMD(
        this.projectId,
        targetYmd
      );
      if (this.signagePlanList.length == 0) {
        return;
      }
      // 指定日でplanレコードは1件だけ
      this.signagePlanId = this.signagePlanList[0].plan.id;
      this.debugLog(`getSignagePlan`, `${this.signagePlanId}`);
    
    },  

    /**
     * 工区の一覧取得し、セレクトボックスに値セット
     */  
    async getSignageAreaList(){
      const lists = await BaseAreaModel.getBaseAreaList(this.projectId)

      lists.forEach(function(list){
        let item = {
          id: list.baseArea.id, 
          name: list.baseArea.areaName
        }
        this.signageListArea.push(item)
      }.bind(this));
      // console.log(`getSignageAreaList ${JSON.stringify(this.listArea)}`)
    },
    
    /**
     * 予定数量取得
     */  
    async getSignageVolumeList() {

      // プロジェクトID、予定IDを指定して取得
      if (this.signagePlanId == "") {
        // 指定日時での予定が無いため終了
        //return
        this.signageVolumlist = [];
      } else {
        let searchKey = `${this.projectId}#${this.signagePlanId}`
        let listVolum = await VolumeModel.getVolumeList(searchKey); 
        // console.log(`getVolumeList listVolum ${JSON.stringify(listVolum)}`)
        // 到着拠点IDを名称に変更
        listVolum.forEach(Item => {
          for (let i=0; i<this.signageListArea.length; i++)
          {
            // console.log(`getVolumeList find ${this.signageListArea[i].id} ${Item.volume.endAreaId}`)
            if (this.signageListArea[i].id == Item.volume.endAreaId)
            {
              Item.volume.endAreaName = this.signageListArea[i].name
              break 
            }
          }
        });
  
        // ソートして一覧に表示（工区名称でソート）
        this.signageVolumlist = listVolum.sort(function(a, b){
          return (a.volume.endAreaName < b.volume.endAreaName) ? -1: 1;
        })   
      }
   
      // 全ての予定を取得する
      let searchKey = `${this.projectId}`
      let listVolum = await VolumeModel.getVolumeList(searchKey); 

      // 到着拠点IDを名称に変更
      listVolum.forEach(Item => {
        for (let i=0; i<this.signageListArea.length; i++)
        {
          // console.log(`getVolumeList find ${this.signageListArea[i].id} ${Item.volume.endAreaId}`)
          if (this.signageListArea[i].id == Item.volume.endAreaId)
          {
            Item.volume.endAreaName = this.signageListArea[i].name
            break 
          }
        }
      });

      // ソートして一覧に表示（工区名称でソート）
      this.signageAllVolumlist = listVolum.sort(function(a, b){
        return (a.volume.endAreaName < b.volume.endAreaName) ? -1: 1;
      }) 
      
    },    

    /**
     * 予定数量取得（対象プロジェクト内全ての予定取得し、到着拠点ID、土捨場名称、岩種名の一覧作成）
     */  
    async getAllVolumeList() {

      // プロジェクト単位で予定数量取得
      const searchKey = `${this.projectId}`
      let listVolum = await VolumeModel.getVolumeList(searchKey); 

      // 全て以外は、土捨場で絞り込む
      if (this.endAreaId != "") {
        listVolum = listVolum.filter((item) => item.volume.endAreaId === this.endAreaId);
      }

      // 到着拠点ID、岩種名のリストを作成
      let endAreaList = [];
      if (listVolum.length > 0){
        // 最初のレコード（到着拠点ID、岩種名）をリストに追加
        let item = { 
          endAreaId: listVolum[0].volume.endAreaId,
          rockName: listVolum[0].volume.pouringPosition,
          graphWorkValues: [],
          graphDispValues: []
        };
        endAreaList.push(item);

        // 2レコード目から、到着拠点ID、岩種名の組合せで重複する分は除いてリストを作成
        for (let i=1; i<listVolum.length; i++){
          // 到着拠点ID、岩種名を取得
          let endAreaId = listVolum[i].volume.endAreaId;
          let rockName = listVolum[i].volume.pouringPosition;

          // 取得した到着拠点ID、岩種名が既に登録されていないかチェック
          let volume = endAreaList.filter((item) => item.endAreaId === endAreaId && item.rockName === rockName);
          if (volume.length == 0) {
            // 登録されていない場合はリストに追加
            endAreaList.push({endAreaId: endAreaId, rockName: rockName, graphWorkValues: [], graphDispValues: []});
          }
        }
      }

      // 到着拠点IDを名称（土捨場名称）に変更してリストに追加
      endAreaList.forEach(Item => {
        for (let i=0; i<this.signageListArea.length; i++)
        {
          if (this.signageListArea[i].id == Item.endAreaId)
          {
            Item.endAreaName = this.signageListArea[i].name
            break 
          }
        }
      });

      // 作成したリストセット（土捨場名称、岩種名称でソート）
      this.allDumpSiteList = endAreaList.sort(function(a, b){
        if (a.endAreaName < b.endAreaName) return -1;
        if (a.endAreaName > b.endAreaName) return 1;

        if (a.rockName < b.rockName) return -1;
        if (a.rockName > b.rockName) return 1;
        return 0;
      }) ;

    },

    /**
     * 打設箇所一覧取得
     */
    async makePouringList() {
      this.pouringList = []
      // リスト選択用に値と表示名の単純なリストを作成する
      this.signageVolumlist.forEach(function(item){
        this.pouringList.push({"id": item.volume.id, "name": `${item.volume.endAreaName} ${item.volume.pouringPosition}`})
      }.bind(this))
        
      if (this.pouringList.length == 0) {
        this.pouringList.push({"id": '', "name": ``, "endAreaId": ``, "pouringPosition": ``});
        // this.showBottomToast("予定が登録されていません。", "info")
      }
    },

    /**
     * 土捨場名リスト取得
     */
    async makeDumpSiteList() {
      this.dumpSiteList = [];
      if (this.signageAllVolumlist.length > 0) {
        // 全て
        this.dumpSiteList.push({"id": "", "name": `全て`})
        
        // 土捨場名リスト作成
        for (let i=0; i<this.signageAllVolumlist.length; i++){
          let dumpSite = this.dumpSiteList.find((item)=>item.id === this.signageAllVolumlist[i].volume.endAreaId);
          if (!dumpSite) {
            this.dumpSiteList.push({"id": this.signageAllVolumlist[i].volume.endAreaId, "name": `${this.signageAllVolumlist[i].volume.endAreaName}`});
          }
        }

      } else {
        this.dumpSiteList.push({"id": -1, "name": `全て`})
      }
    },

    /**
     * 表示内容の更新
     */
    async updateUI() {
      this.infoLog(`updateUI`, `projectId: ${this.projectId} userid: ${this.userId} targetDate: ${this.targetDate}`)

      this.debugLog(`updateUI`, `${this.volumeId}`)
      // 現在時刻を更新
      this.timeString = DateUtil.dateStringBase(new Date(), 'HH:mm')

      // 打設予定を取得 ------------------------
      // 選択した打設箇所に紐づく予定数量を取得。 
      let volume = this.signageVolumlist.filter(function(item) {
        return item.volume.id == this.volumeId
      }.bind(this))

      let baseAreaId = ""
      let estimate = new Decimal(0)
      if (volume.length > 0) {
        // 打設予定
        estimate = new Decimal(volume[0].volume.totalVolume)
        this.estimate.volume = estimate.toNumber().toFixed(2);
        baseAreaId = volume[0].volume.endAreaId
      } else {
        return;
      }
      let targetYmd = this.targetDate.replaceAll("/", "")
      // 指定プロジェクト、日付、拠点でDBから運行情報(drive#)取得する。コンクリートの運搬のみ対象
      // その後、打設箇所でフィルターする
      let currentDrives = (await DriveModel.getDriveListForSignage(this.projectId, targetYmd, baseAreaId)).filter(function(item) {
        return item.drive.pouringPosition == volume[0].volume.pouringPosition && item.drive.carryType == "1"
      })

      // ------------------------------
      // 打設完了 設定
      // ------------------------------
      // 打設が終了したレコードを抽出
      let calcVolume = new Decimal(0.0).toNumber()
      let calcNumber = 0
      const pouringEndList = currentDrives.filter(function(item) {
        return item.drive.pouringEndTime != null && item.drive.pouringEndTime != ''
      }.bind(this))

      // 抽出したレコードから最大積載量と台数を計算
      pouringEndList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
      })

      // 表示用変数に値を設定
      this.pouringEnd.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.pouringEnd.number = calcNumber

      // ------------------------------
      // 打設/待機 設定
      // ------------------------------
      // 現場入場もしくは打設中レコードを抽出
      calcVolume = new Decimal(0.0).toNumber()
      calcNumber = 0
      let lastStartTime = '-'
      const pouringOrWaitingList = currentDrives
        .filter(function(item) {
          return ((item.drive.siteEndTime && !item.drive.pouringStartTime && !item.drive.pouringEndTime)
            || (item.drive.siteEndTime && item.drive.pouringStartTime && !item.drive.pouringEndTime))
        }.bind(this))
        .slice()
        .sort(function(a, b) {
          if (a.drive.pouringStartTime < b.drive.pouringStartTime) return -1;
          if (a.drive.pouringStartTime > b.drive.pouringStartTime) return 1;
        })

      // 抽出したレコードから最大積載量と台数を計算
      pouringOrWaitingList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
        if (element.drive.pouringStartTime) {
          lastStartTime = DateUtil.getFormatString(element.drive.pouringStartTime, "HH:mm")
        }
      });

      // 表示用変数に値を設定
      this.pouringOrWaiting.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.pouringOrWaiting.number = calcNumber
      this.pouringOrWaiting.lastTime = lastStartTime

      // ------------------------------
      // 運搬中 設定
      // ------------------------------
      // 運搬中のレコードを抽出
      calcVolume = new Decimal(0.0).toNumber()
      calcNumber = 0
      const transportingList = currentDrives.filter(function(item) {
        return item.drive.factoryStartTime && !item.drive.siteEndTime && !item.drive.pouringStartTime && !item.drive.pouringEndTime
      }.bind(this))

      // 抽出したレコードから最大積載量と台数を計算
      transportingList.forEach(element => {
        calcVolume += element.drive.maxCapacity
        calcNumber++
      })

      // 表示用変数に値を設定
      this.transporting.volume = new Decimal(calcVolume).toNumber().toFixed(2)
      this.transporting.number = calcNumber

      // ------------------------------
      // 実績 設定
      // ------------------------------
      //残量の計算。打設完了時刻のあるレコードを積算、予定量との差を出す--------------
      let transportEndDrives = currentDrives.filter(function(item) {
        this.debugLog(`updateUI`, `残量計算 ${JSON.stringify(item, null, "\t")}`)
        return item.drive.pouringPosition == volume[0].volume.pouringPosition && 
        (item.drive.pouringEndTime != "" && item.drive.pouringEndTime != null)
      }.bind(this))
      this.debugLog(`updateUI`, `運搬完了: ${transportEndDrives.length}`)
    
      let transportEndVolume = new Decimal(0);
      transportEndDrives.forEach(function(item) {
      
        let _volume = new Decimal(item.drive.maxCapacity)
        transportEndVolume = transportEndVolume.plus(_volume)
      })

      // 打設量実績----------------------------
      this.achive.volume = transportEndVolume.toNumber().toFixed(2);
      // 打設台数
      this.achive.number = transportEndDrives.length

      // ------------------------------
      // 残出荷数  設定
      // ------------------------------
      const remainList = currentDrives.slice().sort(function(a, b) {
        if (a.drive.maxCapacity > b.drive.maxCapacity) return -1;
        if (a.drive.maxCapacity < b.drive.maxCapacity) return 1;
      })
      let maxCapacity = 0.0
      if (remainList != null && remainList.length > 0) {
        maxCapacity = remainList[0].drive.maxCapacity
      }
      const remainVolume = this.estimate.volume - this.pouringEnd.volume - this.pouringOrWaiting.volume - this.transporting.volume 
      const remainNumber = maxCapacity == 0 ? 0 : Math.ceil(remainVolume / maxCapacity);
      this.remain.volume = new Decimal(remainVolume).toNumber().toFixed(2)
      this.remain.number = remainNumber < 0 ? 0: remainNumber;

      // 平均到着時間、平均帰着時間を算出----------------
      let arriveDiffList = []
      let returnDiffList = []

      currentDrives.forEach(function(item){
        if ((item.drive.factoryStartTime != "" && item.drive.factoryStartTime != null) &&
         (item.drive.siteEndTime != "" && item.drive.siteEndTime != null)) {
          // 工場出発時刻と現場到着時刻の差分を取得する
          arriveDiffList.push(DateUtil.diff(item.drive.siteEndTime, item.drive.factoryStartTime, "m", false))
        }

        if ((item.drive.siteStartTime != "" && item.drive.siteStartTime != null) &&
         (item.drive.factoryEndTime != "" && item.drive.factoryEndTime != null)) {
          // 工場出発時刻と現場到着時刻の差分を取得する
          returnDiffList.push(DateUtil.diff(item.drive.factoryEndTime, item.drive.siteStartTime, "m", false))
        }
      })
    
      // 平均到着時間を算出
      if (arriveDiffList.length > 0) {
        this.average.arrive = Math.round((arriveDiffList.reduce((sum, value) => { return sum + value })) / arriveDiffList.length)
      } else {
        this.average.arrive = 0
      }
    
      // 平均帰着時間を算出
      if (returnDiffList.length > 0) {
        this.average.return = Math.round((returnDiffList.reduce((sum, value) => { return sum + value })) / returnDiffList.length)
      } else {
        this.average.return = 0
      }
    },

    /**
     * 表示内容の更新(残土用)
     */    
    async updateZandoUI() {

      // 現在時刻を更新
      this.timeString = DateUtil.dateStringBase(new Date(), 'HH:mm');

      // 予定がない場合は処理しない
      // if (this.zandoVolumeList.length < 1) return;

      // ----------------------------
      // 総搬出量を取得
      // ----------------------------
      // 該当プロジェクト内の運行情報取得
      let targetDriveList = await DriveModel.getDriveList(`${this.projectId}`);

      // 該当プロジェクト内の全予定の土捨場・岩種名の組合せ毎で搬出量を算出
      let calcDate = this.targetDate.replaceAll("/", "");
      this.amountAll = 0;
      this.zandoVolumeList = [];
      for (let i=0; i<this.allDumpSiteList.length; i++) {
        // 到着拠点ID、岩種名取得
        let endAreaId = this.allDumpSiteList[i].endAreaId;
        let rockName = this.allDumpSiteList[i].rockName;

        // 運行情報から、到着拠点ID（土捨場）、岩種名が一致するレコード取得
        let driveList = targetDriveList.filter((item) => 
          item.drive.endAreaId === endAreaId && 
          item.drive.pouringPosition === rockName && 
          item.drive.pouringEndTime != null && item.drive.pouringEndTime != '');
       
        // 取得したレコードの積載量の和を算出
        let totalLoadCapacity = 0;
        for (let j=0; j<driveList.length; j++) {
          // 選択日含め過去の日付で集計
          let date = driveList[j].sk.split("#")[2];
          if (calcDate >= date){
            totalLoadCapacity = totalLoadCapacity + driveList[j].drive.loadCapacity;
          }
        }

        this.zandoVolumeList.push({
          endAreaId: this.allDumpSiteList[i].endAreaName, 
          rockName: rockName,
          totalLoadCapacity: totalLoadCapacity })
        
        this.amountAll = this.amountAll + totalLoadCapacity
        // console.log(`▲▲総搬出量：${this.allDumpSiteList[i].endAreaName} ${rockName} ：${totalLoadCapacity}`);
      }
      
      // --------------------------------------------------------
      // 予定量(当日)を取得
      // --------------------------------------------------------
      // 当日予定数量取得
      let targetVolumeList = [];
      if (this.endAreaId == "") {
        // 全て
        targetVolumeList = this.signageVolumlist;

      } else {
        // 到着拠点（土捨場）が一致する予定数量取得
        targetVolumeList = this.signageVolumlist.filter(function(item) {
          if (this.endAreaId != null && this.pouringPosition != null){
            return item.volume.endAreaId == this.endAreaId && item.volume.pouringPosition == this.pouringPosition
          } else {
            return item.volume.endAreaId == this.endAreaId
          }
        }.bind(this));

      }
      //console.log(`▲▲到着拠点情報：${this.endAreaId} ${JSON.stringify(targetVolumeList)}`)

      // 取得した予定数量の出荷総量（㎥）の和を算出
      let dateTotalVolume = 0;
      for (let i=0; i<targetVolumeList.length; i++) {
        dateTotalVolume = dateTotalVolume + targetVolumeList[i].volume.totalVolume;
      }
      this.datePlanTotalVolume = dateTotalVolume;

      //予定量があるかどうかのチェック
      if (this.datePlanTotalVolume !== 0 && this.datePlanTotalVolume !== "" && this.datePlanTotalVolume !== null){
        this.isDatePlanTotalVolume = true;
      } else {
        // なければ排出量0にし、予定・残台数は非表示にする
        this.dateAllAmount = 0;
        this.isUnitNumber = false;
        this.isDatePlanTotalVolume = false;
      }

      // --------------------------------------------------------
      // 搬出量(当日) 、残必要台数（当日）を取得
      // --------------------------------------------------------
      // 運行情報から当日の運行情報取得（積込完了分取得）
      let dateDriveList = targetDriveList.filter(function(item) {
        return item.sk.indexOf(`drive#${this.projectId}#${this.targetDate.replaceAll("/", "")}`) !== -1 && item.drive.pouringEndTime != null && item.drive.pouringEndTime != ''
      }.bind(this));

      // 土捨場、岩種名毎に、予定数量・搬出量・1台あたりの積載量（単位：㎤）のリストを作成
      let calcValues = [];
      for (let i=0; i<targetVolumeList.length; i++) {
        let endAreaId = targetVolumeList[i].volume.endAreaId;
        let rockName = targetVolumeList[i].volume.pouringPosition;
        let totalVolume = targetVolumeList[i].volume.totalVolume;
        let loadCapacity = targetVolumeList[i].volume.loadCapacity;

        let driveList = dateDriveList.filter((item) => 
          item.drive.endAreaId === endAreaId && 
          item.drive.pouringPosition === rockName && 
          item.drive.pouringEndTime != null && item.drive.pouringEndTime != '') ;
       
        let driveTotalLoadCapacity = 0;
        for (let j=0; j<driveList.length; j++) {
          driveTotalLoadCapacity = driveTotalLoadCapacity + driveList[j].drive.loadCapacity;
        }
        // リストに追加
        calcValues.push({totalVolume: totalVolume, loadCapacity: loadCapacity, totalLoadCapacity: driveTotalLoadCapacity});
      }

      // 搬出量、残必要台数算出
      let dateTotalLoadCapacity = 0;
      let dateRemainCnt = 0;

      for (let i=0; i<calcValues.length; i++)
      {
        // 搬出量加算
        dateTotalLoadCapacity = dateTotalLoadCapacity + calcValues[i].totalLoadCapacity;

        // 残必要台数
        // 1:予定数量 - 搬出量
        let remainVolume = calcValues[i].totalVolume - calcValues[i].totalLoadCapacity;
        // 2:1で算出した値 / 1台あたりの積載量
        let remainCnt = 0;
        if (calcValues[i].loadCapacity > 0) {
          remainCnt = Math.ceil(remainVolume / calcValues[i].loadCapacity);
        } 
        
        // 残必要台数加算
        if (remainCnt < 0) remainCnt= 0;
        dateRemainCnt = dateRemainCnt + remainCnt;

        this.dateAllAmount = dateTotalLoadCapacity;
        this.unitNumber = dateRemainCnt;

        //残必要台数があるかどうかのチェック
        let isUnitNumber = false;
        if (this.unitNumber !== 0 && this.unitNumber !== "" && this.unitNumber !== null){
          isUnitNumber = true;
        }
        this.isUnitNumber = isUnitNumber;
      }
      //残量の算出（予定量-搬出量）
      if (this.dateAllAmount) {
        try {
          this.dateAllAmount = Number(this.dateAllAmount).toFixed(1);
        } catch (e) {
          this.dateAllAmount = 0
        }
      }
      if (this.amountAll) {
        try {
          this.amountAll = Number(this.amountAll).toFixed(1);
        } catch (e) {
          this.amountAll = 0
        }
      }
      this.restDateTotalLoadCapacity = this.datePlanTotalVolume - this.dateAllAmount

      // --------------------------------------------------------
      // 平均到着時間、平均帰着時間を算出
      // --------------------------------------------------------
      // 土捨場単位で集計
      this.averageAllList = [];
      this.dumpSiteAverageList = [];
      const targetDriveSk = `drive#${this.projectId}#${this.targetDate.replaceAll("/", "")}`
      console.log(`updateZandoUI targetDriveSk:${targetDriveSk}`)
      for (let i=0; i<this.allDumpSiteList.length; i++) {
      
        // 到着拠点ID取得
        let endAreaId = this.allDumpSiteList[i].endAreaId;        

        // 処理済みかチェック（土捨場単位で集計）
        let checkList = this.averageAllList.filter((item) => 
          item.endAreaId === endAreaId
        );                
        if (checkList.length > 0) continue;

        // 運行情報から、到着拠点ID（土捨場）、岩種名が一致するレコード取得
        // かつ、選択日の運行を対象とする#18365
        let driveList = targetDriveList.filter((item) => 
          item.sk.indexOf(targetDriveSk) >= 0 &&
          item.drive.endAreaId === endAreaId && 
          item.drive.pouringEndTime != null && item.drive.pouringEndTime != '');        

        let arriveDiffList = []
        let returnDiffList = []
        driveList.forEach(function(item){
          if ((item.drive.factoryStartTime != "" && item.drive.factoryStartTime != null) &&
            (item.drive.siteEndTime != "" && item.drive.siteEndTime != null)) {
            // 工場出発時刻と現場到着時刻の差分を取得する
            arriveDiffList.push(DateUtil.diff(item.drive.siteEndTime, item.drive.factoryStartTime, "m", false))
          }

          if ((item.drive.siteStartTime != "" && item.drive.siteStartTime != null) &&
            (item.drive.factoryEndTime != "" && item.drive.factoryEndTime != null)) {
            // 工場出発時刻と現場到着時刻の差分を取得する
            returnDiffList.push(DateUtil.diff(item.drive.factoryEndTime, item.drive.siteStartTime, "m", false))
          }
        })

        // 平均到着時間を算出
        let averageArrive = 0;
        if (arriveDiffList.length > 0) {
          averageArrive = Math.round((arriveDiffList.reduce((sum, value) => { return sum + value })) / arriveDiffList.length)
        }

        // 平均帰着時間を算出
        let averageReturn = 0;
        if (returnDiffList.length > 0) {
          averageReturn = Math.round((returnDiffList.reduce((sum, value) => { return sum + value })) / returnDiffList.length)
        }

        // 算出した土捨場単位での平均時間をプッシュ
        let item1 = {
          endAreaId: this.allDumpSiteList[i].endAreaId, 
          endAreaName: this.allDumpSiteList[i].endAreaName, 
          title: "平均到着時間",
          averageArrive: averageArrive
        }
        let item2 = {
          endAreaId: this.allDumpSiteList[i].endAreaId, 
          endAreaName: "", 
          title: "平均帰着時間",
          averageReturn: averageReturn
        }

        // 計算結果保持
        this.averageAllList.push(item1);
        this.averageAllList.push(item2);

        // セレクトボックス情報セット
        this.dumpSiteAverageList.push({"id": this.allDumpSiteList[i].endAreaId, "name": `${this.allDumpSiteList[i].endAreaName}`});
      }

      // セレクトボックスの値が複数ある場合は最初の行をセット
      if (this.dumpSiteAverageList.length > 0) {
        this.dumpSiteAverage = this.dumpSiteAverageList[0].id;
      }

      // --------------------------------------------------------
      // 折れ線グラフ描画処理
      // --------------------------------------------------------
      let beforeDate = ``;
      let graphLabels = [];
      for (let i=0; i<targetDriveList.length; i++){
        // 積込完了を対象
        if (!targetDriveList[i].drive.pouringEndTime) {
          console.log(`ない ${JSON.stringify(targetDriveList[i].drive)}`)
          // 積込完了でなければスキップ
          continue
        }
        // sk より 日付を取得
        let curDate = targetDriveList[i].sk.split("#")[2];

        if (beforeDate != curDate){
          // X軸ラベル用に日付セット
          graphLabels.push(`${curDate.substr(4, 2)}/${curDate.substr(6, 2)}`);
          beforeDate = curDate;

          // 対象日付の、土捨場・岩種毎に集計
          for (let j=0; j<this.allDumpSiteList.length; j++) {
            if (i==0) {
              this.allDumpSiteList[j].graphWorkValues = [];
            }

            // 到着拠点ID、岩種名取得
            let endAreaId = this.allDumpSiteList[j].endAreaId;
            let rockName = this.allDumpSiteList[j].rockName;

            // 運行情報から、到着拠点ID（土捨場）、岩種名 + 日付が一致するレコード取得
            let driveList = targetDriveList.filter((item) => 
              item.drive.pouringEndTime && 
              item.drive.endAreaId === endAreaId && 
              item.drive.pouringPosition === rockName && 
              item.sk.indexOf(`drive#${this.projectId}#${curDate}`) !== -1);
          
            // 取得したレコードの積載量の和を算出
            let totalLoadCapacity = 0;
            for (let k=0; k<driveList.length; k++) {
              totalLoadCapacity = totalLoadCapacity + driveList[k].drive.loadCapacity;
            }

            // 算出した積載量を搬出量として登録
            this.allDumpSiteList[j].graphWorkValues.push(totalLoadCapacity);
          }
        }
      }

      // 日付、土捨場、岩種毎に集計した値を、積上げ折れ線グラフ用にデータを整形
      for (let i=0; i<this.allDumpSiteList.length; i++){
        let sumValue = 0;
        for (let j=0; j<this.allDumpSiteList[i].graphWorkValues.length; j++){
          // 前の日のレコードの搬出量に対象日付の搬出量を加算
          sumValue = sumValue + this.allDumpSiteList[i].graphWorkValues[j];
          this.allDumpSiteList[i].graphDispValues.push(sumValue);
        }
      }
      
      // 積上げ折れ線グラフ用データセット作成
      let graphDatasets = [];
      for (let i=0; i<this.allDumpSiteList.length; i++){
        // 色取得
        let retValue = await this.getGraphColor(i);
        // 積上げ折れ線グラフ用
        graphDatasets.push({
          label: `${this.allDumpSiteList[i].endAreaName} ${this.allDumpSiteList[i].rockName}`,
          data: this.allDumpSiteList[i].graphDispValues,
          borderColor: retValue.borderColor,
          backgroundColor: retValue.backgroundColor,
          borderWidth: 0.5,
          lineTension: 0,            
          stack: 1, 
          fill: i==0 ? 'origin' : '-1'
        })
      }
      if (graphLabels.length == 1) {
      
        let yesterday = DateUtil.addDay(DateUtil.stringToDate(beforeDate, "YYYYMMDD"), -1)
        graphLabels.unshift(DateUtil.dateStringBase(yesterday, "MM/DD"));
        for (let i = 0; i < graphDatasets.length;i++) {
          graphDatasets[i].data.unshift(0)
        }
        
      }
      // グラフ更新
      this.signagechartLineData = {
        labels: graphLabels,
        datasets: graphDatasets
      }
      
    },    

    /**
     * 積上げ折れ線グラフ色取得(残土用)
     */   
    async getGraphColor(index) {

      // 戻り値用変数初期化
      let retValue = {
        borderColor: 'rgba(192, 0, 0, 1)',
        backgroundColor: 'rgba(192, 0, 0, 0.5)',
      }

      // 10以上の場合は10で割った値の余りを採用
      if (index > 9) {
        index = index % 10;
      }

      // Index毎に色を決定
      switch (index){
        case 0:
          retValue = {
            borderColor: 'rgba(255, 0, 0, 1)',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
          };
          break;
        case 1:
          retValue = {
            borderColor: 'rgba(192, 0, 0, 1)',
            backgroundColor: 'rgba(192, 0, 0, 0.5)',
          };
          break;
        case 2:
          retValue = {
            borderColor: 'rgba(255, 192, 0, 1)',
            backgroundColor: 'rgba(255, 192, 0, 0.5)',
          };
          break;

        case 3:
          retValue = {
            borderColor: 'rgba(255, 255, 0, 1)',
            backgroundColor: 'rgba(255, 255, 0, 0.5)',
          };
          break;          
        case 4:
          retValue = {
            borderColor: 'rgba(146, 208, 80, 1)',
            backgroundColor: 'rgba(146, 208, 80, 0.5)',
          };
          break;          
        case 5:
          retValue = {
            borderColor: 'rgba(0, 176, 80, 1)',
            backgroundColor: 'rgba(0, 176, 80, 0.5)',
          };
          break;          
        case 6:
          retValue = {
            borderColor: 'rgba(0, 176, 240, 1)',
            backgroundColor: 'rgba(0, 176, 240, 0.5)',
          };
          break;          
        case 7:
          retValue = {
            borderColor: 'rgba(0, 112, 192, 1)',
            backgroundColor: 'rgba(0, 112, 192, 0.5)',
          };
          break;          
        case 8:
          retValue = {
            borderColor: 'rgba(0, 32, 96, 1)',
            backgroundColor: 'rgba(0, 32, 96, 0.5)',
          };
          break;          
        case 9:
          retValue = {
            borderColor: 'rgba(112, 48, 160, 1)',
            backgroundColor: 'rgba(112, 48, 160, 0.5)',
          };
          break;          
        default:
          retValue = {
            borderColor: 'rgba(255, 0, 0, 1)',
            backgroundColor: 'rgba(255, 0, 0, 0.5)',
          }
      } 
      return retValue;    

    },

    /**
     * リロード処理
     */
    async reload() {
      // 処理中インジケーター表示
      let loader = this.showLoader();
      try {
        await this.getSignagePlan();
        await this.getSignageAreaList();
        await this.getSignageVolumeList();

        await this.makePouringList();
        await this.updateUI();

        if (this.isZando) {
          await this.getAllVolumeList();
          await this.makeDumpSiteList();
          await this.updateZandoUI();          
        }

      } catch (e) {
        this.errorLog(`reload`, `${e.message}`);
      } finally {
        this.hideLoader(loader);
      }
    },

    async getDrive() {},
    
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, "bottom-center", type);
    },

    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {
      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos,
      });
    },

    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
      
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      });
    },

    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */      
    hideLoader(loader) {
      loader.hide();
    },   

  },

};