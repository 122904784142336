const short = require("short-uuid");
const baseModel = require("../baseModel")

/**
 * CO2排出関係設定値のデータ取得モデルクラス
 */
class CO2SettingModel extends baseModel {

  /**
   * CO2排出関係設定値レコードを取得する
   */
  static async getCO2Setting() {
    return await super.getSingleData({sk: `co2setting`});
  }

  /**
   * CO2排出関係設定値レコードをプロジェクトIDを取得する
   * @param {*} projectId
   */
  static async getCO2SettingbyProjectId(projectId) {
    return await super.getSingleData({sk: `co2setting#${projectId}`});
  }

  /**
   * CO2排出関係設定値レコードを追加する
   * @param {*} data
   */
  static async addCO2Setting(data) {
    await super.addData(data);
  }

  /**
   * CO2排出関係設定値レコードを更新する
   * @param {*} data
   */
  static async updateCO2Setting(data) {
    await super.updateData(data);
  }

  /**
   * CO2排出関係設定値レコードを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteCO2Setting(data) {
    await super.deleteData(data);
  }

  /**
   * 新規登録用のスキーマ（共通用）
   */
  static async getNewData() {
    let newRowData = await super.getNewData()
    
    newRowData.sk = `co2setting`;    
    newRowData.co2setting = {
      speedThreshold: 60,
      suddenStartValue: 20,
      suddenStopValue: 20,
      ecoDriveParam: 1.5,
      AClassCount: 1,
      BClassCount: 3,
      CClassCount: 10,
      AClassFuelParam: 1.2,
      BClassFuelParam: 1.0,
      CClassFuelParam: 0.8,
      fuelMileage1: 4.58,
      fuelMileage2: 3.79,
      fuelMileage3: 3.38,
      fuelMileage4: 3.09,
      fuelMileage5: 2.89,
      ArankFuelMemo: "",
      BrankFuelMemo: "",
      CrankFuelMemo: "",
      fuelMileage1Memo: "",
      fuelMileage2Memo: "",
      fuelMileage3Memo: "",
      fuelMileage4Memo: "",
      fuelMileage5Memo: "",
    }
    return newRowData
  }

  /**
   * 新規登録用のスキーマ（プロジェクト毎用）
   * @param {*} projectId
   */
  static async getNewPerProjectData(projectId) {
    let newRowData = await super.getNewData()
    
    newRowData.sk = `co2setting#${projectId}`;
    newRowData.co2setting = {
      speedThreshold: 60,
      suddenStartValue: 20,
      suddenStopValue: 20,
      ecoDriveParam: 1.5,
      AClassCount: 1,
      BClassCount: 3,
      CClassCount: 10,
      AClassFuelParam: 1.2,
      BClassFuelParam: 1.0,
      CClassFuelParam: 0.8,
      fuelMileage1: 4.58,
      fuelMileage2: 3.79,
      fuelMileage3: 3.38,
      fuelMileage4: 3.09,
      fuelMileage5: 2.89,
    }
    return newRowData
  }

}

module.exports = CO2SettingModel;