const short = require("short-uuid");
const DateUtil = require("../../appUtils/DateUtil")
const baseModel = require("../baseModel")

/**
 * スポットタイプのデータ取得モデルクラス
 */
class SpotTypeModel extends baseModel {
  /**
   * テナント内のスポットタイプリストを取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getSpotTypeList(projectId) {
    return await super.getList({ beginsWith: `spottype#${projectId}` })
  }

  /**
   * スポットタイプを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteSpotType(data) {
    await super.deleteData(data)
  }

  /**
   * スポットタイプレコードを追加する
   * @param {*} data
   */
  static async addSpotType(data) {
    await super.addData(data)
  }

  /**
   * スポットタイプレコードを更新する
   * @param {*} data
   */
  static async updateSpotType(data) {
    await super.updateData(data)
  }

  /**
   * スポットタイプレコードを取得する
   * @param {*} data
   */
  static async getSpotType(data) {
    return await super.getSingleData(data);

  }

  /**
   * skの文字列を生成
   * @param {*}
   * @returns
   */
  static createSk(projectId, id) {
    // spot#プロジェクトID#スポットID(uuidで採番)
    return `spottype#${projectId}#${id}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData(projectId) {
    let newRowData = await super.getNewData()
    
    const id = short.generate()
    newRowData.sk = this.createSk(projectId, id)
    
    newRowData.spottype = {
      spottypeId: id,
      name: "",
      voicetext: "",
      iconpath: "",
      spotType: 0,
      order: 0,
    }

    return newRowData
 
  }
}

module.exports = SpotTypeModel;