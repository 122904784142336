const baseModel = require("../baseModel");
const UserInfo = require('../../appUtils/UserInfo');
const AppLog = require("../../appUtils/AppLog")
/**
 * ユーザーデータ取得モデルクラス
 */
class UserModel extends baseModel {
  /**
   * ユーザ一覧を取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getUserList() {
    return await super.getList({ beginsWith: "user" });
  }
  /**
   * 編集可能なユーザー一覧を取得する
   * @param {テナント(グループ)}} tenantId
   * @returns
   */
  static async getEditableUserList() {
    const loginInfo = await UserInfo.getUserInfo()
    // ログインユーザーの情報取得
    const currentUserSk = this.createSk(loginInfo.sub)
    const currentUser = await super.getSingleData({sk: currentUserSk});

    let userList = [];

    // 一般ユーザーは自身のユーザー情報のみ取得
    if (currentUser.user.usertype == "0") {
      userList = await super.getList({ beginsWith: `${currentUserSk}` });
      // return await super.getList({ beginsWith: `${currentUserSk}` });
    } else {
      userList = await super.getList({ beginsWith: "user" });
      // return await super.getList({ beginsWith: "user" });
    }

    return await this.filterDeleteUsers(userList);
  }

  /**
   * 削除ユーザー以外のユーザー一覧を取得します。
   * @param {*} userList ユーザー一覧
   * @returns 削除ユーザー以外のユーザー一覧
   */
  static async filterDeleteUsers(userList) {
    return userList.filter(function (item) {
      //console.log(item.user.deleted);
      // 削除フラグがfalseのユーザーだけを抽出(null判定で項目未定義も許容)
      if (item.user.deleted == null || item.user.deleted == false)
        return true;
    })
  }

  /**
   * ユーザーを削除する
   * @param {pk:テナントID, sk:sk} data
   * @returns
   */
  static async deleteUser(data) {
    await super.deleteData(data);
  }

  /**
   * ユーザーレコードを追加する
   * @param {*} data
   */
  static async addUser(data) {
    await super.addData(data);
  }

  /**
   * ユーザーレコードを更新する
   * @param {*} data
   */
  static async updateUser(data) {
    await super.updateData(data);
  }

  /**
   * ユーザーレコードを取得する
   * @param {*} data
   */
  static async getUser(data) {
    AppLog.debugLog("UserModel", "", `getUser ${JSON.stringify(data)}`)
    // console.log(`getUser ${JSON.stringify(data)}`)
    // const loginInfo = await UserInfo.getUserInfo()
    // // ログインユーザの情報取得
    // const currentUserSk = this.createSk(loginInfo.sub)
    // const currentUser = await super.getSingleData({sk: currentUserSk});

    // // 一般ユーザーであれば、別ユーザーの情報は取得させない
    // if (currentUser.user.usertype == "0" && data.sk != currentUserSk) {
    //   console.log(`ユーザーが異なる ${data.sk}${currentUserSk}`)
    //   return null
    // }

    return await super.getSingleData(data);

  }
  
  /**
   * ユーザーIDからユーザー情報を取得する
   */
  static async getUserFromUserId(pk, userId) {
    
    let condition = {
      pk: pk,
      sk: { beginsWith: "user#" }
    }
    console.log(`condition: ${JSON.stringify(condition)}`)
    let result = await super.getListBase(condition)

    
    // const items = result.data.listKonoikeConcreteData.items;
    for (let i = 0;i < result.length;i++){
      let item = result[i]
      if (item.user.userId == userId) 
      {
        return item
      }
    }
    
    return null
  }
  /**
   * skの文字列を生成
   * @param {*} loginUser
   * @param {*} userId
   * @returns
   */
  static createSk(userid) {
    return `user#${userid}`;
  }
  /**
   * 新規登録用のスキーマ
   */
  static async getNewData() {
    let newRowData = await super.getNewData()

    newRowData.user = {
      userId: "",
      name: "",
      usertype: "0",
      status: "1",
      email: "",
      period: "",
      deleted: false,
      organizationId: ""
    }
    
    return newRowData
  }
}

module.exports = UserModel;
