// Vue.js基本コンポーネント
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Creative TimのUIコンポーネント
import ArgonDashboard from "./plugins/argon-dashboard";

// Element-UIコンポーネント
import ElementPlus from "element-plus";
// import "element-plus/lib/theme-chalk/index.css";
import "element-plus/dist/index.css"
import ja from 'element-plus/lib/locale/lang/ja'

// グローバルに読み込みを行うコンポーネント
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "splitpanes/dist/splitpanes.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// 共通スタイル
import "@/appStyle/common.scss"

// Amplify関係のコンポーネント
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports.js";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
Amplify.configure(awsconfig);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const options = { containerClassName: "ct-notification" };

const appInstance = createApp(App);

appInstance.use(store)
appInstance.use(router)
appInstance.use(ArgonDashboard);
appInstance.use(ElementPlus, {locale: ja})
appInstance.use(Toast, options);
appInstance.use(
  VueLoading,
  {
    container: null,
    canCancel: false,
    color: "#003C9C",
    width: 64,
    height: 64,
    backgroundColor: "#ffffff",
    opacity: 0.5,
    isFullPage: true,
    zIndex: 9999,
  },
  {}
);
appInstance.mount('#app')
// appInstance.config.errorHandler = (err, vm, info) => {
//   // alert(`errorHandler ${err}`)
//   console.log(`Captured in Vue.config.errorHandler: ${info}`, err);
  
// };
// window.addEventListener("error", event => {
//   // alert(`EventListener error: ${event.error}`)
//   console.log("Captured in error EventListener", event.error);

// });
// window.addEventListener("unhandledrejection", event => {
//   // alert(`unhandledrejection: ${event.reason}`)
//   console.log(`main.js store: ${JSON.stringify(store.state.timeline)}`)
//   console.log("Captured in unhandledrejection EventListener", event.reason);
  
// });
