<template>
  <div>
    <div class="card">
      <div class="card-header border-0">
        <div class="row ml-0">
          <table-tool-menu
            class="col-12"
            :title="title"
            :addTitle="addTitle"
            :editTitle="editTitle"
            :isAdd="userAuthority"
            :isEdit="userAuthority"
            :isDelete="false"
            :isCopy="false"
            :isSingleSelect="selectRow"
            :isMultiSelect="false"
            :processing="false"
            :disableMoveDown="false"
            :disableMoveUp="false"
            @addNew="onClickNewProject"
            @edit="onEditProject(selectedData)"
          >
          </table-tool-menu>
        </div>
      </div>

      <div class="project-list">
        <el-table
          ref="projectTable"
          class="table-responsive align-items-center table-flush"
          header-row-class-name="app-th-row"
          select-on-indeterminate="true"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
          @sort-change="runSort"
          :default-sort="{ prop: 'createdAt', order: 'descending' }"
          :data="pagedData"
        >
          <el-table-column type="selection" v-if="userAuthority" align="left" min-width="120px">
          </el-table-column>
          <el-table-column
            label="プロジェクト名"
            min-width="410px"
            prop="project.name"
            sortable="'custom'"
          >
            <template v-slot="{ row }">
              <b>
                <a href="#!" @click.prevent="onClickProject(row)"
                  >{{ row.project.name }}
                </a>
              </b>
            </template>
          </el-table-column>
          <el-table-column label="作成日" min-width="140px" prop="createdAt" sortable="'custom'">
            <template v-slot="{ row }">
              <span>{{ dateStringFormat(row.createdAt) }}</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="row mt-1">
          <div class="col">
            <div class="mt-1 d-flex justify-content-center">
              <el-pagination
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .project-list {
    width: 100%;
    height: 100%;
    flex: 0 0 auto;
  }

  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>

<script>
//import users from "./../users";
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  // ElTooltip,
} from "element-plus";
import ProjectModel from "@/appModel/project/ProjectModel";
import TableToolMenu from "../components/Menu/TableToolMenu"
import UserInfo from "@/appUtils/UserInfo"
import DateUtil from '@/appUtils/DateUtil';
import SortUtil from '@/appUtils/SortUtil';
import projects from '../../views/Tables/projects';
import appLog from "@/appUtils/AppLog"
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import settingModel from "@/appModel/settingModel"
import { useToast } from "vue-toastification";
import Logger from "@/appViews/common/Logger.js";
export default {
  name: "inline-actions-table",
  components: {
    // [ElTooltip.name]: ElTooltip,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    TableToolMenu,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      //users,
      projects: [],
      currentPage: 1,
      selectedData: null,
      title: "プロジェクト一覧",
      addTitle: "プロジェクトを追加",
      editTitle: "プロジェクトを編集",
      userAuthority: false,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）      
      /**
       * 初期ソート（作成日=>降順)
       */
      sortColumn: {
        prop: 'createdAt',
        order: 'descending',
      }        
    };
  },
  /**
   * コンピュートプロパティ
   */
  computed: {
    selectRow() {
      if (this.selectedData) {
        return true;
      } else {
        return false;
      }
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.projects.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.projects.length;
    },
    vuename() {
      return "Projects.vue"
    },
    isZandoPath() {
      if (this.$route.path == "/zandoprojects") {
        return true
      }
      return false
    }
  },
  async mounted() {
    console.log(`Projects mouted`)
  },
  async created() {
    console.log(`Projects created`)
    this.infoLog(`Start Vuex:projectId(${this.$store.state.project.projectId})`)

    let loader = this.showLoader();

    try {
      let title = ""
      if (this.isZandoPath) {
        // 残土
        let timelineData = {
          projectType: `1`,
          projectid: ``,
          projectname: ``,
          datestring: DateUtil.getDateString('YYYY/MM/DD')
        }
        this.$store.commit("setTimeline", timelineData);
        title = "ダンプカー運行管理"
      } else {
        // 生コン
        let timelineData = {
          projectType: `0`,
          projectid: ``,
          projectname: ``,
          datestring: DateUtil.getDateString('YYYY/MM/DD')
        }
        this.$store.commit("setTimeline", timelineData);
        title = "生コン運行管理"
      }

      if (this.$store.state.user.userType == 0) {
        this.userAuthority = false
      } else {
        this.userAuthority = true
      }

      // スマートフォン判定(幅768px未満)の場合はメニューを折りたたむ
      if (window.innerWidth < 768 && !this.$sidebar.isMinimized) {
        this.$sidebar.toggleMinimize()
      }
      
      let navInfo = this.$store.state.navInfo
      navInfo.title = title
      navInfo.description = ""
      this.$store.commit("setNavInfo", navInfo)
      await this.getProjectList();
      await this.runSort(this.sortColumn);

      // 連打防止
      this.repeatedHitsFlg = false

      // システム利用区分チェック
      if (!await this.isValidProjectType()) {
        return
      }

    } catch (e) {
      this.errorLog(`created`, this.parseErrorObject(e))
    } finally {
      this.hideLoader(loader);
    }
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  beforeRouteLeave() {
    this.infoLog(`beforeRouteLeave`, `end`)
  },
  methods: {
    async getProjectList() {
      //UserInfo.getUserInfo();
      let userInfo = this.$store.state.user
      let currentCognitoId = userInfo.cognitoSub
      if (this.isZandoPath){
        this.projects = await ProjectModel.getProjectListFromlsiStr1(1);
      } else {
        this.projects = await ProjectModel.getProjectListFromlsiStr1(0);
      }
      if (userInfo.userType != "2") {
        this.projects = this.projects.filter(function(project) {
          let index = project.project.users.findIndex(function(cognitoId) {
            this.debugLog(`getProjectList cognitoId:${cognitoId} currentCognitoId:${currentCognitoId}`)
            return currentCognitoId == cognitoId
          }.bind(this))
          this.debugLog(`getProjectList index:${index}`)
          return index >= 0
        }.bind(this))
        
      }
      
      // console.log(`projects:${JSON.stringify(this.projects,null,"\t")}`)
    },   
    async onClickNewProject() {

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true
      
      
      // プロジェクト上限数チェック
      const projectLimit = await settingModel.getProjectLimit()
      let limitNum = 100
      if (projectLimit) {
        if (projectLimit.value) {
          limitNum = projectLimit.value - 0
          console.log(`baseAreaLimit.value: ${projectLimit.value}`)
        }
      }
      
      const projects = await ProjectModel.getProjectListFromlsiStr1(this.isZandoPath ? 1 : 0)
      
      if (projects.length >= limitNum) {
        this.showBottomToast("プロジェクト数の上限に達しました。", "error");
        
      } else {
        this.$router.push({
          path: this.getRoutePath(`newproject`),
        });
      }
    },
    /**
     * プロジェクト名リンククリックのイベントハンドラ
     */
    onClickProject(row) {

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true

      // プロジェクトIDを取得
      let id = row.sk.split("#")[1];
      // プロジェクト名を取得
      let name = row.project.name;
      
      let type = row.project.projectType ? row.project.projectType : "0"
      console.log(`プロジェクトタイプ ${type}`)
      //alert(id)
      let timelineData = {
        projectType: `${type}`,
        projectid: `${id}`,
        projectname: `${name}`,
        datestring: DateUtil.getDateString('YYYY/MM/DD')
      }
      this.$store.commit("setTimeline", timelineData);

      

      const ua = navigator.userAgent.toLowerCase();
      if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
        this.$router.push({
          path: this.getRoutePath(`mobiletimeline`)
        });
      } else {
        this.$router.push({
          path: this.getRoutePath("timeline")
        });
      }
    },
    onEditProject(row) {

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true
      
      this.debugLog(JSON.stringify(row, null, "\t"));
      let store = this.$store.state.project;
      // alert(JSON.stringify(row.meeting,null,"\t"))
      store.projectId = row.project.id;
      
      this.$store.commit("setProject", store);
      this.$router.push({
        path: this.getRoutePath(`editproject`),
      });
    },
    handleSelectRow(selection, row) {
      // console.log(row);

      // 今回選択した行以外は選択を外し、常に単一選択とする
      for (let i = 0; i < selection.length; i++) {
        if (selection[i] != row) {
          this.$refs.projectTable.toggleRowSelection(selection[i], false)
        }
      }
      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

    },
    handleSelectAllRow() {
      // 全行選択時は選択を解除する
      this.$refs.projectTable.clearSelection();
      this.selectedData = null;
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.debugLog(`handleCurrentChange`);

      // 同じ行を選択した場合は選択解除
      if (currentRow == oldCurrentRow) {
        this.$refs.projectTable.setCurrentRow();
        return;
      }
      this.selectedData = currentRow;
    },
    /**
     * 日付文字を別の形式に変更
     */
    dateStringFormat(dateString) {
      return DateUtil.getFormatString(dateString, "YYYY/MM/DD HH:mm")
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      this.debugLog(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      this.debugLog(fieldName);

      if (!fieldName) {
        return;
      }

      // 復元用に保持
      // this.sortColumn = column;

      // 日本語ソート
      let isKana = true;
      if (fieldName == "createdAt") {
        isKana = false;
      }

      this.projects = SortUtil.sort(this.projects, fieldName, sortingType, isKana);
    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },
    // debugLog(message) {
    //   appLog.debugLog(this.vuename, this.$store.state.user.userId, message)
    // },
    // infoLog(message) {
    //   appLog.infoLog(this.vuename, this.$store.state.user.userId, message)
    // },
    // errorLog(message) {
    //   appLog.errLog(this.vuename, this.$store.state.user.userId, message)
    // },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },
  },
};
</script>
