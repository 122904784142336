<template>
  <div>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>
<style>
</style>
<style scoped>
.main-content {
  height: 90vh;
  /* height: 100%; */
  height: 100dvh;
}
</style>
<script>
export default {
  components: {
  },
  
  data() {
    return {
    }
  },
  computed: {    
    debug() {
      return process.env.NODE_ENV == 'dev' || process.env.NODE_ENV == 'local'
    }
  },
  watch: {

  },
  methods: {
    async init() {
      this.env = process.env.NODE_ENV
    },
  },
  mounted() {
    this.init()
    
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
    // console.log('beforeCreate');
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    // console.log('created');
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
    // console.log('beforeMount');
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
    // console.log('beforeUpdate');
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
    // console.log('updated');

    // // 現在のパスを取得
    // const path = this.$route.path;
    // // console.log(path)

    // if (path == '/noticeList') {
    //   // 通知を表示した際は未読がすべて既読になるのでクリア
    //   this.notReadNoticeCount = '';
    // }
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
    // console.log('beforeUnmount');
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    // console.log('unmounted');
  }
};
</script>
<style lang="scss"></style>
