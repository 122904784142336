<template>
   <modal v-model:show="modal.isShow" class="modalBodyClass" type="mini">
    <template v-slot:header>
        <h5 class="modal-title" id="modalLabel">{{modal.title}}</h5>
    </template>
    <div>
      {{modal.message}}
    </div>
    <template v-slot:footer>
      <base-button v-model:type="modal.cancelType" @click="modal.cancelCallBack">{{modal.cancelLabel}}</base-button>
      <base-button v-model:type="modal.okType" @click="modal.okCallBack">{{modal.okLabel}}</base-button>
    </template>
  </modal>
  <div>
      <div class="card">
          <div class="card-header border-0">
            <div class="row ml-0">
              <table-tool-menu
                class="col-12"
                :title="deviceListed"
                :isAdd="false"
                :isSingleSelect="false"
                :isMultiSelect="false"
                :processing="false"
                :disableMoveDown="false"
                :disableMoveUp="false"
              >
              </table-tool-menu>
            </div>
          </div>
        <div class="device-list">
          <el-table
              class="table-responsive table-flush"
              header-row-class-name="app-th-row"
              :cell-style="{padding: '2px 10px', height: '15px', fontSize: '13px'}"
              @sort-change="runSort"
              :default-sort="{ prop: 'deviceId.lastUsedDate', order: 'descending' }"
              :data="pagedData"
            >
              <el-table-column
                label="端末名"
                min-width="320px"
                prop="deviceId.deviceId"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  <span class="deviceLblStyle">
                    {{ row.deviceId.deviceId }} <br/>
                    {{ checkEmpty(row.deviceId.modelName + '-' + row.deviceId.userId) ? row.deviceId.modelName + '-' + row.deviceId.userId : "" }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="タイプ" prop="deviceId.deviceType" min-width="120px">
                <template v-slot="{ row }">
                  <span class="deviceLblStyle" v-if="row.deviceId.deviceType == '0'">Web</span>
                  <span class="deviceLblStyle" v-if="row.deviceId.deviceType == '1'">iPhone</span>
                  <span class="deviceLblStyle" v-if="row.deviceId.deviceType == '2'">Android</span>
                  <span></span>
                </template>
              </el-table-column>

              <el-table-column
                label="最終利用日時"
                min-width="200px"
                prop="deviceId.lastUsedDate"
                sortable="'custom'"
              >
                <template v-slot="{ row }">
                  {{ checkEmpty(row.deviceId.lastUsedDate) ? dateStringFormat(row.deviceId.lastUsedDate) : "" }}
                </template>
              </el-table-column>

              <el-table-column
                min-width="200px"
              >
                <template v-slot="{ row }">
                  <div class="col-sm-6">
                    <base-button
                      type="primary"
                      size="sm"
                      block
                      @click="onClickDeleteDevice(row)"
                      >削除</base-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="row mt-1">
              <div class="col">
                <div class="mt-1 d-flex justify-content-center">
                  <el-pagination
                    @current-change="setCurrent"
                    :page-size="pagination.perPage"
                    :current-page="pagination.currentPage"
                    layout="prev, pager, next"
                    :total="total">
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
  </div>
 
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
    margin-bottom: 0 !important;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .device-list {
    width: 100%;
    height: 100%;
  }

  .deviceLblStyle {
    color: #003C9C;
    font-weight: bold;
  }

  .modal-open {
    width: 100% !important;
  }

  .modalBodyClass {
    word-break: break-word;
  }

  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>

<script>
import DeviceModel from "../../appModel/device/DeviceModel";
import TenantModel from "../../appModel/Tenant/TenantModel";
import UserInfo from "@/appUtils/UserInfo";
import TableToolMenu from "../components/Menu/TableToolMenu"
import SortUtil from '../../appUtils/SortUtil.js';
import ValidateUtil from "../../appUtils/ValidateUtil";
import appLog from "@/appUtils/AppLog"
import ValidSession from "../common/ValidSession.js";
import DateUtil from "@/appUtils/DateUtil"
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
import Modal from "@/components/Modal";

export default {
  components: {
    TableToolMenu,
    Modal,
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      deviceList: [],
      modal: {
        isShow: false,
      },
      userAuthority: true,
      title: "端末一覧",
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      /**
       * 初期ソート（最終利用日時=>降順)
       */
      sortColumn: {
        prop: 'deviceId.lastUsedDate',
        order: 'descending',
      },
      deviceTotal: ""
    };
  },

  // コンピュートプロパティ
  computed: {
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.deviceList.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.deviceList.length;
    },
    vuename() {
      return "DeviceList.vue"
    }, 
    deviceListed(){
      return this.title + "（ご利用数" + this.deviceList.length + "台／ご契約数" + this.deviceTotal + "台）"
    }
  },

  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    let timelineData = {
      projectType: `0`,
      projectid: ``,
      projectname: ``,
      datestring: DateUtil.getDateString('YYYY/MM/DD')
    }
    this.$store.commit("setTimeline", timelineData);
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
    this.init()
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
  },
  beforeRouteLeave() {
    appLog.infoLog("DeviceList.vue", `End`)
  },
  methods: {
    async init() {
      this.infoLog("init", "start")
      let loader = this.showLoader();

      try {

        // ログインユーザーの情報
        const user = await UserInfo.getUserInfo();
        // 販売店の情報
        const tenant = await TenantModel.getTenant(user.group);

        this.deviceTotal = tenant.tenant.deviceNum

        await this.getDeviceList()
        
        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = "利用端末管理"
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)

      } catch (e) {
        appLog.errLog("DeviceList.vue", `${e.message}`)
        this.errorLog("init", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
        this.infoLog("init", "end")
      }
    },
    async onClickDeleteDevice(row) {

      const _this = this;

      let documentClasses = document.body.classList;
      
      documentClasses.remove("modal-open");

      //削除確認ポップアップ表示
      this.$nextTick(function() {
        this.modal = {
        
          isShow: true,
          title: "端末の削除",
          message: `${row.deviceId.deviceId}（${row.deviceId.modelName}-${row.deviceId.userId}）を削除します。よろしいですか？`,
          okLabel: "削除",
          okType: "danger",
          okCallBack: function() {
            _this.deleteDevice(row);
            // 閉じる
            _this.modal.isShow = false
          },
          cancelLabel: "キャンセル",
          cancelType: "secondary",
          cancelCallBack: function() {
            // 閉じる
            _this.modal.isShow = false

          },
        };
      });
    },
    async deleteDevice(row){
      try {
        //端末削除行う
        let deleteDeviceRet = await DeviceModel.deleteData(row);
        this.debugLog(`deleteDevice`, `${JSON.stringify(deleteDeviceRet)}`)
      } catch (e) {
        //削除失敗ログ
        this.errorLog(`onClickDeleteDevice`, `${this.parseErrorObject(e)}`);
        this.showBottomToast("削除時にエラーが発生しました", "error");
      } finally {
        await this.getDeviceList()
      }
    },
    async getDeviceList() {
      //端末データ取得
      this.deviceList = await DeviceModel.getDeviceList();
      await this.runSort(this.sortColumn);
    },
    checkEmpty(item){

      if (item === "-") {
        return false
      }

      return ValidateUtil.checkEmpty(item)
    },
    dateStringFormat(dateString) {
      return DateUtil.getFormatString(dateString, "YYYY/MM/DD HH:mm:ss")
    },
    /**
     * ソートを実行します。
     */
    async runSort(column) {
      await this.changeTableSort(column);
    },

    /**
     * テーブルのソート順を変更します。
     */
    async changeTableSort(column){
      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      if (!fieldName) {
        return;
      }

      // 日本語ソート
      let isKana = true;
      if (fieldName == "lastUsedDate") {
        isKana = false;
      }
     
      this.deviceList = SortUtil.sort(this.deviceList, fieldName, sortingType, isKana);

    },
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
  }
};
</script>
