<template>
  <div>
    <div class="card">
      <!-- ヘッダー -->
      <div class="row ml-0">
        <application-header
          :title="appTitle"
          :isDatePicker="false"
          :isNowTime="false"
          :isEditBase="false"
          :isEditVehicle="false"
          :isCreatePlan="false"
          :isDriveLog="false"
          :isOutputCsv="false"
          :isDriveVue="true"
          @driveVue="onClickDriveVue"
        >
        </application-header>
      <!-- <div class="card-header border-0">
        <button type="button" class="btn btn-secondry w-20"
        @click="clickCancel" >キャンセル</button>
      </div> -->
      <div class="card-header border-0">
      <!-- ↓↓↓ ヘッダー部ページング一時コメント -->
      <!-- <div class="card-header border-0 d-flex flex-fill flex-column"> -->
      <!-- ↑↑↑ ヘッダー部ページング一時コメント -->
        <!-- メニュー 削除・編集・上へ・下へを有効 -->
        <div class="row ml-0">
            <table-tool-menu
                class="vehicleMenu"
                :title="title"
                :isAdd="false"
                :isEdit="true"
                :editTitle="editTitle"
                :isDelete="false"                    
                :isCopy="false"
                :isSingleSelect="selectRow"
                :isMultiSelect="false"
                :processing="false"
                :isOrderMove="true" 
                :disableMoveDown="disableMoveDown"
                :disableMoveUp="disableMoveUp"
                @edit="onEditVehicle(selectedData)"
                @moveRow="onClickRowMove"
            >
            </table-tool-menu>            
        </div>

        <!-- ↓↓↓ ヘッダー部ページング一時コメント -->
        <!--         
        <div class="row mt-0 d-flex flex-fill justify-content-center">        
          <div class="col-auto">
            <div class="mt-0">
              <el-pagination
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>

          <div class="col-auto">
          </div>
        </div> 
        -->
        <!-- ↑↑↑ ヘッダー部ページング一時コメント -->
      </div>

      <!-- 車両情報一覧テーブル -->
      <div class="vehicle-list">
        <el-table
          ref="VehicleTable"
          class="table-responsive table-flush"
          header-row-class-name="app-th-row"
          select-on-indeterminate="true"
          :data="pagedData"
          :row-class-name="tableRowSetColor"
          @select="handleSelectRow"
          @select-all="handleSelectAllRow"
        >
          <el-table-column type="selection" align="left" min-width="120px"></el-table-column>          
          <el-table-column
            label="車両番号"
            min-width="150px"
            prop="vehicle.name"
          >
          </el-table-column>
          <el-table-column label="最大積載量（㎥）" v-if="!isZando" min-width="100px" prop="vehicle.maxCapacity" ></el-table-column> 
          <el-table-column label="モバイルアプリで表示しない" min-width="100px" prop="invaliddDisp" ></el-table-column>
          <el-table-column label="備考" min-width="200px" prop="vehicle.memo" ></el-table-column>
        </el-table>

        <div class="row mt-1 d-flex flex-fill justify-content-center">        
          <div class="col-auto">
            <div class="mt-1">
              <el-pagination
                @current-change="setCurrent"
                :page-size="pagination.perPage"
                :current-page="pagination.currentPage"
                layout="prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- 編集用のモーダル(車両情報) -->
      <modal v-model:show="modals.ItemEdit" size="sm" body-classes="p-0">
        <template v-slot:header>
          <h6 class="modal-title">{{editTitle}}</h6>
        </template>
        <card
          type="secondary"
          header-classes="bg-transparent pb-5"
          body-classes="px-lg-2 py-lg-2"
          class="border-0 mb-0"
        >
          <div class="col-md-12">
            <base-input type="text" label="車両番号">
              <template></template>
              <el-input
                v-model="editValueItem.name"
              ></el-input>
            </base-input>
          </div>
          <div class="col-md-12" v-if="!isZando">
            <base-input type="text" label="最大積載量（㎥）">
              <template></template>
              <el-input
                v-model="editValueItem.maxCapacity"
              ></el-input>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input type="text" label="表示・非表示フラグ">
              <el-checkbox
                v-model="editValueItem.noDispiPhone"
                label="モバイルアプリで表示しない"
                size="medium"
              ></el-checkbox>
            </base-input>
          </div>

          <div class="col-md-12">
            <base-input type="text" label="備考">
              <template></template>
              <el-input
                v-model="editValueItem.memo"
              ></el-input>
            </base-input>
          </div>                       
        </card>

        <!-- キャンセル、更新ボタン -->
        <div class="row mt-2 mb-2 ml-2 mr-2">
          <div class="col-sm-5">
            <base-button
              type="secondary"
              block
              @click="modals.ItemEdit = false"
              >キャンセル</base-button
            >
          </div>
          <div class="col-sm-2">
          </div>
          <div class="col-sm-5">
            <base-button
              type="primary"
              block
              @click="onClickSubmitItem"
              >更新</base-button
            >
          </div>
        </div>
      </modal> 

      </div>
    </div>
  </div>
</template>

<style scoped>
  .card {
     /* 横スクロールバー非表示対策 */
    overflow-x: hidden;
    border-radius: 0;
  }
  .card-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }  
  .vehicle-list {
    width: 100%;
    height: 100%;
  }
  :deep .el-pagination .btn-next .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pagination .btn-prev .el-icon {
    font-size: 1.2rem;
  }

  :deep .el-pager li {
    font-size: 1.2rem;
  }
</style>

<!-- <style scoped> -->
<style>
  /** このVueだけのスタイル */

  /* @import url("//unpkg.com/element-plus/dist/index.css");
  .el-table .warning-row {
    --el-table-tr-background-color: var(--el-color-warning-lighter);
  }
  .el-table .success-row {
    --el-table-tr-background-color: var(--el-color-success-lighter);
  } */
  .el-table .no-disp-mobile-row {
    --el-table-tr-background-color: gray;
  }
</style>

<script>
import ApplicationHeader from '../components/Menu/ApplicationHeader.vue';
import Modal from "@/components/Modal";
import appLog from "@/appUtils/AppLog"
import TableToolMenu from "../components/Menu/TableToolMenu";
import VehicleModel from "@/appModel/Vehicle/VehicleModel";
import { useToast } from "vue-toastification";
import ValidSession from "../common/ValidSession.js";
import UseApps from "@/appViews/common/UseApps.js";
import Logger from "@/appViews/common/Logger.js";
//import SortUtil from '@/appUtils/SortUtil'; 未使用

export default {
  components: {
    ApplicationHeader,
    TableToolMenu,
    Modal
  },
  mixins: [ValidSession, UseApps, Logger],
  data() {
    return {
      projectType: "0", // 0:生コン、1：残土
      projectId: null,
      projectName: null,
      datestring: null,
      vehicles: [],
      selectedData: null,
      appTitle: "車両編集",
      title: "車両情報一覧",
      editTitle: "車両情報を編集",
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },      
      modals: {
        ItemEdit: false,
      },
      editValueItem: null,  
      disableMoveDown: false,
      disableMoveUp: false,
      userAuthority: 1,
      memoLengh: 100,
      repeatedHitsFlg: false, // 連打防止フラグ （運行情報画面へ遷移する際に使用）      
    };
  },

  // コンピュートプロパティ
  computed: {
    selectRow() {
      if (this.userAuthority == 0)
      {
        return false;
      }
      if (this.selectedData) {
        return true;
      } else {
        return false;
      }
    },
    /***
     * ページング用のデータをかえす
     */
    pagedData() {
      return this.vehicles.slice(this.from, this.to)
    },
    /**
     * ページング制御用
     */
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    /**
     * ページング制御用
     */
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    /**
     * ページング制御用
     */
    total() {
      return this.vehicles.length;
    },
    vuename() {
      return "VehicleList.vue"
    },   
  },
  beforeCreate() {
    //インスタンスは生成されたがデータが初期化される前
  },
  created() {
    //インスタンスが生成され､且つデータが初期化された後
    this.infoLog("created", `Start Vuex：projectid(${this.$store.state.vehicle.projectid})、projectname(${this.$store.state.vehicle.projectname})、datestring(${this.$store.state.vehicle.datestring})`)
    this.init()
  },
  beforeMount() {
    //インスタンスが DOM 要素にマウントされる前
  },
  mounted() {
    //インスタンスが DOM 要素にマウントされた後
  },
  beforeUpdate() {
    //データは更新されたが DOM に適用される前
  },
  updated() {
    //データが更新され､且つ DOM に適用された後
  },
  beforeUnmount() {
    //Vue インスタンスが破壊される前
  },
  unmounted() {
    //Vue インスタンスが破壊された後
    this.dbData = null
  },
  beforeRouteLeave() {
    // モーダルを非表示
    this.modals.ItemEdit = false;
    
    this.infoLog("beforeRouteLeave", `End`)
  },
  //ボタンイベントなどのメソッドはmethodsに
  methods: {
    /**
     * 初期化処理
     */    
    async init() {
      this.infoLog("init", "start")
      let loader = this.showLoader();

      // store(vuex)から値取得
      this.projectId = this.$store.state.vehicle.projectid
      this.datestring = this.$store.state.vehicle.datestring
      this.projectName = this.$store.state.vehicle.projectname

      try {
        // ProjectIDがセットされているかチェック
        if (this.projectId == null || this.projectId == ""){
          this.showBottomToast(`車両一覧画面表示処理に失敗しました。（プロジェクトIDが不正）`, 'error').then(() => {
          })
          this.$router.push({
            path: "/projects",
          });        
        }
        // プロジェクトタイプ取得
        if (this.isZando) {
          this.projectType = "1"; // 残土
        } else {
          this.projectType = "0"; // 生コン
        }        
        
        // タイトルをセット
        let navInfo = this.$store.state.navInfo
        navInfo.title = `${this.projectName} - ${this.$route.meta.title}`
        navInfo.description = ""
        this.$store.commit("setNavInfo", navInfo)
        
        // 車両編集用変数初期化
        this.initEditListItem();

        // 車両一覧取得
        await this.getVehicleList();

        // 連打防止
        this.repeatedHitsFlg = false

      } catch (e) {
        // appLog.errLog("VehicleList.vue", this.$store.state.user.userId, `${e.message}`)
        this.errorLog("init", this.parseErrorObject(e))
      } finally {
        this.hideLoader(loader);
        this.infoLog("init", "end")
      }
    },
    /**
     * 車両編集項目初期化
     */       
    initEditListItem() {
      
      // 車両編集項目の初期化
      let item = {
        name: "",
        maxCapacity: 0,
        noDispiPhone: false,
        memo: "",
      }

      this.editValueItem = item
    },
    /**
     * 車両情報一覧取得（vehicle.orderカラム昇順でソート）
     */       
    async getVehicleList(){

      const projectId = this.projectId;
      let listdata = await VehicleModel.getVehicleList(projectId); 
      
      // ソートして一覧に表示
      this.vehicles = listdata.sort(function(a, b){
        //return (a.vehicle.order < b.vehicle.order) ? -1: 1;
        if (a.vehicle.order > b.vehicle.order) return 1;
        if (a.vehicle.order < b.vehicle.order) return -1;

        if (a.vehicle.id > b.vehicle.id) return 1;
        if (a.vehicle.id < b.vehicle.id) return -1;
        return 0
      })

      // 表示・非表示設定、最大積載量小数表示桁数(2桁)設定
      this.vehicles.forEach(item => {
        if (item.invalid == true){
          item.invaliddDisp = "表示しない"
        }
        item.vehicle.maxCapacity = item.vehicle.maxCapacity.toFixed(2);
      });

      // メニューを非表示にする
      this.selectedData = null;      
    },
    /**
     * 車両情報編集画面（モーダル画面表示）
     */       
    onEditVehicle(row) {
      // 選択行の情報をセット      
      this.editValueItem.name = row.vehicle.name;
      this.editValueItem.maxCapacity = row.vehicle.maxCapacity;
      this.editValueItem.memo = row.vehicle.memo;
      this.editValueItem.noDispiPhone = null;
      this.editValueItem.noDispiPhone = row.invalid;

      // 編集画面表示
      this.modals.ItemEdit = true;
    },
    /**
     * 車両情報編集画面（モーダル）更新ボタン押下時の処理
     */       
    async onClickSubmitItem() {
      this.infoLog("onClickSubmitItem", "start")
      //車両番号の入力チェック
      if (this.editValueItem.name == ""){
        this.showBottomToast('車両番号を入力してください', 'warning')
        return;
      }
      
      //最大積載量の入力チェック
      if (this.editValueItem.maxCapacity == ""){
        this.showBottomToast('最大積載量（㎥）を入力してください', 'warning')
        return;
      }
      
      // 入力チェック
      // 最大積載量 数値かチェック
      if (isNaN(this.editValueItem.maxCapacity)){
        this.showBottomToast(`最大積載量（㎥）には数値を入力してください。`, 'warning')
        return;
      }
      // 小数点以下のチェック（2桁までOK）
      //console.log(this.editValueItem.maxCapacity)
      let chkValue = String(this.editValueItem.maxCapacity).split('.')
      if (chkValue.length == 2){
        if (chkValue[1].length > 2){
          this.showBottomToast(`最大積載量（㎥）の小数点以下は2桁までにしてください。`, 'warning')
          return
        }
      }      

      // 備考の文字長さチェック
      if (String(this.editValueItem.memo).length > this.memoLengh){
        this.showBottomToast(`備考の文字数は ${this.memoLengh} 文字以内で入力してください。`, 'warning')
        return 
      }
      // 備考の文禁則文字チェック
      if (String(this.editValueItem.memo).length > 0){
        // 禁則文字チェック
        let kinsokuflg = false
        const kinsoku_str = process.env.VUE_APP_KINSOKU_CHARACTERS
        for (let i=0; i<String(kinsoku_str).length;i++) {
          let check_str = String(kinsoku_str).substr(i+1, 1)
          let ret = String('dummy' + this.editValueItem.memo).indexOf(check_str)
          if (ret>0) {
            kinsokuflg = true
            break
          }
        }
        if (kinsokuflg){
          this.showBottomToast(`備考に禁則文字が指定されてます。\n禁則文字（${kinsoku_str.substr(1, kinsoku_str.length - 1)}）`, 'warning')
          return 
        }
      }

      try {
        if (confirm('車両情報を更新します。よろしいですか？'))
        {
          // 更新対象のデータ作成
          let updateVeihcle = this.selectedData;
          updateVeihcle.vehicle.name = this.editValueItem.name
          updateVeihcle.vehicle.maxCapacity = this.editValueItem.maxCapacity;
          updateVeihcle.vehicle.memo = this.editValueItem.memo;
          updateVeihcle.invalid = this.editValueItem.noDispiPhone;

          // 追加した表示・非表示用のカラム削除
          delete updateVeihcle["invaliddDisp"]
          
          // 更新処理
          await VehicleModel.updateVehicle(updateVeihcle);

          // モーダル画面閉じる
          this.modals.ItemEdit = false;

          // 一覧情報再取得
          this.getVehicleList(updateVeihcle);

          // 完了メッセージ
          this.showBottomToast(`車両情報処理が完了しました。`, 'info')

          // ログ出力
          appLog.infoLog("VehicleList.vue", this.$store.state.user.userId, `【Update】 Vehicle： pk[${updateVeihcle.pk}]、sk[${updateVeihcle.sk}]、車両名 [${updateVeihcle.vehicle.name}]、最大積載量（㎥） [${updateVeihcle.vehicle.maxCapacity}]、モバイルアプリで表示しない [${updateVeihcle.invalid}]、メモ [${updateVeihcle.vehicle.memo}]`)

        } 
      } catch (e) {
        //エラー外メッセージ表示
        this.showBottomToast(`$車両情報更新処理に失敗しました。`, 'error')
        appLog.errLog("VehicleList.vue", this.$store.state.user.userId, `${e.message}`)
        this.errorLog("onClickSubmitItem", this.parseErrorObject(e))
      }         
    },    
    /**
     * 上へ下へボタンの活性、非活性の設定
     */ 
    controlMoveButton(index)
    {
      if (index == 0)
      {
        this.disableMoveDown = false;
        this.disableMoveUp = true;
      } else if (index == this.vehicles.length - 1)
      {
        this.disableMoveDown = true;
        this.disableMoveUp = false;
      } else 
      {
        this.disableMoveDown = false;
        this.disableMoveUp = false;
      }
    },
    /**
     * テーブルの行選択時の処理
     */       
    handleSelectRow(selection, row) {

      if (this.userAuthority == 0)
      {
        this.$refs.VehicleTable.clearSelection()
        this.selectedData = null
        return 
      }

      // 今回選択した行以外は選択を外し、常に単一選択とする
      for (let i = 0; i < selection.length; i++) {
        if (selection[i] != row) {
          this.$refs.VehicleTable.toggleRowSelection(selection[i], false)
        }
      }

      if (this.selectedData == row) {
        // 同一業の場合はチェックOFF
        this.selectedData = null;
      } else {
        this.selectedData = row;
      }

      // 上下矢印の有効、無効を設定
      const nowIndex = this.getRowIndex(this.selectedData.id);
      this.controlMoveButton(nowIndex);
    },
    /**
     * テーブルの全チェックボックスをチェックした時の処理
     */       
    handleSelectAllRow() {
      // 全行選択時は選択を解除する
      this.$refs.VehicleTable.clearSelection();
      this.selectedData = null;
    },   
    /**
     * 上へ、下への矢印ボタン押下時の処理
     */       
    onClickRowMove(direction) {
      // 連続押下防止
      if (this.processing) return;

      this.processing = true;

      this.updateOrder(direction).then(() => {
        this.processing = false;
      })
    },
    /**
     * 上へ、下への矢印ボタン押下時の処理
     */       
    updateOrder(direction) {
      return new Promise((resolve) => {
        setTimeout(() => {
          // 対象のテーブル
          let dataList = this.vehicles;
          // 対象の行
          let currentRow =this.selectedData;

          // 現在の行インデックス
          const nowIndex = this.getRowIndex(currentRow.id);
          
          // 移動後のインデックス
          let afterIndex = nowIndex + 1;
          if (direction == 'up') {
            afterIndex = nowIndex - 1;
          }

          // 連打された時の対応
          if (afterIndex < 0) afterIndex = 0
          if (afterIndex > this.vehicles.length - 1) afterIndex = this.vehicles.length - 1

          // リストの中から2つのインデックスに該当する行を抽出し、
          // インデックスを入れ替えた値でorderを更新
          // ※order順で並んでいる想定
          // ※先頭、末尾行への考慮はボタンのdisableで制御済みの想定
          dataList[nowIndex].vehicle.order = afterIndex;
          dataList[afterIndex].vehicle.order = nowIndex;

          // 明示的に並び替える
          dataList.sort(function(a, b) {
            if (a.vehicle.order < b.vehicle.order) return -1;
            if (a.vehicle.order > b.vehicle.order) return 1;
            return 0;
          })        

          // DBを更新
          this.saveVehicle(nowIndex, afterIndex).then(() => {
            
            // 上へ下へボタン活性、非活性にする
            this.controlMoveButton(afterIndex);

            // チェックが外れてしまうので、移動後のデータを再チェック
            this.$refs.VehicleTable.toggleRowSelection(currentRow, true);
    
          })

          resolve();
        }, 100);
      });
    },
    /**
     * テーブル行から指定したidのインデックスを取得します。
     * @params {string} id ID
     * @returns {number} インデックス
     */
    getRowIndex(id) {
      // 対象のテーブル
      let dataList = this.vehicles;

      let currentIndex = dataList.findIndex(item => {
        return item.id == id
      })

      return currentIndex;
    },
    /**
     * 車両情報を保存する（変更行のソート番号更新）
     */
    async saveVehicle(nowIndex, afterIndex) {
      try {
        
        // レコード移動前のソート番号を更新
        let updateItem = this.vehicles[nowIndex]
        delete updateItem["invaliddDisp"]

        await VehicleModel.updateVehicle(updateItem).then(() => {
        })

        // レコード移動後のソート番号を更新
        updateItem = this.vehicles[afterIndex]
        delete updateItem["invaliddDisp"]
        await VehicleModel.updateVehicle(updateItem).then(() => {
        })

        // 表示・非表示再設定
        this.vehicles.forEach(item => {
          if (item.invalid == true){
            item.invaliddDisp = "表示しない"
          }
        });

      } catch (e) {
        this.showBottomToast(`車両一覧のソート順番保存処理に失敗しました。`, 'error')
        // appLog.errLog("VehicleList.vue", this.$store.state.user.userId, `${e.message}`)
        this.errorLog("saveVehicle", this.parseErrorObject(e))
        throw e
      }
    },

    /**
     * モバイルで表示しない行はグレイで表示
     */
    tableRowSetColor({ row, rowIndex }) {

      // return 'no-disp-mobile-row'
      if (row.invalid) {
        return 'no-disp-mobile-row'
      }
      return ''
    },
  
    /**
     * トーストでメッセージを表示
     */
    showBottomToast(message, type) {
      this.runToast(message, 'bottom-center', type)
    },
    /**
     * トーストでメッセージを表示（処理）
     */
    runToast(message, pos, type) {

      const toast = useToast();
      toast[type](message, {
        hideProgressBar: true,
        icon: false,
        toastClassName: ["custome-toast-class"],
        closeButton: false,
        position: pos
      });
    },         
    /**
     * 処理中インジケーターを表示します。
     * @returns 表示したインジケーター
     */
    showLoader() {
      return this.$loading.show({
        container: null,
        canCancel: false,
        color: "#003C9C",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        isFullPage: true,
        zIndex: 9999,
      })
    },
    /**
     * 処理中インジケーターを閉じます。
     * @paramas {Object} loader 表示したインジケーター
     */    
    hideLoader(loader) {
      loader.hide();
    },
    /**
     * ページ変更時のハンドラ
     */
    setCurrent(newPage) {
      this.pagination.currentPage = newPage
    },    
    /**
     * 運行情報画面ボタンクリック
     */
    async onClickDriveVue(){

      // 連打防止
      if (this.repeatedHitsFlg) return
      this.repeatedHitsFlg = true
      
      // store(vuex)に値をセット
      let store = this.$store.state.timeline;
      store.projectid = this.projectId;
      store.projectname = this.projectName;
      store.datestring = this.datestring
 
      await this.$store.commit("setTimeline", store);      

      // 車両一覧画面表示
      this.$router.push({
        path: this.getRoutePath('timeline'),
      });        
    }, 
    /**
     * コンソール出力のみ。
     */
    debugLog(funcName, message) {
      try {
        this.base_debugLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にINFOログが残る
     */
    infoLog(funcName, message) {
      try {
        this.base_infoLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラーは破棄
        console.log(e)
      }
    },
    /**
     * AmplifyのAPI経由でS3にERRORログが残る
     */
    errorLog(funcName, message) {
      try {
        this.base_errorLog(`${this.vuename}:${funcName}`, this.$store.state.user.userId, message);
      } catch (e) {
        // ログ出力のエラー破棄
        console.log(e)
      }
    },
    // /**
    //  * ゴミ箱ボタン（削除）押下時の処理
    //  */       
    // async onDeleteVehicle(row) {
    //   try {
    //     if (confirm('選択した車両情報を削除します。よろしいですか？'))
    //     {
    //       // 削除処理
    //       //await VehicleModel.deleteVehicle(this.selectedData);
    //       const item = this.selectedData
    //       item.invalid = true

    //       // 更新処理
    //       await VehicleModel.updateVehicle(item);
          
    //       // 一覧情報再取得
    //       this.getVehicleList();
    //       // 完了メッセージ
    //       this.showBottomToast(`車両情報の削除処理が完了しました。`, 'info')          
    //     }
    //   } catch (e) {
    //     //エラー外メッセージ表示
    //     this.showBottomToast(`車両情報の削除処理に失敗しました。`, 'error')
    //     appLog.errLog("VehicleList", "", `${JSON.stringify(e)}`)
    //   } 
    // },

    // clickCancel() {
    //   this.$router.push({
    //     path: "/Projects",
    //     name: "Projects",        
    //   });  
    // },
    /**
     * ソート実行
     */
    /* ソート機能が不要になった為、削除
    async runSort(column) {
      await this.changeTableSort(column);
    },*/
    /**
     * テーブルのソート順を変更処理
     */
    /* ソート機能が不要になった為、削除
    async changeTableSort(column){
      console.log(column);

      if (column === false) {
        return;
      }

      // フィールド名とソート種別を取得
      let fieldName = column.prop;
      let sortingType = column.order;

      console.log(fieldName);

      if (!fieldName) {
        return;
      }

      // 日本語ソート
      let isKana = true;
      if (fieldName == "name" || fieldName == "memo") {
        isKana = false;
      }

      this.vehicles = SortUtil.sort(this.vehicles, fieldName, sortingType, isKana);
    },*/    
  },
};
</script>
