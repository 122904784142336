class Mime {
  static fileObjToBuffer(object) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onload = e => {
        resolve(e.target.result)
      }
      fr.readAsArrayBuffer(object)
      fr.onerror = () => {
        reject(new Error('例外発生'))
      }
    })
  }


  
  static async getType(object) {
    let arrayBuffer = await this.fileObjToBuffer(object)
    let arr = new Uint8Array(arrayBuffer).subarray(0, 4);
    let header = '';

    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    console.log(arr)
    switch (true) {
      case /^89504e47/.test(header):
        return 'image/png';
      case /^47494638/.test(header):
        return 'image/gif';
      case /^424d/.test(header):
        return 'image/bmp';
      case /^ffd8ff/.test(header):
        return 'image/jpeg';
      default:
        return null;
    }
  }
  static async getExt(object) {
    let arrayBuffer = await this.fileObjToBuffer(object)
    let arr = new Uint8Array(arrayBuffer).subarray(0, 4);
    let header = '';

    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    console.log(arr)
    switch (true) {
      case /^89504e47/.test(header):
        return 'png';
      case /^47494638/.test(header):
        return 'gif';
      case /^424d/.test(header):
        return 'bmp';
      case /^ffd8ff/.test(header):
        return 'jpg';
      default:
        return null;
    }
  }

}
module.exports = Mime